import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScheduleShopService } from 'src/app/core/services/schedule-shop.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-shop-page',
  templateUrl: './shop-page.component.html',
  styleUrls: ['./shop-page.component.scss']
})
export class ShopPageComponent implements OnInit {
  shops=[{
      "id":1,
      "email": "demo21@gmail.com",
      "mobile": "8783459890",
      "firstName": "john",
      "lastName": "hi",
      "parentId": "478d3543-d30d-43f2-9559-a080f7b93025",
      "isRegistered": true,
      "legalName": "NH_SHOP",
      "accountMethod": "Accounts With Stock Management",
      "businessType": "Proprietorship",
      "gstinNo": "22",
      "gstType": "Regular",
      "compositeType": "Trader",
      "homeno": "2",
      "pincode": "390011",
      "address": "10,abcd road",
      "city": "vadodara",
      "district": "vadodara",
      "landmark": "vadodara",
      "state": "GUJARAT",
      "country": "INDIA"
    }  
  ]

  public shopForm: FormGroup;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public ShopListResponse:any;

  constructor(private formBuilder:FormBuilder ,private ScheduleShopService:ScheduleShopService,private toastService:ToastNotificationService,
    private titleCasePipe: TitleCasePipe) { }

  ngOnInit(): void {
  this.getShopList();
  }

  getShopList(){
    this.ScheduleShopService.getShopList({},this.currentUser.id).then((res) => {
      if(res.data.rows){
        this.ShopListResponse = res.data.rows;
      }
     },(err)=>{
      if(err.error.expose){
        this.ShopListResponse = [];
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else{
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }
  
}
