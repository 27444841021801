<div class="widget" style="box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15)">
  <mat-card>
    <mat-card-header>
      <mat-card-title
        style="
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 18px;
        "
        >Order Summary</mat-card-title>
      <!-- <div class="widgeticons">
        <mat-icon class="settingColor">settings_applications</mat-icon>
        <mat-icon class="deleteColor">delete</mat-icon>
      </div> -->
    </mat-card-header>

    <mat-card-content>
      <div class="card-body p-0">
        <div class="max-w-100 line" [chart]="lineChart" style="margin: auto"></div>
      </div>
      </mat-card-content>
  </mat-card>
</div>
