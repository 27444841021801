import { Location, TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.scss']
})
export class BookAppointmentComponent implements OnInit {
  public allAppointmentShopType :any = [];
  shopId: any;
  lang:any;
  param1:any;
  param2:any;
  private searchSubject = new Subject<string>();
  private searchSubscription: Subscription;
  isLoading: boolean = false;

  constructor( private appointmentService : AppointmentService,
    private router: Router,
    private route: ActivatedRoute,private toastService:ToastNotificationService, private titleCasePipe: TitleCasePipe
   ,public _location: Location ) { 
      this.route.queryParams.subscribe(params => {
          this.param1 = params['param1'];
          this.param2 = params['param2'];
      });
    }

  ngOnInit(): void {
    // this.getAppointmentType();
    this.route.paramMap.subscribe((p) => {
      this.shopId = p.get("id");
      if (this.shopId != null) {
        this.getAppointmentType()
      }
    });
    this.searchSubscription = this.searchSubject
      .pipe(debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.isLoading = true; // Show the loader when the API call is initiated
        })).subscribe(searchKey => {
          // Call the API with the search key
          if (searchKey.length >= 3) {
            this.getAppointmentType(searchKey);
          } else if (searchKey === '') {
            // this.isLoading = true; // Hide the loader if the search key is less than the threshold
            this.getAppointmentType();
          } else {
            this.isLoading = false; // Hide the loader if the search key is less than the threshold
          }
        });
        
    this.lang = this.route.snapshot.paramMap.get('lang');
  }

  getAppointmentType(search?) {
    this.appointmentService.getAppointmentType({}, this.shopId, 500, 1, (search ? search : null)).then((res) => {
      if(res.success){
        this.allAppointmentShopType = res.data.pageData 
      }
    },(err) => {
      if(err.error.expose){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      } else{
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }      
    })
  }

  goTOBack(){
    this._location.back();
  }
  
  onSearchInputChange(searchKey: string) {
    // Emit the search key to the searchSubject
    this.searchSubject.next(searchKey);
  }
}
