import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UrlConstants } from 'src/app/_helpers/url-constants';


@Injectable({
  providedIn: 'root'
})
export class UPIserviceService {

  constructor(public httpClient: HttpService,private http:HttpClient) { }
  getUPIDetails(data: {},id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getUpIList +id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
 postUPIDetails(data:{},id){
  return new Promise((resolve,reject)=>{
    this.httpClient.call(data,UrlConstants.PostUpI +id,'POST').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  })

 }
 deleteUPIupiNo(data: {}, upiNo): any {
  return new Promise((resolve, reject) => {
    this.httpClient.call(data, UrlConstants.DeleteUPI + upiNo, 'DELETE').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  });
}
deletemultipleUPIupiNo(data: {}, userId): any {
  return new Promise((resolve, reject) => {
    this.httpClient.call(data, UrlConstants.multipleDeleteUpi + userId, 'DELETE').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  });
}
getPrimaryUpi(data: {},id): any {
  return new Promise((resolve, reject) => {
    this.httpClient.call(data, UrlConstants.IsPrimaryUPI +id, 'PATCH').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  });
}

}
