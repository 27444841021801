import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/core/services/product.service';

@Component({
  selector: 'app-low-stocks-product',
  templateUrl: './low-stocks-product.component.html',
  styleUrls: ['./low-stocks-product.component.scss'],
})
export class LowStocksProductComponent implements OnInit {
  constructor(private productService: ProductService) {}

  lowStockProductList: any[] = [];
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;

  ngOnInit(): void {
    this.getLowStockSummary();
  }
  getLowStockSummary() {
    this.productService
      .getLowStockWarning(
        {},
        this.currentUser.id,
        this.partyPageSize,
        this.currentPageNo + 1
      )
      .then((res) => {
        if (res.success) {
          this.lowStockProductList = res.data.pageData;
        }
      });
  }
}
