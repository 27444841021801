import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UrlConstants } from 'src/app/_helpers/url-constants';

@Injectable({
  providedIn: 'root'
})
export class EndDayReportsService {

  constructor(public httpClient: HttpService,private http:HttpClient) { }
  getAllDayDetails(data: {},id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.allTodayData +id ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getAllDayReciptReportsDetails(data: {},id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.ReciptReports +id ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
