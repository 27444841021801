import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import * as moment from 'moment';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { getChartOptions } from '../../../chartOptions/chartOptions';
import { Chart } from 'angular-highcharts'

HC_exporting(Highcharts);

@Component({
  selector: 'app-stock-details',
  templateUrl: './stock-details.component.html',
  styleUrls: ['./stock-details.component.scss']
})
export class StockDetailsComponent implements OnInit {

  public todaysDate = this.commonService.getTodaysDate();
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public stockDetailsChart: any = [];
  totalProfit: number;
  totalLoss: number;
  grossProfit: number;
  grossLoss: number;
  public salesType: string = 'Weekly';
  public defaultSaleSelect = "Weekly";
  public isWeek: boolean = true;
  public isMonth: boolean = false;
  public isYear: boolean = false;
  @Input() chartType: string; // Default chart type
  public currentChartType: string = 'pie'; // Default chart type
  pieChart: Chart;
  @Input() xAxisTitle: string = 'Days'; // Default x-axis title
  @Input() yAxisTitle: string = 'Sale Amount'; // Default y-axis title
  
  constructor(public commonService: CommonService, public dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getProfitLoss();
    this.dashboardService.widgetData$.subscribe(data => {
      if (data) {
        const chartData = JSON.parse(data.chartData);
        if(chartData.component === 'StockDetailsComponent'){
          this.chartType = data.chartType;
        }
        // this.chartId = data.chartId;
        // this.chartData = data.chartData;
        // Initialize your chart here using this.chartType, this.chartId, and this.chartData
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyChart();
  }

  private initChart(chartType, chartData): void {
    this.destroyChart(); // Destroy any existing chart instance
    // if(categories){
    //   this.xAxisCategories = categories;
    // }
    // const options = { ...lineChartOptions, chart: { ...lineChartOptions.chart, type: this.chartType } };
    const options = getChartOptions(chartType, chartData, this.xAxisTitle, this.yAxisTitle, []);

    // if (chartData) {
    //   options.series = chartData;
    // }

    this.pieChart = new Chart(options);
  }

  getSaleInvoiceReportType(type) {
    this.salesType = type
    if (type === 'Weekly') {
      this.isWeek = true;
      this.isMonth = false;
      this.isYear = false;
      this.getProfitLoss()
    } else if (type === 'Monthly') {
      this.isWeek = false;
      this.isMonth = true;
      this.isYear = false;
      this.getProfitLoss()
    } else if (type === 'Yearly') {
      this.isWeek = false;
      this.isMonth = false;
      this.isYear = true;
      this.getProfitLoss()
    }
  }

  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartType) {
      this.getSaleInvoiceReportType('Weekly');
    }
  }
  
  private destroyChart() {
    if (this.pieChart) {
      this.pieChart.ref$.subscribe(chart => {
        chart.destroy();
      });
    }
  }

  getProfitLoss(){
    const today = new Date(this.todaysDate);
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const lastWeekStartDate = `${lastWeek.getFullYear()}-${lastWeek.getMonth() + 1}-${lastWeek.getDate()}`;
    var chartData = {};
    if (this.salesType === 'Weekly'){
      this.dashboardService.getProfitLoss({}, this.currentUser.id, lastWeekStartDate, this.todaysDate).then(async (result: any) => {

        this.grossProfit = result.data.grossProfit
        this.grossLoss = result.data.grossLoss
        this.totalProfit = result.data.profit
        this.totalLoss = result.data.loss
        if(this.grossProfit === 0 && this.grossLoss === 0 && this.totalLoss === 0 && this.totalProfit === 0){
          this.currentChartType = 'NoData';
        } else {
          this.currentChartType = this.chartType;
          chartData = await this.getPieChartSeries();
          this.initChart(this.currentChartType, chartData);
          // chartData = [
          //   {
          //     name: 'profit',
          //     size : '100%',
          //     data: [
          //       ['Gross Profit', this.grossProfit],
          //       {
          //         name: 'Total Profit',
          //         y: this.totalProfit,
          //         color: '#EDEBF0'
          //       }
          //     ],
          //     innerSize: '80%',
          //     dataLabels: {
          //       enabled: true
          //     }
          //   },
          //   {
          //     name: 'Loss',
          //     size : '50%',
          //     data: [
          //       ['Gross Loss', this.grossLoss],
          //       {
          //         name: 'Total Loss',
          //         y: this.totalLoss,
          //         color: '#FC7643'
          //       }
          //     ],
          //     innerSize: '70%',
          //     dataLabels: {
          //       enabled: true
          //     }
          //   }
          // ]
        }
      }, (error) => {
        if (error.error.status == 404) {
          this.currentChartType = 'NoData';
          this.initChart(this.currentChartType, {});
        }
      })
    }

    // if monthly
    if (this.salesType === 'Monthly'){
      const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
      const oneMonthAgoDate = `${oneMonthAgo.getFullYear()}-${oneMonthAgo.getMonth() + 1}-${oneMonthAgo.getDate()}`;
      this.dashboardService.getProfitLoss({}, this.currentUser.id, oneMonthAgoDate, this.todaysDate).then(async (result: any) => {
        this.grossProfit = result.data.grossProfit
        this.grossLoss = result.data.grossLoss
        this.totalProfit = result.data.profit
        this.totalLoss = result.data.loss
        if(this.grossProfit === 0 && this.grossLoss === 0 && this.totalLoss === 0 && this.totalProfit === 0){
          this.currentChartType = 'NoData';
        } else {
          this.currentChartType = this.chartType;
          chartData = await this.getPieChartSeries();
          this.initChart(this.currentChartType, chartData);
        }
      }, (error) => {
        if (error.error.status == 404) {
          // this.newPartiesList = [];
          this.currentChartType = 'NoData';
          this.initChart(this.currentChartType, {});
        }
      })
    }
    // // if yearly
    if (this.salesType === 'Yearly'){
      const oneYearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD')
      this.dashboardService.getProfitLoss({}, this.currentUser.id,  oneYearAgo, this.todaysDate).then(async (result: any) => {
        this.grossProfit = result.data.grossProfit
        this.grossLoss = result.data.grossLoss
        this.totalProfit = result.data.profit
        this.totalLoss = result.data.loss
        if(this.grossProfit === 0 && this.grossLoss === 0 && this.totalLoss === 0 && this.totalProfit === 0){
          this.currentChartType = 'NoData';
        } else {
          this.currentChartType = this.chartType;
          chartData = await this.getPieChartSeries();
          this.initChart(this.currentChartType, chartData);
        }
      }, (error) => {
        if (error.error.status == 404) {
          this.initChart(this.currentChartType, {});
          this.currentChartType = 'NoData';
        }
      })
    }
    this.initChart('NoData', {});
  }

  getPieChartSeries() {
    return ([
      {
        name: 'profit',
        size : '100%',
        data: [
          ['Gross Profit', this.grossProfit],
          {
            name: 'Total Profit',
            y: this.totalProfit,
            color: '#EDEBF0'
          }
        ],
        innerSize: '80%',
        dataLabels: {
          enabled: true
        }
      },
      {
        name: 'Loss',
        size : '50%',
        data: [
          ['Gross Loss', this.grossLoss],
          {
            name: 'Total Loss',
            y: this.totalLoss,
            color: '#FC7643'
          }
        ],
        innerSize: '70%',
        dataLabels: {
          enabled: true
        }
      }
    ])
    // this.stockDetailsChart = Highcharts;

    // if (this.grossProfit === 0 && this.grossLoss === 0 && this.totalLoss === 0 && this.totalProfit === 0) {
    //   // Display a "No data found" message
    //   const chartOptions = {
    //     chart: {
    //       type: 'pie',
    //       events: {
    //         load: function() {
    //           const chart = this;
    //           const centerX = chart.plotWidth / 2;
    //           const centerY = chart.plotHeight / 2;

    //           // Add the image element
    //           chart.noDataImage = chart.renderer
    //             .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 70, 200, 150)
    //             .add();
    //         }
    //       }
    //     },
    //     title: {
    //       text: ' ',
    //       style: {
    //         fontFamily: "'Poppins', sans-serif",
    //           fontWeight: '500',
    //         fontSize: '16px',
    //         color: '#737791'
    //       }
    //     },
    //     credits: {
    //       enabled: false
    //     }
    //   };
    //   this.stockDetailsChart.chart('stock-details-chart', chartOptions);
    //   document.getElementById('stock-details-chart').style.height = '300px';
    // } else {
    //     const chartOptions = {
    //       chart: {
    //         type: 'pie',
    //       },
    //       credits:{
    //         enabled: false
    //      },
    //       title: {
    //         text: ' ',
    //         style: {
    //           fontFamily: "'Poppins', sans-serif",
    //           fontWeight: '500',
    //           fontSize: '16px',
    //           color: '#737791'
    //         }
    //       },
    //       yAxis : {
    //         gridLineWidth: 0
    //       },
    //       colors: ['#110152'],
    //       series: [
    //         {
    //           name: 'profit',
    //           size : '100%',
    //           data: [
    //             ['Gross Profit', this.grossProfit],
    //             {
    //               name: 'Total Profit',
    //               y: this.totalProfit,
    //               color: '#EDEBF0'
    //             }
    //           ],
    //           innerSize: '80%',
    //           dataLabels: {
    //             enabled: true
    //           }
    //         },
    //         {
    //           name: 'Loss',
    //           size : '50%',
    //           data: [
    //             ['Gross Loss', this.grossLoss],
    //             {
    //               name: 'Total Loss',
    //               y: this.totalLoss,
    //               color: '#FC7643'
    //             }
    //           ],
    //           innerSize: '70%',
    //           dataLabels: {
    //             enabled: true
    //           }
    //         }
    //       ]
    //     };
    //     this.stockDetailsChart.chart('stock-details-chart', chartOptions);
    //     document.getElementById('stock-details-chart').style.height = '300px';
    //   }
  }
}
