<div class="bg-dark">
    <marquee direction="left">
        <h4 class="text-white p-0 m-0 font-style">Shipping all over India</h4>
    </marquee>
</div>
<div class="d-flex p-3 header-color fixed-top">
    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
        <h2 class="text-center font-style font-weight">Enterprise Name</h2>
    </div>
    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
        <i class="fa fa-user fa-2x text-right p-1" aria-hidden="true"></i>
        <span class="margin-left" (click)="showSearchBar()"><i class="fa fa-search fa-2x text-right p-1" aria-hidden="true"></i></span>
        <span class="margin-left"><i class="fa fa-shopping-bag fa-2x text-right p-1" aria-hidden="true"></i></span>
    </div>
</div>
<!-- <nav class="navbar navbar-expand-sm navbar-light bg-light headernav1 nav-tabs nav align-items-center px-4">
    <div class="container">
        <a href=""><img class="logo" [src]="logoImage" alt="LOGO"></a>
        <a class="navbar-brand mr-2 title">
            <h3>ENT Name</h3>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse">
        </div>
        <form class="form-inline my-2 my-lg-0" style="text-align: center">
            <input type="text" class="form-control bg-white form-control-sm search_text border-circle" name="searchdata"
                aria-label="Small" list="searchdata" placeholder="Search" #searchInput
                aria-describedby="inputGroup-sizing-sm" />
            <span class="">
                <i class="fas fa-user fa-2x pointer-event-cursor float-right text-white" title="SignIn"></i>
            </span>
        </form>
    </div>
</nav> -->

<ul ngbNav class="header-color nav-tabs nav d-flex flex-row justify-content-around align-items-center px-3 py-2">
    <li ngbDropdown class="nav-item header2item p-2 pointer-event-cursor">
        <a class="text-dark font-style">Clothing</a>
    </li>
    <li ngbDropdown class="nav-item header2item p-2 pointer-event-cursor">
        <a class="text-dark font-style">Clothing</a>
    </li>
    <li ngbDropdown class="nav-item header2item p-2 pointer-event-cursor">
        <a class="text-dark font-style">Clothing</a>
    </li>
    <li ngbDropdown class="nav-item header2item p-2 pointer-event-cursor">
        <a class="text-dark font-style">Clothing</a>
    </li>
    <li ngbDropdown class="nav-item header2item p-2 pointer-event-cursor">
        <a class="text-dark font-style">Clothing</a>
    </li>
</ul>

<div class="search-box justify-content-between" style="margin-left: 5%;" *ngIf="isSearchBar">
    <div>
        <form class="position-relative">
            <input class="form-control search-input-box fuzzy-search example-full-width border-0" type="search"
                placeholder="Search..." aria-label="Search" />
            <span class="fas fa-search search-box-icon"></span>
        </form>
    </div>
    <div class="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none">
        <div class="btn-close-falcon" aria-label="Close"></div>
    </div>
    
</div>
<ng-template #signUp let-modal style="margin-top: 12%">
    <div class="container-fluid cred-manager">
        <div class="container mt-2">
            <form>
                <div class="col-12 text-center" id="sign_up">
                    <h5 class="letter-spacing">Login</h5>
                </div>
                <div class="form-group mt-5">
                    <input type="text" class="form-control text" placeholder="Username" formControlName="Email"
                        required>
                </div>
                <div class="form-group mt-5 has-feedback">
                    <input type="password" class="form-control text" placeholder="Password" formControlName="Password"
                        [type]="hide ? 'password' : 'text'" required>
                    <span class="form-control-feedback"> <i class="fa pr-2" [ngClass]="hide ? 'fa-eye-slash' : 'fa-eye'"
                            (click)="hide = !hide"></i></span>
                </div>
                <div class="form-group form-check">
                    <div class="row">
                        <div class="col">
                            <input type="checkbox" class="form-check-input" id="autoSizingCheck">
                            <label class="form-check-label" id="autoSizingCheck"><small>Remember
                                    Password</small></label>
                        </div>
                        <div class="col text-right">
                            <span class="pointer-event-cursor"><small>Forgot
                                    Password?</small></span>
                        </div>
                    </div>
                </div>
                <div class="form-group mt-5">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <button type="submit" class="btn btn-primary form-control"><span
                                    class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Login</button>
                        </div>
                        <div class="col-md-6">
                            <button type="submit" class="btn btn-outline-secondary form-control">Sign
                                up</button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</ng-template>