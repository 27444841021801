import { TitleCasePipe } from '@angular/common';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/core/services/common.service';
import { RemoteShopService } from 'src/app/core/services/remote-shop.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-remote-cart',
  templateUrl: './remote-cart.component.html',
  styleUrls: ['./remote-cart.component.scss']
})
export class RemoteCartComponent implements OnInit {
  public productArray: any = [];
  public quantity: any;
  public totalBillAmount;
  public billGstAmount;
  public prodId: any;
  public quantityCount: number =0;
  public isData: boolean = true;
  public mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  public count: number = 0;
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));

  constructor(private remoteShopService: RemoteShopService,
    private toastNotificationService: ToastNotificationService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private titleCasePipe: TitleCasePipe,
    private toastService: ToastNotificationService, ) { }

  ngOnInit(): void {
    this.getCustomerCartItems();
    this.getCartCount();
  }

  /**
   * get user cart items
  */
  getCustomerCartItems() {
    //this.spinner.show()
    this.remoteShopService.getCustomerCartItems({}, this.currentUser.id)
      .then((res) => {
       // this.spinner.hide();
        this.productArray = res.data.products;
        this.billGstAmount = res.data.invoiceDetails.billGstAmount;
        this.totalBillAmount = res.data.invoiceDetails.totalBillAmount;
        this.quantityCount = res.data.invoiceDetails.totalProducts;
        this.commonService.sendData(res.data.invoiceDetails.totalProducts)
      }, (err) => {
        if (err.error.status == 404) {
          this.isData = false;
          //this.spinner.hide();
          this.productArray = [];
          this.billGstAmount = 0;
          this.totalBillAmount = 0;
          this.quantityCount = 0;
          // this.toastNotificationService.openErrorSnackBar("No items found...")
        } else if(err.error.status === 500){
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
      })
  }

  /**
   * delete product from cart by productId
   * @param productId 
   */
  deleteProduct(productId) {
    this.prodId = productId
    // this.remoteShopService.deleteCustomerCartItems({}, productId)
    //   .then((res) => {
    //     if(res){
    //     this.getCustomerCartItems();
    //     this.getCartCount();
    //     this.toastNotificationService.openSnackBar("Product deleted successfully!!!");
    //     }
    //   }, (err) => {
       
    //     this.toastNotificationService.openErrorSnackBar("Try again later...")
    //   })
  }

  /**
   * update product quantity
   * @param productId 
   * @param quantity 
   * @param sign 
   */

  putProductQuantity1(productId,quantity){
    let data = {
      "quantity": quantity
    }
    this.remoteShopService.putProductQuantity(data, productId)
      .then((res) => {
        this.getCustomerCartItems();
        this.getCartCount();
      },(err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else{
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
          });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }

  putProductQuantity(productId, quantity, sign) {
    let newQuantity;
    if (sign == '+') {
      newQuantity = parseInt(quantity) + 1
    }
    else {
      newQuantity = parseInt(quantity) - 1
      if(newQuantity == 0){
        this.deleteProduct(productId);
      }
    }
    let data = {
      "quantity": newQuantity
    }
    this.remoteShopService.putProductQuantity(data, productId)
      .then((res) => {
        this.getCustomerCartItems();
        this.getCartCount();
      },(err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else{
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
          });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }
  removeCart(){

    this.remoteShopService.deleteCustomerCartItems({}, this.prodId)
      .then((res) => {
        if(res){
        this.getCustomerCartItems();
        this.getCartCount();
        this.toastService.toastMsg({
          title: "Success",
          content: "Product Deleted Successfully!",
        })
        // this.toastNotificationService.openSnackBar("Product Deleted Successfully!!!");
        }
      }, (err) => {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Try Again Later!",
          });
        // this.toastNotificationService.openErrorSnackBar("Please Try Again Later!!!")
      })
    
  }
  /**
   * get user cart count
  */
   getCartCount(){
    this.remoteShopService.getCartCount({},this.currentUser.id).then((res)=>{
      this.count = res.data.count
      this.commonService.sendData(res.data.count)
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }
}
