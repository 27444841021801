import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ProductService } from 'src/app/core/services/product.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { RemoteShopService } from 'src/app/core/services/remote-shop.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { StateConstants } from 'src/app/_helpers/state-constans';
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  public warnTxt = Constants.warningText;
  public chkBoxTxt = Constants.chkBoxContactText;
  public clientId: any;
  public allStates = StateConstants.states;
  public cartArray: any = [];
  public inventoryData: any = []
  public isData: boolean = true;
  public isStockAvailable: boolean = false;
  public totalrow: any;
  public partyPageSize: number = 5;
  public currentPageNo: number = 0;
  public productId: any;
  public selectedProctData: any;
  public prodPrice: any;
  public pinDetails: any;
  public isLessPrice: boolean = false;
  public isHighPrice: boolean = false;
  public isHighPriceMin: boolean = false;
  public submitted: boolean = false;
  public checked: any;
  public notchecked: any;
 
  public currentUser = JSON.parse(this.localStorage.getItem('currentUser'));
  public wishlistForm = this.formBuilder.group({
    budgetMax: ["", [Validators.required]],
    budgetMin: ["", [Validators.required]],
    pincode: ["", [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],
    location: ["", [Validators.required]],
    publish: [false,]
  })
  public todaysDate = this.commonService.getTodaysDate();
  constructor( private route: ActivatedRoute,private spinner: NgxSpinnerService,
    private titleCasePipe: TitleCasePipe,
    private productService: ProductService,
    private remoteShopService: RemoteShopService,
    private toastService: ToastNotificationService,

    private notificationService: ToastNotificationService,
    private toastNotificationService: ToastNotificationService,private formBuilder: FormBuilder,private modal: NgbModal,
    @Inject("LOCALSTORAGE") private localStorage: Storage,private router: Router, private commonService: CommonService,
    private biddingService: BiddingService,public profileService: ProfileService,) { }
 
  ngOnInit(): void {
    this.route.paramMap.subscribe((p) => {
      this.clientId = p.get("id");
      if (this.clientId != null) {
       this.getGoodsById();
      }
    });
    this.getCartCount();
    this.getWishlistsByUserId();
  }
  getGoodsById(){
    this.productService.getGoodsById({}, this.clientId).then((res) => {
      if(res){
        this.inventoryData= res.data; 
      }
    })
  }
  get f() {
    return this.wishlistForm.controls;
  }
  public showPopup: boolean = false
  addProductToCart(item, changePath){
    this.cartArray.push(item)
    this.addToCart(item, changePath);
  }
  async addToCart(element, changePath) {
    console.log("ELE<ENT",element)
    let data = {
      "customerId": this.currentUser.id,
      "clientId": this.clientId,
      "barcode": this.inventoryData.barcode,
      "quantity": 1,
    }
    await this.remoteShopService.postAddToCart(data).then((res) => {
      this.toastService.toastMsg({
        title: "Success",
        content: "Product Added Successfully!",
      })
      // this.toastNotificationService.openSnackBar("Product Added Successfully!!!");
      // this.getCustomerCartItems();
      if (changePath == 'true') {
        this.router.navigate(["/pages/customer/my-cart"])
      }
      this.getCartCount();
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }
  getCartCount() {
    this.remoteShopService.getCartCount({}, this.currentUser.id).then((res) => {
      this.commonService.sendData(res.data.count)
    }, (err) => {
      if (err.error.status === 500) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }
  addBidModal(prodId, ProductName, price, modalName) {
    this.productId = this.clientId ;
    this.selectedProctData = this.inventoryData.itemName;
    this.prodPrice = this.inventoryData.salePrice;
    this.modal.open(modalName, { size: "md", centered: true, backdrop: "static", });
  }
  getPincodeDetails(e) {
    this.profileService.getUserPincode({}, e).then((res) => {
      this.pinDetails = res;
      this.wishlistForm.patchValue({
        'location': this.pinDetails.data.stateName,
      })
    }, (err) => {
      if (err.error.status === 500) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  getMinBudget() {
    if (this.wishlistForm.controls.budgetMin.value > this.prodPrice) {
      this.isLessPrice = true;
    } else {
      this.isLessPrice = false;
    }
  }

  getMaxBudget() {
    if (this.wishlistForm.controls.budgetMax.value > this.prodPrice) {
      this.isHighPrice = true;
    } else if (this.wishlistForm.controls.budgetMax.value < this.wishlistForm.controls.budgetMin.value) {
      this.isHighPriceMin = true;
    }
    else {
      this.isHighPrice = false;
      this.isHighPriceMin = false;
    }
  }
  postwishlistBid(name) {
    this.submitted = true;
    if (this.wishlistForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
        });
      // this.notificationService.openErrorSnackBar("Please Fill Mandatory Fields!!!")
    } else if (this.isLessPrice || this.isHighPrice || this.isHighPriceMin) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
        });
      // this.notificationService.openErrorSnackBar("Please Fill Mandatory Fields!!!")
    }
    else {
      let data = {
        "userId": this.currentUser.id,
        "publish": this.wishlistForm.controls.publish.value,
        "productId": this.productId,
        "budgetMax": this.wishlistForm.controls.budgetMax.value,
        "location": this.wishlistForm.controls.location.value,
        "pincode": this.wishlistForm.controls.pincode.value,
        "approxCompletedDate": this.todaysDate,
        "budgetMin": this.wishlistForm.controls.budgetMin.value,
      }
      this.biddingService.postwishlistBid(data).then((res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Bid Request Submitted Successfully!",
          })
          // this.notificationService.openSnackBar("Bid Request Submitted Successfully!!!");
          this.getWishlistsByUserId();
          this.modal.dismissAll();
          this.modal.open(name, { size: "sm", centered: true });
        }
      }, (err) => {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      })
    }
  }
  modalDismiss() {
    this.modal.dismissAll();
    this.wishlistForm.reset();
  }
  getWishlistsByUserId() {
    this.biddingService.getWishlistsByUserId({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      localStorage.setItem("BidData", JSON.stringify({
        data: res.data,
      }))
    }, (err) => {
      if (err.error.status === 500) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  } 
}
