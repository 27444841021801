import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { BsDaterangepickerConfig, MonthPickerComponent } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PartyReportsService } from 'src/app/core/services/party-reports.service';
import { PartyService } from 'src/app/core/services/party.service';
import { PurchaseInvoiceService } from 'src/app/core/services/purchase-invoice.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import * as XLSX from 'xlsx';
interface IRange {
  value: Date[];
  label: string;

}
@Component({
  selector: 'app-party-statement',
  templateUrl: './party-statement.component.html',
  styleUrls: ['./party-statement.component.scss']
})
export class PartyStatementComponent implements OnInit {
  public quarterStartDate: any;
  public quarterEndDate: any;
  public today: Date;
  public quarter;
  public maxDate: Date;
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  public partyName = new FormControl();
  public partyId: any;
  public allPartyList: any = [];
  public partyList: any;

  public partyData: any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public filteredOptions: Observable<string[]>;
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public submitted: boolean = false;
  public supplytype: any;
  public userProfileGSTNo: any;
  public gstStateMatch: boolean = true;
  public purchaseGst: any;
  public myControl = new FormControl();
  public dateRange: Date[]
  public startOfDateRange: any;
  public endOfDateRange: any;
  public search: any;
  public searchPartyName: any;
  public searchpartyList: any = [];
  fileName = 'Vendor Statement.xlsx';
  // public salepurchaseList: any = [];
  public Ref :any;
  public valuetotal: any='Equal';
  public total: any = 0;

  public valueReceivedPaid: any='Equal';
  public totalvalueReceivedPaid: any = 0;

  public valueTXN: any='Equal';
  public totalTXN: any = 0;

  public valuereceiveBalance: any='Equal';
  public totalreceiveBalance: any = 0;

  public valuePayable: any='Equal';
  public totalPayable: any = 0;
  public type: any = 'CASH';
  public txnType = 'SALES';

  constructor(private purchaseInvoiceService: PurchaseInvoiceService,
    private partyService: PartyService,
    private saleInvoiceService: SaleInvoiceService,
    private partyreportsService: PartyReportsService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    let quarter = moment(this.today).utc().quarter() //outputs 4
    this.getQuarterRange();
  }

  ngOnInit(): void {
    this.getAllParties();
    // this.getpartystmt();
    this.ranges = [

      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      {
        value: [this.quarterStartDate._d, this.quarterEndDate._d],
        label: ' Last Quarter'
      },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear(), 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];
    this.dateRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]

    for (let i = 0; i < this.ranges.length; i++) {
      if (this.ranges[i].label == "Last Quarter") {
      }
    }
  }
  getReceiptById() {

  }
  
  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  getAllParties() {
    this.partyService.getAllPartiesDetails({}, this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1)).then((res) => {
        this.allPartyList = res.data.pageData;
      });
  }

  searchPartyByName1(search){
     this.partyService.getAllPartiesDetailsSearch({}, this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1),search).then((res) => {
      this.allPartyList = res.data.pageData;
      if(res.data.pageData.length === 0){
        this.toastService.toastMsg({
          title: "Error",
          content: "Vendor Name Not Found",
        });
        //this.getAllParties();
      }
    });
  }

  setSelectedPartyDeatails(event,item) {
    if (event.isUserInput) {
      this.partyId = item.id;
      this.partyreportsService.getPartyByStatement({},this.currentUser.id ,this.partyId, moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD'), moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD')).then((res) => {
        this.searchpartyList = res.data;
      })
    }
  }

  onselectxnType(value){
    this.txnType = value;
  }

  applytxnType(){
    this.partyreportsService.getPartyfiltertxnType({},this.currentUser.id ,this.partyId, this.txnType).then((res) => {
      this.searchpartyList = res.data;
    })
  }

  onselectPayment(value){
    this.type = value;
  }

  applyPayment(){
    this.partyreportsService.getPartyfilterpayment({},this.currentUser.id ,this.partyId, this.type).then((res) => {
      this.searchpartyList = res.data;
    })
  }

  applyRef(){
    this.partyreportsService.getPartyfilterRef({},this.currentUser.id ,this.partyId, this.Ref).then((res) => {
      this.searchpartyList = res.data;
    })
  }

  onselectotal(value){
    this.valuetotal = value;
  }
  applytotal(){
    if (this.valuetotal === 'Greater' || this.valuetotal === 'Equal' || this.valuetotal === 'Less') {
      this.partyreportsService.getPartyfiltertotal({},this.currentUser.id ,this.partyId, this.valuetotal,this.total).then((res) => {
        this.searchpartyList = res.data;
      })
    }
  }
 
  onselectvalueReceivedPaid(value){
    this.valueReceivedPaid = value;
  }

  applyReceivedPaid(){
    if (this.valueReceivedPaid === 'Greater' || this.valueReceivedPaid === 'Equal' || this.valueReceivedPaid === 'Less') {
      this.partyreportsService.getPartyfiltereceivedpaid({},this.currentUser.id ,this.partyId, this.valueReceivedPaid,this.totalvalueReceivedPaid).then((res) => {
        this.searchpartyList = res.data;
      })
    }
  }

  onselectTXN(value){
    this.valueTXN = value;
  }

  applyTXN(){
    if (this.valueTXN === 'Greater' || this.valueTXN === 'Equal' || this.valueTXN === 'Less') {
      this.partyreportsService.getPartyfilterTXN({},this.currentUser.id ,this.partyId, this.valueTXN,this.totalTXN).then((res) => {
        this.searchpartyList = res.data;
      })
    }
  }

  onselectreceiveBalance(value){
    this.valuereceiveBalance = value;
  }

  applyreceiveBalance(){
    if (this.valuereceiveBalance === 'Greater' || this.valuereceiveBalance === 'Equal' || this.valuereceiveBalance === 'Less') {
      this.partyreportsService.getPartyreceivedbalance({},this.currentUser.id ,this.partyId, this.valuereceiveBalance,this.totalreceiveBalance).then((res) => {
        this.searchpartyList = res.data;
      })
    }
  }
  
  onselectPayable(value){
    this.valuePayable = value;
  }

  applyPayable(){
    if (this.valuePayable === 'Greater' || this.valuePayable === 'Equal' || this.valuePayable === 'Less') {
      this.partyreportsService.getPartyfilterpayable({},this.currentUser.id ,this.partyId, this.valuePayable,this.totalPayable).then((res) => {
        this.searchpartyList = res.data;
      })
    }
  }


  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;

  }
  getQuarterRange() {
    this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
    this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  }

  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  onPrint() {
    const elementsToHide = ['headingPrintHide', 'dateRangePrintHide', 'searchBarHide'];

    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = 'none';
      }
    });

    // Print
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
    // Restore hidden elements after printing
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = '';
      }
    });
  }
  
  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }
}



