import { TitleCasePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTabBody } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { PartGroupServiceService } from "src/app/core/services/part-group-service.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { PartyService } from "src/app/core/services/party.service";

@Component({
  selector: "app-party-group-add",
  templateUrl: "./party-group-add.component.html",
  styleUrls: ["./party-group-add.component.scss"],
})
export class PartyGroupAddComponent implements OnInit {
  public PartyGroupForm: FormGroup;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  submitted: boolean;
  constructor(
    private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PartyGroupAddComponent>,
    public toastService: ToastNotificationService, public partyService: PartyService,
    private titleCasePipe: TitleCasePipe,
    private partyGroupservice: PartGroupServiceService,
    public router: Router
  ) {}
  ngOnInit(): void {
    this.getPartyGroupForm();
  }
  getPartyGroupForm() {
    this.PartyGroupForm = this.formBuilder.group({
      groupDescription: [""],
      groupName: [""],
    });
  }
  get f() {
    return this.PartyGroupForm.controls;
  }
  PostPartyGroup() {
    this.submitted = true;
    let body = {
      groups: {
        userId: this.currentUser.id,
        groupDescription: this.PartyGroupForm.controls.groupDescription.value,
        groupName: this.PartyGroupForm.controls.groupName.value,
      },
    };
    if (this.PartyGroupForm.controls.groupDescription.value == "" || 
        this.PartyGroupForm.controls.groupName.value == "") {
          this.toastService.toastMsg({
            title: "Error",
            content: "Fill All Required Fields.",
          });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    }
    if (this.PartyGroupForm.valid) {
      this.partyGroupservice.postsinglepartyGroup(body).then(
        (res:any) => {
          if (res.success) {
            this.toastService.toastMsg({
              title: "Success",
              content: "Group Record Added Successfully!",
            });
            // this.toastService.openSnackBar("Group Record Added Successfully");
            this.router.navigate(["pages/party-page/party-group-list"]);
            this.partyService.notifyPartyAdded();
            this.dialogRef.close();
            this.submitted = true;
          }
        },
        (err) => {
          if (err.error.expose) {
            // this.toastService.openErrorSnackBar(
            //   this.titleCasePipe.transform(err.error.error_message)
            // );
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message)
            });
          } else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
        }
      );
    }
  }

  cancel() {
    this.PartyGroupForm.reset();
    this.submitted = false;
    this.dialogRef.close();

  }
}
