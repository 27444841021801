import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { PartyReportsService } from 'src/app/core/services/party-reports.service';
import { map, startWith } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ScheduleShopService } from 'src/app/core/services/schedule-shop.service';
import { WarehouseService } from 'src/app/core/services/warehouse.service';


@Component({
  selector: 'app-item-batch-report',
  templateUrl: './item-batch-report.component.html',
  styleUrls: ['./item-batch-report.component.scss']
})
export class ItemBatchReportComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public totalPages: any;
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public goodsData = [];
  public searchedProduct = [];
  public myControl = new FormControl();
  public prodName: string;
  public prodId: string;
  public filteredOptions: Observable<string[]>
  public fileName = 'Item Batch Report.xlsx';
  public partyList: any = []
  public productId: any;
  itemList: any = [];
  selectedItem: any;
  shopList: any[] = [];
  warehouseList: any[] = [];
  warehouse: any;
  currentWarehouseId: any;
  public serviceData: any = [];
  isLoading: boolean;
  public selectedPartyIds: any[] = [];
  public allSelect: boolean = false;
  public checkSelect: boolean = false;
  public isProductNotFound: boolean;
  currentWarehouseSelected: boolean = false;
  public toatlPages: any;
  selectedType: string = '';  // To keep track of the selected type ('shop' or 'warehouse')
  shop: null;

  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  constructor(private productService: ProductService,
    private partyservice: PartyReportsService,
    private toastService: ToastNotificationService,
    private biddingService: BiddingService,
    private titleCasePipe: TitleCasePipe,private scheduleShopService: ScheduleShopService,private warehouseService: WarehouseService,) { }

  ngOnInit(): void {
    this.getProductReport();
    // this.getGoodsbyUser();
    if(this.currentUser.userType === 'SHOP') {
      this.getWarehouseList();
    }
    
    this.productService.productAdded$.subscribe(() => {
      // Fetch the updated list of users
      if (this.selectedType === 'Shop') {
        const selectedShopId = this.selectedItem.id;
        this.currentWarehouseId = selectedShopId;
        
        this.getGoodsbyUser(selectedShopId)
      } else if (this.selectedType === 'Warehouse') {
        const selectedWarehouseId = this.selectedItem.id;
         this.currentWarehouseId = selectedWarehouseId;
         this.getProductReport()
        //this.getGoodsbyUser(selectedWarehouseId)
        } else if(this.selectedType === 'clear') {
        this.currentWarehouseId = this.currentUser.id;
        this.clearSelection();
      } else {
        this.getGoodsbyUser();
      }
    });

  }

  getProductReport(currentWarehouseId?) {
    this.productService.getProductRepolist({},this.currentWarehouseId ? this.currentWarehouseId : this.currentUser.id,this.partyPageSize, (this.currentPageNo + 1), this.currentWarehouseId ? this.currentWarehouseId : this.currentUser.id,
  ).then((res) => {
      this.itemList = res.data.pageData;
      // this.ItemListStock=res.data.pageData.central_invs
      this.p = res.data.currentpage;
      this.totalrow = res.data.totalrows
      this.totalPages = res.data.totalPages
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
        }
      })
  }


  getGoodsbyUser(warehouseId?) {
    setTimeout(() => {
      // .getGoodsProductByUser_Type({}, warehouseId ? warehouseId : this.currentUser.id, 'GOODS', "DESC", this.partyPageSize, (this.currentPageNo + 1), this.currentWarehouseId ? this.currentWarehouseId : this.currentUser.id)
      this.productService.getGoodsProductByUser_Type({}, warehouseId ? warehouseId : this.currentUser.id, 'GOODS', "DESC", this.partyPageSize, (this.currentPageNo + 1), warehouseId ? warehouseId : this.currentUser.id)
      .then((res) => {
          this.serviceData = []
            if(res.success){
              this.p = res.data.currentPage;
              this.goodsData = res.data.pageData;
              const filteredgoodsData: string[] = this.goodsData.map(item => item.id);
              const result = this.arraysHaveSameElements(filteredgoodsData, this.selectedPartyIds);
              this.allSelect = !!result;
              this.isProductNotFound = false;
              this.totalrow = res.data.totalrows;
              this.toatlPages = res.data.totalPages
            }
          },
          (err) => {
            if (err.error.status == 404) {
              this.goodsData = [];
              this.isProductNotFound = true;
            }
          }
        );
        this.isLoading = false; // Hide the loader after the API call is completed
    }, 500);
    this.getItemBYProductname();
    this.getItemBYProductname()
    this.getProductReport()
  }


  private filterNames(name: string): string[] {
    return this.goodsData.filter(goods =>
      goods.itemName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  searchProduct() {
    this.biddingService.getAllProductsByUserSearch({}, this.currentWarehouseId ? this.currentWarehouseId : this.currentUser.id, this.myControl.value, this.partyPageSize, (this.currentPageNo + 1),this.currentWarehouseId ? this.currentWarehouseId : this.currentUser.id)
      .then((res) => {
        this.prodName = res.data.pageData.itemName
        this.totalrow = res.data.totalrows;
        this.totalPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;

        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.itemList.slice())
          );
      }
        ,
        (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
              });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
              });
          }
        })
  }


  selectedProduct(itemName) {
    console.log("itemname", itemName)
    this.biddingService.getAllProductsByUserSearch({},this.currentWarehouseId ? this.currentWarehouseId : this.currentUser.id, itemName, this.partyPageSize, (this.currentPageNo + 1),this.currentWarehouseId ? this.currentWarehouseId : this.currentUser.id)
      .then((res) => {
        this.totalPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
        }
      })
    this.prodName = itemName
    this.getItemBYProductname()
    this.getProductReport()
  }

  getItemBYProductname() {
    this.productService.getItemBYProductname({}, this.currentWarehouseId ? this.currentWarehouseId : this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1), this.prodName?this.prodName : null , this.currentWarehouseId ? this.currentWarehouseId : this.currentUser.id, ).then((res: any) => {
      this.itemList = res.data.pageData;
      this.p = res.data.currentPage;
      this.totalrow = res.data.totalrows;
      this.totalPages = res.data.totalPages
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong",
          });
      }
    })
  }
  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  onPrint() {
    const elementsToHide = ['headingPrintHide', 'searchBarHide','headingPrintHide1'];
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = 'none';
      }
    });
    // Print
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
    // Restore hidden elements after printing
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = '';
      }
    });
  }

  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }

  public handlePage(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.itemList.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getProductReport();
    }
  }
  onTypeChange() {
    // Reset the selected warehouse/shop when the type changes
    this.warehouse = null;
    this.shop = null;
    if (this.selectedType === 'Shop') {
      this.getShopList();
    } else if (this.selectedType === 'Warehouse') {
      this.getWarehouseList();
    } else if(this.selectedType === 'clear') {
      this.clearSelection();
    }
  }

  clearSelection() {
    // Reset selected item and any related data
    this.selectedItem = null;
    this.selectedType = null;
    this.currentWarehouseSelected = false;
    this.getGoodsbyUser(this.currentUser.id);
    this.getProductReport(this.warehouse)
  }

  onSelectionChange() {
    if (this.selectedItem) {
      if (this.selectedType === 'Shop') {
        this.currentWarehouseId = this.selectedItem.id;
        this.getGoodsbyUser(this.currentWarehouseId)
      } else if (this.selectedType === 'Warehouse') {
        this.currentWarehouseSelected = true;
        this.currentWarehouseId = this.selectedItem.id;
        this.getGoodsbyUser(this.currentWarehouseId)
      } else if(this.selectedType === 'clear') {
        this.currentWarehouseSelected = false;
        this.clearSelection();
      }
    } else if(this.warehouse) {
      if(this.warehouse === 'clear') {
        this.clearSelection();
      } else {
        this.getProductReport(this.warehouse)
      }
    }
  }
  getWarehouseList(selectedWarehouseId?) {
    this.warehouseService.getAllWarehouse({
      data:{},
      userId: selectedWarehouseId ? selectedWarehouseId : (this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id),
      size: 1000,
      page: 1,
      search:'',
    }).then((res: any) => {
      if(res.success) {
        this.warehouseList = res.data.pageData;
        // if(this.currentUser.userType === 'CLIENT'){
        //   this.currentWarehouseId = this.selectedItem?.id
        //   console.log('In client', this.selectedItem)
        // } else if(this.currentUser.userType === 'SHOP'){
        //   this.currentWarehouseId = this.warehouse
        //   console.log('In shop', this.warehouse)
        // } else {
        //   this.currentWarehouseId = this.currentUser.id
        // }
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  getShopList(selectedShopId?) {
    this.scheduleShopService.getShopList({}, selectedShopId ? selectedShopId : (this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id)) .then((res: any) => {
      if(res.success) {
        this.shopList = res.data.rows;
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }
  
  arraysHaveSameElements = (array1: string[], array2: string[]): boolean => {
    // Convert arrays to sets
    const set1 = new Set(array1);
    const set2 = new Set(array2);

    // Compare sizes of sets
    if (set1.size !== set2.size) {
        return false;
    }

    // Compare content of sets
    for (const item of set1) {
        if (!set2.has(item)) {
            return false;
        }
    }

    return true;
  };



}
