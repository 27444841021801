<!-- <div class="tab-content border-x border-bottom" id="myTabContent">
    <div class="tab-pane fade show active" id="tab-home" role="tabpanel" aria-labelledby="home-tab">
        <div class="bg-white">
            <div class="bg-white scroll">
                <div class="row justify-content-end m-2">
                    <div></div>
                    <div class="search-box">
                        <form class="position-relative">
                            <input class="form-control search-input fuzzy-search" type="search"
                                placeholder="Search..." />
                            <span class="fas fa-search search-box-icon"></span>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 m-3 card_radius" *ngFor="let i of allRetailers">
                        <div class="card shadow bg-white pointer-event-cursor"
                            routerLink="/pages/customer/viewProduct/{{i.id}}">
                            <img class="card-img-top retailerCard" src="/assets/images/960x0.jpg">
                            <div class="card-body card_height">
                                <h3 class="card-text m-0">
                                    {{ i.firstName + " " + i.lastName | titlecase }}
                                </h3>
                                <p class="card-text m-0">
                                    Appliances,Clothing,Electronics
                                </p>
                                <p class="card-text m-0">
                                    Delhi,India
                                    <span>
                                        <button class="btn btn-light float-right p-0 pointer-event-cursor"
                                            title="Delivery Service" style="background-color: none;">
                                            <i class="fas fa-truck"></i>
                                        </button>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="content">
    <div class="card mb-3">
        <div class="card-header bg-light pt-3 pb-3">
            <p class="ad-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
              All Retailers
            </p>
        </div>
        <div class="progress" style="height: 2px">
            <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>
        <div class="card-body">
                <div class="row  justify-content-end">
                <div class="flex-item searchBar">
                  <input type="search" id="searchInput" class="search-form-control pe-5 my-2" placeholder="Trade Name"
                  [(ngModel)]="searchKey" (input)="onSearchInputChange($event.target.value)">
              </div>
            </div>
            <div class="row min-vh-50 flex-center g-0" *ngIf="!isData">
               <h4 class="text-center">No Data Found.</h4>
            </div>
            <div class="row" *ngIf="allRetailers.length > 0">
                <div class="mb-2 col-md-6 col-lg-3 mb-3 pointer-event-cursor" *ngFor="let item of allRetailers" routerLink="/pages/customer/viewProduct/{{item.id}}">
                    <div class="border rounded-1 h-100 d-flex flex-column justify-content-between pb-3">
                        <div class="overflow-hidden">
                            <div class="position-relative rounded-top overflow-hidden"><a class="d-block">
                                    <img class="img-fluid rounded-top imgFormat" [src]="item.profileImage == null ? '/assets/images/960x0.jpg' :item.profileImage " alt="" /></a>
                            </div>
                            <div class="p-3">
                                <h5 class="fs-0"><a class="text-dark">{{ item.client?.tradeName == null ? '' : item.client?.tradeName | titlecase}}</a></h5>

                                <p class="fs--1 mb-1"><a class="text-500">Appliances, Clothing, Electronics, Fertilizer, Pesticide, Others</a></p>

                                
                                <h5 class="fs-md-2 text-warning mb-0 d-flex align-items-center mb-3">
                                    {{ item.price | currency:"INR":"symbol":'1.0-0'}}
                                </h5>
                                <div class="justify-content-between">
                                    <p class="fs--1 mb-1" *ngFor="let itemaddreess of  item.useraddresses">
                                        <strong class="text-success">{{itemaddreess.city | titlecase}} ,{{itemaddreess.state | titlecase}} , {{itemaddreess.country | titlecase}}   </strong> 
                                    </p>
                                    <div>
                                        <button class="btn btn-light float-right p-0 pointer-event-cursor"
                                            title="Delivery Service" style="background-color: none;">
                                            <i class="fas fa-truck"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="card-footer bg-light d-flex justify-content-center" *ngIf="allRetailers.length > 0">
          
        </div> -->
    </div>
    <div class="card" *ngIf="allRetailers.length <= 0">
        <!-- <div class="row min-vh-50 flex-center g-0" > -->
            <!-- <h4 class="text-center">No Products to show....</h4> -->
            <ngx-spinner bdOpacity="1" bdColor="rgba(97,197,212,0.07)" size="medium" color="#0047AB"
                type="ball-spin-clockwise" fullScreen="true">
                <p style="color: #61c5d4;">Loading..</p>
            </ngx-spinner>
        <!-- </div> -->
    </div> 
</div>