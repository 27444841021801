import { TitleCasePipe } from '@angular/common';
import lang from "src/assets/langTranslation/language_translation"
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { EstimationService } from 'src/app/core/services/estimation.service';
import { PartyService } from 'src/app/core/services/party.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { FormArray, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WarehouseService } from 'src/app/core/services/warehouse.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import moment from 'moment';

@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss']
})

export class EstimateComponent implements OnInit {

  saveAsDraft: boolean = false;
  public lang = lang.UAE;
  public gstAmount = 0;
  public unitValue: any;
  public index: any;
  public product: any;
  public amount: any;
  public qty: any;
  public unit: any;
  public wtax: any;
  public wotax: any;
  public ramount: any;
  public allPartyList: any = [];
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  filteredOptions: Observable<string[]>
  public myControl = new FormControl();
  public myControl1 = new FormControl();
  searchedProduct: any;
  productOptions: Observable<any>;
  public partyId: any;
  public partyData: any;
  public todaysDate;
  public isShown: boolean = false;
  public subTotal = 0;
  public totalGST = 0
  public total = 0;
  public additionalCess: number = 0;
  public productData: any = [];
  public estimateInvoiceForm: any;
  public gstType: any;
  public gstInRs: any
  public gstInPer: any;
  public compositeType: any;
  public today: Date;
  public maxDate: Date;
  public submitted: boolean = false;
  public barcode: any;
  public sendArray: any = [];
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public userProfileGSTNo: any;
  public supplytype: any;
  public esitimateGst: any;
  public gstStateMatch: boolean = true;
  public totalPrice: any;
  public gstRate = 0;
  warehouseList : any[];
  draftId: any;

  constructor(private partyService: PartyService,
    public dialogRef: MatDialogRef<EstimateComponent>,
    @Inject(MAT_DIALOG_DATA) public estimationData: any,
    private saleInvoiceService: SaleInvoiceService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private estimaetionService: EstimationService,
    private toastService: ToastNotificationService,
    private profileService: ProfileService,
    private titleCasePipe: TitleCasePipe,
    private biddingService: BiddingService,
    public warehouseService : WarehouseService,
    private sharedDataService: SharedDataService,
    private router: Router) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }

  ngOnInit(): void {
    this.estimateInvoiceForm = this.formBuilder.group({
      receiptType: [""],
      partyName: [""],
      invoiceNo: [""],
      invoiceDate: ["", new Date(),[Validators.required]],
      barcode: [""],
      totalBillAmount: [""],
      billGstAmount: [""],
      credit: ["false"],
      gstRate: [""],
      billingAddress: ["", [Validators.required]],
      referenceNumber: ["", [Validators.required]],
      warehouse: [null],
    });

    this.getAllParties();
    this.getProfileData();
    this.getWarehouseList();
    this.todaysDate = new Date();
    this.newAttribute = { unit: "", ramount: "", wtax: "", wotax: "", index: "", product: "", gty: "", select: "" };
    this.fieldArray.push(this.newAttribute);

    console.log("estimation data -- ",this.estimationData);

    if (this.estimationData && this.estimationData.draftData?.id) {
      this.setEstimateToSaleForm(this.estimationData)
    }
  }

  get f() {
    return this.estimateInvoiceForm.controls;
  }

  Units = Constants.Units

  getUnit(value: string) {
    this.unitValue = "1 " + value;
  }

  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.userProfileGSTNo = res.data.additional.gstinNo ? res.data.additional.gstinNo.slice(0, 2) : "";
    }))
  }

  getAllParties() {
    this.partyService
      .getAllPartiesDetails({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
      .then((res) => {
        this.allPartyList = res.data.pageData;
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
      });
  }

  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  setEstimateToSaleForm(estimationData: any) {
    this.saleInvoiceService.getSaleInvoiceById({}, this.estimationData.draftData.id).then((saleInvoiceData: any) => {
      if(saleInvoiceData.success){
        for (let index = 0; index < saleInvoiceData.data.sales_products.length; index++) {
          const element = saleInvoiceData.data.sales_products[index];
          this.productData.push({
            basePrice: element.pricePerUnit,
            salePrice: element.totalPrice,
            gstRate: element.gst,
            ...element
          })
        }
        this.myControl.patchValue(estimationData.draftData.customerName);
        this.draftId = estimationData.draftData?.id

        console.log("Inside this.estimationData.draftData.id", estimationData.draftData);
        console.log("estimationData.draftData.credit", estimationData.draftData.credit)
        console.log("estimationData.draftData.invoice", estimationData.draftData?.invoiceNo)
        
        this.estimateInvoiceForm.patchValue({
          // gstNo: saleInvoiceData.data.party.gstIn,
          // billingAddress: saleInvoiceData.data.party.billingAddress,
          // shippedFrom: saleInvoiceData.data.party.shippingAddress,
          // ewayBillNo: saleInvoiceData.data.party.ewayBillNo,
          // credit: saleInvoiceData.data?.credit,
          // cashSale: saleInvoiceData.data.cashSale ? true : false,
          // reverseCharge: saleInvoiceData.data.reverseCharge ? true : false,
          referenceNumber: estimationData.draftData?.invoiceNo,
          invoiceDate: moment(estimationData.draftData.invoiceDate).format("DD MMM YYYY"),
          receiptType: estimationData.draftData?.receiptType,
          billingAddress: estimationData.draftData?.billingAddress,
          shippingAddress: estimationData.draftData?.shippingAddress,
          ewayBillNo: estimationData.draftData?.ewayBillNo,
          reverseCharge: estimationData.draftData?.reverseCharge,
          counterDiscount: estimationData.draftData?.counterDiscountAmount,
          sendWhatsappMsg: estimationData.draftData?.isWhatsapp || true,
          gstNo: estimationData.draftData?.party?.gstIn,

        });
        if (this.estimationData && this.estimationData.item && this.estimationData.item.vendor) {
          this.myControl.patchValue(this.estimationData.item.vendor.partyName);
        }

        // this.myControl.patchValue(this.estimationData.item.party.partyName)
        this.setSelectedPartyDeatails({
          id: saleInvoiceData.data.partyId,
          partyName: saleInvoiceData.data.party.partyName
        })

        for (let i = 0; i <= this.productData.length; i++) {
          this.addDetailsButtonClick(i);
        }
        this.calculationTotal();
      }
    });
  }

  searchPartyByName(item) {
    this.saleInvoiceService.getPartyByName({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item)
      .then((response: any) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.allPartyList.slice())
            );
        }
      });
  }

  setSelectedPartyDeatails(item) {
    this.partyId = item.id;
    if (this.partyData) {
      this.partyData.forEach((ele) => {
        this.esitimateGst = ele.gstIn
        this.estimateInvoiceForm.patchValue({
          'billingAddress': ele.billingAddress
        })
        if (this.esitimateGst.slice(0, 2) === this.userProfileGSTNo) {
          this.gstStateMatch = true
          this.supplytype = "INTRASTATE"
        }
        else {
          this.gstStateMatch = false;
          this.supplytype = "INTERSTATE"
        }
      })

    }
    else {
      this.saleInvoiceService.getPartyByName({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item.partyName)
        .then((response: any) => {
          this.partyData = response.data.rows;
          this.allPartyList = response.data.rows;
          this.allPartyList.forEach((ele) => {
            this.esitimateGst = ele.gstIn
            this.estimateInvoiceForm.patchValue({
              'billingAddress': ele.billingAddress
            })
            if (this.esitimateGst.slice(0, 2) === this.userProfileGSTNo) {
              this.gstStateMatch = true
              this.supplytype = "INTRASTATE"
            }
            else {
              this.gstStateMatch = false;
              this.supplytype = "INTERSTATE"
            }
          });
        })
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(name => name ? this.filterNames(name) : this.allPartyList.slice())
      );
    }
  }

  addFieldValue() {
    this.fieldArray.push(this.newAttribute)
    this.newAttribute = {};
  }

  deleteFieldValue(index: number) {
    this.fieldArray.splice(index, 1);
  }

  toggleShow() {
    this.isShown = !this.isShown;
    if (this.isShown) {
      this.total = this.subTotal + this.totalGST + this.additionalCess;
    } else {
      this.total = this.subTotal + this.totalGST;
    }
  }

  /**
   * calculation total for product
   */
  async calculationTotal() {
    this.barcode = "";
    let price = 0;
    let additionalCess = 0;
    let gstInPer = 0;
    this.totalPrice = 0;
    this.subTotal = 0;
    this.gstRate = 0;
    this.productData.forEach((element) => {
      let gstInPer = element.gstRate;
      price = price + (element.salePrice * element.quantity);
      this.gstRate = ((element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity) + this.gstRate
      this.gstAmount = (element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity
      additionalCess = element.salePrice * element.additionalCess / 100;

      this.subTotal = (element.basePrice * element.quantity) + this.subTotal;

      element.subTotal = element.basePrice * element.quantity;
      element.gstAmount = (element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity
      element.total = element.salePrice * element.quantity;

      gstInPer = element.gstRate;
      if (this.isShown) {
        this.totalPrice = price;
      } else {
        this.totalPrice = price;
      }
    });

    if (this.gstType === 'Exempted') {
      this.totalGST = 0;
      this.gstInRs = 0;
      this.gstInPer = 0
    } else if (this.gstType === 'Composite') {
      if (this.compositeType == 'Manufacturer' || this.compositeType == 'Trader') {
        this.totalGST = this.subTotal * 1 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else if (this.compositeType == 'Restaurant') {
        this.totalGST = this.subTotal * 5 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else {
        this.totalGST = this.subTotal * 6 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      }
    } else {
      this.totalGST = this.gstRate;
      this.gstInRs = this.gstRate;
      this.gstInPer = gstInPer;
    }

    this.additionalCess = additionalCess
    if (this.isShown) {
      this.total = price;
    } else {
      this.total = price;
    }
  }

  /**
 * calculation for quantity change
 * @param i
 */
  changeQtyCalculation(i) {
    this.totalGST = (this.productData[i].salePrice * this.productData[i].quantity) * (this.productData[i].gstRate / 100);
    this.subTotal = (this.productData[i].salePrice * this.productData[i].quantity);
    this.total = this.totalGST + this.subTotal;
    this.calculationTotal();
  }

  /**
  * quantity change method
  * @param sign
  * @param i
  */
  changeQuantity(sign, i, quantity = 0) {
    switch (sign) {
      case "-":
        if (this.productData[i].quantity > 1) {
          this.productData[i].quantity = this.productData[i].quantity - 1;
        }
        // this.changeQtyCalculation(i);
        this.calculationTotal();
        break;
      case "+":
        this.productData[i].quantity = this.productData[i].quantity + 1;
        // this.changeQtyCalculation(i);
        this.calculationTotal();
        break;
      case "rm":
        this.productData.splice(i, 1);
        if (this.productData.length <= 0) {
          this.totalGST = 0;
          this.subTotal = 0;
          this.total = 0;
        }
        this.calculationTotal();
        break;
      case "qty":

        this.productData[i].quantity = quantity;
        this.calculationTotal();
        break;
      default:
        this.productData[i];
        break;
    }
  }

  onSearchInputChange(searchKey: string) {
    if (searchKey.length >= 3) {
      this.getGoodsList(searchKey);
    }
  }

  getGoodsList(value) {
    if (value && (String(value).trim() !== '' || value !== null)) {
      this.biddingService.getAllProductsByUserSearch({}, this.estimateInvoiceForm.value.warehouse ? this.estimateInvoiceForm.value.warehouse : this.currentUser.id, value, 1000, 1, this.estimateInvoiceForm.value.warehouse ? this.estimateInvoiceForm.value.warehouse : this.currentUser.id)
        .then((res) => {
          if(res.success) {
            this.searchedProduct = res.data.pageData;
            this.productOptions = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
              );
            }
        },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
            }

          })
    } else {}
  }

  /**
    * get goods by barcode
    */
  // getGoodsByBarcode() {
  //   this.saleInvoiceService.getGoodsByBarcode({}, this.barcode, this.currentUser.id)
  //     .then((response) => {
  //       // Check if the barcode already exists in productData
  //       const existingProductIndex = this.productData.findIndex(
  //         (product) => product.barcode === response.data.barcode
  //       );

  //       if (existingProductIndex !== -1) {
  //         // Barcode exists, update quantity
  //         this.productData[existingProductIndex].quantity += 1;
  //       } else {
  //         // Barcode doesn't exist, add new product
  //         response.data.quantity = 1;
  //         this.productData.push(response.data);
  //       }
  //       this.calculationTotal();
  //     });
  // }

  getGoodsByBarcodeSelected(barcode, event) {
    if (event.isUserInput) {
      this.saleInvoiceService
        .getGoodsByBarcode({}, barcode, this.estimateInvoiceForm.value.warehouse ? this.estimateInvoiceForm.value.warehouse : this.currentUser.id, this.estimateInvoiceForm.value.warehouse ? this.estimateInvoiceForm.value.warehouse : this.currentUser.id)
        .then(async (response) => {
          response.data.quantity = 1;
          // Check if the barcode already exists in productData
          const existingProductIndex = this.productData.findIndex(
            (product) => product.barcode === response.data.barcode
            );

          if (existingProductIndex !== -1) {
            // Barcode exists, update quantity
            this.productData[existingProductIndex].quantity += 1;
            // await this.checkFreeProducts(this.productData)
            // await this.checkOffer(this.productData[existingProductIndex].quantity, this.productData[existingProductIndex].id)
            // this.removeCoupon()
            this.calculationTotal()
          } else {
            // Barcode doesn't exist, add new product
            response.data.quantity = 1;
            this.productData.push(response.data);
            this.addDetailsButtonClick(this.productData.length - 1);
            this.calculationTotal();
            // await this.checkFreeProducts(this.productData)
            // await this.checkOffer(response.data.quantity, response.data.id)
            // this.removeCoupon()
          }

          this.myControl1.patchValue('')
          document.getElementById('inp12').nodeValue = ''
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
    }
  }

  addDetailsButtonClick(i): void {
    const content = this.estimateInvoiceForm.get("subcheckbox") as FormArray;
    if (content !== null && (Array.isArray(content) || typeof content === 'object')) {
    content.push(this.addRolesPermission(i));
    }
  }

  addRolesPermission(id): FormGroup {
    return new FormGroup({
      read: new FormControl(false),
    });
  }

  setSaveAsDraft() {
    this.saveAsDraft = true;
    this.submitted = true;
    if (this.todaysDate == "" || this.estimateInvoiceForm.invalid) {
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    } else {
      if (this.productData.length < 1) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Add At Least One Product To Proceed!",
        });
        // this.toastService.openErrorSnackBar("Please Add At least One Product To Proceed!!!");
        return false;
      }
      this.productData.forEach(e => {
        let data = {
          quantity: e.quantity,
          barcode: e.barcode
        }
        this.sendArray.push(data);
      })

      const userType = this.currentUser.userType.toLowerCase();
      const warehouseIdToSend =
        userType === 'warehouse' ? this.currentUser.id :
        userType === 'shop' ? (this.estimateInvoiceForm.value.warehouse || this.currentUser.id) :
        userType === 'client' && this.estimateInvoiceForm.value.warehouse !== null ? this.estimateInvoiceForm.value.warehouse : this.currentUser.id;
      let data = {
        "invoiceDetails": {
          "receiptType": this.estimateInvoiceForm.controls.receiptType.value,
          "invoiceNo": this.estimateInvoiceForm.controls.referenceNumber.value,
          "billingAddress": this.estimateInvoiceForm.controls.billingAddress.value,
          // "customerName": this.estimateInvoiceForm.value?.customerName ? this.estimateInvoiceForm.value?.customerName : null,
          "customerName": this.partyData[0].partyName,
          "invoiceDate": this.todaysDate,
          "userId": this.estimateInvoiceForm.value.warehouse ? this.estimateInvoiceForm.value.warehouse : this.currentUser.id,
          "partyId": this.partyId,
          "lastInvoiceNumber": 100,
          "supplyType": this.supplytype,
          warehouseId: `${warehouseIdToSend}`,
          isDraft: true,
        },
        "products": this.sendArray
      }
      this.estimaetionService.postEstimationDetails(data).then((res) => {
        if ( this.estimateInvoiceForm.controls.receiptType.value === "ESTIMATION") {
          this.toastService.toastMsg({
            title: "Success",
            content: 'Estimation Drafted Successfully!',
          });
        } else {
          this.toastService.toastMsg({
            title: "Success",
            content: 'Performa Drafted Successfully!',
          });
        }
        this.commonService.notifyDataAdded();
        this.router.navigate(['/pages/sale_new/sale_draft']);
        this.dialogRef.close();
        // this.estimateInvoiceForm.reset();
        // this.submitted = false;
        // this.myControl.reset();
        // this.productData = [];
        // this.subTotal = 0;
        // this.totalGST = 0;
        // this.additionalCess = 0;
        // this.total = 0;
      },(err) => {
        this.sendArray = [];
        if (err.error.expose) {
          let errorMessage = err.error.error_message;
          if (errorMessage.includes('invoiceDetails.partyId')) {
              errorMessage = "Vendor Name  is required. Please select a valid Vendor Name option.";
          } else {
              errorMessage = this.titleCasePipe.transform(errorMessage);
          }
          this.toastService.toastMsg({
              title: "Error",
              content: errorMessage,
          });
        } else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }
  }

  PostEstimateDetail() {
    this.submitted = true;
    if (this.todaysDate == "" || this.estimateInvoiceForm.invalid) {
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    } else {
      if (this.productData.length < 1) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Add At Least One Product To Proceed!",
        });
        // this.toastService.openErrorSnackBar("Please Add At least One Product To Proceed!!!");
        return false;
      }
      this.productData.forEach(e => {
        let data = {
          quantity: e.quantity,
          barcode: e.barcode
        }
        this.sendArray.push(data);
      })

      const userType = this.currentUser.userType.toLowerCase();
      const warehouseIdToSend =
        userType === 'warehouse' ? this.currentUser.id :
        userType === 'shop' ? (this.estimateInvoiceForm.value.warehouse || this.currentUser.id) :
        userType === 'client' && this.estimateInvoiceForm.value.warehouse !== null ? this.estimateInvoiceForm.value.warehouse : this.currentUser.id;
      let data = {
        "invoiceDetails": {
          "receiptType": this.estimateInvoiceForm.controls.receiptType.value,
          "invoiceNo": this.estimateInvoiceForm.controls.referenceNumber.value,
          "billingAddress": this.estimateInvoiceForm.controls.billingAddress.value,
          // "customerName": this.currentUser.firstName,
          "customerName": this.partyData[0].partyName,
          "invoiceDate": this.todaysDate,
          "userId": this.estimateInvoiceForm.value.warehouse ? this.estimateInvoiceForm.value.warehouse : this.currentUser.id,
          "partyId": this.partyId,
          "lastInvoiceNumber": 100,
          "supplyType": this.supplytype,
          warehouseId: `${warehouseIdToSend}`,
          isDraft:false,
          draftId: this.draftId ? this.draftId : null,
        },
        "products": this.sendArray
      }
      this.estimaetionService.postEstimationDetails(data).then((res) => {
        if ( this.estimateInvoiceForm.controls.receiptType.value === "ESTIMATION") {
          this.toastService.toastMsg({
            title: "Success",
            content: 'Estimation Added Successfully!',
          });
        } else {
          this.toastService.toastMsg({
            title: "Success",
            content: 'Performa Added Successfully!',
          });
        }
        this.commonService.notifyDataAdded();
        this.saleInvoiceService.notifySaleInvoiceAdded();
        this.dialogRef.close();
        this.estimateInvoiceForm.reset();
        this.submitted = false;
        this.myControl.reset();
        this.productData = [];
        this.subTotal = 0;
        this.totalGST = 0;
        this.additionalCess = 0;
        this.total = 0;
      },(err) => {
        this.sendArray = [];
        if (err.error.expose) {
          let errorMessage = err.error.error_message;
          if (errorMessage.includes('invoiceDetails.partyId')) {
              errorMessage = "Vendor Name  is required. Please select a valid Vendor Name option.";
          } else {
              errorMessage = this.titleCasePipe.transform(errorMessage);
          }
          this.toastService.toastMsg({
              title: "Error",
              content: errorMessage,
          });
        } else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }
  }

  onCancelModal() {
    this.dialogRef.close();

  }

  openDatepicker() {
    const selectedDate = this.todaysDate;
    if (isNaN(selectedDate.getTime())) {
      this.todaysDate = new Date();
    }
  }

  getWarehouseList() {
    this.warehouseService.getAllWarehouse({
      data:{},
      userId: this.currentUser.userType !== 'CLIENT' ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
    }).then((res: any) => {
      this.warehouseList = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  // Function to handle selection change in warehouse selection dropdown
  onWarehouseSelectionChange(event: any) {
    if (event.value === 'clear') {
      this.estimateInvoiceForm.get('warehouse').setValue(null); // Clear the selection
      this.productData = [];
      this.calculationTotal();
    }
  }
}
