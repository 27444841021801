<div class="container-fluid mb-3 mx-0 bg-white">
    <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
            Stock Details
        </div>
        <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
          <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i>
            Export</button>
    
          <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
          </button>
        </div>
    </div>
    <hr class="mt-1" />
    <div class="fs--1"> 
        <div class="row mb-2">
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-4 col-lg-3" id = "dateRangePrintHide">
                <label class="form-label">Date</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input  class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true"
                        [(ngModel)]="dateRange" bsDaterangepicker [maxDate]="today" (ngModelChange)="changeDate($event)"
                        [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
                    <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
                </mat-form-field>
            </div>
            <!-- <div class="col-sm-7"></div>
            <div class="col-sm-1  mt-2 text-center" id = "excelPrintHide" (click)="exportExcel()">
                <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
                <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>
            </div>
            <div class="col-sm-1 mt-2 text-center" id = "PrintoptionHide" (click)="onPrint()">
                <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x"
                    (click)="printComponent('component1')"></a>
                <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
            </div> -->
        </div>
        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped fs--1 mb-3" id="excel-table">
                <thead class="" style="background-color: #F0F0F0;">
                    <tr class="text-900">
                        <th class="text-nowrap text-center" scope="col">#</th>
                        <th class="text-nowrap text-center" scope="col">Product Name</th>
                        <th class="text-nowrap text-center" scope="col">Opening Stock</th>
                        <th class="text-nowrap text-center" scope="col">Inward</th>
                        <th class="text-nowrap text-center" scope="col">Purchase Return</th>
                        <th class="text-nowrap text-center" scope="col">Outward</th>
                        <th class="text-nowrap text-center" scope="col">Sales Return</th>
                        <th class="text-nowrap text-center" scope="col">Adjustment</th>
                        <th class="text-nowrap text-center" scope="col">Closing Stock</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor = "let item of itemProfitLossList;let i = index">
                        <td class="text-center">{{ i + 1}}</td>
                        <td class="text-center">{{item.itemName}}</td>
                        <td class="text-center">{{item.openingStock}}</td>
                        <td class="text-center">{{item.inward}}</td>
                        <td class="text-center">{{item.totalpurchasereturn}}</td>
                        <td class="text-center">{{item.outward}}</td>
                        <td class="text-center">{{item.totalsalereturnqqq}}</td>
                        <td class="text-center"></td>
                        <td class="text-center">{{item.stock}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="itemProfitLossList == 0">
                    <tr class="text-center">
                      <td colspan="10">
                        <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1"  width="200" height="150">                    </td>
                    </tr>
                  </tbody>
            </table>
        </div>
    </div>
</div>