import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route, UrlSegment, CanActivateChild, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements  CanActivate{
  constructor(private router: Router,
    private authService: AuthService) { }

    canActivate() {
      if (this.authService.isLoggedIn()) {
        return true; 
      }else{
        this.router.navigateByUrl("/auth/role-selection");
        return false;
      }
    }
    
  }