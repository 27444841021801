<div class="container-fluid mb-3 mx-0 bg-white">
  <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
    <div
      class="col-6 d-md-inline-block fs--1 mt-3 ml-5"
      style="font-weight: 600; font-size: 20px !important"
    >
      Sale Purchase By Vendor
    </div>
    <div
      class="col-6 navOption-btns"
      style="display: flex; justify-content: flex-end"
    >
      <button
        class="btn me-3"
        id="excelPrintHide"
        style="white-space: nowrap"
        (click)="exportExcel()"
        type="button"
      >
        <i class="fas fa-download"></i>
        Export
      </button>
      <button
        class="btn"
        id="PrintoptionHide"
        (click)="onPrint()"
        type="button"
      >
        <i class="bi-printer fa-1x"></i>
      </button>
    </div>
  </div>
  <hr class="mt-1" />
  <div class="fs--1">
    <div class="row">
      <div
        style="
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        "
        class="col-xs-12 col-sm-12 col-md-4 col-lg-3"
        id="dateRangePrintHide"
      >
        <label for="Date Range" class="form-label">Date</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input  class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true"
            [(ngModel)]="dateRange" bsDaterangepicker [maxDate]="today" (ngModelChange)="changeDate($event)"
            [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
          <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
        </mat-form-field>
      </div>
      <div
        style="
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        "
        class="col-xs-12 col-sm-12 col-md-4 col-lg-3"
        id="searchBarHide"
      >
        <label class="form-label">Select Vendor Name</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input
            type="text"
            placeholder="Vendor Name"
            aria-label="Select Vendor Name"
            matInput
            (keyup)="searchPartyByName($event.target.value)"
            [matAutocomplete]="auto"
            [ngClass]="{
              'is-invalid': partyName.invalid && partyName.touched && submitted
            }"
            required
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option.partyName"
              (onSelectionChange)="setSelectedPartyDeatails1(option)"
            >
              {{ option.partyName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="table-responsive scrollbar mt-4 fs--1">
      <table
        class="table bg-white table-bordered table-stripped"
        id="excel-table"
      >
        <thead class="" style="background-color: #f0f0f0">
          <tr class="text-900">
            <th
              scope="col"
              class="text-center text-nowrap p-1"
              style="width: 6%"
            >
              #
              <button
                class="btn fs--1 p-0"
                id="dropdownMenuButton"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i style="margin-left: 1px" class="text-1400 fs-1"></i>
              </button>
            </th>
            <th scope="col" class="text-left text-nowrap p-1">
              Vendor Name
              <button
                class="btn fs--1 p-0"
                id="dropdownMenuButton"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i style="margin-left: 1px" class="text-1400 fs-1"></i>
              </button>
            </th>
            <!-- </th> -->
            <th scope="col" class="text-center text-nowrap p-1">
              Sales Amount
              <button
                class="btn fs--1 p-0"
                id="dropdownMenuButton"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  style="margin-left: 10px; color: #ff9a62"
                  class="text-1400 fs-1 bi-filter-right"
                ></i>
              </button>
              <div
                class="dropdown-menu scrollbar p-3"
                style="width: 22%"
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    (change)="onSelectSaleAmount($event.target.value)"
                  >
                    <option value="Equal">Equal to</option>
                    <option value="Greater">Greater than</option>
                    <option value="Less">Less than</option>
                  </select>
                </li>
                <li>
                  <div class="col-sm" style="margin-top: 10px">
                    <input
                      class="form-control fs--1"
                      id="inputPassword3"
                      type="input"
                      [(ngModel)]="totalQuantityInput"
                    />
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <div class="text-center">
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm" type="button">
                      CLEAR
                    </button>
                  </div>
                  <div class="btn-group mb-2 mb-md-0">
                    <button
                      class="btn btn-primary btn-sm"
                      type="button"
                      style="margin-left: 8px"
                      (click)="ApplyQuantity()"
                    >
                      APPLY
                    </button>
                  </div>
                </div>
              </div>
            </th>
            <th scope="col" class="text-center text-nowrap p-1">
              Purchase Amount
              <button
                class="btn fs--1 p-0"
                id="dropdownMenuButton"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  style="margin-left: 10px; color: #ff9a62"
                  class="text-1400 fs-1 bi-filter-right"
                ></i>
              </button>
              <div
                class="dropdown-menu scrollbar p-3"
                style="width: 22%"
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    (change)="onSelectPurchaseAmount($event.target.value)"
                  >
                    <option value="Equal">Equal to</option>
                    <option value="Greater">Greater than</option>
                    <option value="Less">Less than</option>
                  </select>
                </li>
                <li>
                  <div class="col-sm" style="margin-top: 10px">
                    <input
                      class="form-control fs--1"
                      id="inputPassword3"
                      type="input"
                      [(ngModel)]="totalQuantityPurchase"
                    />
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <div class="text-center">
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm" type="button">
                      CLEAR
                    </button>
                  </div>
                  <div class="btn-group mb-2 mb-md-0">
                    <button
                      class="btn btn-primary btn-sm"
                      type="button"
                      style="margin-left: 8px"
                      (click)="ApplyPurchaseQuantity()"
                    >
                      APPLY
                    </button>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="btn-reveal-trigger text-left"
            *ngFor="let item of salepartyList; let i = index"
          >
            <td>{{ i + 1 }}</td>
            <td class="PARTY NAME">{{ item.partyName }}</td>
            <td class="SALE AMOUNT text-center">{{ item.sales }}</td>
            <td class="PURCHASE AMOUNT text-center">{{ item.purchase }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="salepartyList == 0">
          <tr class="text-center">
            <td colspan="10">
              <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
              alt="notfound1"  width="200" height="150">
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td class="nowrap text-align-left" colspan="1">
              <strong class="text-dark"> Total </strong>
            </td>
            <td class="nowrap" colspan="1" style="text-align: center">
              <strong class="text-dark">
                <a class="text-success">{{ itemSaleListTotal.totalSales }}</a>
              </strong>
            </td>
            <td class="nowrap" colspan="1" style="text-align: center">
              <strong class="fw-bold text-dark">
                <a class="text-success">
                  {{ itemSaleListTotal.totalPurchase }}
                </a>
              </strong>
            </td>
          </tr>
        </tfoot>
      </table>
      <div id="headingPrintHide1">
        <mat-paginator
          class="float-right mt-2"
          *ngIf="salepartyList"
          [pageSize]="partyPageSize"
          showFirstLastButtons
          [pageSizeOptions]="[5, 10, 20, 30]"
          [length]="totalrow"
          [pageIndex]="currentPageNo"
          showFirstLastButtons
          (page)="handlePage($event)"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
