import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BankServiceService } from 'src/app/core/services/bank-service.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';



@Component({
  selector: 'app-practice-availabilty',
  templateUrl: './practice-availabilty.component.html',
  styleUrls: ['./practice-availabilty.component.scss']
})
export class PracticeAvailabiltyComponent implements OnInit {
  public bankForm: FormGroup;
  addtionalBankDetails: FormArray;
  ifscNumber:any;
  bankdetails:any=[];
  public hide: boolean = true;
  public isValidFormSubmitted: boolean;
  bnames:any;
  data:any;
  time;
  bootstrapTime;
  period = 'AM';
  hour = '10';
  minutes = '00';

  constructor(private formBuilder: FormBuilder,
    public router: Router,
    private toastService: ToastNotificationService,
    private bankService:BankServiceService,
    private http:HttpClient) { }

  ngOnInit(): void {
    this.bankForm = this.formBuilder.group({
      addtionalBankDetails: new FormArray([this.createaddtionalDetails()]),

   });
   console.log(this.bankForm.status)
  }
  
  createaddtionalDetails(): FormGroup {
    return new FormGroup({
      WeekName: new FormControl("",[Validators.required,Validators.pattern('^[A-Z]{4}0[0-9]{6}$')]),
      timePicker: new FormControl("",[Validators.required]),
   });
  }
  myError = (controlName: string, errorName: string) => {
    return this.bankForm.controls[controlName].hasError(errorName);
    }
    
  deleteaddBankDetails(index): void {
    const data = this.bankForm.get('addtionalBankDetails') as FormArray;
    data.removeAt(index);
  }
  addBankDetails(): void {
    const data = this.bankForm.get('addtionalBankDetails') as FormArray;
    this.bnames ="";
    data.push(this.createaddtionalDetails());
  }
  BankDetails(){
  
    console.log("data")
    console.log(this.bankForm.value)
  }
  get bankdataControl() {
    return this.bankForm.get('addtionalBankDetails') as FormArray;
  }

}
