import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts'
import { getChartOptions } from '../../../chartOptions/chartOptions';

import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    // monthYearLabel: 'MMM YYYY',
    // dateA11yLabel: 'YYYY-MM-DD HH:mm:ss',
    // monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-total-purchase',
  templateUrl: './total-purchase.component.html',
  styleUrls: ['./total-purchase.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },

  ]
})
export class TotalPurchaseComponent implements OnInit {

  // @ViewChild("purchaseChart") purchaseChart;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public userId: string = "";
  public showProfile: boolean;
  public todaysDate = this.commonService.getTodaysDate();
  public totalPurchase: any;
  public permissionData: any;
  public purchaseChart: any = [];
  public puchaseMonthChart: any = []
  public puchaseYearChart: any = []
  public weekNames: any;
  public weekDates: any;
  public weekTotalPurchaseAmt: any[] = [];
  public pastWeekTotalPurchaseAmt: any[] = [];
  public weekNamesSales: any;
  public dayPurchaseDateValue: Date;
  public weekPurchaseDateValue: Date;
  public weekSalesDateValue: Date;
  public monthSalesDateValue: Date;
  public monthPurchaseDateValue: Date;
  public yearSalesDateValue: Date;
  public yearPurchaseDateValue: Date;
  public totalBillAmount: any;
  public monthDay: any;
  public purchaseMonthDay: any[];
  public pastpurchaseMonthDay: any[];
  public purchaseMonthTotalBillAmount: any[];
  public pastpurchaseMonthTotalBillAmount: any[];
  public yearlySalesDate: any;
  public yearlySalestotalBillAmount: any;
  public pastyearlyPurchaseDate: any;
  public yearlyPurchaseDate: any;
  public pastyearlyPurchasetotalBillAmount: any;
  public yearlyPurchasetotalBillAmount: any;
  public isDay: boolean = true;
  public isWeek: boolean = false;
  public isMonth: boolean = false;
  public isYear: boolean = false;
  public purchaseType: string = 'Daily';
  public maxDate = new Date();
  public newSaleDate;
  public newPurchaseDate;
  public defaultPurchaseSelect = "Daily";
  public defaultSaleSelect = "Daily";
  dailyPurchaseData: any;
  yesterdayPurchaseData: any;
  @Input() chartType: string; // Default chart type
  @Input() chartData: any;
  @Input() chartId: any;
  public currentChartType: string = 'spline'; // Default chart type
  lineChart: Chart;
  @Input() xAxisTitle: string = 'Days'; // Default x-axis title
  @Input() yAxisTitle: string = 'Purchase Amount'; // Default y-axis title
  @Input() xAxisCategories: any[] = []; // Default x-axis categories
  @Input() inputs: any;

  constructor(public rolePermission: RolesPermissionsService,
    public authorizationService: AuthorizationService,
    public dashboardService: DashboardService,
    public commonService: CommonService,
    public router: Router) {
    // Chart.register(...registerables)
  }

  ngOnInit(): void {
    this.dayPurchaseDateValue = new Date();
    this.weekPurchaseDateValue = new Date();
    this.monthPurchaseDateValue = new Date();
    this.yearPurchaseDateValue = new Date();
    this.getTotalPurchaseDailyReport();
    this.dashboardService.widgetData$.subscribe(data => {
      if (data) {
        const chartData = JSON.parse(data?.chartData);
        if(chartData.component === 'TotalPurchaseComponent'){
          this.chartType = data.chartType;
          this.chartId = data.chartId;
        }
        // Initialize your chart here using this.chartType, this.chartId, and this.chartData
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyChart();
  }

  private initChart(chartType, chartData, categories): void {
    this.destroyChart(); // Destroy any existing chart instance
    if(categories){
      this.xAxisCategories = categories;
    }
    // const options = { ...lineChartOptions, chart: { ...lineChartOptions.chart, type: this.chartType } };
    const options = getChartOptions(chartType, chartData, this.xAxisTitle, this.yAxisTitle, this.xAxisCategories);

    // if (chartData) {
    //   options.series = chartData;
    // }

    this.lineChart = new Chart(options);
  }
  getPurchaseReportType(type) {
    this.purchaseType = type
    if (type === 'Daily') {
      this.isDay = true;
      this.isWeek = false;
      this.isMonth = false;
      this.isYear = false;
      this.getTotalPurchaseDailyReport();
    } else if (type == 'Weekly') {
      this.isDay = false;
      this.isWeek = true;
      this.isMonth = false;
      this.isYear = false;
      this.getTotalPurchaseWeekReport()
    } else if (type == 'Monthly') {
      this.isDay = false;
      this.isWeek = false;
      this.isMonth = true;
      this.isYear = false;
      this.getTotalPurchaseMonthReport();
    } else if (type == 'Yearly') {
      this.isDay = false;
      this.isWeek = false;
      this.isMonth = false;
      this.isYear = true;
      this.getTotalPurchaseYearReport();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.chartType || changes.chartData || changes.xAxisTitle || changes.yAxisTitle || changes.categories) {
    if (changes.chartType || changes.chartData) {
      this.getPurchaseReportType('Daily');
    }
  }
  
  private destroyChart() {
    if (this.lineChart) {
      this.lineChart.ref$.subscribe(chart => {
        chart.destroy();
      });
    }
  }

  private formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getTotalPurchaseDailyReport() {
    if (this.currentUser.id) {
        // if (this.newPurchaseDate) {
        //     this.todaysDate = this.newPurchaseDate;
        // }
      var chartData = {};
      const today = new Date(this.todaysDate);
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      const yesterdayFormatted = `${yesterday.getFullYear()}-${yesterday.getMonth() + 1}-${yesterday.getDate()}`;
      this.dashboardService.getDayWiseInvoiceReport({}, this.currentUser.id, this.todaysDate, "PURCHASE").then(async (res) => {
          this.dailyPurchaseData = res.data.dataset.map((data: any) => {
              return {
                  date: data.date,
                  totalAmount: data.totalBillAmount
              };
          });

          this.dashboardService.getDayWiseInvoiceReport({}, this.currentUser.id, yesterdayFormatted, "PURCHASE").then(async (res) => {
              this.yesterdayPurchaseData = res.data.dataset.map((data: any) => {
                  return {
                      date: data.date,
                      totalAmount: data.totalBillAmount
                  };
              });
              const isPastDayDataEmpty = this.yesterdayPurchaseData[0]?.totalAmount === (null || undefined);
              const isCurrentDayDataEmpty = this.dailyPurchaseData[0]?.totalAmount === (null || undefined);
              if (isPastDayDataEmpty && isCurrentDayDataEmpty) {
                this.currentChartType = 'NoData';
              } else {
                this.currentChartType = this.chartType;
                chartData = [
                  {
                    name: 'Yesterday',
                    data: [(this.yesterdayPurchaseData[0]?.totalAmount || 0), 0],
                    color: '#FC7643',
                  },
                  {
                    name: 'Today',
                    data: [(this.dailyPurchaseData[0]?.totalAmount || 0), 0],
                    color: '#110152',
                  }
                ]
              }
              this.initChart(this.currentChartType, chartData, [this.formatDate(yesterday), this.formatDate(new Date(today))]);
          });
      });
    this.initChart('NoData', {}, []);
  } else {
      this.router.navigate(["/auth"]);
  }
}

// plotDailyPurchaseChart() {
//     this.purchaseChart = Highcharts;

//     const isPastDayDataEmpty = this.yesterdayPurchaseData.every(data => data.totalAmount === 0);
//     const isCurrentDayDataEmpty = this.dailyPurchaseData.every(data => data.totalAmount === 0);

//     if (isPastDayDataEmpty || isCurrentDayDataEmpty) {
//         const chartOptions = {
//           chart: {
//             type: 'spline',
//             events: {
//               load: function() {
//                 const chart = this;
//                 const centerX = chart.plotWidth / 2;
//                 const centerY = chart.plotHeight / 2;

//                 // Add the image element
//                 chart.noDataImage = chart.renderer
//                   .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 70, 200, 150)
//                   .add();
//               }
//             }
//           },
//           title: {
//             text: ' ',
//             // text: this.salesType + ' Sales Report',
//             style: {
//               fontFamily: "'Poppins', sans-serif",
//               fontWeight: '500',
//               fontSize: '16px',
//               color: '#737791'
//             }
//           },
//           credits: {
//             enabled: false
//           },
//         };

//         // Render the chart
//         this.purchaseChart.chart('purchaseCanvas', chartOptions)
//         document.getElementById('purchaseCanvas').style.height = '300px';
//     } else {
//         const chartOptions = {
//             chart: {
//                 type: 'spline',
//             },
//             credits:{
//                 enabled: false
//             },
//             title: {
//                 text: ' ',
//                 style: {
//                     fontFamily: "'Poppins', sans-serif",
//                     fontWeight: '500',
//                     fontSize: '16px',
//                     color: '#737791'
//                 }
//             },
//             xAxis: {
//                 title: {
//                     text: 'Days'
//                 },
//                 categories: [this.yesterdayPurchaseData[0].date, this.dailyPurchaseData[0].date]
//             },
//             yAxis: {
//                 title: {
//                     text: 'Purchase Amount'
//                 },
//                 gridLineWidth: 0
//             },
//             plotOptions: {
//                 series: {
//                     animation: {
//                         duration: 3000
//                     },
//                     cursor: 'pointer',
//                 }
//             },
//             series: [
//                 {
//                     name: 'Yesterday',
//                     data: [this.yesterdayPurchaseData[0].totalAmount, 0],
//                     color: '#FC7643',
//                 },
//                 {
//                     name: 'Today',
//                     data: [this.dailyPurchaseData[0].totalAmount, 0],
//                     color: '#110152',
//                 }
//             ]
//         };

//         this.purchaseChart.chart('purchaseCanvas', chartOptions);
//         document.getElementById('purchaseCanvas').style.height = '300px';
//     }
// }

  /**
   * Total Purchase Week Report
   */
  getTotalPurchaseWeekReport() {
    if (this.currentUser.id) {
      if (this.newPurchaseDate) {
        this.todaysDate = this.newPurchaseDate;
      }
      var chartData = {};
      const today = new Date(this.todaysDate);
      const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      const lastWeekStartDate = `${lastWeek.getFullYear()}-${lastWeek.getMonth() + 1}-${lastWeek.getDate()}`;
      this.dashboardService.getTotalInvoiceWeekReport({}, this.currentUser.id, this.todaysDate, "PURCHASE").then(async (res) => {
        this.weekNames = res.data.dataset.map((names: any) => names.dayName);
        this.weekDates = res.data.dataset.map((dates: any) => dates.date);
        this.weekTotalPurchaseAmt = (res.data.dataset).map((total: any) => total.totalBillAmount);

        this.dashboardService.getTotalInvoiceWeekReport({}, this.currentUser.id, lastWeekStartDate, "PURCHASE").then(async (res) => {
          this.weekNames = res.data.dataset.map((names: any) => names.dayName);
          this.weekNames = res.data.dataset.map((names: any) => {
            const dayName = names.dayName;
            const abbreviatedDayName = dayName.substring(0, 3);
            return abbreviatedDayName;
          });
          this.weekDates = res.data.dataset.map((dates: any) => dates.date);
          this.pastWeekTotalPurchaseAmt = res.data.dataset.map((total: any) => total.totalBillAmount);

          const isPastWeekDataEmpty = this.pastWeekTotalPurchaseAmt.every(value => value === 0);
          const isCurrentWeekDataEmpty = this.weekTotalPurchaseAmt.every(value => value === 0);
          if (isPastWeekDataEmpty && isCurrentWeekDataEmpty) {
            this.currentChartType = 'NoData';
          } else {
            this.currentChartType = this.chartType;
            series: [
              {
                name: 'Past Week',
                data: this.pastWeekTotalPurchaseAmt,
                color: '#FC7643',
              },
              {
                name: 'Current Week',
                data: this.weekTotalPurchaseAmt,
                color: '#110152',
              }
            ]
          }
          this.initChart(this.currentChartType, chartData, this.weekNames);
        })
      })
    this.initChart('NoData', {}, []);
    } else {
      this.router.navigate(["/auth"]);
    }
  }

  // plotWeeklyChart() {
  //   this.purchaseChart = Highcharts;
  //   // noData(Highcharts);
  //   const isPastWeekDataEmpty = this.pastWeekTotalPurchaseAmt.every(value => value === 0);
  //   const isCurrentWeekDataEmpty = this.weekTotalPurchaseAmt.every(value => value === 0);
  //   if (isPastWeekDataEmpty && isCurrentWeekDataEmpty) {
  //     // Display a "No data found" message
  //     const chartOptions = {
  //       chart: {
  //         type: 'spline',
  //         events: {
  //           load: function() {
  //             const chart = this;
  //             const centerX = chart.plotWidth / 2;
  //             const centerY = chart.plotHeight / 2;

  //             // Add the image element
  //             chart.noDataImage = chart.renderer
  //               .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 70, 200, 150)
  //               .add();
  //           }
  //         }
  //       },
  //       title: {
  //         text: ' ',
  //           style: {
  //             fontFamily: "'Poppins', sans-serif",
  //             fontWeight: '500',
  //             fontSize: '16px',
  //             color: '#737791'
  //           }
  //       },
  //       xAxis: {
  //         categories: this.weekNames
  //       },
  //       credits: {
  //         enabled: false
  //       },
  //     };
  //     this.purchaseChart.chart('purchaseCanvas', chartOptions)
  //     document.getElementById('purchaseCanvas').style.height = '300px';
  //   } else {
  //       const chartOptions = {
  //         chart: {
  //           type: 'spline',
  //         },
  //         credits:{
  //           enabled: false
  //        },
  //         title: {
  //           text: ' ',
  //           // text: this.purchaseType + ' Purchase Report',
  //           style: {
  //             fontFamily: "'Poppins', sans-serif",
  //             fontWeight: '500',
  //             fontSize: '16px',
  //             color: '#737791'
  //           }
  //         },
  //         xAxis: {
  //           title: {
  //             text: 'Days'
  //           },
  //           categories: this.weekNames
  //         },
  //         yAxis: {
  //           title: {
  //             text: 'Purchase Amount'
  //           },
  //           gridLineWidth: 0
  //         },
  //         plotOptions: {
  //           series: {
  //               animation: {
  //                   duration: 3000
  //               },
  //               cursor: 'pointer',

  //           }
  //         },
  //         series: [
  //           {
  //             name: 'Past Week',
  //             data: this.pastWeekTotalPurchaseAmt,
  //             color: '#FC7643',
  //           },
  //           {
  //             name: 'Current Week',
  //             data: this.weekTotalPurchaseAmt,
  //             color: '#110152',
  //           }
  //         ]
  //       };
  //       this.purchaseChart.chart('purchaseCanvas', chartOptions)
  //       document.getElementById('purchaseCanvas').style.height = '300px';
  //     }
  // }

  /**
   * Total Purchase Month Report
   */
  getTotalPurchaseMonthReport() {
    // this.puchaseChart.destroy();
    if (this.newPurchaseDate) {
      this.todaysDate = this.newPurchaseDate;
    }
    var chartData = {};
    const today = new Date(this.todaysDate);
    const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const oneMonthAgoDateString = `${oneMonthAgo.getFullYear()}-${oneMonthAgo.getMonth() + 1}-${oneMonthAgo.getDate()}`;
    // this API call returns current month data
    this.dashboardService.getTotalInvoiceMonthReport({}, this.currentUser.id, this.todaysDate, "PURCHASE").then((res) => {
      this.purchaseMonthDay = res.data.dataset.map((names: any) => names.day.split('-')[2]);
      this.purchaseMonthTotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
      // this API call returns past month data
      this.dashboardService.getTotalInvoiceMonthReport({}, this.currentUser.id, oneMonthAgoDateString, "PURCHASE").then((res) => {
        this.pastpurchaseMonthDay = res.data.dataset.map((names: any) => names.day);
        this.pastpurchaseMonthTotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);

        const isPastMonthDataEmpty = this.pastpurchaseMonthTotalBillAmount.every(value => value === 0);
        const isCurrentMonthDataEmpty = this.purchaseMonthTotalBillAmount.every(value => value === 0);
        if (isPastMonthDataEmpty && isCurrentMonthDataEmpty) {
          this.currentChartType = 'NoData';
        } else {
          this.currentChartType = this.chartType;
          chartData = [
            {
              name: 'Past Month',
              data: this.pastpurchaseMonthTotalBillAmount,
              color: '#FC7643',
            },
            {
              name: 'Current Month',
              data: this.purchaseMonthTotalBillAmount,
              color: '#110152',
            }
          ]
        }
        this.initChart(this.currentChartType, chartData, this.purchaseMonthDay);
      });
    });
    this.initChart('NoData', {}, []);
  }

  // plotMonthChart(){
  //   this.purchaseChart = Highcharts;
  //   const isPastWeekDataEmpty = this.pastpurchaseMonthTotalBillAmount.every(value => value === 0);
  //   const isCurrentWeekDataEmpty = this.purchaseMonthTotalBillAmount.every(value => value === 0);
  //   if (isPastWeekDataEmpty && isCurrentWeekDataEmpty) {
  //     // Display a "No data found" message
  //     const chartOptions = {
  //       chart: {
  //         type: 'spline',
  //         events: {
  //           load: function() {
  //             const chart = this;
  //             const centerX = chart.plotWidth / 2;
  //             const centerY = chart.plotHeight / 2;

  //             // Add the image element
  //             chart.noDataImage = chart.renderer
  //               .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 70, 200, 150)
  //               .add();
  //           }
  //         }
  //       },
  //       title: {
  //         text: ' ',
  //           style: {
  //             fontFamily: "'Poppins', sans-serif",
  //             fontWeight: '500',
  //             fontSize: '16px',
  //             color: '#737791'
  //           }
  //       },
  //       xAxis: {
  //         categories: this.weekNames
  //       },
  //       credits: {
  //         enabled: false
  //       },
  //     };
  //     this.purchaseChart.chart('purchaseCanvas', chartOptions);
  //     document.getElementById('purchaseCanvas').style.height = '300px';

  //     } else {
  //       const chartOptions = {
  //         chart: {
  //           type: 'spline',
  //         },
  //         credits:{
  //           enabled: false
  //        },
  //         title: {
  //           text: ' ',
  //           style: {
  //             fontFamily: "'Poppins', sans-serif",
  //             fontWeight: '500',
  //             fontSize: '16px',
  //             color: '#737791'
  //           }
  //         },
  //         xAxis: [{
  //           categories: this.purchaseMonthDay
  //         }
  //       ],
  //         yAxis: {
  //           title: {
  //             text: 'Purchase Amount'
  //           },
  //           gridLineWidth: 0
  //         },
  //         plotOptions: {
  //           series: {
  //             animation: {
  //                 duration: 3000
  //             },
  //             cursor: 'pointer',
  //             draggableX: true, // Enable draggable points on the x-axis
  //             draggableY: true // Enable draggable points on the y-axis
  //           }
  //         },
  //         series: [
  //           {
  //             name: 'Past Month',
  //             // data: [900,290,350,261,754],
  //             data: this.pastpurchaseMonthTotalBillAmount,
  //             color: '#FC7643',
  //           },
  //           {
  //             name: 'Current Month',
  //             // data: [300,200,550,161,554],
  //             data: this.purchaseMonthTotalBillAmount,
  //             color: '#110152',
  //           }
  //         ]
  //       };
  //       this.purchaseChart.chart('purchaseCanvas', chartOptions)
  //       document.getElementById('purchaseCanvas').style.height = '300px';

  //     }
  // }

  /**
   * Total Purchase Year Report
   */
  getTotalPurchaseYearReport() {
    // this.puchaseChart.destroy();
    if (this.newPurchaseDate) {
      this.todaysDate = this.newPurchaseDate;
    }
    var chartData = {};
    const oneYearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD')

    this.dashboardService.getTotalInvoiceYearReport({}, this.currentUser.id, this.todaysDate, "PURCHASE").then((res) => {
      this.yearlyPurchaseDate = res.data.dataset.map((names: any) => names.date + "/" + names.year);
      this.yearlyPurchasetotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);

      this.dashboardService.getTotalInvoiceYearReport({}, this.currentUser.id, oneYearAgo, "PURCHASE").then((res) => {
        this.pastyearlyPurchaseDate = res.data.dataset.map((names: any) => names.date + "/" + names.year);
        this.pastyearlyPurchasetotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);

        const isPastYearDataEmpty = this.pastyearlyPurchasetotalBillAmount.every(value => value === 0);
        const isCurrentYearDataEmpty = this.yearlyPurchasetotalBillAmount.every(value => value === 0);
        if (isPastYearDataEmpty && isCurrentYearDataEmpty) {
          this.currentChartType = 'NoData';
        } else {
          this.currentChartType = this.chartType;
          chartData = [
            {
              name: 'Past year',
              data: this.pastyearlyPurchasetotalBillAmount,
              color: '#FC7643',
            },
            {
              name: 'Current Year',
              data: this.yearlyPurchasetotalBillAmount,
              color: '#110152',
            }
          ]
        }
        this.initChart(this.currentChartType, chartData, this.yearlyPurchaseDate);
      })
    })
    this.initChart('NoData', {}, []);
  }

  // plotYearChart(){
  //   this.purchaseChart = Highcharts;
  //   const isPastWeekDataEmpty = this.pastyearlyPurchasetotalBillAmount.every(value => value === 0);
  //   const isCurrentWeekDataEmpty = this.yearlyPurchasetotalBillAmount.every(value => value === 0);
  //   if (isPastWeekDataEmpty && isCurrentWeekDataEmpty) {
  //     // Display a "No data found" message
  //     const chartOptions = {
  //       chart: {
  //         type: 'spline',
  //         events: {
  //           load: function() {
  //             const chart = this;
  //             const centerX = chart.plotWidth / 2;
  //             const centerY = chart.plotHeight / 2;

  //             // Add the image element
  //             chart.noDataImage = chart.renderer
  //               .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 70, 200, 150)
  //               .add();
  //           }
  //         }
  //       },
  //       title: {
  //         text: ' ',
  //           style: {
  //             fontFamily: "'Poppins', sans-serif",
  //             fontWeight: '500',
  //             fontSize: '16px',
  //             color: '#737791'
  //           }
  //       },
  //       xAxis: {
  //         categories: this.weekNames
  //       },
  //       credits: {
  //         enabled: false
  //       },
  //     };
  //     this.purchaseChart.chart('purchaseCanvas', chartOptions);
  //     document.getElementById('purchaseCanvas').style.height = '300px';

  //     } else {
  //       const chartOptions = {
  //         chart: {
  //           type: 'spline',
  //         },
  //         credits:{
  //           enabled: false
  //       },
  //         title: {
  //           text: ' ',
  //           style: {
  //             fontFamily: "'Poppins', sans-serif",
  //             fontWeight: '500',
  //             fontSize: '16px',
  //             color: '#737791'
  //           }
  //         },
  //         xAxis: [{
  //           categories: this.yearlyPurchaseDate
  //         }
  //       ],
  //         yAxis: {
  //           title: {
  //             text: 'Purchase Amount'
  //           },
  //           gridLineWidth: 0
  //         },
  //         plotOptions: {
  //           series: {
  //               animation: {
  //                   duration: 3000
  //               },
  //               cursor: 'pointer',
  //           }
  //         },
  //         series: [
  //           {
  //             name: 'Past year',
  //             data: this.pastyearlyPurchasetotalBillAmount,
  //             color: '#FC7643',
  //           },
  //           {
  //             name: 'Current Year',
  //             data: this.yearlyPurchasetotalBillAmount,
  //             color: '#110152',
  //           }
  //         ]
  //       };
  //       this.purchaseChart.chart('purchaseCanvas', chartOptions)
  //       document.getElementById('purchaseCanvas').style.height = '300px';

  //     }
  // }
}
