
<div class="card mb-3">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Available Appointment Type
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="row min-vh-50 flex-center g-0" *ngIf="allAppointmentShopType.length === 0">
        <h4 class="text-center">No Records Found....</h4>
    </div>
    <div class="card-body">
        <div class="bg-white">
            <div class="bg-white scroll">
                <div class="row justify-content-end m-3" *ngIf="allAppointmentShopType.length > 0">
                    <div class="row headerButtons mb-3">
                        <div class="flex-item searchBar">
                            <input type="search" id="searchInput" class="search-form-control pe-5 my-2" placeholder="search here..."
                                (input)="onSearchInputChange($event.target.value)">
                        </div>
                        <div class="navOption-btns flex-item formButtons mx-0">                        
                            <a class="btn" (click)="goTOBack()">Back</a>
                        </div>

                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" *ngFor="let a of allAppointmentShopType">
                        <div class="card mb-3 bg-white pointer-event-cursor"
                            routerLink="/pages/customer/book-appointment-time"
                         [queryParams]="{param1:a.id,param2:shopId}">
                            <img class="card-img-top retailerCard" [height]="165" width="284" 
                            [src]="a.shopData?.profileImage == null ? '/assets/images/timeshop.jpg' :a.shopData?.profileImage ">
                            <div class="card-body card_height">
                                <h3 class="card-text m-0">
                                    Name : {{ a.name }}
                                </h3>
                                <p class="card-text m-0">
                                    Duration: {{ a.duration }}
                                    <!-- Appliances,Clothing,Electronics -->
                                </p>
                                <p class="card-text m-0">
                                    Cancellation: {{ a.cancellation }}

                                    <span>
                                        <button class="btn btn-light float-right p-0 pointer-event-cursor"
                                            title="Delivery Service" style="background-color: none;">

                                        </button>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- <div class="tab-pane fade" id="tab-profile" role="tabpanel" aria-labelledby="profile-tab">
        <div class="row bg-white">
            <div class="table-responsive d-flex justify-content-around mt-2">
                <table class="table table-borderless">
                    <thead class="shadow-none thead-light text-center">
                        <th scope="col" data-filter-control="input" class="text-nowrap">
                            Receipt Id
                        </th>
                        <th scope="col" data-filter-control="input" class="text-nowrap pointer-event-cursor">
                            Retailer Name
                        </th>
                        <th scope="col" data-filter-control="input" class="text-nowrap pointer-event-cursor">
                            Delivery Date
                        </th>
                        <th scope="col" data-filter-control="input" class="text-nowrap pointer-event-cursor">
                            Total Bill
                        </th>
                        <th scope="col" data-filter-control="input" class="text-nowrap pointer-event-cursor">
                            Payment Method
                        </th>
                        <th scope="col" data-filter-control="input" class="text-nowrap pointer-event-cursor">
                            Status
                        </th>
                        <th scope="col" data-filter-control="input" class="text-nowrap pointer-event-cursor">
                            Action
                        </th>

                    </thead>


                </table>
            </div>
        </div>
    </div> -->
<!-- <div class="tab-pane fade" id="tab-contact" role="tabpanel" aria-labelledby="contact-tab">

    </div> -->
