<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title mb-0">Add Bank</h1>
    <div class="addProductButtons">
        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-save" (click)="BankDatasave()">Save</button>
    </div>
</div>
<div class="modal-body">
    <div class="container-fluid px-2">
        
        <form [formGroup]="bankForm">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <label class="form-label">Title of Account<span class="error-asterisk">*</span> </label>
                    <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                        <input matInput placeholder="Title of Account"autocomplete="off"
                            formControlName="AccountHolderName"  (keypress)="acceptString($event)" [ngClass]="{
                                'is-invalid':
                                  f.AccountHolderName.invalid && f.AccountHolderName.touched && submitted
                              }"  value=""  />
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.AccountHolderName.touched) && f.AccountHolderName.errors?.required">
                            Title of Account Is Required.
                    </mat-error>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <label class="form-label">IBAN<span class="error-asterisk">*</span> </label>
                    <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                        <input matInput class="text-uppercase" placeholder="IBAN" maxlength="23" style="text-transform : uppercase" formControlName="IFSC"
                    [ngClass]="{
                  'is-invalid':
                    f.IFSC.invalid && f.IFSC.touched && submitted
                }" autocomplete="new-password" value="" />
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.IFSC.touched) && f.IFSC.errors?.required">
                            IBAN Is Required.
                    </mat-error>
                    <mat-error class="text-danger" *ngIf="(submitted || f.IFSC.touched) && f.IFSC.errors?.pattern">
                            IBAN required only alphanumeric format.
                    </mat-error>
                </div>

                <div class="col-md-12 col-lg-6">
                    <label class="form-label">Bank Name<span class="error-asterisk">*</span> </label>
                    <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                        <input type="text" placeholder="Bank Name" formControlName="BankName"  (keypress)="acceptString($event)"
                            aria-label="Select Party Name" matInput 
                            [ngClass]="{
                'is-invalid':
                  f.BankName.invalid && f.BankName.touched && submitted
              }"/>
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.bankName">
                                {{ option.bankName }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.BankName.touched) && f.BankName.errors?.required">
                            Bank Name Is Required.
                    </mat-error>
                </div>

                <div class="col-md-12 col-lg-6">
                    <label class="form-label"> Branch Name<span class="error-asterisk">*</span> </label>
                    <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                        <input matInput class="text-capitalize" type="text" placeholder="Branch Name"
                            formControlName="Branch" (keypress)="acceptString($event)" [ngClass]="{
                'is-invalid':
                  f.Branch.invalid && f.Branch.touched && submitted
              }" value=""/>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.Branch.touched) && f.Branch.errors?.required">
                            Branch Name Is Required.
                    </mat-error>
                </div>


                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <label class="form-label">Account #<span class="error-asterisk">*</span> </label>
                    <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                        <input (keypress)="numberOnly($event)" matInput [type]="hide ? 'password' : 'text'"
                            placeholder="Account #" formControlName="AccountNumber" minlength="9"
                            autocomplete="new-password" maxlength="19" [ngClass]="{
                'is-invalid':
                  f.AccountNumber.invalid &&
                  f.AccountNumber.touched &&
                  submitted
              }" value=""  autocomplete="off"/>
                        <mat-icon matSuffix (click)="hide = !hide">{{ hide ? "visibility_off" : "visibility" }}
                        </mat-icon>
                    </mat-form-field>
                        <mat-error class="text-danger" *ngIf="(submitted || f.AccountNumber.touched) && f.AccountNumber.errors?.required">
                            Account # Is Required.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="(submitted || f.AccountNumber.touched) && f.AccountNumber.errors?.pattern">
                            Account # Required Numeric.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="f.AccountNumber.errors?.minLength">
                            Account # Required Min Length 9.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="f.AccountNumber.errors?.maxLength">
                            Account # Required Max Length 19.
                        </mat-error>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <label class="form-label"> Re-Enter Account #<span class="error-asterisk">*</span> </label>
                    <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                        <input (keypress)="numberOnly($event)" matInput type="text" placeholder="Re Enter Account #"
                            formControlName="ConfirmAccountNumber" minlength="9" maxlength="19"
                            autocomplete="new-password" [ngClass]="{
                'is-invalid':
                  f.ConfirmAccountNumber.invalid &&
                  f.ConfirmAccountNumber.touched &&
                  submitted
              }" value="" />
                    </mat-form-field>
                        <mat-error class="text-danger" *ngIf="(submitted || f.ConfirmAccountNumber.touched) && f.ConfirmAccountNumber.errors?.required">
                            Re Enter Account # Is Required.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="(submitted || f.ConfirmAccountNumber.touched) && f.ConfirmAccountNumber.errors?.pattern">
                            Re Enter Account # Required Numeric.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="f.ConfirmAccountNumber.errors?.minLength">
                            Re Enter Account # Required Min Length 9.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="f.ConfirmAccountNumber.errors?.maxLength">
                            Re Enter Account # Required Max Length 19.
                        </mat-error>
                        <mat-error
                          *ngIf="f.ConfirmAccountNumber.errors?.confirmedValidator">
                          Account # &  Re Enter Account # must be same.
                      </mat-error>
                </div>
            </div>
        </form>
    </div>
</div>
