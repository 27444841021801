import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Gstr1Service } from 'src/app/core/services/gstr1.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import * as XLSX from 'xlsx';

interface IRange {
  value: Date[];
  label: string;
}

@Component({
  selector: 'app-sale-summary',
  templateUrl: './sale-summary.component.html',
  styleUrls: ['./sale-summary.component.scss']
})
export class SaleSummaryComponent implements OnInit {

  public quarterStartDate: any;
  public quarterEndDate: any;
  public today: Date;
  public quarter;
  public maxDate: Date;
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  public dateRange: Date[];
  fileName = 'SaleSummaryReport.xlsx';
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public saleSummarylist: any;
  public totalQuititysale: any;
  public totalValuesale: any
  public searchKey: any;
  isLoading: boolean = false;
  private searchSubject = new Subject<string>();
  private searchSubscription: Subscription;
  
  //   printComponent(cmpName) {
  //     let printContents = document.getElementById(cmpName).innerHTML;
  //     let originalContents = document.body.innerHTML;

  //     document.body.innerHTML = printContents;

  //     window.print();

  //     document.body.innerHTML = originalContents;

  // }

  constructor(private gstr1Service: Gstr1Service,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    let quarter = moment(this.today).utc().quarter() //outputs 4
    this.getQuarterRange();
  }

  isShown: boolean = false;
  ngOnInit(): void {
    this.ranges = [

      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      {
        value: [this.quarterStartDate._d, this.quarterEndDate._d],
        label: ' Last Quarter'
      },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear(), 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];
    this.dateRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]

    this.getSaleSummaryReport();
    
    this.searchSubscription = this.searchSubject
      .pipe(debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.isLoading = true; // Show the loader when the API call is initiated
        })).subscribe(searchKey => {
          // Call the API with the search key
          if (searchKey.length >= 3) {
            this.searchgetSaleSummaryReport(searchKey.trim());
          } else if (searchKey === '') {
            setTimeout(() => {
              this.isLoading = false; // Hide the loader if the search key is less than the threshold
              this.getSaleSummaryReport();
            }, 500);
          } else {
            this.isLoading = false; // Hide the loader if the search key is less than the threshold
          }
        });
  }

  changeDate(_e: any) {
    this.getSaleSummaryReport();
  }

  getSaleSummaryReport() {
    this.gstr1Service.getSaleSummary({}, this.currentUser.id, moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD'), moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD'))
    .then((res: any) => {
      if(res.success){
        this.saleSummarylist = res.data.saleSummary;
        this.totalQuititysale = res.data.totalQuantity;
        this.totalValuesale = res.data.totalValue;
      }
    })
  }

  onSearchInputChange(searchKey: string) {
    // Emit the search key to the searchSubject
    this.searchSubject.next(searchKey.trim());
  }

  searchgetSaleSummaryReport(searchKey) {
    setTimeout(() => {
      this.gstr1Service.getSaleSummarysearch({}, this.currentUser.id, moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD'), moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD'), searchKey)
      .then((res: any) => {
        if(res.success){
          this.saleSummarylist = res.data.saleSummary;
          this.totalQuititysale = res.data.totalQuantity;
          this.totalValuesale = res.data.totalValue;
        }
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
        }
      })
      this.isLoading = false; // Hide the loader after the API call is completed
    }, 500);
  }

  getQuarterRange() {
    this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
    this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  }

  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  onPrint() {
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
  }

  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }
}
