<div class="card">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Generated Receipts
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body p-1">
        <div class="table-responsive">
            <table class="table table-bordered table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th scope="col">#</th>
                        <th class="text-nowrap">Customer Name</th>
                        <th class="text-nowrap">Contact #.</th>
                        <th class="text-nowrap">Address</th>
                        <th class="text-nowrap">Total Bill</th>
                        <th class="text-nowrap"> GST Amount</th>
                        <th class="text-nowrap"> Payable Amount</th>
                        <th class="text-nowrap">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="btn-reveal-trigger text-left" *ngFor="let item of generatedReceiptsData; let i = index">
                        <td>{{ i+1 }}</td>
                        <td>{{item.customerName | titlecase}}</td>
                        <td>{{item.mobile}}</td>
                        <td>{{item.billingAddress}}</td>
                        <td>{{item.totalBillAmount - item.billGstAmount | currency:'INR':'symbol':'1.2-2' }}</td>
                        <td>{{item.billGstAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                        <td>{{item.totalBillAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                        <td>
                             <!-- <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#invoice-modal"
                                (click)="getReceiptById(item.id)">
                                Print
                            </button>  -->
                            
                            <!--print -->
                            <button class="btn btnp" data-bs-toggle="modal" data-bs-target="#invoice-modal"
                            (click)="getReceiptById(item.id)">
                                <span class="bi-printer icon-color fs-1" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="print"></span>
                                
                            </button>
                            
                            
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="generatedReceiptsData == 0">
                    <tr class="text-center">
                        <td colspan="10">
                            <h5 class="text-nowrap">No Order Generated Receipts Added. Please Add Generated Receipts To
                                Be Displayed Here. <span>

                            </span>
                            </h5>
                        </td>
                    </tr>
                </tbody>
                
            </table>
        </div>
    </div>
</div>
<!-- Print Modal -->
<div class="modal fade" id="invoice-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <app-receip-format [receiptProductData]="saleProductData" [(invoiceNo)]="invoiceNo"
        [(billGstAmount)]="billGstAmount" [(totalBillAmount)]="totalBillAmount"></app-receip-format>
</div>