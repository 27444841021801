import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PartyService } from 'src/app/core/services/party.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { map, startWith } from 'rxjs/operators';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { TitleCasePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { FormArray } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SaleOrderService } from 'src/app/core/services/sale-order.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import lang from "src/assets/langTranslation/language_translation"
import { CommonService } from 'src/app/core/services/common.service';
import { WarehouseService } from 'src/app/core/services/warehouse.service';
import { ProductService } from 'src/app/core/services/product.service';
import moment from 'moment';

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  // public PaymentInForm: FormGroup;
  saveAsDraft: boolean = false;
  public gstAmount = 0;
  public isShippingAddressinModal: any;
  public isShowGstFields: boolean = true;
  public gstDetails: any
  public isShown: boolean = false;
  public allPartyList: any;
  public filteredOptions: any;
  public myControl = new FormControl();
  public myControl1 = new FormControl();
  searchedProduct: any;
  productOptions: Observable<any>;
  public partyId: any;
  public partyData: any
  public unitValue: any;
  public index: any;
  public product: any;
  public amount = 0;
  public qty = 0;
  public unit: any;
  public wtax: any;
  public wotax: any;
  public ramount: any;
  public taxAmount = 0
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  public today: Date;
  public maxDate: Date;
  public isDisabledGST: boolean = true;
  public submittedParty: boolean = false;
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public finalObject;
  imageLoaded: boolean = false;
  public purchaseImage: any;
  public imageSrc: any = '';
  public imageSrc1: any = '';
  public todaysDate: any;
  public todaysDate2: any;
  public sendArray: any = [];
  public totalPrice: any;
  public barcode: any;
  public refNo: any;
  public saleInvoiceNo;
  public SaleReturnNo;
  public subTotal = 0;
  public totalGST = 0;
  public total = 0;
  public additionalCess: number = 0;
  public productData: any = [];
  public submitted: boolean = false;

  public gstType: any;
  public gstInRs: any
  public gstInPer: any;
  public compositeType: any;
  public lblColor: boolean = false;
  public gstRate = 0;
  public gstStateMatch: boolean = true;
  public purchaseOrderForm: FormGroup;
  public purchaseOrderGst: any;
  public userProfileGSTNo: any;
  public supplytype: any;
  public paramID: any;
  public AddReceiptName: any;
  public lang = lang.UAE;
  public productPicUrl: string = '';
  // serialNumber: number = 1;

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  isArabic: any;
  PartyForm: FormGroup;
  warehouseList: any[];
  draftId: any;

  constructor(
    private formBuilder: FormBuilder, public activeModal: NgbActiveModal,
    @Inject(MAT_DIALOG_DATA) public estimationData: any,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PurchaseOrderComponent>,
    private partyService: PartyService, private commonService: CommonService,
    private router: Router,
    private toastService: ToastNotificationService,
    private saleInvoiceService: SaleInvoiceService,
    private biddingService: BiddingService,
    private titleCasePipe: TitleCasePipe,
    private profileService: ProfileService,
    private warehouseService: WarehouseService,
    private modal: NgbModal, private saleorderservice: SaleOrderService,private productService: ProductService,) {

    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }
  ngOnInit(): void {
    // Subscribe to the observable to receive updates
    this.PartyForm = this.formBuilder.group({
      gstType: [null],
      gstIn: [
        null,
        [
          Validators.minLength(15),
          Validators.maxLength(15),
        ],
      ],
      partyName: ["", [Validators.required]],
      mobile: [
        ,
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern("^[0-9]*$"),
          Validators.required,
        ],
      ],
      email: [
        null,
        [
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$" ),
              Validators.email,
          Validators.required,
        ],
      ],
      billingAddress: [null, [Validators.required]],
      shippingAddress: [],
      balanceStatus: ["1"],
      isCustomer: [false],
    });
    this.getProfileData();
    this.todaysDate = new Date();
    this.todaysDate2 = new Date();
    this.getAllParties();
    this.getWarehouseList();
    this.newAttribute = { unit: "", ramount: "", wtax: "", wotax: "", index: "", product: "", gty: "", select: "" };
    this.fieldArray.push(this.newAttribute);
    this.purchaseOrderForm = this.formBuilder.group({
      partyName: [null],
      orderNo: [null],
      MobileNo: [null,[Validators.minLength(10),Validators.maxLength(10)]],
      orderDate: [null, [Validators.required]],
      dueDate: [null, [Validators.required]],
      PurchaseType: ['false'],
      Description: [null],
      billingAddress: [null],
      warehouse: [null],
    });

    console.log("estimation data -- ",this.estimationData);

    if (this.estimationData && this.estimationData.draftData?.id) {
      this.setEstimateToSaleForm(this.estimationData)
    }
  }

  get f() {
    return this.purchaseOrderForm.controls;
  }

  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.userProfileGSTNo = res.data.additional.gstinNo ? res.data.additional.gstinNo.slice(0, 2) : "";
    }))
  }

  setSelectedPartyDeatails(item) {
    this.partyId = item.id;
    if (this.partyData) {
      this.partyData.forEach((ele) => {
        this.purchaseOrderGst = ele.gstIn
        this.purchaseOrderForm.patchValue({
          'billingAddress': ele.billingAddress,
          'MobileNo' :ele.mobile,
        })
        if (this.purchaseOrderGst.slice(0, 2) == this.userProfileGSTNo) {
          this.gstStateMatch = true
          this.supplytype = "INTRASTATE"
        }
        else {
          this.gstStateMatch = false;
          this.supplytype = "INTERSTATE"
        }
      })

    }
    else {
      this.saleInvoiceService.getPartyByName({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item.partyName)
        .then((response: any) => {
          this.partyData = response.data.rows;
          this.allPartyList = response.data.rows;
          this.allPartyList.forEach((ele) => {
            this.purchaseOrderGst = ele.gstIn
            this.purchaseOrderForm.patchValue({
              'billingAddress': ele.billingAddress,
               'MobileNo' :ele.mobile,

            })
            if (this.purchaseOrderGst.slice(0, 2) == this.userProfileGSTNo) {
              this.gstStateMatch = true
              this.supplytype = "INTRASTATE"
            }
            else {
              this.gstStateMatch = false;
              this.supplytype = "INTERSTATE"
            }
          });
        })
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(name => name ? this.filterNames(name) : this.allPartyList.slice())
      );
    }
  }

  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  getAllParties() {
    this.partyService
      .getAllPartiesDetails({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
      .then((res) => {
        this.allPartyList = res.data.pageData;
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
      });
  }

  setEstimateToSaleForm(estimationData: any) {
    this.saleInvoiceService.getSaleInvoiceById({}, this.estimationData.draftData.id).then((saleInvoiceData: any) => {
      if(saleInvoiceData.success){
        for (let index = 0; index < saleInvoiceData.data.sales_products.length; index++) {
          const element = saleInvoiceData.data.sales_products[index];

          this.productData.push({
            basePrice: element.pricePerUnit,
            purchasePrice: element.pricePerUnit,
            salePrice: element.totalPrice,
            gstRate: element.gst,
            ...element
          })
          this.setPurchasePrice(element.pricePerUnit, index)
        }

        this.myControl.patchValue(estimationData.draftData.customerName);
        this.draftId = estimationData.draftData?.id
        console.log("saleInvoiceData.data",saleInvoiceData.data)
        this.purchaseOrderForm.patchValue({
          // gstNo: saleInvoiceData.data.party.gstIn,
          // billingAddress: saleInvoiceData.data.party.billingAddress,
          // shippedFrom: saleInvoiceData.data.party.shippingAddress,
          // ewayBillNo: saleInvoiceData.data.party.ewayBillNo,
          // credit: saleInvoiceData.data.credit ? true : false,
          // cashSale: saleInvoiceData.data.cashSale ? true : false,
          // reverseCharge: saleInvoiceData.data.reverseCharge ? true : false,
          // invoiceNo: estimationData.draftData?.invoiceNo,
          PurchaseType: estimationData.draftData?.credit ? estimationData.draftData?.credit.toString() : null,
          orderDate: moment(estimationData.draftData.invoiceDate).format("DD-MMM-YYYY"),
          dueDate: moment(estimationData.draftData.dueDate).format("DD-MMM-YYYY"),
          // orderNo: estimationData.draftData?.invoiceNo,
          // MobileNo: estimationData.draftData?.mobile,
          cashSale: estimationData.draftData?.cashSale,
          // billingAddress: estimationData.draftData?.billingAddress,
          shippingAddress: estimationData.draftData?.shippingAddress,
          ewayBillNo: estimationData.draftData?.ewayBillNo,
          reverseCharge: estimationData.draftData?.reverseCharge,
          counterDiscount: estimationData.draftData?.counterDiscountAmount,
          sendWhatsappMsg: estimationData.draftData?.isWhatsapp || true,
          gstNo: estimationData.draftData?.party?.gstIn,
          orderNo: saleInvoiceData.data?.invoiceNo,
          MobileNo: saleInvoiceData.data?.mobile,
          billingAddress: saleInvoiceData.data?.billingAddress,

        });
        this.partyId = saleInvoiceData.data.partyId;
        
        if (this.estimationData && this.estimationData.item && this.estimationData.item.vendor) {
          this.myControl.patchValue(this.estimationData.item.vendor.partyName);
        }

        // this.myControl.patchValue(this.estimationData.item.party.partyName)
        this.setSelectedPartyDeatails({
          id: saleInvoiceData.data.partyId,
          partyName: saleInvoiceData.data.party.partyName
        })
        this.partyId = saleInvoiceData.data.partyId;
        // for (let i = 0; i <= this.productData.length; i++) {
        //   this.addDetailsButtonClick(i);
        // }
        this.calculationTotal();
      }
    });
  }

  get fp() {
    return this.PartyForm.controls;
  }

  Units = [
    "NONE",
    "BAG-BAGS",
    "BAL-BALE",
    "BDL-BUNDLES",
    "BKL-BUCKLES",
    "BOU-BILLIONS OF UNITS",
    "BOX-BOX",
    "BTL-BOTTLES",
    "BUN-BUNCHES",
    "CAN-CANS",
    "CBM-CUBIC METER",
    "CCM-CUBIC CENTIMETER)",
    "CMS-CENTIMETER",
    "CTN-CARTONS",
    "DOZ-DOZEN",
    "DRM-DRUM",
    "GGR-GREAT GROSS",
    "GMS-GRAMS",
    "GRS-GROSS",
    "GYD-GROSS YARDS",
    "KGS-KILOGRAMS",
    "KLR-KILOLITER",
    "KME-KILOMETRE",
    "MLT-MILLILITRE",
    "MTR-METERS(MTR)",
    "MTS-METRIC TONS",
    "NOS-NUMBERS",
    "PAC-PACKS",
    "PCS-PIECES",
    "PRS-PAIRS)",
  ]

  async calculationTotal() {
    this.barcode = "";
    let price = 0;

    let additionalCess = 0;
    let gstInPer = 0;
    this.totalPrice = 0;
    this.subTotal = 0;
    this.gstRate = 0;
    this.productData.forEach((element) => {
      let gstInPer = element.gstRate;

      additionalCess = (element.salePrice * element.additionalCess) / 100;

      element.basePrice = element.purchasePrice / (1 + element.gstRate / 100);
      element.subTotal = element.basePrice * element.quantity;
      element.gstAmount =
        (element.purchasePrice - element.basePrice) * element.quantity;
      this.gstRate = element.gstAmount + this.gstRate;

      element.total = element.purchasePrice * element.quantity;
      // total for all products in the array
      price = element.total + price;
      // total without gst for all products in the array
      this.subTotal = element.subTotal + this.subTotal;

      gstInPer = element.gstRate;
      if (this.isShown) {
        this.totalPrice = price;
      } else {
        this.totalPrice = price;
      }
    });

    if (this.gstType === "Exempted") {
      this.totalGST = 0;
      this.gstInRs = 0;
      this.gstInPer = 0;
    } else if (this.gstType === "Composite") {
      if (
        this.compositeType == "Manufacturer" ||
        this.compositeType == "Trader"
      ) {
        this.totalGST = (this.subTotal * 1) / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else if (this.compositeType == "Restaurant") {
        this.totalGST = (this.subTotal * 5) / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else {
        this.totalGST = (this.subTotal * 6) / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      }
    } else {
      this.totalGST = this.gstRate;
      this.gstInRs = this.gstRate;
      this.gstInPer = gstInPer;
    }

    this.additionalCess = additionalCess;
    if (this.isShown) {
      this.total = price;
    } else {
      this.total = price;
    }
  }

  changeQtyCalculation(i) {
    this.totalGST = (this.productData[i].salePrice * this.productData[i].quantity) * (this.productData[i].gstRate / 100);
    this.subTotal = (this.productData[i].salePrice * this.productData[i].quantity);
    this.total = this.totalGST + this.subTotal;
    this.calculationTotal();
  }

  changeQuantity(sign, i, quantity = 0) {
    switch (sign) {
      case "-":
        if (this.productData[i].quantity > 1) {
          this.productData[i].quantity = this.productData[i].quantity - 1;
        }
        // this.changeQtyCalculation(i);
        this.calculationTotal();
        break;
      case "+":
        this.productData[i].quantity = this.productData[i].quantity + 1;
        // this.changeQtyCalculation(i);
        this.calculationTotal();
        break;
      case "rm":
        this.productData.splice(i, 1);
        if (this.productData.length <= 0) {
          this.totalGST = 0;
          this.subTotal = 0;
          this.total = 0;
        }
        this.calculationTotal();
        break;
      case "qty":
        this.productData[i].quantity = quantity;
        this.calculationTotal();
        break;
      default:
        this.productData[i];
        break;
    }
  }

  getGoodsByBarcode() {
    this.saleInvoiceService.getGoodsByBarcode({}, this.barcode, this.purchaseOrderForm.value.warehouse ? this.purchaseOrderForm.value.warehouse : this.currentUser.id)
      .then((response) => {
        // Check if the barcode already exists in productData
        const existingProductIndex = this.productData.findIndex(
          (product) => product.barcode === response.data.barcode
        );

        if (existingProductIndex !== -1) {
          // Barcode exists, update quantity
          this.productData[existingProductIndex].quantity += 1;
        } else {
          // Barcode doesn't exist, add new product
          response.data.quantity = 1;
          this.productData.push(response.data);
          console.log(this.productData)
          for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            console.log(element, element.pricePerUnit)
            this.productData.push({
              purchasePrice: (element.pricePerUnit || null),
              ...element
            })
            this.setPurchasePrice(element.pricePerUnit, index)
          }
        }
        this.calculationTotal();
      });
  }

  onSearchInputChange(searchKey: string) {
    if (searchKey.length >= 3) {
      this.getGoodsList(searchKey);
    }
  }

  getWarehouseList() {
    this.warehouseService.getAllWarehouse({
      data:{},
      userId: this.currentUser.userType !== 'CLIENT' ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
    }).then((res: any) => {
      this.warehouseList = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  getGoodsList(value) {
    if (value && (String(value).trim() !== '' || value !== null)) {
      this.biddingService.getAllProductsByUserSearch({}, this.purchaseOrderForm.value.warehouse ? this.purchaseOrderForm.value.warehouse : this.currentUser.id, value, 1000, 1, this.purchaseOrderForm.value.warehouse ? this.purchaseOrderForm.value.warehouse : this.currentUser.id)
        .then((res) => {
          if(res.success) {
            this.searchedProduct = res.data.pageData;
            this.productOptions = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
              );
            }
        },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
            }

          })
    } else {}
  }

  getGoodsByBarcodeSelected(barcode, event) {
    if (event.isUserInput) {
      this.saleInvoiceService
        .getGoodsByBarcode({}, barcode,this.currentUser.id, this.purchaseOrderForm.value.warehouse ? this.purchaseOrderForm.value.warehouse : this.currentUser.id)
        .then(async (response) => {
          response.data.quantity = 1;
          // Check if the barcode already exists in productData
          const existingProductIndex = this.productData.findIndex(
            (product) => product.barcode === response.data.barcode
            );

          if (existingProductIndex !== -1) {
            // Barcode exists, update quantity
            this.productData[existingProductIndex].quantity += 1;
          } else {
            // Barcode doesn't exist, add new product
            response.data.quantity = 1;
            const element = response.data;
            this.productData.push({
              purchasePrice: (element.pricePerUnit || null),
              ...element
            })
            // this.addDetailsButtonClick(this.productData.length - 1);
          }

          this.calculationTotal();
          this.myControl1.patchValue('')
          document.getElementById('inp12').nodeValue = ''
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
    }
  }

  // addDetailsButtonClick(i): void {
  //   const content = this.purchaseOrderForm.get("subcheckbox") as FormArray;
  //   content.push(this.addRolesPermission(i));
  // }

  // addRolesPermission(id): FormGroup {
  //   return new FormGroup({
  //     read: new FormControl(false),
  //   });
  // }

  showShippingAddressinModal(checked: boolean) {
    this.isShippingAddressinModal = checked;
  }

  // customertoggleShow(value) {
  //   this.isShowGstFields = value;
  // }

  getUnit(value) {
    this.unitValue = "1 " + value;
  }

  getGstNumber(gstin) {

  }

  searchPartyByName(item) {
    this.partyId = item.id;
    this.saleInvoiceService.getPartyByName({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item.partyName)
      .then((response) => {
        this.partyData = response.data.rows;
        this.partyData.forEach((ele) => {
        })
      });
  }

  addFieldValue() {
    this.fieldArray.push(this.newAttribute)
    this.newAttribute = {};
  }

  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validateTRN(TRN) {
    if (TRN.length === 15) {
      this.isDisabledGST = false;
    } else {
      this.isDisabledGST = true;
      this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
    }
  }

  startUpload() {
    document.getElementById('uploadFile').click();
  }

  // addNewParty() {
  //   this.submittedParty = true;
  //   if (this.isShowGstFields) {
  //     if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
  //       || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == "")) {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: "Fill All Required Fields.",
  //       });
  //       return false;
  //     } else if ((this.PartyForm.controls.billingAddress.value == "" && this.PartyForm.controls.partyName.value == ""
  //       && this.PartyForm.controls.mobile.value == "" && this.PartyForm.controls.email.value == "")) {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: "Fill All Required Fields.",
  //       });
  //     }
  //     else {
  //       this.addPartyObject();
  //     }
  //   }
  //   else {
  //     if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
  //       || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == ""
  //       || this.PartyForm.controls.gstType.value == "" || this.PartyForm.controls.gstIn.value == "")) {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: "Fill All Required Fields.",
  //       });
  //       return false;
  //     }

  //     else {
  //       this.addPartyObject();
  //     }
  //   }
  // }

  // addPartyObject() {
  //   this.finalObject = {
  //     "partyDetails": {
  //       "isCustomer": this.isShowGstFields,
  //       "gstType": this.PartyForm.controls.gstType.value ? this.PartyForm.controls.gstType.value : null,
  //       "gstIn": this.PartyForm.controls.gstIn.value ? this.PartyForm.controls.gstIn.value : null,
  //       "partyName": this.PartyForm.controls.partyName.value,
  //       "mobile": this.PartyForm.controls.mobile.value ? (this.PartyForm.controls.mobile.value).toString() : null,
  //       "email": this.PartyForm.controls.email.value,
  //       "billingAddress": this.PartyForm.controls.billingAddress.value,
  //       "shippingAddress": this.PartyForm.controls.shippingAddress.value,
  //       "openingBalance": null,
  //       "asOfDate": null,
  //       "balanceStatus": this.PartyForm.controls.balanceStatus.value,
  //       "payment": null,
  //       "creditPeriodDay": null,
  //       "creditLimit": null,
  //       "noOfCredits": null,
  //       "relationSince": null,
  //       "userId": this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
  //       "distance": null,
  //       "transporation": null,
  //     },
  //     "groupAdd": null
  //   }

  //   this.partyService.postParty(this.finalObject).then((res) => {
  //     this.paramID = res.data.id
  //     if (res) {
  //       this.toastService.toastMsg({
  //         title: "Success",
  //         content: "Party Added Successfully!",
  //       })
  //       this.PartyForm.reset();
  //       this.modal.dismissAll();
  //       this.getAllParties();
  //       this.submittedParty = false;
  //     }
  //   },
  //     (err) => {
  //       if (err.error.expose) {
  //         this.toastService.toastMsg({
  //           title: "Error",
  //           content: this.titleCasePipe.transform(err.error.error_message),
  //         });
  //       }
  //       else {
  //         this.toastService.toastMsg({
  //           title: "Error",
  //           content: "Something Went Wrong.",
  //         });
  //       }
  //     })
  // }

  addNewParty() {
    this.submittedParty = true;
    console.log('This.partyForm',this.PartyForm)
    console.log('PartyForm Valid:', this.PartyForm.valid);
    console.log('PartyForm Controls:', this.PartyForm.controls);

    // Use Angular's built-in validation
    if (this.PartyForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return;
    }

    // Check if GST fields should be shown and validate accordingly
    if (this.isShowGstFields) {
      if (!this.PartyForm.controls.billingAddress.value || !this.PartyForm.controls.partyName.value ||
          !this.PartyForm.controls.mobile.value || !this.PartyForm.controls.email.value) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return;
      }
    } else {
      if (!this.PartyForm.controls.gstIn.value || !this.PartyForm.controls.billingAddress.value ||
          !this.PartyForm.controls.partyName.value || !this.PartyForm.controls.mobile.value ||
          !this.PartyForm.controls.email.value) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return;
      }
    }

    // Proceed with form submission if valid
    this.addPartyObject();
  }

  addPartyObject() {
    console.log("addPartyObject", this.isShowGstFields)
    const finalObject = {
      partyDetails: {
        isCustomer: this.isShowGstFields,
        gstIn: this.PartyForm.controls.gstIn.value ? this.PartyForm.controls.gstIn.value : null,
        partyName: this.PartyForm.controls.partyName.value,
        mobile: this.PartyForm.controls.mobile.value ? this.PartyForm.controls.mobile.value.toString() : null,
        email: this.PartyForm.controls.email.value,
        billingAddress: this.PartyForm.controls.billingAddress.value,
        shippingAddress: this.PartyForm.controls.shippingAddress.value || null,
        openingBalance: null,
        asOfDate: null,
        balanceStatus: this.PartyForm.controls.balanceStatus.value,
        payment: null,
        creditPeriodDay: null,
        creditLimit: null,
        noOfCredits: null,
        relationSince: null,
        userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
        distance: null,
        transporation: null,
        gstType: null,
      },
      groupAdd: null
    };

    console.log('Final Object:', finalObject);

    this.partyService.postParty(finalObject).then((res) => {
      if (res.success) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Contact Added Successfully!",
        });
        this.PartyForm.reset();
        this.modal.dismissAll();
        this.getAllParties();
        this.submittedParty = false;
        this.isShowGstFields = true;
      }
    }).catch((err) => {
      this.toastService.toastMsg({
        title: "Error",
        content: err.error?.expose ? this.titleCasePipe.transform(err.error.error_message) : "Something Went Wrong.",
      });
    });
  }

  customertoggleShow(value) {
    this.isShowGstFields = value;
    console.log("customertoggleShow", value);
    this.isShippingAddressinModal = false;
    this.PartyForm.patchValue({
      gstIn: null, // Assuming you want to clear gstIn as well
      partyName: '',
      mobile: null,
      email: '',
      billingAddress: '',
      shippingAddress: ''
    });

    Object.keys(this.PartyForm.controls).forEach(key => {
      this.PartyForm.get(key)?.markAsUntouched();
    });
    const gstInControl = this.PartyForm.get('gstIn');

    if (this.isShowGstFields) {
      gstInControl.disable();
    } else {
      gstInControl.enable();
    }
  }


  openAddPartyModal(name) {
    this.modal.open(name, {
      centered: true,
      backdrop: "static",
      size: "lg"
    });
  }

  modalDismiss() {
    this.PartyForm.reset();
    this.modal.dismissAll();
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  handleInputChange(e) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.purchaseOrderForm.patchValue({
          file: reader.result,
        });

        if (file == null) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });

        } else {

          this.imageSrc = reader.result;

          formdata.set("upload", file)
          e.srcElement.value = "";
          this.productService.postCoverImage1(formdata).then((res) => {
            this.productPicUrl = res.data.locations[0];
            this.toastService.toastMsg({
              title: "Success",
              content: "Receipt uopload Successfully!",
            });
          });
        }
      }
    }
  }

  cancelImage() {
    this.productPicUrl = '';
    this.imageSrc=null;
    this.imageLoaded=false;
    this.toastService.toastMsg({
      title: "Success",
      content: "Receipt removed Successfully!",
    });
  }

  getCredit(e) {
    if (e.value == "true") {
      e.value = true;
    } else {
      e.value = false;
    }
  
    // this.purchaseOrderForm.controls.PurchaseType = e.value;
  }

  setSaveAsDraft() {
    this.saveAsDraft = true;
    this.submitted = true;
    if (this.purchaseOrderForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    } else {
      if (this.productData.length < 1) {

        this.toastService.toastMsg({
          title: "Error",
          content: "Please Add At Least One Product To Proceed!",
        });
        return false;
      }
      this.productData.forEach(e => {
        let data = {
          quantity: e.quantity,
          barcode: e.barcode,
          amount: e.purchasePrice
        }
        this.sendArray.push(data);
      })

      const userType = this.currentUser.userType.toLowerCase();
      const warehouseIdToSend =
        userType === 'warehouse' ? this.currentUser.id :
        userType === 'shop' ? (this.purchaseOrderForm.value.warehouse || this.currentUser.id) :
        userType === 'client' && this.purchaseOrderForm.value.warehouse ? this.purchaseOrderForm.value.warehouse : this.currentUser.id;

      let data = {
        "invoiceDetails": {
          "invoiceDate": this.purchaseOrderForm.controls.orderDate.value,
          "invoiceNo": this.purchaseOrderForm.controls.orderNo.value,
          "shippedFrom": "",
          "ewayBillNo": "2123123",
          "billingAddress": this.purchaseOrderForm.controls.billingAddress.value,
          "mobile": this.purchaseOrderForm.controls.MobileNo.value ? this.purchaseOrderForm.controls.MobileNo.value : null,
          "shippingAddress": "",
          "credit": this.purchaseOrderForm.controls.PurchaseType.value,
          "userId": this.purchaseOrderForm.value.warehouse ? this.purchaseOrderForm.value.warehouse : this.currentUser.id,
          "partyId": this.partyId,
          "rcm": false,
          "roundOff": false,
          "dueDate": this.purchaseOrderForm.controls.dueDate.value,
          warehouseId: `${warehouseIdToSend}`,
          isDraft: true,
        },

        "products": this.sendArray
      }
      this.saleorderservice.postPurchaseOrder(data).then((res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Purchase Order Drafted Successfully!",
          })
          this.commonService.notifyDataAdded();
          this.router.navigate(['/pages/purchase-page/purchase-draft']);
          this.dialogRef.close();
        }
      }, (err) => {
        this.sendArray = [];
        if (err.error.expose) {
          let errorMessage = err.error.error_message;
          if (errorMessage.includes('invoiceDetails.partyId')) {
              errorMessage = "Vendor Name  is required. Please select a valid Vendor Name option.";
          } else {
              errorMessage = this.titleCasePipe.transform(errorMessage);
          }
          this.toastService.toastMsg({
              title: "Error",
              content: errorMessage,
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }
  }

  postPurchase() {
    this.submitted = true;
    if (this.purchaseOrderForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    } else {
      if (this.productData.length < 1) {

        this.toastService.toastMsg({
          title: "Error",
          content: "Please Add At Least One Product To Proceed!",
        });
        return false;
      }
      this.productData.forEach(e => {
        let data = {
          quantity: e.quantity,
          barcode: e.barcode,
          amount: e.purchasePrice
        }
        this.sendArray.push(data);
      })

      const userType = this.currentUser.userType.toLowerCase();
      const warehouseIdToSend =
        userType === 'warehouse' ? this.currentUser.id :
        userType === 'shop' ? (this.purchaseOrderForm.value.warehouse || this.currentUser.id) :
        userType === 'client' && this.purchaseOrderForm.value.warehouse ? this.purchaseOrderForm.value.warehouse : this.currentUser.id;
      console.log("this.purchaseOrderForm.controls",this.purchaseOrderForm.controls)
      let data = {
        "invoiceDetails": {
          "invoiceDate":  moment(this.purchaseOrderForm.controls.orderDate.value, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
          // "invoiceDate": this.purchaseOrderForm.controls.orderDate.value,
          "invoiceNo": this.purchaseOrderForm.controls.orderNo.value,
          "shippedFrom": "",
          "ewayBillNo": "2123123",
          "billingAddress": this.purchaseOrderForm.controls.billingAddress.value,
          "shippingAddress": "",
          "credit": this.purchaseOrderForm.controls.PurchaseType.value,
          "userId": this.purchaseOrderForm.value.warehouse ? this.purchaseOrderForm.value.warehouse : this.currentUser.id,
          "partyId": this.partyId,
          "rcm": false,
          "roundOff": false,
          "dueDate":  moment(this.purchaseOrderForm.controls.dueDate.value, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
          // "dueDate": this.purchaseOrderForm.controls.dueDate.value,
          warehouseId: `${warehouseIdToSend}`,
          isDraft:false,
          draftId: this.draftId ? this.draftId : null,
          "mobile": this.purchaseOrderForm.controls.MobileNo.value ? this.purchaseOrderForm.controls.MobileNo.value : null,
        },

        "products": this.sendArray
      }
      this.saleorderservice.postPurchaseOrder(data).then((res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Purchase Order Added Successfully!",
          })
          this.partyService.notifyPartyAdded();
          this.commonService.notifyDataAdded();
          this.dialogRef.close();
        }
      }, (err) => {
        this.sendArray = [];
        if (err && err?.error?.expose) {
          let errorMessage = err.error.error_message;
          if (errorMessage.includes('invoiceDetails.partyId')) {
              errorMessage = "Vendor Name  is required. Please select a valid Vendor Name option.";
          } else {
              errorMessage = this.titleCasePipe.transform(errorMessage);
          }
          this.toastService.toastMsg({
              title: "Error",
              content: errorMessage,
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }
  }

  setGST(value, index) {
    this.productData[index].gstRate = value;
    this.calculationTotal();
  }

  setPurchasePrice(value, index) {
    this.productData[index].purchasePrice = value;

    this.calculationTotal();
  }

  cancel() {
    this.dialogRef.close();
    this.submitted = false;
    this.purchaseOrderForm.reset();
  }

  openDatepickerOrderDate() {
    // Check if the current value of invoiceDate is an "Invalid Date"
    const orderDate = this.purchaseOrderForm.get('orderDate').value;
    if (isNaN(orderDate.getTime())) {
      // Set a default date or any valid date here
      this.purchaseOrderForm.get('orderDate').setValue(new Date());
    }
  }

  openDatepickerDueDate() {
    // Check if the current value of invoiceDate is an "Invalid Date"
    const dueDate = this.purchaseOrderForm.get('dueDate').value;
    if (isNaN(dueDate.getTime())) {
      // Set a default date or any valid date here
      this.purchaseOrderForm.get('dueDate').setValue(new Date());
    }
  }

  // Function to handle selection change in warehouse selection dropdown
  onWarehouseSelectionChange(event: any) {
    if (event.value === 'clear') {
      this.purchaseOrderForm.get('warehouse').setValue(null); // Clear the selection
      this.productData = [];
      this.calculationTotal();
    }
  }
}
