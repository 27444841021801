import { Directive, ViewContainerRef, ComponentFactoryResolver, Input, OnInit, Type, SimpleChanges } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { componentTypeMapping } from 'src/app/pages/shared/shared.module';

@Directive({
  selector: '[dynamicComponent]'
})
export class DynamicComponentDirective implements OnInit {
  @Input() dynamicComponent: string;
  @Input() inputs: any;
  // @Input() dynamicComponent: Type<any>;

  constructor(private viewContainerRef: ViewContainerRef, 
    private componentFactoryResolver: ComponentFactoryResolver,
  private dashboardService: DashboardService) { }

  ngOnInit() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentTypeMapping[this.dynamicComponent]);
    this.viewContainerRef.clear();
    this.viewContainerRef.createComponent(componentFactory);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dynamicComponent || changes.inputs) {
      this.loadComponent();
    }
  }

  loadComponent() {
    this.viewContainerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentTypeMapping[this.dynamicComponent]);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);

    // Pass the data via the service
    this.dashboardService.updateWidgetData(this.inputs);

    if (this.inputs) {
      Object.keys(this.inputs).forEach(inputName => {
        componentRef.instance[inputName] = this.inputs[inputName];
      });
    }
  }
}


// <div class="widget p-2 mb-2 width{{ widget.widgetWidth }}" cdkDrag>
//   <div dynamicComponent [dynamicComponent]="widget.component" 
//        [inputs]="{ chartType: widget.chartType, chartData: widget.chartData, categories: widget.categories }" 
//        class="widget-content"></div>
// </div>
