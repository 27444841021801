<div class="card mb-2">
    <div class="card-body p-2">
        <div class="row flex-between-center">
            <div class="col-sm-auto mb-2 mb-sm-0">
                <p class="d-none d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
                    Bidding Details
                </p>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="card mb-2"  *ngIf="bidArray.length == 0">
        <div class="card-body p-2">
            <div class="row min-vh-50 flex-center g-0" >
                <h4 class="text-center">No Records Found....</h4>
            </div>
        </div>
    </div>

    <div class="accordion-item" *ngIf="bidArray.length > 0">
        <h5 class="accordion-header" id="panelsStayOpen-headingTwo" *ngFor="let Bid of bidArray">
            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo">
                <p class="mt-3 mt-sm-0 fs-1 fs-md-2 text-dark text-capitalize "><a
                        class="text-dark  text-capitalize fw-bold fs-0 fs-lg-1"
                        href="../../../app/e-commerce/product/product-details.html">
                        {{bidProduct}}
                        <span class="ms-3 mt-1" style="font-size: 12px!important;"><b>Rating</b></span>
                        <span class="ms-3 mt-1" style="font-size: 12px!important;"><span class="badge bg-success"
                                style="font-size: 12px!important;">4.2 <span class="fas fa-star mt-1"
                                    style="font-size: 12px!important;"></span></span></span>
                        <span class="ms-3 mt-1 text-success" style="font-size: 12px!important;">
                            <span class="material-icons fs-0 ms-1 mt-1 text-success"
                                style="font-size: 12px!important;">check_circle</span>
                        </span>

                    </a>
                    <span class="fs-1 fs-md-2 text-warning mb-0 mt-3 text-right"
                        style="margin-left: 600px!important; font-size: 20px!important;">
                        <span>
                            <mat-icon style="font-size: 18px;" class="iconcalender p-1 mt-2">currency_rupee
                            </mat-icon>
                        </span> {{Bid.price}}
                    </span>
                </p>
            </button>
        </h5>
        <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show"
            aria-labelledby="panelsStayOpen-headingTwo">
            <div class="accordion-body">
                <div class="row">
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <a class="d-block">
                            <img class="img-fluid fit-cover w-sm-100 h-sm-100 rounded-0 absolute-sm-centered"
                                src="../../../assets/img/products/2.jpg" alt="" />
                        </a>
                    </div>
                    <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-8 col-xl-8 text-left">

                                <h5 class="mt-sm-0 text-capitalize fw-bold "><a class="text-dark fs-0 fs-lg-1"
                                        href="../../../app/e-commerce/product/product-details.html">
                                        {{bidProduct}}

                                    </a>
                                </h5>
                                <div class="" style="font-size: 12px!important;"><span class="badge bg-success"
                                        style="font-size: 12px!important;">4.2 <span class="fas fa-star"
                                            style="font-size: 12px!important;">
                                        </span></span><span class="bgBullet" style="font-size: 12px!important;">100
                                        Rating and Reviews</span></div>
                                <!-- <div class=""><span class="fa fa-star text-warning"></span><span
                                        class="fa fa-star text-warning"></span><span
                                        class="fa fa-star text-warning"></span><span
                                        class="fa fa-star text-warning"></span><span
                                        class="fa fa-star text-300"></span><span class="ms-1"></span>
                                </div> -->
                                <div>
                                    <div class="">
                                        <a class="btn btn-sm btn-success mt-lg-2 p-1">
                                            <span class="material-icons text-white fs-0 ms-1 mt-1"
                                                style="font-size: 12px!important;">check_circle</span>
                                            Home Delivery
                                        </a>
                                    </div>
                                    <span class="fas fa-map-marker-alt p-1"></span>
                                    <span class="text-dark" style="font-size: 12px!important;">
                                        {{BidLocation}}
                                    </span>
                                </div>

                                <div>
                                    <!-- <div class="mb-2 mt-2">
                                <h2 class="text-dark fw-bold m-0">Contact</h2>
                                <div>
                                    <span class="material-icons text-secondary fs-0">phone</span>
                                    <span class="m-3 text-dark">91-9876543210</span>
                                    <span class="m-3 text-dark">91-9876543210</span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mb-2">
                                <div>
                                    <span class="material-icons text-secondary fs-0">email</span>
                                    <span class="m-3 text-dark">ns1@gmail.com</span>
                                </div>
                                <div>
                                    <span class="fa fa-globe"></span>
                                    <span class="m-3 text-dark">web.iceipts.com</span>
                                </div>
                            </div> -->
                                    <div>
                                        <div class="text-dark " style="font-size: 12px!important;">
                                            <span class="" style="font-size: 12px!important;"> Expected Delivery Date
                                                :</span> {{bidExpectedDate}}
                                        </div>

                                        <div *ngIf="whitsStatus=='ACCEPTED'" class="text-warning"
                                            style="font-size: 12px!important;">Your bid is already accepted
                                        </div>


                                        <!-- <h3 class="text-dark ">
                                    <span class="fw-bold">Description :</span> {{productDescription}}
                                </h3> -->
                                    </div>
                                </div>

                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 ">
                                <div class="text-right">
                                    <!-- <h4 class="fs-1 fs-md-2 text-warning mb-0 mt-2" style="font-size: 20px!important;">
                                        <span>
                                            <mat-icon style="font-size: 18px;" class="iconcalender p-1 mt-2">
                                                currency_rupee
                                            </mat-icon>
                                        </span> {{bidProductprice}}
                                    </h4> -->

                                    <!-- <h5 class="fs--1 text-500 mb-0 mt-1">
                                <del>$2399 </del><span class="ms-1">-50%</span>
                            </h5> -->
                                </div>
                                <!-- ACCEPTED -->
                                <div class="row mt-8 text-center" *ngIf="whitsStatus=='PENDING'">
                                    <div class="col-lg-6 col-md-5 col-sm-6  col-xl-6">
                                        <a class="btn btn-sm btn-warning btn-block btn-width mb-1 text-nowrap pointer-event-cursor"
                                            data-bs-toggle="modal" data-bs-target="#acceptBid-modal"
                                            (click)="getAcceptData(bidPageApIRes)">
                                            <span>Accept Bid</span>
                                        </a>
                                    </div>
                                    <div class="col-lg-6 col-md-5 col-sm-6 col-xl-6">
                                        <a class="btn btn-sm btn-warning btn-block btn-width mb-1 text-nowrap pointer-event-cursor"
                                        (click)="RejectCustomerBid()">
                                            <span>Reject Bid</span>
                                        </a>
                                    </div>
                                    <!-- <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                                        <a class="btn-sm btn-warning d-lg-block mt-lg-3 pointer-event-cursor">
                                            <span class="p-2" data-bs-toggle="modal"
                                                data-bs-target="#acceptBid-modal" (click)="getAcceptData(bidPageApIRes)">Accept</span>
                                        </a>
                                    </div>
                                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                                        <a class="btn-sm btn-warning d-lg-block mt-lg-3 pointer-event-cursor">
                                            <span class="">Reject Bid</span>
                                        </a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" #closebutton id="acceptBid-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 500px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                    aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6">
                    <p class="d-none d-md-inline-block fs--1 mt-3 ml-5"
                        style="font-weight:800;font-size:20px!important;">
                        Delivery Details
                    </p>
                </div>
                <form [formGroup]="DeliveryForm">
                    <div class="pb-0 mb-2">
                        <div class="row m-2">
                            <div class="col-md-6">
                                <div class="d-flex " style="font-size: 12px;">
                                    <label class="text-nowrap">Home Delivery</label>
                                    <div class="form-check form-switch">
                                        <input style="margin-left: 2px!important;"
                                            class="form-check-input toggleB text-warning"
                                            style="margin-left:3px!important;" id="inlineCheckbox1" type="checkbox"
                                            formControlName="homeDelivery"
                                            (change)="showHomeDelevery($event.target.checked)" [checked]="isHome" />
                                        <!-- <label class="form-check-label toggleB " for="inlineCheckbox1">Yes</label> -->
                                    </div>
                                </div>

                            </div>
                            <!-- <div class="col-md-6">
                                <div class="d-flex ">
                                    <label class="text-nowrap">Collect From Shop</label>
                                    <div class="form-check form-switch">
                                        <input style="margin-left: 2px!important;"
                                            (change)="showShopCollect($event.target.checked)"
                                            class="form-check-input toggleB text-warning " id="inlineCheckbox1"
                                            type="checkbox" formControlName="ShopCollect" [checked]="isShop" />
                                         <label class="form-check-label toggleB " for="inlineCheckbox1">Yes</label> -->
                            <!-- </div>
                                </div>

                            </div> -->
                        </div>
                        <div class="row m-2" *ngIf="isShop && !isHome">
                            <div class="col-6">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Payment </mat-label>
                                    <mat-select matNativeControl formControlName="paymentMode"
                                        placeholder="paymentMode">
                                        <mat-option value="Regular">Online</mat-option>
                                        <mat-option value="Composite">Cash</mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>

                        </div>

                        <!-- <div class="row m-2">
                            <div class="col-md-6" *ngIf="isHome" style="font-size: 12px;">
                                <h5>Pincode</h5>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Pincode</mat-label>
                                    <input matNativeControl type="text" required matInput formControlName="pincode"
                                    minlength="6" maxlength="6" placeholder="Pincode" [ngClass]="{
                            'is-invalid': f.pincode.invalid && f.pincode.touched && f.pincode.pristine }" value="">
                                    <mat-error *ngIf="f.pincode.invalid && f.pincode.touched && f.pincode.pristine"
                                        class="invalid-feedback">
                                        <div class="error-font" *ngIf="f.pincode.errors.required">
                                            Pincode Is Required!</div>
                                    </mat-error>
                                </mat-form-field>

                            </div>
                        </div> -->
                        <!-- <div class="row m-2">
                            <div class="col-md-12">
                                <mat-form-field class="example-full-width" appearance="outline" *ngIf="isHome"
                                    style="font-size: 12px;">
                                    <mat-label>Building Name Street</mat-label>
                                    <input matNativeControl type="text" required matInput formControlName="street"
                                        placeholder="Building Name Street" [ngClass]="{
                            'is-invalid': f.street.invalid && f.street.touched && f.street.pristine }" value="">
                                    <mat-error *ngIf="f.street.invalid && f.street.touched && f.street.pristine"
                                        class="invalid-feedback">
                                        <div class="error-font" *ngIf="f.street.errors.required">
                                            street Is Required!</div>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="example-full-width" appearance="outline" *ngIf="isHome"
                                    style="font-size: 12px;">
                                    <mat-label>Area</mat-label>
                                    <input matNativeControl type="text" required matInput formControlName="area"
                                        placeholder="area" [ngClass]="{
                            'is-invalid': f.area.invalid && f.area.touched && f.area.pristine }" value="">
                                    <mat-error *ngIf="f.area.invalid && f.area.touched && f.area.pristine"
                                        class="invalid-feedback">
                                        <div class="error-font" *ngIf="f.area.errors.required">
                                            area Is Required!</div>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="example-full-width" appearance="outline" *ngIf="isHome"
                                    style="font-size: 12px;">
                                    <mat-label>City</mat-label>
                                    <input matNativeControl type="text" required matInput formControlName="city"
                                        placeholder="City" [ngClass]="{
                            'is-invalid': f.city.invalid && f.city.touched && f.city.pristine }" value="">
                                    <mat-error *ngIf="f.city.invalid && f.city.touched && f.city.pristine"
                                        class="invalid-feedback">
                                        <div class="error-font" *ngIf="f.city.errors.required">
                                            city Is Required!</div>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="example-full-width" appearance="outline" *ngIf="isHome"
                                    style="font-size: 12px;">
                                    <mat-label>Landmark</mat-label>
                                    <input matNativeControl type="text" required matInput formControlName="landmark"
                                        placeholder="Landmark" [ngClass]="{
                            'is-invalid': f.landmark.invalid && f.landmark.touched && f.landmark.pristine }" value="">
                                    <mat-error *ngIf="f.landmark.invalid && f.landmark.touched && f.landmark.pristine"
                                        class="invalid-feedback">
                                        <div class="error-font" *ngIf="f.landmark.errors.required">
                                            Landmark Is Required!</div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div> -->
                        <div class="row mt-3 mb-3 m-2">
                            <div class="col-6 text-center d-grid gap-2">
                                <a class="btn btn-warning" data-bs-dismiss="modal">Cancel</a>
                            </div>
                            <!-- <div class="col-6 d-grid">
                                <a class="btn btn-warning"
                                    (click)="postDeleveryDetails()" data-bs-dismiss="modal">Submit</a>
                            </div> -->
                            <div class="col-6 d-grid">
                                <a class="btn btn-warning" (click)="postDeleveryDetails()"
                                    data-bs-dismiss="modal">Submit</a>
                            </div>
                        </div>
                        <!-- place-order -->
                    </div>
                </form>
            </div>

            <!-- <app-popup [(warnTxt)]="warnTxt" [(isChkbox)]="isChkbox" [(chkBoxTxt)]="chkBoxTxt" [(id)]="productId"></app-popup> -->
        </div>
    </div>
</div>