import { Location, TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-book-appointment-shop',
  templateUrl: './book-appointment-shop.component.html',
  styleUrls: ['./book-appointment-shop.component.scss']
})
export class BookAppointmentShopComponent implements OnInit {
  public allAppointmentShops :any = [];
  public searchKey: any;
  public defaultScheduleImage = Constants.images.defaultScheduleImage
  private searchSubject = new Subject<string>();
  private searchSubscription: Subscription;
  isLoading: boolean = false;
  
  constructor( private appointmentService : AppointmentService,
    private router: Router,private toastService:ToastNotificationService,
    private toastNotificationService:ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    public _location: Location) { 
    }
    
    ngOnInit(): void {
    this.getAppointmentUsers();

    this.searchSubscription = this.searchSubject
      .pipe(debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.isLoading = true; // Show the loader when the API call is initiated
        })).subscribe(searchKey => {
          // Call the API with the search key
          if (searchKey.length >= 3) {
            this.getAppointmentUserssearch(searchKey);
          } else if (searchKey === '') {
            // this.isLoading = true; // Hide the loader if the search key is less than the threshold
            this.getAppointmentUserssearch(searchKey);
          } else {
            this.isLoading = false; // Hide the loader if the search key is less than the threshold
          }
        });
  }
  
  getAppointmentUsers() {
    this.appointmentService.getAppointmentUsers({}).then((res) => {
      this.allAppointmentShops = res.data
    },(err)=> {
      if(err.error.status === 404){
        this.toastService.toastMsg({
          title: "Error",
          content: "No data Found.",
        });
        // this.toastNotificationService.openErrorSnackBar("No data Found.");
      } else if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
    })
  }

  getAppointmentUserssearch(searchKey) {
    this.appointmentService.getAppointmentUsersSearch({},searchKey).then((res) => {
      if(res.success){
        this.allAppointmentShops = res.data;
        
        if(res.data.length < 1){          
          this.toastService.toastMsg({
            title: "Error",
            content: "Shop Not Found."
          });
        }
      }
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Shop Not Found."
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong."
          });
        }
    })
  }
  goTOBack(){
    this._location.back();
  }
  
  onSearchInputChange(searchKey: string) {
    // Emit the search key to the searchSubject
    this.searchSubject.next(searchKey);
  }
}
