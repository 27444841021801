<!-- Weekly Data -->
<!-- <script src="https://code.highcharts.com/modules/draggable-points.js"></script> -->
<!-- Weekly Sales -->
<div class="card widget" style="box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15)">
  <div class="card-header">
    <div class="row headerRow">
      <div class="col-sm-6">
        <span class="pt-3" style="font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 18px;">
          {{ salesType }} Sales
        </span>
      </div>
      <div class="col-sm-6">
        <div class="row justify-content-end">
          <div class="col-auto d-flex">
            <mat-form-field appearance="outline" style="width: 120px">
              <mat-select matNativeControl matInput disableOptionCentering [(value)]="defaultSaleSelect"
                (selectionChange)="getSaleInvoiceReportType($event.value)">
                <mat-option value="Daily" selected>Daily</mat-option>
                <mat-option value="Weekly">Weekly</mat-option>
                <mat-option value="Monthly">Monthly</mat-option>
                <mat-option value="Yearly">Yearly</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" style="height: 325px">
    <div class="max-w-100 line" [chart]="lineChart" style="margin: auto"></div>
    <!-- <div class="max-w-100" style="margin: auto" id="salesCanvas">
      {{ salesChart }}
    </div> -->
  </div>
</div>

<!-- Monthly Data -->
<!-- <div class="row g-0">
  <div class="col-lg-12 ps-lg-2 mb-3">
    <div class="card h-lg-100">
      <div class="card-header">
        <div class="row flex-between-center">
          <div class="col-auto">
            <h5 class="mb-0">Total Monthly Sales</h5>
          </div>
          <div class="col-auto d-flex">
            <mat-form-field appearance="outline">
              <input matInput [matDatepicker]="picker3" placeholder="" [(ngModel)]="monthSalesDateValue"
                (dateChange)="getUpdatedInvoiceMonthReport($event.target.value)">
              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-datepicker #picker3></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="card-body h-100 pe-0">
        <canvas class="max-w-100" id="salesMonthCanvas" width="1618" height="1000">{{salesMonthlyChart}}</canvas>
      </div>
    </div>
  </div>
</div>  -->

<!-- Yearly Data -->
<!-- <div class="row g-0">
  <div class="col-lg-12 ps-lg-2 mb-3">
    <div class="card h-lg-100">
      <div class="card-header">
        <div class="row flex-between-center">
          <div class="col-auto">
            <h5 class="mb-0">Total Yearly Sales</h5>
          </div>
          <div class="col-auto d-flex">
            <mat-form-field appearance="outline">
              <input matInput [matDatepicker]="picker5" placeholder="" [(ngModel)]="yearSalesDateValue"
                (dateChange)="getUpdatedInvoiceYearReport($event.target.value)">
              <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
              <mat-datepicker #picker5></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="card-body h-100 pe-0">
        <canvas class="max-w-100" id="salesYearCanvas" width="1618" height="1000">{{salesYearChart}}</canvas>
      </div>
    </div>
  </div>
</div>  -->