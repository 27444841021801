<!-- <nav>
    <button type="button" (click)="toggle()">Toggle Open/Close</button>
</nav>

<div>
     <div [@openClose]="'open'" class="open-close-container bg-white" style="width: 18rem;">
    <p class="">The box is now {{ isOpen ? 'Open' : 'Closed' }}!</p>
     </div>
</div> -->

<div class="row d-flex p-5 bg-dark mb-3">
    <div class="col mb-2">
        <div class="card m-auto font-style" style="width: 15rem;">
            <div class="card-header">
                <div class="">
                    <img [src]="defaultImage" class="rounded-circle center" width="100" height="100">
                </div>
            </div>
            <div class="card-body">
                <h5 class="card-title text-center">Card title</h5>
                Some quick example text to build on the card title and make up the bulk of the card's content.
            </div>
        </div>
    </div>
    <div class="col mb-2">
        <div class="card m-auto font-style" style="width: 15rem;">
            <div class="card-header">
                <div class="">
                    <img [src]="defaultImage" class="rounded-circle center" width="100" height="100">
                </div>
            </div>
            <div class="card-body">
                <h5 class="card-title text-center">Card title</h5>
                Some quick example text to build on the card title and make up the bulk of the card's content.
            </div>
        </div>
    </div>
    <div class="col mb-2">
        <div class="card m-auto font-style" style="width: 15rem;">
            <div class="card-header">
                <div class="">
                    <img [src]="defaultImage" class="rounded-circle center" width="100" height="100">
                </div>
            </div>
            <div class="card-body">
                <h5 class="card-title text-center">Card title</h5>
                Some quick example text to build on the card title and make up the bulk of the card's content.
            </div>
        </div>
    </div>
</div>