<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 4px;" class="modal-title  mb-0">Add Roles and Permissions</h1>

    <div class="addProductButtons">

        <button style="margin-right: 10px;" type="button" class="btn btn-cancel"
            (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-save" *appHasPermission="['create']; name 'admin'" (click)="addCustomRoles()">Save</button>

    </div>
</div>

    <div class="modal-body">
        <div class="">

            <form [formGroup]="rolesForm">
                <div class="row mt-3">
                    <div style="font-size: 12px" class="col-sm-12 col-md-6 col-lg-6">
                        <label class="form-label">Role Name<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" required placeholder="Role Name" matInput formControlName="RoleName" (keypress)="acceptString($event)"
                                [ngClass]="{
                                'is-invalid': (f.RoleName.invalid && f.RoleName.touched) && submitted }">
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="(submitted || f.RoleName.touched) && f.RoleName.errors?.required">
                            Role Name is required.
                        </mat-error>

                    </div>

                    <div style="font-size: 12px" class="col-sm-12 col-md-6 col-lg-6 ml-0">
                        <label class="form-label">Description<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">

                            <textarea matInput required formControlName="Description" rows="1" placeholder="Description"
                                [ngClass]="{
                                    'is-invalid': (f.Description.invalid && f.Description.touched) && submitted }"></textarea>
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="(submitted || f.Description.touched) && f.Description.errors?.required">
                            Description is required.
                        </mat-error>
                    </div>
                </div>


                <div class="row ">

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="table-responsive scrollbar table-bordered mt-2">
                            <table class="table table-striped bg-white">

                                    <tr class="btn-reveal-trigger">
                                        <th scope="col" class="tableheader">Permission Name</th>
                                        <th scope="col-md-2">Read</th>
                                        <th scope="col-md-2">Create</th>
                                        <th scope="col-md-2">Modify</th>
                                        <th scope="col-md-2">Delete</th>
                                        <th scope="col-md-2">All</th>
                                    </tr>

                                <tbody formArrayName="permissionsFormArray">
                                    <tr class="btn-reveal-trigger"
                                        *ngFor="let item of formArr.controls;let p of permissionData; let i = index"
                                        [formGroupName]="i">
                                        <td class="tableheaderchild">{{p.display | titlecase}}</td>
                                        <!-- read -->
                                        <td>
                                            <div class="mb-0">
                                                <input class="form-check-input" formControlName="read" [checked]="read"
                                                    type="checkbox" (change)="selectCrud(i,$event.target.checked)" />
                                            </div>
                                        </td>
                                        <!-- create -->
                                        <td>
                                            <div class=" mb-0">
                                                <input class="form-check-input"  formControlName="create" [checked]="create"
                                                    type="checkbox" (change)="selectCrud(i,$event.target.checked)" />
                                            </div>
                                        </td>
                                        <!-- Modify -->
                                        <td>
                                            <div class=" mb-0">
                                                <input class="form-check-input"  formControlName="modify" [checked]="modify"
                                                    type="checkbox" (change)="selectCrud(i,$event.target.checked)" />
                                            </div>
                                        </td>
                                        <!-- Delete -->
                                        <td>
                                            <div class=" mb-0">
                                                <input class="form-check-input" formControlName="delete" [checked]="delete"
                                                    type="checkbox" (change)="selectCrud(i,$event.target.checked)" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class=" mb-0">
                                                <input class="form-check-input" type="checkbox" [checked]="all"
                                                    formControlName="all"
                                                    (change)="selectAllCrudOption(i,$event.target.checked)" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <div class="mt-3 d-flex justify-content-end ">
                            
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </div>
            </div>



