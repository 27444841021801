import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-employee',
  templateUrl: './shop-employee.component.html',
  styleUrls: ['./shop-employee.component.scss']
})
export class ShopEmployeeComponent implements OnInit {
  employeeshop=[
    {
      "id":1,
      "email": "demo21@gmail.com",
      "mobile": "8783459890",
      "firstName": "john",
      "lastName": "hi",

  },
  {
    "id":2,
    "email": "demo21@gmail.com",
    "mobile": "8783459890",
    "firstName": "john smith",
    "lastName": "hi",

},

];

  constructor() { }

  ngOnInit(): void {
  }
  deleteEmployee(id:any){
    this.employeeshop = this.employeeshop.filter(x => x.id != id);

  }
}
