<div class="container-fluid mb-3 mx-0 bg-white">
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-md-12 col-xl-12">
            <p class="text-gray fw-bold mt-3 fs-1 icCenter">iCeipts Technology</p>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-lg-8 col-sm-12 col-md-8 col-xl-8">
            <p class="text-black fw-bold mt-3 fs-6">DAILY REPORT</p>
        </div>
        <div class="col-lg-4 col-sm-12 col-md-4 col-xl-4 d-flex justify-content-end align-items-center">
            <img class="me-2" src="assets/img/icons/spot-illustrations/test_logo.png" alt="iceipts logo" width="35"
                height="35" />
        </div>
    </div>
    <hr>

    <!-- table heading  -->
    <div class="row m-2">
        <div
            class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center headingbgcolor">
            Name
        </div>
        <div
            class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center headingbgcolor">
            Summary
        </div>
        <div
            class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center headingbgcolor">
            Print
        </div>
    </div>
    <!-- Table rows -->
    <div class="row m-2">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            All Day End
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">

        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#invoice-modal2"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>
    <div class="row m-2" *appHasPermission="['read']; name 'sales'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Sale
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp;{{allDataSale ? allDataSale: 0}}
        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#saledModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>
    <div class="row m-2" *appHasPermission="['read']; name 'salereturn'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Sale Return
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp;{{allDataSaleReturn ? allDataSaleReturn:0}}
        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#saledReturnModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>
    <div class="row m-2" *appHasPermission="['read']; name 'purchaseinvoicelist'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Purchase
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp; {{allDatapurchase ? allDatapurchase:0}}
        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#purchaseModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>
    <div class="row m-2" *appHasPermission="['read']; name 'purchasereturnlist'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Purchase Return
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp;{{allDatapurchaseReturn ? allDatapurchaseReturn:0}}
        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#purchaseReturnModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>
    <div class="row m-2" *appHasPermission="['read']; name 'bankdetails'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Cash In Hand
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp;{{allDataCashInHand? allDataCashInHand:0}}

        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#CashInHandModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>
    <div class="row m-2" *appHasPermission="['read']; name 'paymentin'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Payment In
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp;{{allDataPaymentIn? allDataPaymentIn:0}}

        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#paymentIndModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>
    <div class="row m-2" *appHasPermission="['read']; name 'paymentoutlist'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Payment Out
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp; {{allDataPaymentout? allDataPaymentout:0}}
        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#paymentOutdModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>
    <div class="row m-2" *appHasPermission="['read']; name 'estimation'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Estimation
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp;{{allDataEstimation? allDataEstimation:0}}
        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#EstimationModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>

    <div class="row m-2" *appHasPermission="['read']; name 'saleorder'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Sale Order
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp; {{allDataSaleOrder? allDataSaleOrder:0}}
        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#saledOrderModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>
    <div class="row m-2" *appHasPermission="['read']; name 'purchaseorderlist'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Purchase Order
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp;{{allDataPurchaseOrder? allDataPurchaseOrder:0}}
        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#purchaseOrderModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>
    <div class="row m-2" *appHasPermission="['read']; name 'expenses'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Expense
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp; {{allDataExpense? allDataExpense:0}}
        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#expenseModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>

    <div class="row m-2" *appHasPermission="['read']; name 'sundrydebtor'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Sundry Debtor
            </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp;{{saundryDebtorTotal? saundryDebtorTotal:0}}

        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#sundryDebModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>
    <div class="row m-2" *appHasPermission="['read']; name 'sundrycreditor'">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Sundry Creditor
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp; {{saundryCreditorTOTAL? saundryCreditorTOTAL:0}}

        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#sundryCreditorModal"
                (click)="getReceiptById()" style="height: 30px;">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div>
    <!-- <div class="row m-2">
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-left align-items-center rowbgcolor">
            Transaction
        </div>
        <div class="col-lg-5 col-sm-12 col-md-5 col-xl-5 d-flex justify-content-center align-items-center rowbgcolor">
            AED&nbsp; 0
        </div>
        <div class="col-lg-1 col-sm-12 col-md-1 col-xl-1 d-flex justify-content-center align-items-center rowbgcolor">
            <button class="btn btnp me-2" type="button" data-bs-toggle="modal" data-bs-target="#trModal"
                (click)="getReceiptById()">
                <span class="bi-printer-fill p-3" style="color: #110152;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="print"></span>
            </button>
        </div>
    </div> -->
</div>


<div class="modal fade" #closebutton id="invoice-modal2" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="normal-print">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrint()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date :  {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" style="width:50%" scope="col">Description</th>
                                            <th class="text-center text-white" style="width:50%" scope="col">Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td class="text-danger fw-bold" style="text-decoration:underline">All
                                                transactions (Sales ,Return, and Exhange)</td>
                                            <td></td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total(without Tax)</td>
                                            <td>{{trDataWithoutTax ? (trDataWithoutTax| number:'1.2-2') : 0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total(with Tax)</td>
                                            <td>{{trDataWithTax ? (trDataWithTax| number:'1.2-2') : 0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td>Tax</td>
                                            <td>{{trDataTax ? (trDataTax| number:'1.2-2') : 0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td class="text-danger fw-bold" style="text-decoration:underline">
                                                <a>Sales</a>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Sales (Without tax)</td>
                                            <td>{{saleDataWithoutTax ? (saleDataWithoutTax| number:'1.2-2') :0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Sales (With tax)</td>
                                            <td> {{saleDataWithTax ? (saleDataWithTax| number:'1.2-2'):0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td>Tax</td>
                                            <td>{{saleDataTax ? (saleDataTax| number:'1.2-2') : 0}}</td>
                                        </tr>

                                        <tr class="btn-reveal-trigger text-left">
                                            <td class="text-danger fw-bold" style="text-decoration:underline">
                                                <a>Sale Return</a>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Sale Return (Without tax)</td>
                                            <td>{{saleReturnDataWithoutTax ? (saleReturnDataWithoutTax| number:'1.2-2') :0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Sale Return (With tax)</td>
                                            <td> {{saleReturnDataWithTax ? (saleReturnDataWithTax | number:'1.2-2'):0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td>Tax</td>
                                            <td>{{saleReturnDataTax ? (saleReturnDataTax| number:'1.2-2') : 0}}</td>
                                        </tr>

                                        <tr class="btn-reveal-trigger text-left">
                                            <td class="text-danger fw-bold" style="text-decoration:underline">
                                                <a>Purchase </a>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Purchase (Without tax)</td>
                                            <td>{{purchaseDataWithoutTax ? (purchaseDataWithoutTax| number:'1.2-2') :0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Purchase (With tax)</td>
                                            <td> {{purchaseReturnDataWithTax ? (purchaseReturnDataWithTax| number:'1.2-2'):0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td>Tax</td>
                                            <td>{{purchaseDataTax ? (purchaseDataTax| number:'1.2-2') : 0}}</td>
                                        </tr>

                                        <tr class="btn-reveal-trigger text-left">
                                            <td class="text-danger fw-bold" style="text-decoration:underline">
                                                <a>Purchase Return </a>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Purchase Return(Without tax)</td>
                                            <td>{{purchaseReturnDataWithoutTax ? (purchaseReturnDataWithoutTax| number:'1.2-2') :0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Purchase Return(With tax)</td>
                                            <td> {{purchaseReturnDataWithTax ? (purchaseReturnDataWithTax| number:'1.2-2'):0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td>Tax</td>
                                            <td>{{purchaseReturnDataTax ? (purchaseReturnDataTax| number:'1.2-2') : 0}}</td>
                                        </tr>


                                        <tr class="btn-reveal-trigger text-left">
                                            <td class="text-danger fw-bold" style="text-decoration:underline">
                                                <a>Cash in Hand </a>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Cash In hand </td>
                                            <td>{{cashinHandData ? (cashinHandData| number:'1.2-2') :0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Payment Type</td>
                                            <td> {{cashinHandPaymentType ? cashinHandPaymentType:0}}</td>
                                        </tr>

                                        <tr class="btn-reveal-trigger text-left">
                                            <td class="text-danger fw-bold" style="text-decoration:underline">
                                                <a>payment In </a>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td>With Tax</td>
                                            <td>{{PaymentMinData ? (PaymentMinData| number:'1.2-2') :0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td class="text-danger fw-bold" style="text-decoration:underline">
                                                <a>payment Out </a>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td>payment Out (With Tax)</td>
                                            <td>{{PaymentMOutData ? (PaymentMOutData| number:'1.2-2') :0}}</td>
                                        </tr>

                                        <tr class="btn-reveal-trigger text-left">
                                            <td class="text-danger fw-bold" style="text-decoration:underline">
                                                <a>Estimation </a>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Estimation (Without tax)</td>
                                            <td>{{estimateWithoutTax ? (estimateWithoutTax| number:'1.2-2') :0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Estimation (With tax)</td>
                                            <td> {{estimateDataWithTax ? (estimateDataWithTax| number:'1.2-2') :0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td>Tax</td>
                                            <td>{{estimateDataTax ? (estimateDataTax| number:'1.2-2') : 0}}</td>
                                        </tr>


                                        <tr class="btn-reveal-trigger text-left">
                                            <td class="text-danger fw-bold" style="text-decoration:underline">
                                                <a>Sale Order </a>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Sale Order (Without tax)</td>
                                            <td>{{saleOrderWithoutTax ? (saleOrderWithoutTax| number:'1.2-2') :0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Sale Order (With tax)</td>
                                            <td> {{saleOrderDataWithTax ? (saleOrderDataWithTax| number:'1.2-2') :0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td>Tax</td>
                                            <td>{{saleOrderDataTax ? (saleOrderDataTax| number:'1.2-2') : 0}}</td>
                                        </tr>


                                        <tr class="btn-reveal-trigger text-left">
                                            <td class="text-danger fw-bold" style="text-decoration:underline">
                                                <a>Purchase Order </a>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td>Purchase Order(Without tax)</td>
                                            <td>{{purchaseOrderWithoutTax ? (purchaseOrderWithoutTax| number:'1.2-2') :0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td> Total Purchase Order (With tax)</td>
                                            <td> {{purchaseOrderDataWithTax ? (purchaseOrderDataWithTax| number:'1.2-2') :0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td>Tax</td>
                                            <td>{{purchaseOrderDataTax ? (purchaseOrderDataTax| number:'1.2-2') : 0}}</td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td class="text-danger fw-bold" style="text-decoration:underline">
                                                <a>Expense </a>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="btn-reveal-trigger text-left">
                                            <td>Total  Expense Amount</td>
                                            <td>{{expenseTotalAmount ? (expenseTotalAmount| number:'1.2-2') :0}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SAle---- -->
<div class="modal fade" #closebutton id="saledModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="Sale_print">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintaSale()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black generateDate">Generation Date :  {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" scope="col">Item Name</th>
                                            <th class="text-center text-white" scope="col">Quantity</th>
                                            <th class="text-end text-white" scope="col">Vat Price</th>
                                            <th class="text-end text-white" scope="col">Total price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of saleData">
                                            <td>{{item?.itemName}}</td>
                                            <td>{{item?.quantity}}</td>
                                            <td class="text-end">{{item?.gstPrice}}</td>
                                            <td class="text-end">AED&nbsp;{{item?.totalPrice}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="saleData == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SAle RETURN---- -->
<div class="modal fade" #closebutton id="saledReturnModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="Sale_Return_print">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintaSaleReturn()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date :  {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" scope="col">Item Name</th>
                                            <th class="text-center text-white" scope="col">Quantity</th>
                                            <th class="text-end text-white" scope="col">Vat Price</th>
                                            <th class="text-end text-white" scope="col">Total price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of salesReturnData">
                                            <td>{{item?.itemName}}</td>
                                            <td>{{item?.quantity}}</td>
                                            <td class="text-end">{{item?.gstPrice}}</td>
                                            <td class="text-end totalprize">AED&nbsp;{{item?.totalPrice}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="salesReturnData == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Purchase Model---- -->
<div class="modal fade" #closebutton id="purchaseModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="purchase_print">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintaPurchase()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date : {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" scope="col">Item Name</th>
                                            <th class="text-center text-white" scope="col">Quantity</th>
                                            <th class="text-end text-white" scope="col">Vat Price</th>
                                            <th class="text-end text-white" scope="col">Total price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of puchaseData">
                                            <td>{{item?.itemName}}</td>
                                            <td>{{item?.quantity}}</td>
                                            <td class="text-end">{{item?.gstPrice}}</td>
                                            <td class="text-end">AED&nbsp;{{item?.totalPrice}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="puchaseData == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Purchase Return Model---- -->
<div class="modal fade" #closebutton id="purchaseReturnModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="purchaseReturn_print">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintaPurchaseReturn()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date : {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" scope="col">Item Name</th>
                                            <th class="text-center text-white" scope="col">Quantity</th>
                                            <th class="text-end text-white" scope="col">Vat Price</th>
                                            <th class="text-end text-white" scope="col">Total price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of puchaseReturnData">
                                            <td>{{item?.itemName}}</td>
                                            <td>{{item?.quantity}}</td>
                                            <td class="text-end">{{item?.gstPrice}}</td>
                                            <td class="text-end"> AED&nbsp;{{item?.totalPrice}}</td>
                                        </tr>
                                    </tbody>

                                    <tbody *ngIf="puchaseReturnData == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Cash Ind hand modal --CashInHandModal_---- -->
<div class="modal fade" #closebutton id="CashInHandModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="normal_printCashInHand">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintCashInhand()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date : {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" scope="col">Payment Type</th>
                                            <th class="text-end text-white" scope="col">Enter Amount</th>
                                            <th class="text-center text-white" scope="col">Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left"
                                            *ngFor="let item of cashInHandSeparate">
                                            <td>{{item?.paymentType}}</td>
                                            <td class="text-end"> AED&nbsp; {{item?.enterAmount}}</td>
                                            <td>{{item?.remark}}</td>
                                        </tr>
                                    </tbody>

                                    <tbody *ngIf="cashInHandSeparate == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- payment In---- -->
<div class="modal fade" #closebutton id="paymentIndModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="normal_printPaymentIn">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintapyemnetIn()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date : {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-nowrap text-white" style="vertical-align:middle;width:200px;">Receipt #
                                            </th>
                                            <th class="text-nowrap text-white" style="vertical-align:middle;width:200px;">Date</th>
                                            <th class="text-nowrap text-white"
                                                style="vertical-align:middle;text-align: right;width:150px;">Received
                                            </th>
                                            <th class="text-nowrap text-white"
                                            style="vertical-align:middle;text-align: right;width:200px;">Pending Amount</th>

                                            <th class="text-nowrap text-white"
                                                style="vertical-align:middle;text-align: right;width:200px;">Actual Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of paymentIndata">

                                            <td class="text-nowrap" style="vertical-align:middle;">{{ item?.invoiceNo ?
                                                item?.invoiceNo : '-'}}</td>
                                            <td class="text-nowrap" style="vertical-align:middle;" class="text-nowrap">
                                                {{ item?.invoiceDate | date:"dd MMM
                                                YYYY"|
                                                uppercase }}</td>
                                            <td class="text-nowrap" style="vertical-align:middle;text-align: right;">
                                                AED&nbsp;{{ item?.received |
                                                number:'1.2-2'}}</td>
                                                <td class="text-nowrap" style="vertical-align:middle;text-align: right;">
                                                    AED&nbsp;{{ item?.balance |
                                                    number:'1.2-2'}}</td>
                                            <td class="text-nowrap" style="vertical-align:middle;text-align: right;">
                                                AED&nbsp;{{ item?.totalBillAmount |
                                                number:'1.2-2'}}</td>
                                        </tr>
                                    </tbody>


                                    <tbody *ngIf="paymentIndata == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- payment Out---- -->
<div class="modal fade" #closebutton id="paymentOutdModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="normal_printPaymentOut">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintapyemnetOut()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date : {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-nowrap text-white" style="vertical-align:middle;width:200px;">Receipt #
                                            </th>
                                            <th class="text-nowrap text-white" style="vertical-align:middle;width:200px;">Date</th>
                                            <th class="text-nowrap text-white"
                                                style="vertical-align:middle;text-align: right;width:150px;">Paid
                                            </th>
                                            <th class="text-nowrap text-white"
                                            style="vertical-align:middle;text-align: right;width:200px;">Pending Amount</th>

                                            <th class="text-nowrap text-white"
                                                style="vertical-align:middle;text-align: right;width:200px;">Actual Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of paymentIOutdata">

                                            <td class="text-nowrap" style="vertical-align:middle;">{{ item?.invoiceNo ?
                                                item?.invoiceNo : '-'}}</td>
                                            <td class="text-nowrap" style="vertical-align:middle;" class="text-nowrap">
                                                {{ item?.invoiceDate | date:"dd MMM
                                                YYYY"|
                                                uppercase }}</td>
                                            <td class="text-nowrap" style="vertical-align:middle;text-align: right;">
                                                AED&nbsp;{{ item?.received |
                                                number:'1.2-2'}}</td>
                                                <td class="text-nowrap" style="vertical-align:middle;text-align: right;">
                                                    AED&nbsp;{{ item?.balance |
                                                    number:'1.2-2'}}</td>
                                            <td class="text-nowrap" style="vertical-align:middle;text-align: right;">
                                                AED&nbsp;{{ item?.totalBillAmount |
                                                number:'1.2-2'}}</td>
                                        </tr>
                                    </tbody>


                                    <tbody *ngIf="paymentIOutdata == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Estimation Model  -->
<div class="modal fade" #closebutton id="EstimationModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="estimation_print">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintaEstimation()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date : {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" scope="col">Invoice number</th>
                                            <th class="text-end text-white" scope="col">Vat Price</th>
                                            <th class="text-end text-white" scope="col">Total Bill</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of estimationData">
                                            <td>{{item?.invoiceNo}}</td>
                                            <td class="text-end">{{item?.billGstAmount|number:'1.2-2'}}</td>
                                            <td class="text-end">AED{{item?.totalBillAmount|number:'1.2-2'}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="estimationData == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

 <!-- Sale Order -->
<div class="modal fade" #closebutton id="saledOrderModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="SaleOrder_print">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintaSaleOrder()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date : {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" scope="col">Item Name</th>
                                            <th class="text-center text-white" scope="col">Quantity</th>
                                            <th class="text-end text-white" scope="col">Vat Price</th>
                                            <th class="text-end text-white" scope="col">Total price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of saleOrderData">
                                            <td>{{item?.itemName}}</td>
                                            <td>{{item?.quantity}}</td>
                                            <td class="text-end">{{item?.gstPrice}}</td>
                                            <td class="text-end">AED&nbsp;{{item?.totalPrice}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="saleOrderData == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

 <!-- Purchase Order -->
 <div class="modal fade" #closebutton id="purchaseOrderModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="purchaseOrder_print">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintPurchaseOrder()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date : {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" scope="col">Item Name</th>
                                            <th class="text-center text-white" scope="col">Quantity</th>
                                            <th class="text-end text-white" scope="col">Vat Price</th>
                                            <th class="text-end text-white" scope="col">Total price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of purchaseOrderData">
                                            <td>{{item?.itemName}}</td>
                                            <td>{{item?.quantity}}</td>
                                            <td class="text-end">{{item?.gstPrice}}</td>
                                            <td class="text-end">AED&nbsp;{{item?.totalPrice}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="purchaseOrderData == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

 <!--Expense-->
 <div class="modal fade" #closebutton id="expenseModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="expense_print">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintExpense()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date : {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" scope="col">Expensense Id</th>
                                            <th class="text-center text-white" scope="col">Name</th>
                                            <th class="text-end text-white" scope="col">Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of expenseData">
                                            <td>{{item?.expensecategoryId}}</td>
                                            <td>{{item?.name}}</td>
                                            <td class="text-end">AED&nbsp;{{item?.totalAmount}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="expenseData == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Sundry Deb ---- -->
<div class="modal fade" #closebutton id="sundryDebModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="sundrydeb_print">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintaSundryDeb()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date :  {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" scope="col">Vendor Name</th>
                                            <th class="text-center text-white" scope="col">Email</th>
                                            <th class="text-center text-white" scope="col">TRN</th>
                                            <!-- <th class="text-end text-white" scope="col">No of Days	</th> -->
                                            <th class="text-center text-white" scope="col">Due Amount	</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of SundryDebData">
                                            <td >{{ item.partyName ||'' }}</td>
                                            <td>{{ item.email || ''}}</td>
                                            <td>{{ item.gstIn || ''}}</td>
                                            <!-- <td>{{item.creditPeriodDay}}</td> -->
                                            <td style="vertical-align:middle;text-align: right;" class="DueAmount">AED&nbsp;{{
                                                item.totalDebt
                                                | number:'1.2-2'}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="SundryDebData == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Sundry Creditor ---- -->
<div class="modal fade" #closebutton id="sundryCreditorModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="sundryCred_print">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintaSundryCredit()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date :  {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" scope="col">Vendor Name</th>
                                            <th class="text-center text-white" scope="col">Email</th>
                                            <th class="text-center text-white" scope="col">TRN</th>
                                            <!-- <th class="text-end text-white" scope="col">No of Days	</th> -->
                                            <th class="text-end text-white" scope="col">Pending Amount	</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of sundryCreditorData">
                                            <td >{{ item.partyName ||'' }}</td>
                                            <td>{{ item.email || ''}}</td>
                                            <td>{{ item.gstIn || ''}}</td>
                                            <!-- <td>{{item.creditPeriodDay}}</td> -->
                                            <td style="vertical-align:middle;text-align: right;" class="DueAmount">AED&nbsp;{{
                                                item.totalCredit
                                                | number:'1.2-2'}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="sundryCreditorData == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Transaction Modal---- -->
<div class="modal fade" #closebutton id="trModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="container" id="tr_print">
                    <div class="card" style="box-shadow: none !important;">
                        <div class="card-body container">
                            <div>
                                <button
                                    style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                    id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0 mt-5"
                                    type="button" (click)="onPrintaTr()"><span class="fas fa-print me-1">
                                    </span>Print
                                </button>
                            </div>
                            <div class="row mt-5">
                                <div
                                    class="col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
                                    <p class="text-black mt-3 fs-6 icCenter">iCeipts Technology</p>
                                </div>
                            </div>
                            <!-- second row  -->
                            <div class="row mt-5">
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
                                    <p class="text-black">Generation Date :  {{ todaysDate| date:"dd MMM
                                        YYYY"|
                                        uppercase }}</p>
                                </div>
                                <div
                                    class="col-lg-6 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end align-items-center">
                                    <!-- <p class="text-black">24-07-2024 10:00am 24-07-2023 11:30pm</p> -->
                                </div>
                            </div>

                            <!-- Table row -->

                            <div class="table-responsive scrollbar mt-3">
                                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                                    <thead style="background-color:rgb(56, 53, 53);color:white">
                                        <tr class="text-900" style="background-color:rgb(56, 53, 53);color:white">
                                            <th class="text-center text-white" scope="col">Item Name</th>
                                            <th class="text-center text-white" scope="col">Quantity</th>
                                            <th class="text-end text-white" scope="col">Vat Price</th>
                                            <th class="text-end text-white" scope="col">Total price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of trData">
                                            <td>{{item?.itemName}}</td>
                                            <td>{{item?.quantity}}</td>
                                            <td class="text-end">{{item?.gstPrice}}</td>
                                            <td class="text-end">AED&nbsp;{{item?.totalPrice}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="trData == 0">
                                        <tr class="text-center">
                                          <td colspan="12">
                                           No Data
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
