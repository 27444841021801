import { Component, OnInit } from '@angular/core';
import { RemoteShopService } from 'src/app/core/services/remote-shop.service';
import { Constants } from 'src/app/_helpers/constant';
import * as moment from 'moment';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-remote-retailer',
  templateUrl: './remote-retailer.component.html',
  styleUrls: ['./remote-retailer.component.scss']
})
export class RemoteRetailerComponent implements OnInit {
  public orderData :any;
  public orderData1 :any;


  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public searchKey: any;
  public totalbillAmount:any;
  public invoicedate:any;
  public dateRange: Date[];
  public today: Date;
  public selectedDate:any;
  public todaysDate;
  public orderlist:any;


  constructor(private remoteShopService: RemoteShopService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe) {
    this.getClientPendingOrder();
   }

  ngOnInit(): void {

    this.todaysDate = new Date(new Date().setDate(new Date().getDate()));
    this.getClientPendingOrder();
    
  }
  getClientPendingOrder(){
    this.remoteShopService.getGeneratedOrder({},Constants.currentUser.id,0).then((res)=>{
      console.log(res);
      this.orderData = res.data;
    })
  }
/* moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD') */
  // getAllclientPendingOrderSearch(searchKey){

  //   if(this.searchKey == ""){
  //     this.toastService.openErrorSnackBar("Please enter Invoice value!!!");
  //     this.remoteShopService.getRemoteOrder({},Constants.currentUser.id,0,searchKey).then((res)=>{
  //       console.log(res)
  //       this.orderData = res.data;
  
  //     })

  //   }
  //   else{
  //     this.remoteShopService.getRemoteOrder({},Constants.currentUser.id,0,searchKey).then((res)=>{
  //       console.log(res)
  //       this.orderData = res.data;
  //       if(this.orderData.length > 0){
          
  //         this.toastService.openSnackBar("Invoice Value Found Successfully!!!");
  //       }else{
  //         this.toastService.openErrorSnackBar("Invoice Value Not Found!!!");
  //       }
  
  //     },(err) => {
  //       if (err.error.expose) {
  //         this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
  //       }
  //       else {
  //         this.toastService.openErrorSnackBar("Something Went Wrong.");
  //       }
  //     })
  //   }

  // }

  getAllclientPendingOrderSearch1(){
    this.remoteShopService.getRemoteOrder1({},Constants.currentUser.id,0,moment(this.todaysDate, 'DD-MMM-YYYY').format('YYYY-MM-DD')).then((res)=>{
      console.log(res)
      this.orderData1 = res.data;
      if(this.orderData1.length > 0){
        this.orderData = res.data;
      }
      else{
        this.getClientPendingOrder();
      }
      

    })

  }

  getAllRemoteOrderSearch(searchkey){
    console.log(this.searchKey)
    if(this.searchKey == ""){
      this.toastService.toastMsg({
        title: "Error",
        content: "Please Enter Party Name!!!"
        });
      // this.toastService.openErrorSnackBar("Please enter Party name!!!");
      this.remoteShopService.getRemoteOrdersearch({},this.currentUser.id,0,searchkey).then((res)=>{
        console.log(res)
        this.orderData = res.data;

      })
      
    }
    else{
      this.remoteShopService.getRemoteOrdersearch({},this.currentUser.id,0,searchkey).then((res)=>{
        console.log(res)
        this.orderData1 = res.data;
        if(this.orderData1.length > 0){
          this.orderData = res.data;
          this.toastService.toastMsg({
            title: "Success",
            content: "Record Found Successfully!",
          })
          // this.toastService.openSnackBar("Record Found Successfully!!!");
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Record Not Found!"
            });
          // this.toastService.openErrorSnackBar("Record Not Found!!!");
        }
        
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message)
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong."
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })

    }
   

  }

  changeDate(e){
    this.getAllclientPendingOrderSearch1();
    }
}
