<div class="modal-header pt-0">
  <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title mb-0">Add Expense</h1>

  <div class="addProductButtons">

    <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
      (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-save" (click)="postexpenseDetails()" *appHasPermission="['create']; name 'expenses'">Save</button>

  </div>
</div>
<div class="modal-body">
  <div class="container-fluid px-2">
    <form [formGroup]="expenseForm">
      <div class="row mt-2">
        <div class="col-sm-12 col-lg-6 col-md-12 col-xl-6">
          <div class="mb-2">
            <label class="form-label">Expense Type<span class="error-asterisk">*</span> </label>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-select matNativeControl disableOptionCentering placeholder="Expense Type"
                formControlName="expenseType"
                [ngClass]="{'is-invalid':f.expenseType.invalid && f.expenseType.touched && submitted}">
                <mat-option value="Operating Expenses" (click)="getExpensecategory('Operating Expenses')">Operating
                  Expense</mat-option>
                <mat-option value="COGS" (click)="getExpensecategory('COGS')">COGS</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error class="text-danger"
              *ngIf="(submitted || f.expenseType.touched) && f.expenseType.errors?.required">
              Expense Type is Required.
            </mat-error>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 col-md-12 col-xl-6">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 col-lg-6 col-md-12 col-xl-6">
          <div class="mb-2">
            <label class="form-label">Expense Category<span class="error-asterisk">*</span></label>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-select matNativeControl disableOptionCentering required placeholder="Expense Category"
                formControlName="expenseCategory" (selectionChange)="SetExpenseNAture($event.value)">
                <mat-option *ngFor="let item of expenseCategoryData" [value]="item.id" class="text-capitalize">
                  {{item.name | titlecase}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error class="text-danger"
              *ngIf="(submitted || f.expenseCategory.touched) && f.expenseCategory.errors?.required">
              Expense Category is Required.
            </mat-error>

          </div>
        </div>
        <div class="col-sm-12 col-lg-6 col-md-12 col-xl-6">
          <div class="mb-2">
            <label class="form-label">Date<span class="error-asterisk">*</span></label>
            <mat-form-field class="example-full-width" appearance="outline">
              <input matInput required bsDatepicker placeholder="DD MMM YYYY" [maxDate]="today"
                style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                        containerClass: 'theme-dark-blue',
                        dateInputFormat: 'DD MMM YYYY',
                         showWeekNumbers:false
                        }" (click)="openDatepicker()" formControlName="expenseDate" autocomplete="off">
              <span>
                <mat-icon class="iconcalender p-1">date_range</mat-icon>
              </span>
            </mat-form-field>
            <mat-error class="text-danger"
              *ngIf="(submitted || f.expenseDate.touched) && f.expenseDate.errors?.required">
              Date is Required.
            </mat-error>

          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-lg-12 col-md-12 col-xl-12 col-sm-12">

        </div>
      </div> -->
      <!-- For table row  -->
      <div class="row mb-2" *ngIf="expenseListType === 'COGS'">
        <div class="table-responsive">
          <table class="table table-bordered fs--1 mb-0">
            <thead class="text-900 text-center inner-model-table-heading">
              <tr>
                <th class="p-2" style="width:10% !important">#</th>
                <th class="p-2" style="width:50% !important">Item<span class="error-asterisk">*</span></th>
                <th class="p-2" style="width:20% !important">Quantity <span class="error-asterisk">*</span></th>
                <th class="p-2">Price/Unit<span class="error-asterisk">*</span></th>
                <th class="p-2">Amount</th>
                <th class="p-2">X</th>
              </tr>
            </thead>
            <!-- <tbody>
              <ng-container formArrayName="addtionalDetails" *ngFor="
                      let group of additionalDetailsControls.controls;
                      let i = index
                    ">
                <tr [formGroupName]="i">
                  <td style="vertical-align:middle;">{{i+1}}</td>
                  <td style="vertical-align:middle;">
                    <input matInput type="text" required formControlName="item" />
                  </td>
                  <td style="vertical-align:middle;"> <input (keyup)="setTotalValue(i)" matInput type="text"
                      formControlName="quantity" /></td>
                  <td style="vertical-align:middle;"> <input matInput (keyup)="setTotalValue(i)"
                      (keyup.enter)="addAddtionalDetails()" type="text" formControlName="pricePerUnit" /></td>
                  <td style="vertical-align:middle;"> <input matInput type="text" formControlName="totalAmount"
                      readonly /></td>
                  <td class=" Icons">
                    <button class="btn" type="button" (click)="deleteaddAddtionalDetails(group)">
                      <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                        title="Delete" style="color: red;"></span>
                      < Delete -->
                    <!-- </button>
                  </td>
                </tr>
              </ng-container>
            </tbody> -->
            <tbody>
              <ng-container formArrayName="addtionalDetails" *ngFor="let group of additionalDetailsControls.controls; let i = index">
                <tr [formGroupName]="i">
                  <td style="vertical-align:middle;">{{i + 1}}</td>

                  <td style="vertical-align:middle;">
                    <input matInput type="text" required formControlName="item" (keyup)="setTotalValue(i)"/>
                    <div *ngIf="(submitted || group.get('item').touched) &&  group.get('item').errors?.required">
                      <small class="text-danger" >Item is required.</small>
                    </div>
                  </td>

                  <td style="vertical-align:middle;">
                    <input matInput type="text" required formControlName="quantity" (keyup)="setTotalValue(i)" />
                    <div *ngIf="(submitted || group.get('quantity').touched) &&  group.get('quantity').errors?.required">
                      <small class="text-danger" >Quantity is required.</small>
                    </div>
                  </td>
                  
                  <td style="vertical-align:middle;">
                    <input matInput type="text" required formControlName="pricePerUnit" (keyup)="setTotalValue(i)" (keyup.enter)="addAddtionalDetails()" />
                    <!-- <div *ngIf="group.get('pricePerUnit')?.invalid && (group.get('pricePerUnit')?.touched || group.get('pricePerUnit')?.dirty)">
                      <small class="text-danger" *ngIf="group.get('pricePerUnit')?.errors?.['required']">Price per unit is required.</small>
                    </div> -->
                    <div *ngIf="(submitted || group.get('pricePerUnit').touched) &&  group.get('pricePerUnit').errors?.required">
                      <small class="text-danger" >pricePerUnit is required.</small>
                    </div>
                  </td>
                  <td style="vertical-align:middle;">
                    <input matInput type="text" formControlName="totalAmount" readonly />
                  </td>
                  <td class="Icons">
                    <button class="btn" type="button" (click)="deleteaddAddtionalDetails(i)">
                      <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" style="color: red;"></span>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
            

          </table>
          <div class="row d-flex justify-content-end mt-2">
            <div class="col-sm-12 col-md-6 col-lg-6 text-nowrap mb-1 d-grid">
             <a class="btn btn-outline-warning dash fs--1" (click)="addAddtionalDetails()">
                <span id="boot-icon" class="bi bi-plus-lg newrow"></span>
                <span class="me-2 fs--1 text-black newrow" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Add Row">
                  Add New Row</span>
              </a>
            </div>
          </div>
        </div>





      </div>
      <!-- Expense Mode -->
      <div class="row">

        <!-- <div class="col-lg-6 col-md-12 col-xl-6  mt-2">
          <label class="form-label">Payment Mode</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl matInput disableOptionCentering  placeholder="Payment Mode" formControlName="paymentMode">
              <mat-option value="UPI">UPI</mat-option>
              <mat-option value="CASH">CASH</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div class="col-lg-6 col-md-12 col-xl-6 mt-2">
          <label class="form-label">Payment Mode<span class="error-asterisk">*</span></label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl matInput disableOptionCentering placeholder="Payment Mode"
              formControlName="paymentMode" required
              [ngClass]="{'is-invalid': f.paymentMode.invalid && (submitted || f.paymentMode.touched)}">
              <mat-option value="UPI">UPI</mat-option>
              <mat-option value="CASH">CASH</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.paymentMode.touched) && f.paymentMode.errors?.required">
            Payment Mode is required.
          </mat-error>
        </div>

        <div class="col-lg-6 col-md-12 col-xl-6  mt-2">
          <label class="form-label">Total Amount<span class="error-asterisk">*</span></label>
          <!-- </div>
        <div class="col-lg-3 col-md-6 col-xl-3 mt-4"> -->
          <mat-form-field class="example-full-width" appearance="outline"
            *ngIf="expenseForm.get('expenseType').value !== 'COGS'">
            <input matNativeControl required type="text" placeholder="Total Amount" formControlName="toatalAmount"
              (keypress)="numberOnly($event)" />
          </mat-form-field>
          <mat-error class="text-danger"
            *ngIf="(submitted || f.toatalAmount.touched) && f.toatalAmount.errors?.required">
            Total Amount is Required.
          </mat-error>

          <mat-form-field class="example-full-width" appearance="outline"
            *ngIf="expenseForm.get('expenseType').value === 'COGS'">
            <input matNativeControl type="text" readonly disabled placeholder="Total Amount"
              formControlName="toatalAmount" />
          </mat-form-field>
        </div>

      </div>
      <!-- Note -->
      <div class="row mt-2">
        <div class="col-sm-12 col-lg-12 col-md-12 col-xl-12">
          <div class="mb-2">
            <label class="form-label">Note <span class="error-asterisk">*</span></label>
            <mat-form-field class="example-full-width" appearance="outline">
              <input matNativeControl matInput placeholder="Note" formControlName="expenseNote" autocomplete="off"
                required [ngClass]="{'is-invalid':f.expenseNote.invalid && f.expenseNote.touched && submitted}" />
            </mat-form-field>
            <mat-error class="text-danger"
              *ngIf="(submitted || f.expenseNote.touched) && f.expenseNote.errors?.required">
              Expense Note is Required.
            </mat-error>

          </div>

        </div>
      </div>
    </form>

  </div>

</div>