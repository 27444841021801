<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 5px;" class="modal-title mb-0">Create Coupons</h1>

    <div class="addProductButtons">
        <button type="button" style="margin-right: 10px;" class="btn btn-cancel" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-save ms-2" (click)="onSave()" *appHasPermission="['create']; name 'coupons'">Save</button>
    </div>
</div>

<div class="modal-body">
    <form [formGroup]="promotionForm">
        <div class="row mb-3">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label class="form-label">Coupon Name<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input matInput placeholder="Coupon Name" maxlength="50" formControlName="coupon_name" required>
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submitted || f.coupon_name.touched) && f.coupon_name.errors?.required">
                Coupon name is required.
              </mat-error>
            </div>
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label class="form-label">Coupon Code<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input matInput placeholder="Coupon Code"  maxlength="8" minlength="3" formControlName="coupon_code" required>
                <span matSuffix>
                  <a style="cursor: pointer; color: 110152;" (click)="generateCoupon()" matTooltip="Generate Coupon">
                    <i class="fas fa-sync-alt"></i>
                  </a>
                </span>
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submitted || f.coupon_code.touched) && f.coupon_code.errors?.required">
                Coupon Code is required.
              </mat-error>
              <mat-error class="text-danger" *ngIf="f.coupon_code.errors?.minlength">
                Coupon Code must be greater than 3 characters.
              </mat-error>
            </div>
        </div>         

        <div class="row mb-3">
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Discount Percentage<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Discount Percentage" maxlength="3" formControlName="discountPercentage" (keypress)="numberOnly($event)">
                    <span class="matSuffix" matSuffix>%</span>
                </mat-form-field>
                <mat-error *ngIf="f.discountPercentage.errors?.max">
                    Discount Percentage cannot exceed 100%.
                </mat-error>
                <mat-error class="text-danger" *ngIf="(submitted || f.discountPercentage.touched) && f.discountPercentage.errors?.required">
                  Discount percentage is required.
                </mat-error>
            </div>
            
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Discount Upto<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Max Discount Amount" maxlength="10" formControlName="maxDiscountAmount" (keypress)="numberOnly($event)">
                    <span class="matSuffix" matSuffix>AED</span>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.maxDiscountAmount.touched) && f.maxDiscountAmount.errors?.required">
                  Discount upto amount is required.
                </mat-error>
            </div>

        </div>
        
        <div class="row mb-3">
          <label class="form-label">Description</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <textarea matInput autocomplete="off" formControlName="description" rows="3" maxlength="150" placeholder="Description">
            </textarea>
          </mat-form-field>
        </div>
        
        <div class="row mb-3">
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Start date<span class="error-asterisk">*</span></label>
                <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                    <input matInput bsDatepicker required placeholder="Start Date" formControlName="startDate"
                      bsDatepicker [bsConfig]="{
                        containerClass: 'theme-dark-blue',
                        dateInputFormat: 'DD MMM YYYY',
                        showWeekNumbers: false,
                        minDate: today
                      }" [ngClass]="{
                        'is-invalid':
                          f.startDate.invalid && f.startDate.touched && submitted
                      }" (click)="openDatepicker('startDate')" />
                    <span>
                      <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="
                      (submitted || f.startDate.touched) &&
                      f.startDate.errors?.required
                    ">
                    Start Date is Required.
                  </mat-error>
            </div>
            
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Expiry Date<span class="error-asterisk">*</span></label>
                <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                    <input matInput bsDatepicker placeholder="Expiry Date" [minDate]="calculateMinDate()"
                      formControlName="expiryDate" bsDatepicker required [bsConfig]="{
                        containerClass: 'theme-dark-blue',
                        dateInputFormat: 'DD MMM YYYY',
                        showWeekNumbers: false,
                        showOnFocus: true
                      }" (click)="openDatepicker('expiryDate')" />
                    <span>
                      <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="
                      (submitted || f.expiryDate.touched) &&
                      f.expiryDate.errors?.required
                    ">
                    Expiry Date is Required.
                  </mat-error>
            </div>

        </div>

        <div class="row mb-3">
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Usage Limit</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Usage Limit" maxlength="8" formControlName="usage_limit" (keypress)="numberOnly($event)">
                </mat-form-field>
            </div>
        </div>
    </form>    
</div>