import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-total-net-profit',
  templateUrl: './total-net-profit.component.html',
  styleUrls: ['./total-net-profit.component.scss'],
})
export class TotalNetProfitComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  netProfit: number;
  todaysProductSoldPercent: any;
  netSales: number;
  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.getNetSalesProfit();
  }

  // (for now only net sales in given and profit will be doing later)
  getNetSalesProfit() {
    this.dashboardService.getNetSalesProfit({}, this.currentUser.id).then(
      (res: any) => {
        // // console.log(res.data);
        this.netSales = res.data.parties[0].net_sales;
        this.netProfit = res.data.netProfit;
      },
      (err) => {
        if (err.error.status == 404) {
          // this.newPartiesList = [];
        }
      }
    );
  }
}
