<!-- <h2 mat-dialog-title>Select Price Range</h2>

<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Minimum Price</mat-label>
    <input type="range" min="0" max="1000" [(ngModel)]="priceRange" (input)="onPriceRangeChange()">
  </mat-form-field>

  <p>Minimum Price: {{ minPrice }}</p>
  <p>Maximum Price: {{ maxPrice }}</p>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="apply()">Apply</button>
</div> -->


<h2 mat-dialog-title>Select Price Range</h2>
<div mat-dialog-content  style="margin-right: 0px !important; overflow: hidden;">
  <label class="form-label">Minimum Price</label>
  <mat-form-field class="example-full-width" appearance="outline">
    <input matNativeControl required matInput [(ngModel)]="minPrice" value="0">
  </mat-form-field>

<!-- <h2 mat-dialog-title>Select Price Range</h2>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Minimum Price</mat-label>
    <input matInput type="number" [(ngModel)]="minPrice" value="0"> -->
  <!-- </mat-form-field> -->
  <label class="form-label">Maximum Price</label>
  <mat-form-field class="example-full-width" appearance="outline">
    <input matNativeControl required matInput [(ngModel)]="maxPrice" value="100000">
</mat-form-field>
  <!-- <mat-form-field>
    <mat-label>Maximum Price</mat-label>
    <input matInput type="number" [(ngModel)]="maxPrice" value="100000">
  </mat-form-field> -->
</div>

<div mat-dialog-actions>
  <!-- <button mat-button (click)="cancel()">Cancel</button> -->
  <!-- <button mat-button (click)="apply()">Apply</button> -->
  <button style="margin-left: 50px !important;" type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>

  <button style="margin-left: 10px !important;" type="button" class="btn btn-save" (click)="apply()">Apply</button>

  <!-- <button mat-button (click)="Save()">Save</button> -->

</div>