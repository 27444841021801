<div class="modal-header pt-0">
  <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:5px;" class="modal-title mb-0">Sale Return</h1>
  <div class="addProductButtons">
    <button style="margin-right: 10px;" type="button" class="btn btn-cancel"
      (click)="onCancelModal()">Cancel</button>
    <button type="button" class="btn btn-save" (click)="SaveSaleReturn()" *appHasPermission="['create']; name 'salereturn'">Save</button>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="saleReturnForm">
    <div class="row my-2 d-flex justify-content-end" style="margin-left: 8px;">
      <div class=""></div>
      <div class="col-sm-12 col-md-12 col-lg-12 d-flex mt-1 mt-sm-0 justify-content-end">
        <label style="font-size: 14px;font-weight: 500;" class="form-check-label form-label me-2"
          [ngClass]="lblColor ? 'labelDark' : 'labelLight'" for="customSwitch1">Full Return</label>
        <div class="form-check form-switch mb-0">
          <input class="form-check-input" id="customSwitch1" formControlName="returnType" type="checkbox"
            checked="checked" (click)="getRadioBtnValue($event.target.checked)" />
          <label class="form-check-label form-label align-top" for="customSwitch1"
            style="font-size: 14px;font-weight: 500;">Partial Return</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4">
        <label class="form-label">Sale Invoice #<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
          <input type="text" placeholder="Sale Invoice #" matInput formControlName="saleInvoiceno"
            (keyup.enter)="getInvoiceById($event.target.value)" id="inputEmail3">
        </mat-form-field>
        <mat-error class="text-danger"
          *ngIf="(submitted || f.saleInvoiceno.touched) && f.saleInvoiceno.errors?.required">
          Sale Invoice # Is Required.
        </mat-error>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4">
        <label class="form-label">Sale Return #<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
          <input type="text" matInput formControlName="SaleReturnNo" placeholder="Sale Return #">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.SaleReturnNo.touched) && f.SaleReturnNo.errors?.required">
          Sale Return # Is Required.
        </mat-error>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4">
        <label class="form-label">Date<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
          <input matInput required ngModel bsDatepicker placeholder="Date." [(ngModel)]="todaysDate"
            [maxDate]="today" id="datePicker" [bsConfig]="{
              containerClass: 'theme-dark-blue',
              dateInputFormat: 'DD MMM YYYY',
              showWeekNumbers:false
            }" formControlName="invoiceDate" (click)="openDatepicker()"
            [ngClass]="{
              'is-invalid': (f.invoiceDate.invalid && f.invoiceDate.touched) && submitted }" >
          <mat-icon class="iconcalender">date_range</mat-icon>
        </mat-form-field>
        
        <mat-error class="text-danger" *ngIf="(submitted || f.invoiceDate.touched) && f.invoiceDate.errors?.required">
          Date Is Required.
        </mat-error>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4" *appHasPermission="['delete', 'modify','create', 'read']; name 'warehouse'">
        <div [ngSwitch]="currentUser.userType">
          <div *ngSwitchCase="'CLIENT'" style="font-size: 14px">
            <label class="form-label">Warehouse</label>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                <mat-option value="clear">Clear Selection</mat-option>
                <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                  {{warehouse.firstName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'SHOP'" style="font-size: 14px">
            <label class="form-label">Warehouse</label>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                <mat-option value="clear">Clear Selection</mat-option>
                <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                  {{warehouse.firstName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
      </div>
      </div>
    </div>

    <div class="table-responsive mt-2  my-custom-scrollbar ">
      <table class="table table-bordered p-2">
        <thead class="inner-model-table-heading">
          <tr class="font_size font-weight-bold">
            <th style="vertical-align: middle;" scope="col">#</th>
            <th scope="col" style="width: 10%;vertical-align: middle;">
              SKU<span class="text-danger"> *</span>
            </th>
            <th style="vertical-align: middle;" class="text-nowrap">SKU Description</th>
            <th style="vertical-align: middle;">Unit</th>

            <th style="vertical-align: middle;">Quantity</th>
            <th style="vertical-align: middle;" scope="col" class="text-nowrap" colspan="2">
              {{ lang.Base_Price_In_Rs }}<span class="text-danger"> *</span>
            </th>
            <th style="vertical-align: middle;" class="text-center" scope="col" colspan="2">
              {{lang.GST}}<span class="text-danger"> *</span>
            </th>
            <th style="vertical-align: middle;" class="text-nowrap" scope="col ">
              {{lang.Total_In_Rs}}<span class="text-danger text-nowrap"> *</span>
            </th>
            <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
              Action<span class="text-danger"> *</span>
            </th>
          </tr>
        </thead>

        <tbody class="mod">
          <tr>
            <td></td>
            <td>
                <mat-form-field class="example-full-width" appearance="outline">
                  <input type="text" id="inp12" placeholder="SKU" (input)="onSearchInputChange($event.target.value)"
                      matInput [formControl]="myControl1" [matAutocomplete]="productAuto">
                  <mat-icon class="m-2" matSuffix>search</mat-icon>        
                  <mat-autocomplete #productAuto="matAutocomplete">
                      <mat-option *ngFor="let option of productOptions | async" [value]="option.itemName"
                      (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                      <span style="font-size: 12px;">
                      {{option.itemName | titlecase}}/<b>{{ option.barcode}}</b>
                      </span>
                  </mat-option>
                  </mat-autocomplete>
              </mat-form-field>
            </td>
            <td>
              <input type="text" matInput placeholder="SKU Description" required />
            </td>

            <td colspan="1">
              <input type="text" matInput readonly placeholder="Unit" required />
            </td>
            <td colspan="1">
              <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
            </td>
            <td colspan="1">
              <input type="text" matInput readonly placeholder="Price / Unit" />
            </td>
            <td colspan="1">
              <input type="text" matInput readonly placeholder="Price" />
            </td>
            <td colspan="1">
              <input type="text" matInput readonly required placeholder="%" />
            </td>
            <td colspan="1">
              <input type="text" matInput readonly required placeholder="In AED"  style="width: 40px;" />
            </td>
            <td>
              <input type="text" matInput readonly placeholder="Amount" style="max-width: 150px;" required />
            </td>
            <td></td>
          </tr>
          <tr *ngFor="let item of productData;let i=index">
            <td style="vertical-align: middle;">{{ i + 1 }}</td>

            <td>
              {{ item.barcode }}
            </td>
            <td>{{ item.itemName | titlecase}}</td>
            <td class="text-center">
              <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit" readonly />
            </td>
            <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
              <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                (click)="changeQuantity('-',i)">-</a>
              <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;text-align: center;"
                required />
              <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                (click)="changeQuantity('+',i)">+</a>
            </td>
            <td colspan="1">
              <p class="">
                AED&nbsp;{{ (item.pricePerUnit/(1 + item.gst/100)) | number:'1.2-2'}}
              </p>
            </td>
            <td colspan="1">
              <p class="">
                AED&nbsp;{{ ((item.pricePerUnit/(1 + item.gst/100)) * item.quantity) | number:'1.2-2'}}
              </p>
            </td>
            <td colspan="1">
              <p class="" style="width: 3rem;">
                {{ item.gst }}%
              </p>
            </td>
            <td colspan="1" style="max-width: 150px;">
              <p class="">
                AED&nbsp;{{ item.gstAmount | number:'1.2-2'}}
              </p>
            </td>

            <td style="max-width: 150px;">
              <p class="">
                AED&nbsp;{{ item.finalAmount | number:'1.2-2'}}
              </p>
            </td>
            <td readonly>
              <button type="button" class="text-center btnb" style="width: 3rem!important;"
                (click)="changeQuantity('rm',i)">
                <span class="bi-trash icon-color fs-1" style="color: red;" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Delete"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive my-custom-scrollbar justify-content-start row">
      <div class="col-sm-12 col-md-6 col-lg-9"></div>
      <div class="col-sm-12 col-md-6 col-lg-3 table-bordered my-custom-scrollbar">
        <table class="table table-bordered p-5 my-custom-scrollbar">
          <tr>
            <td class="thWidth p-2" style="text-align:left!important">Subtotal</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important">
              AED&nbsp;{{ totalAmount - GstAmount | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth p-2" style="text-align:left!important">{{lang.CGST}}</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important">
              AED&nbsp;{{ GstAmount | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth p-2" style="text-align:left!important">Total</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important">
              AED&nbsp;{{ totalAmount | number:'1.2-2'}}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </form>
</div>