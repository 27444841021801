<div class="modal-header pt-0">
    <h1 style="color: #eb8b3f; font-weight: 500" class="modal-title mb-0 ms-2">
        Create Template
    </h1>

    <div class="addProductButtons">
        <button style="margin-right: 10px" type="button" class="btn btn-cancel" (click)="cancel()">
            Cancel
        </button>

        <button type="button" class="btn btn-save" (click)="submitForm()">
            Save
        </button>
    </div>
</div>

<div class="modal-body">
    <form [formGroup]="templateForm">      
        <div class="row">
            <div class="col-sm-12 col-lg-12 col-md-12 mb-3">
                <label class="form-label">Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput formControlName="name" required placeholder="Name" maxlength="100"
                        [ngClass]="{
              'is-invalid':
                templateForm.get('name').invalid &&
                templateForm.get('name').touched &&
                submitted
            }" />
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="
                    (submitted || templateForm.get('name').touched) &&
                    templateForm.get('name').errors?.required
                ">
                    Name is required.
                </mat-error>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-lg-12 col-md-12 mb-3">
                <label class="form-label">Subject<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="subject" row="1" placeholder="Subject"
                        maxlength="250" [ngClass]="{
              'is-invalid':
                templateForm.get('subject').invalid &&
                templateForm.get('subject').touched &&
                submitted
            }">
          </textarea>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="
                    (submitted || templateForm.get('subject').touched) &&
                    templateForm.get('subject').errors?.required
                ">
                    Subject is required.
                </mat-error>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-12 col-md-6 mb-3">
                <label class="form-label">Header<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="header" row="2" placeholder="Header"
                        maxlength="150" [ngClass]="{
              'is-invalid':
                templateForm.get('header').invalid &&
                templateForm.get('header').touched &&
                submitted
            }">
          </textarea>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="
                    (submitted || templateForm.get('header').touched) &&
                    templateForm.get('header').errors?.required
                ">
                    Header is required.
                </mat-error>
            </div>
            
            <div class="col-sm-6 col-lg-12 col-md-6 mb-3">
                <label class="form-label">Footer<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="footer" row="2" placeholder="Footer"
                        maxlength="150" [ngClass]="{
              'is-invalid':
                templateForm.get('footer').invalid &&
                templateForm.get('footer').touched &&
                submitted
            }">
          </textarea>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="
                    (submitted || templateForm.get('footer').touched) &&
                    templateForm.get('footer').errors?.required
                ">
                    Footer is required.
                </mat-error>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-lg-12 col-md-12 mb-3">
                <label class="form-label">Body<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="body" row="5" placeholder="Body"
                        [ngClass]="{ 'is-invalid': templateForm.get('body').invalid && templateForm.get('body').touched && submitted }">
                    </textarea>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf=" (submitted || templateForm.get('body').touched) && templateForm.get('body').errors?.required ">
                    Body is required.
                </mat-error>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-sm-12 col-md-6 my-3" style="padding-left: 15px">
                <mat-checkbox formControlName="sendSMS">
                    <span style="font-size: 14px; font-weight: 500">Send SMS</span></mat-checkbox>
            </div>
            <div class="col-sm-12 col-md-6 my-3" style="padding-left: 15px">
                <mat-checkbox formControlName="sendNotification">
                    <span style="font-size: 14px; font-weight: 500">Send Notification</span></mat-checkbox>
            </div>
            <div class="col-sm-12 col-md-6 my-3" style="padding-left: 15px">
                <mat-checkbox formControlName="sendEmail">
                    <span style="font-size: 14px; font-weight: 500">Send Email</span></mat-checkbox>
            </div>
            <div class="col-sm-12 col-md-6 my-3" style="padding-left: 15px">
                <mat-checkbox formControlName="sendWhatsappMsg">
                    <span style="font-size: 14px; font-weight: 500">Send WhatsApp Message</span></mat-checkbox>
            </div>
        </div> -->
    </form>
</div>