<!-- Add Cash -->
<div class="modal-header pt-0">
  <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:5px;" class="modal-title mb-0">{{ mode ? 'Add' : 'Remove' }}</h1>
  <div class="addProductButtons">
    <button style="margin-right: 10px;" type="button" class="btn btn-cancel"
      (click)="cancelAddForm()" >Cancel</button>
    <button type="button" *ngIf="mode" class="btn btn-save" (click)="save('ADD')">Save</button>
    <button type="button" *ngIf="!mode" class="btn btn-save"(click)="save('REMOVE')">Save</button>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="addForm">
    <div class="row d-flex justify-content-start">
      <div class="col-sm-12 col-md-12 col-lg-6 my-3">
        <label class="form-label">Enter Amount <span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
          <input type="text" placeholder="Enter Amount" matInput (keypress)="numberOnly($event)"
            formControlName="amount" maxlength="8" 
            [ngClass]="{
              'is-invalid':
                f.amount.invalid && f.amount.touched && submitted
            }" required>
        </mat-form-field>
        <mat-error class="text-danger mb-1" *ngIf="
                      (submitted || f.amount.touched) &&
                      f.amount.errors?.required
                    ">
            Amount is required.
          </mat-error>
      </div>
    </div>
    <div class="row d-flex justify-content-start">
      <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
        <label class="form-label">Description <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width " style=" font-size: 12px" appearance="outline">
          <textarea matInput rows="5" placeholder="Enter Description"
            maxlength="150" formControlName="description"
            [ngClass]="{
              'is-invalid':
                f.description.invalid && f.description.touched && submitted
            }"></textarea>
        </mat-form-field>
        <mat-error class="text-danger mb-1" *ngIf="
                      (submitted || f.description.touched) &&
                      f.description.errors?.required
                    ">
            Description is required.
          </mat-error>
      </div>
    </div>
  </form>
</div>

<!-- Remove Cash -->
<!-- <div class="modal-header pt-0" *ngIf="mode === false">
  <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:5px;" class="modal-title mb-0">Remove</h1>
  <div class="addProductButtons">
    <button style="margin-right: 10px;" type="button" class="btn btn-cancel"
      (click)="cancelRemoveForm()" >Cancel</button>
    <button type="button" class="btn btn-save"(click)="save('REMOVE')">Save</button>
  </div>
</div>
<div class="modal-body" *ngIf="mode === false">
  <form  [formGroup]="removeForm">
    <div class="row d-flex justify-content-start">
      <div class="col-sm-12 col-md-4 col-lg-6 my-3">
        <label class="form-label">Enter Amount</label>
        <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
          <input type="text" placeholder="Enter Amount" matInput (keypress)="numberOnly($event)"
            formControlName="removeAmount" maxlength="20" required>
        </mat-form-field>
      </div>
    </div>
    <div class="row d-flex justify-content-start">
      <div class="col-sm-12 col-md-4 col-lg-6 mb-3">
        <label class="form-label">Description</label>
        <mat-form-field class="example-full-width " style=" font-size: 12px" appearance="outline">
          <textarea matInput rows="5" placeholder="Enter Description"
            maxlength="150" formControlName="removeDescription"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div> -->
