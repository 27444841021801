import { Component, OnInit } from '@angular/core';
import { SundryCreditorService } from 'src/app/core/services/sundry-creditor.service';

@Component({
  selector: 'app-pending-payment',
  templateUrl: './pending-payment.component.html',
  styleUrls: ['./pending-payment.component.scss'],
})
export class PendingPaymentComponent implements OnInit {
  pendingPartyList: any[] = [];
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  constructor(private sundryservice: SundryCreditorService) {}

  ngOnInit(): void {
    this.getAppPendingPayments();
  }

  getAppPendingPayments() {
    this.sundryservice
      .sundryCredit(
        {},
        this.currentUser.id,
        'RECEIVED',
        this.partyPageSize,
        this.currentPageNo + 1
      )
      .then((res) => {
        if (res.success) {
          this.pendingPartyList = res.data.pageData;
        }
      });
  }
}
