import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Gstr1Service } from 'src/app/core/services/gstr1.service';
import * as XLSX from 'xlsx';

interface IRange {
  value: Date[];
  label: string;

}
@Component({
  selector: 'app-gst-tax-report',
  templateUrl: './gst-tax-report.component.html',
  styleUrls: ['./gst-tax-report.component.scss']
})
export class GstTaxReportComponent implements OnInit {
  public quarterStartDate:any;
  public quarterEndDate: any;
  public today: Date;
  public quarter;
  public maxDate: Date;
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  public dateRange:Date[];
  fileName= 'gstTaxReport.xlsx';
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public gstTaxlist:any;
  public Taxin : any;
  public Taxout : any;
  
  constructor(private gstr1Service:Gstr1Service ) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    let quarter = moment(this.today).utc().quarter() //outputs 4
    //this.getQuarterRange();

  }
  ngOnInit(): void {

    this.dateRange=[new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]

    this.ranges = [
       
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'
  
      },
      // {
      //   value: [this.quarterStartDate._d, this.quarterEndDate._d],
      //   label: ' Last Quarter'
      // },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'
  
      },
      {
        value: [new Date(new Date(new Date().getFullYear() , 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'
  
      },
      {
        value: [],
        label: 'Custom Range'
  
      }];
      

      this.getGstrTaxReport();
    
  }

  changeDate(_e: any){
    this.getGstrTaxReport();  
    }

  getGstrTaxReport(){
    this.gstr1Service.getgstrTaxReport({},this.currentUser.id,moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD') ,moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD')).then((res : any)=>{
      console.log("Res",res)
      this.gstTaxlist = res.data.result;
      this.Taxout = res.data.totalPurchase;
      this.Taxin = res.data.totalSales;
    })
  }
  // getQuarterRange() {
  //   this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
  //   this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  // }
  
  exportExcel(){
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
    /* save to file */  
    XLSX.writeFile(wb, this.fileName);

}

onPrint() {
  const printContents = document.getElementById('excel-table').innerHTML;
  const originalContents = document.body.innerHTML;
  this.removeScrollbar();
  window.print();
  this.restoreScrollbar();
  }

  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }

}
