<!-- <div class="container-fluid" style="background-color: #e7e6ee; padding: 0px 12px 8px 12px">
  <div class="row pt-3 update-dashboard">
    <div class="col-3">
      <div class="form-label">Widget Library</div>
      <div class="flex-item searchBar mt-3 bg-white">
        <input type="search" id="searchInput" class="form-control pe-5" placeholder="Name" [(ngModel)]="searchTerm" />
      </div>

      <div class="button-selection widget-list mb-2 mt-3">
        <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="widgetList" class="example-list"
          (cdkDropListDropped)="drop($event)" *ngFor="let widget of widgetList" class="widget p-2 mb-2">
          <div class="align-items-left">
            <div class="row" cdkDrag>
              <div class="col-lg-2">
                <mat-icon>list</mat-icon>
              </div>
              <div class="col-lg-10">
                <div class="btn-text">{{ widget?.name }}</div>
                <div class="btn-description">
                  {{ widget?.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-9 p-0">
      <dashboard-nav></dashboard-nav>
      <div class="dash-widget-list mb-2 mt-4 d-flex flex-wrap">
        <div cdkDrag cdkDropList #doneList="cdkDropList" [cdkDropListData]="draggedWidgetList" class="example-list"
          (cdkDropListDropped)="drop($event)" *ngFor="let widget of draggedWidgetList" class="widget p-2 mb-2 bg-white"
          draggable="true">
          <div class="align-items-left">
            <div class="row" >
              <div class="col-lg-2">
                <mat-icon>menu</mat-icon>
              </div>
              <div class="col-lg-10">
                <div class="btn-text">Drag and drop a widget</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="container-fluid" style="background-color: #e7e6ee; padding: 0px 12px 8px 12px" cdkDropListGroup>
  <div class="row pt-3 update-dashboard">
    <div class="col-3">
      <div class="form-label">Widget Library</div>
      <div class="flex-item searchBar mt-3 bg-white">
        <input type="search" id="searchInput" class="form-control pe-5" placeholder="Name" [(ngModel)]="searchTerm" />
      </div>

        <app-left-side-panel></app-left-side-panel>
    </div>


    <div class="col-9 p-0">
      <dashboard-nav></dashboard-nav>
      <app-dashboard-widgets-panel></app-dashboard-widgets-panel>
    </div>
  </div>
</div> -->

<div class="container-fluid" style="background-color: #e7e6ee; padding: 0px 12px 8px 12px" cdkDropListGroup>
  <div class="row p-3 align-items-center">
    <!-- Header on the left -->
    <div class="col px-0">
      <h2 class="mb-0">Customize Your Dashboard</h2>
    </div>
    <!-- Buttons on the right -->
    <div class="col d-flex justify-content-end">
      <div class="d-flex dashboard-btns">
        <div class="nav-item navOption-btns update" *ngIf="!showUpdate">
          <button type="button" class="btn btn-primary" (click)="saveDashboard()">
            Save Dashboard
          </button>
        </div>
        <div class="nav-item navOption-btns update" *ngIf="showUpdate">
          <button type="button" class="btn btn-primary" (click)="updateDashboard()">
            Update Dashboard
          </button>
        </div>
        <div class="nav-item navOption-btns update">
          <button type="button" class="btn btn-primary" routerLink="/pages/dashboard">Exit</button>
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="row pt-3 update-dashboard">
    <div class="col-2">
      <div class="mb-2">Widget Library</div>
      <!-- <div class="flex-item searchBar mt-3 bg-white">
        <input type="search" id="searchInput" class="form-control pe-5" placeholder="Name" [(ngModel)]="searchTerm" />
      </div>-->

      <div class="button-selection widget-list mb-2 mt-3" cdkDropList #todoList="cdkDropList"
        [cdkDropListData]="widgetList" [cdkDropListConnectedTo]="[doneList]" class="example-list"
        (cdkDropListDropped)="DropWidget($event)">
        <!-- <div class="example-box" *ngFor="let item of widgetList" cdkDrag>{{item.name}}</div> -->
        <div *ngFor="let widget of widgetList" class="widget p-2 mb-2" cdkDrag>
          <div class="align-items-left">
            <div class="row">
              <div class="col-lg-2">
                <mat-icon>list</mat-icon>
              </div>
              <div class="col-lg-10">
                <div class="btn-text">{{ widget?.name }}</div>
                <div class="btn-description">{{ widget?.description }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-10 p-0">
      <!-- <dashboard-nav></dashboard-nav> -->
      <!-- <div class="d-flex dashboard-btns">
        <div class="nav-item navOption-btns update" *ngIf="!showUpdate">
          <button type="button" class="btn btn-primary" (click)="saveDashboard()">
            Save Dashboard
          </button>
        </div>
        <div class="nav-item navOption-btns update" *ngIf="showUpdate">
          <button type="button" class="btn btn-primary" (click)="updateDashboard()">
            Update Dashboard
          </button>
        </div>
        <div class="nav-item navOption-btns update">
          <button type="button" class="btn btn-primary" routerLink="/pages/dashboard">Exit</button>
        </div>
      </div>-->

      <div class="dash-widget-list mb-2 mt-4 d-flex flex-wrap drop-area" cdkDropList #doneList="cdkDropList"
        [cdkDropListData]="draggedWidgetList" [cdkDropListConnectedTo]="[todoList]"
        (cdkDropListDropped)="DropWidget($event)">
        <div class="mx-auto my-auto" *ngIf="draggedWidgetList.length === 0">
          <div class="d-flex align-items-centers">
            <mat-icon>drag_indicator</mat-icon>
            <span class="mx-2 fs-2">Drag and Drop your widgets here</span>
          </div>
        </div>
        <div *ngFor="let widget of draggedWidgetList" class="widget p-2 mb-2 width{{ widget.widgetWidth }}" cdkDrag>
          <div dynamicComponent [dynamicComponent]="widget.component" [inputs]="{ chartType: widget.chartType, chartId: widget?._id, chartData: (widget | json) }" class="widget-content">
            <button class="delete-button" *ngIf="!widget.isDeleted" (click)="removeWidget(widget)">
              <mat-icon>delete</mat-icon>
            </button>
            <button class="configure-button" *ngIf="widget.isConfigure" (click)="configureModal(widget)">
              <mat-icon>settings</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
