import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RemoteShopService } from 'src/app/core/services/remote-shop.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';


@Component({
  selector: 'app-remote-customer',
  templateUrl: './remote-customer.component.html',
  styleUrls: ['./remote-customer.component.scss']
})
export class RemoteCustomerComponent implements OnInit {
  public defaultShopImg = Constants.images.defaultShopImg;
  public allRetailers :any = [];
  public isData: boolean = true;
  public imageSrc: any = '';
  public searchKey:any;
  private searchSubject = new Subject<string>();
  isLoading: boolean = false;
  private searchSubscription: Subscription;



  constructor(private remoteShopService: RemoteShopService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private titleCasePipe: TitleCasePipe,
    private toastService : ToastNotificationService
    ) { }

  ngOnInit(): void {
    this.getAllRetailer();
    
    this.searchSubscription = this.searchSubject
      .pipe(debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.isLoading = true; // Show the loader when the API call is initiated
        })).subscribe(searchKey => {
          // Call the API with the search key
          if (searchKey.length >= 3) {
            this.getshopSearch(searchKey);
          } else if (searchKey === '') {
            // this.isLoading = true; // Hide the loader if the search key is less than the threshold
            this.getshopSearch(searchKey);
          } else {
            this.isLoading = false; // Hide the loader if the search key is less than the threshold
          }
        });
  }
  getAllRetailer() {
    this.spinner.show();
    this.remoteShopService.gettRemoteShops({}).then((res) => {
      this.spinner.hide();
      this.allRetailers = res.data; 
    },(err)=>{
      this.isData = false;
      if(err.error.status == 404){
        this.isData = false;
      } else if(err.error.status === 500){
        this.spinner.hide();
        this.isData = false;
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }
  // viewRetailerInventory(clientId){
  //   this.router.navigate(["/pages/customer/viewProduct/"+clientId])
  //   // routerLink="/pages/customer/viewProduct/{{i.id}}
  //   localStorage.setItem('currentShopId',clientId);
  // }
  onSearchInputChange(searchKey: string) {
    // Emit the search key to the searchSubject
    this.searchSubject.next(searchKey);
  }
  
  getshopSearch(searchKey) {
    setTimeout(() => {
      if (this.searchKey === "") {
        this.remoteShopService.gettRemoteShops({},searchKey).then((res) => {
          if(res.success){
            this.allRetailers = res.data; 
          }
        })
      }
      else {
        this.remoteShopService.gettRemoteShops({},searchKey).then((res) => {
          if(res.success){
            this.allRetailers = res.data; 
          }
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
      }
      this.isLoading = false; // Hide the loader after the API call is completed
    }, 500);
  }
 
}
