import { Component, OnInit } from '@angular/core';
import { Gstr1Service } from 'src/app/core/services/gstr1.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-gst-report4',
  templateUrl: './gst-report4.component.html',
  styleUrls: ['./gst-report4.component.scss']
})
export class GstReport4Component implements OnInit {

  fileName = 'gst4Report.xlsx';
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public gst4ListA: any;
  public gst4ListB: any;

  //   printComponent(cmpName) {
  //     let printContents = document.getElementById(cmpName).innerHTML;
  //     let originalContents = document.body.innerHTML;

  //     document.body.innerHTML = printContents;

  //     window.print();

  //     document.body.innerHTML = originalContents;

  // }


  constructor(private gstr1Service: Gstr1Service) {

  }

  ngOnInit(): void {
    this.getGstr4BReport();
  }

  getGstr4BReport() {
    this.gstr1Service.getgstr4BReport({}, this.currentUser.id).then((res: any) => {
      console.log(res)
      this.gst4ListA = res.data.gst4.a;
      this.gst4ListB = res.data.gst4.b;
      console.log(this.gst4ListA)
    })
  }

  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  onPrint() {
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
  }

  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }

}
