import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SaleOrderService {
  postBulkParty(formdata: FormData, id: any) {
    throw new Error('Method not implemented.');
  }

  constructor(public httpClient: HttpService) { }
  postSaleOrdSave(data: {}) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postSaleOrder, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  saleOderForWarehouse(data: {}) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.saleOderForWarehouse, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  postPurchaseOrder(data: {}){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.PostPurchaseOrder, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSaleOrderList(data: {}, userId: string, receiptType, size, page, packStatus?, partyName?, receiptType2?, packStatus2?): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleOrder + userId + "?receiptsType=" + receiptType + "&size="+ size + "&page=" + page + "&packStatus=" + (packStatus || '') + "&receiptsType=" + (receiptType2 || '') + "&packStatus=" + (packStatus2 || '') + "&isDraft=0", 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSaleOrderListSearch(data: {}, userId: string, receiptType,size,page,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleOrder + userId + "?receiptsType=" + receiptType + "&size="+ size + "&page=" + page + "&search="+search  + "&isDraft=0", 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSaleOderForWarehouseBySaleOrderId(data: {}, saleOrderId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleOderForWarehouseBySaleOrderId + saleOrderId  + "?isDraft=0", 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getPurchaseOrderList(data: {}, userId: string, receiptType, size, page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleOrder + userId + "?receiptsType=" + receiptType + "&size="+ size + "&page=" + page  + "&isDraft=0", 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // DRAFTED

  getSaleOrderDraftedList(data: {}, userId: string, receiptType, size, page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleOrder + userId + "?receiptsType=" + receiptType + "&size="+ size + "&page=" + page + "&isDraft=1", 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPurchaseOrderDraftedList(data: {}, userId: string, receiptType, size, page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleOrder + userId + "?receiptsType=" + receiptType + "&size="+ size + "&page=" + page  + "&isDraft=1", 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

}
