import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UpdateDashboardComponent } from './pages/update-dashboard/update-dashboard.component';
import { DashboardComponent } from '../schedule/compnnent/dashboard/dashboard.component';
import { ClientDashboardComponent } from './dashboard.component';

const routes: Routes = [
    // {
    //   path: '',
    //   component: ClientDashboardComponent,
    //   children: [
    //     // {
    //     //   path: '',
    //     //   redirectTo: 'update-dashboard',
    //     //   pathMatch: 'full'
    //     // },
    //     {
    //       path: 'update-dashboard',
    //       component: UpdateDashboardComponent,
    //     },
    //   ]
    // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
