import { WarehouseService } from 'src/app/core/services/warehouse.service';
import { TitleCasePipe } from '@angular/common';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { idText } from 'typescript';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';

import {
  FormControl,
  Validators,
  NgModel,
  FormGroup,
  FormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';


interface DashboardListResponse {
  data: any[]; // Define the structure of the 'data' property
  // Add other properties if present in the response
}
@Component({
  selector: 'create-dashboard',
  templateUrl: './create-dashboard.component.html',
  styleUrls: ['./create-dashboard.component.scss'],
})


export class CreateDashboardComponent implements OnInit {
  isLoading: boolean = false;
  public submitted: boolean;
  CreateDashboardForm: any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));

  public userList = [];

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<CreateDashboardComponent>,
    private formBuilder: FormBuilder,
    private toastService: ToastNotificationService,
    private WarehouseService: WarehouseService,
    private titleCasePipe: TitleCasePipe,
    private DashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  async ngOnInit(): Promise<void> {
    this.getWarehouseList();
    this.CreateDashboardForm = this.formBuilder.group({
      dashboardName: ['', [Validators.required, Validators.maxLength(100)]],
      dashboardPrivacy: ['', Validators.required],
      dashboardUser: [{ value: '', disabled: true }] // Assuming this is the form control for selecting users
    });

    this.CreateDashboardForm.get('dashboardPrivacy').valueChanges.subscribe(value => {
      if (value === 'user') {
        this.CreateDashboardForm.get('dashboardUser').enable(); // Enable the control
      } else {
        this.CreateDashboardForm.get('dashboardUser').disable(); // Disable the control
      }
    });
  }

  get f() {
    return this.CreateDashboardForm.controls;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getWarehouseList() {
    this.WarehouseService.getAllWarehouse({
      data:{},
      userId: this.currentUser.id,
      size: 10,
      page: 1,
      search:'',
    }).then((res: any) => {
      const filteredData = res.data.pageData.map(({ fullName, id }) => ({ fullName, id }));
      this.userList = filteredData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  createDashboard() {
    localStorage.removeItem("selectedDashboardId");
    this.submitted = true;
    if (this.CreateDashboardForm.invalid) {
      this.toastService.toastMsg({
        title: 'Error',
        content: 'Fill All Required Fields.',
      });
      return false;
    } else {
      let data = {
        dashboardName: this.f.dashboardName.value,
        dashboardPrivacy: this.f.dashboardPrivacy.value,
        userId: this.f.dashboardUser.value
      };
      localStorage.setItem('currentDashboardData', JSON.stringify(data));

      this.dialogRef.close();
      this.router.navigate(['/update-dashboard/']);
    }
  }

}
