<div class="block widget p-3">
  <div class="block-container">
    <div class="leftSection">
      <span class="block-headers"> New Customers </span>
      <span class="block-value">
        {{
          (newPartiesJoinedCount ? newPartiesJoinedCount : 0) | number : "1.0-2"
        }}
      </span>
    </div>
    <div>
      <span class="rounded-circle" style="margin: 0 4px">
        <img
          src="assets/img/dashboard-party-profiles/new-customer.png"
          alt="new customer"
          width="40"
          height="40"
        />
      </span>
    </div>
  </div>
  <div class="lowerSection">
    <!-- <span style = "color: #00B69B;"> -->
    <span [ngStyle]="{ color: newPartiesPercent >= 0 ? '#00B69B' : '#FF0000' }">
      <img
        src="assets/img/dashboard-party-profiles/{{
          newPartiesPercent >= 0 ? 'profit.png' : 'loss.png'
        }}"
        alt="Total Sales"
        style="width: 15px; height: 15px"
      />
      <!-- <img src="assets/img/dashboard-party-profiles/profit.png" alt = "Total Sales" width = "10" height = "10"> -->
      {{ (newPartiesPercent ? newPartiesPercent : 0) | number : "1.0-2" }}%
    </span>
    <span *ngIf="newPartiesPercent >= 0">Up</span>
    <span *ngIf="newPartiesPercent < 0">Down</span>
    from yesterday
  </div>
</div>
