
<div class="container-fluid bg-white p-4">
    <div class="row mt-2">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="row mt-3 page-nav-link">
             <div class="col-xxl-1 col-xl-1 col-lg-2 col-md-3 col-sm-4 col-xs-4 links" *appHasPermission="['read']; name 'employee'">
                  <a class="mt-3 pb-1 text-nowrap" routerLink="./allEmployees" routerLinkActive='active'>Employee List</a>
              </div>
              <div class="col-xxl-1 col-xl-1 col-lg-2 col-md-3 col-sm-4 col-xs-4 links" *appHasPermission="['read']; name 'approval'">
                  <a class="mt-3 pb-1 text-nowrap" routerLink="./employee-approvals" routerLinkActive='active'>Employee Approvals</a>
              </div>
             <div class="col-xxl-1 col-xl-1 col-lg-2 col-md-3 col-sm-4 col-xs-4 links" *appHasPermission="['read']; name 'approval'">
                  <a class="mt-3 pb-1 text-nowrap" routerLink="./approvals" routerLinkActive='active'>Approvals </a>
              </div>
              <div class="col-xxl-1 col-xl-1 col-lg-2 col-md-3 col-sm-6 links" *appHasPermission="['read']; name 'approval'">
                <a class="mt-3 pb-1 ps-1 text-nowrap" routerLink="./approval-list" routerLinkActive="active">Company Resource</a>
              </div>
              <div class="col-xxl-1 col-xl-1 col-lg-2 col-md-3 col-sm-6 links" *appHasPermission="['read']; name 'approval'">
                <a class="mt-3 pb-1 ps-1 text-nowrap" routerLink="./approval-type" routerLinkActive="active">Approval Type</a>
              </div>
              <!-- <div class="col-xxl-10 col-xl-10 col-lg-8 col-md-3 col-sm-0 col-xs-0"></div> -->
          </div>
      </div>
    </div>
    <hr  class = "mt-1" />
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
  