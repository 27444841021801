<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 5px;" class="modal-title mb-0">Update Service</h1>
    <div class="addServiceButtons">
        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="cancel()">Cancel</button>
        <button type="submit" class="btn btn-save" (click)="serviceUpdate()"
            *appHasPermission="['modify']; name 'service'">Save</button>
    </div>
</div>
<div class="modal-body">
    <form [formGroup]="ProductForm">
        <div class="row mb-3">
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Service Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput formControlName="serviceName" placeholder="Service Name" maxlength="50"
                        [ngClass]="{
                        'is-invalid': (f.serviceName.invalid && f.serviceName.touched) && submitted }"
                        autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.serviceName.touched)">
                    <mat-error *ngIf="f.serviceName.errors?.required">
                        Service Name is required!
                    </mat-error>
                </mat-error>
            </div>

            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Service Code<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput formControlName="serviceCode" placeholder="Service Code"
                        [ngClass]="{'is-invalid': (f.serviceCode.invalid && f.serviceCode.touched) && submitted }"
                        minlength="4" maxlength="8" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.serviceCode.touched) && f.serviceCode.errors?.pattern">
                    Service Code must be alphanumeric value, Special character not Allowed !
                </mat-error>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.serviceCode.touched) && f.serviceCode.errors?.required">
                    Service Code is required!
                </mat-error>
                <mat-error *ngIf="f.serviceCode.errors?.minlength">
                    Service Code must be at least 4 character.
                </mat-error>
                <mat-error *ngIf="f.serviceCode.errors?.maxlength">
                    Service Code must be at least 8 characters.
                </mat-error>

            </div>
        </div>

        <div class="row mb-3">

            <!-- Generate Barcode -->
            <!-- <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Barcode/SKU<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Barcode/SKU" type="text"
                        formControlName="generateBarcode" [ngClass]="{
                        'is-invalid': (f.generateBarcode.invalid && f.generateBarcode.touched) && submitted }"
                        minlength="13" maxlength="13" (keypress)="numberOnly($event)"  [readonly]="true"  autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.generateBarcode.touched) && f.generateBarcode.errors?.pattern">
                    Barcode/SKU is required!
                </mat-error>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.generateBarcode.touched) && f.generateBarcode.errors?.required">
                    Barcode/SKU is required!
                </mat-error>
                <mat-error *ngIf="f.generateBarcode.errors?.maxlength">
                    Barcode/SKU must be at least 13 digit.
                </mat-error>
                <mat-error *ngIf="f.generateBarcode.errors?.minlength">
                    Barcode/SKU must be at least 13 digit.
                </mat-error>
            </div> -->

            <div style="font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Category<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering required formControlName="category"
                        placeholder="Category" [ngClass]="{
                            'is-invalid': (f.category.invalid && f.category.touched) && submitted }">
                        <mat-option *ngFor="let item of productCategory"
                            [value]="item.id">{{item.categoryName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.category.touched)">
                    <mat-error *ngIf="f.category.errors?.required">
                        Category is required!
                    </mat-error>
                </mat-error>
            </div>

        </div>

        <div class="row mb-3">

            <!-- Price -->

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">{{ lang.SalePriceInc }}<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" matNativeControl required matInput placeholder="{{ lang.SalePriceInc }}"
                        formControlName="price" [ngClass]="{
                                'is-invalid': (f.price.invalid && f.price.touched) && submitted }"
                        (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.price.touched)">
                    <mat-error *ngIf="f.price.errors?.required">
                        {{ lang.SalePriceInc }} is required!
                    </mat-error>
                    <mat-error *ngIf="f.price.errors?.pattern">
                        {{ lang.SalePriceInc }} Only number allowed!
                    </mat-error>
                </mat-error>
            </div>

            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">{{lang.GSTRate}}<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="{{lang.GSTRate}}" formControlName="gstRate"
                        [ngClass]="{
                            'is-invalid': (f.gstRate.invalid && f.gstRate.touched) && submitted }"
                        (keypress)="numberDecimalOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.gstRate.touched)">
                    <mat-error *ngIf="f.gstRate.errors?.required">
                        {{lang.GSTRate}} is required!
                    </mat-error>
                </mat-error>
            </div>

        </div>

        <div class="row mb-3">
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label class="form-label">Service Description<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput rows="3" required placeholder="Service Description" formControlName="description" maxlength="150"
                        [ngClass]="{
                            'is-invalid': (f.description.invalid && f.description.touched) && submitted }"
                        autocomplete="off"></textarea>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.description.touched)">
                    <mat-error *ngIf="f.description.errors?.required">
                        Service Description is required!
                    </mat-error>
                </mat-error>
            </div>
        </div>

        <div class="row mb-3">

            <!-- Opening Stock  -->
            <!-- <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Opening Stock</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="number" matNativeControl matInput placeholder="Openeing Stock"
                        formControlName="openingStock" autocomplete="off">
                </mat-form-field>
            </div> -->

            <!-- As on Date -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">As on Date<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput required ngModel bsDatepicker [(ngModel)]="todaysDate"
                        style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                    containerClass: 'theme-dark-blue',
                    dateInputFormat: 'DD MMM YYYY',
                    showWeekNumbers:false
                }" formControlName="asOfDate" (click)="openDatepicker()"
                  [ngClass]="{
                            'is-invalid': (f.asOfDate.invalid && f.asOfDate.touched) && submitted }">
                    <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.asOfDate.touched) && f.asOfDate.errors?.required">
                    <!-- <mat-error *ngIf="f.description.errors?.required"> -->
                        As of date is required.
                    <!-- </mat-error> -->
                </mat-error>
            </div>

        </div>






        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p class="d-md-inline-block fs--1 ml-5"
                    style="font-weight:500;font-size:20px!important;margin-left: 5px;">
                    Update Service Image
                </p>
            </div>

        </div>

        <div class="row mt-3">

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row mb-sm-2"
                style="justify-content: center;">

                <div *ngIf="imageSrcSeven" class="{{imageSrcSeven ? '':'dash'}} "
                    style="width: 200px !important; height: 200px !important;"
                    [ngStyle]="{'margin-top': imageSrcSeven ? '-20px' : '0px'}">
                    <button *ngIf="imageSrcSeven" class="delete-button-cover" type="button" (click)="cancelCoverImageCover()">
                        <i class="bi bi-x bi-sm" style="color:red"></i>
                    </button>
                    <img *ngIf="imageSrcSeven" class="m-2" height="200" width="200"
                        [src]="imageSrcSeven ? imageSrcSeven : ''" (load)="handleCoverImageLoad()"
                        [class.loaded]="imageLoaded" class="text-center" />
                </div>

                <label *ngIf="!imageSrcSeven" class="uploader2"
                    style="width: 200px !important;height:200px !important;">

                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <i class="bi bi-plus-lg  fs-3" (click)="startUpload()"></i>

                        <span class="mt-2" style="font-size: 12px; font-weight:500;line-height: 21;">Add Service
                            Cover Image</span>
                        <span class="mt-2 text-muted" style="text-align: center; font-size: 12px;line-height: 21;">Drop
                            an Image or <span style="color: #1BB2F2;">Browsing images</span> it from your
                            computer</span>


                    </div>
                    <input name="Select File" type="file" id="uploadCoverPic" (change)="coverImgChange($event)" />

                </label>

            </div>

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row" style="justify-content: center;">
                <label class="uploader2" style="width: 200px !important;height:200px !important;">

                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <i class="bi bi-plus-lg  fs-3"></i>

                        <span class="mt-2" style="font-size: 12px; font-weight:500;line-height: 21;">Add Service
                            Image</span>
                        <span class="mt-2 text-muted" style="text-align: center; font-size: 12px;line-height: 21;">Drop
                            an Image or <span style="color: #1BB2F2;">Browsing images</span> it from your
                            computer</span>


                    </div>
                    <input type="file" name="file" accept="image/*" style="display:none;" [(ngModel)]="currentInput"
                        [ngModelOptions]="{ standalone: true }" multiple (change)="onFileSelected($event)" />

                </label>

            </div>

        </div>

        <div class="row mt-5">

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 mt-2 flex d-flex flex-row"
                *ngFor="let fetch of fetchImages" style="justify-content: center;">

                <button class="delete-button"  type="button" (click)="cancelCoverImage(fetch.id)">
                    <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
                <img [src]="fetch.productImage" class="preview" height="200" width="200px" />
            </div>
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 mt-2 flex d-flex flex-row"
                *ngFor="let preview of previews;let i=index;" style="justify-content: center;">

                <button class="delete-button"  type="button" (click)="cancelmultiImage(i)">
                    <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
                <img [src]="preview" class="preview" height="200" width="200px" />
            </div>
        </div>
    </form>
</div>
