<div class="container-fluid mb-3 px-0 mx-0">
    <div class="row pt-2 pb-3" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:500;font-size:20px!important;padding-left: 15px;">
            GSTR-2
        </div>
        <div class="col-6 navOption-btns" style="display: flex; justify-content: flex-end;">
            <button class="btn me-3" style="white-space: nowrap;" (click)="exportExcel()"
                type="button"><i class="fas fa-download"></i></button>
            <button class="btn" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
            </button>
        </div>
    </div>
    <hr class="mt-1" />
    <div class="fs--1 bg-white">
        <div class="row mb-3">
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start;flex-basis:300px;" class="col-lg-3 col-sm-12 col-md-3">
                <label for="Date Range" class="form-label" id="datePrintHide">Date</label>
                <mat-form-field class="example-full-width" appearance="outline" id="datePrintHide">
                    <input  class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
                        bsDaterangepicker [maxDate]="today" [(ngModel)]="dateRange" (ngModelChange)="changeDate($event)"
                                            [bsConfig]="{ ranges: ranges,
                                            showWeekNumbers:false,
                                            showNextMonth: false,
                                            rangeInputFormat : 'DD MMM YYYY',
                                            containerClass: 'theme-dark-blue',
                                              displayOneMonthRange: true }">
                    <span>
                        <mat-icon class="iconcalender p-1">date_range</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>
        <div class="form-check ms-1" id="showoptionHide">
            <input class="form-check-input" id="flexCheckIndeterminate" type="checkbox" value="" />
            <label class="form-check-label" for="flexCheckIndeterminate"><strong style="font-weight: 500; font-size: 14px;">Consider non-tax as exempted</strong></label>
        </div>
        <ul class="nav nav-tabs nav-justified mb-3" id="myTab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#tab-home"
                    role="tab" aria-controls="tab-home" aria-selected="true"><span style = "font-size: 12px;font-family: 500;">Sale</span></a></li>
            <li class="nav-item"><a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#tab-profile"
                    role="tab" aria-controls="tab-profile" aria-selected="false"><span style = "font-size: 12px;font-family: 500;">Sale Return</span></a></li>
        </ul>
        <div class="tab-content border-x" id="myTabContent">
            <div class="tab-pane fade show active" id="tab-home" role="tabpanel" aria-labelledby="home-tab">
                <table class="table table-light table-bordered" id="excel-table">
                    <thead style="background-color: #F0F0F0;">
                        <tr>
                            <th scope="col">GSTIN/UIN</th>
                            <th scope="col">Party Name</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="tab-pane fade" id="tab-profile" role="tabpanel" aria-labelledby="profile-tab">
                <div>
                    <table class="table table-light table-bordered" id="excel-table">
                        <thead style="background-color: #F0F0F0;">
                            <tr>
                                <th style="width :30%" colspan="3">Bill Details</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Rate</th>
                                <th class="text-nowrap" style="width :10%" colspan="1">Cess Rate</th>
                                <th class="text-nowrap" style="width :10%" colspan="1">Taxable Value</th>
                                <th class="text-nowrap" style="width :10%" colspan="3">Reverse Charge</th>
                                <th scope="col"></th>
                                <th class="text-center" style="width :30%" colspan="3">Amount</th>
                                <th class="text-nowrap" style="width :10%" colspan="2">Place Of Supply(Name Of State)
                                </th>
                            </tr>
                        </thead>
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Date</th>
                                <th scope="col">Value</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th class="text-nowrap" scope="col">Integrated Tax</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th class="text-nowrap" scope="col">Central Tax</th>
                                <th class="text-nowrap" scope="col">State/UT Tax</th>
                                <th scope="col"></th>
                                <th scope="col">Cess</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
