<div class="bg-dark">
    <div class="row d-flex p-5">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <h1 class="font-style font-weight text-light">Enterprise Name</h1>
            <p class="font-style">
                Hand Crafted by some of India's finest artisans,
                NEEDLEDUST brings to you a line of handcrafted
                fine leather juttis that speak the charm of a
                true old school artisan with the design
                sensibilities of a modern, luxury shoe brand.
            </p>
            <div class="d-flex">
                <input type="text" class="form-control" placeholder="Enter your email address" style="width: 65% !important;">
                <span class="margin-left"><button class="btn btn-light">JOIN</button></span>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <h2 class="font-style text-light">SHOP ONLINE</h2>
            <h4 class="font-style text-light">Clothing</h4>
            <h4 class="font-style text-light">Accessories</h4>
            <h4 class="font-style text-light">Home & Kitchen</h4>
            <h4 class="font-style text-light">Appliances</h4>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <h2 class="font-style text-light">REACH US</h2>
            <h4 class="font-style text-light">Contact Us</h4>
            <h4 class="font-style text-light">iceipts@gmail.com</h4>
            <h4 class="font-style text-light">+91 9876543210</h4>
            <h4 class="font-style text-light">(10am - 6pm | Mon-Fri)</h4>
            <h4 class="font-style text-light">
                <i class="fab fa-facebook fa-2x text-white"></i>
                <span class="margin-left"><i class="fab fa-instagram fa-2x"></i></span>
            </h4>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <h2 class="font-style text-light">ENTERPRISE</h2>
            <h4 class="font-style text-light">About</h4>
            <h4 class="font-style text-light">Press</h4>
            <h4 class="font-style text-light">Stories</h4>
            <h4 class="font-style text-light">Testimonial</h4>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <h2 class="font-style text-light">POLICIES</h2>
            <h4 class="font-style text-light">Terms & Conditions</h4>
            <h4 class="font-style text-light">Returns & Exchange</h4>
            <h4 class="font-style text-light">Shippig policy</h4>
            <h4 class="font-style text-light">Privacy Policy</h4>
        </div>
    </div>
    <div class="row p-3">
        <h5 class="font-style text-light text-center">
            <i class="far fa-copyright"></i>All Rights Preseved</h5>
    </div>
</div>