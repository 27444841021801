<div class="card">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
          Product Service List
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body p-0 m-2">
        <div id="tableExample"   data-list='{"valueNames":["itemName","category","servicecode","description","unit"]}' >
        <div class="table-responsive" >
            <table class="table table-bordered table-striped fs--1 mb-0" >
                <thead class="bg-200 text-900">
                    <tr>
                        <th scope="col">#</th>
                        <th class="text-nowrap" class="sort" data-sort="ItemName">Item Name</th>
                        <th class="text-nowrap" class="sort" data-sort="barcode">Barcode</th>
                        <th class="text-nowrap" class="sort" data-sort="category">Category</th>
                        <th class="text-nowrap" class="sort" data-sort="description" >Description</th>
                        <th class="text-nowrap" class="sort" data-sort="price">Price</th>
                        <th class="text-nowrap" class="sort" data-sort="gstRate">GST Rate</th>
                        <th class="text-nowrap" class="sort" data-sort="openingStock">Opening Stock</th>  
                        <th >Action</th> 
                    </tr>
                </thead> 
                <tbody class="list" id="table-customers-body" *ngIf="serviceData">
                    <tr class="btn-reveal-trigger text-left" *ngFor="let item of serviceData; let i = index" >
                        <td scope="row">{{ i+1 }}</td>
                        <td class="ItemName">{{ item.itemName }}</td>
                        <td class="barcode">{{ item.barcode  }}</td>
                        <td class="category">{{ item.category | titlecase }}</td>
                        <td class="description">{{ item.description | titlecase}}</td>
                        <td class="price">{{ item.price }}</td>
                        <td class="gstRate">{{ item.gstRate }}</td>
                        <td class="openingStock">{{ item.stock  }}</td>
                        <td>
                            <div class="">
                                <button class="btn" (click)="updateProductId(item.id)" >
                                    <span class="bi-pencil-square icon-color fs-1"  data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"></span>
                                    <!-- //edit -->
                                    </button>
                                    <span class="m-1" text-center></span>

                                <button class="btn " (click)="deleteServiceId(item.id)">
                                    <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"></span>
                                 <!-- Delete -->
                                </button>
                            </div>
                        </td>  
                    </tr>
                </tbody>
                <tbody *ngIf="serviceData == 0">
                    <tr class="text-center">
                        <td colspan="10">
                            <h5 class="text-nowrap">No Product Service list Added. Please Add Product Service List To Be Displayed Here. <span>
                                <a class="nav-link" routerLink="/pages/product-add">Add New Product</a>
                            </span></h5>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
    </div>  
    <div class="card-footer border-top d-flex justify-content-center" *ngIf="serviceData > 0">
        <button class="btn btn-falcon-default btn-sm me-2" type="button" disabled="disabled" data-bs-toggle="tooltip"
            data-bs-placement="top" title="Prev"><span class="fas fa-chevron-left"></span></button><a
            class="btn btn-sm btn-falcon-default text-primary me-2" href="#!">1</a><a
            class="btn btn-sm btn-falcon-default me-2" href="#!">2</a><a class="btn btn-sm btn-falcon-default me-2"
            href="#!"><span class="fas fa-ellipsis-h"></span></a><a class="btn btn-sm btn-falcon-default me-2"
            href="#!">35</a>
        <button class="btn btn-falcon-default btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="top"
            title="Next"><span class="fas fa-chevron-right"></span></button>
    </div>
</div>
