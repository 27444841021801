import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { PartyReportsService } from 'src/app/core/services/party-reports.service';
import { PartyService } from 'src/app/core/services/party.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import * as XLSX from 'xlsx';

interface IRange {
  value: Date[];
  label: string;
}
@Component({
  selector: 'app-sale-purchase-by-party',
  templateUrl: './sale-purchase-by-party.component.html',
  styleUrls: ['./sale-purchase-by-party.component.scss']
})
export class SalePurchaseByPartyComponent implements OnInit {
  public quarterStartDate: any;
  public quarterEndDate: any;
  public today: Date;
  public quarter;
  public maxDate: Date;
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  public partyList: any;
  public partyData: any;
  public allPartyList: any = [];
  public filteredOptions: Observable<string[]>;
  public myControl = new FormControl();
  public partyPagesize: number = 10;
  public currentPageNo: number = 0;
  public partyName = new FormControl();
  public submitted: boolean = false;
  public dateRange: Date[]
  public startOfDateRange: any;
  public endOfDateRange: any;
  public selectedPartyId: any;
  public partyId: any;
  public salepartyList: any = [];
  public itemSaleListTotal: any = [];
  fileName = 'Sale Purchase By Vendor.xlsx';
  public salesGreaterThan = 0;
  public salesLessThan = 0;
  public totalQuantityInput = 0;
  public purchaseGreaterThan = 0;
  public purchaseLessThan = 0;
  public totalQuantityPurchase = 0;
  public valueItem: string = "Equal";
  public pagesize: number = 1;
  public totalrow: any;
  public partyPageSize: number = 5;
  public toatlPages: any;
  public p = 1;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));

  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  constructor(private partyservice: PartyReportsService,
    public toastService: ToastNotificationService,
    private saleInvoiceService: SaleInvoiceService,
    private partyService: PartyService,
    private partyreportsService: PartyReportsService,
    private titleCasePipe: TitleCasePipe) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    let quarter = moment(this.today).utc().quarter() //outputs 4
    this.getQuarterRange();
  }

  ngOnInit(): void {
    this.getAllParties();
    this.getSalePurchaseByParty();
    this.ranges = [
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      {
        value: [this.quarterStartDate._d, this.quarterEndDate._d],
        label: ' Last Quarter'
      },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear(), 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];
    this.dateRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]
  }

  getQuarterRange() {
    this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
    this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  }

  getSalePurchaseByParty() {
    this.partyreportsService.getSalePurchaseByParty({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1)).then((res: any) => {
      this.partyList = res.data.dataset;
      this.itemSaleListTotal = res.data;
      this.salepartyList = res.data.dataset;
      this.toatlPages = res.data.totalPages;
      this.totalrow = res.data.totalRows;

    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
          });
      }
    })
  }

  getsalePurchase() {
    this.partyreportsService.getSalePurchaseReport({}, this.currentUser.id, moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD'), moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD')).then((res) => {
      this.salepartyList = res.data.dataset;
      this.itemSaleListTotal = res.data;
      this.p = res.data.currentPage;
      this.totalrow = res.data.totalRows;
      this.toatlPages = res.data.totalPages
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
        }
      })
  }


  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  getAllParties() {
    this.partyService
      .getAllPartiesDetails({}, this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
      .then((res) => {
        this.allPartyList = res.data.pageData;
        this.filteredOptions = this.partyName.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
      });


  }
  searchPartyByName(item) {
    this.saleInvoiceService
      .getPartyByName({}, this.currentUser.id, item)
      .then((response: any) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(""),
            map((name) =>
              name ? this.filterNames(name) : this.allPartyList.slice()
            )
          );
        }
      });
  }

  setSelectedPartyDeatails1(item) {
    this.selectedPartyId = item.id;
    console.log(this.selectedPartyId)
    this.partyreportsService.getSalePurchaseReportsearch({}, this.currentUser.id, this.selectedPartyId,this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.salepartyList = res.data.dataset;
      this.itemSaleListTotal = res.data;
      this.p = res.data.currentPage;
      this.totalrow = res.data.totalRows;
      this.toatlPages = res.data.totalPages
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
        }
      })
  }
 
  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }

  onPrint() {
    const elementsToHide = ['headingPrintHide', 'dateRangePrintHide', 'searchBarHide','headingPrintHide1'];

    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = 'none';
      }
    });

    // Print
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
    // Restore hidden elements after printing
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = '';
      }
    });
  }

  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }
  onSelectSaleAmount(value) {
    this.valueItem = value;
  }

  ApplyQuantity() {
    if (this.valueItem == "Greater") {
      this.partyreportsService.getSalePurchaseReportSaleGreater({}, this.currentUser.id, this.totalQuantityInput).then((res) => {
        this.salepartyList = res.data.dataset;
        this.itemSaleListTotal = res.data;
      })
    }
    else if (this.valueItem == "Equal") {
      this.partyreportsService.getSalePurchaseReportSaleEqual({}, this.currentUser.id, this.totalQuantityInput).then((res) => {
        this.salepartyList = res.data.dataset;
        this.itemSaleListTotal = res.data;
      })
    }
    else {
      this.partyreportsService.getSalePurchaseReportSaleless({}, this.currentUser.id, this.totalQuantityInput).then((res) => {
        this.salepartyList = res.data.dataset;
        this.itemSaleListTotal = res.data;
      })
    }
  }

  onSelectPurchaseAmount(value) {
    this.valueItem = value;
  }

  ApplyPurchaseQuantity() {
    if (this.valueItem == "Greater") {
      this.partyreportsService.getSalePurchaseReportPurchaseGreater({}, this.currentUser.id, this.totalQuantityPurchase).then((res) => {
        this.salepartyList = res.data.dataset;
        this.itemSaleListTotal = res.data;
      })
    }
    else if (this.valueItem == "Equal") {
      this.partyreportsService.getSalePurchaseReportPurchaseEqual({}, this.currentUser.id, this.totalQuantityPurchase).then((res) => {
        this.salepartyList = res.data.dataset;
        this.itemSaleListTotal = res.data;
      })
    }
    else {
      this.partyreportsService.getSalePurchaseReportPurchaseGreaterless({}, this.currentUser.id, this.totalQuantityPurchase).then((res) => {
        this.salepartyList = res.data.dataset;
        this.itemSaleListTotal = res.data;
      })
    }
  }

  changeDate(e) {
    this.getsalePurchase();
  }
  
  handlePage(e){
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.partyList.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getSalePurchaseByParty();
    }
  }
}
