import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddOfferService {

  private offerAddedSource = new Subject<void>();
  offerAdded$ = this.offerAddedSource.asObservable();

  notifyofferAdded() {
    this.offerAddedSource.next();
  }
  
  constructor(public httpClient: HttpService, private http: HttpClient) { }
  getAllOfferList(data: {}, userId,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getOfferList + userId + "?size=" + size + "&page=" +page , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getAllOffersearch(data: {}, userId,offerName,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getOfferList + userId + "?offerName="+offerName + "&size=" + size + "&page=" +page , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postAddOfferDetails(data: {} ){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postaddoffer, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    })
  }
  postAddOfferImage(data: {}){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.postOfferImage, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    })
  }
  deleteOfferProductyById(data: {}, offerId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants. deleteProductByOffer + offerId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  // deleteProductByOffer

  putAddOfferDetails(data: {} ,id){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.PutOffer +id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    })
  }
  getAllOfferListById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getOfferById + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteproductofferCoverImgId(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deleteCoverPioffer + userId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deletemultiofferById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.multideleteByOffer + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      );
    });
  }
}



