import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
// import { NGXLogger } from 'ngx-logger';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {


    constructor(private injector: Injector,public authenticationService:AuthService) { }
    // intercept(
    //     request: HttpRequest<any>,
    //     next: HttpHandler
    //   ): Observable<HttpEvent<any>> {
    //     return next.handle(request).pipe(
    //       catchError((err) => {
    //        console.log(err);
    //         if (err.status === 401) {
    //           // auto logout if 401 response returned from api
    //            //this.authenticationService.logout();
    //           //location.reload(true);
    //           return throwError(err)
    //         } else if (err.status === 404) {
    //           return throwError(err);
    //         } else if(err.status === 500){
    //           return throwError(err);
    //         }
    
    //         //const error =  err.statusText;
    //         //return throwError(error);
    //       })
    //     );
    //   }

    handleError(error: Error) {

        // Obtain dependencies at the time of the error
        // This is because the GlobalErrorHandler is registered first
        // which prevents constructor dependency injection
        // const logger = this.injector.get(NGXLogger);

        const err = {
            message: error.message ? error.message : error.toString(),
            stack: error.stack ? error.stack : ''
        };

        // Log  the error
        // logger.error(err);

        // Re-throw the error
        throw error;
    }
}
