import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PartyService } from 'src/app/core/services/party.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ScheduleShopService } from 'src/app/core/services/schedule-shop.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Location, TitleCasePipe } from '@angular/common';
@Component({
  selector: 'app-shop-sform',
  templateUrl: './shop-sform.component.html',
  styleUrls: ['./shop-sform.component.scss']
})
export class ShopSFormComponent implements OnInit {
  public submitted: boolean;
  public shopDetailsForm: FormGroup;
  public gstDetails: any;
  public isDisabledGST: boolean = true;
  public pinDetails: any;
  isShown: boolean = false;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  accountMethods = [
    {
      "id": 1,
      "accMethod": "Accounts"
    },
    {
      "id": 2,
      "accMethod": "Accounts With Stock Management"
    },
    // {
    //   "id": 3,
    //   "accMethod": "Both"
    // },
  ]
  compositeTypes = [
    {
      "id": 1,
      "composite": "Trader"
    },
    {
      "id": 2,
      "composite": "Manufacturer"
    },
    {
      "id": 3,
      "composite": "Restaurant"
    },
    {
      "id": 4,
      "composite": "Service Provider"
    },
  ]
  gstTypes = [
    {
      "id": 1,
      "gstName": "Regular"
    },
    {
      "id": 2,
      "gstName": "Composite"
    },
    {
      "id": 3,
      "gstName": "Exempted"
    }
  ]
  businessTypes = [
    {
      "id": 1,
      "buisness": "Proprietorship"
    },
    {
      "id": 2,
      "buisness": "Partnership"
    },
    {
      "id": 3,
      "buisness": "Private Limited Company"
    },
    {
      "id": 4,
      "buisness": "Public Limited Company"
    },
    {
      "id": 5,
      "buisness": "Limited Liability Partnership"
    },
  ]
  constructor(private formBuilder: FormBuilder,
    private scheduleShopService: ScheduleShopService,
    private toastService: ToastNotificationService,
    public partyService: PartyService,
    private router: Router,
    private profileService: ProfileService,
    private location: Location,
    private titleCasePipe: TitleCasePipe) { }

  ngOnInit(): void {
    this.shopDetailsForm = this.formBuilder.group({
      //Basic details
      email: [null, [Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"), Validators.email, Validators.required]],
      mobile: [, [Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.required]],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      // parentId: ["", [Validators.required]],
      //shop details
      isRegistered: ['false', [Validators.required]],
      legalName: ["", [Validators.required]],
      accountMethod: ["", [Validators.required]],
      businessType: ["", [Validators.required]],
      //gstinNo: ["", [Validators.required]],
      gstinNo: [null, [Validators.pattern("^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"),
      Validators.minLength(15), Validators.maxLength(15)]],
      gstType: ["", [Validators.required]],
      compositeType: ["", [Validators.required]],
      //address details
      homeno: ["", [Validators.required]],
      pincode: ["", [Validators.minLength(6), Validators.maxLength(6), Validators.pattern("^[0-9]*$"), Validators.required]],
      address: ["", [Validators.required]],
      city: ["", Validators.required],
      district: ["", Validators.required],
      landmark: ["", [Validators.required]],
      state: ["", Validators.required],
      country: ["", Validators.required],
    });
  }
  get f() {
    return this.shopDetailsForm.controls;
  }
  saveShop() {
    this.submitted = true;
    // console.log("Shop" + JSON.stringify(this.shopDetailsForm.value));
    // if (this.shopDetailsForm.invalid) {
    //   this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
    //   return false;
    // }

    if(this.isShown){
      if((this.shopDetailsForm.controls.email.value == "" || this.shopDetailsForm.controls.mobile.value == "" || this.shopDetailsForm.controls.firstName.value == ""
          || this.shopDetailsForm.controls.lastName.value == "" || this.shopDetailsForm.controls.isRegistered.value == "" || this.shopDetailsForm.controls.legalName.value == ""
          || this.shopDetailsForm.controls.accountMethod.value == "" || this.shopDetailsForm.controls.businessType.value == "" || this.shopDetailsForm.controls.gstinNo.value == ""
          || this.shopDetailsForm.controls.gstType.value == "" || this.shopDetailsForm.controls.compositeType.value || this.shopDetailsForm.controls.homeno.value == "" || this.shopDetailsForm.controls.pincode.value == ""
          || this.shopDetailsForm.controls.address.value == "" || this.shopDetailsForm.controls.city.value == "" || this.shopDetailsForm.controls.district.value == "" || this.shopDetailsForm.controls.landmark.value == "" 
          || this.shopDetailsForm.controls.state.value == "" || this.shopDetailsForm.controls.country.value == "") && (this.shopDetailsForm.invalid))
          {
            this.toastService.toastMsg({
              title: "Error",
              content: "Fill All Required Fields.",
            });
            // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
            return false;
           }
          else if((this.shopDetailsForm.controls.email.value == "" && this.shopDetailsForm.controls.mobile.value == "" && this.shopDetailsForm.controls.firstName.value == ""
          && this.shopDetailsForm.controls.lastName.value == "" && this.shopDetailsForm.controls.isRegistered.value == "" && this.shopDetailsForm.controls.legalName.value == ""
          && this.shopDetailsForm.controls.accountMethod.value == "" && this.shopDetailsForm.controls.businessType.value == "" && this.shopDetailsForm.controls.gstinNo.value == ""
          && this.shopDetailsForm.controls.gstType.value == "" && this.shopDetailsForm.controls.compositeType.value == "" && this.shopDetailsForm.controls.homeno.value == "" || this.shopDetailsForm.controls.pincode.value == ""
          && this.shopDetailsForm.controls.address.value == "" && this.shopDetailsForm.controls.city.value == "" && this.shopDetailsForm.controls.district.value == "" || this.shopDetailsForm.controls.landmark.value == "" 
          && this.shopDetailsForm.controls.state.value == "" && this.shopDetailsForm.controls.country.value == "") && (this.shopDetailsForm.invalid))
          {
            this.toastService.toastMsg({
              title: "Error",
              content: "Fill All Required Fields.",
            });
            // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
            
          }
          else {
            this.addShopObject();
          }
    } else {
      if((this.shopDetailsForm.controls.email.value == "" || this.shopDetailsForm.controls.mobile.value == "" || this.shopDetailsForm.controls.firstName.value == ""
          || this.shopDetailsForm.controls.lastName.value == "" || this.shopDetailsForm.controls.isRegistered.value == "" || this.shopDetailsForm.controls.legalName.value == ""
          || this.shopDetailsForm.controls.accountMethod.value == "" || this.shopDetailsForm.controls.businessType.value == "" || this.shopDetailsForm.controls.gstinNo.value == ""
          || this.shopDetailsForm.controls.gstType.value == "" || this.shopDetailsForm.controls.compositeType.value || this.shopDetailsForm.controls.homeno.value == "" || this.shopDetailsForm.controls.pincode.value == ""
          || this.shopDetailsForm.controls.address.value == "" || this.shopDetailsForm.controls.city.value == "" || this.shopDetailsForm.controls.district.value == "" || this.shopDetailsForm.controls.landmark.value == "" 
          || this.shopDetailsForm.controls.state.value == "" || this.shopDetailsForm.controls.country.value == "") && (this.shopDetailsForm.invalid))
          {
            this.toastService.toastMsg({
              title: "Error",
              content: "Fill All Required Fields.",
            });
            // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
            return false;
           }
          else if((this.shopDetailsForm.controls.email.value != "" && this.shopDetailsForm.controls.mobile.value != "" && this.shopDetailsForm.controls.firstName.value != ""
          && this.shopDetailsForm.controls.lastName.value != "" && this.shopDetailsForm.controls.isRegistered.value != "" && this.shopDetailsForm.controls.legalName.value != ""
          && this.shopDetailsForm.controls.accountMethod.value != "" && this.shopDetailsForm.controls.businessType.value != "" && this.shopDetailsForm.controls.gstinNo.value != ""
          && this.shopDetailsForm.controls.gstType.value != "" && this.shopDetailsForm.controls.compositeType.value != "" && this.shopDetailsForm.controls.homeno.value != "" || this.shopDetailsForm.controls.pincode.value != ""
          && this.shopDetailsForm.controls.address.value != "" && this.shopDetailsForm.controls.city.value != "" && this.shopDetailsForm.controls.district.value != "" || this.shopDetailsForm.controls.landmark.value != "" 
          && this.shopDetailsForm.controls.state.value != "" && this.shopDetailsForm.controls.country.value != "") && (this.shopDetailsForm.invalid))
          {
            this.toastService.toastMsg({
              title: "Error",
              content: "Fill All Required Fields.",
            });
            // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
            
          }
          else {
            this.addShopObject();
          }

    }

   
  }


  addShopObject(){
    let body = {
      "basicDetails": {
        "email": this.shopDetailsForm.controls.email.value,
        "mobile": this.shopDetailsForm.controls.mobile.value,
        "firstName": this.shopDetailsForm.controls.firstName.value,
        "lastName": this.shopDetailsForm.controls.lastName.value,
        "parentId": this.currentUser.id
      },
      "shopDetails": {
        "isRegistered": this.shopDetailsForm.controls.isRegistered.value,
        "legalName": this.shopDetailsForm.controls.legalName.value,
        "accountMethod": this.shopDetailsForm.controls.accountMethod.value,
        "businessType": this.shopDetailsForm.controls.businessType.value,
        "gstinNo": this.shopDetailsForm.controls.gstinNo.value,
        "gstType": this.shopDetailsForm.controls.gstType.value,
        "compositeType": this.shopDetailsForm.controls.compositeType.value
      },
      "address": {
        "homeno": this.shopDetailsForm.controls.homeno.value,
        "pincode": this.shopDetailsForm.controls.pincode.value,
        "address": this.shopDetailsForm.controls.address.value,
        "city": this.shopDetailsForm.controls.city.value,
        "district": this.shopDetailsForm.controls.district.value,
        "landmark": this.shopDetailsForm.controls.landmark.value,
        "state": this.shopDetailsForm.controls.state.value,
        "country": this.shopDetailsForm.controls.country.value
      }
    }
    this.scheduleShopService.addNewShop(body).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Shop Record Added Successfully!",
          })
        // this.toastService.openSnackBar("Shop Record Added Successfully");
        this.router.navigate(["/pages/shop-page"]);
      } 
    },(err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    });
  }
  getGstNumber(gstin) {
    this.partyService.getGstNumber({}, gstin).then((res) => {
      this.gstDetails = res;
      if (res) {
        this.shopDetailsForm.patchValue({
          gstType: res.data.taxPayerType,
          legalName: res.data.legalName,
          // status: res.data.status
        })
      }
      this.isDisabledGST = false;
    }, (err) => {
      this.isDisabledGST = true;
    })
  }

  //get pincode api
  getPincodeDetails(pin) {
    this.profileService.getUserPincode({}, pin).then((res) => {
      this.pinDetails = res;
      this.shopDetailsForm.patchValue({
        district: res.data.districtName,
        state: res.data.stateName,

      })
    })
  }
  backBtn() {
    this.location.back();
  }
}
