import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';
import { ConfigureWidgetModalComponent } from './pages/configure-widget-modal/configure-widget-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MaterialExampleModule } from 'src/app/material.module';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UpdateDashboardComponent } from './pages/update-dashboard/update-dashboard.component';
import { DashboardNavComponent } from './pages/dashboard-nav/dashboard-nav.component';
import { DynamicComponentDirective } from './pages/update-dashboard/components/dynamic-component-loader.component';
import { ChartModule } from 'angular-highcharts';



@NgModule({
  declarations: [
    // DashboardNavComponent,
    // UpdateDashboardComponent,
    // DynamicComponentDirective,
    ConfigureWidgetModalComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MaterialExampleModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule,
    DragDropModule,
    NgbModule,
    RouterModule,
    ChartModule
  ],
  exports: [
    // DashboardNavComponent,
    // UpdateDashboardComponent,
    // DynamicComponentDirective,
    ConfigureWidgetModalComponent
  ],
  providers: [CurrencyPipe, TitleCasePipe, NgbActiveModal],
})
export class DashboardModule { }
