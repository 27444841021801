import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignServiceService {

  constructor(public httpClient:HttpService) { }
  
  getCampaignBycampId(data:{},campaignId):any{
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.GetCampaignBYCamapignId +campaignId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllCampaigns(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAllCampaigns + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  addCampaign(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addCampaign + userId , 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteCampaigns(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteCampaigns + userId , 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updateCampaign(data, campId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.updateCampaign + campId , 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  addTemplate(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addTemplate + userId , 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  getAllTemplate(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAllTemplate + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
