<div class="container-fluid px-0">
    <div class="row">
        <div class="col-md-12">
            <ngb-carousel>
                <ng-template ngbSlide>
                    <div class="row d-flex p-5">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                            <div class="card m-auto" style="width: 15rem;">
                                <div class="card-body p-0">
                                    <img src="../../../assets/images/galleryImg1.jpg">
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                            <div class="card m-auto" style="width: 15rem;">
                                <div class="card-body p-0">
                                    <img src="../../../assets/images/galleryImg2.jpg">
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                            <div class="card m-auto" style="width: 15rem;">
                                <div class="card-body p-0">
                                    <img src="../../../assets/images/galleryImg3.jpg">
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                            <div class="card m-auto" style="width: 15rem;">
                                <div class="card-body p-0">
                                    <img src="../../../assets/images/galleryImg4.jpg">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </div>
</div>