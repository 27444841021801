<div class="card mb-3">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Shop Details
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="container-fluid bg-white ">

        <div class="mt-3 d-flex justify-content-end">

            <button class="btn btn-outline-primary me-1 mb-1"  routerLinkActive="active"
            [routerLink]="['/pages/shop-add-form']" type="button">
            <i class="fas fa-plus-square me-2"></i>Add New Shop
            </button>
        </div>

        <div class="card-body p-0 overflow-hidden">
            <div class="table-responsive">
                <table class="table table-striped table-bordered mt-2 bg-white">
                    <thead >
                        <tr class="btn-reveal-trigger">
                            <th scope="col">#</th>
                            <th scope="col">Legal Name</th>
                            <th scope="col">Business Type</th>
                            <th scope="col">Registered</th>
                            <th scope="col">Email</th>
                            <th scope="col" class="text-center">Setting</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="shops">
                        <tr class="" *ngFor="let shop of ShopListResponse;let i = index">
                            <td>{{ i+1 }}</td>
                            <td>   
                               {{ shop.client == null ?  'no data' : shop.client.legalName | titlecase }}
                            </td>
                            <td>
                                {{ shop.client == null ?  'no data' : shop.client.businessType}}
                                
                            </td>
                            <td>
                                {{ shop.client == null ?  'no data' : shop.client.isRegistered}}
                               
                            </td>
                            <td>
                                {{ shop.email == "" ?  'no data' : shop.email}}
                              
                            </td>
                            <td class="name align-middle text-center white-space-nowrap py-2">

                                <button class="btn"  [routerLink]="['/pages/shop-setting/'+ shop.id + '/edit-shop-details/' + shop.id ]">
                                    <span class="bi-gear icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Setting"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody> 

                    <tbody *ngIf="ShopListResponse == 0">
                        <tr class="text-center">
                            <td colspan="10">
                                <h5 class="text-nowrap">No Shops Details Added. Please Add  Shops Details To Be Displayed Here. <span>
                                    <a class="nav-link" routerLink="/pages/shop-add-form">Add New Shop</a>
                               </span></h5>

                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </div> 
 </div>