<div class="container-fluid mb-3 mx-0 bg-white">
  <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
    <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
      Item Batch Report
    </div>
    <div class="col-6 navOption-btns" style="display: flex; justify-content: flex-end;">
      <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i>
        Export</button>
      <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
      </button>
    </div>
  </div>
  <hr class="mt-1" />
  <div class="fs--1">
    <div class="row mx-auto mb-3">
      <div class="headerButtons mb-3" id="showoptionHide">
      <div class="selection-dropdown row flex-item" *appHasPermission="['read']; name: 'warehouse'">
        <div class="ps-0" style="flex-basis: 220px;" *ngIf="currentUser.userType === 'CLIENT'">
            <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl [(ngModel)]="selectedType" (ngModelChange)="onTypeChange()" placeholder="Select Type">
                <mat-option value="clear">Clear Selection</mat-option>
                <mat-option value="Shop">Shop</mat-option>
                <mat-option value="Warehouse">Warehouse</mat-option>
            </mat-select>
            </mat-form-field>
        </div>
        <!-- Shop/Warehouse selection dropdown -->
        <div class="ps-0" style="flex-basis: 220px;" *ngIf="currentUser.userType === 'CLIENT' && selectedType">
            <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl disableOptionCentering [(ngModel)]="selectedItem" (ngModelChange)="onSelectionChange()" placeholder="{{ selectedType }}">
                <mat-option *ngFor="let item of (selectedType === 'Shop' ? shopList : warehouseList)" [value]="item">
                {{ selectedType === 'Shop' ? item.client.tradeName : item.firstName }}
                </mat-option>
            </mat-select>
            </mat-form-field>
        </div>
        <div class="ps-0" style="flex-basis: 220px;" *ngIf="currentUser.userType === 'SHOP'">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-select matNativeControl disableOptionCentering [(ngModel)]="warehouse" (ngModelChange)="onSelectionChange()" placeholder="Warehouse">
                    <mat-option value="clear">Clear Selection</mat-option>
                    <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                        {{ warehouse.firstName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
      <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;"
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3" id="searchBarHide">
        <!-- <label class="form-label">Select Product Name</label> -->
        <mat-form-field class="example-full-width" appearance="outline">
          <input type="text" placeholder="Product Name" (input)="searchProduct()" aria-label="Select Product Name"
            matInput [formControl]="myControl" [matAutocomplete]="auto">
          <mat-icon class="m-2" matSuffix>search</mat-icon>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.itemName"
              (onSelectionChange)="selectedProduct(option.itemName)">
              {{ option.itemName | titlecase }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        </div>
        </div>
      </div>


      
   
      <div class="table-responsive scrollbar mt-3">
        <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
          <thead class="" style="background-color: #F0F0F0;">
            <tr class="text-900">
              <th class="text-center" scope="col">#</th>
              <th class="text-center" scope="col">Item Name</th>
              <th class="text-center" scope="col">Current Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr class="btn-reveal-trigger text-left" *ngFor="let item of itemList;let i = index">
              <td class="text-center">{{ i + 1}}</td>
              <td class="Item Name text-center">{{item?.itemName ? item.itemName : item?.central_invs?.stock}}</td>
              <td class="Current Quantity text-center">{{item?.central_invs?.stock}}</td>
            </tr>
          </tbody>
          <tbody *ngIf="itemList == 0">
            <tr class="text-center">
              <td colspan="10">
                <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1"  width="200" height="150">
              </td>
            </tr>
          </tbody>
        </table>
        <div id="headingPrintHide1">
          <mat-paginator class="float-right mt-2" *ngIf="itemList.length > 0" [pageSize]="partyPageSize"
            [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo" showFirstLastButtons
            (page)="handlePage($event)" aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>