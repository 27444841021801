<div class="container-fluid mb-3 mx-0 bg-white">
  <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
      <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
        Low Stock Summary
      </div>
      <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
        <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i>
          Export</button>
        <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
        </button>
      </div>
  </div>
  <hr class="mt-1" />
  <div class="fs--1">
    <div class="row mx-auto">
      <div class="container">
        <div class="row mb-3" id = "searchBarHide">
          <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <label class="form-label">Select Categories</label>
            <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width" appearance="outline">
              <mat-select placeholder="Select Categories" (selectionChange)="getCategories($event.value)">
                <mat-option *ngFor="let item of productCategory" [value]="item">{{item.categoryName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="table-responsive scrollbar mt-3">
          <table class="table table-bordered table-striped fs--1 mb-3" id="excel-table">
            <thead class="" style="background-color: #F0F0F0;">
              <tr class="text-900">
                <th class="text-nowrap text-center">#</th>
                <th class="text-nowrap text-center">Item Name</th>
                <th class="text-nowrap text-center">Minimum Stock Qty</th>
                <th class="text-nowrap text-center">Stock Qty</th>
                <th class="text-nowrap text-center">Stock Value</th>
              </tr>
            </thead>
            <tbody>
              <tr class="btn-reveal-trigger text-left" *ngFor="let item of stockList;let i = index">
                <td class="text-center">{{ i + 1}}</td>
                <td class="text-center">{{ item.itemName| titlecase }}</td>
                <td class="text-center">{{ item.lowStockUnits }}</td>
                <td class="text-center">{{ item?.central_invs[0]?.stock}}</td>
                <td class="text-center">{{ item?.central_invs[0]?.stockValue}}</td>
              </tr>
            </tbody>
            <tbody *ngIf="stockList == 0">
              <tr class="text-center">
                <td colspan="10">
                  <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1"  width="200" height="150">                </td>
              </tr>
            </tbody>
          </table>
          <div id="headingPrintHide1">
            <mat-paginator class="float-right mt-2" *ngIf="stockList.length > 0" [pageSize]="partyPageSize"
              [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo" showFirstLastButtons
              (page)="handlePage($event)" aria-label="Select page">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>