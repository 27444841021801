import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { TimesheetService } from 'src/app/core/services/timesheet.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-add-time',
  templateUrl: './add-time.component.html',
  styleUrls: ['./add-time.component.scss']
})
export class AddTimeComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public AddTimeForm: FormGroup;
  public isSwitchOn: boolean = false;
  public todaysDate;
  public employeeList: [];
  startTime: string = ''; // Format: 'hh:mm:ss'
  endTime: string = ''; // Format: 'hh:mm:ss'
  period: string = '';
  timeDifferenceFormatted: string = '';
  public submitted: boolean = false;
  public today: Date;
  constructor(
    public dialogRef: MatDialogRef<AddTimeComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public datatime: any,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private timesheetService: TimesheetService,
    public rolesPermissionsService: RolesPermissionsService,
  ) {
    this.today = new Date(new Date().setDate(new Date().getDate() - 15));
    this.todaysDate = new Date();
  }

  ngOnInit(): void {
    this.getUsersList();
    this.AddTimeForm = this.formBuilder.group({
      employeeName: [''],
      startDate: [this.todaysDate],
      start_time: ['', [Validators.required]],
      end_time: ['', [Validators.required]],
      period: ['00:00:00', Validators.required], // Default duration
      isBillable: [false],
      clientName: ['', [Validators.required]],
      projectName: ['', [Validators.required]],
      task: [''],
      description: [''],
      reason: [''],
    })

    if(this.datatime.day !== undefined){
      let initialDate = new Date(this.datatime.day);
      this.AddTimeForm.patchValue({
        startDate: moment(initialDate).format("DD-MMM-YYYY"),
      });
    }
    
  }

  calculateTimeDifference() {
    const startTimeValue = this.AddTimeForm.controls['start_time'].value;
    const endTimeValue = this.AddTimeForm.controls['end_time'].value;
    if (startTimeValue && endTimeValue) {
      const [startHour, startMinute] = startTimeValue.split(':');
      const [endHour, endMinute] = endTimeValue.split(':');

      const startDate = new Date();
      startDate.setHours(parseInt(startHour, 10));
      startDate.setMinutes(parseInt(startMinute, 10));

      const endDate = new Date();
      endDate.setHours(parseInt(endHour, 10));
      endDate.setMinutes(parseInt(endMinute, 10));

      if (startDate < endDate) {
        const timeDifferenceInMilliseconds = endDate.getTime() - startDate.getTime();
        const hoursDifference = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
        const minutesDifference = Math.floor((timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const secondsDifference = Math.floor((timeDifferenceInMilliseconds % (1000 * 60)) / 1000);

        this.timeDifferenceFormatted = `${this.formatTimeUnit(hoursDifference)}:${this.formatTimeUnit(minutesDifference)}:${this.formatTimeUnit(secondsDifference)}`;

        this.AddTimeForm.patchValue({
          period: this.timeDifferenceFormatted
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Start Time Must Be Less Than End Time!",
        });
        this.AddTimeForm.patchValue({
          start_time: [null, [Validators.required]],
          end_time: [null, [Validators.required]],
        });
      }
    }
    else {
      this.timeDifferenceFormatted = '';
      this.AddTimeForm.patchValue({
        period: this.timeDifferenceFormatted,
      });
    }
  }

  private formatTimeUnit(unit: number): string {
    return unit < 10 ? `0${unit}` : `${unit}`;
  }

  getUsersList() {
    this.rolesPermissionsService.getUsers({}, this.currentUser.id,1000,1).then((res) => {
      this.employeeList = res.data.pageData.map((item) => { return item.firstName });
    })
  }

  // Short-cut used in mat errors.
  get f() {
    return this.AddTimeForm.controls;
  }

  // To save the add time form
  postAddTimeForm() {
    this.submitted = true;
    if (this.AddTimeForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }
    else {
      if (this.AddTimeForm.controls.start_time.value < this.AddTimeForm.controls.end_time.value) {

        let body = {
          "timeDetails": {
            "date": this.AddTimeForm.controls.startDate.value,
            "startTime": this.AddTimeForm.controls.start_time.value,
            "endTime": this.AddTimeForm.controls.end_time.value,
            "period": this.AddTimeForm.controls.period.value,
            "isBillable": this.AddTimeForm.controls.isBillable.value,
            "selectProject": this.AddTimeForm.controls.projectName.value,
            "enterTask": this.AddTimeForm.controls.task.value,
            "description": this.AddTimeForm.controls.description.value,
            "reason": this.AddTimeForm.controls.reason.value,
            "selectClient": this.AddTimeForm.controls.clientName.value,
            "selectEmployee": this.AddTimeForm.controls.employeeName.value,
          }
        }

        this.timesheetService.addTime(body, this.currentUser.id).then((res) => {
          this.toastService.toastMsg({
            title: "Success",
            content: "Time Added Successfully!",
            })
          this.timesheetService.notifytimeAdded();
          this.AddTimeForm.reset();
          this.todaysDate = "";
          this.submitted = false;
          this.dialogRef.close();
        },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
            }
          })
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Start Time Must Be Less Than End Time!",
        });
      }
    }
  }

  // For isBillable input.
  toggleSwitch(): void {
    this.isSwitchOn = !this.isSwitchOn;
  }

  // To close the dialog.
  clearAddTimeForm() {
    this.dialogRef.close();
  }

  openDatepicker() {
   // Check if the current value of taskdate is an "Invalid Date"
   const selectedDate = this.AddTimeForm.get('startDate').value;
   if (!(selectedDate instanceof Date) || isNaN(selectedDate.getTime())) {
       // Set a default date or any valid date here
       this.AddTimeForm.get('startDate').setValue(new Date());
   }
  }
  
  stringOnly(event){
    const charCode = (event.which) ? event.which : event.keyCode;

    // Allow alphabets (a-z, A-Z) and space (charCode 32)
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode === 32) {
      return true;
    }

    return false;
  }

}
