<div class="widget" style="box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15)">
  <mat-card>
    <mat-card-header>
      <mat-card-title style="
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 18px;
        ">Net Profit: Past Year Vs Current Year</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div [chart]="columnChart" style="margin: auto"></div>
      <!--<div id="column-chart" style="margin: auto">
        {{ netProfitChart }}
      </div>-->
    </mat-card-content>
  </mat-card>
</div>