<!--temporary addition-->
<div class="d-flex justify-content-center align-items-center" style="background-color: #e7e6ee; height:70vh;">
  <div class="block text-center">
    <div class="mb-2">
      <div class="row">
        <div class="col-md-3">
          <img src="../../../../../assets/img/chart-icons/chart1.png" alt="" />
        </div>
        <div class="col-md-3">
          <img src="../../../../../assets/img/chart-icons/chart2.png" alt="" />
        </div>
        <div class="col-md-3">
          <img src="../../../../../assets/img/chart-icons/chart3.png" alt="" />
        </div>
        <div class="col-md-3">
          <img src="../../../../../assets/img/chart-icons/chart4.png" alt="" />
        </div>
      </div>
    </div>
    <h5>Add any custom widgets to your dashboard</h5>
    <button type="button" class="btn btn-primary save">Add widgets to this dashboard</button>
  </div>
</div>