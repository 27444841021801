<div class="widget">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Pending Payment</mat-card-title>
      <div class="">
        <h5 class="pending-Payment">Payment</h5>
      </div>
    </mat-card-header>

    <mat-card-content>
      <!-- <div class="row" *ngFor="let item of pendingPartyList | slice : 0 : 5">
        <div class="col-md-6 col-sm-6 p-2 ps-4 text-left">
          <h3 class="card-title partyName mb-0">
            {{ item.partyName }}
          </h3>
        </div>
        <div class="col-md-6 col-sm-6 p-2 pe-3 text-right">
          <span class="green-digit">
            AED&nbsp;{{ item.totalDebt | number : "1.2-2" }}
          </span>
        </div>
      </div> -->
      <!-- <div *ngIf="pendingPartyList.length === 0" class="pendingParty-notFound">
        <img
          class="image-responsive"
          src="../../../assets/img/icons/spot-illustrations/notfound1.png"
          alt="notfound1"
          width="200"
          height="150"
        />
      </div> -->

      <div class="row" *ngFor="let item of pendingPartyList | slice : 0 : 5">
        <div class="firstRow">
          <div class="leftPart">
            <div class="dashboardName">
              <h3 class="card-title partyName">
                {{ item?.partyName ? item?.partyName : 'NA' }}
              </h3>
            </div>
          </div>
          <div class="finalAmount">
            <span class="green-digit">
              AED&nbsp;{{ item.totalDebt | number : "1.2-2" }}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="pendingPartyList.length === 0" class="pendingParty-notFound">
        <img
          class="image-responsive"
          src="../../../assets/img/icons/spot-illustrations/notfound1.png"
          alt="notfound1"
          width="200"
          height="150"
        />
      </div>
    </mat-card-content>
  </mat-card>
</div>
