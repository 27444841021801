import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { PayrollService } from 'src/app/core/services/payroll.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-new-structure',
  templateUrl: './new-structure.component.html',
  styleUrls: ['./new-structure.component.scss']
})
export class NewStructureComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))

  public NewStructureForm: FormGroup;
  public submitted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<NewStructureComponent>,
    private formBuilder: FormBuilder,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private payrollService: PayrollService,
    private commonService: CommonService) { }

  ngOnInit(): void {

    this.NewStructureForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      type: ["", [Validators.required]],
      description: ["", [Validators.required]],
    })

  }

  // Short-cut used in mat errors.
  get f() {
    return this.NewStructureForm.controls;
  }

  // To save the add time form
  postStructureForm() {
    this.submitted = true;
    if (this.NewStructureForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    } else {
      let body = {
          "name": this.NewStructureForm.controls.name.value,
          "type": this.NewStructureForm.controls.type.value,
          "description": this.NewStructureForm.controls.description.value,
          "userId": this.currentUser.id,
      }

      this.payrollService.addStructure(body).then((res) => {
          console.log(res);
          this.toastService.toastMsg({
            title: "Success",
            content: "New Structure Added Successfully!",
            });
          // this.toastService.openSnackBar("New Structure Added Successfully!!!");
          this.commonService.notifyDataAdded();
          this.submitted = false;
          this.dialogRef.close();
      },
      (err) => {

        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }

      })
    }

  }

  // To close the dialog.
  clearNewStructureForm() {
    this.dialogRef.close();
  }

}
