import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-low-stock-summary',
  templateUrl: './low-stock-summary.component.html',
  styleUrls: ['./low-stock-summary.component.scss']
})
export class LowStockSummaryComponent implements OnInit {
  fileName = 'LowStockSummary.xlsx';
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public ProductPageSize: number = 5;
  public pagesize: number = 1;
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public totalrow: any;
  public toatlPages: any;
  public p = 1;
  public productCategory: any;
  public search: any;
  stockList: any = [];

  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  constructor(private productService: ProductService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe) { }

  ngOnInit(): void {
    this.getLowStock();
    this.getProductCategory();
  }

  getProductCategory() {
    this.productService.getproductCategory({}).then((res: any) => {
      this.productCategory = res.data;
    })
  }
  getLowStock() {
    this.productService.getLowStockWarning({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.p = res.data.currentpage;
      this.stockList = res.data.pageData;
      this.totalrow = res.data.totalrows
      this.toatlPages = res.data.totalPages
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
        }
      })
  }
  public handlePage(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.stockList.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getLowStock();
    }
  }

  getCategories(search) {
    this.search = search;
    console.log(search);
    this.productService.getCategoriesList({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1), this.search.categoryName).then((res: any) => {

      console.log(res);
      this.p = res.data.currentpage;
      this.stockList = res.data.pageData;
      this.totalrow = res.data.totalrows;
      this.toatlPages = res.data.totalPages;
      if (this.stockList.length > 0) {
      } else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Record Not Found",
          });
      }


    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong",
            });
        }
      })
  }
  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  onPrint() {
    const elementsToHide = ['headingPrintHide', 'searchBarHide', 'headingPrintHide1'];
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = 'none';
      }
    });

    // Print
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
    // Restore hidden elements after printing
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = '';
      }
    });
  }

  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }
}
