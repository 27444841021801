import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FireStorageService {
  // public task: AngularFireUploadTask;
  // constructor(private angularFire: AngularFireStorage) { }

  
  // async upload(filepath,fileData) {
  //   this.task = this.angularFire.upload(filepath, fileData);
  //   return (await this.task).ref.getDownloadURL();
  // }
  // async delete(path) {
  //   return  await this.angularFire.storage.ref().child(path).delete();
  // }
}
