import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WidgetsLayoutHomeComponent } from './widgets-layout-home/widgets-layout-home.component';
import { UpdateDashboardComponent } from './pages/dashboard/pages/update-dashboard/update-dashboard.component';
import { SuperadminLayoutComponent } from './super-admin/components/superadmin-layout/superadmin-layout.component';
import { SuperadminSubscriptionComponent } from './super-admin/components/superadmin-subscription/superadmin-subscription.component';
import { SalesInvoiceComponent } from './pages/Invoice/sales-invoice/sales-invoice.component';
import { CustomerFacingDisplayComponent } from './customer-facing-display/customer-facing-display.component';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'widegts-home',
    loadChildren: () =>
      import('./widgets-layout-home/widgets-layout-home.module').then(
        (m) => m.WidgetsLayoutHomeModule
      ),
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'super-admin',
    loadChildren: () => import('./super-admin/super-admin.module').then((m) => m.SuperAdminModule),
  },
  {
    path: 'update-dashboard',
    component: UpdateDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-display',
    component: CustomerFacingDisplayComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'notfound',
    component: PageNotFoundComponent,
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', redirectTo: 'notfound' },
];
const config: ExtraOptions = {
  useHash: true,
  relativeLinkResolution: 'legacy',
};
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes),
    // RouterModule.forRoot(appRoutes),
    //   RouterModule.forRoot(appRoutes, {
    //     enableTracing: true,
    //     scrollPositionRestoration: 'top'
    //  }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
