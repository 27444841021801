import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from "src/app/core/services/auth.service";
import { CommonService } from 'src/app/core/services/common.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { NgxSpinnerService } from "ngx-spinner";
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: 'app-switch-user',
  templateUrl: './switch-user.component.html',
  styleUrls: ['./switch-user.component.scss']
})
export class SwitchUserComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  EmployeeForm: FormGroup;
  submitted:boolean= false
  employees: any =[];
  UserType: string;
  public isLoginBtn: boolean = false;
  public successful: boolean = false;
  hide: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<SwitchUserComponent>, private authService: AuthService, private commonService: CommonService,
    private fb: FormBuilder,public rolesPermissionsService: RolesPermissionsService,private toastService: ToastNotificationService,
    private router: Router,private cdr: ChangeDetectorRef,private titleCasePipe: TitleCasePipe, private spinner: NgxSpinnerService,
  ) { }

  // employees = [
  //   { id: 1, name: 'John Doe' },
  //   { id: 2, name: 'Jane Smith' },
  // ];

  ngOnInit(): void {
    this.getAllEmployee()
    this.EmployeeForm = this.fb.group({
      employeeName: ['',Validators.required],
      password: ['',Validators.required]
    });
  }

  getAllEmployee(){
    console.log(this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id)
    this.rolesPermissionsService.getUsers({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, 5000, 1).then((res: any) => {
      if (res.success) {
        this.employees = res.data.pageData;
        // const filteredgoodsData: string[] = this.userData.map(item => item.id);
        // const result = this.arraysHaveSameElements(filteredgoodsData, this.selectedEmployeeIds);
        // this.allSelect = !!result;
        // if (this.userData.length === 0){
        //   this.checkSelectsingle = false;
        //   this.upiCheckedId = [];
        //   this.checkSelect = false;
        //   this.allSelect = false;
        }
    })
  }

  cancelAddForm() {
    this.dialogRef.close();
  }

  get f() {
    return this.EmployeeForm.controls;
  }

  save() {
    this.submitted = true;
    let sendUserType: string = '';
    
    if (this.UserType === 'Employee') {
      sendUserType = "EMPLOYEE";
    }
   // Retrieve the values from the form controls
   const employeeName = this.EmployeeForm.controls.employeeName.value;
   const password = this.EmployeeForm.controls.password.value;

   if (!employeeName || !password) {
     this.toastService.toastMsg({
         title: "Error",
         content: "Please fill in all mandatory fields.",
     });
     return; // Stop further execution
   }

   let data = {
     email: employeeName,
     password: password,
     rememberme: '',
     userType: sendUserType ? sendUserType : 'EMPLOYEE'
   };

    
    // Retrieve the stored current route
    const currentRoute = localStorage.getItem('currentRoute');
    
    this.authService.login(data).subscribe(async (res) => {
      if (res.success && res.data) {
        await this.authService.logout();
        
        // Re-login after logout
        this.authService.login(data).subscribe(async (res) => {
          this.isLoginBtn = false;
          this.spinner.hide();
          this.startSession();
          this.toastService.toastMsg({
            title: "Success",
            content: "Login Successfully!",
          });
  
          // Navigate back to the current route without reloading
          if (currentRoute) {
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
              this.router.navigate([currentRoute]); // Navigate to the current route without reloading
            });
          } else {
            console.log("No current route available.");
          }
  
          this.dialogRef.close();
        });
      } else {
        this.handleNavigationOnError(currentRoute);
      }
    }, (err) => {
      this.handleLoginError(err);
    });
  }
  
  handleNavigationOnError(currentRoute: string | null) {
    if (currentRoute) {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentRoute]); // Navigate to the current route without reloading
      });
    } else {
      console.log("No current route available, staying on current page.");
    }
  }
  
  

  handleLoginError(err: any) {
    this.isLoginBtn = false;
    
    if (err.error.status === 406) {
      this.toastService.toastMsg({
        title: "Error",
        content: "User Not Verified. Please Check Your Register Email",
      });
    } else if (err.error.status == 404) {
      this.toastService.toastMsg({
        title: "Error",
        content: "User Not Found!!! Please Register Again!!!",
      });
    } else if (err.error.status == 401 || err.error.status == 400 || err.error.status == 403) {
      this.toastService.toastMsg({
        title: "Error",
        content: this.titleCasePipe.transform(err.error.error_message),
      });
    } else {
      this.toastService.toastMsg({
        title: "Error",
        content: "User Not Found Or Wrong Password.",
      });
    }
  }

  startSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const requestBody = {
        userId: currentUser.id,
        openingbalance: 0,
        closingbalance: null
      }
      this.commonService.startSession(requestBody).then((res: any) => {
        if (res.success) {
          if (currentUser) {
            currentUser.dailysessionId = res.data.sessionId;
          } else {
            currentUser = {
              ...currentUser,
              dailysessionId: res.data.sessionId
            };
          }
          // Save the updated currentUser object back to local storage
          localStorage.setItem('currentUser', JSON.stringify(currentUser));

          // Check if session already exists
          if (res.data.alreadyexists) {
            // Close the session and navigate to the close register page
          
            const currentRoute = localStorage.getItem('currentRoute');

            // Logic to redirect to the close register page
            this.router.navigate([currentRoute]); // Navigate to the current route without reloading
            this.toastService.toastMsg({
              title: 'Warning',
              content: 'Session was not close yet. Please close the session first.',
            });
            // Trigger the modal programmatically
            // const modalTrigger = document.getElementById('trigger-modal');
            // modalTrigger?.click();
          } else {
            // Regular startup screen logic
            this.toastService.toastMsg({
              title: 'Success',
              content: 'Session started successfully!',
            });
          }
        }else if (res.status === 406 && res.error_message === 'session already running') {
          // Handle session already running case
          // const modalTrigger = document.getElementById('trigger-modal');
          // modalTrigger?.click();
        } else {
          // Handle other types of errors
          this.toastService.toastMsg({
            title: 'Error',
            content: res.error_message || 'An error occurred while starting the session.',
          });
        }
      }).catch((err) => {
        if (err.status === 406 && err.error.error_message.trim() === 'session already running') {
          // Handle session already running case
          // const modalTrigger = document.getElementById('trigger-modal');
          // modalTrigger?.click();  // Open the modal to notify the user
        } else {
          this.toastService.toastMsg({
            title: 'Error',
            content: err?.error?.error_message || 'Something went wrong',
          });
          // content: 'An unexpected error occurred. Please try again later.',
        }
      });
    });
  }

  redirectToCloseRegister() {
    const currentRoute = localStorage.getItem('currentRoute');

    // Logic to redirect to the close register page
    this.router.navigate([currentRoute]); // Navigate to the current route without reloading

   // this.router.navigate(['/pages/close-register']);
  }
 
}
