import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { FormArray, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { PartyService } from 'src/app/core/services/party.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { PurchaseInvoiceService } from 'src/app/core/services/purchase-invoice.service';
import { PurchaseReturnService } from 'src/app/core/services/purchase-return.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { StateConstants } from 'src/app/_helpers/state-constans';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import lang from "src/assets/langTranslation/language_translation"
import { WarehouseService } from 'src/app/core/services/warehouse.service';

@Component({
  selector: 'app-puchase-return',
  templateUrl: './puchase-return.component.html',
  styleUrls: ['./puchase-return.component.scss']
})
export class PuchaseReturnComponent implements OnInit {
  // serialNumber: number = 1;
  saleReturnType = "FULL";

  public lang = lang.UAE;
  public gstAmount = 0;
  public maxDate: Date;
  public totalPrice: any;
  public PRinvoiceNo: any;
  public Units = Constants.Units;
  public unitValue: any;
  public index: any;
  public product: any;
  public amount: any;
  public qty: any;
  public billNo: any;
  public allStates = StateConstants.states;
  public unit: any;
  public wtax: any;
  public wotax: any;
  public ramount: any;
  public myControl1 = new FormControl();
  searchedProduct: any;
  productOptions: Observable<any>;
  public allPartyList: any = [];
  public formControl = new FormControl(new Date());
  public ngModelDate = new Date();
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public today: Date;
  public productData: any = [];
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  public purchaseForm: any;
  public subTotal = 0;
  public totalGST = 0;
  public total = 0;
  public todaysDate;
  public invoiceNo;
  public invoiceDate;
  public gstType: any;
  public gstInRs: any
  public gstInPer: any;
  public additionalCess: number = 0;
  public isShown: boolean = false;
  public compositeType: any;
  public lblColor: boolean = false;
  public barcode = "";
  public sendArray: any = [];
  public PurchaseReturnNo;
  public purchaseInvoiceNo;
  public gstRate = 0;
  public invoiceDetails: any;
  public invoiceId: any;
  public invoiceIDDD: any;
  public SaleProduct: any;
  public GstAmount = 0;
  public totalAmount = 0;
  public partyPagesize: number = 10;
  public currentPageNo: number = 0;
  public partyPageSize: number = 10;
  public submitted: boolean = false;
  public warehouseList: any[];

  constructor(private saleInvoiceService: SaleInvoiceService, public activeModal: NgbActiveModal, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PuchaseReturnComponent>,
    private formBuilder: FormBuilder,
    private partyService: PartyService,
    private purchasereturnService: PurchaseReturnService,
    private router: Router,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private biddingService: BiddingService,
    private commonService: CommonService,
    private warehouseService: WarehouseService,
    private profileService: ProfileService, private purchaseInvoiceService: PurchaseInvoiceService,
    private modal: NgbModal) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }

  ngOnInit(): void {
    this.getWarehouseList();
    this.todaysDate = new Date();
    this.newAttribute = { unit: "", ramount: "", wtax: "", wotax: "", index: "", product: "", gty: "", select: "" };
    this.fieldArray.push(this.newAttribute);
    this.purchaseForm = this.formBuilder.group({
      PurchaseReturnNo: ["", [Validators.required]],
      purchaseInvoiceno: ["", [Validators.required]],
      returnType: [false],
      warehouse: [null],
    });

  }
  get f() {
    return this.purchaseForm.controls;
  }

  getUnit(value: string) {
    this.unitValue = "1 " + value;
  }

  async calculationTotal() {
    this.barcode = "";
    let price = 0;

    let additionalCess = 0;
    let gstInPer = 0;
    this.totalPrice = 0;
    this.subTotal = 0;
    this.gstRate = 0;
    this.productData.forEach((element) => {
      let gstInPer = element.gstRate;
      price = price + (element.salePrice * element.quantity);
      this.gstRate = ((element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity) + this.gstRate
      this.gstAmount = (element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity
      additionalCess = element.salePrice * element.additionalCess / 100;
      this.subTotal = (element.basePrice * element.quantity) + this.subTotal;
      element.subTotal = element.basePrice * element.quantity;
      element.gstAmount = (element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity
      element.total = element.salePrice * element.quantity;
      gstInPer = element.gstRate;
      if (this.isShown) {
        this.totalPrice = price;
      } else {
        this.totalPrice = price;
      }
    });


    if (this.gstType === 'Exempted') {
      this.totalGST = 0;
      this.gstInRs = 0;
      this.gstInPer = 0
    } else if (this.gstType === 'Composite') {
      if (this.compositeType == 'Manufacturer' || this.compositeType == 'Trader') {
        this.totalGST = this.subTotal * 1 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else if (this.compositeType == 'Restaurant') {
        this.totalGST = this.subTotal * 5 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else {
        this.totalGST = this.subTotal * 6 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      }
    } else {
      this.totalGST = this.gstRate;
      this.gstInRs = this.gstRate;
      this.gstInPer = gstInPer;
    }

    this.additionalCess = additionalCess
    if (this.isShown) {
      this.total = price;
    } else {
      this.total = price;
    }
  }

  /**
   * get goods by barcode
   */
  getGoodsByBarcode() {
    this.saleInvoiceService.getGoodsByBarcode({}, this.barcode, this.purchaseForm.value.warehouse ? this.purchaseForm.value.warehouse : this.currentUser.id)
      .then((response) => {
        // Check if the barcode already exists in productData
        const existingProductIndex = this.productData.findIndex(
          (product) => product.barcode === response.data.barcode
        );

        if (existingProductIndex !== -1) {
          // Barcode exists, update quantity
          this.productData[existingProductIndex].quantity += 1;
        } else {
          // Barcode doesn't exist, add new product
          response.data.quantity = 1;
          this.productData.push(response.data);
        }
        this.calculationTotal();
      });
  }
  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  
  onSearchInputChange(searchKey: string) {
    if (searchKey.length >= 3) {
      this.getGoodsList(searchKey);
    }
  }

  getGoodsList(value) {
    if (value && (String(value).trim() !== '' || value !== null)) {
      this.biddingService.getAllProductsByUserSearch({}, this.purchaseForm.value.warehouse ? this.purchaseForm.value.warehouse : this.currentUser.id, value, 1000, 1, this.purchaseForm.value.warehouse ? this.purchaseForm.value.warehouse : this.currentUser.id)
        .then((res) => {
          if(res.success) {
            this.searchedProduct = res.data.pageData;
            this.productOptions = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
              );
            }
        },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
            }

          })
    } else {}
  }
   
  getGoodsByBarcodeSelected(barcode, event) {
    if (event.isUserInput) {
      this.saleInvoiceService
        .getGoodsByBarcode({}, barcode, this.purchaseForm.value.warehouse ? this.purchaseForm.value.warehouse : this.currentUser.id)
        .then(async (response) => {
          response.data.quantity = 1;
          // Check if the barcode already exists in productData
          const existingProductIndex = this.productData.findIndex(
            (product) => product.barcode === response.data.barcode
            );

          if (existingProductIndex !== -1) {
            // Barcode exists, update quantity
            this.productData[existingProductIndex].quantity += 1;
            // await this.checkFreeProducts(this.productData)
            // await this.checkOffer(this.productData[existingProductIndex].quantity, this.productData[existingProductIndex].id)
            // this.removeCoupon()
          } else {
            // Barcode doesn't exist, add new product
            response.data.quantity = 1;
            this.productData.push(response.data);
            // this.addDetailsButtonClick(this.productData.length - 1);
            // await this.checkFreeProducts(this.productData)
            // await this.checkOffer(response.data.quantity, response.data.id)
            // this.removeCoupon()
          }

          this.calculationTotal();
          this.myControl1.patchValue('')
          document.getElementById('inp12').nodeValue = ''
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
    }
  }

  addDetailsButtonClick(i): void {
    const content = this.purchaseForm.get("subcheckbox") as FormArray;
    content.push(this.addRolesPermission(i));
  }

  addRolesPermission(id): FormGroup {
    return new FormGroup({
      read: new FormControl(false),
    });
  }

  /**
   * quantity change method
   * @param sign
   * @param i
   */

  changeQuantity(sign, i, quantity = 0) {
    switch (sign) {
      case "-":
        if (this.productData[i].quantity > 1) {
          this.productData[i].quantity = this.productData[i].quantity - 1;
        }
        this.calculationTotal();
        break;
      case "+":
        this.productData[i].quantity = this.productData[i].quantity + 1;
        this.calculationTotal();
        break;
      case "rm":
        this.productData.splice(i, 1);
        if (this.productData.length <= 0) {
          this.totalGST = 0;
          this.subTotal = 0;
          this.total = 0;
        }
        this.calculationTotal();
        break;
      case "qty":
        this.productData[i].quantity = quantity;
        this.calculationTotal();
        break;
      default:
        this.productData[i];
        break;
    }
  }
  /**
   * calculation for quantity change
   * @param i
   */
  changeQtyCalculation(i) {
    this.totalGST = (this.productData[i].salePrice * this.productData[i].quantity) * (this.productData[i].gstRate / 100);
    this.subTotal = (this.productData[i].salePrice * this.productData[i].quantity);
    this.total = this.totalGST + this.subTotal;
    this.calculationTotal();
  }
  SavePurchase() {
    this.submitted = true;
    if (this.purchaseForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields."
      });
      return false;
    }
    else {
      this.productData.forEach(e => {
        let data = {
          "purchaseProductId": e.id,
          "returnQty": e.quantity,
        }
        this.sendArray.push(data);
      })

      const userType = this.currentUser.userType.toLowerCase();
      const warehouseIdToSend =
        userType === 'warehouse' ? this.currentUser.id :
        userType === 'shop' ? (this.purchaseForm.value.warehouse || this.currentUser.id) :
        userType === 'client' && this.purchaseForm.value.warehouse ? this.purchaseForm.value.warehouse : this.currentUser.id;

      let body = {
        "purchaseInvoiceNumber": this.purchaseForm.controls.purchaseInvoiceno.value,
        "returnType": this.saleReturnType,
        "purchaseReturnNumber": this.purchaseForm.controls.PurchaseReturnNo.value,
        "returnDate": this.todaysDate,
        "userId": this.purchaseForm.value.warehouse ? this.purchaseForm.value.warehouse : this.currentUser.id,
        "returnProducts": this.sendArray,
        warehouseId: `${warehouseIdToSend}`
      }
      this.purchasereturnService.postPurchaseReturn(body).then((res: any) => {
        if (res.success) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Purchase Return Added Successfully!"
          });
          this.partyService.notifyPartyAdded();
          this.dialogRef.close();
        }
      }, (err) => {
        this.sendArray = [];
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }

  }

  toggleShow() {
    this.isShown = !this.isShown;
    if (this.isShown) {
      this.total = this.subTotal + this.totalGST + this.additionalCess;
    } else {
      this.total = this.subTotal + this.totalGST;
    }
  }

  getRadioBtnValue(event) {
    if (!event) {
      this.lblColor = true;
      this.saleReturnType = "FULL"
    }
    else {
      this.lblColor = false;
      this.saleReturnType = "PARTIAL"
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getInvoiceById(item) {
    this.invoiceId = item;
    this.getallInvoice();
  }

  getallInvoice() {
    this.purchaseInvoiceService.getAllPurchaseInvoiceBySearch({}, this.currentUser.id, "desc", "PURCHASE", this.invoiceId).then((res) => {
      if(res.success){
        this.invoiceIDDD = res.data.pageData[0].id;
        this.getReceiptById();
      }
    }, (err) => {
      this.sendArray = [];
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }

  getReceiptById() {
    this.saleInvoiceService.getSaleInvoiceById({}, this.invoiceIDDD).then((res) => {
      if(res.success){
        this.SaleProduct = res.data.sales_products;
        this.productData = this.SaleProduct
        this.GstAmount = res.data.billGstAmount;
        this.totalAmount = res.data.totalBillAmount
      }
    }, (err) => {
      this.sendArray = [];
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }

  cancel() {
    this.dialogRef.close();
    this.purchaseForm.reset();
  }

  openDatepicker() {
    const selectedDate = this.todaysDate;
    if (isNaN(selectedDate.getTime())) {
      this.todaysDate = new Date();
    }
  }

  getWarehouseList() {
    this.warehouseService.getAllWarehouse({
      data:{},
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
    }).then((res: any) => {
      this.warehouseList = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  // Function to handle selection change in warehouse selection dropdown
  onWarehouseSelectionChange(event: any) {
    if (event.value === 'clear') {
      this.purchaseForm.get('warehouse').setValue(null); // Clear the selection
      this.productData = [];
      this.calculationTotal();
    }
  }
}