import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { EndDayReportsService } from 'src/app/core/services/end-day-reports.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
    selector: 'app-daily-report-page',
    templateUrl: './daily-report-page.component.html',
    styleUrls: ['./daily-report-page.component.scss']
})
export class DailyReportPageComponent implements OnInit {
    public currentUser = JSON.parse(localStorage.getItem("currentUser"))
    allDataSale: any;
    allDataSaleReturn: any;
    allDatapurchase: any;
    allDatapurchaseReturn: any;
    allDataCashInHand: any;
    allDataPaymentIn: any;
    allDataPaymentout: any;
    allDataEstimation: any;
    allDataPerforma: any;
    allDataSaleOrder: any;
    allDataPurchaseOrder: any;
    allDataExpense: any
    cashInHandSeparate: any = [];
    saleData: any = [];
    salesReturnData: any = [];
    puchaseData: any = [];
    puchaseReturnData: any = [];
    paymentIndata: any = [];
    paymentIOutdata: any = [];
    saleOrderData: any = [];
    purchaseOrderData: any = [];
    expenseData: any = [];
    estimationData: any = [];
    todaysDate: any;
    SundryDebData: any = [];
    sundryCreditorData: any = [];
    trData: any = [];
    //Main print  data 
    trDataWithoutTax: any;
    trDataTax: any;
    trDataWithTax: any;
    saleDataWithoutTax: any;
    saleDataTax: any;
    saleDataWithTax: any;
    saleReturnDataWithoutTax: any
    saleReturnDataTax: any;
    saleReturnDataWithTax: any;
    purchaseDataWithoutTax: any;
    purchaseDataTax: any;
    purchaseDataWithTax: any;
    purchaseReturnDataWithoutTax: any;
    purchaseReturnDataTax: any;
    purchaseReturnDataWithTax: any;
    cashinHandData: any;
    cashinHandPaymentType: any;
    PaymentMinData: any;
    PaymentMOutData: any;
    estimateWithoutTax: any;
    estimateDataTax: any;
    estimateDataWithTax: any;
    saleOrderWithoutTax: any;
    saleOrderDataTax: any;
    saleOrderDataWithTax: any;
    purchaseOrderWithoutTax: any;
    purchaseOrderDataTax: any;
    purchaseOrderDataWithTax: any;
    expenseTotalAmount: any;
    saundryDebtorTotal: any;
    saundryCreditorTOTAL: any;


    constructor(private endService: EndDayReportsService, private titleCasePipe: TitleCasePipe, private toastService: ToastNotificationService) { }

    ngOnInit(): void {
        this.todaysDate = new Date();
        // this.getEndDay();
        this.getReceiptDetails();
    }
    getReceiptDetails() {
        this.endService.getAllDayReciptReportsDetails({}, this.currentUser.id).then((res) => {

            // sale
            this.allDataSale = res?.data?.sales?.sales[0]?.totalWithTax;
            // sale return
            this.allDataSaleReturn = res?.data?.saleReturn?.sales[0]?.totalWithTax;

            //purchase
            this.allDatapurchase = res?.data?.purchase?.purchase[0]?.totalWithTax;

            //purchase return 
            this.allDatapurchaseReturn = res?.data?.purchaseReturn?.purchaseReturn[0]?.totalWithTax;
            // cash in hand
            this.allDataCashInHand = res?.data?.cashInHand?.cashInHand.addr[0]?.cashInHand;
            // paymentIN
            this.allDataPaymentIn = res?.data?.paymentIn?.paymentIn[0]?.totalWithTax;

            // paymentOUT
            this.allDataPaymentout = res?.data?.paymentOut?.paymentIn[0]?.totalWithTax;

            // estimation
            this.allDataEstimation = res?.data?.estimation?.estimationTotal[0]?.totalWithTax;

            // 
            // this.allDataPerforma = res?.data?.sales?.sales[0]?.totalWithTax;

            // SALE ORDER
            this.allDataSaleOrder = res?.data?.saleOrder?.saleOrder[0]?.totalWithTax;

            // PURCHASE ORDER
            this.allDataPurchaseOrder = res?.data?.purchaseOrderReport?.purchaseOrder[0]?.totalWithTax;

            // expense
            this.allDataExpense = res?.data?.expense?.expense[0]?.totalAmount;

            this.saundryDebtorTotal = res?.data?.saundryDebtor?.saundryDebtorTOTAL[0]?.totalDebt;

            this.saundryCreditorTOTAL = res?.data?.saundryCreditor?.saundryCreditorTOTAL[0]?.totalCredit;


            // // SAUNDRY DEBTOR 
            // this.saund

            //   console.log(res)
            this.cashInHandSeparate = res.data?.cashInHand?.cashInHand?.addrNew
            this.saleData = res.data?.sales?.salesProduct;
            this.salesReturnData = res.data?.saleReturn?.salesProduct;
            this.puchaseData = res.data?.purchase?.purchaseProduct;
            this.puchaseReturnData = res.data?.purchaseReturn?.purchaseReturnProduct;
            this.paymentIndata = res.data?.paymentIn?.saleInvoices;
            this.paymentIOutdata = res.data?.paymentOut?.saleInvoices;
            this.saleOrderData = res.data?.saleOrder?.saleOrderProducts;
            this.purchaseOrderData = res.data?.purchaseOrderReport?.purchaseOrderProduct;
            this.expenseData = res.data?.expense?.expenseUsed
            this.estimationData = res.data?.estimation?.estimationInvoices;
            this.SundryDebData = res.data?.saundryDebtor?.saundryDebtor
            this.sundryCreditorData = res.data?.saundryCreditor?.saundryCreditor
            this.trData = res.data?.transaction?.allTranscationProducts

            //main print data for Transaction -
            this.trDataWithoutTax = res.data?.transaction?.allTranscation[0]?.totalWithoutTax
            this.trDataTax = res.data?.transaction?.allTranscation[0]?.tax
            this.trDataWithTax = res.data?.transaction?.allTranscation[0]?.totalWithTax

            // //main print data for Sales -
            this.saleDataWithoutTax = res.data?.sales?.sales[0]?.totalWithoutTax
            this.saleDataTax = res.data?.sales?.sales[0]?.tax
            this.saleDataWithTax = res.data?.sales?.sales[0]?.totalWithTax


            // //main print data for Sales Return
            this.saleReturnDataWithoutTax = res.data?.saleReturn?.sales[0]?.totalWithoutTax
            this.saleReturnDataTax = res.data?.saleReturn?.sales[0]?.tax
            this.saleReturnDataWithTax = res.data?.saleReturn?.sales[0]?.totalWithTax


            // //main print data for Purchase
            this.purchaseDataWithoutTax = res.data?.purchase?.purchase[0]?.totalWithoutTax
            this.purchaseDataTax = res.data?.purchase?.purchase[0]?.tax
            this.purchaseDataWithTax = res.data?.purchase?.purchase[0]?.totalWithTax

            // //main print data for Purchase Return
            this.purchaseReturnDataWithoutTax = res.data?.purchaseReturn?.purchaseReturn[0]?.totalWithoutTax
            this.purchaseReturnDataTax = res.data?.purchaseReturn?.purchaseReturn[0]?.tax
            this.purchaseReturnDataWithTax = res.data?.purchaseReturn?.purchaseReturn[0]?.totalWithTax

            // Cash in hand main page
            this.cashinHandData = res.data?.cashInHand?.cashInHand?.addr[0]?.cashInHand
            this.cashinHandPaymentType = res.data?.cashInHand?.cashInHand?.addr[0]?.paymentType
            //payment In main page 
            this.PaymentMinData = res.data?.paymentIn?.paymentIn[0]?.totalWithTax
            //payemnt out main page 
            this.PaymentMOutData = res.data?.paymentOut?.paymentIn[0]?.totalWithTax
            //estimation main page
            this.estimateWithoutTax = res.data?.estimation?.estimationTotal[0]?.totalWithoutTax
            this.estimateDataTax = res.data?.estimation?.estimationTotal[0]?.tax
            this.estimateDataWithTax = res.data?.estimation?.estimationTotal[0]?.totalWithTax
            //Sale Order main page
            this.saleOrderWithoutTax = res.data?.saleOrder?.saleOrder[0]?.totalWithoutTax
            this.saleOrderDataTax = res.data?.saleOrder?.saleOrder[0]?.tax
            this.saleOrderDataWithTax = res.data?.saleOrder?.saleOrder[0]?.totalWithTax
            //Purchase Order main page
            this.purchaseOrderWithoutTax = res.data?.purchaseOrderReport?.purchaseOrder[0]?.totalWithoutTax
            this.purchaseOrderDataTax = res.data?.purchaseOrderReport?.purchaseOrder[0]?.tax
            this.purchaseOrderDataWithTax = res.data?.purchaseOrderReport?.purchaseOrder[0]?.totalWithTax
            //expense main page.
            this.expenseTotalAmount = res.data?.expense?.expense[0]?.totalAmount
        },
            (err) => {
                if (err.error.expose) {
                }
                else {
                    this.toastService.toastMsg({
                        title: "Error",
                        content: "Something Went Wrong.",
                    });
                }
            })

    }

    getEndDay() {
        this.endService.getAllDayDetails({}, this.currentUser.id).then((res) => {
            this.allDataSale = res?.data?.sale;
            this.allDataSaleReturn = res?.data?.saleReturn
            this.allDatapurchase = res?.data?.purchase
            this.allDatapurchaseReturn = res?.data?.purchaseReturn
            this.allDataCashInHand = res?.data?.cashInHand[0]?.cashInHand
            this.allDataPaymentIn = res?.data?.paymentInTotal
            this.allDataPaymentout = res?.data?.paymentOutTotal
            this.allDataEstimation = res?.data?.estimation
            this.allDataPerforma = res?.data?.perfomaTotal
            this.allDataSaleOrder = res?.data?.saleOrder
            this.allDataPurchaseOrder = res?.data?.purchaseOrder
            this.allDataExpense = res?.data?.expenseReport[0]?.expenseAmount
            // console.log("API CALLL")
        },
            (err) => {
                if (err.error.expose) {
                }
                else {
                    this.toastService.toastMsg({
                        title: "Error",
                        content: "Something Went Wrong.",
                    });
                }
            })
    }
    getReceiptById() {

    }
    onPrintaTr() {
        const printContents = document.getElementById('tr_print').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
        body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}
        </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();



    }
    onPrintaSundryCredit() {
        const printContents = document.getElementById('sundryCred_print').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
        body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}
        </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();


    }
    onPrintaSundryDeb() {
        const printContents = document.getElementById('sundrydeb_print').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
        body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}
        </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();

    }
    onPrintaEstimation() {
        const printContents = document.getElementById('estimation_print').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
         body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}
            </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();
    }
    onPrintExpense() {
        const printContents = document.getElementById('expense_print').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
         body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}
        </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();



    }
    onPrintPurchaseOrder() {
        const printContents = document.getElementById('purchaseOrder_print').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
        body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}
        </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();




    }
    onPrintaSaleOrder() {
        const printContents = document.getElementById('SaleOrder_print').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
         body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}
            </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();



    }
    onPrintapyemnetOut() {
        const printContents = document.getElementById('normal_printPaymentOut').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
         body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}
            </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();


    }
    onPrintapyemnetIn() {
        const printContents = document.getElementById('normal_printPaymentIn').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
         body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}
        </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();

    }
    onPrintaPurchaseReturn() {
        const printContents = document.getElementById('purchaseReturn_print').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
        body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}
        </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();
    }


    onPrintaPurchase() {
        const printContents = document.getElementById('purchase_print').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
        body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}        </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();
    }


    onPrintaSaleReturn() {
        const printContents = document.getElementById('Sale_Return_print').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
         body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}

.text-end{
    text-align: right; /* Aligns the text to the right */
}
        </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();



    }

    onPrintaSale() {
        const printContents = document.getElementById('Sale_print').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}

        </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();


    }

    onPrintCashInhand() {
        const printContents = document.getElementById('normal_printCashInHand').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
         body {
    font-size: 14px;
    font-family: Poppins;
}
   #printPageButton {
            display: none;
          }
            .icCenter{
              display: flex !important;
             justify-content: center;
            }
#originalForReceiptPrint {
    display: flex !important;
    justify-content: center;
    border: 2px solid grey;
    color: black !important;
    font-size: 12px !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    margin: 0;
    padding: 0;
}

.table-responsive {
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the table */
    padding: 0;
}

.table-responsive table {
    width: 100%; /* Ensure the table takes up full width of its container */
    margin: 0;
    padding: 0;
    border-collapse: collapse; /* To ensure proper borders during printing */
}

.table-responsive thead th {
    color: white;
    background-color: rgb(56, 53, 53); /* Ensure header background is dark */
    -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
}

.table-responsive tbody tr, td {
    min-height: fit-content;
    height: auto !important;
}

.table-responsive th, td, tr {
    border: 1px solid #000 !important; /* Add borders for better readability */
}

.generateDate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px); /* Adjust the width to account for left and right margins */
    margin: 0 auto; /* Center the content */
}

.text-black {
    color: black; /* Set text color to black */
    margin-top: 1rem; /* Equivalent to mt-3 in Bootstrap, which is 1rem or 16px */
    font-size: 1rem; /* Equivalent to fs-6 in Bootstrap (about 16px) */
    font-family: Poppins, sans-serif; /* Set the font family to Poppins */
}

@media print {
    .table-responsive, .generateDate {
        width: calc(100% - 40px); /* Ensure margins are accounted for in print */
        margin: 0 auto; /* Center the content */
    }

    .row {
        margin-top: 3rem; /* Maintain the same top margin when printing */
    }

    .text-black {
        color: black; /* Ensure the text remains black in print */
        font-size: 1rem; /* Keep the font size consistent in print */
    }

    .col-lg-12, .col-sm-12, .col-md-12, .col-xl-12 {
        justify-content: center; /* Center content horizontally in print */
        align-items: center; /* Center content vertically in print */
    }

    .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .table-responsive th, td {
        border: 1px solid #000 !important; /* Ensure table borders are visible */
        padding: 5px; /* Add padding for better readability */
    }

    @page {
        margin: 10mm; /* Set print margins */
        size: auto; /* Let the content determine page size */
    }
}
    
.text-end{
    text-align: right; /* Aligns the text to the right */
}
        </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();

    }

    onPrint() {
        const printContents = document.getElementById('normal-print').innerHTML;
        // Open a new tab
        const printWindow = window.open('Iceipts Uae', '_blank');

        // Set the content of the new tab
        printWindow.document.write(
            `<html>
        <head><title>&nbsp;</title><style>
           #printPageButton {
            display: none;
          }
          #printPageButton {
      display: none;
    }
    .body {
      font-size: 14px;
      font-family: Poppins;
    }
    #originalForReceiptPrint {
      display: flex !important;
      justify-content: center;
      border: 2px solid grey;
      color: black !important;
      margin: 0;
      padding: 0;
      font-size: 12px !important;
      -webkit-print-color-adjust: exact;
    }
    .table-responsive {
      width: 100%;
      border-collapse: collapse;
    }
    .table-responsive thead th {
      color: white;
      background-color: rgb(56, 53, 53);
      -webkit-print-color-adjust: exact;
    }
    .table-responsive tbody tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    .table-responsive tbody tr {
      border-bottom: 1px solid #ddd;
    }
    .table-responsive th, td {
      border: 1px solid #ddd;
      padding: 8px;
    }
    .table-responsive tbody tr:hover {
      background-color: #ddd;
    }

    /* Print specific styles */
    @media print {
      .table-responsive {
        border: none;
      }
      .table-responsive thead th {
        background-color: rgb(56, 53, 53) !important;
        color: white !important;
      }
      .table-responsive tbody tr:nth-child(even) {
        background-color: #f2f2f2 !important;
      }
      .table-responsive tbody tr {
        border: 1px solid #ddd !important;
      }
      .table-responsive th, td {
        border: 1px solid #ddd !important;
        padding: 8px !important;
      }
      .table-responsive tbody tr:hover {
        background-color: transparent !important;
      }
      #printPageButton {
        display: none !important;
      }
    }
        </style></head><body>`
        );
        printWindow.document.write(printContents);
        printWindow.document.write('</body><footer>&nbsp;</footer></html>');
        printWindow.print();
        printWindow.document.close();
        printWindow.close();

    }


    closeModal() {
    }

}
