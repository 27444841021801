import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/_helpers/constant';

@Component({
  selector: 'app-feature-product',
  templateUrl: './feature-product.component.html',
  styleUrls: ['./feature-product.component.scss']
})
export class FeatureProductComponent implements OnInit {

  public defaultImage = Constants.images.defaultShopImg
  constructor() { }

  ngOnInit(): void {
  }

}
