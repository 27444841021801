import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { PagesModule } from './pages/pages.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { DynamicScriptLoaderService } from './_helpers/DynamicScriptLoaderService';
import { NgSelectModule } from "@ng-select/ng-select";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { GlobalErrorHandler } from './core/error/global-error-handler';
import { TextMaskModule } from 'angular2-text-mask';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin,
  listPlugin
]);
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WidegtsLibraryComponent } from './widegts-library/widegts-library.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialExampleModule } from './material.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from 'src/environments/environment';
import { initializeApp } from 'firebase/app';
import { AuthService } from './core/services/auth.service';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomToastComponent } from './core/services/custom-toast/custom-toast.component';
import { SharedModule } from './pages/shared/shared.module';
import { CustomerFacingDisplayComponent } from './customer-facing-display/customer-facing-display.component';

initializeApp(environment.firebase);

// Create a factory function to load translation files
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    CustomToastComponent,
    PageNotFoundComponent,
    WidegtsLibraryComponent,
    CustomerFacingDisplayComponent
  ],
  imports: [
    BrowserModule,
    NgSelectModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    PagesModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    TextMaskModule,
    FullCalendarModule,
    DragDropModule,
    NgbModule,
    MaterialExampleModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    CKEditorModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
    // FullCalendarModule
    // Ng2TelInputModule,
  ],
  providers: [
    DatePipe,
    { provide: 'root', useValue: 'root' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    DynamicScriptLoaderService,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
