// encryption.service.ts
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private key = 'key2778326726723'; // Must be 32 hex characters (128 bits)
  private iv = 'sefc78893hsdajhj783'; // Must be 16 hex characters (64 bits)

  encrypt(data: string): string {
    return CryptoJS.AES.encrypt(data, CryptoJS.enc.Hex.parse(this.key), {
      iv: CryptoJS.enc.Hex.parse(this.iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  }

  decrypt(encrypted: string): string {
    const decrypted = CryptoJS.AES.decrypt(encrypted, CryptoJS.enc.Hex.parse(this.key), {
      iv: CryptoJS.enc.Hex.parse(this.iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }
}