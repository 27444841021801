import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProfileService } from 'src/app/core/services/profile.service';


@Component({
  selector: 'app-add-role-based-users',
  templateUrl: './add-role-based-users.component.html',
  styleUrls: ['./add-role-based-users.component.scss']
})
export class AddRoleBasedUsersComponent implements OnInit {
  today: Date;
  maxDate: Date;
  public roleData: any;
  public usersForm: FormGroup;
  public roleName: any;
  public roleId: any;
  public submitted: boolean;
  public alreadyExists: boolean = false;
  public showAlert: boolean = false;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public profilePic: any = '';
  public profilePicUrl: any = '';
  pinDetails: any;
  city: string[] = [
    'Abu Dhabi',
    'Dubai',
    'Sharjah',
    'Ajman',
    'Umm Al Quwain',
    'Ras Al Khaimah',
    'Fujairah',
  ];
  assets: any[] = [
    { id: 1, name: 'Laptop', selected: false },
    { id: 2, name: 'Mobile', selected: false },
    { id: 3, name: 'headphones', selected: false },
  ];
  selectedRoleName: any;
  selectedRoleId: any;

  constructor(public rolePermissionservice: RolesPermissionsService,
    public formBuilder: FormBuilder, public profileService : ProfileService,
    private route: ActivatedRoute,
    private titleCasePipe: TitleCasePipe,public activeModal: NgbActiveModal, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddRoleBasedUsersComponent>,
    private router: Router,
    private toastService: ToastNotificationService,
  ) { }

  ngOnInit(): void {
    this.rolePermissionservice.userAdded$.subscribe(() => {
      this.getRole();
    });
    this.getRole();
    this.today=new Date();
    //call roles list
    // form for role based users
    this.usersForm = this.formBuilder.group({
      firstName: ["", [Validators.required, this.alphabeticOnlyValidator()]],
      lastName: [""],
      email: ["", [Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")
     ,Validators.email]],
      address: [null,],
      roleAssign: ["", [Validators.required]],
      designation: ["", [Validators.required, this.alphabeticOnlyValidator()]],
      parentId: [this.currentUser.id],
      joiningDate :["", Validators.required],
      resignationDate :[null],
      salary :["", Validators.required],
      department :[null,[ this.alphabeticOnlyValidator()]],
      asset: [''],
      gender:['',[Validators.required, this.alphabeticOnlyValidator()]],
      mobile: ['', [ Validators.pattern("^[0-9]{10}$")]],
        homeno: [null],
        pincode: [null],
        city: [null],
        district: [null],
        landmark: [null],
        // state: [null],
        country: ['United Arab Emirates (UAE) (Former Trucial Oman, Trucial States)'],
    });
  }

  alphabeticOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const alphabeticOnlyRegex = /^[a-zA-Z\s]+$/;
      const value = control.value;
      if (value && !alphabeticOnlyRegex.test(value)) {
        return { alphabeticOnly: true };
      }
      return null;
    };
  }

  getRole() {
    this.rolePermissionservice.getRoles({},this.currentUser.id).then(data => {
      if(data.success){
        this.roleData = data.data;
        this.roleId=data.data.id;
      }
    },(err) => {
      if(err.error.expose){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else{
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }

  get f() {
    return this.usersForm.controls;
  }
  getRolesData(e,item){
    if (e.isUserInput) {
    this.selectedRoleId = item.id;
   // this.selectedRoleName = item.roleName;
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  //add role based user
  async addUsersWithRoles() {
    this.submitted = true;
    if(this.usersForm.invalid){
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }else {
      let usersData = {
        basicdetails: {
          firstName: this.usersForm.value.firstName,
          lastName: null,
          email: this.usersForm.value.email,
          mobile: this.usersForm.value.mobile,
          parentId: this.currentUser.id,
          profileImage: this.profilePicUrl ? this.profilePicUrl : null,
        },
        additional:{
          roleAssign:  this.usersForm.value.roleAssign,
          designation: this.usersForm.value.designation,
          joiningDate:this.usersForm.value.joiningDate,
          resignationDate: this.usersForm.value.resignationDate,
          salary: this.usersForm.value.salary,
          assets: this.usersForm.value.asset ? this.usersForm.value.asset.toString():null,
          department:this.usersForm.value.department,
          gender:this.usersForm.value.gender
        },
        address: {
          isPrimary: true,
          homeno: this.usersForm.value?.homeno,
          pincode: this.usersForm.value?.pincode,
          address: this.usersForm.value?.address,
          city: this.usersForm.value?.city,
          district: this.usersForm.value?.district,
          landmark: this.usersForm.value?.landmark,
          state: this.usersForm.value?.state,
          country: this.usersForm.value?.country
        }
      }
      let roleBody = {
        "roles": [
            {
                "roleId": this.selectedRoleId
            }
        ]
      }
      try{
        const addEmployeeResponse = await this.rolePermissionservice.addEmployeeUser(usersData)
        // .then((res) => {
        //   if(res.success){
        //     console.log("res: ", res)
        //     employeeId = res.data.employee.id 
        //   }
        // })
        if (addEmployeeResponse.success) {
          const employeeId = addEmployeeResponse.data.id;
          this.rolePermissionservice.assignUserToRoles(employeeId, roleBody).then((res)=> {
            if(res.success){
              this.showAlert = true;
              this.toastService.toastMsg({
                title: "Success",
                content: "Employee Added Successfully!"
              });
              this.dialogRef.close();
              this.rolePermissionservice.notifyUserAdded();
            }
          },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
            }
          })
        }
      } catch(err) {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
      }
    }
  }

  getPincodeDetails(pin) {
    this.rolePermissionservice.getEmployeePincode({}, pin).then((res) => {
      this.pinDetails = res;
      this.usersForm.patchValue({
        state: res.data.stateName,
        city: res.data.districtName
      });
    });
  }

  cancel() {
    // Reset the form and submitted flag
    this.usersForm.reset();
    this.submitted = false;
    this.dialogRef.close();

  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  async handleInputChange(e) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file.type.match(pattern)) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Invalid Format. Please Select An Image File.",
      });
      return;
    }
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profilePic = reader.result;
      };
      if (file == null) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Select Image To Upload.",
        });
      } else {
        formdata.set("upload", file);
        e.srcElement.value = "";
        this.rolePermissionservice.uploadImage(formdata).then((res) => {
          if(res.success){
            this.profilePicUrl = res.data.location

            this.toastService.toastMsg({
              title: "Success",
              content: "Image Uploaded Successfully!",
            });
          }
        }, (err) => {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        });
        this.loaded = true;
      }
    }
  }

  deleteProfilePic() {
    // Clear the profilePic value
    this.profilePic = '';
    let formdata = new FormData();
    formdata.set("upload", null); // Sending an empty file or null to indicate deletion
    this.profileService.deleteProfileImg(formdata, this.currentUser.id)
    .then((res) => {
      if(res.success){
        this.toastService.toastMsg({
          title: "Success",
          content: "Image Deleted successfully!",
        });
        this.profilePicUrl = null; // Clear the profilePic value
        this.loaded = true;
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  
  openDatepickerJoinDate(){
      // Check if the current value of taskdate is an "Invalid Date"
      const selectedDate = this.usersForm.get('joiningDate').value;
      if (selectedDate instanceof Date && !isNaN(selectedDate.getTime())) {
        // Set a default date or any valid date here
        this.usersForm.get('joiningDate').setValue(new Date());
      }
  }

  openDatepickeresignDate(){
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.usersForm.get('resignationDate').value;
    if (selectedDate instanceof Date && !isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here+
      this.usersForm.get('resignationDate').setValue(new Date());
    }
  }

  calculateMinDate() {
    const joiningDate = this.usersForm.get('joiningDate').value;
    // Assuming joiningDate is a valid Date object
    if (joiningDate) {
        const minDate = new Date(joiningDate);
        minDate.setDate(minDate.getDate() + 1); // Setting the minimum date as one day after joining date
        return minDate;
    }
    return null; // or you can set a default minDate if joiningDate is not set
  }

}
