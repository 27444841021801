<div class="container-fluid mb-3 px-0 mx-0">
  <div class="row pt-2 pb-3" id="headingPrintHide">
    <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:500;font-size:20px!important;padding-left: 15px;">
      GSTR-1
    </div>
    <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
      <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i></button>
      <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
      </button>
    </div>
  </div>
  <hr class="mt-1" />
  <div class="fs--1 bg-white">
    <div class="row mb-3">
        <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start;flex-basis:300px;" class="col-lg-3 col-sm-12 col-md-3" id="dateRangeIfShownPrintHide">
          <label for="Date Range" class="form-label">Date</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input  class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" type="text"
                [outsideClick]="true" ngModel
                bsDaterangepicker
                [maxDate]="today"
                [(ngModel)]="dateRange"
                (ngModelChange)="changeDate($event)"
                [bsConfig]="{ ranges: ranges,
                                  showWeekNumbers:false,
                                  showNextMonth: false,
                                  rangeInputFormat : 'DD MMM YYYY',
                                  containerClass: 'theme-dark-blue',
                                    displayOneMonthRange: true }">
            <span>
              <mat-icon class="iconcalender p-1">date_range</mat-icon>
            </span>
        </mat-form-field>
      </div>
    </div>
    <div class="form-check ms-1" id="showoptionHide">
      <input class="form-check-input" id="flexCheckIndeterminate" type="checkbox" value="" />
      <label class="form-check-label" for="flexCheckIndeterminate"><strong style="font-weight: 500; font-size: 14px;">Consider non-tax as exempted</strong></label>
    </div>
    <ul class="nav nav-tabs nav-justified mb-3" id="myTab" role="tablist">
      <li class="nav-item"><a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#tab-home" role="tab"
          aria-controls="tab-home" aria-selected="true"><span style = "font-size: 12px;font-family: 500;">Sale</span></a></li>
      <li class="nav-item"><a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#tab-profile" role="tab"
          aria-controls="tab-profile" aria-selected="false"><span style = "font-size: 12px;font-family: 500;">Sale Return</span></a></li>
    </ul>
    <div class="tab-content" id="myTabContent" id="print">
      <div class="tab-pane fade show active" id="tab-home" role="tabpanel" aria-labelledby="home-tab">
        <table class="table table-light table-bordered"  id="excel-table">
          <thead style="background-color: #F0F0F0;">
            <tr>
              <th scope="col">GSTIN/UIN</th>
              <th scope="col">Party Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sales of gstr1salesList">
                <td>{{sales.gstIn}}</td>
                <td>{{sales.partyName}}</td>
              </tr>
          </tbody>
          <tbody *ngIf="gstr1salesList == 0">
            <tr class="text-center">
              <td colspan="10">
                <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt="">
                <!-- <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Sales generated during the period.</h5> -->
                <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tab-pane fade" id="tab-profile" role="tabpanel" aria-labelledby="profile-tab">
        <div class="table-responsive scrollbar">
          <table class="table bg-white table-bordered table-stripped"  id="excel-table">
            <thead style="background-color: #F0F0F0;">
              <tr>
                <th class="text-nowrap" scope="col" style="width :30%" colspan="3">Invoice Details</th>
                <th class="text-nowrap" scope="col" style="width :10%" colspan="1">Tax Rate</th>
                <th class="text-nowrap" scope="col" style="width :10%" colspan="1">Cess Rate</th>
                <th class="text-nowrap" scope="col" style="width :10%" colspan="1">Taxable Value</th>
                <th class="text-nowrap" scope="col" style="width :30%" colspan="4">Amount</th>
                <th class="text-nowrap" scope="col" style="width :10%">Place Of Supply(Name Of State)</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th class="text-nowrap" scope="col" style="width :10%">Invoice No</th>
                <th class="text-nowrap" scope="col" style="width :30%">Date</th>
                <th class="text-nowrap" scope="col" style="width :10%">Value</th>
                <th class="text-nowrap" scope="col"></th>
                <th class="text-nowrap" scope="col"></th>
                <th class="text-nowrap" scope="col"></th>
                <th class="text-nowrap" scope="col">Integrated Tax</th>
                <th class="text-nowrap" scope="col">Central Tax</th>
                <th class="text-nowrap" scope="col">State/UT Tax</th>
                <th class="text-nowrap" scope="col">Cess</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let salesReturn of gstr1salesReturnList">
                  <td class="text-center">{{salesReturn.invoiceNo}}</td>
                  <td class="text-center">{{salesReturn.invoiceDate| date:"dd MMM YYYY"| uppercase}}</td>
                  <td style="text-align: right;">{{salesReturn.totalBillAmount}}</td>
                  <td style="text-align: right;">{{salesReturn.gst}}</td>
                  <td style="text-align: right;">{{salesReturn.cessRate}}</td>
                  <td style="text-align: right;">AED {{salesReturn.taxableValue | number:'1.2-2'}}</td>
                  <td style="text-align: right;">AED{{salesReturn.igst| number:'1.2-2'}}</td>
                  <td style="text-align: right;">AED{{salesReturn.cgst| number:'1.2-2'}}</td>
                  <td style="text-align: right;">AED{{salesReturn.sgst| number:'1.2-2'}}</td>
                  <td style="text-align: right;">AED{{salesReturn.sadditionalCess | number:'1.2-2'}}</td>
                  <td class="text-center">{{salesReturn.placeOfSupply}}</td>
              </tr>
            </tbody>
            <tbody *ngIf="gstr1salesReturnList == 0">
              <tr class="text-center">
                <td colspan="12">
                  <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt="">
                  <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Sales Return generated during the period.</h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

