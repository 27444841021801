import { I } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { Constants } from 'src/app/_helpers/constant';
import { StateConstants } from 'src/app/_helpers/state-constans';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import * as moment from 'moment';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-bidding',
  templateUrl: './bidding.component.html',
  styleUrls: ['./bidding.component.scss']
})
export class BiddingComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public isChkbox: boolean;
  public warnText: any;
  public biddingList: any = [];
  public biddingBiDArray: any;
  public BidData = JSON.parse(localStorage.getItem("BidData"));
  public BidFalse: boolean = false;
  public allStates = StateConstants.states;
  public chkBoxTxt = Constants.chkBoxContactText;
  public ProdId: any;
  public productName: any;
  public pinDetails: any;
  public prodPrice: any;
  public isLessPrice: boolean = false;
  public isHighPrice: boolean = false;
  public showTimeInterval: any;
  public interval: any;
  public difference: any;
  public p=1;
  public currentpage:any;
  public totalrow:any;
  public toatlPages:any;
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  public isData: boolean = true;
  imgsrc:any;
  public wishData=[]


  public wishlistForm = this.formBuilder.group({
    budgetMax: ["", [Validators.required]],
    budgetMin: ["", [Validators.required]],
    pincode: ["", [Validators.required]],
    location: ["", [Validators.required]],
    publish: [true]
  })
  public todaysDate = this.commonService.getTodaysDate();

  constructor(private biddingService: BiddingService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private modal: NgbModal,
    private notificationService: ToastNotificationService,
    private toastService: ToastNotificationService,
    public profileService: ProfileService,
    public titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit() {
    this.getWishlistsByUserId();
    // this.getExpiryTime(this.BidData);
    if (this.biddingList) {
      this.interval = setInterval(() => {
        this.getExpiryTime();
      }, 1000)
    }
  }


  getWishlistsByUserId() {
    this.biddingService.getWishlistsByUserId({}, this.currentUser.id,this.partyPageSize,(this.currentPageNo + 1)).then((res) => {
      this.biddingList=[];
      this.totalrow=res.data.totalrows;
      this.wishData= res.data.pageData
      console.log("wishData",this.wishData)

      res.data.pageData.forEach(element => {
        let createDate = new Date(element.createdAt).getTime();
        let lastDate = new Date(moment(createDate).add(7, 'days').format("YYYY-MM-DD HH:mm:ss")).getTime();
        let todaysDate = new Date().getTime();
        this.difference = lastDate - todaysDate;
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(this.difference / (1000 * 60 * 60 * 24));
        var hours = Math.floor((this.difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((this.difference % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((this.difference % (1000 * 60)) / 1000);
        this.showTimeInterval = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
        
       
        let data = {
          itemName: element.product ? element.product.itemName : "-",
          id: element.product ? element.product.id : "-",
          publish: element.publish ? element.publish : "-",
          description: element.product ? element.product.description : "-",
          budgetMin: element.budgetMin ? element.budgetMin :"-",
          budgetMax: element.budgetMax ? element.budgetMax : "-",
          pincode: element.pincode ? element.pincode :"-",
          location: element.location ? element.location : "-",
          approxCompletedDate: element.approxCompletedDate ? element.approxCompletedDate : "-",
          price: element.product ? element.product.salePrice : "-",
          showTimeInterval: this.showTimeInterval ? this.showTimeInterval : "",
          biddings: element.biddings ? element.biddings : "-",
          createdAt: element.createdAt ? element.createdAt : "",
          userId: element.userId,
          coverPic:element.product ? element.product.coverImage : "",

         
        }
        console.log("data",data)
      
        this.biddingList.push(data);
        
        // console.log("coverpic",this.biddingList.coverPic)
    //     console.log(this.difference);
    // console.log(todaysDate);
    //     // If the count down is over, write some text 
    //     if (this.difference < todaysDate) {
    //       console.log("bd");
    //       clearInterval(this.interval);
    //       this.showTimeInterval = "EXPIRED";
    //     }
      })
    },(err)=>{
      this.isData = false
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  getExpiryTime() {
    this.biddingList.forEach((element) => {
      let createDate = new Date(element.createdAt).getTime();
      let lastDate = new Date(moment(createDate).add(7, 'days').format("YYYY-MM-DD HH:mm:ss")).getTime();
      let todaysDate = new Date().getTime();
      this.difference = lastDate - todaysDate;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(this.difference / (1000 * 60 * 60 * 24));
      var hours = Math.floor((this.difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((this.difference % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((this.difference % (1000 * 60)) / 1000);
      this.showTimeInterval = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      element.showTimeInterval = this.showTimeInterval;
      if (this.difference < todaysDate) {
        clearInterval(this.interval);
        element.showTimeInterval = "EXPIRED";
      }
    });
  }

  /**
   * delete bid
   * @param wishId 
   */
  removeWishlist(wishId) {
    this.biddingService.removeWishlist({}, wishId).then((res) => {
      console.log(res);
      this.getWishlistsByUserId();
    })
  }
  BidTimerFalseFlow() {
    this.BidFalse = !this.BidFalse;
  }


  modalDismiss() {
    // this.bankForm.reset();
    this.modal.dismissAll();
    // this.isEdit = false;
  }
  get f() {
    return this.wishlistForm.controls;
  }
  postwishlistBid() {
    let data = {
      "userId": this.currentUser.id,
      "publish": this.wishlistForm.controls.publish.value,
      "productId": this.ProdId,
      "budgetMax": this.wishlistForm.controls.budgetMax.value,
      "location": this.wishlistForm.controls.location.value,
      "pincode": this.wishlistForm.controls.pincode.value,
      "approxCompletedDate": this.todaysDate,
      "budgetMin": this.wishlistForm.controls.budgetMin.value,
    }
    if (this.wishlistForm.valid) {
      this.biddingService.postwishlistBid(data).then((res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Bid Request Submitted Successfully!",
          })
          // this.notificationService.openSnackBar("Bid Request Submitted Successfully!!!");
          this.getWishlistsByUserId();
        }
      })
    }

  }
  GeTproductId(id: any, ItemName, item) {
    this.ProdId = id;
    this.productName = ItemName;
    console.log("ITEM ", item)
    this.wishlistForm.patchValue({
      'location': item.location,
      'pincode': item.pincode,
      'budgetMin': item.budgetMin,
      'budgetMax': item.budgetMax
    })
  }
  getPincodeDetails(e) {
    this.profileService.getUserPincode({}, e).then((res) => {
      this.pinDetails = res;
      this.wishlistForm.patchValue({
        'location': this.pinDetails.data.stateName,
      })

    })
  }
  getMinBudget() {
    if (this.wishlistForm.controls.budgetMin.value > this.prodPrice) {
      this.isLessPrice = true;
    } else {
      this.isLessPrice = false;
    }
  }
  getMaxBudget() {
    if (this.wishlistForm.controls.budgetMax.value > this.prodPrice) {
      this.isHighPrice = true;
    } else {
      this.isHighPrice = false;
    }
  }
  public handlePage(e: any) {
    console.log(e);
    // this.tableData = [];
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.biddingList.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getWishlistsByUserId() ;
    }
  }
}
