import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment-type',
  templateUrl: './appointment-type.component.html',
  styleUrls: ['./appointment-type.component.scss']
})
export class AppointmentTypeComponent implements OnInit {
  appointMentData=[
    {
      "id":1,
     "Name":"Routine Visit",
     "Duration":"Business/Owner",
     "LocationType":"At Saloon",
     "BookingSpan":"Indefinatiately into Future",
     "ClientFacing":"yes",
     "RSVPEnaled":"no"
    },
    {
      "id":2,
      "Name":"Virtual appt",
      "Duration":"Business/Owner",
      "LocationType":"Virtual/Telechat",
      "BookingSpan":"Indefinatiately into Future",
       "ClientFacing":"yes",
      "RSVPEnaled":"yes"
    },
    {
     "id":3,
     "Name":"Test  1*1 Appointment",
     "Duration":"Business/Owner",
      "LocationType":"At Saloon",
      "BookingSpan":"Indefinatiately into Future",
     "ClientFacing":"yes",
     "RSVPEnaled":"no"
    },
    {
     "id":4,
     "Name":"One To One",
     "Duration":"Business/Owner",
     "LocationType":"Virtual/Telechat",
     "BookingSpan":"Indefinatiately into Future",
     "ClientFacing":"yes",
     "RSVPEnaled":"yes"
    },

  ]


  constructor() { }

  ngOnInit(): void {
  }


}
