<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:5px;" class="modal-title mb-0">Estimation/Performa
        Invoice</h1>

    <div class="addProductButtons">
        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel my-1"
            (click)="onCancelModal()">Cancel</button>
        <button style="margin-right: 10px !important;" type="button"
        class="btn btn-save my-1" (click)="setSaveAsDraft()" [hidden]="draftId">
        Save as Draft
      </button>
        <button type="submit" class="btn btn-save my-1" (click)="PostEstimateDetail()">Save</button>
    </div>

</div>

<div class="modal-body">
    <form [formGroup]="estimateInvoiceForm">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                <!-- Select Type -->
                <label class="form-label" style="font-size: 14px !important">Select Type<span
                        class="error-asterisk">*</span></label>
                <mat-form-field style=" font-size: 14px" class="example-full-width" appearance="outline">
                    <mat-select disableOptionCentering required placeholder="Select Type" formControlName="receiptType"
                        [ngClass]="{
                          'is-invalid': (f.receiptType.invalid && f.receiptType.touched) && submitted }">
                        <mat-option value="ESTIMATION">Estimation Invoice</mat-option>
                        <mat-option value="PERFOMA">Performa Invoice</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.receiptType.touched) && f.receiptType.errors?.required">
                    Select Type Is Required.
                </mat-error>
                <label class="form-label" style="font-size: 14px !important">Reference #<span
                        class="error-asterisk">*</span></label>
                <!-- Reference Number -->
                <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
                    <input placeholder="Reference #" ngxOnlyNumbers matInput formControlName="referenceNumber"
                        [ngClass]="{
                      'is-invalid': (f.referenceNumber.invalid && f.referenceNumber.touched) && submitted }">
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.referenceNumber.touched) && f.referenceNumber.errors?.required">
                    Reference # Is Required.
                </mat-error>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                <!-- Party Name -->
                <label class="form-label" style="font-size: 14px !important">Customer Name<span
                        class="error-asterisk">*</span></label>
                <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Customer Name" aria-label="Select Party Name" matInput
                        (keyup)="searchPartyByName($event.target.value)" [formControl]="myControl"
                        [matAutocomplete]="auto" [ngClass]="{
                          'is-invalid': (myControl.invalid && myControl.touched) && submitted }" required>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                            (onSelectionChange)="setSelectedPartyDeatails(option)">
                            {{option.partyName}}
                        </mat-option>
                    </mat-autocomplete>

                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || myControl.touched) && myControl.errors?.required">
                  Customer Name Is Required.
                </mat-error>


                <!-- Date -->
                <label class="form-label" style="font-size: 14px !important">Date <span
                  class="error-asterisk">*</span></label>
                <mat-form-field style=" font-size: 14px" class="example-full-width" appearance="outline">
                    <input matInput required ngModel bsDatepicker placeholder="Date." [maxDate]="today" [(ngModel)]="todaysDate" id="datePicker"
                        bsDatepicker  formControlName="invoiceDate"  [bsConfig]="{
              containerClass: 'theme-dark-blue',
              dateInputFormat: 'DD MMM YYYY',
              showWeekNumbers:false
              }" (click)="openDatepicker()"
              [ngClass]="{
                'is-invalid': (f.invoiceDate.invalid && f.invoiceDate.touched) && submitted }" >
                    <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submitted || f.invoiceDate.touched) && f.invoiceDate.errors?.required">
                Date Is Required.
              </mat-error>
                <!-- <mat-form-field class="example-full-width" appearance="outline">
                <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
                  style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                                containerClass: 'theme-dark-blue',
                                dateInputFormat: 'DD MMM YYYY',
                                 showWeekNumbers:false
                                }" formControlName="invoiceDate" autocomplete="off" (click)="openDatepicker()">
                <span>
                  <mat-icon class="iconcalender p-1">date_range</mat-icon>
                </span>
              </mat-form-field> -->
            </div>

            <!-- Billing Address -->
            <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label class="form-label" style="font-size: 14px !important">Billing Address<span
                        class="error-asterisk">*</span></label>
                <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
                    <textarea matInput rows="6" placeholder="Billing Address" formControlName="billingAddress" maxlength="150" required
                     [ngClass]="{
                      'is-invalid': (f.billingAddress.invalid && f.billingAddress.touched) && submitted }"></textarea>
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                    Billing Address Is Required.
                </mat-error>

            </div>

        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" [ngSwitch]="currentUser.userType" *appHasPermission="['delete', 'modify','create', 'read']; name 'warehouse'">
            <div *ngSwitchCase="'CLIENT'" style="font-size: 14px" >
                <label class="form-label">Warehouse</label>
                <mat-form-field class="example-full-width" appearance="outline">
                <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                    <mat-option value="clear">Clear Selection</mat-option>
                    <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                    {{warehouse.firstName}}
                    </mat-option>
                </mat-select>
                </mat-form-field>
            </div>
            <div *ngSwitchCase="'SHOP'" style="font-size: 14px">
                <label class="form-label">Warehouse</label>
                <mat-form-field class="example-full-width" appearance="outline">
                <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                    <mat-option value="clear">Clear Selection</mat-option>
                    <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                    {{warehouse.firstName}}
                    </mat-option>
                </mat-select>
                </mat-form-field>
            </div>
          </div>
        </div>

        <div class="table-responsive my-custom-scrollbar">
            <table class="table table-bordered ">
              <thead class="table-secondary">
                <tr class="font_size font-weight-bold">
                  <th scope="col" style="width:4%;vertical-align: middle;">
                    #
                  </th>
                  <th scope="col" style="width: 10%;vertical-align: middle;">
                    SKU<span class="text-danger"> *</span>
                  </th>
                  <th style = "vertical-align: middle;" class="text-nowrap" scope="col">
                    SKU Description
                  </th>
                  <th style = "vertical-align: middle;">Unit</th>

                  <th style = "vertical-align: middle;">Quantity</th>
                  <th style = "vertical-align: middle;" scope="col" class="text-nowrap" colspan="2">
                    {{ lang.Base_Price_In_Rs }}<span class="text-danger"> *</span>
                  </th>

                  <th style = "vertical-align: middle;" class="text-center" scope="col" colspan="2">
                    {{lang.GST}}<span class="text-danger"> *</span>
                  </th>

                  <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
                    {{lang.Total_In_Rs}}<span class="text-danger"> *</span>
                  </th>
                  <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
                    Action<span class="text-danger"> *</span>
                  </th>
                </tr>
              </thead>

              <tbody class="mod">
                <tr>
                  <td></td>
                  <td>
                    <!-- <input type="text" matInput placeholder="SKU" (change)="getGoodsByBarcode()"
                      [(ngModel)]="barcode" [ngModelOptions]="{standalone: true}" required /> -->
                      <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" id="inp12" placeholder="SKU" (input)="onSearchInputChange($event.target.value)"
                          matInput [formControl]="myControl1" [matAutocomplete]="productAuto">
                        <mat-icon class="m-2" matSuffix>search</mat-icon>
                        <mat-autocomplete #productAuto="matAutocomplete">
                          <mat-option *ngFor="let option of productOptions | async" [value]="option.itemName"
                            (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                            <span style="font-size: 12px;">
                            {{ option.itemName | titlecase }}/<b>{{ option.barcode }}</b>
                            </span>
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                  </td>
                  <td class="text-nowrap" scope="col">
                    <input type="text" matInput placeholder="SKU Description" required />
                  </td>
                  <td colspan="1">
                    <input type="text" matInput placeholder="Unit"  required />
                  </td>
                  <td colspan="1">
                    <input type="text" matInput placeholder="Quantity" style="width: 5rem !important;" required />
                  </td>

                  <td colspan="1">
                    <input type="text" matInput placeholder="Price / Unit" />
                  </td>
                  <td colspan="1">
                    <input type="text" matInput placeholder="Price" />
                  </td>
                  <td colspan="1">
                    <input type="text" matInput required placeholder="%" />
                  </td>
                  <td colspan="1">
                    <input type="text" matInput required placeholder="In AED"  style="width: 40px;" />
                  </td>

                  <td>
                    <input type="text" style="width: 5rem;" matInput placeholder="Amount" style="max-width: 150px;" required />
                  </td>
                  <td style="width: 3rem;"></td>
                </tr>
                <tr *ngFor="let item of productData;let i=index">
                  <td style="vertical-align: middle;">{{ i + 1 }}</td>

                  <td>
                    {{ item.barcode }}
                  </td>
                  <td>{{ item.itemName | titlecase}}</td>
                  <td class="text-center">
                    <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                      readonly />
                  </td>

                  <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
                    <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                      (click)="changeQuantity('-',i)">-</a>
                    <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                      (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;text-align: center;" required />
                    <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                      (click)="changeQuantity('+',i)">+</a>
                  </td>
                  <td colspan="1">
                    <p class="">
                      AED&nbsp;{{ item.basePrice | number:'1.2-2'}}

                    </p>
                  </td>
                  <td colspan="1">
                    <p class="">
                      AED&nbsp;{{ item.subTotal | number:'1.2-2'}}
                    </p>
                  </td>
                  <td colspan="1">
                    <p class="" style="width: 3rem;">
                      {{ item.gstRate }}%
                    </p>
                  </td>
                  <td colspan="1" style="max-width: 150px;">
                    <p class="" >
                      AED&nbsp;{{ item.gstAmount | number:'1.2-2'}}
                    </p>
                  </td>

                  <td style="max-width: 150px;">
                    <p class="" >
                      AED&nbsp;{{ item.total | number:'1.2-2'}}
                    </p>
                  </td>
                  <td>
                    <a class="text-center btnb" style="width: 3rem!important;" (click)="changeQuantity('rm',i)">
                      <span class="bi-trash icon-color fs-1" style="color: red;" data-bs-toggle="tooltip" data-bs-placement="top"
                        title="Delete"></span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-responsive mt-2  my-custom-scrollbar d-flex justify-content-end">
            <div class="col-sm-6"></div>
            <div class="col-sm-4 table-bordered my-custom-scrollbar">
              <table class="table table-bordered  my-custom-scrollbar">
                <tr>
                  <td class="thWidth p-2" style="text-align:left;">Subtotal</td>
                  <td class="p-2" style="width: 50px;" style="text-align:right;">
                    AED&nbsp;{{ subTotal | number:'1.2-2'}}
                  </td>
                </tr>
                <tr>
                  <td class="thWidth  p-2" *ngIf="gstStateMatch" style="text-align:left;">{{lang.CGST}}</td>
                  <td class="p-2" *ngIf="gstStateMatch" style="width: 50px;" style="text-align:right;">
                    AED&nbsp;{{ gstRate | number:'1.2-2'}}
                  </td>
                </tr>
                <tr>
                  <td class="thWidth  p-2" style="text-align:left;">Total</td>
                  <td class="p-2" style="width: 50px;" style="text-align: right;">
                    AED&nbsp;{{ total | number:'1.2-2'}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
    </form>
</div>
