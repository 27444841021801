import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { rejects } from 'assert';
import { Observable, Subject } from 'rxjs';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class BankServiceService {

  private partyAddedSource = new Subject<void>();
  partyAdded$ = this.partyAddedSource.asObservable();

  constructor(public httpClient: HttpService, private http: HttpClient) { }
  notifyPartyAdded() {
    this.partyAddedSource.next();
  }

  getBankDetails(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getBankDeatilsApi + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getBankDetailsByAcc(data: {}, accNo): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getbankDataByACC + accNo, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getBankDetailsById(data: {}, userId, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getBankDeatilsApi + userId + "?id=" + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // getUserBankdataById(data: {}, userId, id): any {
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.call(data, UrlConstants.viewbank + userId + "?id=" + id, 'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }

  postBankDetails(data: {}, id) {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.postBankDetails + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    })
  }

  deleteBankAccNo(data: {}, accNo): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteBankByACC + accNo, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getuserBankById(data: {}, id):any{
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getuserBankbyId + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updateBankDetails(data: {}, accNo): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.updateBank + accNo, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getBankDetailsByIFSC(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getBankDetails + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

}

