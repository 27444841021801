import {
  ApplicationRef,
  ComponentFactoryResolver,
  Injectable,
  Injector,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { Subject } from 'rxjs';

export enum toastTypes {
  error,
  success,
  warn,
  info
}

export interface ToastData {
  title: string;
  content: string;
  show?: boolean;
  timer?: any; // Define the timer property as optional with the correct type
  type?: toastTypes;
  progressWidth?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationService {
  data: ToastData;
  public open = new Subject<ToastData>();
  showToast: any;

  constructor(private snackBar: MatSnackBar) {}

  toastMsg(data: ToastData) {
    if (data.type) {
      this.data.type = toastTypes.error;
    }
    this.data = { ...data, show: true, progressWidth: '100%' };
    this.open.next(this.data);
  }

  hide() {
    this.data = { ...this.data, show: false };
    this.open.next(this.data);
  }

  public openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
      panelClass: ['green-snackbar'],
    });
  }

  public openErrorSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
      panelClass: ['red-snackbar'],
    });
  }
}

// toastSuccess(data: ToastData) {
//   if(data.type) {
//     this.data.type = toastTypes.success
//   }
//   this.data = {...data, show: true, progressWidth: '100%'};
//   this.toast(this.data);

// console.log('in success toast', this.data);
// this.toastQueue.push(this.data);
// console.log('in success toast', this.toastQueue)
// this.open.next(this.data)
// }

// toastError(data: ToastData) {
//   console.log(data);
//   if(data.type) {
//     this.data.type = toastTypes.error
//   }
//   this.data = {...data, show: true, progressWidth: '100%'};
//      this.toast(this.data);

// console.log('in error toast', this.data);
// if (this.toastQueue.length > 0) {
//   this.open.next(this.data)

// }
// this.toastQueue.push(this.data);
// console.log('in error toast', this.toastQueue)
// }

// hide() {
//   this.data = {...this.data, show: false};
//   // if (this.toastQueue.length > 0) {
//   //   this.showNextToast();
//   // } else {
//     this.open.next(this.data);
//   // }
// }

// toastSuccess(data: ToastData) {
//  this.data = {...data, show: true, progressWidth: '100%'};
//  this.toast(data);
// }

// toastError(data: ToastData) {
//   this.data = {...data, show: true, progressWidth: '100%'};
//   this.toast(data);
// }

// toast(data) {
//   this.toastQueue.push(data);
//   console.log("toast push in array", this.toastQueue)
//   console.log("toast data", data)
//   this.showNextToast();
// }

// showNextToast() {
//   console.log('showNextToast', this.data)
//   if (this.toastQueue.length > 0) {
//     console.log(this.toastQueue)
//     const nextToast = this.toastQueue.shift();
//     console.log("nextToast", nextToast)
//     nextToast.show = true;
//     this.open.next(nextToast);

// Automatically hide the toast after a certain duration
// setTimeout(() => {
//   this.hide();
// }, 5000); // Adjust the duration as needed
//   }
// }

// hide() {
//   if (this.toastQueue.length > 0) {
//     this.data = {...this.data, show: true, progressWidth: '100%'};
//     this.showNextToast();
//   }
// }
