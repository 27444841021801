import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpHeaders, HttpClient  } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private newAddedSource = new Subject<void>();
  rowAdded$ = this.newAddedSource.asObservable();
  // private isArabicSubject = new BehaviorSubject<boolean>(
  //   localStorage.getItem('isArabic') === 'true' || false
  // );

  constructor(public httpClient: HttpService, private httpclient: HttpClient) { }

  notifyDataAdded() {
    this.newAddedSource.next();
  }

  // Expose an observable to subscribe to
  // isArabic$: Observable<boolean> = this.isArabicSubject.asObservable();

  // // Function to set the language value based on the countryId
  // setIsArabicByCountryId(countryId: number): void {
  //   // Assuming that countryId 228 corresponds to Arabic otherwise assume that countryId 102 corresponds to India
  //   const isArabic = countryId === 228;
  //   this.isArabicSubject.next(isArabic);
    
  //   // Store the isArabic value in local storage
  //   localStorage.setItem('isArabic', isArabic.toString());
  // }

  getTodaysDate() {
    let currentDate = new Date();
    let month = currentDate.getMonth() + 1;
    let date = currentDate.getDate();
    let datestr = currentDate.getFullYear() + "-" +
      (month < 10 ? "0" + month : month) + "-" + (date < 10 ? "0" + date : date);
    return datestr;
  }

  private subject = new Subject<any>();

  sendData(count: any) {
    this.subject.next({ count });
  }

  clearData() {
    this.subject.next();
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }

  getWorkList(data:{},userId,size,page){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.auditWorklog + userId + "?size=" + size + "&page=" + page ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllInvoicesToDownload(data:{}, userId, receiptsType){
    return this.httpclient.request("GET", environment.inventoryUrl + UrlConstants.getAllInvoicesToDownload + userId + "?size=" + 5000 + "&page=" + 1 + "&receiptsType=" + (receiptsType || '') , {
      body: data,
      responseType: "blob",
    })
  }

  sundryDebtorDownload(data:{}, userId, receiptsType){
    return this.httpclient.request("GET", environment.inventoryUrl + UrlConstants.sundryDebtorDownload + userId + "?size=" + 5000 + "&page=" + 1 + "&receiptsType=" + (receiptsType || '') , {
      body: data,
      responseType: "blob",
    })
  }

  sundryCreditorDownload(data:{}, userId, receiptsType){
    return this.httpclient.request("GET", environment.inventoryUrl + UrlConstants.sundryCreditorDownload + userId + "?size=" + 5000 + "&page=" + 1 + "&receiptsType=" + (receiptsType || '') , {
      body: data,
      responseType: "blob",
    })
  }

  getAllPaymentOutDataDownload(data:{}, userId){
    return this.httpclient.request("GET", environment.inventoryUrl + UrlConstants.getAllPaymentOutDataDownload + userId + "?size=" + 5000 + "&page=" + 1, {
      body: data,
      responseType: "blob",
    })
  }

  getAllPaymentInDataDownload(data:{}, userId){
    return this.httpclient.request("GET", environment.inventoryUrl + UrlConstants.getAllPaymentInDataDownload + userId + "?size=" + 5000 + "&page=" + 1, {
      body: data,
      responseType: "blob",
    })
  }

  getSaleReturnDownload(data:{}, userId, receiptsType){
    return this.httpclient.request("GET", environment.inventoryUrl + UrlConstants.getSaleReturnDownload + userId + "?size=" + 5000 + "&page=" + 1 + "&search=" + (receiptsType || '') , {
      body: data,
      responseType: "blob",
    })
  }

  getEstimationDownload(data:{}, userId, receiptsType){
    return this.httpclient.request("GET", environment.inventoryUrl + UrlConstants.getAllInvoicesToDownload + userId + "?size=" + 5000 + "&page=" + 1 + "&receiptsTypeNew=" + (receiptsType || '') , {
      body: data,
      responseType: "blob",
    })
    // return new Promise((resolve, reject) => {
    //   this.httpClient.inventory(data, UrlConstants.getAllInvoicesToDownload + userId + "?size=" + 5000 + "&page=" + 1 + "&receiptsType=" + (receiptsType || '') ,  'GET').subscribe(
    //     (res) => resolve(res),
    //     (err) => reject(err)
    //   );
    // });
  }

  bulkImportSale(data:{}, userId, receiptsType){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, 'invoice/bulkImportSale' + "?userId=" + userId + "&receiptType=" + (receiptsType || '') ,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  startSession(data:{}){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.startSession, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  endSession(data:{}, sessionId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.endSession + sessionId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  downloadInvoiceSample(data): any {
    return this.httpclient.request("POST", environment.inventoryUrl + UrlConstants.downloadInvoiceSample, {
      body: data,
      responseType: "blob",
    }) 
  }
}
