<!-- <form> -->
<form [formGroup]="NumberForm">
    <div class="card mb-3">
        <div class="card-header bg-light pt-3 pb-3">
            <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
                Invoice Number
            </p>
        </div>
        <div class="progress" style="height: 2px">
            <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>
        <div class="card-body fs--1">
            <!-- <h5>Shop</h5> -->
            <div class="row d-flex justify-content-start">
                <!-- Prefix 1 -->
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width"
                        appearance="outline">
                        <mat-label>Prefix 1</mat-label>
                        <input matNativeControl autocomplete="off" formControlName="prefixOne" [(ngModel)]="prefixOne"
                            required matInput>
                    </mat-form-field>
                </div>

                <!-- Seprator 1 -->
                <div class=" col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width"
                        appearance="outline">
                        <mat-label>Seprator 1</mat-label>
                        <select matNativeControl class="mod" formControlName="seprator1" [(ngModel)]="seprator1"
                            required>
                            <option>.</option>
                            <option>/</option>
                            <option>-</option>
                            <option></option>
                        </select>
                    </mat-form-field>
                </div>

                <!-- Prefix 2 -->
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 mb-3">
                    <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width"
                        appearance="outline">
                        <mat-label>Prefix 2</mat-label>
                        <input matNativeControl autocomplete="off" formControlName="prefixTwo" [(ngModel)]="prefixTwo"
                            required matInput>
                    </mat-form-field>
                </div>

                <!-- Seprator 2 -->
                <div class=" col-xs-12 col-sm-12 col-md-3 col-lg-3 mb-3">
                    <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width"
                        appearance="outline">
                        <mat-label>Seprator 2</mat-label>
                        <select matNativeControl class="mod" formControlName="seprator2" [(ngModel)]="seprator2"
                            required>
                            <option>.</option>
                            <option>/</option>
                            <option>-</option>
                            <option></option>
                        </select>
                    </mat-form-field>
                </div>
                <hr>

                <h5 style = "font-weight: bold;">Select Type of Calander</h5>
                <!-- Calendar Type -->
                <div class=" col-xs-12 col-sm-12 col-md-3 col-lg-3 mb-3">
                    <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width"
                        appearance="outline">
                        <mat-label>Calendar Type</mat-label>
                        <select matNativeControl class="mod" required formControlName="calendarType"
                            [(ngModel)]="calendarTypeTxt" (change)="calendarType($event.target.value)">
                            <option value="FINANCIAL">Financial</option>
                            <option value="CALENDAR">Calander</option>
                        </select>
                    </mat-form-field>
                </div>

                <div style="font-size: 11px; margin-top: -2px;" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 mb-3 ">
                    <!-- Year -->
                    <div class="d-flex justify-content-start">
                        <p>Add Year: </p>&nbsp;&nbsp;
                        <div class="form-check form-switch">
                            <input class="form-check-input" formControlName="year" [(ngModel)]="year"
                                id="inlineCheckbox1" type="checkbox" (click)=toggleDisplay() />
                            <label style="margin-top: -18px;" class="form-check-label "
                                for="inlineCheckbox1">Yes</label>
                        </div>
                    </div>
                    <!-- Month -->
                    <div style="font-size: 11px; margin-top: -10px;" class="d-flex justify-content-start">
                        <p>Add Month: </p>&nbsp;&nbsp;
                        <div class="form-check form-switch">
                            <input class="form-check-input" formControlName="month" [(ngModel)]="month"
                                id="inlineCheckbox1" type="checkbox" (click)=toggleDisplaym() />
                            <label style="margin-top: -18px;" class="form-check-label "
                                for="inlineCheckbox1">Yes</label>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <!-- Calander -->

                    <!-- Enter Year -->
                    <div *ngIf="isCalendar" [hidden]="isShown" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 mb-3">
                        <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width"
                            appearance="outline">
                            <mat-label>Enter Year (YYYY)</mat-label>
                            <select matNativeControl class="mod" formControlName="yearformat" [(ngModel)]="yearformat"
                                (change)="getYearFormat($event.target.value)" [(ngModel)]="calenderYearformat" required>
                                <option value="YYYY">YYYY </option>
                            </select>
                        </mat-form-field>
                    </div>

                    <!-- Enter Month -->
                    <div *ngIf="isCalendar" [hidden]="isShownm" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 mb-3">
                        <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width"
                            appearance="outline">
                            <mat-label>Enter Month (MM)</mat-label>
                            <select matNativeControl class="mod" formControlName="monthformat" [(ngModel)]="monthformat"
                                (change)="getMonthFormat($event.target.value)" [(ngModel)]="calenderMonthformat"
                                required>
                                <option value="MM">MM</option>
                            </select>
                        </mat-form-field>
                    </div>

                    <!--  -->

                    <!-- Enter Year -->
                    <div *ngIf="!isCalendar" [hidden]="isShown" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 mb-3">
                        <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width"
                            appearance="outline">
                            <mat-label>Enter Year (YYYY-YYYY)</mat-label>
                            <select matNativeControl class="mod" formControlName="yearformat" [(ngModel)]="yearformat"
                                (change)="getYearFormat($event.target.value)" [(ngModel)]="calenderYearformat" required>
                                <option value="YY-YY">YY-YY </option>
                                <option value="YYYY-YYYY">YYYY-YYYY</option>
                            </select>
                        </mat-form-field>
                    </div>

                    <!-- Enter Month -->
                    <div *ngIf="!isCalendar" [hidden]="isShownm" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 mb-3">
                        <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width "
                            appearance="outline">
                            <mat-label>Enter Month (MMM)</mat-label>
                            <select matNativeControl class="mod" formControlName="monthformat" [(ngModel)]="monthformat"
                                (change)="getMonthFormat($event.target.value)" [(ngModel)]="calenderMonthformat" required>
                                <option value="MMM">MMM </option>
                            </select>
                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <h5 style = "font-weight: bold;">Serial Number</h5>
                <!-- Number Of Zeros -->
                <div class=" col-xs-12 col-sm-12 col-md-3 col-lg-3 mb-3">
                    <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width "
                        appearance="outline">
                        <mat-label>Number Of Zeros</mat-label>
                        <select matNativeControl class="mod" formControlName="numberOfZeros" [(ngModel)]="numberOfZeros"
                            (change)="getNoOfZeros($event.target.value)" required>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                        </select>
                    </mat-form-field>
                </div>
            </div>
            <hr>
            <div class="row col-md-6">
                <h4>
                    <b>
                        Preview - {{ prefixOne}}
                        {{ seprator1 }}
                        {{ prefixTwo }}
                        {{ seprator2 }}
                        {{ yearformat }}
                        {{ monthformat }}
                        {{ numberOfZeros }}
                        {{ lastDigit }}
                    </b>
                </h4>
            </div>
        </div>
        <div class="card-footer">
            <div *ngIf="!isShowButton" class="col-12 d-flex p-3 justify-content-end">
                <button type="submit" class="btn btn-outline-primary" style="margin-left: 10px;"
                    (click)="postInvoiceNumber()">Save</button>
            </div>
            <div *ngIf="isShowButton" class="col-12 d-flex p-3 justify-content-end">
                <button type="submit" class="btn btn-outline-primary" style="margin-left: 10px;"
                    (click)="updateInvoiceNumber()">Update</button>
            </div>
        </div>
    </div>
</form>