<div class="card  mb-3">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Book an Appointment
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-6 mt-4 text-center">
                <mat-form-field style="width: 80%;" class="example-full-width" appearance="outline">
                    <mat-label>Date For Appointment</mat-label>
                    <input matInput [outsideClick]="true" placement="bottom" 
                    style="text-transform:uppercase!important;"  autocomplete="off" [(ngModel)]="model" [minDate]="today" bsDatepicker
                        [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', showWeekNumbers:false, containerClass: 'theme-dark-blue', displayOneMonthRange: true }" (click)="openDatepicker()">
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                <button type="submit" class="btn btn-outline-primary m-3" (click)="getDaySlot(shopId)">Get Day Slot</button>
            </div>

            <div class="col-sm-6">
              <div style="display: flex;justify-content: space-between;align-items: center;">
                <div class="row text-center">
                  <strong class="text-black" style="font-weight: 800; font-size: 18px;">Which Time ?</strong>
                </div>
                <div class="justify-content-end m-3">
                  <a class="btn btn-save" style="float:right!important;" (click)="goTOBack()">Back</a>
                </div>
              </div>
              <h3>Available Time Slot</h3>
              <div class="row">
                  <div *ngIf="dayTimeSlot == 0" class="col-sm-4">
                      <p>No Day Slot Available</p>
                  </div>
                  <div class="col-sm-4" *ngFor="let time of dayTimeSlot">
                      <button style="width: 100%; font-size: 11px; "
                          class="border-black btn btnhover  btn-falcon-default me-3 mb-1" type="button"
                          (click)="getTimeDetails(time.startSlot,time.endSlot)">{{time.startSlot.substr(0,5)}}-{{time.endSlot.substr(0,5)}}
                      </button>
                  </div>
              </div>
            </div>
        </div>
    </div>
    <div class="card-body p-1 m-3" *ngIf="custClick">

        <form [formGroup]="customerForm">

            <div class="row">
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Customer Name</mat-label>
                        <input type="text" matInput formControlName="customerName" placeholder="customer Name"
                        [ngClass]="{
                            'is-invalid': (f.customerName.invalid && f.customerName.touched) && submitted }"  required />
                    </mat-form-field>
                    <mat-error class="text-danger"
                    *ngIf="(submitted || f.customerName.touched) && f.customerName.errors?.required">
                    Customer Name Is Required.
                </mat-error>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Contact #</mat-label>
                        <input matInput formControlName="phone"  minlength="10" maxLength="10" placeholder="Contact #"
                        ngxOnlyNumbers [ngClass]="{
                            'is-invalid': (f.phone.invalid && f.phone.touched) && submitted }" required/>
                    </mat-form-field>
                    <mat-error class="text-danger"
                    *ngIf="(submitted || f.phone.touched) && f.phone.errors?.required">
                    Contact # Is Required.
                </mat-error>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input type="text" matInput formControlName="email" placeholder="Email"
                        [ngClass]="{'is-invalid': (f.email.invalid && f.email.touched) && submitted }" required/>
                    </mat-form-field>
                    <mat-error class="text-danger"
                    *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                    Email Is Required.
                </mat-error>
               

                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Address</mat-label>
                        <input type="text" matInput formControlName="address" placeholder="address"
                        [ngClass]="{
                            'is-invalid': (f.address.invalid && f.address.touched) && submitted }"  required/>
                    </mat-form-field>
                    <mat-error class="text-danger"
                    *ngIf="(submitted || f.address.touched) && f.address.errors?.required">
                    Address Is Required.
                </mat-error>
                </div>
            </div>
        </form>
        <button class="btn btn-outline-primary" type="submit" (click)="saveCustomer()"> Book an Appointment</button>

    </div>
    <div class="card-footer bg-light">
        <div class=" mt-3 d-flex justify-content-end">
            <div class="">
                <button class="btn btn-outline-primary px-5 px-sm-6 fs--1" type="submit" (click)="customerFormOpen()">
                    Next<span class=" ms-2 fs--1" data-fa-transform="shrink-3">
                    </span>
                </button>
            </div>
        </div>
    </div>

</div>
