import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { PartyReportsService } from 'src/app/core/services/party-reports.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { map, startWith } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { PartGroupServiceService } from 'src/app/core/services/part-group-service.service';


interface IRange {
  value: Date[];
  label: string;

}


@Component({
  selector: 'app-sale-purchase-by-party-group',
  templateUrl: './sale-purchase-by-party-group.component.html',
  styleUrls: ['./sale-purchase-by-party-group.component.scss']
})
export class SalePurchaseByPartyGroupComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  fileName = 'Sale Purchase By VendorGroup.xlsx';
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public toatlPages: any;
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public partyPagesize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  public quarterStartDate: any;
  public quarterEndDate: any;
  public today: Date;
  public quarter;
  public maxDate: Date;
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  public dateRange: Date[];
  public groupName: any;
  public saleAmount: any;
  public purchaseAmount: any
  public selectedPartyId: any;
  public selectedGroupName: any;
  public partyId: any;
  public partyList: any;
  public submitted: boolean = false;
  public partyName = new FormControl();
  public groupNamefc = new FormControl();
  public partyData: any;
  public allPartyList: any = [];
  public allGroupList: any = [];
  public partyGroupData: any = [];
  public filteredOptions: Observable<string[]>;
  public filteredOptionsGroups: Observable<string[]>;
  public myControl = new FormControl();
  public salepurTotal: any = [];
  public salegroupList: any = [];
  public selectedGroupId: any = []
  public slectedGroup: any;
  public eqSaleAmount: 0;
  public minSaleAmount: 0;
  public maxSaleAmount: 0;
  public totalQuantityInput: 0;
  public purchaseAmountFilterMin = 0;
  public purchaseAmountFilterMax = 0;
  public totalPurchaseInput = 0;
  public eqPurchaseAmount = 0;
  public valueItem: string = "Equal";
  GroupsNames: any;

  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  constructor(private partyreportsService: PartyReportsService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private saleInvoiceService: SaleInvoiceService,
    private partyGroupservice: PartGroupServiceService
  ) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    let quarter = moment(this.today).utc().quarter() //outputs 4
    this.getQuarterRange();

  }

  ngOnInit(): void {
    this.getPartiGroupsDetails()
    this.getSalePurchaseParty();
    this.ranges = [
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      {
        value: [this.quarterStartDate._d, this.quarterEndDate._d],
        label: ' Last Quarter'
      },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear(), 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];
    this.dateRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]
  }

  getQuarterRange() {
    this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
    this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  }

  getPartiGroupsDetails() {
    this.partyGroupservice.getPartiGroupsDetails({}, this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1)).then((res) => {
      this.p = res.data.currentPage;
      this.partyGroupData = res.data.pageData;
      this.totalrow = res.data.totalrows
      this.toatlPages = res.data.totalPages
      this.GroupsNames = this.partyGroupData.map((item) => {
        return item.group.groupName
      });
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
          });
      }
    })
  }

  getSalePurchaseParty() {
    this.partyreportsService.getSalePurchaseByGroup({}, this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1)).then((res) => {
      this.partyList = res.data.dataset;
      this.salegroupList = res.data.result;
      this.salepurTotal = res.data;
      this.p = res.data.currentPage;
      this.totalrow = res.data.totalRows;
      this.toatlPages = res.data.totalPages
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
          });
      }
    })
  }

  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  private filterNamesGroups(name: string): string[] {
    return this.partyGroupData.filter(partyList =>
      partyList.group.groupName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  getSalePurchRepo() {
    this.partyreportsService.getSalePurchaseGroupReport({}, this.currentUser.id, moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD'), moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD')).then((res) => {
      this.salegroupList = res.data.result;
      this.salepurTotal = res.data;
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
        }
      })
  }

  searchGroupByName(item) {
    this.partyGroupservice.getPartiGroupsDetailsByName({}, this.currentUser.id, item).then((res) => {
      this.partyGroupData = res.data;
      this.filteredOptions = this.partyName.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNamesGroups(name) : this.partyGroupData.slice())
        );
    }
    )
  }

  setSelectedGroupDeatails(item) {
    this.slectedGroup = item;
    this.partyreportsService.getSalePurchaseGroupReportsearch({}, this.currentUser.id, this.slectedGroup,this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.salegroupList = res.data.result;
      this.salepurTotal = res.data;
      this.p = res.data.currentPage;
      this.totalrow = res.data.totalRows;
      this.toatlPages = res.data.totalPages
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
        }
      })
  }

  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
  onPrint() {
    const elementsToHide = ['headingPrintHide', 'dateRangePrintHide', 'searchBarHide','headingPrintHide1'];
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = 'none';
      }
    });

    // Print
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
    // Restore hidden elements after printing
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = '';
      }
    });
  }

  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }

  onSelectSaleAmount(value) {
    this.valueItem = value;
  }

  ApplyQuantity() {
    if (this.valueItem == "Greater") {
      this.partyreportsService.getGroupReportSaleGreater({}, this.currentUser.id, this.totalQuantityInput).then((res) => {
        this.salegroupList = res.data.result;
        this.salepurTotal = res.data;
      })
    }
    else if (this.valueItem == "Equal") {
      this.partyreportsService.getGroupReportSaleEqual({}, this.currentUser.id, this.totalQuantityInput).then((res) => {
        this.salegroupList = res.data.result;
        this.salepurTotal = res.data;
      })
    }
    else {
      this.partyreportsService.getGroupReportSaleless({}, this.currentUser.id, this.totalQuantityInput).then((res) => {
        this.salegroupList = res.data.result;
        this.salepurTotal = res.data;
      })
    }
  }
  onSelectPurchase(value) {
    this.valueItem = value;
  }

  ApplyPurchaseValue() {
    if (this.valueItem == "Greater") {
      this.partyreportsService.getGroupReportPurchaseGreater({}, this.currentUser.id, this.totalPurchaseInput).then((res) => {
        this.salegroupList = res.data.result;
        this.salepurTotal = res.data;
      })
    }
    else if (this.valueItem == "Equal") {
      this.partyreportsService.getGroupReportPurchaseEqual({}, this.currentUser.id, this.totalPurchaseInput).then((res) => {
        this.salegroupList = res.data.result;
        this.salepurTotal = res.data;
      })
    }
    else {
      this.partyreportsService.getGroupReportPurchaseless({}, this.currentUser.id, this.totalPurchaseInput).then((res) => {
        this.salegroupList = res.data.result;
        this.salepurTotal = res.data;
      })
    }
  }

  changeDate(e) {
    this.getSalePurchRepo();
  }

  handlePage(e) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.partyList.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getSalePurchaseParty();
    }
  }
}


