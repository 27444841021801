import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleScheduleService } from 'src/app/core/services/schedule-schedule.service';

@Component({
  selector: 'app-shop-schedule',
  templateUrl: './shop-schedule.component.html',
  styleUrls: ['./shop-schedule.component.scss']
})
export class ShopScheduleComponent implements OnInit {


  schedules=[
    {
      "id":1,
      "appointmentType":"Routine Visit",
      "shopName":"Chinchwad East Pune",
      "breakTime":"2pm -3pm",
      "bufferTime":"15min",
    },
    {
      "id":2,
      "appointmentType":"Virtual Appt.",
      "shopName":"Chinchwad East Pune",
      "breakTime":"12pm -1pm",
      "bufferTime":"30 min",
    },
]
appointMentType=[
  {
  "id":1,
  "appointMent":"Routine Visit"
  },
  {
    "id":2,
    "appointMent":"Virtual appt	"
  },
  {
    "id":3,
    "appointMent":"One To One"
  },
]


shopID:any;
public schduleAllRes:any;


  constructor(private formBuilder:FormBuilder,
    private modal:NgbModal,private route: ActivatedRoute,private scheduleScheduleService:ScheduleScheduleService ) { }

  ngOnInit(): void {
  
    this.route.params.subscribe(
      params => this.shopID = params['id']
    );
   this.getAllSchedule();
  }
  
  getAllSchedule(){
    this.scheduleScheduleService.getAllScheduleShopID({},this.shopID).then((res) => {
      this.schduleAllRes = res.data;
     })
  }
    
  modalDismiss(){
    this.modal.dismissAll();
  }
  

  deleteSchedule(id:any){
   this.schedules = this.schedules.filter(x => x.id != id);

  }

}
