import { TitleCasePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as moment from "moment";
import { CampaignServiceService } from "src/app/core/services/campaign-service.service";
import { CommonService } from "src/app/core/services/common.service";
import { CrmService } from "src/app/core/services/crm.service";
import { PartyService } from "src/app/core/services/party.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
// Third Party Module Imports
// import moment from 'moment';
// import * as moment from 'moment';
// import 'moment-timezone';

@Component({
  selector: 'app-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.scss']
})
export class AddTemplateComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  templateForm: FormGroup;
  public submitted: boolean;
  partyList: any[] = [];
  selectAllParties: boolean = false;
  selectedParties: any[] = []; // Array to store selected parties
  public todaysDate;
  public today: Date;
  public maxDate: Date;
  minDate: Date = new Date();  // Define minDate as today's date
  date = new Date();
  public selectedYear: number;
  public years: number[] = [];
  selectedTime: { hour: number; minute: number } = { hour: 0, minute: 0 };
  selectedDateTime: any;
  isActive: boolean = true;
  toList: any[] = [];
  currentTime: string;
  // currentTime: Date = new Date();
  intervalId: any;

  constructor(
    private formBuilder: FormBuilder, private crmservice: CrmService,
    public router: Router, private commonService: CommonService,
    private partyService: PartyService,
    public campService: CampaignServiceService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddTemplateComponent>,
    private dialog: MatDialog
  ) {
    this.todaysDate = new Date();
    this.minDate = new Date(this.todaysDate.getFullYear(), this.todaysDate.getMonth(), 25);
  }

  ngOnInit(): void {
    this.templateForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      subject: ["", [Validators.required]],
      header: ["", [Validators.required]],
      footer: ["", [Validators.required]],
      body: ["", [Validators.required]],
    });
  }

  submitForm() {
    this.submitted = true;
    if (this.templateForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }
    else {
      const requestData = {
        templateData: {
          name: this.templateForm.value.name,
          isActive: this.isActive,
          subject: this.templateForm.value.subject,
          header: this.templateForm.value.header,
          footer: this.templateForm.value.footer,
          body: this.templateForm.value.body,
        }
      };
      this.campService.addTemplate(requestData, this.currentUser.id)
        .then((res) => {
          if (res.success) {
            this.dialogRef.close();
            this.commonService.notifyDataAdded()
            this.toastService.toastMsg({
              title: "Success",
              content: "You Have Successfully Added The Campaign!"
            });
          }
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
    }
  }

  cancel() {
    this.submitted = false;
    this.dialogRef.close();
  }

  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.templateForm.get('selectedDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.templateForm.get('selectedDate').setValue(new Date());
    }
  }
}
