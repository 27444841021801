import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduleAppointmentService } from 'src/app/core/services/schedule-appointment.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-shopappoint-form',
  templateUrl: './shopappoint-form.component.html',
  styleUrls: ['./shopappoint-form.component.scss']
})
export class ShopappointFormComponent implements OnInit {

  public appointMentForm: any;
  public submitted: boolean;
  locations = [
    {
      "id": 1,
      "locationName": "At Home"

    },
    {
      "id": 2,
      "locationName": "Virtual/Technician"

    },
    {
      "id": 2,
      "locationName": "Phone Call"

    },
  ]
  userRoles = [
    {
      "id": 1,
      "userRole": "Admin"

    },
    {
      "id": 1,
      "userRole": "User"

    }
  ]
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  shopID: any;

  constructor(private formBuilder: FormBuilder,
    private scheduleAppointmentService: ScheduleAppointmentService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.appointMentForm = this.formBuilder.group({
      Name: ["", [Validators.required,]],
      Duration: ["", [Validators.required]],
      LocationType: ["", [Validators.required]],
      UserRole: ["", Validators.required],
      Description: ["", Validators.required],
      Cancellation: [false, Validators.required],
      isDisable: [false, Validators.required],
      withinDays: ["",[Validators.required]]

    });
    this.route.params.subscribe(
      params => this.shopID = params['id']
    );
  }
  saveAppointForm() {
    this.submitted = true;
    if (this.appointMentForm.invalid) {
      console.log(this.appointMentForm.controls);
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    }
    let body = {
      "name": this.appointMentForm.controls.Name.value,
      "duration": this.appointMentForm.controls.Duration.value,
      "locationType": this.appointMentForm.controls.LocationType.value,
      "userRole": this.appointMentForm.controls.UserRole.value,
      "description": this.appointMentForm.controls.Description.value,
      "cancellation": this.appointMentForm.controls.Cancellation.value ? this.appointMentForm.controls.Cancellation.value : false,
      "isDisable": this.appointMentForm.controls.isDisable.value ? this.appointMentForm.controls.isDisable.value : false,
      "withinDays": this.appointMentForm.controls.withinDays.value,
      "userId": this.currentUser.id,
      "shopId": this.shopID
    }

    this.scheduleAppointmentService.postAppointDetails(body).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Shop Appointment Added Successfully!",
          })
        // this.toastService.openSnackBar("Shop Appointment Added Successfully.");
        // this.router.navigate(["/pages/shop-setting/shopID/shop-appointment/shopID"]);
        // this.appointMentForm.reset();
        this.resetForm();
        this.submitted = false;

      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    });


  }
  get f() {
    return this.appointMentForm.controls;
  }

  resetForm() {
    this.appointMentForm = this.formBuilder.group({
      Name: [""],
      Duration: [""],
      LocationType: [""],
      UserRole: [""],
      Description: [""],
      Cancellation: [false],
      isDisable: [false],
      withinDays: [""]
    });
  }
}
