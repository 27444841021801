import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SaleReturnServiceService {

  constructor(public httpClient:HttpService) { }
  postSaleReturn(data:{}){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postSaleReturn,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
