<main class="main" id="top">
    <!-- <div class="{{ isFluid ? 'container-fluid' : 'container' }}" data-layout="container-fluid"> -->
    <div class="" data-layout="container-fluid">
        <script>
            var isFluid = JSON.parse(localStorage.getItem("isFluid"));
            if (isFluid) {
                var container = document.querySelector("[data-layout]");
                container.classList.remove("container");
                container.classList.add("container-fluid");
            }
        </script>

        <!-- side bar -->
        <nav class="navbar navbar-vertical navbar-expand-md " id="sidebarPrint">
            <div class="row">
                <script>
                    var navbarStyle = localStorage.getItem("isNavbarVerticalCollapsed");
                    if (isNavbarVerticalCollapsed === "true") {
                        document
                            .querySelector(".navbar-vertical")
                            .classList.add(`navbar-${navbarStyle}`);
                    }
                </script>
                <div class="d-flex align-items-center hide22">
                    <div class="toggle-icon-wrapper d-flex align-items-center m-2">

                        <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                            data-bs-toggle="tooltip" data-bs-placement="left" title=""
                            data-bs-original-title="Toggle Navigation" aria-label="Toggle Navigation"><span
                                class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>

                    </div>
                </div>
            </div>
            <div class="custom-li collapse navbar-collapse" id="navbarVerticalCollapse"
                style="background-color: #110152!important;overflow: auto;width: 100%!important;margin:0!important;padding: 0!important;">
                <div class="navbar-vertical-content scrollbar" style="width: auto;">
                    <!-- <div class="navbar-vertical-content scrollbar"> -->
                    <ul class="navbar-nav d-flex align-items-center flex-column mb-3" id="navbarVerticalNav">
                        <!-- parent pages Dashboard-->

                        <li routerLink="/pages/employee-work/Profile-employee" routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/party.png" class="bi-pie-chart">
                                </div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">
                                    My Profile
                                </div>
                            </a>
                        </li>

                        <li routerLink="/pages/employee-work/expense-employee"  routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/party.png" class="bi-pie-chart">
                                </div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">
                                    Expense
                                </div>
                            </a>
                        </li>

                        <li routerLink="/pages/employee-work/payroll-employee" routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/party.png" class="bi-pie-chart">
                                </div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">
                                    Payroll
                                </div>
                            </a>
                        </li>

                        <!-- <li  routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/party.png" class="bi-pie-chart">
                                </div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">
                                    Letters
                                </div>
                            </a>
                        </li> -->

                        <li routerLink="/pages/employee-work/leave-employee"  routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/party.png" class="bi-pie-chart">
                                </div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">
                                    Leave
                                </div>
                            </a>
                        </li>

                        <li  routerLinkActive="active" routerLink="/pages/employee-work/employee-attendence"   
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/party.png" class="bi-pie-chart">
                                </div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">
                                    Attendence
                                </div>
                            </a>
                        </li>

                        <!-- Employee Approval -->
                        <li routerLinkActive="active" routerLink="/pages/employee-work/employee-approval"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/check-mark.png" class="bi-pie-chart">
                                </div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">
                                    Approvals
                                </div>
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>

        <div class="content" style="width: auto;margin-right: 0 !important;padding-left: 10px;padding-right: 10px;">
            <nav class="navbar navbar-expand-md bg-white" id="headNavPrint">
                <div class="container-fluid">
                    <a class="navbar-brand ">
                        <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3 collapsed"
                            type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse"
                            aria-controls="navbarVerticalCollapse" aria-expanded="false"
                            aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span
                                    class="toggle-line"></span></span></button>
                        <div class="d-flex align-items-center py-3">
                            <img class="me-2" src="assets/img/icons/spot-illustrations/new-logo-iceipt-blue.png" alt=""
                                width="42" />
                            <!-- <span class="font-sans-serif">iCeipts</span> -->
                        </div>
                    </a>

                    <form #insideElement #searchInput class="search-form" (ngSubmit)="onSearch()">
                        <input type="search" [(ngModel)]="searchTerm" name="searchTerm" placeholder="Search Here..."
                            (input)="onSearch()" (keyup.enter)="onEnter()">
                        <button type="submit" (keyup.enter)="onSearch()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </form>

                    <div class="dropdown" [ngClass]="{'show': filteredCategories.length > 0}" (click)="closeDropdown()">
                        <button class="dropbtn"
                            [style.display]="filteredCategories.length > 0 ? 'block' : 'none'">Categories</button>
                        <div class="dropdown-content"
                            [style.display]="filteredCategories.length > 0 ? 'block' : 'none'">
                            <a *ngFor="let category of filteredCategories"
                                [routerLink]="categoryRouterLinks[category]">{{ category }}</a>
                        </div>
                    </div>

                    <div class="collapse navbar-collapse nav-list-items" id="navbarNav">
                        <div class="navbar-nav">

                            <div class="nav-item createNewButton navOption-btns">

                            </div>

                            <div class="nav-item reportButton navOption-btns">

                            </div>

                            <div class="nav-item mx-4 bellIcon">

                                <div class=" col-4">
                                    <a class="nav-link notification-indicator notification-indicator-primary px-2 fa-icon-wait"
                                        *ngIf="isShow" id="navbarDropdownNotification" role="button"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                                            class="svg-inline--fa fa fa-bell fa-w-14 fs-2"
                                            data-fa-transform="shrink-6"></span></a>
                                    <a *ngIf="!isShow" style="color: var(--falcon-navbar-light-color)"
                                        id="navbarDropdownNotification" role="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false"><span
                                            class="svg-inline--fa fa fa-bell fa-w-14 fs-2"
                                            data-fa-transform="shrink-6"></span></a>
                                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
                                        aria-labelledby="navbarDropdownNotification">
                                        <div class="card card-notification shadow-none">
                                            <div class="card-header">
                                                <div class="row justify-content-between align-items-center">
                                                    <div class="col-auto">
                                                        <h4 class="card-header-title mb-0">Notifications</h4>
                                                    </div>
                                                    <div class="col-auto ps-0 ps-sm-3">
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="font-size: 12px;" class="scrollbar-overlay"
                                                style="max-height: 19rem">
                                                <div class="list-group list-group-flush fw-normal">
                                                    <div class="notification-body list-group-title border-bottom text-center p-2"
                                                        *ngIf="!isData">
                                                        No new notifications.
                                                    </div>
                                                    <div *ngIf="isData && !isShow">
                                                        <div class="list-group-item"
                                                            *ngFor="let i of notificationReadArray">
                                                            <a class="notification notification-flush notification-unread"
                                                                (click)="GoTOPages(type)">
                                                                <div class="notification-avatar">
                                                                    <div class="avatar avatar-2xl me-3">

                                                                    </div>
                                                                </div>
                                                                <div class="notification-body">
                                                                    <strong>{{i.title}}</strong>
                                                                    <p class="mb-1">
                                                                        {{i.msg}}
                                                                    </p>
                                                                    <p>{{type}}</p>
                                                                    <span class="notification-time"><span class="me-2"
                                                                            role="img"
                                                                            aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="isData && isShow">
                                                        <div class="list-group-item"
                                                            *ngFor="let i of notificationUnreadArray">
                                                            <a class="notification notification-flush notification-unread"
                                                                (click)="GoTOPages(type)">
                                                                <div class="notification-avatar">
                                                                    <div class="avatar avatar-2xl me-3">

                                                                    </div>
                                                                </div>
                                                                <div class="notification-body">
                                                                    <strong>{{i.title}}</strong>
                                                                    <p class="mb-1">
                                                                        {{i.msg}}
                                                                    </p>
                                                                    <p>{{type}}</p>
                                                                    <span class="notification-time"><span class="me-2"
                                                                            role="img"
                                                                            aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer text-center border-top" *ngIf="isData">
                                                <a class="card-link d-block" routerLink="/pages/notifications">View
                                                    all</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="nav-item profilePic">
                                <a class="nav-link p-0" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="avatar avatar-xl">
                                        <img class="rounded-circle"
                                            [src]="imageSrc ? imageSrc : '/assets/images/default-profile-pic.JPG'" />
                                    </div>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end py-3"
                                    style="font-size: 13px !important;min-width: 150px;"
                                    aria-labelledby="navbarDropdownUser">
                                    <div class="bg-white dark__bg-1000 rounded-2 py-2">
                                        <a class="dropdown-item fw-bold text-warning"
                                            routerLink="/auth/subscription"><span
                                                class="fas fa-crown me-1"></span><span>Go
                                                Pro</span></a>

                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" *ngIf="this.currentUser.userType == 'EMPLOYEE'"
                                            routerLinkActive="active" routerLink="/pages/employee-work/Profile-employee">Profile &amp;
                                            account</a>

                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" *ngIf="this.currentUser.userType == 'EMPLOYEE'"
                                            routerLinkActive="active" style="cursor: pointer;" (click)="logout()">Logout</a>
                                        <!-- <a class="dropdown-item" (click)="logout()">Logout</a> -->
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </nav>

            <router-outlet></router-outlet>
            <!-- Dashboard cards -->


        </div>
    </div>
</main>