import { Component, OnInit } from '@angular/core';
import { SundryCreditorService } from 'src/app/core/services/sundry-creditor.service';

@Component({
  selector: 'app-credit-vendor',
  templateUrl: './credit-vendor.component.html',
  styleUrls: ['./credit-vendor.component.scss'],
})
export class CreditVendorComponent implements OnInit {
  creditorsPartyList: any[] = [];
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;

  constructor(private sundryservice: SundryCreditorService) {
    this.getAllCreditors();
  }

  ngOnInit(): void {}
  getAllCreditors() {
    this.sundryservice
      .sundryCredit(
        {},
        this.currentUser.id,
        'PAID',
        this.partyPageSize,
        this.currentPageNo + 1
      )
      .then((res) => {
        if (res.success) {
          this.creditorsPartyList = res.data.pageData;

        }
      });
  }
}
