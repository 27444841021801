<h2 mat-dialog-title>Edit Business Partner</h2>

<mat-dialog-content [ngSwitch]="businessType">
  <ng-container *ngSwitchCase="'Partnership'">
    <form [formGroup]="editForm">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select formControlName="position" required>
          <mat-option value="Managing Partner">Managing Partner</mat-option>
          <mat-option value="Partner">Partner</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%;">
        <input matInput type="text" placeholder="Name Of Partner" formControlName="nameOfPartner" required>
      </mat-form-field>
    </form>
  </ng-container>

  <ng-container *ngSwitchCase="'Private Limited Company'">
    <form [formGroup]="editForm">
      <mat-form-field appearance="outline" style="width: 100%;">
        <input matInput type="text" placeholder="Name Of Director" formControlName="nameOfPartner" required>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%;">
        <input matInput type="text" placeholder="Position" formControlName="position" required>
      </mat-form-field>
    </form>
  </ng-container>

  <ng-container *ngSwitchCase="'Public Limited Company'">
    <form [formGroup]="editForm">
      <mat-form-field appearance="outline" style="width: 100%;">
        <input matInput type="text" placeholder="Name Of Director" formControlName="nameOfPartner" required>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%;">
        <input matInput type="text" placeholder="Position" formControlName="position" required>
      </mat-form-field>
    </form>
  </ng-container>

  <ng-container *ngSwitchCase="'Limited Liability Partnership'">
    <form [formGroup]="editForm">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select formControlName="position" required>
          <mat-option value="Managing Partner">Managing Partner</mat-option>
          <mat-option value="Partner">Partner</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%;">
        <input matInput type="text" placeholder="Name Of Partner" formControlName="nameOfPartner" required>
      </mat-form-field>
    </form>
  </ng-container>

  <ng-container *ngSwitchCase="'Hindu Undivided Family'">
    <form [formGroup]="editForm">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select placeholder="Position" formControlName="position" required>
          <mat-option value="'Karta'">Karta</mat-option>
          <mat-option value="'Member'">Member</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%;">
        <input matInput type="text" placeholder="Name of Member" formControlName="nameOfPartner" required>
      </mat-form-field>
    </form>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button class="btn-cancel" (click)="onNoClick()">Cancel</button>
  <button mat-button class="btn-save" [mat-dialog-close]="editForm.value" [disabled]="editForm.invalid" (click)="saveChanges()">Save</button>
</mat-dialog-actions>
