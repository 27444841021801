<div class="container-fluid bg-white">
    <div class="mt-3 d-flex justify-content-end">

        <button class="btn btn-outline-primary me-1 mb-1"
            routerLink="/pages/shop-setting/{{shopID}}/add-schedule-form/{{shopID}}" type="button">
            <i class="fas fa-plus-square me-2"></i>
            Add New Schedule
        </button>
    </div>

    <div class="card-body p-0 overflow-hidden">
        <div class="table-responsive">
            <table class="table table-striped table-bordered mt-2 bg-white">
                <thead>
                    <tr class="btn-reveal-trigger">
                        <th scope="col">#</th>
                        <th scope="col">Schedule Name</th>
                        <th scope="col">Appointment Name</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Location Type</th>
                        <th scope="col" class="text-center"></th>
                    </tr>
                </thead>
                <tbody class="list" *ngIf="schedules">
                    <tr class="text-capitalize" *ngFor="let schedule of schduleAllRes; let i = index">
                        <td>{{ i+1 }}</td>
                        <td>{{ schedule.scheduleName }}</td>
                        <td>{{ schedule.appointmentType.name}}</td>
                        <td>{{ schedule.appointmentType.duration}}</td>
                        <td>{{ schedule.appointmentType.locationType}}</td>

                        <td class="name align-middle text-center white-space-nowrap py-2">
                            <!-- <button class="btn plus p-1 ms-2" type="button" (click)="deleteSchedule(schedule.id)">
                                Delete
                            </button> -->
                            <span class="m-1" text-center></span>
                        <button class="btn btnp"  data-bs-target="#errorDelete-modal" aria-hidden="true">
                        <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                        (click)="deleteSchedule(schedule.id)"></span>
                            </button>

                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="schduleAllRes == 0">
                    <tr class="text-center">
                        <td colspan="10">
                            <h5 class="text-nowrap">No Shop Schedule Details Added. Please Add Shop Schedule Details To Be
                                Displayed Here. <span>
                                    <a class="nav-link"
                                        routerLink="/pages/shop-setting/{{shopID}}/add-schedule-form/{{shopID}}">Add New
                                        Shop Schedule</a>
                                </span></h5>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>