import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/_helpers/constant';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;
  public sliderImages = Constants.defaultSliderImages
  public pauseOnHover = true;
  constructor() { }

  ngOnInit(): void {
  }


}
