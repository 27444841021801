<div class="modal-body">
    <form [formGroup]="EmployeeForm">
    <div class="row d-flex justify-content-start">
      <div class="col-sm-12 col-md-12 col-lg-12 my-3">
        <label class="form-label">Employee Name <span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
          <mat-select placeholder="Select Employee" formControlName="employeeName" required
          [ngClass]="{
            'is-invalid':
              f.employeeName.invalid && f.employeeName.touched && submitted
          }">
            <mat-option *ngFor="let employee of employees" [value]="employee.email">
              {{ employee?.firstName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.employeeName.touched) && f.employeeName.errors?.required">
          Employee Name is required.
        </mat-error>
      </div>
    </div>
    <div class="row d-flex justify-content-start">
      <div class="col-sm-12 col-md-12 col-lg-12 mb-5">
        <label class="form-label">Password <span class="error-asterisk">*</span></label>
          <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
       <input [type]="hide ? 'password' : 'text'" placeholder="Enter Password" matInput
              formControlName="password" required 
              [ngClass]="{
                'is-invalid':
                  f.password.invalid && f.password.touched && submitted
              }">
                            <button mat-icon-button matSuffix (click)="hide = !hide" aria-label="Toggle password visibility">
                <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.password.touched) && f.password.errors?.required">
            Password is required.
          </mat-error>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <button style="margin-right: 10px;" type="button" class="btn btn-cancel"
        (click)="cancelAddForm()" >Cancel</button>
      <button type="button" class="btn btn-save" (click)="save()">Save</button>
    </div>
  </form>
</div>
<button class="btn mt-2" data-bs-toggle="modal" data-bs-target="#sessionExistsModal" hidden id="trigger-modal"></button>

<div class="modal fade" id="sessionExistsModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                    aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 500;font-family: 'Poppins';"
                        id="sessionModalLabel">
                        Session Already Exists
                    </h2>
                </div>
                <h4 class="text-center" style="font-family: 'Poppins';">
                    A session is already active. Would you like to close the register?
                </h4>
            </div>
            <div class="modal-footer justify-content-center">
                <a class="btn btn-outline-success"  data-bs-dismiss="modal">Cancel</a>
                <a class="btn btn-outline-danger" (click)="redirectToCloseRegister()" data-bs-dismiss="modal">Redirect to Close Register</a>
            </div>
        </div>
    </div>
</div>



