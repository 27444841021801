import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-shop-setting',
  templateUrl: './shop-setting.component.html',
  styleUrls: ['./shop-setting.component.scss']
})
export class ShopSettingComponent implements OnInit {
  shopID:any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      params => this.shopID = params['id']
    ); 
  }
}
