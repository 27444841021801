<ngb-carousel #carousel [interval]="3000" [pauseOnHover]="pauseOnHover">
  <ng-template ngbSlide *ngFor="let img of sliderImages; index as i">
    <div class="carousel-caption">
      <h3>My slide {{i + 1}} title</h3>
    </div>
    <a >
      <div class="picsum-img-wrapper">
        <img [src]="img" alt="My image {{i + 1}} description">
      </div>
    </a>
  </ng-template>
</ngb-carousel>
