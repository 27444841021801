// shared-data.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private data: any;
  private drafts: { [key: string]: any } = {};
  private draftDataSubject = new BehaviorSubject<any>(null);
  draftData$ = this.draftDataSubject.asObservable();

  private showChartSource = new BehaviorSubject<boolean>(false);
  showChart$ = this.showChartSource.asObservable();

  setShowChart(value: boolean): void {
    this.showChartSource.next(value);
  }

  setDraft(data: any) {
    this.draftDataSubject.next(data);
  }

  setDraftData(formType: string, data: any) {
    this.drafts[formType] = data;
  }

  getDraftData(formType: string) {
    return this.drafts[formType];
  }

  setData(data: any) {
    this.data = data;
  }

  getData(): any {
    return this.data;
  }

  clearData() {
    this.data = null;
  }

}
