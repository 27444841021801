import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AssetsServiceService } from 'src/app/core/services/assets-service.service';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

interface IRange {
  value: Date[];
  label: string;
}

@Component({
  selector: 'app-asset-non-formcompoennet',
  templateUrl: './asset-non-formcompoennet.component.html',
  styleUrls: ['./asset-non-formcompoennet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetNonFormcompoennetComponent implements OnInit {
  public nonAssetsForm: any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public formArrayData: any;
  productPicUrl: any;
  submitted: boolean = false;
  assetDeprecianinPer: any;
  GLData: any;
  toatalAmount: any;
  public today: Date;
  public maxDate: Date;
  public todaysDate;
  public ranges: IRange[] = [
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
      label: 'Last 7 Days'
    },
    {
      value: [new Date(new Date().setDate(new Date().getMonth() - 1)), new Date()],
      label: 'Last Month'
    }
  ];
  selectedYear: number;
  public panDocument: any ='';
  public documentPan: any ='';
  panFile: any;
  fileType: string | null = null;
  Body: any;

  constructor(private formBuilder: FormBuilder, public router: Router, private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe, public dialogRef: MatDialogRef<AssetNonFormcompoennetComponent>,
    private cdr: ChangeDetectorRef, private assetsServiceService: AssetsServiceService, private productService: ProductService,) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    this.selectedYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.createnonAseetsForm();
    this.getASSETData();
    this.todaysDate = new Date();

  }
  createnonAseetsForm() {
    this.nonAssetsForm = this.formBuilder.group({
      assetName: ["", [Validators.required]],
      assetDate: ["", [Validators.required]],
      valueOfAssets: ["", [Validators.required]],
      scrapValue: ["", [Validators.required]],
      costOfAssets: [0],
      depreciation: [false],
      assetsMethod: [""],
      assetProcess: [""],
      assetDeprecianinPer: [""],
      assetsYear: [""],
      paymentMode: ["", [Validators.required]],
      // glNumber:["",[Validators.required]],
      addtionalDetails: new FormArray([this.createaddtionalDetails()]),
    });

    this.additionalDetailsControls.valueChanges.subscribe(data => {
      this.toatalAmount = data.reduce((a, b) => a + +b.amount, 0);
      this.updateTotalCostOfAssets();
    });

    // Subscribe to value changes of valueOfAssets control
    this.nonAssetsForm.get('valueOfAssets').valueChanges.subscribe(() => {
      this.updateTotalCostOfAssets();
    });


  }
  updateTotalCostOfAssets() {

    const valueOfAssets = parseFloat(this.nonAssetsForm.get('valueOfAssets').value);
    console.log('valueOfAssets value -->', valueOfAssets);

    let allTotal = 0; // Initialize to 0

    if (!isNaN(valueOfAssets)) {
      allTotal = valueOfAssets; // Set to valueOfAssets if it's a valid number
    }

    if (/'* Add your condition here to include toatalAmount *'/) {
      const toatalAmount = parseFloat(this.toatalAmount); // Parse toatalAmount if needed
      console.log('toatalAmount value -->', toatalAmount);

      if (!isNaN(toatalAmount)) {
        allTotal += toatalAmount; // Add toatalAmount if it's a valid number
      }
    }

    console.log('allTotal value -->', allTotal);
    this.nonAssetsForm.patchValue({
      costOfAssets: allTotal,
    });
  }

  getASSETData() {
    this.assetsServiceService.getAsseTListByledger({}, this.currentUser.id).then((res: any) => {
      console.log("AssetsByLedegr", res)
      this.GLData = res.data.map((item) => { return item.glNumber });
    })
  }


  get f() {
    return this.nonAssetsForm.controls;
  }

  createaddtionalDetails(): FormGroup {
    // this.cdr.detectChanges(); 
    try {
      return new FormGroup({
        name: new FormControl(null),
        amount: new FormControl(null),

      });
    }
    catch (e) {


    }
  }
  get additionalDetailsControls() {
    try {
      return this.nonAssetsForm.get('addtionalDetails') as FormArray;
    }
    catch (e) {


    }

  }
  addAddtionalDetails(): void {
    try {
      const data = this.nonAssetsForm.get('addtionalDetails') as FormArray;
      data.push(this.createaddtionalDetails());
      this.cdr.detectChanges();
    }
    catch (e) {
      console.log(e)

    }
  }

  deleteaddAddtionalDetails(index): void {
    const data = this.nonAssetsForm.get('addtionalDetails') as FormArray;
    if (data.length > 0) {
      data.removeAt(data.length - 1); // Remove the last element
    }
    this.cdr.detectChanges();
  }
  cancel() {
    this.dialogRef.close();
  }
  postnonAsseTsForm() {
    this.submitted = true;
    console.log("non asset form ",this.nonAssetsForm)
    this.formArrayData = this.additionalDetailsControls.value;
    if (this.nonAssetsForm.invalid) {

      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields."
      });
      return false;
    }
    else {
      this.Body =
      {
        "userId": this.currentUser.id,
        // "glNumber": this.nonAssetsForm.controls.glNumber.value,
        "assetsType": "NON CURRENT",
        "assetName": this.nonAssetsForm.controls.assetName.value,
        "date": this.nonAssetsForm.controls.assetDate.value,
        "valueOfAsset": this.nonAssetsForm.controls.valueOfAssets.value,
        "scrapValue": this.nonAssetsForm.controls.scrapValue.value,
        "costOfAsset": this.nonAssetsForm.controls.costOfAssets.value,
        "paymentMode": this.nonAssetsForm.controls.paymentMode.value,
        "uploadDocument": this.documentPan === '' ?  null : this.documentPan ,
        "depreciationStatus": this.nonAssetsForm.controls.depreciation.value,
        "depreciation": this.nonAssetsForm.controls.depreciation.value,
        "method": this.nonAssetsForm.controls.assetsMethod.value,
        "process": this.nonAssetsForm.controls.assetProcess.value,
        "enterDepreciation": this.nonAssetsForm.controls.assetDeprecianinPer.value,
        "year": this.nonAssetsForm.controls.assetsYear.value,
       // "additionalDetails": this.formArrayData,
      }
      this.formArrayData.forEach(element => {
        if (element.name != "" && element.amount != "" ) {
          this.Body.additionalDetails = this.formArrayData
        }
      });
      
      this.assetsServiceService.addAssets(this.Body).then((res: any) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Non Current Assets Added Successfully!"
        });
        this.assetsServiceService.notifyassetsAdded();
        this.dialogRef.close();

      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }
  }
  handleInputChangePan(e) {
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
    const selectedFile = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    let reader = new FileReader();
    let formdata = new FormData();

    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        console.log("fileExtension", fileExtension)
        const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
        if (selectedFile.size > fileSizeLimit) {
          alert("File size exceeds the limit. Please select a smaller image or pdf (up to 1 MB).");
          return;
        }
        else {
          reader.readAsDataURL(selectedFile);
          reader.onload = () => {
            if (selectedFile == null) {
              this.toastService.toastMsg({
                title: "Error",
                content: "Please select image or pdf to upload.",
              });
            } else {
              formdata.set("upload", selectedFile)
              e.srcElement.value = "";
              if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png') {
                this.productService.PostImagePdf(formdata).then((res) => {
                  this.documentPan = res.data.location;
                  this.panDocument = reader.result;
                  this.fileType = 'image';
                  this.panFile = selectedFile
                  this.toastService.toastMsg({
                    title: "Success",
                    content: "Document Uploaded Successfully!",
                  });
                  this.cdr.detectChanges(); // Trigger change detection
                });
              } else {
                this.productService.PostImagePdf(formdata).then((res) => {
                  this.documentPan = res.data.location;
                  this.panDocument = reader.result;
                  this.fileType = 'pdf';
                  this.panFile = selectedFile
                  this.toastService.toastMsg({
                    title: "Success",
                    content: "Document Uploaded Successfully!",
                  });
                  this.cdr.detectChanges(); // Trigger change detection
                });
              }
            }
          }
        }
      } else {
        alert('Please select a valid image (jpg, jpeg, png, pdf).');
      }
    } else {
      alert('You can only select a file once.');
    }
  }
  getLastWordAfterHyphenPan(str) {
    const parts = str.split('-');
    const lastPart = parts[parts.length - 1];
    return lastPart.trim();
  }
  openImgPdfpan() {

    if (this.panFile) {
      const url = URL.createObjectURL(this.panFile);
      if (this.panFile.type.startsWith('image')) {
        window.open(url, '_blank');
      } else if (this.panFile.type === 'application/pdf') {
        window.open(url, '_blank');
      }
    }
  }

  deletePan(event: MouseEvent) {
    if (this.fileType == 'image') {
      this.toastService.toastMsg({
        title: "Success",
        content: "Document Deleted Successfully!",
      });
    } else {
      this.toastService.toastMsg({
        title: "Success",
        content: "Document Deleted Successfully!",
      });
    }
    event.stopPropagation();
    this.documentPan = '';
    this.panDocument = '';
    this.fileType = null
  }
  openDatepicker(){
        // Check if the current value of taskdate is an "Invalid Date"
  const selectedDate = this.nonAssetsForm.get('assetDate').value;
  if (isNaN(selectedDate.getTime())) {
    // Set a default date or any valid date here
    this.nonAssetsForm.get('assetDate').setValue(new Date());
  } 
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  
  acceptString(event): boolean {
    console.log('Event',event)
    const charCode = (event.which) ? event.which : event.keyCode;

    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode === 32) {
      return true;
    }

    return false;
}
}
