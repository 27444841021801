import { Component, ComponentFactoryResolver, Inject, OnInit, Type } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Widget } from 'src/app/_models/widgets';
import * as Highcharts from 'highcharts';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-configure-widget-modal',
  templateUrl: './configure-widget-modal.component.html',
  styleUrls: ['./configure-widget-modal.component.scss'],
})
export class ConfigureWidgetModalComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  widget: Widget;
  dashboardnewId: any;
  chartId: any;
  chartData: any;
  dashboardlistId: any;
  chartType: any;

  constructor(public dialogRef: MatDialogRef<ConfigureWidgetModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dashboardService: DashboardService,
    private commonService: CommonService,
    public toastService: ToastNotificationService,
  ) {
    this.widget = data.widget;
    this.dashboardnewId = data.dashboardNewId;
    this.dashboardlistId = data.widget?._id;
    this.chartType = data.widget?.chartType;
  }

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
  }

  selectChartType(type: string) {
    this.chartType = type;
    console.log(this.chartType)
  }

  updateChartType() {
    // dashboardNewId means dashboard id 
    // dashboardListid means widget id
    
    if(this.dashboardlistId && this.dashboardnewId && this.chartType){
      this.dashboardService.updateDefaultUISettings({chartType: this.chartType, dashboardlistId: this.dashboardlistId, dashboardnewId: this.dashboardnewId }, this.currentUser.id).then((res: any) => {
        if(res.success){
          this.toastService.toastMsg({
            title: "Success",
            content: `Chart Type Changed to ${this.chartType}`,
          });
          this.commonService.notifyDataAdded();
          this.closeDialog();
        }
      })
    } else {
      this.toastService.toastMsg({
        title: "Warning",
        content: `First save dashboard to change the chart configuration`,
      });
      this.dialogRef.close();
    }
  }
}
