<nav class="navbar navbar-expand-md navbar-light pt-3">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="">
      <div class="collapse navbar-collapse nav-list-items dashboard-select">
        <mat-form-field appearance="outline" class="create">
          <mat-select placeholder="Default Dashboard">
            <mat-option value="0">New Dashboard</mat-option>
            <mat-option
              *ngFor="let dashboard of dashboardList"
              [value]="dashboard.id"
              >{{ dashboard.name }}</mat-option
            >

          </mat-select>
        </mat-form-field>
      </div>
      <div class="collapse navbar-collapse nav-list-items dashboard-btns">
        <div class="nav-item navOption-btns create">
          <button type="button" class="btn btn-primary" (click)="openDialog()">
            Create New Dashboard
          </button>
        </div>
        <div class="nav-item navOption-btns create">
          <button
            type="button"
            class="btn btn-primary"
            (click)="customizeDashboard()"
          >
            Customize
          </button>
        </div>
        <div class="nav-item update">
          <a href="">Reset to default</a>
        </div>
        
      </div>
    </div>
  </div>
</nav>
