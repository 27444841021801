<div class="card-body">
    <div class="row flex-between-center">
        <div class="col-sm-auto mb-2 mb-sm-0">
            <h2 class="mb-0">Appointment Types</h2>
        </div>
        <div class="col-sm-auto">
            <div class="row gx-2 align-items-center">
                <div class="col-auto">
                    <form class="row gx-2">
                        <div class="col-auto">
                            <input class="form-control" aria-label="Bulk actions"placeholdeer="search"> 
                        </div>
                    </form>
                </div>
                <button class="col-auto pe-0"><a class="text-600 px-1" routerLink="/pages/schedule/appoinntmentTypeForm" data-bs-toggle="tooltip">add new</a></button>
            </div>
        </div>
    </div>
</div>
<div class="card-body p-0 overflow-hidden">
        
    <div class="table-responsive">
        <table class="table table-striped  bg-white">
            <thead>
                <tr class="btn-reveal-trigger">
                    <th scope="col">Name</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Location Type</th>
                    <th scope="col">Booking Span</th>
                    <th scope="col">Client Facing</th>
                    <th scope="col">RSVP Enaled</th>
                    <th scope="col" class="text-center">Action</th>
                </tr>
            </thead>
            <tbody>
    
                <tr *ngFor="let appointment of appointMentData">
                    <th>{{appointment.Name}}</th>                      
                    <td>{{appointment.Duration}}</td>
                    <td>{{appointment.LocationType}}</td>
                    <td>{{appointment.BookingSpan}}</td>
                    <td>{{appointment.ClientFacing}}</td>
                    <td>{{appointment.RSVPEnaled}}</td>
                    <td>
                        <i class="fas fa-edit icolor"></i>
                        <i class="fa fa-trash icolor" aria-hidden="true"></i>

                    </td>
                   
                </tr>
            </tbody>
           
        </table>
    </div>
</div>