
    <div class="container-fluid">
        <div class="row">
          <!-- Personal Details Widget -->
          <div class="col-sm-12 col-md-12 col-lg-6 my-4">
            <div class="card widget">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <p class="widget-header">Personal Details</p>
                    <img src="assets/icons/edit-pencil.svg" routerLink="/pages/profile-settings" style="cursor: pointer;" *appHasPermission="['modify']; name 'profile'" alt="Edit" width="20" height="20" matTooltip="Edit Profile">
                  </div>
                <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4">

                        <div class="d-flex flex-column align-items-center">
                            <div class="avatar avatar-xxl">
                            <label class="uploader" ondragover="return false;" [class.loaded]="loaded" [style.outlineColor]="dragging ? activeColor : baseColor"
                                (dragenter)="handleDragEnter()" (dragleave)="handleDragLeave()" (drop)="handleDrop($event)">
                                <i class="bi bi-camera profile-icon bottom-right2 fw-bold"
                                style="font-size: 2rem; text-align: center; opacity: 1; cursor: pointer"></i>

                                <div class="profile-pic-container img-fluid" [style.background-image]="'url(' + (imageSrc ? imageSrc : '/assets/images/default-profile-pic.JPG') + ')'">
                                <input type="file" name="file" accept="image/*" (change)="handleInputChange($event)">
                                </div>
                            </label>
                            </div>
                        </div>

                    </div>

                    <div class="col-sm-12 col-md-8 col-lg-8 mt-3">
                        <!-- Display personal details here -->
                        <p class="fontweightTitle mb-4" style="font-weight:500; font-size:25px!important; color: #110152;">{{this.tradeName}}<span
                            data-bs-toggle="tooltip" class="m-3" data-bs-placement="right" title="Verified"><small
                                class="fa fa-check-circle text-primary"
                                data-fa-transform="shrink-4 down-2"></small></span>
                        </p>
                        <p class="fontweight mb-4" *ngIf="legalName">Legal Name: <b>{{ legalName }}</b></p>
                        <p class="fontweight mb-4" >Name: <b>{{ firstName }}</b></p>
                        <p class="fontweight mb-4">Contact #: <b>{{ mobile }}</b></p>
                        <p class="fontweight mb-4">E-mail: <b>{{ email }}</b></p>

                    </div>
                </div>

                </div>
            </div>
          </div>

          <!-- Calendar Widget -->
          <!-- <div class="col-sm-12 col-md-5 col-lg-3 my-4">
            <div class="card widget">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <p class="widget-header">Bank Details</p>
                </div>
                <div class="card-body p-0 hidden-scroll">
                    <table class="table table-responsive fs--1 mb-0">
                        <thead class="text-600">
                        <tr>
                            <th class="text-nowrap">Account</th>
                            <th class="text-nowrap">Amount</th>
                            <th class="text-nowrap"></th>
                        </tr>
                        </thead>
                        <tbody *ngIf="bankApiResponse">
                        <tr *ngFor="let bankData of bankApiResponse; let i = index">
                            <td style="vertical-align: middle;" (click)="getbankdetailsById(bankData.id)">
                            <span style="float: left;"><img src="../../../../assets/images/bank_logo.png" /></span>
                            <span class="text-nowrap" style="float: left;padding-left: 10px;padding-top: 7px;color:#000">{{
                                bankData.bankName }}</span>
                            </td>
                            <td style="vertical-align: middle;color:#000;">20000</td>
                            <td style="vertical-align: middle;">
                            </td>
                        </tr>
                        </tbody>
                        <tbody *ngIf="bankApiResponse.length === 0">
                                <tr class="">
                                  <td colspan="12">

                                    <img style="margin: 10% 15% 0% 15%;" class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                                      alt="notfound1" width="200" height="150" />
                                  </td>
                                </tr>
                              </tbody>
                    </table>
                </div>
            </div>
          </div> -->

          <!-- Settings Widget -->
          <div class="col-sm-12 col-md-12 col-lg-4 my-4">
            <div class="card widget">
              <div class="card-body">
                <div class="col py-1" *appHasPermission="['delete', 'modify','create', 'read']; name 'remoteshopping'">
                    <a class="d-flex align-items-center mb-2">
                        <p class="widget-header">Remote Shop Setting</p>
                    </a>
                    <a class="d-flex align-items-center mb-2">
                        <div class="">
                            <div class=" d-flex align-items-center mb-2">
                                <div class="form-check form-switch toggleHeader">
                                    <input class="form-check-input" type="checkbox" [checked]="isShown"
                                    [(ngModel)]="isRemoteToggle" id="flexSwitchCheckDefault"
                                    (click)="remoteShopToggle()" />
                                    <label class="lab text-nowrap pl-2"> Remote Shop</label>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <hr>
                <!-- Display settings options here -->
                <div class="col py-2">
                    <p class="widget-header">Subscription Setting</p>
                    <a class="font-sans-serif pointer-event-cursor"></a>
                    <a class="border-bottom-0 notification rounded-0 border-x-0 border border-300">
                        <a>
                            <p class="m-3" for="my-followers">Plan Name - {{ planName }} &nbsp; End Date - {{ endDate | date: 'dd MMM YYYY' | uppercase }}</p>
                            <button class="btn profile-btn" (click)="redirectToSubscription()">Update Plan</button>
                        </a>
                    </a>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="card widget">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <p class="widget-header">Business Details</p>
                        <img src="assets/icons/edit-pencil.svg" routerLink="/pages/profile-settings" style="cursor: pointer;" *appHasPermission="['modify']; name 'profile'" alt="Edit" width="20" height="20" matTooltip="Edit Profile">
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col d-flex flex-column align-items-center">
                                <div class="business-labels">
                                    <p class="mb-4">TRN</p>
                                </div>
                                <div class="" >
                                    <p class="text-nowrap p-3 business-tag">{{ gstinNo ? gstinNo : 'Not Found' }}</p>
                                </div>
                            </div>
                        </div>
                        <hr>

                        <div class="row">
                            <div class="col d-flex flex-column align-items-center" *ngIf="accountMethod">
                                <div class="business-labels">
                                    <p class="mb-4">Account Method</p>
                                </div>
                                <div class="flex-item">
                                    <p class="text-nowrap my-2" style="color : #2c7be5"><b>{{ accountMethod | titlecase }}</b></p>
                                </div>
                            </div>
                            <div class="col d-flex flex-column align-items-center" *ngIf="compositeType">
                                <div class="business-labels">
                                    <p class="mb-4">Composite Type</p>
                                </div>
                                <div class="flex-item">
                                    <p class="text-nowrap my-2" style="color : #2c7be5"><b>{{ compositeType | titlecase }}</b></p>
                                </div>
                            </div>
                            <div class="col d-flex flex-column align-items-center" *ngIf="gstType">
                                <div class="business-labels">
                                    <p class="mb-4">Tax Payer Type</p>
                                </div>
                                <div class="flex-item">
                                    <p class="text-nowrap my-2" style="color : #2c7be5"><b>{{ gstType | titlecase }}</b></p>
                                </div>
                            </div>
                        </div>
                        <hr>

                        <div class="row">
                            <div class="d-flex align-items-center" style="justify-content: space-around;">
                                <div class="business-labels">
                                    <p class="mb-1">Address: </p>
                                </div>

                                <div>
                                    <p class="mb-0 fw-bold">
                                        {{ district ? district + ',' : '' }}
                                        {{ pincode ? pincode + ',' : '' }}
                                        <ng-container *ngIf="state">{{ state | titlecase }},</ng-container>
                                        {{ country | uppercase }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="card widget" style="max-height: 320px;">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <p class="widget-header">Additional places of Business</p>
                    </div>
                    <div class="card-body hidden-scroll pt-0">
                        <div class="mb-3" *ngFor="let add of multipleAddress;let i=index" style="line-height:30px!important;">
                            <a class="notification">
                                <div class="notification-avatar">
                                    <div class="avatar avatar-xl me-3">
                                        <div class="avatar-emoji rounded-circle "><span role="img" aria-label="Emoji">
                                                <i class="fas fa-city fs-1"></i></span></div>
                                    </div>
                                </div>
                                <div class="notification-body">
                                    <p class="mb-1"><strong>{{add.address}}, {{add.state | titlecase}}, {{add.country | uppercase}}</strong></p>
                                    <span class="notification-time">{{add.pincode}}</span>
                                </div>
                            </a>
                        </div>
                        <div class="fs--1 p-0 m-1" *ngIf="multipleAddress.length ==0" style="line-height:22px!important;">
                            <a class="notification">

                                <div class="notification-body">
                                    <p>No Additional Places of Business. Please Add at least one
                                        Additional Place of Business Details to be displayed here</p>
                                    <div class="text-center">
                                        <a routerLink="/pages/profile-settings">
                                            Add new Additional Places of Business
                                        </a>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>

