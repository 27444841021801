import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateDashboardComponent } from '../create-dashboard/create-dashboard.component';

@Component({
  selector: 'dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss'],
})
export class DashboardNavComponent implements OnInit {
  public activeDashboard: any;
  public newDashboard: any;
  public newDashboardList: any;
  public dashboardList = [
    { id: '1', name: 'customerDashboard' },
    { id: '2', name: 'retailDashboard' },
    { id: '3', name: 'employeeDashboard' },
    { id: '4', name: 'enduserDashboard' },
  ];
  CreateDashboardForm: any;

  constructor(public router: Router, public dialog: MatDialog) {}

  ngOnInit(): void {}
  openDialog(): void {
    const dialogRef: MatDialogRef<CreateDashboardComponent> = this.dialog.open(
      CreateDashboardComponent,
      {
        width: '700px', // Adjust the width as per your requirement
        height: '100vh',
        data: {},
        panelClass: '',
        disableClose: true,
        position: {
          right: '0',
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {});
  }
  customizeDashboard() {
    let data = {
      activeDashboard: {
        dashboardName: 'userDashboard',
      },
    };
    this.router.navigate(['/update-dashboard/'], this.activeDashboard);
  }
  saveDashboard() {
    let currentDashboard = this.convertJsontoArray(
      localStorage.getItem('newDashboardData')
    );
    console.log(currentDashboard);
    this.dashboardList = this.dashboardList.concat(currentDashboard);
    console.log('save dashboard called' + this.dashboardList);
  }
  convertJsontoArray(jsonResponse) {
    let list;
    for (const prop in jsonResponse) {
      list.push(jsonResponse[prop]);
    }
    return list;
  }
}
