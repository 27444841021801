<div class="card mb-3">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
           Add Roles and Permissions
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>


    <div class="container" *appHasPermission="['read']; name 'admin'">
        <form [formGroup]="rolesForm">
            <div class="row justify-content-center mt-3">
                <div style="font-size: 12px" class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Role Name</mat-label>
                        <input type="text" required placeholder="Role Name" matInput formControlName="RoleName"
                            [ngClass]="{
                            'is-invalid': (f.RoleName.invalid && f.RoleName.touched) && submitted }">
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.RoleName.touched) && f.RoleName.errors?.required">
                        Role Name is required.
                    </mat-error>

                </div>

                <div style="font-size: 12px" class="col-sm-12 col-md-4 col-lg-4 ml-0">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea matInput required formControlName="Description" rows="1" placeholder="Description" maxlength="150"
                            [ngClass]="{
                                'is-invalid': (f.Description.invalid && f.Description.touched) && submitted }"></textarea>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.Description.touched) && f.Description.errors?.required">
                        Description is required.
                    </mat-error>
                </div>
            </div>


            <div class="row d-flex justify-content-center">
                <div class="col-sm-2 col-md-2 col-lg-2 d-none"></div>
                <div class="col-sm-12 col-md-12 col-lg-8">
                    <div class="table-responsive scrollbar table-bordered mt-2">
                        <table class="table table-striped bg-white overflow-hidden">
                            <thead>
                                <tr class="btn-reveal-trigger">
                                    <th scope="col" class="tableheader">Permission Name</th>
                                    <th scope="col-md-2">Read</th>
                                    <th scope="col-md-2">Create</th>
                                    <th scope="col-md-2">Modify</th>
                                    <th scope="col-md-2">Delete</th>
                                    <th scope="col-md-2">All</th>
                                </tr>
                            </thead>
                            <tbody formArrayName="permissionsFormArray">
                                <tr class="btn-reveal-trigger"
                                    *ngFor="let item of formArr.controls;let p of permissionData; let i = index"
                                    [formGroupName]="i">
                                    <td class="tableheaderchild">{{p.display | titlecase}}</td>
                                    <!-- read -->
                                    <td>
                                        <div class="mb-0">
                                            <input class="form-check-input" formControlName="read" [checked]="read"
                                                type="checkbox" (change)="selectCrud(i,$event.target.checked)" />
                                        </div>
                                    </td>
                                    <!-- create -->
                                    <td>
                                        <div class=" mb-0">
                                            <input class="form-check-input"  formControlName="create" [checked]="create"
                                                type="checkbox" (change)="selectCrud(i,$event.target.checked)" />
                                        </div>
                                    </td>
                                    <!-- Modify -->
                                    <td>
                                        <div class=" mb-0">
                                            <input class="form-check-input"  formControlName="modify" [checked]="modify"
                                                type="checkbox" (change)="selectCrud(i,$event.target.checked)" />
                                        </div>
                                    </td>
                                    <!-- Delete -->
                                    <td>
                                        <div class=" mb-0">
                                            <input class="form-check-input" formControlName="delete" [checked]="delete"
                                                type="checkbox" (change)="selectCrud(i,$event.target.checked)" />
                                        </div>
                                    </td>
                                    <td>
                                        <div class=" mb-0">
                                            <input class="form-check-input" type="checkbox" [checked]="all"
                                                formControlName="all"
                                                (change)="selectAllCrudOption(i,$event.target.checked)" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <div class="mt-3 d-flex justify-content-end ">
                            <button class="btn btn-outline-primary me-1  mb-3" type="button"
                                *appHasPermission="['create']; name 'admin'" (click)="addCustomRoles()">
                                Add Role
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="d-flex" *ngIf="isShowMessage">
        <div class="toast show align-items-center text-white bg-success border-0" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    Role Permission Added Successfully.
                </div>
                <button class="btn-close btn-close-white me-2 m-auto" type="button" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>
</div>