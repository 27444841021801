<div class="card-body">
    <div class="row flex-between-center">
        <div class="col-sm-auto mb-2 mb-sm-0">
            <h2 class="mb-0">Appointment Types</h2>
        </div>
        <div class="col-sm-auto">
            <div class="row gx-2 align-items-center">
                <div class="col-auto">
                    <form class="row gx-2">
                        <div class="col-auto margin-padding">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Search</mat-label>
                                <input type="text" matInput placeholder="Search" [(ngModel)]="searchKey"
                                    [ngModelOptions]="{ standalone: true }" (change)="getSearch(searchKey)">
                                <span>
                                    <mat-icon class="iconcalender p-1">search</mat-icon>
                                </span>
                            </mat-form-field>
                        </div>
                    </form>
                </div>
                <!-- P-2 -> Appro. same size as of search bar. -->
                <!-- mb-2 -> Adjust the alignment -->
                <button class="col-auto btn btn-outline-primary float-right" 
                    style = " margin-bottom: 11px;padding-top: 10px;padding-bottom: 10px;" routerLinkActive="active"
                    routerLink="/pages/shop-setting/{{shopID}}/shop-appointment-form/{{shopID}}">
                    <i class="fas fa-plus-square me-2"></i>
                    Add New Appointment</button>
            </div>
        </div>
    </div>
</div>
<div class="card-body p-0 overflow-hidden">
    <div class="table-responsive">
        <table class="table table-striped table-bordered mt-2 bg-white">
            <thead>
                <tr class="btn-reveal-trigger">
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Location Type</th>
                    <th scope="col">Description</th>
                    <th scope="col">User Role</th>
                    <th scope="col">Cancellation</th>
                    <th scope="col">IsDisable</th>
                    <th scope="col" class="text-center">Action</th>
                </tr>
            </thead>
            <tbody class="list" *ngIf="appointListRes">
                <tr class="text-capitalize" *ngFor="let appointment of appointListRes; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{appointment.name}}</td>
                    <td>{{appointment.duration}}</td>
                    <td>{{appointment.locationType}}</td>
                    <td>{{appointment.description}}</td>
                    <td>{{appointment.userRole}}</td>
                    <td>{{appointment.cancellation }}</td>
                    <td>{{appointment.isDisable}}</td>
                    <td>
                        <!-- <i class="bi-pencil-square icolor fs-1 pointer-event-cursor"></i> -->
                        <button class="btn btnp"
                            routerLink="/pages/shop-setting/{{appointment.shopId}}/update-shop-appointment/{{appointment.id}}"
                            routerLinkActive="active" *appHasPermission="['modify']; name 'admin'">
                            <span class="bi-pencil-square icon-color fs-1" data-bs-toggle="tooltip"
                                data-bs-placement="top" title="Edit"></span>
                            <!-- //edit -->
                        </button>
                        <span class="m-1" text-center></span>
                        <button class="btn btnp"  data-bs-target="#errorDelete-modal" aria-hidden="true">
                        <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                            data-bs-target="#errorDelete-modal" data-bs-toggle="modal" (click)="deleteAppointMent(appointment.id)"></span>
                            </button>
                           
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="appointListRes == 0">
                <tr class="text-center">
                    <td colspan="10">
                        <h5 class="text-nowrap">No Appointment Types Details Added. Please Add Appointment Types Details
                            To Be Displayed Here.
                            <span>
                                <!-- <a class="nav-link"
                                    routerLink="/pages/shop-setting/{{shopID}}/shop-appointment-form/{{shopID}}">Add New
                                    Appointment
                                </a> -->
                            </span>
                        </h5>
                    </td>
                </tr>
            </tbody>
        </table>
        <mat-paginator class="float-right mt-2" *ngIf="appointListRes.length > 0" [pageSize]="appointmentPageSize"
            [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo"
            (page)="handlePage($event)" aria-label="Select page">
        </mat-paginator>
    </div>
</div>

<div class="modal fade" id="errorDelete-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                    aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; " id="modalExampleDemoLabel">
                        Delete Appointment Type? </h2>
                </div>
                <h4 class="text-center ">Are You Sure To Delete this Appointment Type?</h4>

            </div>
            <div class="modal-footer justify-content-center">
                <a class="btn btn-outline-primary" data-bs-dismiss="modal">Close</a>
                <a class="btn btn-outline-primary" data-bs-dismiss="modal" (click)="removeAppointMent()">Delete
                </a>

            </div>
        </div>
    </div>
</div>