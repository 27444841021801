import { Component, OnInit } from '@angular/core';
import {
  CdkDrag,
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { WeeklySalesComponent } from '../../widgets/weekly-sales/weekly-sales.component';
import { TotalSalesComponent } from '../../widgets/total-sales/total-sales/total-sales.component';
import { NetProfitChartComponent } from '../../widgets/net-profit-chart/net-profit-chart/net-profit-chart.component';
import { TotalPurchaseComponent } from '../../widgets/total-purchase/total-purchase/total-purchase.component';
import { StockDetailsComponent } from '../../widgets/stock-details/stock-details/stock-details.component';
import { SalesPurchaseChartComponent } from '../../widgets/sales-purchase-chart/sales-purchase-chart/sales-purchase-chart.component';
import { RecentContactsComponent } from '../../widgets/recent-contacts/recent-contacts.component';
import { LowStocksProductComponent } from '../../widgets/low-stocks-product/low-stocks-product.component';
import { PendingPaymentComponent } from '../../widgets/pending-payment/pending-payment.component';
import { CreditVendorComponent } from '../../widgets/credit-vendor/credit-vendor.component';
import { BestSellingProductsComponent } from '../../widgets/best-selling-products/best-selling-products.component';
import { Widget } from 'src/app/_models/widgets';
import { TotalNetProfitComponent } from '../../widgets/total-net-profit/total-net-profit.component';
import { ConfigureWidgetModalComponent } from '../configure-widget-modal/configure-widget-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProductsSoldComponent } from '../../widgets/products-sold/products-sold.component';
import { NewCustomersComponent } from '../../widgets/new-customers/new-customers.component';
import { Router } from '@angular/router';
import { componentTypeMapping } from 'src/app/pages/shared/shared.module';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'update-dashboard',
  templateUrl: './update-dashboard.component.html',
  styleUrls: ['./update-dashboard.component.scss'],
})
export class UpdateDashboardComponent implements OnInit {
  widgetList: Widget[] = [];
  draggedWidgetList: any[] = [];
  searchTerm: string;
  widgetId = 'id';
  draggedWidget;
  storedWidgetList: any;
  widgetWidth: number;
  showUpdate: boolean = false;
  newDashboardid: string;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public selectedDashboardId = localStorage.getItem("selectedDashboardId")
  // public selectedDashboardId = JSON.parse(localStorage.getItem("dashboardWidgetList"))
  dashboardSelectedWidgetList: any;
  addWidget: boolean;
  dashboardId: any;
  dashboardName: any;
  dashboardStatus: any;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private toastService: ToastNotificationService,
    private DashboardService: DashboardService,
    private commonService: CommonService,
  ) {
  }

  async ngOnInit() {
    this.DashboardService.getDashboardWidgetList({}, this.currentUser.id).then((res:any) => {
      if(res.success){
        this.widgetList = res.data;
      }
    })
    .catch(error => {
      console.error("An error occurred while fetching dashboard widget list:", error);
    })

    if(this.selectedDashboardId){
      this.commonService.rowAdded$.subscribe(() => {
        // Fetch the updated list of users
        this.getDashboardById();
      });
      this.getDashboardById();
    }
    // this.storedWidgetList = JSON.parse(localStorage.getItem("dashboardWidgetList"));

    // sort the widgetList array based on the position property
    // this.widgetList.sort((a, b) => a.position - b.position);
  }

  numberOfContainers: number = 1; // Change this to the desired number of containers
  containerArray: any[] = Array(this.numberOfContainers).fill(null);

  DropWidget(event) {
    this.drop(event);
    // this.setWidgetWidth();
  }

  async getDashboardById(){
    await this.DashboardService.getDashboardById({}, this.currentUser.id, this.selectedDashboardId).then((res: any) => { // Specify the type for res
      if (res.success) {
        // localStorage.setItem("dashboardWidgetList", JSON.stringify(res.data));
        this.dashboardId = res.data.id;
        this.dashboardName = res.data.dashboardName;
        this.dashboardStatus = res.data.status;

        const data = res.data.dashboardlists.map(item => {
          return {
          ...item.dashboardsetting,
          chartType: item.chartType,
          position: item.listposition, // Assuming you want to replace `dashboardsetting.position` with `listposition`
          _id: item.id,
        }});

        data.sort((a, b) => a.position - b.position);
        this.storedWidgetList = data;
        
        if (this.storedWidgetList !== undefined) {
          // localStorage.setItem("currentDashboardData", JSON.stringify(this.storedWidgetList));
          const list = this.storedWidgetList;
          this.draggedWidgetList = list;
          this.showUpdate = true;
        } else {
          this.showUpdate = false;
          this.draggedWidgetList = [];
        }
      }
    }).catch(error => {
      console.error("An error occurred while fetching widget list for specific dashboard:", error);
    });
  }

  drop(event: CdkDragDrop<Widget[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const widget = event.previousContainer.data[event.previousIndex];
      widget.isDeleted = false;
      this.widgetWidth = widget.widgetWidth;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    // Update positions after move/transfer
    this.updateWidgetPositions(this.draggedWidgetList);
    this.updateWidgetPositions(this.widgetList);
  }

  updateWidgetPositions(list: Widget[]) {
    list.forEach((widget, index) => {
      widget.position = index + 1; // Assuming position is 1-based index
    });
  }

  setWidgetWidth() {
    document
      .getElementById('widget')
      .classList.add('widget' + this.widgetWidth);
  }

  removeWidget(widget: Widget) {
    // Remove the widget from the draggedWidgetList
    const index = this.draggedWidgetList.indexOf(widget);
    if (index !== -1) {
      this.draggedWidgetList.splice(index, 1);
      // Add the widget back to the widgetList
      this.widgetList.push(widget);
      widget.isDeleted = true; // Set the isDeleted flag if needed
    }
  }

  configureModal(widget: Widget) {
    const dialogRef: MatDialogRef<ConfigureWidgetModalComponent> = this.dialog.open(ConfigureWidgetModalComponent, {
        width: '700px',
        height: '100vh',
        data: { widget: widget, dashboardNewId: this.dashboardId },
        disableClose: true,
        position: {
          right: '0',
        },
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Handle the result from the modal (e.g., update widget configuration)
        Object.assign(widget, result);
      }
    });
  }

  saveDashboard() {
    const currentDashboardData = JSON.parse(localStorage.getItem('currentDashboardData'))
    var dashboardName = currentDashboardData?.dashboardName;
    const newArray = this.draggedWidgetList.map(item => ({
      dashboardsettingId: item.id,
      listposition: item.position,
      chartType: item.chartType

    }));

    if (!dashboardName) {
      dashboardName = this.generateDashboardName();
    } else {
      let data = {
        dashboardData: {
          dashboardName: currentDashboardData.dashboardName,
          status: currentDashboardData.dashboardPrivacy,
        },
        users: [{
          userId: this.currentUser.id,
          primary: true
        }],
        list: newArray
      }
      if (currentDashboardData.userId) {
        data.users.push({ userId: currentDashboardData.userId, primary: false });
      }
      this.DashboardService.postDashboardDetails(data, this.currentUser.id).then((res: any) => {
        if (res.success) {
          this.newDashboardid = res.data.id;
          this.toastService.toastMsg({
            title: "Success",
            content: "Dashboard Created Successfully!",
          });
          // localStorage.setItem(dashboardName, JSON.stringify(this.draggedWidgetList));
          this.router.navigate(['/pages/dashboard']);
        }
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: "There is some error",
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }
  }

  updateDashboard() {
    const newArray = this.draggedWidgetList.map(item => ({
      dashboardsettingId: item.id,
      listposition: item.position,
      chartType: item.chartType
    }));
    if (!this.dashboardName) {
      this.dashboardName = this.generateDashboardName();
    } else {
      let data = {
        dashboardId: localStorage.getItem("selectedDashboardId"),
        dashboardData: {
          dashboardName: this.dashboardName,
          status: this.dashboardStatus,
        },
        users: [
          {
            userId: this.currentUser.id,
            primary: true
          },
        ],
        list: newArray
      }
      this.DashboardService.updateDashboard(data, this.currentUser.id).then((res: any) => {
        if (res.success) {
          this.newDashboardid = res.data.id;
          this.toastService.toastMsg({
            title: "Success",
            content: "Dashboard Created Successfully!",
          });
          // localStorage.setItem(dashboardName, JSON.stringify(this.draggedWidgetList));
          this.router.navigate(['/pages/dashboard']);
        }
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: "There is some error",
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }
  }

  generateDashboardName(): string {
    const baseName = 'New Dashboard';
    let counter = 1;
    let dashboardName = baseName;

    while (localStorage.getItem(dashboardName) !== null) {
      dashboardName = `${baseName}${counter}`;
      counter++;
    }
    return dashboardName;
  }
}
