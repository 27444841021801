import { DashboardService } from '../../../../core/services/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { PartyService } from 'src/app/core/services/party.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-recent-contacts',
  templateUrl: './recent-contacts.component.html',
  styleUrls: ['./recent-contacts.component.scss'],
})
export class RecentContactsComponent implements OnInit {
  recentPartyList: any[] = [];
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;

  constructor(
    private partyService: PartyService,
    private dashboardService: DashboardService
  ) {}
  ngOnInit(): void {
    this.getAllParties();
    this.getRecentSale();
  }
  getAllParties() {
    this.partyService
      .getAllPartiesDetails(
        {},
        this.currentUser.id,
        this.partyPageSize,
        this.currentPageNo + 1
      )
      .then(
        (res) => {
          // // console.log(res.data);
          //this.recentPartyList = res.data.pageData;
          // console.log(this.recentPartyList);
        },
        (err) => {
          if (err.error.status == 404) {
            this.recentPartyList = [];
          }
        }
      );
  }
  getRecentSale() {
    this.dashboardService.getRecentSale({}, this.currentUser.id).then(
      (res: any) => {
        this.recentPartyList = res.data;
      },
      (err) => {
        if (err.error.status == 404) {
          // this.newPartiesList = [];
        }
      }
    );
  }
}
