<div class="container-fluid mb-3 px-0 mx-0">
    <div class="row" id="headingPrintHide">
        <div class="col-md-6 fs--1"
            style="font-weight:500;font-size:20px!important;display: flex;align-items: center;white-space: nowrap;padding-left: 15px;">
            GST Receivable
        </div>

        <div class="col-md-6"
            style="display: flex; flex-direction: row; justify-content: flex-end;align-items: center;flex-wrap: wrap;">
            <div class="flex-item" style="padding-right: 16px;">
                <input type="search" id="searchInput" class="form-control pe-5 mb-2" placeholder="Search">
            </div>
            <div class="flex-item navOption-btns" style="padding-right: 16px;">
                <button class="btn" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()"
                    type="button"><i class="fas fa-download"></i></button>
            </div>
            <div class="flex-item navOption-btns">
                <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i
                        class="bi-printer fa-1x"></i>
                </button>
            </div>
        </div>
    </div>

    <hr class="mt-1" />
    <div class="fs--1 ng-white">
        <div class="row mb-3" style="display: flex; flex-direction: row;align-items: center;">
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start;flex-basis:300px;" class="col-lg-3 col-sm-12 col-md-3" id="dateRangeIfShownPrintHide">
                <label for="Date Range" class="form-label" id="datePrintHide">Date</label>
                <mat-form-field class="example-full-width" appearance="outline" id="datePrintHide">
                    <input  class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
                        bsDaterangepicker [maxDate]="today" [(ngModel)]="dateRange" (ngModelChange)="changeDate($event)"
                                        [bsConfig]="{ ranges: ranges,
                                        showWeekNumbers:false,
                                        showNextMonth: false,
                                        rangeInputFormat : 'DD MMM YYYY',
                                        containerClass: 'theme-dark-blue',
                                          displayOneMonthRange: true }">
                    <span>
                        <mat-icon class="iconcalender p-1">date_range</mat-icon>
                    </span>
                </mat-form-field>
            </div>
            <!-- <div class="col-sm-5"></div>
            <div class="col-sm-2"></div>
            <div class="col-sm-1 mt-2 text-center" id="excelPrintHide" (click)="exportExcel()">
                <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
                <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>
            </div>
            <div class="col-sm-1 mt-2 text-center" id="PrintoptionHide" (click)="onPrint()">
                <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x"></a>
                <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
            </div> -->
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start;flex-basis: 300px;" class="col-lg-3 col-sm-12 col-md-3" id="showoptionHide">
                <label class="form-label" id="showoptionHide">Select Category</label>
                <mat-form-field class="example-full-width" appearance="outline" id="showoptionHide">
                    <!-- <input matNativeControl matInput formControlName="status" [disabled]="!isDisabledGST" [readonly]="!isDisabledGST"> -->
                    <mat-select matNativeControl placeholder="Category">
                        <mat-option value="ALL FIRM">ALL FIRM</mat-option>
                        <mat-option value="My Company">My Company</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="table-responsive scrollbar mb-5 mt-1 fs--1">
            <table class="table bg-white table-bordered table-stripped" id="excel-table">
                <thead class="" style="background-color: #F0F0F0;">
                    <tr class="text-white">
                        <!-- <th scope="col" class="text-center text-nowrap " style="width: 6%;">#</th> -->
                        <th scope="col" class="text-center text-nowrap">#
                            <button class="btn1 fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color: #ffffff !important;"
                                    class="text-1400  fs-1"></i></button>

                        </th>
                        <th scope="col" class="text-center text-nowrap">PARTY NAME
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Contains</option>
                                        <option value="1">Exact match</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col text-center text-nowrap" class="text-nowrap">BILL NO
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Contains</option>
                                        <option value="1">Exact Match</option>

                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-center text-nowrap">TOTAL VALUE
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="2">Less than</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-center text-nowrap">AMOUNT PAID
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="2">Less than</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>

                        <th scope="col text-center text-nowrap" class="text-nowrap">TOTAL TAX PAID(TCS)
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="1">Less than</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>

                        <th scope="col text-center text-nowrap" class="text-nowrap">DATE OF COLLECTION
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="1">Less than</option>
                                        <option value="3">Range</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="date" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>

                        <th scope="col text-center text-nowrap" class="text-nowrap">TAX NAME
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Contains</option>
                                        <option value="1">Exact Match</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>

                        <th scope="col text-center text-nowrap" class="text-nowrap">TCS RATE
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Contains</option>
                                        <option value="1">Exact Match</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody id="component1">
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>


                    </tr>
                </tbody>
            </table>
        </div>

        <hr class="mt-1" />
        <div class="row" style="font-size: 12px;">
            <div class="col-lg-6">
                <strong class="text-dark">Total Sale With TCS:&nbsp;<a class="text-success">₹ 0.00</a></strong>
            </div>
            <div class="col-lg-6" style="display: flex; flex-direction:row; justify-content: flex-end;padding-right: 80px;">
                <strong class="text-dark">Total TCS:&nbsp;<a
                        class="text-success">₹ 0.00</a> </strong>
            </div>
        </div>
        <!-- <div class="card-footer">
            <div>
                <div class="accordion-item position-absolute bottom-0 start-0" style=" width: 100%;">
                    <div class="accordion-body">
                        <div class="row sm p-0" style="height: px;">
                            <div class="col-sm-4 p-2">
                                <strong class=" text-dark p-2" style="margin-top: 20px; margin-left: 15px;">Total
                                    Purchase With TCS: <a class="text-success">₹ 0.00</a></strong>
                            </div>
                            <div class="col-sm-5">
                                <strong class=" text-dark"></strong>
                            </div>
                            <div class="col-sm-3 p-2">
                                <strong class="fw-bold text-dark " style="margin-left: 30px; ">
                                    Total TCS:<a class="text-success">₹ 0.00</a>
                                </strong>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>
