import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import HC_exporting from 'highcharts/modules/exporting';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service'
import { Chart } from 'angular-highcharts'
import { getChartOptions } from '../../../chartOptions/chartOptions';


@Component({
  selector: 'app-sales-purchase-chart',
  templateUrl: './sales-purchase-chart.component.html',
  styleUrls: ['./sales-purchase-chart.component.scss']
})
export class SalesPurchaseChartComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public todaysDate = this.commonService.getTodaysDate();
  public totalSales: any;
  public totalPurchase: any;
  public salesPurchaseChart: any = [];
  private chart: Highcharts.Chart;

  @Input() chartType: string = 'column'; // Default chart type
  public currentChartType: string = 'column'; // Default chart type
  columnChart: Chart;
  @Input() xAxisTitle: string = ''; // Default x-axis title
  @Input() yAxisTitle: string = ''; // Default y-axis title
  @Input() xAxisCategories: any[] = []; // Default x-axis categories

  constructor(public commonService: CommonService, public dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getColumnBarChart();
    this.dashboardService.widgetData$.subscribe(data => {
      if (data) {
        const chartData = JSON.parse(data.chartData);
        if(chartData.component === 'SalesPurchaseChartComponent'){
          this.chartType = data.chartType;
        }        // this.chartId = data.chartId;
        // this.chartData = data.chartData;
        // Initialize your chart here using this.chartType, this.chartId, and this.chartData
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyChart();
  }

  private destroyChart() {
    if (this.columnChart) {
      this.columnChart.ref$.subscribe(chart => {
        chart.destroy();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartType) {
      this.getColumnBarChart();
    }
  }

  private initChart(chartType, chartData, categories): void {
    this.destroyChart(); // Destroy any existing chart instance
    if (categories) {
      this.xAxisCategories = categories;
    }
    // const options = { ...lineChartOptions, chart: { ...lineChartOptions.chart, type: this.chartType } };
    const options = getChartOptions(chartType, chartData, this.xAxisTitle, this.yAxisTitle, this.xAxisCategories);

    // if (chartData) {
    //   options.series = chartData;
    // }

    this.columnChart = new Chart(options);
  }

  getColumnBarChart() {
    // Sale vs purchase column chart data
    this.dashboardService.getSalesPurchase({}, this.currentUser.id, this.todaysDate).subscribe((res: any) => {
      const data = res.data.dataset;
      const salesData = [];
      const purchaseData = [];
      var chartData = {};

      data.forEach(item => {
        salesData.push(item.salesTotalBillAmount);
        purchaseData.push(item.purchaseTotalBillAmount);
      });
      const monthNames = data.map(item => {
        const month = new Date(0, item.date - 1).toLocaleString('en-US', { month: 'short' });
        return month;
      });
      this.totalPurchase = res.data.totalPurchase
      this.totalSales = res.data.totalSales

      var issalesDataEmpty = salesData.every(value => value === 0);
      var ispurchaseDataEmpty = purchaseData.every(value => value === 0);
      if (issalesDataEmpty && ispurchaseDataEmpty) {
        // Display a "No data found" message
        this.currentChartType = 'NoData';
        /*const chartOptions = {
          chart: {
            type: 'column',
            events: {
              load: function () {
                const chart = this;
                const centerX = chart.plotWidth / 2;
                const centerY = chart.plotHeight / 2;

                // Add the image element
                chart.noDataImage = chart.renderer
                  .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 70, 200, 150)
                  .add();
              }
            }
          },
          title: {
            text: ' ',
            style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '16px',
              color: '#737791'
            }
          },
          xAxis: {
            categories: monthNames
          },
          credits: {
            enabled: false
          },
        };
        this.salesPurchaseChart.chart('sales-purchase-chart', chartOptions);
        document.getElementById('sales-purchase-chart').style.height = '300px';*/

      } else {
        this.currentChartType = this.chartType;
        chartData = [
          {
            name: 'Sale',
            data: salesData,
            color: '#110152',

          },
          {
            name: 'Purchase',
            data: purchaseData,
            //color: '#FC7643',
          },
        ]
        /* const chartOptions = {
           chart: {
             type: 'column',
           },
           title: {
             text: ' ',
             style: {
               fontFamily: "'Poppins', sans-serif",
               fontWeight: '500',
               fontSize: '16px',
               color: '#737791'
             }
           },
           yAxis: {
             gridLineWidth: 0
           },
           xAxis: {
             categories: monthNames,
             gridLineWidth: 0
           },
           // tooltip : {
           //    valueSuffix: ''
           // },
           plotOptions: {
             column: {
               dataLabels: {
                 enabled: true,
               },
               tooltip: {
                 enabled: true,
                 formatter: function () {
                   return this.y; // Display the amount as the tooltip content
                 },
               },
             },
           },
           credits: {
             enabled: false
           },
           series: [
             {
               name: 'Sale',
               data: salesData,
               color: '#110152',

             },
             {
               name: 'Purchase',
               data: purchaseData,
               color: '#FC7643',
             },
           ]
         };
         this.salesPurchaseChart.chart('sales-purchase-chart', chartOptions);
         document.getElementById('sales-purchase-chart').style.height = '300px';*/
        this.initChart(this.currentChartType, chartData, monthNames);
      }
    }, (err) => {
      if (err.error.status == 404) {
        // Handle error
        this.initChart('NoData', {}, []);
      }
    });
    this.initChart('NoData', {}, []);
  }
}
