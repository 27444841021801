 <div class="card overflow-hidden mb-3">
  <div class="card-header bg-light">
    <div class="row flex-between-center">
      <div class="col-sm-auto">
        <h5 class="mb-1 mb-md-0">Your Notifications</h5>
      </div>
      <!-- <div class="col-sm-auto fs--1"><a class="font-sans-serif ms-2 ms-sm-3" href="#!">Mark all as read</a><a
          class="font-sans-serif ms-2 ms-sm-3" href="#notification-settings-modal" data-bs-toggle="modal">Notification
          settings</a></div> -->
    </div>
  </div>
  <div *ngIf="isData">
    <div class="card-body fs--1 p-0" *ngFor="let i of notificationArray">
      <a class="border-bottom-0 notification rounded-0 border-x-0 border-300" (click)="GoTOPages(type)">
        <div class="notification-avatar">
          <div class="avatar avatar-xl me-3">
            <!-- <img class="rounded-circle" src="../../assets/img/team/1.jpg" alt="" /> -->

          </div>
        </div>
        <div class="notification-body">
          <strong>{{i.title | titlecase}}</strong>
          <p class="mb-1">{{i.msg | titlecase}}</p>
          <p>{{type}}</p>
          <span class="notification-time"><span class="me-2" role="img" aria-label="Emoji">📢</span>{{i.timeAgo}}</span>

        </div>
      </a>
    </div>
    <mat-paginator  class="float-right mt-2" *ngIf="notificationArray.length> 0"
      [pageSize]="partyPageSize" [pageSizeOptions]="[5, 10, 20,30]"
      [length]="totalrow" [pageIndex]="currentPageNo" (page)="handlePage($event)"
       aria-label="Select page">
  </mat-paginator>
    <!-- <div class="m-2 text-end">
      <button class="btn btn-outline-primary">Show All</button>
    </div> -->
  </div>
  <div class="p-5" *ngIf="!isData">
    <h5 class="text-nowrap">No Remote Retailer Details Added. Please Add Remote Retailer To Be Displayed Here. <span>
      </span></h5>
  </div>
</div>