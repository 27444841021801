import { Injectable } from '@angular/core';
import { AuthGroup } from 'src/app/_helpers/authorization.types';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service'
@Injectable({
  providedIn: 'root'
})

export class AuthorizationService {
  permissions: Array<string>;
  public permissionArray = JSON.parse(localStorage.getItem("Permission"))
  currentUser = JSON.parse(localStorage.getItem("currentUser"))
  userId: string = "";
  constructor(public rolePermissions: RolesPermissionsService) {
  }

  getPermissionById() {
    this.rolePermissions
      .getPermissions({}, this.currentUser.id).
      then((res) => {
        let i = 0;
        localStorage.setItem("Permission", JSON.stringify({
          data: res.data,
        })
        )
      })
  }

  public initializePermissions(permissionName: string, checkPermissions: String[]) {
    this.getPermissionById();
    var authorized = false
    this.permissionArray.data.forEach(permission => {  
      if (permission.permissionName == permissionName) {   
        checkPermissions.forEach(element => {
                   if (permission[`${element}`] == true) {
            authorized = true;
          } else {
            authorized = false;
          }
        });
      }
    })
    return authorized
  }
}

function e(e: any) {
  throw new Error('Function not implemented.');
}
