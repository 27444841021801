<div class="content">
    <div class="card mb-3">
        <div class="card-body mb-2">
            <div class="row flex-between-center">
                <div class="col-sm-auto mb-2 mb-sm-0">
                    <p class="d-none d-md-inline-block fs--1 mt-3 ml-5"
                        style="font-weight:800;font-size:20px!important;">
                        All Products
                    </p>
                    <!-- <h4 class="mb-0">All Products</h4> -->
                </div>
                <div class="col-sm-auto">
                    <div class="row gx-2 align-items-center">
                        <div class="col-auto">
                            <form class="row gx-2">
                                <div class="col-auto">
                                    <h4><b>Select Product Type:</b></h4>
                                </div>
                                <div class="col-auto">
                                    <select class="form-select form-select-sm" aria-label="Bulk actions"
                                        (change)="getClientInventoryGoodsAndServices($event.target.value)">
                                        <option value="goods" selected="">Goods</option>
                                        <option value="service">Service</option>
                                        <!-- <option value="Delete">Price</option> -->
                                    </select>
                                </div>
                            </form>
                        </div>
                        <!-- <div class="col-auto pe-0"> <a class="text-600 px-1"
                                href="../../../app/e-commerce/product/product-list.html" data-bs-toggle="tooltip"
                                data-bs-placement="top" title="Product List"><span class="fas fa-list-ul"></span></a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-body">
            <div class="row min-vh-50 flex-center g-0" *ngIf="!isData">
                <h4 class="text-center">No Data Found....</h4>
            </div>
            <div class="row" *ngIf="inventoryData.length > 0">
                <div class="mb-2 col-sm-12 col-md-4 col-lg-4" *ngFor="let item of inventoryData">
                    <div class="border rounded-1 h-100 d-flex flex-column justify-content-between pb-3">
                        <div class="overflow-hidden">
                            <div class="position-relative rounded-top overflow-hidden"><a class="d-block">
                                    <img class="img-fluid rounded-top imgFormat" [src]="item.coverImage ==null ? defaultProductImg : item.coverImage" alt=""
                                        routerLink="/pages/customer/product-details/{{item.id}}" /></a>

                                        <!-- routerLink="/pages/customer/viewProduct/{{item.id}}" -->
                            </div>
                            <div class="p-3">
                                <h5 class="fs-0"><a class="text-dark">{{ item.itemName | titlecase}}</a></h5>
                                <p class="fs--1 mb-3"><a class="text-500">{{ item.description }}</a></p>
                                <h5 class="fs-md-2 text-warning mb-0 d-flex align-items-center mb-3">
                                    {{ item.salePrice | currency:"INR":"symbol":'1.0-0'}}
                                </h5>
                                <!-- <p class="fs--1 mb-1">Shipping Cost: <strong>$30</strong></p> -->
                                <div *ngIf="item.productType == 'GOODS'">
                                    <p class="fs--1 mb-1" *ngIf="item.stock > 5">
                                        Stock: <strong class="text-success">Available</strong>
                                    </p>
                                    <p class="fs--1 mb-1" *ngIf="item.stock <= 5">
                                        Stock: <strong class="text-danger">Low in Stock</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-between-center px-3">
                            <div><span class="fa fa-star text-warning"></span><span
                                    class="fa fa-star text-warning"></span><span
                                    class="fa fa-star text-warning"></span><span
                                    class="fa fa-star text-warning"></span><span
                                    class="fa fa-star text-300"></span><span class="ms-1">(5)</span>
                            </div>
                            <div>
                                <!-- <a class="btn btn-sm btn-falcon-default me-2" href="#!" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Add to Wish List">
                                    <span class="far fa-heart"></span></a> -->
                                <a class="btn btn-sm btn-falcon-default" (click)="addProductToCart(item,'false')"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Add to Cart"><span
                                        class="fas fa-cart-plus"></span></a>
                            </div>
                        </div>
                        <div class="row m-2">
                            <div class="col-6 d-grid">
                                <a class="btn btn-warning" data-bs-toggle="modal"
                                    (click)="addBidModal(item.id,item.itemName,item.salePrice,addBid)">Request
                                    Bid</a>
                            </div>
                            <div class="col-6 d-grid">
                                <a class="btn btn-warning" (click)="addProductToCart(item,'true')"
                                    routerlink="/pages/customer/my-cart">Buy Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mat-paginator class="float-right mt-2" *ngIf="inventoryData.length> 0" [pageSize]="partyPageSize"
                [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo"
                (page)="handlePage($event)" aria-label="Select page">
            </mat-paginator>

        </div>
    </div>
    <div class="card">
        <div class="" *ngIf="inventoryData.length <= 0">
            <ngx-spinner bdOpacity="1" bdColor="rgba(97,197,212,0.07)" size="medium" color="#0047AB"
                type="ball-spin-clockwise" fullScreen="true">
                <p style="color: #61c5d4;">Loading..</p>
            </ngx-spinner>
        </div>
    </div>
</div>

<!-- Bid Modal -->
<ng-template #addBid let-modal>
    <div>
        <form [formGroup]="wishlistForm">
            <div class=" position-relative">
                <div class="position-absolute top-0 end-0 mt-2 me-2 ">
                    <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        (click)="modalDismiss()" aria-label="Close"></a>
                </div>

                <div class="modal-body p-2">
                    <div class="rounded-top-lg ps-2">
                        <p class="d-none d-md-inline-block fs--1 mt-3 ml-2"
                            style="font-weight:800;font-size:20px!important;">
                            Bidding Request
                        </p>
                    </div>
                    <div class="pb-0 ps-2">
                        <div class="row">
                            <div class="col-6">
                                <h3 class="text-capitalize">{{selectedProctData}}</h3>
                            </div>

                        </div>
                        <div class="row ">
                            <!-- Pincode -->
                            <div class="col-6">
                                <h5>Pincode</h5>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Pincode</mat-label>
                                    <input matNativeControl type="text" ngxOnlyNumbers required matInput
                                        formControlName="pincode" minlength="5" maxlength="5"
                                        (change)="getPincodeDetails($event.target.value)" [ngClass]="{
                            'is-invalid': f.pincode.invalid && f.pincode.touched && f.pincode.pristine }" value="">
                                </mat-form-field>
                                <mat-error class="text-danger"
                                    *ngIf="(submitted ||f.pincode.touched) && f.pincode.errors?.required">
                                    Pincode Is Required!
                                </mat-error>
                                <mat-error class="text-danger"
                                    *ngIf="(submitted ||f.pincode.touched) && f.pincode.errors?.maxlength">
                                    Pincode must be of 5-digits.
                                </mat-error>
                                <mat-error class="text-danger"
                                    *ngIf="(submitted ||f.pincode.touched) && f.pincode.errors?.minlength">
                                    Pincode must be of 5-digits.
                                </mat-error>
                            </div>
                            <!-- State -->
                            <div class="col-6">
                                <h5>Location</h5>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>City</mat-label>
                                    <mat-select matNativeControl aria-label="Default select example"
                                        placeholder="Select City" formControlName="location">
                                        <mat-option [value]="state" *ngFor="let state of allStates">{{ state }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="text-danger"
                                    *ngIf="(submitted ||f.location.touched) && f.location.errors?.required">
                                    Location Is Required.
                                </mat-error>
                            </div>
                        </div>

                        <div class="row ">
                            <h5>Expected Range</h5>
                            <!-- Budget Min -->
                            <div class="col-6">
                                <h5>Minimum</h5>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Minimum</mat-label>
                                    <input matNativeControl type="text" ngxOnlyNumbers required matInput
                                        formControlName="budgetMin" placeholder="Budget Min" (change)="getMinBudget()"
                                        [ngClass]="{
                            'is-invalid': f.budgetMin.invalid && f.budgetMin.touched && f.budgetMin.pristine }"
                                        value="">
                                </mat-form-field>
                                <mat-error class="text-danger"
                                *ngIf="(submitted ||f.budgetMin.touched) && f.budgetMin.errors?.required">
                                Minimum budget Is Required.
                            </mat-error>
                            <mat-error class="text-danger" *ngIf="isLessPrice">
                                Budget should be less than original price.
                            </mat-error>
                            </div>
                            

                            <!-- Budget Max -->
                            <div class="col-6">
                                <h5>Maximum</h5>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Maximum</mat-label>
                                    <input matNativeControl type="text" ngxOnlyNumbers required matInput
                                        formControlName="budgetMax" placeholder="Budget Max" (change)="getMaxBudget()"
                                        [ngClass]="{
                            'is-invalid': f.budgetMax.invalid && f.budgetMax.touched && f.budgetMax.pristine }"
                                        value="">
                                </mat-form-field>
                                <mat-error class="text-danger"
                                    *ngIf="(submitted ||f.budgetMax.touched) && f.budgetMax.errors?.required">
                                    Maximum budget Is Required.
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="isHighPrice">
                                    Budget should be less than original price
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="isHighPriceMin">
                                    Budget should be greater than minimum price
                                </mat-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-check mt-2">
                                    <input class="form-check-input" type="checkbox" formControlName="publish" />
                                    <label class="form-check-label mr-2" for="flexCheckDefault">
                                        {{ chkBoxTxt }}
                                    </label>
                                    <span class="bi-info-circle text-primary fs-1 margin-left" data-bs-toggle="tooltip"
                                        data-bs-placement="bottom" title="Publish bid to notify retailer"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3 mb-3 m-2">
                    <div class="col-6 text-center d-grid gap-2">
                        <a class="btn btn-warning" (click)="modalDismiss()">Cancel</a>
                    </div>
                    <div class="col-6 d-grid">
                        <a class="btn btn-warning" data-bs-target="#PopUp" (click)="postwishlistBid(popup)">Submit</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>


<ng-template #popup let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Bid Added</h4>
        <button type="button" class="close btn btn-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light" *ngIf="this.wishlistForm.controls.publish.value == false">
            <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; " id="modalExampleDemoLabel">
                <!-- {{this.wishlistForm.controls.publish.value}} -->
                Bid sent to bid list sucessfully.
            </h2>
        </div>
        <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light" *ngIf="this.wishlistForm.controls.publish.value == true">
            <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; " id="modalExampleDemoLabel">
                <!-- {{this.wishlistForm.controls.publish.value}} -->
                Bid notified to the seller sucessfully.
            </h2>
        </div>
    </div>
    <div *ngIf="this.wishlistForm.controls.publish.value == true" class="modal-footer justify-content-center">
        <button class="btn btn-warning" (click)="modal.dismiss()" routerLink="/pages/customer/bidding">Go To Bid
            List
        </button>
    </div>
    <div *ngIf="this.wishlistForm.controls.publish.value == false" class="modal-footer justify-content-center">
        <button class="btn btn-warning" (click)="modal.dismiss()" routerLink="/pages/customer/bidding">Go To Bid
            List
        </button>
    </div>
</ng-template>