import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { Chart, registerables } from 'chart.js';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
HC_exporting(Highcharts);
// import { NgxCsvParser } from 'ngx-csv-parser';
import * as XLSX from 'xlsx';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ProductService } from 'src/app/core/services/product.service';
import { SundryCreditorService } from 'src/app/core/services/sundry-creditor.service';
import { PartyService } from 'src/app/core/services/party.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { PriceRangeDialogComponent } from 'src/app/pages/dashboard/widgets/price-range-dialog/price-range-dialog.component';
import { WarehouseService } from 'src/app/core/services/warehouse.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    // monthYearLabel: 'MMM YYYY',
    // dateA11yLabel: 'YYYY-MM-DD HH:mm:ss',
    // monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-warehouse-dashboard',
  templateUrl: './warehouse-dashboard.component.html',
  styleUrls: ['./warehouse-dashboard.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class WarehouseDashboardComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('purchaseChart') purchaseChart;
  @ViewChild('widgetModalContent') widgetModalContent!: TemplateRef<any>;
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public userId: string = '';
  public showProfile: boolean;
  public todaysDate = this.commonService.getTodaysDate();
  public totalSales: any;
  public totalPurchase: any;
  public permissionData: any;
  public canvas: any;
  public puchaseChart: any = []; /*
  public salesChart: any = []; */
  // public stockDetailsChart: any = [];
  // public netProfitChart: any = [];
  public salesPurchaseChart: any = [];
  public salesMonthlyChart: any = [];
  public puchaseMonthChart: any = [];
  public puchaseYearChart: any = [];
  public salesYearChart: any = [];
  public weekNames: any;
  public weekDates: any;
  public weekTotalPurchaseAmt: any;
  public weekTotalSaleAmt: any;
  public weekNamesSales: any;
  public weekPurchaseDateValue: Date;
  public weekSalesDateValue: Date;
  public monthSalesDateValue: Date;
  public monthPurchaseDateValue: Date;
  public yearSalesDateValue: Date;
  public yearPurchaseDateValue: Date;
  public totalBillAmount: any;
  public monthDay: any;
  public purchaseMonthDay: any;
  public purchaseMonthTotalBillAmount: any;
  public yearlySalesDate: any;
  public yearlySalestotalBillAmount: any;
  public yearlyPurchaseDate: any;
  public yearlyPurchasetotalBillAmount: any;
  public isWeek: boolean = true;
  public isMonth: boolean = false;
  public isYear: boolean = false;
  public salesType: string = 'Weekly';
  public purchaseType: string = 'Weekly';
  public maxDate = new Date();
  public newSaleDate;
  public newPurchaseDate;
  public defaultPurchaseSelect = 'Weekly';
  public defaultSaleSelect = 'Weekly';
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  lowStockProductList: any[] = [];
  pendingPartyList: any[] = [];
  creditorsPartyList: any[] = [];
  recentPartyList: any[] = [];
  newPartiesList: any[];
  recentSaleList: any[];
  productList: any[];
  updatedProductList: any[] = [];

  todaySales: number;
  percentageCount: any;
  netSales: number;
  netProfit: number;
  todaysProductSold: any;
  todaysProductSoldPercent: any;
  newPartiesJoinedCount: any;
  newPartiesPercent: any;
  @Input('appFormatNumberWithCommas') value: number;
  selectedWidget: any;
  selectedFilter: string;
  selectedSort: string;
  filteredProductList: any[] = [];
  sortedProductList: any[];
  isSortSelected = false;
  isFilterSelected = false;
  minPrice: number;
  maxPrice: number;
  emptyListMessage: string;
  topProductSoldList: any[];
  widgets = [];
  salesActivityData: any;
  stockInHand: any;

  constructor(
    public rolePermission: RolesPermissionsService,
    private toastService: ToastNotificationService,
    public authorizationService: AuthorizationService,
    private partyService: PartyService,
    public dashboardService: DashboardService,
    private sundryservice: SundryCreditorService,
    public commonService: CommonService,
    private productService: ProductService,
    public router: Router,
    private el: ElementRef,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private warehouseService: WarehouseService
  ) {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    // Subscribe to the observable to receive updates
    this.widgets = [
      // { id: 1, name: "Sale Activity", checked: true },
      // { id: 2, name: 'Inventry Summary', checked: true },
      // { id: 3, name: 'Sale vs Purchase', checked: true },
      // { id: 4, name: 'Order Summary', checked: true },
      // { id: 5, name: 'Stock Details', checked: true },
      // { id: 6, name: 'Sale vs Purchase', checked: true },
      // { id: 7, name: 'Net Profit: Past Year Vs Current Year', checked: true },
      // { id: 8, name: 'Recent Contacts', checked: true },
      // { id: 9, name: 'Low Stocks Products', checked: true },
      // { id: 10, name: 'Pending Payment', checked: true },
      // { id: 11, name: 'Credit Vendor name', checked: true },
      // { id: 12, name: 'Best Selling products', checked: true },
      { id: 1, name: "Sale Activity", checked: true },
      { id: 2, name: 'Inventry Summary', checked: true },
      { id: 3, name: 'Sale vs Purchase', checked: true },
      { id: 4, name: 'Order Summary', checked: true },
      { id: 5, name: 'Low Stocks Products', checked: true },
      { id: 6, name: 'Best Selling products', checked: true },
    ];

    this.weekPurchaseDateValue = new Date();
    this.weekSalesDateValue = new Date();
    this.monthSalesDateValue = new Date();
    this.monthPurchaseDateValue = new Date();
    this.yearSalesDateValue = new Date();
    this.yearPurchaseDateValue = new Date();
    this.getAppPendingPayments();
    this.warehouseDashboardCount();
    this.getLowStockSummary();
    this.getAllCreditors();
    this.getNetSalesProfit();
    this.getRecentSale();
    this.getTopProductSoldToday();
  }
  formatNumberWithCommas(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  selectFilter(filter: string) {
    this.isFilterSelected = true;
    this.selectedFilter = filter;

    this.updateFilteredProductList();
  }

  selectSort(sort: string) {
    this.isSortSelected = true;
    this.selectedSort = sort;
    this.updateSortedProductList();
  }

  openPriceRangeDialog(): void {
    const dialogRef = this.dialog.open(PriceRangeDialogComponent, {
      width: '300px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedFilter = 'Price';
        if (result.maxPrice <= 0 || result.minPrice < 0) {
          this.emptyListMessage = 'Please select a valid price range.';
          setTimeout(() => {
            this.emptyListMessage = '';
          }, 3000);
          this.getTopProductSoldToday();
        } else if (result.minPrice !== null && result.maxPrice !== null) {
          const minPrice = result.minPrice;
          const maxPrice = result.maxPrice;
          this.filteredProductList = this.updatedProductList.filter((item) => {
            return item.salePrice >= minPrice && item.salePrice <= maxPrice;
          });
          if (this.filteredProductList.length === 0) {
            this.emptyListMessage =
              'No products found within the selected price range.';
            setTimeout(() => {
              this.emptyListMessage = '';
            }, 3000);
            this.getTopProductSoldToday();
          }
        } else {
          // Handled the condition when the price range is not selected
          this.emptyListMessage = 'Please select a valid price range.';
          setTimeout(() => {
            this.emptyListMessage = '';
          }, 3000);
        }

        this.updatedProductList = this.filteredProductList;
        // this.productList = this.filteredProductList;
      }
    });
  }

  updateFilteredProductList() {
    this.filteredProductList = this.updatedProductList;

    if (this.selectedFilter === 'low Stock') {
      this.filteredProductList = this.updatedProductList.filter(
        (item) => item.status.toLowerCase() === 'lowstock'
      );
      this.updatedProductList = this.filteredProductList;
    } else if (this.selectedFilter === 'In Stock') {
      this.filteredProductList = this.updatedProductList.filter(
        (item) => item.status.toLowerCase() === 'instock'
      );
      this.updatedProductList = this.filteredProductList;
    } else if (this.selectedFilter === 'out Of Stock') {
      this.filteredProductList = this.updatedProductList.filter(
        (item) => item.status.toLowerCase() === 'outofstock'
      );
      this.updatedProductList = this.filteredProductList;
    } else if (this.selectedFilter === 'All') {
      this.updatedProductList = this.topProductSoldList; // update the list if user remove all filter then only sorted list should be displayed
      this.updateSortedProductList();
      this.selectedFilter = 'All'; // Set the selected filter to 'All'
    }

    // Handle 'All' and other filter conditions as needed

    if (this.filteredProductList.length === 0) {
      this.emptyListMessage =
        'No products found within the selected filter criteria.';
      setTimeout(() => {
        this.emptyListMessage = '';
      }, 3000);
      this.updatedProductList = this.topProductSoldList; // update the list if there is not found in the filter list then only sorted list should be displayed
      this.updateSortedProductList();
      this.selectedFilter = 'All'; // Set the selected filter to 'All'
    }
  }

  updateSortedProductList() {
    this.sortedProductList = this.updatedProductList;

    if (this.selectedSort === 'Price: Low to High') {
      this.sortedProductList = this.sortedProductList.sort(
        (a, b) => a.salePrice - b.salePrice
      );
    } else if (this.selectedSort === 'Price: High to Low') {
      this.sortedProductList = this.sortedProductList.sort(
        (a, b) => b.salePrice - a.salePrice
      );
    } else if (this.selectedSort === 'Stock: Low to High') {
      this.sortedProductList = this.sortedProductList.sort(
        (a, b) => a.stock - b.stock
      );
    } else if (this.selectedSort === 'Stock: High to Low') {
      this.sortedProductList = this.sortedProductList.sort(
        (a, b) => b.stock - a.stock
      );
    } else if (this.selectedSort === 'Total Sales') {
      this.sortedProductList = this.sortedProductList.sort(
        (a, b) => b.totalSold - a.totalSold
      );
    } else {
      this.sortedProductList = this.sortedProductList.sort(
        (a, b) => b.totalSold - a.totalSold
      );
    }
  }

  getSaleInvoiceReportType(type) {
    this.salesType = type;
    if (type == 'Weekly') {
      this.isWeek = true;
      this.isMonth = false;
      this.isYear = false;
    } else if (type == 'Monthly') {
      this.isWeek = false;
      this.isMonth = true;
      this.isYear = false;
    } else if (type == 'Yearly') {
      this.isWeek = false;
      this.isMonth = false;
      this.isYear = true;
    }
  }

  openWidgetModal(widget) {
    this.modalService.open(this.widgetModalContent, { size: 'md' });
  }

  isWidgetChecked(widgetId: number): boolean {
    const widget = this.widgets.find((widget) => widget.id === widgetId);
    return widget?.checked || false;
  }

  // getTotalSales() {
  //   this.dashboardService.getTotalSales({}, this.currentUser.id, "SALES").then((res) => {
  //     if(res.data){
  //     this.todaySales = res.data.todaySales;
  //     this.percentageCount = res.data.percentageChange;
  //     }
  //   })
  // }

  warehouseDashboardCount() {
    this.warehouseService
      .warehouseDashboardCount({}, this.currentUser.id)
      .then((res) => {
        if (res.success) {
          this.salesActivityData = res.data;
          this.stockInHand = res.data?.stockinhand;
        }
      });
  }

  getLowStockSummary() {
    this.productService
      .getLowStockWarning(
        {},
        this.currentUser.id,
        this.partyPageSize,
        this.currentPageNo + 1
      )
      .then((res) => {
        if (res.data) {
          this.lowStockProductList = res.data.pageData;
        }
      });
  }

  getAppPendingPayments() {
    this.sundryservice
      .sundryCredit(
        {},
        this.currentUser.id,
        'RECEIVED',
        this.partyPageSize,
        this.currentPageNo + 1
      )
      .then((res) => {
        if (res.data) {
          this.pendingPartyList = res.data.pageData;
        }
      });
  }

  getAllCreditors() {
    this.sundryservice
      .sundryCredit(
        {},
        this.currentUser.id,
        'PAID',
        this.partyPageSize,
        this.currentPageNo + 1
      )
      .then((res) => {
        if (res.data) {
          this.creditorsPartyList = res.data.pageData;
        }
      });
  }

  getAllParties() {
    this.partyService
      .getAllPartiesDetails(
        {},
        this.currentUser.id,
        this.partyPageSize,
        this.currentPageNo + 1
      )
      .then(
        (res) => {},
        (err) => {
          if (err.error.status == 404) {
            this.recentPartyList = [];
          }
        }
      );
  }

  // getNewParties() {
  //   this.dashboardService.getNewParties({}, this.currentUser.id).then((res:any) => {
  //     if(res.data){
  //       this.newPartiesJoinedCount = res.data.todayCount
  //       this.newPartiesPercent = res.data.percentageChange
  //     }
  //   }, (err) => {
  //     if (err.error.status == 404) {
  //       this.newPartiesList = [];
  //     }
  //   })
  // }

  // (for now only net sales in given and profit will be doing later)
  getNetSalesProfit() {
    this.dashboardService.getNetSalesProfit({}, this.currentUser.id).then(
      (res: any) => {
        this.netSales = res.data.parties[0].net_sales;
        this.netProfit = res.data.netProfit;
      },
      (err) => {
        if (err.error.status == 404) {
          // this.newPartiesList = [];
        }
      }
    );
  }

  getRecentSale() {
    this.dashboardService.getRecentSale({}, this.currentUser.id).then(
      (res: any) => {
        this.recentPartyList = res.data;
      },
      (err) => {
        if (err.error.status == 404) {
          // this.newPartiesList = [];
        }
      }
    );
  }

  exportData(format: string) {
    if (
      (this.todaysProductSold === 0 &&
        this.todaysProductSoldPercent === 0 &&
        this.newPartiesJoinedCount === 0 &&
        this.newPartiesPercent === 0) ||
      ((this.todaySales === undefined ||
        this.todaySales === null ||
        this.todaySales === 0) &&
        (this.percentageCount === undefined ||
          this.percentageCount === 0 ||
          this.percentageCount === null))
    ) {
      // this.toastService.openErrorSnackBar('No data available for export');
      this.toastService.toastMsg({
        title: 'Error',
        content: 'No Data Available For Export',
      });
      return;
    }
    if (format === 'csv') {
      this.exportAsCSV();
    } else if (format === 'excel') {
      this.exportAsExcel();
    } else if (format === 'pdf') {
      this.exportAsPDF();
    }
  }

  exportAsCSV() {
    const csvContent = this.generateCSVContent(); // Function to generate CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'exported_data.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  generateCSVContent() {
    let csvContent = '';

    // Column headers
    const columnHeader = ['Metric', 'Value', 'Percentage Change'];
    csvContent += columnHeader.join(',') + '\n';

    // Data rows
    const dataRows = [
      ['Total Sales', this.todaySales, this.percentageCount],
      ['Product Sold', this.todaysProductSold, this.todaysProductSoldPercent],
      ['New Customers', this.newPartiesJoinedCount, this.newPartiesPercent],
    ];

    // Combine headers and data, skipping the first row
    dataRows.forEach((row) => {
      csvContent += row.join(',') + '\n';
    });

    return csvContent;
  }
  exportAsExcel() {
    const excelData = this.generateExcelData(); // Function to generate Excel data

    // Create a worksheet using json_to_sheet
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData, {
      skipHeader: true,
    });

    // Create a workbook
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };

    // Convert the workbook to an array buffer
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Save the Excel file
    this.saveAsExcelFile(excelBuffer, 'exported_data.xlsx');
  }
  generateExcelData() {
    const excelData = [];

    // Column headers
    const columnHeader = ['Metric', 'Value', 'Percentage Change'];
    excelData.push(columnHeader);

    // Data rows
    const dataRows = [
      ['Total Sales', this.todaySales, this.percentageCount],
      ['Product Sold', this.todaysProductSold, this.todaysProductSoldPercent],
      ['New Customers', this.newPartiesJoinedCount, this.newPartiesPercent],
    ];

    excelData.push(...dataRows);
    // dataRows.forEach(row => {
    //   excelData.push(row);
    // });

    return excelData;
  }

  exportAsPDF() {
    const pdfContent = this.generatePDFContent();
    pdfMake.createPdf(pdfContent).download('exported_data.pdf');
  }

  generatePDFContent() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const content = [];

    // Column headers
    const columnHeader = [
      { text: 'Metric', style: 'tableHeader', bold: true },
      { text: 'Value', style: 'tableHeader', bold: true },
      { text: 'Percentage Change', style: 'tableHeader', bold: true },
    ];
    content.push(columnHeader);

    // Data rows
    const dataRows = [
      ['Total Sales', this.todaySales, this.percentageCount],
      ['Product Sold', this.todaysProductSold, this.todaysProductSoldPercent],
      ['New Customers', this.newPartiesJoinedCount, this.newPartiesPercent],
    ];

    const tableBody = dataRows.map((row) => {
      return row.map((cell) => {
        return { text: cell, style: 'tableCell' };
      });
    });

    content.push(...tableBody);

    return {
      content: [
        {
          table: {
            widths: ['*', '*', '*'],
            body: content,
          },
          layout: 'lightHorizontalLines',
        },
      ],
      styles: {
        tableHeader: {
          bold: true,
          fontSize: 12,
          fillColor: '#CCCCCC',
          margin: [0, 5, 0, 5],
        },
        tableCell: {
          fontSize: 10,
          margin: [0, 5, 0, 5],
        },
      },
    };
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  // getproductSoldToday() {
  //   this.dashboardService.getProductSold({}, this.currentUser.id, "SALES").then((res:any) => {
  //     // console.log(res.data);
  //     this.todaysProductSold = res.data.productDataToday
  //     this.todaysProductSoldPercent = res.data.extraSalesPercentage
  //   }, (err) => {
  //     if (err.error.status == 404) {
  //     }
  //   })
  // }

  getTopProductSoldToday() {
    this.dashboardService
      .getTopProductSold({}, this.currentUser.id, 10, 'SALES')
      .then(
        (res: any) => {
          this.topProductSoldList = res.data;
          this.updatedProductList = this.topProductSoldList;
        },
        (err) => {
          if (err.error.status == 404) {
          }
        }
      );
  }

  grossProfitLossData = {
    labels: ['Gross Profit', 'Gross Loss'],
    datasets: [
      {
        data: [75, 25, 467, 857, 92],
        backgroundColor: ['#28a745', '#dc3545'],
        hoverBackgroundColor: ['#1e7e34', '#c82333'],
      },
    ],
  };

  netProfitLossData = {
    labels: ['Net Profit', 'Net Loss'],
    datasets: [
      {
        data: [60, 40, 234, 453, 6345, 65634],
        backgroundColor: ['#28a745', '#dc3545'],
        hoverBackgroundColor: ['#1e7e34', '#c82333'],
      },
    ],
  };

  // Configure the options for the nested donut charts
  options = {
    cutoutPercentage: 85,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var value = dataset.data[tooltipItem.index];
          return dataset.labels[tooltipItem.index] + ': ' + value + '%';
        },
      },
    },
  };
}
