<div class="card">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Tickets List
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>

    <div class="card-body">
        <div class="row">
            <div class="col-sm-9"></div>
            <div class="col-lg-3" style="margin-top: -13px; font-size: 12px">
                <mat-form-field appearance="outline" class="example-full-width heightCls"
                    style="padding: 3px!important;">
                    <mat-label>Select</mat-label>
                    <mat-select placeholder="Select" (selectionChange)="getEnquiries($event.value)">
                        <mat-option  [value]="item"
                            *ngFor="let item of selectType">{{item}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="card-body p-0 marginLeftRight"  *ngIf="!searchNot">
            <div class="table-responsive">
                <table class="table table-bordered table-striped fs--1 mb-0 bg-white">
                    <thead class="bg-200 text-900">
                        <tr class="btn-reveal-trigger">

                            <th class="text-nowrap  text-center">Ticket Id</th>
                            <th class="text-nowrap  text-center">Created On</th>
                            <th class="text-nowrap  text-center">Type</th>
                            <th class="text-nowrap  text-center">Description</th>
                            <th class="text-nowrap  text-center">Status</th>
                            <!-- <th class="text-nowrap  text-center">
                                <mat-form-field class="bg-white" class=" bg-white example-full-width"
                                    style="border-radius: 5px!important; font-size: 13px!important;">
                                    <mat-select placeholder="Select">
                                        <mat-option (onSelectionChange)="getEnquiries($event)" [value]="item"
                                            *ngFor="let item of selectType">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="btn-reveal-trigger" *ngFor="let contact of  contactDetails">
                            <td class="text-center">{{contact.id}}</td>
                            <td class="text-center">{{contact.updatedAt| date:"dd MMM YYYY"| uppercase }}</td>
                            <td class="text-center">{{contact.ticketType}}</td>
                            <td class="text-center">{{contact.description}}</td>
                            <td class="text-center">{{contact.status}}</td>
                        </tr>

                    </tbody>
                    <tbody *ngIf="contactDetails.length == 0">
                        <tr class="text-center">
                            <td colspan="10">
                                <h5 class="text-nowrap">No Tickets Details Added. Please Add Tickets Details To Be
                                    Displayed
                                    Here. <span>
                                        <a class="nav-link" routerLink="/pages/contact-us">Add New Tickets </a>
                                    </span></h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <mat-paginator class="float-right mt-2" *ngIf="contactDetails.length> 0" [pageSize]="partyPageSize"
                    [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo"
                    (page)="handlePage($event)" aria-label="Select page">
                </mat-paginator>
            </div>
        </div>
        <div class="card-body p-0 marginLeftRight"  *ngIf="!searchNot1">
            <div class="table-responsive">
                <table class="table table-bordered table-striped fs--1 mb-0 bg-white">
                    <thead class="bg-200 text-900">
                        <tr class="btn-reveal-trigger">

                            <th class="text-nowrap  text-center">Ticket Id</th>
                            <th class="text-nowrap  text-center">Created On</th>
                            <th class="text-nowrap  text-center">Type</th>
                            <th class="text-nowrap  text-center">Description</th>
                            <th class="text-nowrap  text-center">Status</th>
                            <!-- <th class="text-nowrap  text-center">
                                <mat-form-field class="bg-white" class=" bg-white example-full-width"
                                    style="border-radius: 5px!important; font-size: 13px!important;">
                                    <mat-select placeholder="Select">
                                        <mat-option (onSelectionChange)="getEnquiries($event)" [value]="item"
                                            *ngFor="let item of selectType">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="btn-reveal-trigger" *ngFor="let contact of  contactDetails">
                            <td class="text-center">{{contact.id}}</td>
                            <td class="text-center">{{contact.updatedAt| date:"dd MMM YYYY"| uppercase }}</td>
                            <td class="text-center">{{contact.ticketType}}</td>
                            <td class="text-center">{{contact.description}}</td>
                            <td class="text-center">{{contact.status}}</td>
                        </tr>

                    </tbody>
                    <tbody *ngIf="contactDetails.length == 0">
                        <tr class="text-center">
                            <td colspan="10">
                                <h5 class="text-nowrap">No Tickets Details Added. Please Add Tickets Details To Be
                                    Displayed
                                    Here. <span>
                                        <a class="nav-link" routerLink="/pages/contact-us">Add New Tickets </a>
                                    </span></h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <mat-paginator class="float-right mt-2" *ngIf="contactDetails.length> 0" [pageSize]="partyPageSize"
                    [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo"
                    (page)="handlePagesearch($event)" aria-label="Select page">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>