import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
@Component({
  selector: 'app-add-custom-roles',
  templateUrl: './add-custom-roles.component.html',
  styleUrls: ['./add-custom-roles.component.scss']
})
export class AddCustomRolesComponent implements OnInit {
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public permissionData: any;
  public rolesForm: FormGroup;
  public permissionName: any;
  public isShowMessage: boolean = false;
  public isShowUpdateBtn: boolean = false;
  public roleId: any;
  public isAllChecked: boolean;
  public read: boolean;
  public delete: boolean;
  public create: boolean;
  public modify: boolean;
  public all: boolean;
  public submitted: boolean;

  constructor(private rolePermissionservice: RolesPermissionsService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,

    private router: Router) { }

  ngOnInit(): void {
    this.getPermissions();
    this.rolesForm = this.fb.group({
      RoleName: ["", [Validators.required]],
      Description: ["", [Validators.required]],
      permissionsFormArray: this.fb.array([]),
    });
  }
  /**
   * get controls of permission form array
   */
  get formArr() {
    return this.rolesForm.get('permissionsFormArray') as FormArray;
  }
  get f() {
    return this.rolesForm.controls;
  }
  /**
   * select and deselect all crud row wise
   * @param index 
   * @param checked 
   */
  selectAllCrudOption(index, checked: boolean) {
    if (checked) {
      this.formArr.controls[index].patchValue({
        read: true,
        delete: true,
        modify: true,
        create: true,
      })
    } else {
      this.formArr.controls[index].patchValue({
        read: false,
        delete: false,
        modify: false,
        create: false,
      })
    }
  }
  /**
   * Row wise selecting and deselecting each crud
   * @param index 
   * @param checked 
   */
  selectCrud(index, checked: boolean) {
    if (!checked) {
      this.formArr.controls[index].patchValue({
        all: false
      })
    } else if(checked){
      let values =this.formArr.controls[index].value
        if(values.create && values.modify && values.delete && values.read){
          this.formArr.controls[index].patchValue({
            all: true
          })
        } 
    }
  }

  /**
   * add new row index wise in table
   * @param index 
   * @param id 
   */
  addDetailsButtonClick(index, id): void {
    const content = this.rolesForm.get("permissionsFormArray") as FormArray;
    content.push(this.addRolesPermission(id));
  }
  /**
   * Method for creating foemcontrol for each row in role permission table
   * @param id 
   * @returns 
   */
  addRolesPermission(id): FormGroup {
    return new FormGroup({
      permissionId: new FormControl(id),
      read: new FormControl(false),
      create: new FormControl(false),
      modify: new FormControl(false),
      delete: new FormControl(false),
      all: new FormControl(),
    });
  }

  /**
   * Get roles permission
   */
  public getPermissions() {
    this.rolePermissionservice.getAllPermissions({}).then((res) => {
      this.permissionData = res.data;
      for (let i = 0; i < res.data.length; i++) {
        this.addDetailsButtonClick(i, res.data[i].id)
      }
    })
  }

  /**
   * Add new role with role permission
   */
  addCustomRoles() {
    this.submitted= true;
    if(this.rolesForm.invalid){
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");

    }
    else{
      let data = {
        "roleName": this.rolesForm.controls.RoleName.value,
        "userId": this.currentUser.id,
        "description": this.rolesForm.controls.Description.value,
        "permissionsArray": this.formArr.value.map((item) => {
          return {
            permissionId: item.permissionId,
            read: item.read,
            delete: item.delete,
            create: item.create,
            modify: item.modify,
          }
        })
      }
      this.rolePermissionservice.createCustomRoles(data).then(res => {
        if (res) {
          this.isShowMessage = true;
          setTimeout(() => {
            this.isShowMessage = false
          }, 3000)
          this.rolesForm.reset();
          if (res) {
            this.toastService.toastMsg({
              title: "Success",
              content: "Role Added Successfully!"
            });
            // this.toastService.openSnackBar("Role Added Successfully")
          }
          this.router.navigate(["/pages/roles-list"]);
        }
        } ,
        (err) => {
        if(err.error.expose){
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else{
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
        
      })
    }
   
  }
}
