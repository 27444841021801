import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PartyReportsService } from 'src/app/core/services/party-reports.service';
import { PartyService } from 'src/app/core/services/party.service';
import { PurchaseInvoiceService } from 'src/app/core/services/purchase-invoice.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import * as XLSX from 'xlsx';

interface IRange {
  value: Date[];
  label: string;

}


@Component({
  selector: 'app-party-wise-profit-loss',
  templateUrl: './party-wise-profit-loss.component.html',
  styleUrls: ['./party-wise-profit-loss.component.scss']
})
export class PartyWiseProfitLossComponent implements OnInit {
  public quarterStartDate: any;
  public quarterEndDate: any;
  public today: Date;
  public quarter;
  public maxDate: Date;
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  public partyName = new FormControl();
  public partyId: any;
  public searchPartyName: any;
  public selectedPartyId: any;
  public partyList: any;
  fileName = 'Vendor Wise Profit Loss.xlsx';

  public allPartyList: any = [];
  public profitList: any = [];
  public profitLossTotal: any = [];
  public partyData: any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public filteredOptions: Observable<string[]>;
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public myControl = new FormControl();
  public submitted: boolean = false;
  public dateRange: Date[]
  public minSaleTotalAmount = 0;
  public maxSaleTotalAmount = 0;
  public totalSaleInput = 0;
  public totalPurchaseInput = 0;
  public minProfitLoss = 0;
  public maxProfitLoss = 0;
  public totalProfitInput = 0;
  public valueItem: string= "Equal";
  public totalgst = 0;
  public pagesize: number = 1;
  public totalrow: any;
  public partyPageSize: number = 5;
  public toatlPages: any;
  public p=1;

  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;

  }

  weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
    - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
    ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString()); label: 'Today'
  public monthEnd: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString());
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  public lastEnd: Date = new Date(new Date(new Date().setDate(0)).toDateString());
  public yearStart: Date = new Date(new Date(new Date().getFullYear() - 1, 0, 1).toDateString());
  public yearEnd: Date = new Date(new Date(new Date().getFullYear() - 1, 11, 31).toDateString());


  constructor(private purchaseInvoiceService: PurchaseInvoiceService,
    private partyreportsService: PartyReportsService,
    private partyService: PartyService,
    private saleInvoiceService: SaleInvoiceService,
    private titleCasePipe: TitleCasePipe,
    public toastService: ToastNotificationService,

  ) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    let quarter = moment(this.today).utc().quarter() //outputs 4
    this.getQuarterRange();

  }
  ngOnInit(): void {

    this.getAllParties();
    this.getProfitLossParty();
    this.ranges = [

      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      {
        value: [this.quarterStartDate._d, this.quarterEndDate._d],
        label: ' Last Quarter'
      },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear(), 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];
    this.dateRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]


  }
  getQuarterRange() {
    this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
    this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  }
  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  /* PartyName option*/
  getAllParties() {
    this.partyService
      .getAllPartiesDetails({}, this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
      .then((res) => {
        this.allPartyList = res.data.pageData;
        this.filteredOptions = this.partyName.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
      });
  }
  searchPartyByName(item) {
    this.searchPartyName = item.partyName

    console.log("item", item);
    this.partyId = item.id;
    this.saleInvoiceService.getPartyByName({}, this.currentUser.id, item)
      .then((response: any) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.allPartyList.slice())
            );
        }
      });
  }

  /* collecting all Parties data*/

  getProfitLossParty() {
    this.partyreportsService.getProfitandLoss({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.profitList = res.data.dataset;
      this.partyList = res.data.dataset;
      this.profitLossTotal = res.data;
      console.log(this.profitList);
      this.toatlPages = res.data.totalPages;
      this.totalrow = res.data.totalRows;
      this.p = res.data.currentPage;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }
/* date range wise search*/
  getProfitLossItem() {
    this.partyreportsService.getProfitLossByParty({}, this.currentUser.id,this.partyPageSize, (this.currentPageNo + 1), moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD') ,moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD')).then((res) => {
      this.profitList = res.data.dataset;
      this.profitLossTotal = res.data;
      this.p = res.data.currentPage;
      this.totalrow = res.data.totalRows;
      this.toatlPages = res.data.totalPages

      console.log(this.profitList);

    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }

  /* party name search*/
  setSelectedPartyDeatails1(item) {
    console.log(item)
    this.selectedPartyId = item.id;
    console.log(this.selectedPartyId);

      this.partyreportsService.getProfitLossByPartysearch({}, this.currentUser.id,this.selectedPartyId,this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
        console.log(res)
        this.profitList = res.data.dataset;
        this.profitLossTotal = res.data;
        this.p = res.data.currentPage;
      this.totalrow = res.data.totalRows;
      this.toatlPages = res.data.totalPages
  
        console.log(this.profitList);
  
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })   
  }

  /* false*/
  setSelectedPartyDeatails(item) {
    console.log(item)
    this.selectedPartyId = item.id;
    console.log(this.selectedPartyId)

    this.partyId = item.id;
    if (this.partyData) {
    } else {
      this.saleInvoiceService
        .getPartyByName({}, this.currentUser.id, item.partyName)
        .then((response: any) => {
          this.partyData = response.data.rows;
          this.allPartyList = response.data.rows;
        });
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((name) =>
          name ? this.filterNames(name) : this.allPartyList.slice()
        )
      );
    }
    this.getProfitLossItem();
  }

  onSelectgst(value){
    this.valueItem = value;
    console.log("valueItem", this.valueItem)
    console.log("value", value)
  }

  Applygst(){
    console.log("APPLY CLICK", this.totalgst)
    this.partyreportsService.getProfitLossByPartyTotalgst1({}, this.currentUser.id,this.totalgst).then((res) => {
      this.profitList = res.data.dataset;
      this.profitLossTotal = res.data;

    })
  }

  onSelectRange(value) {
    this.valueItem = value;
  }

  ApplyValue() {
    console.log(this.valueItem)
    if(this.valueItem == "Greater"){
      this.partyreportsService.getProfitLossByPartyTotalSalesGreater({}, this.currentUser.id,this.totalSaleInput).then((res) => {
      this.profitList = res.data.dataset;
      this.profitLossTotal = res.data;

    })
  }
  else if (this.valueItem == "Equal"){
    this.partyreportsService.getProfitLossByPartyTotalSalesEqual({}, this.currentUser.id,this.totalSaleInput).then((res) => {
      this.profitList = res.data.dataset;
      this.profitLossTotal = res.data;

    })
  }
  else{
    this.partyreportsService.getProfitLossByPartyTotalSalesless({}, this.currentUser.id,this.totalSaleInput).then((res) => {
      this.profitList = res.data.dataset;
      this.profitLossTotal = res.data;

    })  
  }
  }

  onSelectPurchase(value) {
    this.valueItem = value;
  }

  ApplyPurchaseValue() {
    console.log("APPLY CLICK", this.totalPurchaseInput, this.totalPurchaseInput, this.totalPurchaseInput)
     if(this.valueItem == "Greater"){
      this.partyreportsService.getProfitLossByPartyTotalPurchaseGreater({}, this.currentUser.id,this.totalPurchaseInput).then((res) => {
        this.profitList = res.data.dataset;
        this.profitLossTotal = res.data;
       })

     }
     else if (this.valueItem == "Equal"){
      this.partyreportsService.getProfitLossByPartyTotalPurchaseEqual({}, this.currentUser.id,this.totalPurchaseInput).then((res) => {
        this.profitList = res.data.dataset;
        this.profitLossTotal = res.data;
       })

     }
     else{
      this.partyreportsService.getProfitLossByPartyTotalPurchaseless({}, this.currentUser.id,this.totalPurchaseInput).then((res) => {
        this.profitList = res.data.dataset;
        this.profitLossTotal = res.data;
       })

     }

  }
  onSelectProfitLoss(value) {
    this.valueItem = value;
  }

  ApplyProfit() {
    if(this.valueItem == "Greater"){
      this.valueItem ="Greater"
      this.partyreportsService.getProfitLossByPartyTotalProfitGreater({}, this.currentUser.id,this.totalProfitInput).then((res) => {
      this.profitList = res.data.dataset;
      this.profitLossTotal = res.data;

    })
  }
  else if (this.valueItem == "Equal"){
    this.valueItem = "Equal"
    this.partyreportsService.getProfitLossByPartyTotalProfitEqual({}, this.currentUser.id,this.totalProfitInput).then((res) => {
      this.profitList = res.data.dataset;
      this.profitLossTotal = res.data;
    })
  }
  else{
    this.partyreportsService.getProfitLossByPartyTotalProfitless({}, this.currentUser.id,this.totalProfitInput).then((res) => {
      this.profitList = res.data.dataset;
      this.profitLossTotal = res.data;

    }) 
  }
  }

  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }

  onPrint() {
    const elementsToHide = ['headingPrintHide', 'dateRangePrintHide', 'searchBarHide','headingPrintHide1'];
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = 'none';
      }
    });

    // Print
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
    // Restore hidden elements after printing
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = '';
      }
    });
  }
  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }
  changeDate(e){
    this.getProfitLossItem();  
    }
    ClearGst(){
      this.getProfitLossParty();
      this.totalgst=0;
      this.valueItem=""
    }
    ClearToatalSaleAmount(){
      this.getProfitLossParty();
      this.valueItem="Equal"
      this.totalSaleInput = 0;
    }
    clearToatalPurchaseAmount(){
      this.getProfitLossParty();
      this.valueItem="Equal"
      this.totalPurchaseInput=0;

    }
    clearProfitLoss(){
      this.getProfitLossParty();
      this.valueItem="Equal"
      this.totalProfitInput=0;

    }

    public handlePage(e: any) {
      console.log(e);
      // this.tableData = [];
      this.currentPageNo = e.pageIndex;
      this.pagesize = e.pageSize;
      if (this.profitList.length > 0) {
        this.partyPageSize = e.pageSize;
        this.getProfitLossParty();      
      }
    }

}
