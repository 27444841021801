import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
 { name: 'themejs', src: '../theme/public/assets/js/theme.js' },
 { name: 'bootstapminjs', src: '../theme/public/vendors/bootstrap/bootstrap.min.js' },
 { name : 'a' , src:"../theme/public/vendors/popper/popper.min.js"},
 { name : 'b' , src:"../theme/public/vendors/anchorjs/anchor.min.js"},
 { name : 'c' , src:"../theme/public/vendors/is/is.min.js"},
 { name : 'd' , src:"../theme/public/vendors/echarts/echarts.min.js"},
 { name : 'f' , src:"../theme/public/vendors/fontawesome/all.min.js"},
 { name : 'e' , src:"../theme/public/vendors/lodash/lodash.min.js"},
 { name : 'g' , src : "https://polyfill.io/v3/polyfill.min.js?features=window.scroll"},
 { name : 'h' , src:"../theme/public/vendors/list.js/list.min.js"},
 { name : 'i' , src:"../theme/public/vendors/choices/choices.min.js"},
 { name : 'j' , src:"../theme/public/assets/js/flatpickr.js"},
 { name : 'k' , src:"../theme/public/vendors/list.js/list.min.js"},
];

declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // if (!this.scripts[name].loaded) {
        if(1){
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
            script.onreadystatechange = () => {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                }
            };
        } else {  //Others
            script.onload = () => {
                this.scripts[name].loaded = true;
                resolve({script: name, loaded: true, status: 'Loaded'});
            };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }
}
