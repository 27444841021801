<main class="main" id="top">
    <div class="container-fluid" data-layout="container-fluid">
        <script>
            var isFluid = JSON.parse(localStorage.getItem("isFluid"));
            if (isFluid) {
                var container = document.querySelector("[data-layout]");
                container.classList.remove("container");
                container.classList.add("container-fluid");
            }
        </script>

        <!-- side bar -->
        <nav class="navbar navbar-light navbar-vertical navbar-expand-xl">
            <script>
                var navbarStyle = localStorage.getItem("navbarStyle");
                if (navbarStyle && navbarStyle !== "transparent") {
                    document
                        .querySelector(".navbar-vertical")
                        .classList.add(`navbar-${navbarStyle}`);
                }
            </script>
            <div class="d-flex align-items-center">
                <div class="toggle-icon-wrapper">
                    <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle" data-bs-toggle="tooltip"
                        data-bs-placement="left" title="Toggle Navigation">
                        <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
                    </button>
                </div>
                <a class="navbar-brand">
                    <div class="d-flex align-items-center py-3">
                        <img class="me-2" src="assets/img/icons/spot-illustrations/new-logo-iceipt-blue.png" alt=""
                            width="42" />
                            <!-- <span class="font-sans-serif">iCeipts</span> -->
                    </div>
                </a>
            </div>
            <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
                <div class="navbar-vertical-content scrollbar">
                    <ul class="navbar-nav flex-column mb-3" id="navbarVerticalNav">
                        <!-- parent pages Dashboard-->
                        <li class="nav-item">
                            <a class="nav-link" role="button" routerLink="/pages/customer/customer-dashboard"
                                aria-expanded="true" aria-controls="dashboard">
                                <div class="d-flex align-items-center">
                                    <span class="nav-link-icon"><span class="fas fa-chart-pie"></span></span><span
                                        class="nav-link-text ps-1">Dashboard</span>
                                </div>
                            </a>
                        </li>

                        <!-- parent pages Parties-->

                        <li class="nav-item">
                            <div class="row navbar-vertical-label-wrapper mt-3 mb-2">
                                <div class="col-auto navbar-vertical-label">Pages</div>
                                <div class="col ps-0">
                                    <hr class="mb-0 navbar-vertical-divider" />
                                </div>
                            </div>
                            <a class="nav-link dropdown-indicator" href="#remoteshop" role="button"
                                data-bs-toggle="collapse" aria-expanded="false" aria-controls="remoteshop">
                                <div class="d-flex align-items-center">
                                    <span class="nav-link-icon"><span class="bi bi-people-fill"></span></span><span
                                        class="nav-link-text ps-1">Remote Shopping</span>
                                </div>
                            </a>
                        </li>
                        <ul class="nav collapse" id="remoteshop">
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/pages/customer/remote-customer"
                                    routerLinkActive="active" data-bs-toggle="" aria-expanded="false">
                                    <div class="d-flex aligh-items-left">
                                        <span class="nav-link-text ps-1">All Retailer</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLinkActive="active"
                                    routerLink="/pages/customer/order-receipts" data-bs-toggle="" aria-expanded="false">
                                    <div class="d-flex aligh-items-left">
                                        <span class="nav-link-text ps-1">My Orders</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLinkActive="active" routerLink="/pages/customer/bidding"
                                    data-bs-toggle="" aria-expanded="false">
                                    <div class="d-flex aligh-items-left">
                                        <span class="nav-link-text ps-1">Bidding</span>
                                    </div>
                                </a>
                            </li>
                            <!-- <li class="nav-item">
                                <a class="nav-link" routerLinkActive="active"
                                    routerLink="/pages/customer/bidding-details"
                                    data-bs-toggle="" aria-expanded="false">
                                    <div class="d-flex aligh-items-left">
                                        <span class="nav-link-text ps-1">Bidding Details</span>
                                    </div>
                                </a>
                            </li> -->
                        </ul>
                        <!-- -------parent page Appointment------- -->

                        <li class="nav-item">
                            <a class="nav-link" role="button" routerLink="/pages/customer/customer-appointment"
                                aria-expanded="true" aria-controls="appointment">
                                <div class="d-flex align-items-center">
                                    <span class="nav-link-icon"><span class="fas fa-calendar-check"></span></span><span
                                        class="nav-link-text ps-1">Appointment</span>
                                </div>
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#Expenses" role="button" data-bs-toggle="collapse"
                                aria-expanded="true" aria-controls="expenses">
                                <div class="d-flex align-items-center">
                                    <span class="nav-link-icon"><span class="bi bi-cash"></span></span><span
                                        class="nav-link-text ps-1">Expenses</span>
                                </div>
                            </a>
                        </li> -->
                        <!-- -------parent page Bank and Cash------- -->


                        <!-- parent pages Help & FAQs-->
                        <!-- <li class="nav-item">
                            <div class="row navbar-vertical-label-wrapper mt-3 mb-2">
                                <div class="col-auto navbar-vertical-label">Help</div>
                                <div class="col ps-0">
                                    <hr class="mb-0 navbar-vertical-divider" />
                                </div>
                            </div>
                            <a class="nav-link" role="button" data-bs-toggle="collapse"
                                aria-expanded="true" aria-controls="settings">
                                <div class="d-flex align-items-center">
                                    <span class="nav-link-icon"><span class="fas fa-question-circle"></span></span><span
                                        class="nav-link-text ps-1">Help and FAQs</span>
                                </div>
                            </a>
                        </li> -->

                        <!-- parent pages Feedback-->
                        <!-- <li class="nav-item">
                            <a class="nav-link" role="button" data-bs-toggle="collapse"
                                aria-expanded="true" aria-controls="feedback">
                                <div class="d-flex align-items-center">
                                    <span class="nav-link-icon"><span class="bi bi-emoji-smile-fill"></span></span><span
                                        class="nav-link-text ps-1">Feedback</span>
                                </div>
                            </a>
                        </li> -->

                        <!-- parent pages Referal Code-->
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#rCode" role="button" data-bs-toggle="collapse"
                                aria-expanded="true" aria-controls="rCode">
                                <div class="d-flex align-items-center">
                                    <span class="nav-link-icon"><span class="bi bi-key-fill"></span></span><span
                                        class="nav-link-text ps-1">Referal Code</span>
                                </div>
                            </a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </nav>

        <div class="content">
            <!-- Header -->
            <nav class="navbar navbar-light navbar-glass navbar-top navbar-expand">
                <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button"
                    data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse"
                    aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation">
                    <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
                </button>
                <a class="navbar-brand me-1 me-sm-3">
                    <div class="d-flex align-items-center">
                        <img class="me-2" src="assets/img/icons/spot-illustrations/new-logo-iceipt-blue.png" alt=""
                            width="40" /><span class="font-sans-serif">iCeipts</span>
                    </div>
                </a>
                <ul class="navbar-nav align-items-center d-none d-lg-block">
                    <li class="nav-item">
                        <!-- <div class="search-box" data-list='{"valueNames":["title"]}'>
                            <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                                <input class="form-control search-input fuzzy-search" type="search"
                                    placeholder="Search..." aria-label="Search" />
                                <span class="fas fa-search search-box-icon"></span>
                            </form>
                            <div class="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none"
                                data-bs-dismiss="search">
                                <div class="btn-close-falcon" aria-label="Close"></div>
                            </div>
                            <div class="dropdown-menu border font-base start-0 mt-2 py-0 overflow-hidden w-100">
                                <div class="scrollbar list py-3" style="max-height: 24rem">
                                    <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                        Recently Browsed
                                    </h6>
                                    <a class="dropdown-item fs--1 px-card py-1 hover-primary"
                                        href="app/events/event-detail.html">
                                        <div class="d-flex align-items-center">
                                            <span class="fas fa-circle me-2 text-300 fs--2"></span>

                                            <div class="fw-normal title">
                                                Pages
                                                <span class="fas fa-chevron-right mx-1 text-500 fs--2"
                                                    data-fa-transform="shrink-2"></span> Events
                                            </div>
                                        </div>
                                    </a>
                                    <a class="dropdown-item fs--1 px-card py-1 hover-primary"
                                        routerLink="/pages/customer-list">
                                        <div class="d-flex align-items-center">
                                            <span class="fas fa-circle me-2 text-300 fs--2"></span>

                                            <div class="fw-normal title">
                                                E-commerce
                                                <span class="fas fa-chevron-right mx-1 text-500 fs--2"
                                                    data-fa-transform="shrink-2"></span> Customers
                                            </div>
                                        </div>
                                    </a>

                                    <hr class="bg-200 dark__bg-900" />
                                    <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                        Suggested Filter
                                    </h6>
                                    <a class="dropdown-item px-card py-1 fs-0" href="app/e-commerce/customers.html">
                                        <div class="d-flex align-items-center">
                                            <span
                                                class="badge fw-medium text-decoration-none me-2 badge-soft-warning">customers:</span>
                                            <div class="flex-1 fs--1 title">All customers list</div>
                                        </div>
                                    </a>
                                    <a class="dropdown-item px-card py-1 fs-0" href="app/events/event-detail.html">
                                        <div class="d-flex align-items-center">
                                            <span
                                                class="badge fw-medium text-decoration-none me-2 badge-soft-success">events:</span>
                                            <div class="flex-1 fs--1 title">
                                                Latest events in current month
                                            </div>
                                        </div>
                                    </a>
                                    <a class="dropdown-item px-card py-1 fs-0"
                                        href="app/e-commerce/product/product-grid.html">
                                        <div class="d-flex align-items-center">
                                            <span
                                                class="badge fw-medium text-decoration-none me-2 badge-soft-info">products:</span>
                                            <div class="flex-1 fs--1 title">
                                                Most popular products
                                            </div>
                                        </div>
                                    </a>

                                    <hr class="bg-200 dark__bg-900" />
                                    <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                        Files
                                    </h6>
                                    <a class="dropdown-item px-card py-2" href="#!">
                                        <div class="d-flex align-items-center">
                                            <div class="file-thumbnail me-2">
                                                <img class="border h-100 w-100 fit-cover rounded-3"
                                                    src="assets/img/products/3-thumb.png" alt="" />
                                            </div>
                                            <div class="flex-1">
                                                <h6 class="mb-0 title">iPhone</h6>
                                                <p class="fs--2 mb-0 d-flex">
                                                    <span class="fw-semi-bold">Antony</span><span
                                                        class="fw-medium text-600 ms-2">27 Sep at 10:30
                                                        AM</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="dropdown-item px-card py-2" href="#!">
                                        <div class="d-flex align-items-center">
                                            <div class="file-thumbnail me-2">
                                                <img class="img-fluid" src="assets/img/icons/zip.png" alt="" />
                                            </div>
                                            <div class="flex-1">
                                                <h6 class="mb-0 title">Falcon v1.8.2</h6>
                                                <p class="fs--2 mb-0 d-flex">
                                                    <span class="fw-semi-bold">John</span><span
                                                        class="fw-medium text-600 ms-2">30 Sep at 12:30
                                                        PM</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>

                                    <hr class="bg-200 dark__bg-900" />
                                    <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                        Members
                                    </h6>
                                    <a class="dropdown-item px-card py-2" href="pages/user/profile.html">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar avatar-l status-online me-2">
                                                <img class="rounded-circle" src="assets/img/team/1.jpg" alt="" />
                                            </div>
                                            <div class="flex-1">
                                                <h6 class="mb-0 title">Anna Karinina</h6>
                                                <p class="fs--2 mb-0 d-flex">Technext Limited</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="dropdown-item px-card py-2" href="pages/user/profile.html">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar avatar-l me-2">
                                                <img class="rounded-circle" src="assets/img/team/2.jpg" alt="" />
                                            </div>
                                            <div class="flex-1">
                                                <h6 class="mb-0 title">Antony Hopkins</h6>
                                                <p class="fs--2 mb-0 d-flex">Brain Trust</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="dropdown-item px-card py-2" href="pages/user/profile.html">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar avatar-l me-2">
                                                <img class="rounded-circle" src="assets/img/team/3.jpg" alt="" />
                                            </div>
                                            <div class="flex-1">
                                                <h6 class="mb-0 title">Emma Watson</h6>
                                                <p class="fs--2 mb-0 d-flex">Google</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="text-center mt-n3">
                                    <p class="fallback fw-bold fs-1 d-none">No Result Found.</p>
                                </div>
                            </div>
                        </div> -->
                    </li>
                </ul>

                <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
                    <!-- <li class="nav-item">
                        <div class="theme-control-toggle fa-icon-wait px-2">
                            <input class="form-check-input ms-0 theme-control-toggle-input" id="themeControlToggle"
                                type="checkbox" data-theme-control="theme" value="dark" />
                            <label class="mb-0 theme-control-toggle-label theme-control-toggle-light"
                                for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left"
                                title="Switch to light theme">
                                <i class="fas fa-sun fs-0"></i></label>
                            <label class="mb-0 theme-control-toggle-label theme-control-toggle-dark"
                                for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left"
                                title="Switch to dark theme"><i class="fas fa-moon fs-0"></i></label>
                        </div>
                    </li> -->
                    <li class="nav-item d-none d-sm-block">
                        <a class="nav-link px-0 notification-indicator notification-indicator-warning notification-indicator-fill fa-icon-wait"
                            routerLink="/pages/customer/my-cart">
                            <span class="fas fa-shopping-cart"
                                data-fa-transform="shrink-7" style="margin-right: 5px; font-size: 27px">
                            </span>
                            <span class="notification-indicator-number">{{messages}}</span></a>
                    </li>
                    <li class="nav-item dropdown">
                        <a *ngIf="!isShow" style="color: var(--falcon-navbar-light-color)"
                            id="navbarDropdownNotification" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" data-hide-on-body-scroll="data-hide-on-body-scroll">
                            <span class="fas fa-bell" data-fa-transform="shrink-6" style="font-size: 30px"></span>
                        </a>
                        <a class="nav-link notification-indicator notification-indicator-primary px-0 fa-icon-wait"
                            *ngIf="isShow" id="navbarDropdownNotification" role="button" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false"
                            data-hide-on-body-scroll="data-hide-on-body-scroll">
                            <span class="fas fa-bell" data-fa-transform="shrink-6" style="font-size: 30px"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
                            aria-labelledby="navbarDropdownNotification">
                            <div class="card card-notification shadow-none">
                                <div class="card-header">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-auto">
                                            <h5 class="card-header-title mb-0">Notifications</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="scrollbar-overlay" style="max-height: 19rem">
                                    <div class="list-group list-group-flush fw-normal fs--1">
                                        <div class="notification-body list-group-title border-bottom text-center p-2" *ngIf="isData">
                                            No notifications recevied yet!!!!
                                        </div>
                                        <div *ngIf="isData && !isShow">
                                            <div class="list-group-item" *ngFor="let i of notificationReadArray">
                                                <a class="notification notification-flush notification-unread">
                                                    <div class="notification-avatar">
                                                        <div class="avatar avatar-2xl me-3">
                                                            <!-- <img class="rounded-circle"
                                                                src="assets/img/team/1-thumb.png" alt="" /> -->
                                                        </div>
                                                    </div>
                                                    <div class="notification-body">
                                                        <strong>{{i.title}}</strong>
                                                        <p class="mb-1">
                                                            {{i.msg}}
                                                        </p>
                                                        <span class="notification-time"><span class="me-2" role="img"
                                                                aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                        </span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div *ngIf="isData && isShow">
                                            <div class="list-group-item" *ngFor="let i of notificationUnreadArray">
                                                <a class="notification notification-flush notification-unread">
                                                    <div class="notification-avatar">
                                                        <div class="avatar avatar-2xl me-3">
                                                            <!-- <img class="rounded-circle"
                                                                src="assets/img/team/1-thumb.png" alt="" /> -->
                                                        </div>
                                                    </div>
                                                    <div class="notification-body">
                                                        <strong>{{i.title}}</strong>
                                                        <p class="mb-1">
                                                            {{i.msg}}
                                                        </p>
                                                        <span class="notification-time"><span class="me-2" role="img"
                                                                aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                        </span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer text-center border-top" *ngIf="!isData">
                                    <a class="card-link d-block" routerLink="/pages/notifications">View all</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link pe-0 ps-2" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <div class="avatar avatar-xl">
                                <img class="rounded-circle" [src]="imageSrc" alt="" />
                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
                            <div class="bg-white dark__bg-1000 rounded-2 py-2">
                                <!-- <a class="dropdown-item fw-bold text-warning" routerLink="/auth/subscription"><span
                                        class="fas fa-crown me-1"></span><span>Go
                                        Pro</span></a>

                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item">Set status</a> -->
                                <a class="dropdown-item" routerLink="/pages/customer/customer-profile">Profile &amp;
                                    account</a>
                                <!-- <a class="dropdown-item">Feedback</a> -->

                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item"
                                    routerLink="/pages/customer/customer-profile-setting">Settings</a>
                                <a class="dropdown-item" routerLink="/auth/role-selection">Logout</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>

            <router-outlet></router-outlet>
            <!-- Dashboard cards -->

            <!-- Footer -->
            <!-- <footer class="footer">
                <div class="row g-0 justify-content-between fs--1 mt-4 mb-3">
                    <div class="col-12 col-sm-auto text-center">
                        <p class="mb-0 text-600">
                            Thank you for creating with Falcon
                            <span class="d-none d-sm-inline-block">| </span><br class="d-sm-none" /> 2022 &copy; <a href="https://themewagon.com">Themewagon</a>
                        </p>
                    </div>
                    <div class="col-12 col-sm-auto text-center">
                        <p class="mb-0 text-600">v3.7.0</p>
                    </div>
                </div>
            </footer> -->
        </div>
    </div>
</main>
