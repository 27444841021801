import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TimesheetService {

  private timeAddedSource = new Subject<void>();
  timeAdded$ = this.timeAddedSource.asObservable();

  notifytimeAdded() {
    this.timeAddedSource.next();
  }

  constructor(public httpClient: HttpService) { }

  // {{APISERVER}}/addTime/addTime/{{userId}}
  addTime(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addTime + userId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  editTime(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.editTime + userId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // {{APISERVER}}/addTime/getDailyTime/{{userId}}
  getDailyTime(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getDailyTime + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getDailyTimesearch(data: {}, userId,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getDailyTimesearch + userId + "?search="+search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getDailyweeksearch(data: {}, userId,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getweekTimesearch + userId + "?projectName="+search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // {{APISERVER}}/addTime/getDailyTime/{{userId}}
  getWeeklyTime(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getweeklyTime + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // {{APISERVER}}/addTime/getTimeSheetById/timeId
  getTimeSheetById(data: {}, timeId: string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getTimeSheetById + timeId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //Leave Approval list
  getleaveapprovalList(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.timeleaveapproval + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getleaveapprovalSearch(data: {}, id, search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.timeleaveapprovalsearch + id +"?search="+search , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //Leave Approval Delete
  deleteapprovalDeleteById(data: {}, approvalId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteapproval + approvalId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //Leave Approval status
  approvalstatusById(data: {}, userId, leaveId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.satusapproval + userId + "?leaveId=" + leaveId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // leave setting {{APISERVER}}/employees/assignLeaves/{{userId}}
  getleavesetting(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.settingleave + userId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getleavesettingupdate(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.settingleaveupdate + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllLeaveDaysDetails(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.empgetAllleavedays + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  deleteTimeDeleteById(data: {}, deletetimeId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deletetime + deletetimeId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  multideleteTimeDeleteById(data: {}, Id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.multideletetime + Id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getcollectimeById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deletetimeedit + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
