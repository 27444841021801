<div class="block widget p-3">
  <div class="block-container">
    <div class="leftSection">
      <span class="block-headers"> Total Sales </span>
      <span class="block-value">
        {{ (todaySales ? todaySales : 0) | number : "1.0-2" }}
      </span>
    </div>
    <div>
      <span class="rounded-circle" style="margin: 0 4px">
        <img
          src="assets/img/dashboard-party-profiles/total-sales.png"
          alt="Total Sales"
          width="40"
          height="40"
        />
      </span>
    </div>
  </div>
  <div class="lowerSection">
    <!-- <span style = "color: #00B69B;"> -->
    <span
      [ngStyle]="{
        color: percentageCount >= 0 ? '#00B69B' : '#FF0000'
      }"
    >
      <img
        src="assets/img/dashboard-party-profiles/{{
          percentageCount >= 0 ? 'profit.png' : 'loss.png'
        }}"
        alt="Total Sales"
        style="width: 15px; height: 15px"
      />
      <!-- <img src="assets/img/dashboard-party-profiles/profit.png" alt = "Total Sales" style = "width: 15px;height: 15px;"> -->
      {{ (percentageCount ? percentageCount : 0) | number : "1.0-2" }}%
    </span>
    <span *ngIf="percentageCount >= 0">Up</span>
    <span *ngIf="percentageCount < 0">Down</span>
    from yesterday
  </div>
</div>
