import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-new-customers',
  templateUrl: './new-customers.component.html',
  styleUrls: ['./new-customers.component.scss'],
})
export class NewCustomersComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  newPartiesJoinedCount: any;
  newPartiesPercent: any;
  newPartiesList: any[];

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.getNewParties();
  }
  getNewParties() {
    // need to be integrate
    this.dashboardService.getNewParties({}, this.currentUser.id).then(
      (res: any) => {
        if (res.data) {
          // // console.log(res.data);
          this.newPartiesJoinedCount = res.data.todayCount;
          this.newPartiesPercent = res.data.percentageChange;
        }
      },
      (err) => {
        if (err.error.status == 404) {
          this.newPartiesList = [];
        }
      }
    );
  }
}
