<div class="block widget p-3">
  <div class="block-container">
    <div class="leftSection">
      <span class="block-headers"> Total Net Profit </span>
      <span class="block-value">
        {{ (netProfit ? netProfit : 0) | amountFormat }}
      </span>
    </div>
    <div>
      <span class="rounded-circle" style="margin: 0 4px">
        <!-- <img src="assets/img/dashboard-party-profiles/Group 1000002808.svg"> -->
        <img
          src="assets/img/dashboard-party-profiles/net-profit-1.png"
          alt="Total Sales"
          width="40"
          height="40"
        />
      </span>
    </div>
  </div>
  <div style="font-family: 'Poppins', sans-serif">
    <span
      [ngStyle]="{
        color: todaysProductSoldPercent >= 0 ? '#00B69B' : '#FF0000'
      }"
    >
      <!-- <img src="assets/img/dashboard-party-profiles/profit.png" alt = "Total Sales" width = "10" height = "10"> -->
      <img
        src="assets/img/dashboard-party-profiles/{{
          todaysProductSoldPercent >= 0 ? 'profit.png' : 'loss.png'
        }}"
        alt="Total Sales"
        style="width: 15px; height: 15px"
      />
      {{
        (todaysProductSoldPercent ? todaysProductSoldPercent : 0)
          | number : "1.0-2"
      }}%
    </span>
    <span *ngIf="todaysProductSoldPercent >= 0">Up</span>
    <span *ngIf="todaysProductSoldPercent < 0">Down</span>
    from yesterday
  </div>
</div>
