<form [formGroup]="CashInHandForm">
    <div class="card mb-3">
        <div class="card-header bg-light pt-3 pb-3">
            <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
                Cash Adjustment
            </p>
        </div>
        <div class="progress" style="height: 2px">
            <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>
        <div class="card-body">
            <!-- Current Balance -->
            <hr data-content="Current Balance" class="fw-bold">
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4  mb-3">
                    <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width"
                        appearance="outline">
                        <mat-label>Current Balance</mat-label>
                        <input matInput placeholder="Current Balance" formControlName="currentBal">
                    </mat-form-field>
                </div>
            </div>

            <!-- Enter Invoice Details -->
            <hr data-content="Enter Invoice Details" class="fw-bold">
            <div class="row">
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label for="Date"> Date</mat-label>
                        <input matInput required ngModel bsDatepicker formControlName="date" placeholder="Date."
                        style="text-transform:uppercase!important;" [(ngModel)]="todaysDate" id="datePicker" bsDatepicker [bsConfig]="{
                containerClass: 'theme-dark-blue',
                dateInputFormat: 'DD MMM YYYY',
                showWeekNumbers:false
              }" (click)="openDatepicker()">
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>

                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-3 col-lg-3">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Payment Type</mat-label>
                        <mat-select matNativeControl required (selectionChange)="OnChange($event.value)"
                            formControlName="paymentType" [ngClass]="{
                                'is-invalid': (f.paymentType.invalid && f.paymentType.touched) && submitted }">
                            <mat-option value="RECEIPT">RECEIPT</mat-option>
                            <mat-option value="PAYMENT">PAYMENT</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.paymentType.touched) && f.paymentType.errors?.required">
                        Payment Type Is Required!
                    </mat-error>

                </div>

                <div class="col-sm-12 col-md-3 col-lg-3">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Sale Invoice No</mat-label>
                        <input type="text" matInput placeholder="Sale Invoice No"
                            (change)="GetpartyId($event.target.value)" formControlName="saleInvoiceNo">
                    </mat-form-field>
                    <!-- <mat-error class="text-danger"
                        *ngIf="(submitted || f.saleInvoiceNo.touched) && f.saleInvoiceNo.errors?.required">
                        Sale Invoice No Is Required!
                    </mat-error> -->
                </div>

                <div class="col-sm-12 col-md-3 col-lg-3">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Enter Amount</mat-label>
                        <input type="number" matInput placeholder="Enter Amount" formControlName="enterAmount" min="0" oninput="this.value = Math.abs(this.value)"
                         [ngClass]="{
                            'is-invalid': (f.enterAmount.invalid && f.enterAmount.touched) && submitted }">
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.enterAmount.touched) && f.enterAmount.errors?.required">
                        Enter Amount Is Required!
                    </mat-error>
                </div>
            </div>

            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-9 col-lg-10"></div>
                <div class="col-sm-12 col-md-3 col-lg-2" style="text-align: center;">
                    <button class="btn btn-outline-primary mt-2" type="button" data-bs-toggle="modal"
                        data-bs-target="#error-modal">Denomination</button>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="error-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 500px">
                    <div class="modal-content position-relative">
                        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-0">
                            <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                                <h4 class="mb-1" id="modalExampleDemoLabel">Denomination</h4>
                            </div>
                            <div class="p-4 pb-0">
                                <table class="table table-bordered">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col">Denomination</th>
                                            <th scope="col">Count</th>
                                            <th scope="col">Total</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input #firsttwothousand readonly matInput
                                                    formControlName="denomination" type="number" /> </td>
                                            <td><input #secondtwothousand matInput name="valueb"
                                                    formControlName="noOfPieces" type="number" min="0" oninput="this.value = Math.abs(this.value)"/></td>
                                            <td><input #totaltwothousand formControlName="total"
                                                    [value]="(+firsttwothousand.value * +secondtwothousand.value)"
                                                    readonly matInput type="number" /></td>
                                        </tr>

                                        <tr>
                                            <td><input #firstfivehundred readonly matInput formControlName="fivehundred"
                                                    type="number" /> </td>
                                            <td><input #secondfivehundred matInput name="valueb"
                                                    formControlName="nofivehundred" type="number" min="0" oninput="this.value = Math.abs(this.value)"/></td>
                                            <td><input #totalfivehundred formControlName="totalfivehundred"
                                                    [value]="(+firstfivehundred.value * +secondfivehundred.value)"
                                                    readonly matInput type="number" /></td>
                                        </tr>

                                        <tr>
                                            <td><input #firsthundred matInput readonly formControlName="hundred"
                                                    type="number" matInput /> </td>
                                            <td><input #secondhundred matInput name="valueb" formControlName="nohundred"
                                                    type="number" min="0" oninput="this.value = Math.abs(this.value)"/></td>
                                            <td><input #totalhundred matInput readonly formControlName="totalhundred"
                                                    [value]="(+firsthundred.value * +secondhundred.value)"
                                                    name="mulvalue" type="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><input #firstfifty matInput readonly formControlName="fifty"
                                                    type="number" matInput /> </td>
                                            <td><input #secondfifty matInput formControlName="nofifty" name="valueb"
                                                    type="number" min="0" oninput="this.value = Math.abs(this.value)"/>
                                            </td>
                                            <td><input #totalfifty matInput readonly formControlName="totalfifty"
                                                    [value]="(+firstfifty.value * +secondfifty.value)" name="mulvalue"
                                                    type="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><input #firsttwenty matInput readonly formControlName="twenty"
                                                    type="number" matInput /> </td>
                                            <td><input #secondtwenty matInput formControlName="notwenty" name="valueb"
                                                    type="number" min="0" oninput="this.value = Math.abs(this.value)"/>
                                            </td>
                                            <td><input #totaltwenty matInput readonly formControlName="totaltwenty"
                                                    [value]="(+firsttwenty.value * +secondtwenty.value)" name="mulvalue"
                                                    type="text" value="" /></td>
                                        </tr>
                                        <tr>
                                            <td><input #firstten matInput readonly formControlName="ten" type="number"
                                                    matInput /> </td>
                                            <td><input #secondten matInput formControlName="noten" type="number" min="0" oninput="this.value = Math.abs(this.value)"/>
                                            </td>
                                            <td><input #totalten matInput readonly formControlName="totalten"
                                                    [value]="(+firstten.value * +secondten.value)" name="mulvalue"
                                                    type="text" value="" /></td>
                                        </tr>
                                        <tr>
                                            <td><input #firstfive matInput readonly name="valuea" formControlName="five"
                                                    type="number" matInput /> </td>
                                            <td><input #secondfive matInput formControlName="nofive" type="number" min="0" oninput="this.value = Math.abs(this.value)"/>
                                            </td>
                                            <td><input #totalfive matInput readonly formControlName="totalfive"
                                                    [value]="(+firstfive.value * +secondfive.value)" name="mulvalue"
                                                    type="text" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Grand Total</th>
                                            <th scope="col"></th>
                                            <th scope="col"><input matInput readonly formControlName="grandTotal" #myLocalRef
                                                    [value]="(+totaltwothousand.value + +totalfivehundred.value + +totalhundred.value + +totalfifty.value + +totaltwenty.value + +totalten.value + +totalfive.value)"
                                                    name="mulvalue" type="number" /></th>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row p-3">
                                    <div class="col-6 d-grid gap-2"></div>
                                    <div class="col-6 d-grid gap-2">
                                        <button class="btn btn-outline-primary btn-block" id="gray-btn" type="button"
                                            data-bs-dismiss="modal" (click)="tramsperValue()">Done</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- New Balance -->
            <hr data-content="New Balance" class="fw-bold">
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>New Amount</mat-label>
                        <input matInput placeholder="New balance"  type="number"formControlName="NewAmount" min="0" oninput="this.value = Math.abs(this.value)"
                        [ngClass]="{
                            'is-invalid': (f.NewAmount.invalid && f.NewAmount.touched) && submitted }">
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.NewAmount.touched) && f.NewAmount.errors?.required">
                        New Amount Is Required!
                    </mat-error>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>New Balance</mat-label>
                        <input  type="number" matInput placeholder="New balance" formControlName="NewBalance" min="0" oninput="this.value = Math.abs(this.value)"
                        [ngClass]="{
                            'is-invalid': (f.NewAmount.invalid && f.NewAmount.touched) && submitted }">
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.NewBalance.touched) && f.NewBalance.errors?.required">
                        New Balance Is Required!
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end mb-3">
            <div class="m-2">
                <a class="btn btn-outline-primary" (click)="clearInput()">Cancel</a>
            </div>
            <div class="m-2">
                <a class="btn btn-outline-primary" type="submit" (click)="save()">Save</a>
            </div>
        </div>
    </div>
</form>