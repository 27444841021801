<div class="card">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Remote Order
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body p-1 overflow-auto">

        <div class="row mt-2">

            <div class="col-lg-3 col-sm-12 col-md-3 col-xl-3">
                <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                  <mat-label>Invoice Date</mat-label>
                  <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
                    style="text-transform:uppercase!important;" [(ngModel)]="todaysDate" id="datePicker"
                    (ngModelChange)="changeDate($event)" bsDatepicker
                    [bsConfig]="{
                  containerClass: 'theme-dark-blue',
                  dateInputFormat: 'DD MMM YYYY',
                  showWeekNumbers:false }" [ngModelOptions]="{ standalone: true }">
                  <span>
                    <mat-icon class="iconcalender p-1">date_range</mat-icon>
                  </span>
                </mat-form-field>

            </div>

            <div class="col-lg-3 col-sm-12 col-md-3 col-xl-3" style="margin-left: 2px !important; font-size: 12px">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Party Name</mat-label>
                    <input type="text" matInput placeholder="Party Name" [(ngModel)]="searchKey"
                        (change)="getAllRemoteOrderSearch(searchKey)">
                    <span>
                        <mat-icon class="iconcalender p-1">search</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>

        <div class="bg-white">
            <div class="table-responsive">
                <table class="table table-striped table-bordered">
                    <thead class="bg-200 text-900">
                        <tr class="btn-reveal-trigger">
                            <th class="text-nowrap">#</th>
                            <th class="text-nowrap">Date of Order</th>
                            <th class="text-nowrap">Party Name</th>
                            <th class="text-nowrap">Contact #</th>
                            <th class="text-nowrap">Address</th>
                            <th class="text-nowrap">Base Amount</th>
                            <th class="text-nowrap">GST</th>
                            <th class="text-nowrap">Invoice Value</th>
                            <th class="text-nowrap">Actions</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr *ngFor="let item of orderData; let i = index">
                            <td>{{i+1}}</td>
                            <td>{{item.createdAt| date:"dd MMM YYYY"| uppercase}}</td>
                            <td class="text-nowrap">{{item.customerName | titlecase}}</td>
                            <td>{{item.mobile}}</td>
                            <td>{{item.billingAddress}}</td>
                            <td>{{item.totalBillAmount - item.billGstAmount | currency:'INR':'symbol':'1.2-2' }}</td>
                            <td>{{item.billGstAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                            <td>{{item.totalBillAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                            <td class="text-center">
                                <i class="fas fa-receipt" routerLink="/pages/sales-invoice/{{item.orderId}}"></i>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf='orderData == 0'>
                        <tr class="text-center">
                            <td colspan="10">
                                <h5 class="text-nowrap">Remote orders will be Displayed Here, Once Generated<span>
                                    </span></h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
