import { TitleCasePipe } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as moment from "moment";
import { CampaignServiceService } from "src/app/core/services/campaign-service.service";
import { CommonService } from "src/app/core/services/common.service";
import { CrmService } from "src/app/core/services/crm.service";
import { PartyService } from "src/app/core/services/party.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
// Third Party Module Imports
// import moment from 'moment';
// import * as moment from 'moment';
// import 'moment-timezone';

@Component({
  selector: "app-add-campaign",
  templateUrl: "./add-campaign.component.html",
  styleUrls: ["./add-campaign.component.scss"],
})
export class AddCampaignComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  campForm: FormGroup;
  public submitted: boolean;
  partyList: any[] = [];
  selectAllParties: boolean = false;
  selectedParties: any[] = []; // Array to store selected parties
  public todaysDate;
  public today: Date;
  public maxDate: Date;
  minDate: Date = new Date();  // Define minDate as today's date
  date = new Date();
  public selectedYear: number;
  public years: number[] = [];
  selectedTime: { hour: number; minute: number } = { hour: 0, minute: 0 };
  selectedDateTime: any;
  isActive: boolean = true;
  toList: any[] = [];
  currentTime: string;
  // currentTime: Date = new Date();
  intervalId: any;


  constructor(
    private formBuilder: FormBuilder, private crmservice: CrmService,
    public router: Router, private commonService: CommonService,
    private partyService: PartyService,
    public campService: CampaignServiceService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddCampaignComponent>,
    private dialog: MatDialog,
    private cdref: ChangeDetectorRef
    ) {}

  ngOnInit(): void {
    this.todaysDate = new Date();
    this.minDate = new Date(this.todaysDate.getFullYear(), this.todaysDate.getMonth(), 25);
    this.getAllParties();
    this.campForm = this.formBuilder.group({
      campaignName: ["", [Validators.required]],
      campaignDescription: ["", [Validators.required]],
      partySelection: [[], Validators.required],
      sendEmail: [false],
      sendSMS: [false],
      sendNotification: [false],
      sendWhatsappMsg: [false],
      selectedTime: [null, Validators.required],
      selectedDate: [null, Validators.required],
    });

    this.intervalId = setInterval(() => {
      this.today = new Date();
      //this.minDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
      this.today.setSeconds(this.today.getSeconds() + 40);
      const hours = this.today.getHours().toString().padStart(2, '0');
      const minutes = this.today.getMinutes().toString().padStart(2, '0');
      const seconds = this.today.getSeconds().toString().padStart(2, '0');
      this.currentTime = `${hours}:${minutes}`;
      this.onTimeChange() ;
    }, 1000);
  }

  onTimeChange() {
    const selectedTime = this.campForm.get('selectedTime').value;
    if (selectedTime) {
        const [hours, minutes] = selectedTime.split(':').map(Number);
        const selectedDate = new Date();
        selectedDate.setHours(hours);
        selectedDate.setMinutes(minutes);
        const currentTime = new Date();

        if (selectedDate <= currentTime) {
            // The selected time is not at least 3 minutes in the future.
            // Keep the previous valid time (this.currentTime).
            this.campForm.get('selectedTime').setValue(this.currentTime);
        } else {
            // Update the currentTime when a valid time is selected.
            this.currentTime = selectedTime;
        }
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  alphabeticOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const alphabeticOnlyRegex = /^[a-zA-Z\s]+$/;
      const value = control.value;
      if (value && !alphabeticOnlyRegex.test(value)) {
        return { alphabeticOnly: true };
      }
      return null;
    };
  }

  async getAllParties() {
    try {
      const partyResponse = await this.partyService.getAllPartiesDetails({}, this.currentUser.id, 1000, 1);
      if (partyResponse.success) {
        this.partyList = partyResponse.data.pageData.map((party) => ({
          id: party.id,
          email: party.email,
          mobile: party.mobile,
          name: party.partyName,
        }));
      }
    } catch (partyError) {
      if (partyError.error.status == 404) {
        this.partyList = [];
      }
    }

    try {
      const leadResponse = await this.crmservice.getAllLeadList({}, this.currentUser.id, 1000, 1);
      if (leadResponse.success) {
        const leadList = leadResponse.data.pageData.map((lead) => ({
          id: lead.id.toString(),
          email: lead.email,
          mobile: lead.mobile,
          name: lead.name,
        }));
        // Assuming this.partyList was populated with party data previously.
        this.toList = [...this.partyList, ...leadList];
      }
    } catch (leadError) {
      // Handle error for lead data fetch.
    }
  }

  toggleSelectAllParties() {
    this.selectAllParties = !this.selectAllParties;

    if (this.selectAllParties) {
      this.selectedParties = [...this.toList];
    } else {
      this.selectedParties = [];
    }
    this.updatePartySelection();
  }

  togglePartySelection(party) {
    const index = this.selectedParties.findIndex(selectedParty => selectedParty.id === party.id);

    if (index === -1) {
      this.selectedParties.push(party);
    } else {
      this.selectedParties.splice(index, 1);
    }
    // Create a new array to trigger change detection
    this.selectedParties = [...this.selectedParties];
    // Manually trigger change detection
    this.cdref.detectChanges();
    this.updatePartySelection();
  }

  updatePartySelection() {
    this.campForm.get("partySelection").setValue(this.selectedParties.map(party => party.id));
  }

  updateSelectedDateTime() {
    if (this.campForm.get('selectedTime').value) {
      const timeParts = this.campForm.get('selectedTime').value.split(':');
      const selectedDate = new Date(this.campForm.get('selectedDate').value ? this.campForm.get('selectedDate').value : this.todaysDate);
      selectedDate.setHours(parseInt(timeParts[0], 10));
      selectedDate.setMinutes(parseInt(timeParts[1], 10));

      // Convert to UTC
      this.selectedDateTime = new Date(selectedDate).toISOString();
      // const utcTimestamp = selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000;
      // this.selectedDateTime = new Date(utcTimestamp).toISOString();
      // const utcTimestamp = selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000;
    } else {
      this.selectedDateTime = null;
    }
  }

  toggleActiveState() {
    this.isActive = !this.isActive;
  }

  submitForm() {
    this.updateSelectedDateTime()
    this.submitted = true;
    if (this.campForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }
    else {
      if ((this.campForm.value.sendEmail || this.campForm.value.sendSMS || this.campForm.value.sendNotification || this.campForm.value.sendWhatsappMsg) === false) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Select At List Option!!!",
        });
        return false;
      }
      const formData = {
        campaignsData: {
          name: this.campForm.value.campaignName,
          isActive: this.isActive,
          description: this.campForm.value.campaignDescription,
          sendDate: this.selectedDateTime,
          email: this.campForm.value.sendEmail,
          sms: this.campForm.value.sendSMS,
          notification: this.campForm.value.sendNotification,
          whatsapp: this.campForm.value.sendWhatsappMsg,
          note: "Project deadline approaching",
        },
        campaignsUsers: this.selectedParties.map((item) => ({
          userId: item.id,
          email: item.email,
          mobile: item.mobile,
          name: item.name,
        }))
      };
      this.campService
        .addCampaign(formData, this.currentUser.id)
        .then((res) => {
          if (res.success) {
            this.dialogRef.close();
            this.commonService.notifyDataAdded()
            this.toastService.toastMsg({
              title: "Success",
              content: "You Have Successfully Added The Campaign"
            });
          }
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
    }
  }

  cancel() {
    this.submitted = false;
    this.dialogRef.close();
  }

  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.campForm.get('selectedDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.campForm.get('selectedDate').setValue(new Date());
    }
  }
  StringOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;

    // Allow: A-Z (65-90), a-z (97-122), backspace (8), space (32)
    if ((charCode >= 65 && charCode <= 90) || // A-Z
        (charCode >= 97 && charCode <= 122) || // a-z
        charCode === 8 || // Backspace
        charCode === 32) { // Space
      return true; // Allow alphabetic characters and space
    }

    // If charCode doesn't match the above, block it (i.e., prevent special characters and numbers)
    return false;
  }
}
