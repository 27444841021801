<div class="card mb-6 scrollbar-overlay">
  <div class="card-body  fs--1 ">
    <div class="row mx-auto">
      <div class="row mb-3">
        <div class="col-sm-4 card-header">
          <h5>SERIAL NUMBER REPORT</h5>
        </div>
        <div class="card-body  fs--1 ">
          <div class="row mb-3">
            <label class="col-sm-2 mt-1  text-nowrap" for="inputPassword3">Item name</label>
            <div class="col-sm-4">
              <input class="form-control fs--1" id="inputEmail3" type="text" />
            </div>
            <div class="col-sm-4 mt-1"></div>
            <div class="col-sm-1">
              <button class="p-0 bi-file-earmark-excel-fill  fa-2x"
                style="height: 40px; border-radius: 25px; width: 40px; border: none; margin-left: 14px;"></button>
              <div class="text-nowrap fs--2 sm mt-1">Export to Excel</div>
            </div>
            <div class="col-sm-1">
              <button class=" p-0 bi-printer-fill float-right fa-2x"
                style="height: 40px; border-radius: 25px; width: 40px; border: none; background-color: none; margin-left: 14px;"
                (click)="printComponent('component1')"></button>
              <div class="fs--2 sm text-nowrap mt-1" style="margin-left: 22px;">Print</div>
            </div>
          </div>
          <div class="card-body  fs--1 ">
            <div class="row mb-3">
              <div class="form-check col-sm-1 col-md-1 col-lg-1">
                <input class="form-check-input" id="flexCheckDefault" type="checkbox" value="" />
                <label class="form-check-label text-nowrap" for="flexCheckDefault">Show only serial numbers in stock
                </label>
              </div>
              <div class="accordion " id="accordionPanelsStayOpenExample" style="height: 400px;">
                <div class="accordion-item position-absolute bottom-0 start-0" style="width: 100%;">
                </div>
              </div>