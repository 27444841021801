import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { PromotionService } from 'src/app/core/services/promotion.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-coupon-modal',
  templateUrl: './coupon-modal.component.html',
  styleUrls: ['./coupon-modal.component.scss']
})
export class CouponModalComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public promotionForm: any;
  public submitted: boolean = false;
  public today: Date;
  types: string[] = ['DISCOUNT', 'PRODUCT', 'FLAT'];  // ['DISCOUNT', 'PRODUCT', 'FLAT', 'COUPONS'];
  productsIdList: any;
  selectedProductIds: string[] = [];

  constructor(private formBuilder: FormBuilder,
    public router: Router, private commonService: CommonService,
    private toastService: ToastNotificationService, public dialog: MatDialog,
    private titleCasePipe: TitleCasePipe,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CouponModalComponent>,
    private promotionService: PromotionService
  ) {
    this.today = new Date();
  }

  ngOnInit(): void {
    this.today = new Date(); 
    try {
      this.promotionForm = this.formBuilder.group({
        coupon_name: [null, Validators.required],
        coupon_code: [null, Validators.required],
        usage_limit: [null],
        startDate: [null, Validators.required],
        discountPercentage: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
        maxDiscountAmount: [null, Validators.required],
        expiryDate: [null, Validators.required],
        description: [null],
      })
    } catch (error) {
        this.toastService.toastMsg({
          title: 'Error',
          content: error
        })
    }
  }
  
  get f() {
    return this.promotionForm.controls;
  }

  cancel() {
    this.dialogRef.close();
  }

  generateCoupon() {
    this.promotionService.generateCoupon({}, this.currentUser.id).then((res) => {
      if (res.success) {
        this.promotionForm.patchValue({
          coupon_code: res.data
        })
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberDecimalOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSave() {
    this.submitted = true;
    if (this.promotionForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    } else {
      const data = {
        "couponData": {
            "coupon_code": this.promotionForm.value.coupon_code.toUpperCase(),
            "coupon_name": this.promotionForm.value.coupon_name,
            "discount_percentage": this.promotionForm.value.discountPercentage,
            "start_date": this.promotionForm.value.startDate,
            "end_date": this.promotionForm.value.expiryDate,
            "is_valid": true,
            "usage_limit": this.promotionForm.value.usage_limit,
            "max_discount_amount": this.promotionForm.value.maxDiscountAmount,
            "description": this.promotionForm.value.description
        }
      }
      this.promotionService.createCoupon(data, this.currentUser.id).then( res => {
        if(res.success) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Coupon Added Successfully!",
          });
           this.commonService.notifyDataAdded()
            this.dialogRef.close();
         
        }
      },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
  
      }
    )
    }
  }

  openDatepicker(dateType){
    // Check if the current value of taskdate is an "Invalid Date"
    if(dateType === 'startDate') {
      const selectedDate = this.promotionForm.get('startDate').value;
      if (isNaN(selectedDate.getTime())) {
        // Set a default date or any valid date here
        this.promotionForm.get('startDate').setValue(new Date());
      }
    } else if(dateType === 'expiryDate') {
      const selectedDate = this.promotionForm.get('expiryDate').value;
      if (isNaN(selectedDate.getTime())) {
        // Set a default date or any valid date here
        this.promotionForm.get('expiryDate').setValue(new Date());
      }
    }
  }

  calculateMinDate() {
    const expiryDate = this.promotionForm.get('startDate').value;
    // Assuming expiryDate is a valid Date object
    if (expiryDate) {
        const minDate = new Date(expiryDate);
        minDate.setDate(minDate.getDate() + 1); // Setting the minimum date as one day after joining date
        return minDate;
    }
    return null; // or you can set a default minDate if joiningDate is not set
  }
}
