import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[ngxOnlyNumbers]'
})
export class OnlyNumberDirective {
  pattern = '^[0-9]*$';
  constructor() { }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.pattern).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    event.preventDefault();
  }
}
