import { TitleCasePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BankServiceService } from 'src/app/core/services/bank-service.service';
import { CashInHandServiceService } from 'src/app/core/services/cash-in-hand-service.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import * as moment from 'moment';

@Component({
  selector: 'app-add-cash',
  templateUrl: './add-cash.component.html',
  styleUrls: ['./add-cash.component.scss']
})
export class AddCashComponent implements OnInit {
  CashInHandForm: any;
  submitted: boolean;
  public paymentType: string;
  todaysDate: Date = new Date();
  saleInvoiceId: any;
  partyId: any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  isAddMoneyClicked = false;
  isReduceMoneyClicked = false;

  constructor(private formBuilder: FormBuilder,
    public router: Router,
    private toastService: ToastNotificationService,
    private bankService: BankServiceService,
    private http: HttpClient,

    private titleCasePipe: TitleCasePipe,
    private cashInHandService: CashInHandServiceService,
    private saleInvoiceService: SaleInvoiceService, private dialog: MatDialog, public dialogRef: MatDialogRef<AddCashComponent>) { }

  ngOnInit(): void {
    this.todaysDate = new Date();
    this.getCurrentBal()
    this.CashInHandForm = this.formBuilder.group({
      currentBal: [],
      date: ["", [Validators.required]],
      enterAmount: ["", [Validators.required]],
      NewBalance: [""],
      formRemark: ["", [Validators.required]],
    })
  }
  get f1() {
    return this.CashInHandForm.controls;
  }

  calculateValues() {
    if (this.paymentType === undefined) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Please Select Add Money Or Reduce Cash!",
      });
      return false;
    }
    else {
      if (this.paymentType == "ADD") {

        const currentBalance = parseFloat(this.CashInHandForm.get('currentBal').value) || 0;
        const newAmount = parseFloat(this.CashInHandForm.get('enterAmount').value) || 0;

        const newBalance = Math.abs(currentBalance + newAmount);

        this.CashInHandForm.patchValue({
          NewBalance: newBalance
        });
      }
      else {

        const currentBalance = parseFloat(this.CashInHandForm.get('currentBal').value) || 0;
        const newAmount = parseFloat(this.CashInHandForm.get('enterAmount').value) || 0;

        const newBalance = Math.abs(currentBalance - newAmount);

        const difference = currentBalance - newAmount;
        console.log("currentBalance", difference)
        if (difference > 0) {
          this.CashInHandForm.patchValue({
            NewBalance: newBalance
          });
          this.type = false;
        } else if (difference < 0) {
          this.type = true;
        } else {
          this.CashInHandForm.patchValue({
            NewBalance: newBalance
          });
          this.type = false;
        }

      }
    }

  }

  addMoney() {
    this.paymentType = "ADD";
    this.isAddMoneyClicked = true;
    this.isReduceMoneyClicked = false;
    const currentBalance = parseFloat(this.CashInHandForm.get('currentBal').value) || 0;
    const newAmount = parseFloat(this.CashInHandForm.get('enterAmount').value) || 0;

    const newBalance = Math.abs(currentBalance + newAmount);

    this.CashInHandForm.patchValue({
      NewBalance: newBalance
    });
  }

  public type: boolean = false;
  reduceMoney() {
    this.paymentType = "REDUCE"; // Set the paymentType to "WITHDRAWAL" to indicate reducing cash
    this.isAddMoneyClicked = false;
    this.isReduceMoneyClicked = true;
    const currentBalance = parseFloat(this.CashInHandForm.get('currentBal').value) || 0;
    const newAmount = parseFloat(this.CashInHandForm.get('enterAmount').value) || 0;

    const newBalance = Math.abs(currentBalance - newAmount);

    const difference = currentBalance - newAmount;
    console.log("currentBalance", difference)
    if (difference > 0) {
      this.CashInHandForm.patchValue({
        NewBalance: newBalance
      });
      this.type = false;
    } else if (difference < 0) {
      this.type = true;
    } else {
      this.CashInHandForm.patchValue({
        NewBalance: newBalance
      });
      this.type = false;
    }

    // const enteredAmount = parseFloat(this.CashInHandForm.get('enterAmount').value) || 0;

    // if (enteredAmount <= 0) {
    //   console.log("Invalid amount. Please enter a positive value.");
    //   return;
    // }

    // const currentBalance = parseFloat(this.CashInHandForm.get('currentBal').value) || 0;

    // if (enteredAmount > currentBalance) {
    //   console.log("Amount to be reduced exceeds the current balance.");
    //   return;
    // }

    // // Perform the reduction
    // const newBalance = currentBalance - enteredAmount;

    // this.CashInHandForm.patchValue({
    //   NewBalance: newBalance
    // });

    // console.log("Reduced cash by", enteredAmount);
  }

  cancel() {
    this.dialogRef.close();
  }
  cashSave() {
    this.submitted = true;
    if (this.CashInHandForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }else if(this.type === true && this.paymentType === 'REDUCE'){
      this.toastService.toastMsg({
        title: "Error",
        content: "The type is set to reduce cash, Please check the new amount is larger than the current amount.",
      });
    }
    else {

      let body = {
        "cashadjustments": {
          "paymentType": this.paymentType,
          "date": moment(this.CashInHandForm.controls.date.value, "DD MMM YYYY").format("YYYY-MM-DD"),
          "enterAmount": this.CashInHandForm.controls.enterAmount.value,
          "remark": this.CashInHandForm.controls.formRemark.value
        },
      }

      this.cashInHandService.getcashInHand(body, this.currentUser.id).then((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Cash In Hand Added!"
        });
        this.bankService.notifyPartyAdded();
        this.CashInHandForm.reset();
        this.submitted = false;
        this.dialogRef.close();
        this.getCurrentBal();
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }


  }

  getCurrentBal() {
    this.cashInHandService.getcurrentbalance({}, this.currentUser.id).then((res) => {
      this.CashInHandForm.patchValue({
        currentBal: res.data,
      });
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }

      })
  }
  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.CashInHandForm.get('date').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.CashInHandForm.get('date').setValue(new Date());
    }
  }
}
