import { HttpHeaders, HttpClient  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RolesPermissionsService {
  private userAddedSource = new Subject<void>();
  userAdded$ = this.userAddedSource.asObservable();
  
  constructor(public httpClient: HttpService, private httpclient: HttpClient) { }

  notifyUserAdded() {
    this.userAddedSource.next();
  }

  getRoles(data: {}, userId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getRole + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getAllPermissions(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getallPermission, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPermissions(data: {}, userId: string, navigationBarData=false): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getPermission + userId + "?navigationBarData=" + navigationBarData, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  createCustomRoles(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.postCustomRoles, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateCustomRoles(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getRole + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getRolesPermissionById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.editCustomRoles + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  addEmployeeUser(data: {}): any {
    //let headers = new HttpHeaders().set('web','qa');
  
    return new Promise((resolve, reject) => {
     // this.httpClient.call(data, UrlConstants.addUserWithRole,{ requestOptions },'POST').subscribe(
      this.httpClient.call(data, UrlConstants.addEmployeeUser, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updateEmployee(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.updateEmployee + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteRole(data: {},RoleNo): any {
  
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleterole+ RoleNo ,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  


  deleteRoleById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteRole + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getUsers(data: {}, id,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getUser + id + "?size=" + size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllExpensesEmployer(data: {}, id, size, page, search?): any {
    let url = UrlConstants.getAllExpensesEmployer + id + "?size=" + size + "&page=" + page;

    if(search){
      url += "&search=" + (search || '')
    }
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, url, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  approvalstatusById(data: {}, userId, expenseId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getapprovalsbyid + userId + "?expenseId=" + expenseId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getUsersSearch(data: {}, id, search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getUser + id + "?search=" + search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  assignUserToRoles(userId, data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.assignUserToRoles + userId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  deleteUserId(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.EmployeeDelete + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  getAllUsersRole(data: {}, empId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAllUsers + empId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getEmployeeById(data: {}, empId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getEmployeeById + empId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  uploadImage(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.uploadImage ,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getEmployeePincode(data: {}, pincode): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addPincode + pincode, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  
  getEmployeesDownload(data, userId): any {
    return this.httpclient.request("GET", environment.apiUrl + UrlConstants.getEmployeesDownload + userId + "?page=" + 1 + "&size=" + 5000, {
      body: data,
      responseType: "blob",
    })
  }

  downloadEmployee(data: {}) {
    return this.httpclient.request("GET", environment.apiUrl + UrlConstants.downloadEmployee , {
      body: data,
      responseType: "blob",
    })
  }

  uploadEmployee(data:{},id){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.uploadEmployee + "?userId=" + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteMultipleEmp(data:{},id){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteMultipleEmp + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //company resource
  addCompanyResources(data: {}, userId): any {
    return new Promise((resolve, reject) => {
     this.httpClient.call(data, UrlConstants.addCompanyResources + userId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllCompanyResources(userId: string, size, page, search?: string): any {
    // Construct the URL with the optional search parameter
    let url = UrlConstants.getAllCompanyResources + userId +"?size=" + size + "&page=" + page;
    if (search) {
      url += "&search=" + (search || '')
    }
    
    return new Promise((resolve, reject) => {
      this.httpClient.call({}, url, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  //approval type
  resourcelist(data, userId): any {
    return new Promise((resolve, reject) => {
     this.httpClient.call(data, UrlConstants.resourcelist + userId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  getResourcelist(userId): any {
    return new Promise((resolve, reject) => {
     this.httpClient.call({}, UrlConstants.getResourcelist + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  myPostedApprovals(userId, page?, size?): any {
    return new Promise((resolve, reject) => {
     this.httpClient.call({}, UrlConstants.myPostedApprovals + userId + '?page=' + (page || 1) + '&size=' + (size || 500), 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  addApproval(data, userId): any {
    return new Promise((resolve, reject) => {
     this.httpClient.call(data, UrlConstants.addApproval + userId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSingleApproval(userId, approvalId): any {
    return new Promise((resolve, reject) => {
     this.httpClient.call({}, UrlConstants.getSingleApproval + userId + '?approvalId=' + (approvalId || ''), 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  iHaveToApprove(userId): any {
    return new Promise((resolve, reject) => {
     this.httpClient.call({}, UrlConstants.iHaveToApprove + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  changeStatusOfApproval(userId, approvalId, status): any {
    return new Promise((resolve, reject) => {
     this.httpClient.call({}, UrlConstants.changeStatusOfApproval + userId + '?approvalId=' + (approvalId || '') + '&status=' + (status || ''), 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // Projects
  
  addProject(data, userId): any {
    return new Promise((resolve, reject) => {
     this.httpClient.call(data, UrlConstants.changeStatusOfApproval + userId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllProjects(userId): any {
    return new Promise((resolve, reject) => {
     this.httpClient.call({}, UrlConstants.getAllProjects + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  customrolepermission(data, userId): any {
    return new Promise((resolve, reject) => {
     this.httpClient.call(data, UrlConstants.customrolepermission + userId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
}