import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExpenseServiceService {
  private partyAddedSource = new Subject<void>();
  partyAdded$ = this.partyAddedSource.asObservable();

  constructor( public httpClient:HttpService) { }
  notifyPartyAdded() {
    this.partyAddedSource.next();
  }
  getAllExpenseDetails(data:{},id,size,page):any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.expenseListData + id + "?size="+ size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  SearchExpense(data:{},id,size,page,search){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.expenseListData + id + "?size="+ size + "&page=" + page +"&search=" +search,'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
getExpenseCategory(data:{},key){
  return new Promise((resolve, reject) => {
    this.httpClient.inventory(data, UrlConstants.expenseCategory + "?key=" + key, 'GET').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  });

}

  deleteEpenseSingle(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.expnenseDeleteSingle + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  saveExpense(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.saveExpnese + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
  GetExpenseBYId(data: {}, id):any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.expenseDetailsById + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  ExpneseUpdate(data:{},id){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.expenseUpdate + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  ExpneseUpdateITems(data:{},id){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.expenseItemsUpdate + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  downloadExcel(data:{},userId,startDate,endDate){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.expenseListData + userId + "?startDate="+ startDate + "&endDate=" + endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
}
