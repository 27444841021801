<div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 500px">
    <form [formGroup]="wishlistForm">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                  (click)="modalDismiss()"  aria-label="Close"></a>
            </div>

            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6">
                    <p class="d-none d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
                        Bidding Request 
                    </p>
                 </div>
                <div class="p-4 pb-0 mb-2">
                    <!-- Confirm Date -->
                    <!-- <div class="row">
                        <div class="col-12">
                            <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width" appearance="outline">
                                <mat-label>Confirm Date</mat-label>
                                <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [(ngModel)]="todaysDate"
                                  id="datePicker" bsDatepicker [bsConfig]="{
                                containerClass: 'theme-dark-blue',
                                dateInputFormat: 'YYYY-MM-DD',
                                showWeekNumbers:false
                              }" [ngModelOptions]="{ standalone: true }">
                                <span>
                                  <mat-icon class="iconcalender">date_range</mat-icon>
                                </span>
                              </mat-form-field>
                        </div>
                    </div> -->
                    
                    <div class="row">

                         <!-- State -->
                         <div class="col-6">
                            <h5>Location</h5>
                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>State</mat-label>
                                <input matNativeControl type="text" required matInput formControlName="location"
                                    placeholder="State" [ngClass]="{
                        'is-invalid': f.location.invalid && f.location.touched && f.location.pristine }" value="">
                                <mat-error *ngIf="f.location.invalid && f.location.touched && f.location.pristine"
                                    class="invalid-feedback">
                                    <div class="error-font" *ngIf="f.location.errors.required">State
                                        Address Is Required!</div>
                                </mat-error>
                            </mat-form-field> -->
                            <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
                                <mat-label>State</mat-label>
                                <mat-select disableOptionCentering aria-label="Default select example" placeholder="Select State"
                                formControlName="location" >
                    
                                  <mat-option [value]="state" *ngFor="let state of allStates">{{ state }}</mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                        <!-- Pincode -->
                        <div class="col-6">
                            <h5>Pincode</h5>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Pincode</mat-label>
                                <input matNativeControl type="text" required matInput formControlName="pincode"
                                    placeholder="Pincode" [ngClass]="{
                        'is-invalid': f.pincode.invalid && f.pincode.touched && f.pincode.pristine }" value="">
                                <mat-error *ngIf="f.pincode.invalid && f.pincode.touched && f.pincode.pristine"
                                    class="invalid-feedback">
                                    <div class="error-font" *ngIf="f.pincode.errors.required">State
                                        Pincode Is Required.</div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                       
                    </div>

                    <div class="row">
                        <h5>Expected Range</h5>
                        <!-- Budget Min -->
                        <div class="col-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label></mat-label>
                                <input matNativeControl type="text" ngxOnlyString required matInput
                                    formControlName="budgetMin" placeholder="Budget Min" [ngClass]="{
                        'is-invalid': f.budgetMin.invalid && f.budgetMin.touched && f.budgetMin.pristine }" value="">
                                <mat-error *ngIf="f.budgetMin.invalid && f.budgetMin.touched && f.budgetMin.pristine"
                                    class="invalid-feedback">
                                    <div class="error-font" *ngIf="f.budgetMin.errors.required">State
                                        Min Limit Is Required.</div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Budget Max -->
                        <div class="col-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label></mat-label>
                                <input matNativeControl type="text" ngxOnlyString required matInput
                                    formControlName="budgetMax" placeholder="Budget Max" [ngClass]="{
                        'is-invalid': f.budgetMax.invalid && f.budgetMax.touched && f.budgetMax.pristine }" value="">


                                <mat-error *ngIf="f.budgetMax.invalid && f.budgetMax.touched && f.budgetMax.pristine"
                                    class="invalid-feedback">
                                    <div class="error-font" *ngIf="f.budgetMax.errors.required">State
                                        Max Limit Is Required.</div>
                                </mat-error>
                            </mat-form-field>
                        </div>  
                    </div>

                   
                    <div class="row">
                        <div class="col">
                            <!-- <h4 class="fw-bold">{{ warnTxt }}</h4> -->
                            <div class="form-check mt-2">
                                <input class="form-check-input" id="flexCheckDefault" type="checkbox" formControlName="publish" />
                                <label class="form-check-label" for="flexCheckDefault">
                                    {{ chkBoxTxt }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3 m-2">
                <div class="col-6 text-center d-grid gap-2">
                    <a class="btn btn-warning" data-bs-dismiss="modal">Cancel</a>
                </div>
                <div class="col-6 d-grid">
                    <a class="btn btn-warning" data-bs-toggle="modal"
                    data-bs-target="#optionChecked">Request Bid</a>
                </div>
                <button class="btn "                         
                 data-bs-toggle="modal" data-bs-target="#optionChecked">
               <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top" title=""></span>
            <!-- Delete -->
           </button>
           <button class="btn "
                                      
            data-bs-toggle="modal" data-bs-target="#errorDelete-modal">
          <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"></span>
       <!-- Delete -->
      </button>
                <!-- <div class="col-6 text-center d-grid gap-2">
                    <a class="btn btn-warning"  data-bs-dismiss="modal"  data-bs-target="#optionChecked">Submit</a>
                    (click)="postwishlistBid()" -->
                <!-- </div> -->
            </div>
        </div>
    </form>
</div>
<div class="modal fade" id="optionChecked" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="modalDismiss()" aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; "
                        id="modalExampleDemoLabel">
                        Your Bid Sent to  Bid List SucessFully</h2>
                </div>
                <!-- <h4 class="text-center ">Are You Sure To Delete this Bank Account?</h4> -->

            </div>
            <div class="modal-footer justify-content-center">
                <a class="btn btn-outline-primary" data-bs-dismiss="modal">Go To Bid List
                </a>
                

            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="errorDelete-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; "
                        id="modalExampleDemoLabel">
                        Delete Bank Account? </h2>
                </div>
                <h4 class="text-center ">Are You Sure To Delete this Bank Account?</h4>

            </div>
            <div class="modal-footer justify-content-center">
                <a class="btn btn-outline-primary" data-bs-dismiss="modal">Close</a>
                <a class="btn btn-outline-primary" data-bs-dismiss="modal">Delete
                </a>

            </div>
        </div>
    </div>
</div>