<div class="content">
    <div class="card mb-3">
        <div class="card-header bg-light pt-3 pb-3">
            <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
                All Bid Product
            </p>
        </div>
        <div class="progress" style="height: 2px">
            <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>

        <div *ngIf="bidData == 0" class="card-body p-0 overflow-hidden m-2 p-2">
            <div class="text-center">
                <div colspan="10">
                    <h5 class="text-nowrap">No Bid Details Added. Please Add Bid Details To Be Displayed Here.<span>
                        </span></h5>
                </div>
            </div>
        </div>
        <div class="card-body p-0 overflow-hidden m-2" *ngFor="let item of bidData">
            <div class="row">
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <a class="d-block">
                        <img class="ImageFormat" [src]="item.coverPic" onerror="this.src='../../../assets/img/products/2.jpg';"
                       alt="" />
                       <!-- <img class="ImageFormat" 
                       alt="" /> -->
                    </a>
                    <div class="d-grid">
                        <button class="btn btn-warning rounded-0">
                            <h6 class="text-white text-bold mb-0"> Days : Hours : Mins : Secs</h6>
                            <h4 class="text-white text-bold mb-0">{{ showTimeInterval }}</h4>
                        </button>
                    </div>
                </div>
                <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
                    <div class="row ">
                        <div class="col-sm-12 col-md-7 col-lg-8 col-xl-8 text-capitalize">
                            <div class="">
                                <h5 class="mt-3 mt-sm-0 ">
                                    <a class="text-dark fs-0 fs-lg-1 fw-bold">
                                        {{ item.itemName }}
                                    </a>
                                </h5>
                                <ul class="list-unstyled d-none d-lg-block mb-0 bgBullet"
                                    style="font-size: 12px!important;">
                                    <li><span class="fas fa-circle bgBullet" data-fa-transform="shrink-12"
                                            style="font-size: 6px!important;"></span><span>
                                            {{ item.description }}
                                        </span>
                                    </li>
                                </ul>
                            </div>

                            <div class="d-flex mt-8 " style="font-size: 12px!important;">
                                <a class="text-dark fs-0 fs-lg-1">
                                    <span class="text-warning me-3" style="font-size: 12px!important;"><span
                                            style="font-size: 12px!important;" class="text-black fw-bold">Delevery
                                            Pincode : </span>{{item.pincode}}</span>
                                    <span class="text-warning me-3" style="font-size: 12px!important;"><span
                                            style="font-size: 12px!important;" class="text-black fw-bold">City :
                                        </span>{{item.location}}</span>
                                    <span class="text-warning me-3" style="font-size: 12px!important;"><span
                                            style="font-size: 12px!important;" class="text-black fw-bold">Expected
                                            Delivery : </span>{{item.approxCompletedDate| date:"dd MMM YYYY"| uppercase}}</span>
                                </a>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-5 col-lg-4  col-xl-4 " style="font-size: 12px!important;">
                            <div class="text-right">
                                <h4 class="fs-1 fs-md-2 text-warning mb-0">&#8377;{{item.price}}</h4>
                                <h5 class="fs--1 text-500 mb-0 mt-1">
                                    <!-- <del>&#8377;2399 </del><span class="ms-1">-50%</span> -->
                                </h5>
                            </div>
                            <div class="mt-3">
                                <h5 class="text-dark fs-0 fs-md-1 fw-bold">Expected Range </h5>
                                <p class="text-warning">(&#8377;{{ item.budgetMin }}
                                    - &#8377;{{item.budgetMax}})</p>
                            </div>
                            <div class="row mt-6 text-center">
                                <div class="col-lg-6 col-md-5 col-sm-6  col-xl-6">
                                    <a class="btn btn-sm btn-warning btn-block btn-width mb-1 text-nowrap pointer-event-cursor"
                                        (click)="openAcceptBidModal(item.wishlistId,acceptBid)">
                                        <span>Accept</span>
                                    </a>
                                </div>
                                <div class="col-lg-6 col-md-5 col-sm-6 col-xl-6">
                                    <a class="btn btn-sm btn-warning btn-block btn-width mb-1 text-nowrap pointer-event-cursor"
                                        disabled style="margin-right: 5%">
                                        <span>Reject</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-paginator class="float-right mt-2" *ngIf="bidData?.length > 0" [pageSize]="partyPageSize"
            [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo"
            (page)="handlePage($event)" aria-label="Select page">
        </mat-paginator>


    </div>
</div>

<!-- Warning Modal -->
<ng-template #acceptBid let-modal>
    <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
                aria-label="Close"></button>
        </div>
        <div class="modal-body p-0">
            <div class="rounded-top-lg py-3 ps-4 pe-6">
                <p class="d-none d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
                    Bidding Response
                </p>
            </div>
            <form [formGroup]="quotePriceForm">
                <div class="p-4 pb-0 mb-2">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Product Name</mat-label>
                                <input type="text" placeholder="Product Name" (input)="searchProduct()"
                                    aria-label="Select Product Name" matInput [formControl]="myControl"
                                    [matAutocomplete]="auto">
                                <mat-icon class="m-2" matSuffix>search</mat-icon>
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.itemName"
                                        (onSelectionChange)="selectedProduct(option.itemName)">
                                        <span><img class="img-circle m-1 imageRound" width="50" height="50"
                                                src="../../../assets/img/products/2.jpg" alt="" /></span>
                                        {{ option.itemName | titlecase }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-error *ngIf="(submitted || myControl.touched) && myControl.pristine"
                                class="invalid-feedback">
                                Product Name Is Required!
                            </mat-error>
                        </div>
                    </div>
                    <div class="row" *ngIf="searchedProduct.length > 0">
                        <h5><b>Selected Product</b></h5>
                        <h5 class="text-capitalize fw-bold"><span><img class="img-circle m-1 imageRound" width="50"
                                    height="50" src="../../../assets/img/products/2.jpg" alt="" /></span>{{ prodName
                            }}</h5>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Seller Range</mat-label>
                                <input matNativeControl type="text" required matInput ngxOnlyNumbers
                                    placeholder="Seller Range" formControlName="sellerRange" value="" [ngClass]="{
            'is-invalid': f.sellerRange.invalid && f.sellerRange.touched && f.sellerRange.pristine }">
                            </mat-form-field>
                            <mat-error *ngIf="(submitted || f.sellerRange.touched) && f.sellerRange.pristine"
                                class="invalid-feedback">
                                <div class="error-font" *ngIf="f.sellerRange.errors.required">
                                    Seller Range Is Required!</div>
                            </mat-error>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex " style="font-size: 12px;">
                                <label class="text-nowrap">Home Delivery</label>
                                <div class="form-check form-switch">
                                    <input style="margin-left: 2px!important;"
                                        class="form-check-input toggleB text-warning" style="margin-left:3px!important;"
                                        id="inlineCheckbox1" type="checkbox" formControlName="homeDelivery"
                                        (change)="showHomeDelevery($event.target.checked)" [checked]="isHome" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" *ngIf="isHome">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Delivery Charge</mat-label>
                                <!-- <input matNativeControl type="number" ngxOnlyString matInput
                                    placeholder="Delivery Charge" min="0" formControlName="deleveryCharge" value=""
                                    oninput="this.value = Math.abs(this.value)"
                                    [ngClass]="{'is-invalid': f.deleveryCharge.invalid && 
                                        f.deleveryCharge.touched && submitted }" /> -->
                                <input matNativeControl type="number" required matInput formControlName="deleveryCharge"
                                placeholder="Delivery Charge" min="0" oninput="this.value = Math.abs(this.value)" 
                                value="deleveryCharge"   [ngClass]="{'is-invalid':
                                f.deleveryCharge.invalid && f.deleveryCharge.touched && submitted
                                }" />
                            </mat-form-field>
                            <mat-error class="text-danger" *ngIf="(submitted || f.deleveryCharge.touched) && 
                                f.deleveryCharge.errors?.required">
                                    Delivery Charge Is Required!
                            </mat-error>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-sm-12">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Expected Delivery Date</mat-label>
                                <input matInput required ngModel bsDatepicker formControlName="deliveryDate"
                                style="text-transform:uppercase!important;"   [minDate]="todaysDate"  [(ngModel)]="todaysDate" id="datePicker" bsDatepicker [bsConfig]="{
                    containerClass: 'theme-dark-blue',
                    dateInputFormat: 'DD MMM YYYY',
                     showWeekNumbers:false
                    }" (click)="openDatepicker()" autocomplete="off"  [ngClass]="{
                        'is-invalid': f.deliveryDate.invalid && f.deliveryDate.touched && f.deliveryDate.pristine }">
                                <mat-error
                                    *ngIf="f.deliveryDate.invalid && f.deliveryDate.touched && f.deliveryDate.pristine"
                                    class="invalid-feedback">
                                    <div class="error-font" *ngIf="f.deliveryDate.errors.required">
                                        Expected Delivery Date Is Required!</div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Description</mat-label>
                                <textarea matInput rows="5" required placeholder="Description"
                                    formControlName="description" [ngClass]="{
            'is-invalid': f.description.invalid && f.description.touched && f.description.pristine }"></textarea>
                            </mat-form-field>
                            <mat-error *ngIf="(submitted || f.description.touched) && f.description.pristine"
                                class="invalid-feedback">
                                 Description Is Required!
                            </mat-error>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="row mt-3 mb-3 m-2">
            <div class="col-6 text-center d-grid gap-2">
                <a class="btn btn-warning" (click)="modalDismiss()">Cancel</a>
            </div>
            <div class="col-6 d-grid">
                <a class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#PopUp"
                    (click)="postClientQuotePrice()">Submit</a>
            </div>
        </div>
    </div>
</ng-template>