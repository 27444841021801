import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { CommonService } from 'src/app/core/services/common.service';
import { RemoteShopService } from 'src/app/core/services/remote-shop.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';


@Component({
  selector: 'app-bidding-details',
  templateUrl: './bidding-details.component.html',
  styleUrls: ['./bidding-details.component.scss']
})
export class BiddingDetailsComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  @ViewChild('closebutton') closebutton;
  public BidId: any;
  public BidDetailsApi: any;
  public bidArray: any = [];
  public bidProduct: any;
  public bidResponse: any;
  public BidLocation: any;
  public bidProductprice: any;
  public bidExpectedDate: any;
  public isHome: boolean = false;
  public bidArrayId: any;
  public whitsStatus: any;
  public isShop: any;
  public productDescription: any;
  public pageBidData:any;
  public bidPageApIRes:any;
 public productArray:any;
 public cartArray:any;
  public DeliveryForm = this.formBuilder.group({
    homeDelivery: [false],
   // ShopCollect: ["",],
  //  // pincode: ["", [Validators.required]],
  //   street: ["", [Validators.required]],
  //   area: ["", [Validators.required]],
  //   city: ["", [Validators.required]],
  //   landmark: ["", [Validators.required]],
  //   paymentMode: [""]
  })
  constructor(private route: ActivatedRoute,
    private bidservice: BiddingService,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private notificationService: ToastNotificationService,
    private toastNotificationService: ToastNotificationService,
    private router: Router,
    private remoteShopService: RemoteShopService,
    private commonService: CommonService,
    private titleCasePipe:TitleCasePipe,
    private toastService: ToastNotificationService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      params => this.BidId = params['id']
    );
    console.log("BID ID", this.BidId);
    this.getBidDetails();
    this.getCustomerCartItems();
  }

  getBidDetails() {
    this.bidservice.getWishlistById({}, this.BidId).then((res) => {
      if(res){
      this.bidArray = res.data.biddings;
      this.bidArrayId = res.data.biddings.id;
      this.bidProduct = res.data.product.itemName;
      this.bidProductprice = res.data.product.price;
      this.BidLocation = res.data.location
      this.bidExpectedDate = res.data.approxCompletedDate
      this.whitsStatus = res.data.wishStatus
      this.productDescription = res.data.description
      }
      //this.bidPageApIRes=res.data
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  get f() {
    return this.DeliveryForm.controls;
  }

  showHomeDelevery(checked: boolean) {
    this.isHome = checked;
    this.isShop = false;
  }

  postDeleveryDetails() {
    
    if (this.DeliveryForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
        });
      // this.toastNotificationService.openErrorSnackBar("Please Fill Mandatory Fields!!!.")
    } else {
      var BidIdnumber = parseInt(this.BidId, 10); 
      console.log("BidIdnumber",BidIdnumber)
      let body = {
        "wishlistId":BidIdnumber,
       "biddingId": this.bidArray[0].id,
        // "building": this.DeliveryForm.controls.street.value,
        // "area": this.DeliveryForm.controls.area.value,
        // "pincode": this.DeliveryForm.controls.pincode.value,
        // "city": this.DeliveryForm.controls.city.value,
        // "landmark": this.DeliveryForm.controls.landmark.value,
        "delivery":  this.isHome
      }
     
      // this.router.navigate(["/pages/customer/place-order/", this.BidId]);
   
      this.bidservice.postCustomerAcceptBid(body).then((res) => {
        if (res) {
          this.router.navigate(["/pages/customer/my-cart"]);
          this.toastService.toastMsg({
            title: "Success",
            content: "Delivery Details Submitted Successfully!",
          })
          // this.notificationService.openSnackBar("Delivery Details Submitted Successfully!!!");
        }
      },(err)=>{
        if(err.error.status === 500){
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
      })
    }
  }

  RejectCustomerBid(){
    var BidIdnumber = parseInt(this.BidId, 10); 
    console.log("BidIdnumber",BidIdnumber)
    let body = {
      "wishlistId":BidIdnumber,
     "biddingId": this.bidArray[0].id,
    }
    this.bidservice.RejectCustomerBid(body).then((res)=>{
      this.router.navigate(["/pages/customer/bidding"]);
      this.toastService.toastMsg({
        title: "Success",
        content: "Bid Rejected Successfully!",
      })
      // this.notificationService.openSnackBar("Bid Rejcted Successfully!!!");
    }
    , (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
          });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }

  showShopCollect(checked: boolean) {
    this.isShop = checked;
    this.isHome = false;
  }

  addProductToCart(item){
    this.router.navigate(["/pages/customer/my-cart"]);
    // this.cartArray.push(this.pageBidData);
    // console.log("CART ARRAY", this.cartArray)
    // this.addToCart(this.pageBidData);
    
  }

  getAcceptData(bid){
    this.pageBidData=bid;
  }

  // async addToCart(element) {
  //   debugger
  //   let data = {
  //     "customerId": Constants.currentUser.id,
  //     "clientId": this.pageBidData.product.userId,
  //     "barcode": this.pageBidData.product.barcode,
  //     "quantity": 1,
  //   }
  //   console.log("Data--- ",data)
  //   await this.remoteShopService.postAddToCart(data).then((res) => {
  //     this.toastNotificationService.openSnackBar("Product added successfully!!!");
  //     this.getCustomerCartItems();
  //       this.router.navigate(["/pages/customer/my-cart"])
      
  //   })
  // }

  getCustomerCartItems() {
    this.remoteShopService.getCustomerCartItems({}, this.currentUser.id)
      .then((res) => {
        this.productArray = res.data.products;
        this.commonService.sendData(res.data.invoiceDetails.totalProducts)
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }


}
