<div class="modal-header pt-0">
  <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title mb-0">Add Cash In Hand</h1>

  <div class="addProductButtons">

    <button style="margin-right: 10px;" type="button" class="btn btn-cancel"
      (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-save" (click)="cashSave()" *appHasPermission="['create']; name 'bankdetails'">Save</button>

  </div>
</div>
<div class="modal-body">
  <div class="container-fluid px-2">
    <form [formGroup]="CashInHandForm">
      <div class="cashInHandButtons mb-5">
        <button class="btn" type="button"  [ngClass]="{'btn-add-cash1': isAddMoneyClicked, 'btn-add-cash': !isAddMoneyClicked}"
          (click)="addMoney()">
          <i class="fas fa-plus me-2"> </i>Add Cash
        </button>
        <button class="btn" type="button" 
          [ngClass]="{'btn-reduce-cash1': isReduceMoneyClicked, 'btn-reduce-cash': !isReduceMoneyClicked}"
          (click)="reduceMoney()">
          <i class="fas fa-minus me-2"> </i>Reduce Cash
        </button>
      </div>

      <!-- Current Balance -->
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
          <label class="form-label">Current Balance</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input matInput placeholder="Current Balance" formControlName="currentBal" readonly/>
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
          <label class="form-label" for="Date"> Date</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input matInput required ngModel bsDatepicker formControlName="date" placeholder="Date"
              style="text-transform: uppercase !important" [(ngModel)]="todaysDate" id="datePicker" bsDatepicker
              [bsConfig]="{
                      containerClass: 'theme-dark-blue',
                      dateInputFormat: 'DD MMM YYYY',
                      showWeekNumbers: false,
                      minDate: todaysDate,
                      maxDate: todaysDate
                    }" (click)="openDatepicker()" autocomplete="off" readonly />
            <span>
              <mat-icon class="iconcalender">date_range</mat-icon>
            </span>
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
          <label class="form-label">New Amount<span class="error-asterisk">*</span> </label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input type="number" matInput placeholder="Enter Amount" (change)="calculateValues()"
              formControlName="enterAmount" min="0" oninput="this.value = Math.abs(this.value)" [ngClass]="{
                      'is-invalid':
                        f1.enterAmount.invalid &&
                        f1.enterAmount.touched &&
                        submitted
                    }" />
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f1.enterAmount.touched) && f1.enterAmount.errors?.required">
            Enter Amount Is Required.
          </mat-error>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
          <label class="form-label">New Balance</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input type="number" matInput placeholder="New Balance" formControlName="NewBalance" readonly />
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
          <label class="form-label">Perticular<span class="error-asterisk">*</span> </label>
          <mat-form-field class="example-full-width" appearance="outline">
            <textarea matInput required rows="3" placeholder="Perticular"
              formControlName="formRemark"></textarea>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f1.formRemark.touched) && f1.formRemark.errors?.required">
            Perticular is required.
          </mat-error>
        </div>
      </div>
    </form>
  </div>
</div>