
    <div class="row justify-content-start card mb-2 p-1">
        <div class="d-flex col-10">
            <div class="avatar avatar-xl">
                <img class="rounded-circle" src="../../assets/img/team/4.jpg" alt="" />
            </div>
            <div class="ms-3 mt-1 text-dark fs-0 fs-lg-1">3Rd Eye Camera store</div>
            <div class="ms-3 mt-1"><b>Rating</b></div>
            <div class="ms-3 mt-1"><span class="badge bg-success">4.2 <span
                        class="fas fa-star mt-1"></span></span></div>
            <div class="ms-3 mt-1 text-danger">
                <span class="material-icons text-success fs-0 ms-1 mt-1">check_circle</span>
            </div>
        </div>
    </div>


<div>
    <div class="row mb-2">
      <div class="col-md-8 col-lg-8  UpperDiv8 col-sm-12 card  p-2 justify-content-start" style="margin-right: 20px!important;">
        <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 mt-2">
                <a class="d-block">
                    <img class="img-fluid fit-cover w-sm-100 h-sm-100 rounded-0 absolute-sm-centered"
                        src="../../../assets/img/products/2.jpg" alt="" />
                </a>
            </div>
            <div class="col-md-8 col-lg-8 col-sm-12 mt-2 ">
                <div class="row">
                    <div class="col-md-9 col-lg-9 col-sm-12">
                        <h3 class="fw-bold">{{bidProduct}}</h3>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-12 fw-bold">
                        <mat-icon style="font-size: 12px!important;" class="iconcalender p-1">currency_rupee
                        </mat-icon> {{bidArrayPrice}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10 col-lg-10 col-sm-12" >
                        <h3>{{productDescription}}</h3>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3 col-sm-12 card mb-2 p-2 justify-content-end">
            <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12 mt-2">
                    <h3 class="fw-bold">Price details</h3>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12"></div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <h5>Price (1 item)</h5>
                    <h5>Delivery Charges </h5>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <h5><span><mat-icon style="font-size: 12px;" class="iconcalender p-1">currency_rupee
                    </mat-icon></span> {{bidArrayPrice}}</h5>
                    <h5><span><mat-icon style="font-size: 12px;" class="iconcalender p-1">currency_rupee
                    </mat-icon></span>50</h5>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <h5>Total amount</h5>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <h5><span><mat-icon style="font-size: 12px;" class="iconcalender p-1">currency_rupee
                    </mat-icon></span>30000</h5>
                </div>
            </div>

            
     
      </div>
      <div class="row ">
        <div class="col-md-8  UpperDiv8 col-lg-8 col-sm-12 card mt-2" style="padding-right: 5px!important;">
            <h3 class="fw-bold">{{this.submitObj.firstName}}</h3>
            <!-- <h5>{{BidLocation}}</h5> -->
            <h5>{{BiDetailsAddressBuilding}} {{BiDetailsAddressArea }} {{BiDetailsAddressLandmark}} {{BiDetailsAddressCity}}
               {{BiDetailsAddressPincode}}</h5>
        </div>
    </div>  
   
    </div>
</div>

<div class="row mb-3">
    <div class="col-lg-12">
        <div class="col-md-12 text-left fw-bold">
            <div class="card h-100 bg-light text-dark">
                <div class="card-header">
                    <h3 class="mb-0 fw-bold">Payment Form</h3>
                </div>

                <div class="card-body">
                    <form ngNoForm [action]='url' method='post'>
                        <div>
                            <!-- <input class="form-control" type="text" name="service_provider" placeholder="service_provider" value="payu_paisa"> -->
                            <input class="form-control" type="hidden" name="key" placeholder="key"
                                [(ngModel)]="this.submitObj.key" />
                            <input class="form-control" type="text" type="hidden"  name="txnid" placeholder="txnid"
                                [(ngModel)]="this.submitObj.txnId" />
                            <input class="form-control" type="text" (keyup)="setHash()"  type="hidden" name="productinfo" placeholder="productinfo"
                                [(ngModel)]="this.submitObj.productinfo" />
                            <!-- <input class="form-control" type="text" (keyup)="setHash()" placeholder="total amount" name="amount"
                                [(ngModel)]="this.bidArrayPrice" /> -->
                                <input class="form-control" type="text" (keyup)="setHash()" type="hidden"  placeholder="total amount" name="amount"
                                [(ngModel)]="this.submitObj.amount" /> 
                                <input class="form-control" type="text" (keyup)="setHash()"  name="email"
                                [(ngModel)]="this.submitObj.email" />
                            <input class="form-control" type="text" (keyup)="setHash()" name="firstname" placeholder="firstName"
                                [(ngModel)]="this.submitObj.firstName" />
                            <input class="form-control" type="text" (keyup)="setHash()" name="lastname" placeholder="lastname"
                                [(ngModel)]="this.submitObj.lastName" /> 
                             <input class="form-control" type="text" name="surl" type="hidden"  placeholder="surl"
                                [(ngModel)]="this.submitObj.surl" />
                            <input class="form-control" type="text" name="furl"  type="hidden" placeholder="furl"
                                [(ngModel)]="this.submitObj.furl" />
                            <input class="form-control" type="text" (keyup)="setHash()" name="phone" placeholder="Contact #"
                                [(ngModel)]="this.submitObj.phone">
                            <!-- <input class="form-control" type="text" [(ngModel)]="this.submitObj.udf1"
                                name="udf1"   placeholder="udf1">
                            <input class="form-control" type="text" [(ngModel)]="this.submitObj.udf2"
                                name="udf2" placeholder="udf2">
                            <input class="form-control" type="text" [(ngModel)]="this.submitObj.udf3"
                                name="udf3"   placeholder="udf3"> -->
                            <input class="form-control" placeholder="Enter Number"  type="text"  type="hidden" name="hash" placeholder="hash"
                                [(ngModel)]="this.submitObj.hash" /> 
                                <div class="row">
                                    <div class="offset-10 col-2">
                                        <input button class="btn btn-warning text-right text-end mt-2"  type="submit" value="Place Order">
                                    </div>
                                </div>

                           
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
  
</div>
       
  