import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Gstr1Service } from 'src/app/core/services/gstr1.service';
import * as XLSX from 'xlsx';

interface IRange {
  value: Date[];
  label: string;
}

@Component({
  selector: 'app-gst-report',
  templateUrl: './gst-report.component.html',
  styleUrls: ['./gst-report.component.scss']
})
export class GstReportComponent implements OnInit {

  public quarterStartDate: any;
  public quarterEndDate: any;
  public today: Date;
  public quarter: any;
  public maxDate: Date;
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  public dateRange: Date[];
  fileName = 'gst1Report.xlsx';
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public gstr1salesList: any;
  public gstr1salesReturnList: any;


  constructor(
    private gstr1Service: Gstr1Service) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    let quarter = moment(this.today).utc().quarter() //outputs 4
    // this.getQuarterRange();
  }


  ngOnInit(): void {
    //this.dateRange=new Date();
    this.dateRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]

    this.ranges = [

      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      // {
      //   value: [this.quarterStartDate._d, this.quarterEndDate._d],
      //   label: ' Last Quarter'
      // },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear(), 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];

    this.getGstr1Report();

  }
  //   printComponent(cmpName) {
  //     let printContents = document.getElementById(cmpName).innerHTML;
  //     let originalContents = document.body.innerHTML;

  //     document.body.innerHTML = printContents;

  //     window.print();

  //     document.body.innerHTML = originalContents;

  // }

  getGstr1Report() {

    //console.log(" date 1 ",this.dateRange[0], "date 2",this.dateRange[1], )
    // this.gstr1Service.getgstr1Report({},this.currentUser.id,moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD') ,moment(this.dateRange[1], 'DD-MMM-YYYY' ).format('YYYY-MM-DD')).then((res) => {
    //  console.log("Res",res)
    // })
    this.gstr1Service.getgstr1Report({}, this.currentUser.id, moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD'), moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD')).then((res: any) => {
      console.log("Res", res)
      this.gstr1salesList = res.data.sales;
      console.log(this.gstr1salesList)
      this.gstr1salesReturnList = res.data.saleReturn;
      //  console.log(this.gstr1salesList)
    })

  }


  // getQuarterRange() {
  //   this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
  //   this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  // }

  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  // onPrint() {
  //  let printContents: string , originalContents: string;
  //  printContents = document.getElementById('excel-table').innerHTML;
  //  originalContents = document.body.innerHTML;
  //  window.print();


  //  }

  onPrint() {
    let printContents, popupWin;
    printContents = document.getElementById("print").innerHTML.toString();
    printContents = (<string>printContents + "").replace("col-sm", "col-xs");
    // console.log(printContents);
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Reporte</title>
        <meta name="viewport" content="width=10000, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
        <link rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
        <style>

        </style>
      </head>
      <body onload="window.print();">
      <h1></h1>

        ${printContents}
      </body>
    </html>`);
    /* window.close(); */
    popupWin.document.close();
  }



  changeDate(_e: any) {
    this.getGstr1Report();
  }



}
