
import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})

export class SubscriptionService {

  constructor(public httpClient: HttpService) { }
  getPlans(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAllPlan, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getPlansById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAllPlan + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPaymentHash(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getHash, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getFeatures(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAllFeatures + "?userId=" + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getFetaureById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getFeatureById + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  basicSubsription(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.postBasicSubscription, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  premiumSubscription(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.postPremiumSubscription, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSubscribedUsersDetails(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getSubscribedUserDetails + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}