<!-- <div class="modal-header row">
  <div class="col-lg-6 col-sm-12 col-md-4">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 11px;" class="modal-title mb-0">Add Group</h1>
  </div>
  <div class="col-lg-2 col-sm-12 col-md-1"></div>
  <div class="col-lg-2 col-sm-12 col-md-3">
    <button type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
  </div>

  <div class="col-lg-2 col-sm-12 col-md-3">
    <button type="button" class="btn btn-save" (click)="PostPartyGroup()">Save</button>
  </div>

</div> -->

<div class="modal-header pt-0" id="Add-Party-modal">
  <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 11px;" class="modal-title mb-0">Add Group</h1>
  <div class="addProductButtons">

      <button style="margin-right: 10px;" type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
      <button type="button" class="btn btn-save" (click)="PostPartyGroup()" *appHasPermission="['create']; name 'vendorgroup'">Save</button>

  </div>
</div>


<div class="modal-body">
    <div class="container-fluid px-2">
      <form [formGroup]="PartyGroupForm">
        <label class="form-label">Group Name <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input
            matInput
            required maxlength="100"
            formControlName="groupName"
            rows="3" ngxOnlyString
            placeholder="Group Name" autocomplete="off"
          />
        </mat-form-field>
        <mat-error
          class="text-danger"
          *ngIf="
            (submitted || f.groupName.touched) && f.groupName.errors?.required
          "
        >
          Group name is required.
        </mat-error>

        <label class="form-label">Group Description <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <textarea
            matInput autocomplete="off"
            required
            formControlName="groupDescription"
            rows="3" maxlength="150"
            placeholder="Group Description"
          ></textarea>
        </mat-form-field>
        <mat-error
          class="text-danger"
          *ngIf="
            (submitted || f.groupDescription.touched) &&
            f.groupDescription.errors?.required
          "
        >
          Group description is required.
        </mat-error>
      </form>
            <!-- <form [formGroup]="PartyGroupForm" (ngSubmit)="PostPartyGroup()">
              <div class="example-full-width">
                <label>Group Name</label>
                <input type="text" class="form-control" formControlName="groupName" required placeholder="Group Name">
              </div>
                <div class="err-msg">
                  <mat-error *ngIf="(submitted || f.groupName.touched) && f.groupName.errors?.required">
                    <ng-container>Group name is required</ng-container>
                  </mat-error>
                </div>

              <div class="example-full-width">
                <label>Group Description</label>
                <textarea class="form-control" formControlName="groupDescription" required rows="3" placeholder="Group Description"></textarea>
              </div>
              <div class="err-msg">
                <mat-error *ngIf="(submitted || f.groupDescription.touched) && f.groupDescription.errors?.required">
                  <ng-container>Group description is required</ng-container>
                </mat-error>
              </div>
            </form> -->

    </div>
</div>













<!-- <div class="card mb-3" id="customersTable">
  <div class="card-header bg-light pt-3 pb-3">
    <p
      class="d-md-inline-block fs--1 mt-3 ml-5"
      style="font-weight: 800; font-size: 20px !important"
    >
      Add Group
    </p>
  </div>
  <div class="progress" style="height: 2px">
    <div
      class="progress-bar"
      role="progressbar"
      aria-valuenow="33"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>

  <div class="card-body">
    <p
      class="d-md-inline-block fs--1 ml-5"
      style="font-weight: 500; font-size: 18px !important"
    >
      Add Group
    </p>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 container">
        <form [formGroup]="PartyGroupForm">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Group Name</mat-label>
            <input
              matInput
              required
              formControlName="groupName"
              rows="3"
              placeholder="Group Name"
            />
          </mat-form-field>
          <mat-error
            class="text-danger"
            *ngIf="
              (submitted || f.groupName.touched) && f.groupName.errors?.required
            "
          >
            Group name is required
          </mat-error>

          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Group Description</mat-label>
            <textarea
              matInput
              required
              formControlName="groupDescription"
              rows="3"
              placeholder="Group Description"
            ></textarea>
          </mat-form-field>
          <mat-error
            class="text-danger"
            *ngIf="
              (submitted || f.groupDescription.touched) &&
              f.groupDescription.errors?.required
            "
          >
            Group description is required
          </mat-error>
        </form>
        <div class="text-right">
          <button
            type="button"
            class="btn btn-outline-primary"
            (click)="PostPartyGroup()"
          >
            Submit
          </button>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"></div>
    </div>
  </div>
</div> -->
