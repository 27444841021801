<!-- Modal Header -->
<div class="modal-header pt-0">
  <h1 class="modal-title mb-0">New Dashboard</h1>
  <div class="addDashboardButtons nav-item">
    <button type="button" class="btn btn-cancel" style="margin-right: 10px !important" (click)="closeDialog()">
      Cancel
    </button>
    <button type="button" class="btn btn-save" color="primary" (click)="createDashboard()">
      Create Dashboard
    </button>
  </div>
</div>

<!-- Modal body -->
<div class="modal-body">
  <div class="container-fluid px-2">
    <form [formGroup]="CreateDashboardForm">
      <div class="row mb-3">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
          <label class="form-label p-2">Dashboard Name<span class="error-asterisk">*</span></label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input matInput matNativeControl required placeholder="Dashboard Name" formControlName="dashboardName"
              maxlength="100" autocomplete="off" [ngClass]="{
                'is-invalid':
                  f.dashboardName.invalid &&
                  f.dashboardName.touched &&
                  submitted
              }" />
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="submitted || f.dashboardName.touched">
            <mat-error *ngIf="f.dashboardName.errors?.required">
              Dashboard Name is required.
            </mat-error>
          </mat-error>
        </div>
      </div>
      <div class="row mb-3">
        <mat-divider></mat-divider>
        <div class="col-sm-12 col-lg-6 col-md-6 p-3">
          <div class="form-label">Dashboard Privacy</div>
          <div class="form-label font-11 pb-3">
            Decide who can see the dashboard
          </div>
          <mat-radio-group required name="radio" aria-label="Select an option" formControlName="dashboardPrivacy"
            class="privacy-radio-group">
            <mat-radio-button value="Private">Private</mat-radio-button>
            <mat-radio-button value="Public">Public</mat-radio-button>
            <mat-radio-button value="user" class="mt-3-op">Visible to particular user
              <mat-form-field appearance="outline">
                <mat-select matinput placeholder="Select User"  formControlName="dashboardUser">
                  <mat-option *ngFor="let user of userList" [value]="user.id">{{
                    user.fullName
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-radio-button>
          </mat-radio-group>
          <mat-error class="text-danger" *ngIf="submitted || f.dashboardPrivacy.touched">
            <mat-error *ngIf="f.dashboardPrivacy.errors?.required">
              Please select dashboard privacy.
            </mat-error>
          </mat-error>
        </div>
        <mat-divider></mat-divider>
      </div>
    </form>
  </div>
</div>
