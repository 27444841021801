import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PartyService {
  private partyAddedSource = new Subject<void>();
  partyAdded$ = this.partyAddedSource.asObservable();

  getPartyByItem(arg0: {}, id: any, prodId: string) {
    throw new Error('Method not implemented.');
  }

  constructor(public httpClient: HttpService, private httpclient : HttpClient) {

  }

  notifyPartyAdded() {
    this.partyAddedSource.next();
  }

  postParty(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postParty, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postBulkParty(data: {}, id) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postBulkParty + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllPartiesDetails(data: {}, id, size, page, type?): any {
    let partyType = 111
    if (type == 'customer-list') {
      partyType = 1
    } else if (type == 'party-list') {
      partyType = 0
    }
    let url = UrlConstants.getAllPartiesApi + id + "?size=" + size + "&page=" + page
    if (partyType != 111) {
      url = url + "&partyType=" + partyType
    }
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, url, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllPartiesDetailsSearch(data: {}, id, size, page, search: string, type?): any {
    let partyType = 111
    if (type === 'customer-list') {
      partyType = 1
    } else if (type === 'party-list') {
      partyType = 0
    }
    let url = UrlConstants.getAllPartiesApi + id + "?size=" + size + "&page=" + page + "&search=" + search 
    if (partyType !== 111) {
      url = url + "&partyType=" + partyType
    }
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, url , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getPartyDetails(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getParty + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getGstNumber(data: {}, gstin): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getGst + gstin, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateParty(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.updateParty + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateAdditionalDetails(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.updateAdditionalDeatils + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deletePartyAdditional(data: {}, additionalId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deletePartyAdditional + additionalId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  deletePartyById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deletePartyById + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  UnarchivedParty(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.UnarchivedParty + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getGroupParty(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getGroupParty + id, 'POST').subscribe(
        (res) => {
          resolve(res)
          console.log(res)
        },
        (err) => reject(err)
      );
    });
  }

  multiplePartyDel(data, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.multiplePartyDel + id, 'DELETE').subscribe(
        (res) => {
          resolve(res)
          console.log(res)
        },
        (err) => reject(err)
      );
    });
  }

  unArchiveMultipleParties(data, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.unArchiveMultipleParties + id, 'POST').subscribe(
        (res) => {
          resolve(res)
          console.log(res)
        },
        (err) => reject(err)
      );
    });
  }

  getAllCountries(data, search?): any {
    let url = UrlConstants.getAllCountries;
    if(search !== undefined) {
      url += "?search=" + search 
    } 
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, url, 'GET').subscribe(
        (res) => { resolve(res) },
        (err) => reject(err)
        );
      });
    }

  getAllPartyDownload(data, userId, partyType): any {
    return this.httpclient.request("GET", environment.inventoryUrl + UrlConstants.getAllPartyDownload + userId + "?filter=" + "DESC" + "&size=" + 5000 + "&page=" + 1 + "&partyType=" + partyType, {
      body: data,
      responseType: "blob",
    }) 
    // return new Promise((resolve, reject) => {
    //   this.httpClient.inventory(data, UrlConstants.getAllPartyDownload + userId + "?filter=" + "DESC" + "&size=" + 5000 + "&page=" + 1 + "&partyType=" + partyType, 'GET').subscribe(
    //     (res) => { resolve(res) },
    //     (err) => reject(err)
    //   );
    // });
  }
    
  
    downloadParties(data): any {
      return this.httpclient.request("GET", environment.inventoryUrl + UrlConstants.downloadParties, {
        body: data,
        responseType: "blob",
      }) 
    }
}
