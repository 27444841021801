import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { TotalSalesComponent } from '../dashboard/widgets/total-sales/total-sales/total-sales.component';
import { TotalPurchaseComponent } from '../dashboard/widgets/total-purchase/total-purchase/total-purchase.component';
import { StockDetailsComponent } from '../dashboard/widgets/stock-details/stock-details/stock-details.component';
import { SalesPurchaseChartComponent } from '../dashboard/widgets/sales-purchase-chart/sales-purchase-chart/sales-purchase-chart.component';
import { NetProfitChartComponent } from '../dashboard/widgets/net-profit-chart/net-profit-chart/net-profit-chart.component';
import { AmountFormatPipe } from '../dashboard/amountFormat.pipe';
import { MaterialExampleModule } from 'src/app/material.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RouterModule } from '@angular/router';
import { OrderSummaryChartComponent } from '../dashboard/widgets/order-summary-chart/order-summary-chart.component';
import { BestSellingProductsComponent } from '../dashboard/widgets/best-selling-products/best-selling-products.component';
import { CreditVendorComponent } from '../dashboard/widgets/credit-vendor/credit-vendor.component';
import { LowStocksProductComponent } from '../dashboard/widgets/low-stocks-product/low-stocks-product.component';
import { NewCustomersComponent } from '../dashboard/widgets/new-customers/new-customers.component';
import { PendingPaymentComponent } from '../dashboard/widgets/pending-payment/pending-payment.component';
import { PriceRangeDialogComponent } from '../dashboard/widgets/price-range-dialog/price-range-dialog.component';
import { ProductsSoldComponent } from '../dashboard/widgets/products-sold/products-sold.component';
import { RecentContactsComponent } from '../dashboard/widgets/recent-contacts/recent-contacts.component';
import { TotalNetProfitComponent } from '../dashboard/widgets/total-net-profit/total-net-profit.component';
import { WeeklySalesComponent } from '../dashboard/widgets/weekly-sales/weekly-sales.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CreateDashboardComponent } from '../dashboard/pages/create-dashboard/create-dashboard.component';
import { DynamicComponentDirective } from '../dashboard/pages/update-dashboard/components/dynamic-component-loader.component';
import { UpdateDashboardComponent } from '../dashboard/pages/update-dashboard/update-dashboard.component';
import { DashboardNavComponent } from '../dashboard/pages/dashboard-nav/dashboard-nav.component';
import { ChartModule } from 'angular-highcharts';
import { AddWidgetComponent } from '../dashboard/pages/add-widget/add-widget.component';
import { HasPermissionDirective } from 'src/app/directive/has-permission.directive';
import { HttpClientModule } from '@angular/common/http';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SalesInvoiceComponent } from '../Invoice/sales-invoice/sales-invoice.component';

export const componentTypeMapping = {
  'TotalSalesComponent': TotalSalesComponent,
  'ProductsSoldComponent': ProductsSoldComponent,
  'NewCustomersComponent': NewCustomersComponent,
  'TotalNetProfitComponent': TotalNetProfitComponent,
  'WeeklySalesComponent': WeeklySalesComponent,
  'TotalPurchaseComponent': TotalPurchaseComponent,
  'StockDetailsComponent': StockDetailsComponent,
  'SalesPurchaseChartComponent': SalesPurchaseChartComponent,
  "NetProfitChartComponent": NetProfitChartComponent,
  'RecentContactsComponent': RecentContactsComponent,
  'LowStocksProductComponent': LowStocksProductComponent,
  'PendingPaymentComponent': PendingPaymentComponent,
  'CreditVendorComponent': CreditVendorComponent,
  'BestSellingProductsComponent': BestSellingProductsComponent,
};
@NgModule({
  declarations: [
    TotalSalesComponent,
    TotalPurchaseComponent,
    StockDetailsComponent,
    SalesPurchaseChartComponent,
    NetProfitChartComponent,
    AmountFormatPipe,
    OrderSummaryChartComponent,
    BestSellingProductsComponent,
    CreditVendorComponent,
    LowStocksProductComponent,
    NewCustomersComponent,
    PendingPaymentComponent,
    PriceRangeDialogComponent,
    ProductsSoldComponent,
    RecentContactsComponent,
    TotalNetProfitComponent,
    WeeklySalesComponent,
    CreateDashboardComponent,
    UpdateDashboardComponent,
    DashboardNavComponent,
    DynamicComponentDirective,
    AddWidgetComponent,
    HasPermissionDirective,
    // SalesInvoiceComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    HttpClientModule,
    MaterialExampleModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatIconModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    ChartModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule,
    DragDropModule,
    NgbModule,
    BsDatepickerModule,
    ChartModule,
    HttpClientModule,

    TotalSalesComponent,
    TotalPurchaseComponent,
    StockDetailsComponent,
    SalesPurchaseChartComponent,
    NetProfitChartComponent,
    AmountFormatPipe,
    OrderSummaryChartComponent,
    BestSellingProductsComponent,
    CreditVendorComponent,
    LowStocksProductComponent,
    NewCustomersComponent,
    PendingPaymentComponent,
    PriceRangeDialogComponent,
    ProductsSoldComponent,
    RecentContactsComponent,
    TotalNetProfitComponent,
    WeeklySalesComponent,
    CreateDashboardComponent,
    UpdateDashboardComponent,
    DashboardNavComponent,
    DynamicComponentDirective,
    AddWidgetComponent,
    MatSelectModule,
    MatAutocompleteModule,
    HasPermissionDirective,
    // SalesInvoiceComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CurrencyPipe, TitleCasePipe, NgbActiveModal],
})
export class SharedModule { }
