
 <div class="card mb-3 mt-3">
    <div class="theme-wizard mb-5 mb-lg-0 mb-xl-5 mb-xxl-0 h-100">
        <div class="card-header bg-light pt-3 pb-3">
            <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;" >
                Schedule
             </p>
            <span class="tab-slider--nav">
                    <ul class="tab-slider--tabs">
                      <li class="tab-slider--trigger" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="viewMode ='tab1'">Dashboard</li>
                      <li class="tab-slider--trigger" [class.active]="viewMode == 'tab2'" rel="tab2" (click)="viewMode ='tab2'">Admin</li>
                    </ul>
            </span>
        </div>
        <div class="m-2">
            <div class="tab-slider--container" [ngSwitch]="viewMode">
              <div id="tab1" class="tab-slider--body" *ngSwitchCase="'tab1'">
               <app-dashboard></app-dashboard>
              </div>
              <div id="tab2" class="tab-slider--body" *ngSwitchCase="'tab2'">
                <app-admin></app-admin>
              </div>
            </div>
        </div>
        </div>
</div>