import { Component, OnInit } from '@angular/core';
import { Gstr1Service } from 'src/app/core/services/gstr1.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-gst-report9',
  templateUrl: './gst-report9.component.html',
  styleUrls: ['./gst-report9.component.scss']
})
export class GstReport9Component implements OnInit {
  selectedYear: number;
  years: number[] = [];
  fileName = 'gst9Report.xlsx';
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))

  public clientDatafinancialYearFrom: any;
  public clientDatagstinNo: any;
  public clientDatalegalName: any;
  public clientDatatradeName: any;


  public gstr9AListunregistered: any;
  public gstr9AListregistered: any;
  public part2GList: any;

  public subTotalAtoGtotalCess: any;
  public subTotalAtoGintegrated: any;
  public subTotalAtoGstateTax: any;
  public subTotalAtoGintrastate: any;
  public subTotalAtoGtaxableValue: any;

  public pt3list: any;
  public pt3list6A: any;
  public pt3list6Cservice: any;




  constructor(private gstr1Service: Gstr1Service) {
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 2020; year--) {
      this.years.push(year);
    }
  }

  ngOnInit(): void {
    this.getGstr9Report();
    console.log("TEST 1", this.currentUser.id);
  }

  getGstr9Report() {
    console.log("TEST 2", this.currentUser.id);

    this.gstr1Service.gstr9Report({}, this.currentUser.id).then((res: any) => {
      console.log("Res", res)
      this.clientDatafinancialYearFrom = res.data.a.clientData.financialYearFrom;
      this.clientDatagstinNo = res.data.a.clientData.gstinNo;
      this.clientDatalegalName = res.data.a.clientData.legalName;
      this.clientDatatradeName = res.data.a.clientData.tradeName;

      this.gstr9AListunregistered = res.data.a.unregistered;
      this.gstr9AListregistered = res.data.a.registered;

      this.part2GList = res.data.a.part2G;

      this.subTotalAtoGtotalCess = res.data.a.subTotalAtoG.totalCess;
      this.subTotalAtoGintegrated = res.data.a.subTotalAtoG.integrated;
      this.subTotalAtoGstateTax = res.data.a.subTotalAtoG.stateTax;
      this.subTotalAtoGintrastate = res.data.a.subTotalAtoG.intrastate;
      this.subTotalAtoGtaxableValue = res.data.a.subTotalAtoG.taxableValue;

      this.pt3list = res.data.b.gst9_p3;
      this.pt3list6A = res.data.b.gstr9pt3_6A;
      this.pt3list6Cservice = res.data.b.gstr9pt3_6C_SERVICE;

    })

  }
  //   printComponent(cmpName) {
  //     let printContents = document.getElementById(cmpName).innerHTML;
  //     let originalContents = document.body.innerHTML;

  //     document.body.innerHTML = printContents;

  //     window.print();

  //     document.body.innerHTML = originalContents;

  // }

  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  onPrint() {
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
  }
  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }
}
