import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-price-range-dialog',
  templateUrl: './price-range-dialog.component.html',
  styleUrls: ['./price-range-dialog.component.scss']
})
export class PriceRangeDialogComponent implements OnInit {

  // priceRange: number;
  minPrice: number;
  maxPrice: number;
  constructor(public dialogRef: MatDialogRef<PriceRangeDialogComponent>) { }

  ngOnInit(): void {
    this.minPrice = 0; // Set default minimum price
    this.maxPrice = 100000
    // this.priceRange = 0; // Set the initial price range
    // this.onPriceRangeChange(); // Initialize the minPrice and maxPrice values
  }

  cancel(): void {
    this.dialogRef.close(); // Close the dialog without any changes
  }

  apply(): void {
    console.log("this.maxPrice, this.minPrice")
    console.log(this.maxPrice, this.minPrice)
    if (this.minPrice !== null && this.maxPrice !== null) {
      const priceRange = {
        minPrice: this.minPrice,
        maxPrice: this.maxPrice
      };
      console.log(priceRange)
      this.dialogRef.close(priceRange); // Pass the selected price range as the result
    } else {
      console.log('Price range not selected');
    }
  }

  Save(){
    
  }

  // onPriceRangeChange(): void {
  //   const priceStep = 0; // Adjust the step value based on your price range
  //   const rangePercentage = this.priceRange / 100;
  //   const priceRangeSpan = this.maxPrice - this.minPrice;
  //   this.minPrice = Math.floor(this.minPrice + rangePercentage * priceRangeSpan);
  //   this.maxPrice = Math.floor(this.minPrice + priceStep);

  //   // Adjust the maximum price to stay within the desired range
  //   if (this.maxPrice > this.minPrice + priceRangeSpan) {
  //     this.maxPrice = this.minPrice + priceRangeSpan;
  //   }
  // }
}
