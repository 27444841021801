import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleScheduleService {
  getAllSchByShopId

  constructor(public httpClient: HttpService,private http:HttpClient) { }

  getAllScheduleShopID(data: {},shopId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.appointment(data, UrlConstants.getAllSchByShopId + shopId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllSchedule(data: {}, userId, pageSize, page, shopId?): any {
    let url = UrlConstants.getAllSchedule + userId + "?size=" + pageSize + "&page=" + page;

    if(shopId){
      url += '&shopId=' + shopId;
    }

    return new Promise((resolve, reject) => {
      this.httpClient.appointment(data, url, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
