import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts'

import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { getChartOptions } from '../../chartOptions/chartOptions';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    // monthYearLabel: 'MMM YYYY',
    // dateA11yLabel: 'YYYY-MM-DD HH:mm:ss',
    // monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-weekly-sales',
  templateUrl: './weekly-sales.component.html',
  styleUrls: ['./weekly-sales.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class WeeklySalesComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public userId: string = '';
  public showProfile: boolean;
  public todaysDate = this.commonService.getTodaysDate();
  public totalSales: any;
  public permissionData: any;
  public canvas: any;
  public puchaseChart: any = [];
  public salesChart: any = [];
  public salesMonthlyChart: any = [];
  public puchaseMonthChart: any = [];
  public puchaseYearChart: any = [];
  public salesYearChart: any = [];
  public weekNames: any;
  public weekDates: any;
  public weekTotalSaleAmt: any;
  public pastWeekTotalSaleAmt: any;
  public weekNamesSales: any;
  public weekSalesDateValue: Date;
  public monthSalesDateValue: Date;
  public yearSalesDateValue: Date;
  public lastweekSalesDateValue: Date;
  public lastmonthSalesDateValue: Date;
  public lastyearSalesDateValue: Date;
  public currentMonthtotalBillAmount: any[];
  public pastMonthTotalBillAmount: any[];
  public monthDay: any;
  public pastmonthDay: any;
  public yearlySalesDate: any;
  public lastyearlySalesDate: any;
  public yearlySalestotalBillAmount: any;
  public lastYearlySalestotalBillAmount: any;
  public isDay: boolean = true;
  public isWeek: boolean = false;
  public isMonth: boolean = false;
  public isYear: boolean = false;
  public salesType: string = 'Daily';
  public maxDate = new Date();
  public newSaleDate;
  public defaultSaleSelect = 'Daily';
  @Input() chartType: any; // Default chart type
  @Input() chartData: any; // Default chart type
  @Input() chartId: any; // Default chart type
  public currentChartType: string = 'spline'; // Default chart type
  lineChart: Chart;
  @Input() xAxisTitle: string = 'Days'; // Default x-axis title
  @Input() yAxisTitle: string = 'Sale Amount'; // Default y-axis title
  @Input() xAxisCategories: any[] = []; // Default x-axis categories
  dailySalesData: any;
  yesterdaySalesData: any;

  constructor(
    public rolePermission: RolesPermissionsService,
    public authorizationService: AuthorizationService,
    public dashboardService: DashboardService,
    public commonService: CommonService,
    public router: Router,
    public toastService: ToastNotificationService,
  ) {
    // Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.weekSalesDateValue = new Date();
    this.lastweekSalesDateValue = new Date();
    this.monthSalesDateValue = new Date();
    this.lastmonthSalesDateValue = new Date();
    this.yearSalesDateValue = new Date();
    this.lastyearSalesDateValue = new Date();

    this.getTotalSalesDailyReport();  
    this.dashboardService.widgetData$.subscribe(data => {
      if (data) {
        const chartData = JSON.parse(data.chartData);
        if(chartData.component === 'WeeklySalesComponent'){
          this.chartType = data.chartType;
          this.chartId = data.chartId;
        }
        // Initialize your chart here using this.chartType, this.chartId, and this.chartData
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyChart();
  }

  private initChart(chartType, chartData, categories): void {
    this.destroyChart(); // Destroy any existing chart instance
    if(categories){
      this.xAxisCategories = categories;
    }
    // const options = { ...lineChartOptions, chart: { ...lineChartOptions.chart, type: this.chartType } };
    const options = getChartOptions(chartType, chartData, this.xAxisTitle, this.yAxisTitle, this.xAxisCategories);

    // if (chartData) {
    //   options.series = chartData;
    // }

    this.lineChart = new Chart(options);
  }

  getSaleInvoiceReportType(type) {
    this.salesType = type;
    if (type === 'Daily') {
      this.isDay = true;
      this.isWeek = false;
      this.isMonth = false;
      this.isYear = false;
      this.getTotalSalesDailyReport()
    } else if (type === 'Weekly') {
      this.isDay = true;
      this.isWeek = true;
      this.isMonth = false;
      this.isYear = false;
      this.getTotalInvoiceWeekReport();
    } else if (type === 'Monthly') {
      this.isDay = true;
      this.isWeek = false;
      this.isMonth = true;
      this.isYear = false;
      this.getTotalInvoiceMonthReport();
    } else if (type === 'Yearly') {
      this.isDay = true;
      this.isWeek = false;
      this.isMonth = false;
      this.isYear = true;
      this.getTotalInvoiceYearReport();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartType) {
      this.getSaleInvoiceReportType('Daily');
    }
  }
  
  private destroyChart() {
    if (this.lineChart) {
      this.lineChart.ref$.subscribe(chart => {
        chart.destroy();
      });
    }
  }

  private formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
  getTotalSalesDailyReport() {
    if (this.currentUser.id) {
      // if (this.newPurchaseDate) {
      //     this.todaysDate = this.newPurchaseDate;
      // }
      let chartData = {}
      const today = new Date(this.todaysDate);
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      const yesterdayFormatted = `${yesterday.getFullYear()}-${yesterday.getMonth() + 1}-${yesterday.getDate()}`;
      this.dashboardService.getDayWiseInvoiceReport({}, this.currentUser.id, this.todaysDate, "SALES").then(async (res) => {
          this.dailySalesData = res.data.dataset.map((data: any) => {
              return {
                  date: data.date,
                  totalAmount: data.totalBillAmount
              };
          });

          this.dashboardService.getDayWiseInvoiceReport({}, this.currentUser.id, yesterdayFormatted, "SALES").then(async (res) => {
            this.yesterdaySalesData = res.data.dataset.map((data: any) => {
              return {
                  date: data.date,
                  totalAmount: data.totalBillAmount
              };
            });
            const isPastDayDataEmpty = this.yesterdaySalesData[0]?.totalAmount === (null || undefined);
            const isCurrentDayDataEmpty = this.dailySalesData[0]?.totalAmount === (null || undefined);
            if (isPastDayDataEmpty && isCurrentDayDataEmpty) {
              this.currentChartType = 'NoData';
            } else {
              this.currentChartType = this.chartType;
              chartData = [
                {
                    name: 'Yesterday',
                    data: [(this.yesterdaySalesData[0]?.totalAmount || 0), 0],
                    color: '#FC7643',
                },
                {
                    name: 'Today',
                    data: [(this.dailySalesData[0]?.totalAmount || 0), 0],
                    color: '#110152',
                }
              ]
            }
            this.initChart(this.currentChartType, chartData, [this.formatDate(yesterday), this.formatDate(new Date(today))]);
          });
      });
      this.initChart('NoData', {}, []);
    } else {
        this.router.navigate(["/auth"]);
    }
  }

  getTotalInvoiceWeekReport() {
    if (this.newSaleDate) {
      this.todaysDate = this.newSaleDate;
    }
    var chartData = {};
    const today = new Date(this.todaysDate);
    const lastWeek = new Date( today.getFullYear(), today.getMonth(), today.getDate() - 7 );
    const lastWeekStartDate = `${lastWeek.getFullYear()}-${lastWeek.getMonth() + 1}-${lastWeek.getDate()}`;
    // this api call for the latest sales of the current week report
    this.dashboardService.getTotalInvoiceWeekReport( {}, this.currentUser.id, this.todaysDate, 'SALES')
      .then((res) => {
        this.weekNamesSales = res.data.dataset.map((names: any) => {
          const dayName = names.dayName;
          const abbreviatedDayName = dayName.substring(0, 3);
          return abbreviatedDayName;
        });
        this.weekTotalSaleAmt = res.data.dataset.map((total: any) => total.totalBillAmount);
        let date = res.data.dataset.map((date: any) => date.date);
        this.dashboardService.getTotalInvoiceWeekReport({}, this.currentUser.id, lastWeekStartDate, 'SALES')
          .then((res) => {
            this.pastWeekTotalSaleAmt = res.data.dataset.map((total: any) => total.totalBillAmount);
            let date = res.data.dataset.map((date: any) => date.date);

            const isPastWeekDataEmpty = this.pastWeekTotalSaleAmt.every((value) => value === 0);
            const isCurrentWeekDataEmpty = this.weekTotalSaleAmt.every((value) => value === 0);
            if (isPastWeekDataEmpty && isCurrentWeekDataEmpty) {
              this.currentChartType = 'NoData';
            } else {
              this.currentChartType = this.chartType;            
              chartData = [
                {
                  'name': 'Past Week',
                  'data' : this.pastWeekTotalSaleAmt,
                },
                {
                  'name': 'Current Week',
                  'data' : this.weekNamesSales,
                }
              ]
            }
            this.initChart(this.currentChartType, chartData, this.weekNamesSales);
          });
      });
      this.initChart('NoData', {}, []);
  }

  // plotWeeklyChart() {
  //   this.salesChart = Highcharts;
  //   // noData(Highcharts);

  //   const isPastWeekDataEmpty = this.pastWeekTotalSaleAmt.every(
  //     (value) => value === 0
  //   );
  //   const isCurrentWeekDataEmpty = this.weekTotalSaleAmt.every(
  //     (value) => value === 0
  //   );
  //   if (isPastWeekDataEmpty && isCurrentWeekDataEmpty) {
  //     // Display a "No data found" message
  //     const chartOptions = {
  //       chart: {
  //         type: this.chartType || 'spline',
  //         events: {
  //           load: function () {
  //             const chart = this;
  //             const centerX = chart.plotWidth / 2;
  //             const centerY = chart.plotHeight / 2;

  //             // Add the image element
  //             chart.noDataImage = chart.renderer
  //               .image(
  //                 '../../../assets/img/icons/spot-illustrations/notfound1.png',
  //                 centerX - 90,
  //                 centerY - 70,
  //                 200,
  //                 150
  //               )
  //               .add();
  //           },
  //         },
  //       },
  //       title: {
  //         text: ' ',
  //         // text: this.salesType + ' Sales Report',
  //         style: {
  //           fontFamily: "'Poppins', sans-serif",
  //           fontWeight: '500',
  //           fontSize: '16px',
  //           color: '#737791',
  //         },
  //       },
  //       credits: {
  //         enabled: false,
  //       },
  //     };

  //     // Render the chart
  //     this.salesChart.chart('salesCanvas', chartOptions);
  //     document.getElementById('salesCanvas').style.height = '300px';
  //   } else {
  //     const chartOptions = {
  //       chart: {
  //         type: this.chartType || 'spline',
  //         height: 350
  //       },
  //       credits: {
  //         enabled: false,
  //       },
  //       title: {
  //         text: ' ',
  //         // text: this.salesType + ' Sales Report',
  //         style: {
  //           fontFamily: "'Poppins', sans-serif",
  //           fontWeight: '500',
  //           fontSize: '16px',
  //           color: '#737791',
  //         },
  //       },
  //       xAxis: {
  //         title: {
  //           text: 'Days',
  //         },
  //         categories: this.weekNamesSales,
  //       },
  //       yAxis: {
  //         title: {
  //           text: 'Sale Amount',
  //         },
  //         gridLineWidth: 0,
  //       },
  //       plotOptions: {
  //         series: {
  //           animation: {
  //             duration: 3000,
  //           },
  //           cursor: 'pointer',
  //         },
  //       },
  //       series: [
  //         {
  //           name: 'Past Week',
  //           data: this.pastWeekTotalSaleAmt,
  //           color: '#FC7643',
  //         },
  //         {
  //           name: 'Current Week',
  //           data: this.weekTotalSaleAmt,
  //           color: '#110152',
  //         },
  //       ],
  //     };

  //     this.salesChart.chart('salesCanvas', chartOptions);
  //     // document.getElementById('salesCanvas').style.height = '300px';
  //   }
  // }

  /**
   * Total Sales Invoice Month Report
   */
  getTotalInvoiceMonthReport() {
    if (this.newSaleDate) {
      this.todaysDate = this.newSaleDate;
    }
    var chartData = {};
    const today = new Date(this.todaysDate);
    const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const oneMonthAgoDateString = `${oneMonthAgo.getFullYear()}-${oneMonthAgo.getMonth() + 1}-${oneMonthAgo.getDate()}`;
    // this API call returns current month data
    this.dashboardService.getTotalInvoiceMonthReport({}, this.currentUser.id, this.todaysDate, 'SALES')
      .then(async (res) => {
        this.monthDay = Object.values(res.data.dataset).map(
          (names: any) => names.day.split('-')[2]
        );
        this.currentMonthtotalBillAmount = Object.values(res.data.dataset).map(
          (total: any) => total.totalBillAmount
        );
        this.dashboardService.getTotalInvoiceMonthReport({}, this.currentUser.id, oneMonthAgoDateString, 'SALES')
          .then(async (res) => {
            this.pastmonthDay = Object.values(res.data.dataset).map((names: any) => names.day.split('-')[2]);
            this.pastMonthTotalBillAmount = Object.values(res.data.dataset).map((total: any) => total.totalBillAmount);

            const isPastMonthDataEmpty = this.pastMonthTotalBillAmount.every((value) => value === 0);
            const isCurrentMonthDataEmpty = this.currentMonthtotalBillAmount.every((value) => value === 0);
            if (isPastMonthDataEmpty && isCurrentMonthDataEmpty) {
              this.chartType = 'NoData';
            } else {
              this.currentChartType = this.chartType;
              chartData = [
                {
                  'name': 'Past Month',
                  'data' : this.currentMonthtotalBillAmount,
                },
                {
                  'name': 'Current Month',
                  'data' : this.pastMonthTotalBillAmount,
                }
              ]
            }
            
            this.initChart(this.currentChartType, chartData, this.pastmonthDay);
          });
      });
      this.initChart('NoData', {}, []);
  }

  // plotMonthChart() {
  //   this.salesChart = Highcharts;
  //   const isPastMonthDataEmpty = this.pastMonthTotalBillAmount.every(
  //     (value) => value === 0
  //   );
  //   const isCurrentMonthDataEmpty = this.currentMonthtotalBillAmount.every(
  //     (value) => value === 0
  //   );
  //   if (isPastMonthDataEmpty && isCurrentMonthDataEmpty) {
  //     // Display a "No data found" message
  //     console.log("chart type", this.chartType)
  //     const chartOptions = {
  //       chart: {
  //         type: this.chartType || 'spline',
  //         events: {
  //           load: function () {
  //             const chart = this;
  //             const centerX = chart.plotWidth / 2;
  //             const centerY = chart.plotHeight / 2;

  //             // Add the image element
  //             chart.noDataImage = chart.renderer
  //               .image(
  //                 '../../../assets/img/icons/spot-illustrations/notfound1.png',
  //                 centerX - 90,
  //                 centerY - 70,
  //                 200,
  //                 150
  //               )
  //               .add();
  //           },
  //         },
  //         height: 350
  //       },
  //       title: {
  //         text: ' ',
  //         style: {
  //           fontFamily: "'Poppins', sans-serif",
  //           fontWeight: '500',
  //           fontSize: '16px',
  //           color: '#737791',
  //         },
  //       },
  //       xAxis: {
  //         categories: this.weekNames,
  //       },
  //       credits: {
  //         enabled: false,
  //       },
  //     };
  //     this.salesChart.chart('salesCanvas', chartOptions);
  //     document.getElementById('salesCanvas').style.height = '300px';
  //   } else {
  //     // Data available, render the chart
  //     console.log("chart type", this.chartType)
  //     const chartOptions = {
  //       chart: {
  //         type: this.chartType || 'spline',
  //         height: 350
  //       },
  //       title: {
  //         text: ' ',
  //         style: {
  //           fontFamily: "'Poppins', sans-serif",
  //           fontWeight: '500',
  //           fontSize: '16px',
  //           color: '#737791',
  //         },
  //       },
  //       credits: {
  //         enabled: false,
  //       },
  //       xAxis: {
  //         categories: this.pastmonthDay,
  //       },
  //       yAxis: {
  //         title: {
  //           text: 'Sales Amount',
  //         },
  //         gridLineWidth: 0,
  //       },
  //       plotOptions: {
  //         series: {
  //           animation: {
  //             duration: 3000,
  //           },
  //           cursor: 'pointer',
  //         },
  //       },
  //       series: [
  //         {
  //           name: 'Past Month',
  //           data: this.pastMonthTotalBillAmount,
  //           color: '#FC7643',
  //         },
  //         {
  //           name: 'Current Month',
  //           data: this.currentMonthtotalBillAmount,
  //           color: '#110152',
  //         },
  //       ],
  //     };
  //     this.salesChart.chart('salesCanvas', chartOptions);
  //     document.getElementById('salesCanvas').style.height = '300px';
  //   }
  // }

  /**
   * Total Sales Invoice Year Report
   */
  getTotalInvoiceYearReport() {
    if (this.newSaleDate) {
      this.todaysDate = this.newSaleDate;
    }
    var chartData = {}
    const oneYearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD');
    this.dashboardService.getTotalInvoiceYearReport({}, this.currentUser.id, this.todaysDate, 'SALES')
      .then((res) => {
        this.yearlySalesDate = res.data.dataset.map((names: any) => names.date + '/' + names.year);
        this.yearlySalestotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
        this.dashboardService.getTotalInvoiceYearReport({}, this.currentUser.id, oneYearAgo, 'SALES')
          .then((res) => {
            this.lastyearlySalesDate = res.data.dataset.map((names: any) => names.date + '/' + names.year);
            this.lastYearlySalestotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);

            const isPastYearDataEmpty = this.lastYearlySalestotalBillAmount.every((value) => value === 0);
            const isCurrentYearDataEmpty = this.yearlySalestotalBillAmount.every((value) => value === 0);
            if (isPastYearDataEmpty && isCurrentYearDataEmpty) {
              this.currentChartType = 'NoData';
            } else {
              this.currentChartType = this.chartType;
              chartData = [
                {
                  'name': 'Past Year',
                  'data' : this.lastYearlySalestotalBillAmount,
                },
                {
                  'name': 'Current Year',
                  'data' : this.yearlySalestotalBillAmount,
                }
              ]
            }
          
            this.initChart(this.currentChartType, chartData, this.lastyearlySalesDate);
          });
        });
        this.initChart('NoData', {}, []);
      }

  // plotYearlyChart() {
  //   this.salesChart = Highcharts;
  //   const isPastYearDataEmpty = this.lastYearlySalestotalBillAmount.every(
  //     (value) => value === 0
  //   );
  //   const isCurrentYearDataEmpty = this.yearlySalestotalBillAmount.every(
  //     (value) => value === 0
  //   );
  //   if (isPastYearDataEmpty && isCurrentYearDataEmpty) {
  //     // Display a "No data found" message
  //     const chartOptions = {
  //       chart: {
  //         type: this.chartType || 'spline',
  //         events: {
  //           load: function () {
  //             const chart = this;
  //             const centerX = chart.plotWidth / 2;
  //             const centerY = chart.plotHeight / 2;

  //             // Add the image element
  //             chart.noDataImage = chart.renderer
  //               .image(
  //                 '../../../assets/img/icons/spot-illustrations/notfound1.png',
  //                 centerX - 90,
  //                 centerY - 70,
  //                 200,
  //                 150
  //               )
  //               .add();
  //           },
  //         },
  //       },
  //       title: {
  //         text: ' ',
  //         style: {
  //           fontFamily: "'Poppins', sans-serif",
  //           fontWeight: '500',
  //           fontSize: '16px',
  //           color: '#737791',
  //         },
  //       },
  //       xAxis: {
  //         categories: this.weekNames,
  //       },
  //       credits: {
  //         enabled: false,
  //       },
  //     };
  //     this.salesChart.chart('salesCanvas', chartOptions);
  //     document.getElementById('salesCanvas').style.height = '300px';
  //   } else {
  //     console.log("chart type", this.chartType)
  //     const chartOptions = {
  //       chart: {
  //         type: this.chartType || 'spline',
  //         zoomType: 'xy',
  //       },
  //       credits: {
  //         enabled: false,
  //       },
  //       title: {
  //         text: ' ',
  //         style: {
  //           fontFamily: "'Poppins', sans-serif",
  //           fontWeight: '500',
  //           fontSize: '16px',
  //           color: '#737791',
  //         },
  //       },
  //       xAxis: {
  //         categories: this.yearlySalesDate,
  //         // categories: this.lastyearlySalesDate + '-' + this.yearlySalesDate
  //       },
  //       yAxis: {
  //         title: {
  //           text: 'Sales Amount',
  //         },
  //         min: 0,
  //         gridLineWidth: 0,
  //       },
  //       plotOptions: {
  //         series: {
  //           animation: {
  //             duration: 3000,
  //           },
  //           cursor: 'pointer',
  //           dragDrop: {
  //             draggableY: true, // enable Y-axis dragging
  //             // dragMinY: 0, // minimum Y-axis value
  //             // dragMaxY: 100, // maximum Y-axis value
  //             // dragPrecisionY: 1, // Y-axis precision
  //             dragCursor: 'ns-resize', // cursor style during drag
  //             dragStart: function (e) {
  //               console.log('Drag started on series:', e.target.name);
  //             },
  //             dragEnd: function (e) {
  //               console.log('Drag ended on series:', e.target.name);
  //             },
  //           },
  //         },
  //       },
  //       series: [
  //         {
  //           name: 'Past Year',
  //           data: this.lastYearlySalestotalBillAmount,
  //           color: '#FC7643',
  //         },
  //         {
  //           name: 'Current Year',
  //           data: this.yearlySalestotalBillAmount,
  //           color: '#110152',
  //         },
  //       ],
  //     };
  //     this.salesChart.chart('salesCanvas', chartOptions);
  //     document.getElementById('salesCanvas').style.height = '300px';
  //   }
  // }

}
