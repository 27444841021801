import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import lang from "src/assets/langTranslation/language_translation"
import { FormArray, FormGroup } from '@angular/forms';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { PartyService } from 'src/app/core/services/party.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { TitleCasePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaleOrderService } from 'src/app/core/services/sale-order.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WarehouseService } from 'src/app/core/services/warehouse.service';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { Observable } from 'rxjs';
import { ProductService } from 'src/app/core/services/product.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import moment from 'moment';

@Component({
  selector: 'app-sale-order',
  templateUrl: './sale-order.component.html',
  styleUrls: ['./sale-order.component.scss']
})
export class SaleOrderComponent implements OnInit {

  saveAsDraft: boolean = false;
  public gstRate = 0;
  @ViewChild('closebutton') closebutton;
  public lang = lang.UAE;
  public SaleOrderForm: FormGroup;
  public isShippingAddressinModal: any;
  public isShowGstFields: boolean = true;
  public gstDetails: any
  public isShown: boolean = false;
  public allPartyList: any;
  public filteredOptions: any;
  public myControl = new FormControl();
  public partyId: any;
  public partyData: any
  public unitValue: any;
  public index: any;
  public product: any;
  public amount = 0;
  public qty = 0;
  public unit: any;
  public wtax: any;
  public wotax: any;
  public ramount: any;
  public taxAmount = 0
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  public today: Date;
  public maxDate: Date;
  public isDisabledGST: boolean = true;
  public submittedParty: boolean = false;
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public finalObject;
  public todaysDate: any;
  public todaysDate2: any;
  public sendArray: any = [];
  public totalPrice: any;
  public barcode: any;
  public refNo: any;
  public saleInvoiceNo;
  public SaleReturnNo;
  public subTotal = 0;
  public totalGST = 0;
  public total = 0;
  public additionalCess: number = 0;
  public productData: any = [];
  public gstType: any;
  public gstInRs: any
  public gstInPer: any;
  public compositeType: any;
  public lblColor: boolean = false;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public gstAmount = 0;
  public supplytype: any;
  public submitted: boolean = false;
  public SaleOrderGst:any;
  public gstStateMatch: boolean = true;
  public userProfileGSTNo: any;
  imageLoaded: boolean = false;
  public purchaseImage:any;
  public imageSrc: any = '';
  public imageSrc1: any = '';
  public paramID: any;
  public AddReceiptName:any;
  PartyForm: FormGroup;
  warehouseList: any;
  public myControl1 = new FormControl();
  searchedProduct: any;
  productOptions: Observable<any>;
  lastInvoiceNumber: any;
  public productPicUrl: string = '';
  draftId: any;


  constructor(private saleorderservice: SaleOrderService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<SaleOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public estimationData: any,
    private partyService: PartyService,
    private toastService: ToastNotificationService,
    private saleInvoiceService: SaleInvoiceService,
    private titleCasePipe: TitleCasePipe,
    private profileService: ProfileService,
    private warehouseService: WarehouseService,
    private biddingService: BiddingService,
    private sharedDataService: SharedDataService,
    private modal: NgbModal,private router: Router,private productService: ProductService,) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }

  ngOnInit(): void {
    this.getAllParties();
    this.getProfileData();
    this.getWarehouseList();
    this.getSaleInvoiceNumber();
    this.todaysDate = new Date();
    this.todaysDate2 = new Date();
    this.newAttribute = { unit: "", ramount: "", wtax: "", wotax: "", index: "", product: "", gty: "", select: "" };
    this.fieldArray.push(this.newAttribute);
    this.SaleOrderForm = this.formBuilder.group({
      partyName: [null],
      orderNo: [null],
      MobileNo: [null],
      orderDate: [null,[Validators.required]],
      dueDate: [null, [Validators.required]],
      PurchaseType: ['false', Validators.required],
      Description: [null],
      billingAddress:[null],
      warehouse: [null],
      customerName: [null],
      shipmentCharges: [0],
      // mobile: [null],
      // subcheckbox: this.formBuilder.array([]),
    });

    this.PartyForm = this.formBuilder.group({
      gstType: [null],
      gstIn: [
        null,
        [
          Validators.minLength(15),
          Validators.maxLength(15),
        ],
      ],
      partyName: [null, [Validators.required]],
      mobile: [ null,
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern("^[0-9]*$"),
          Validators.required,
        ],
      ],
      email: [
        null,
        [
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"), Validators.email,
          Validators.required,
        ],
      ],
      billingAddress: [null, [Validators.required]],
      shippingAddress: [null],
      balanceStatus: ["1"],
      isCustomer: [false],
    });

    console.log("estimation data -- ",this.estimationData);

    if (this.estimationData && this.estimationData.draftData?.id) {
      this.setEstimateToSaleForm(this.estimationData)
    }

    const shipmentChargesControl = this.SaleOrderForm.get('shipmentCharges');

    // Subscribe to valueChanges of counterDiscount control
    shipmentChargesControl?.valueChanges.subscribe(value => {
      const maxAllowedAmount = this.total - 1;

      if (value && value > maxAllowedAmount) {
        // If the entered value exceeds the maximum allowed, set it to the maximum
        shipmentChargesControl?.setValue(maxAllowedAmount);
      }
    });
  }

  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.userProfileGSTNo = res.data.additional.gstinNo ? res.data.additional.gstinNo.slice(0, 2) : "";
    }))
  }

  get f() {
    return this.SaleOrderForm.controls;
  }

  handleInputChange(e) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.SaleOrderForm.patchValue({
          file: reader.result,
        });

        if (file == null) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });

        } else {

          this.imageSrc = reader.result;

          formdata.set("upload", file)
          e.srcElement.value = "";
          this.productService.postCoverImage1(formdata).then((res) => {
            this.productPicUrl = res.data.locations[0];
            this.toastService.toastMsg({
              title: "Success",
              content: "Receipt uopload Successfully!",
            });
          });
        }
      }
    }
  }

  setEstimateToSaleForm(estimationData: any) {
    this.saleInvoiceService.getSaleInvoiceById({}, this.estimationData.draftData.id).then((saleInvoiceData: any) => {
      if(saleInvoiceData.success){
        for (let index = 0; index < saleInvoiceData.data.sales_products.length; index++) {
          const element = saleInvoiceData.data.sales_products[index];
          this.productData.push({
            basePrice: element.pricePerUnit,
            salePrice: element.totalPrice,
            gstRate: element.gst,
            ...element
          })
        }
        this.myControl.patchValue(estimationData.draftData.customerName);
        this.draftId = estimationData.draftData?.id
        console.log("Inside this.estimationData.draftData.id", estimationData.draftData);
        console.log("estimationData.draftData.shipmentCharges", estimationData.draftData.shipmentCharges)
        console.log("estimationData.draftData.dueDate", estimationData.draftData?.dueDate)
        console.log("estimationData.draftData.description", estimationData.draftData?.description)
        this.SaleOrderForm.patchValue({
          // gstNo: saleInvoiceData.data.party.gstIn,
          // billingAddress: saleInvoiceData.data.party.billingAddress,
          // shippedFrom: saleInvoiceData.data.party.shippingAddress,
          // ewayBillNo: saleInvoiceData.data.party.ewayBillNo,
          // credit: saleInvoiceData.data.credit ? true : false,
          // cashSale: saleInvoiceData.data.cashSale ? true : false,
          // reverseCharge: saleInvoiceData.data.reverseCharge ? true : false,
          shipmentCharges: saleInvoiceData.data.shipmentCharges ? saleInvoiceData.data.shipmentCharges : 0,

          orderNo: estimationData.draftData?.invoiceNo,
          orderDate: moment(estimationData.draftData?.orderDate).format("DD-MMM-YYYY"),
          PurchaseType: estimationData.draftData?.credit ? 'true' : 'false',
          dueDate: moment(estimationData.draftData?.dueDate).format("DD-MMM-YYYY"),
          // dueDate: estimationData.draftData?.dueDate,
          billingAddress: estimationData.draftData?.billingAddress,
          shippingAddress: estimationData.draftData?.shippingAddress,
          ewayBillNo: estimationData.draftData?.ewayBillNo,
          Description: estimationData.draftData?.description,
          // shipmentCharges: estimationData.draftData?.shipmentCharges ? parseFloat(estimationData.shipmentCharges) : 0,
          reverseCharge: estimationData.draftData?.reverseCharge,
          counterDiscount: estimationData.draftData?.counterDiscountAmount,
          gstNo: estimationData.draftData?.party?.gstIn,
        });
        if (this.estimationData && this.estimationData.item && this.estimationData.item.vendor) {
          this.myControl.patchValue(this.estimationData.item.vendor.partyName);
        }

        // this.myControl.patchValue(this.estimationData.item.party.partyName)
        this.setSelectedPartyDeatails({
          id: saleInvoiceData.data.partyId,
          partyName: saleInvoiceData.data.party.partyName
        })

        // for (let i = 0; i <= this.productData.length; i++) {
        //   this.addDetailsButtonClick(i);
        // }
        this.calculationTotal();
      }
    });
  }


  startUpload(){
    document.getElementById('uploadFile').click();
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  cancelImage(){
    this.productPicUrl = '';
    this.imageSrc=null;
    this.imageLoaded=false;
    this.toastService.toastMsg({
      title: "Success",
      content: "Receipt removed Successfully!",
    });
  }

  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  getAllParties() {
    this.partyService
      .getAllPartiesDetails({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
      .then((res) => {
        this.allPartyList = res.data.pageData;
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
      });
  }
    get fp() {
    return this.PartyForm.controls;
  }

  validateTRN(TRN) {
    if (TRN.length === 15) {

        this.isDisabledGST = false;

    } else {
      this.isDisabledGST = true;
      this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
      // this.setGSTINError = true;
    }
  }

  async calculationTotal() {
    this.barcode = "";
    let price = 0;
    let additionalCess = 0;
    let gstInPer = 0;
    this.totalPrice = 0;
    this.subTotal = 0;
    this.gstRate = 0;
    this.productData.forEach((element) => {
      let gstInPer = element.gstRate;
      price = price + (element.salePrice * element.quantity);
      this.gstRate = ((element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity) + this.gstRate
      this.gstAmount = (element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity
      additionalCess = element.salePrice * element.additionalCess / 100;
      this.subTotal = (element.basePrice * element.quantity) + this.subTotal;
      element.subTotal = element.basePrice * element.quantity;
      element.gstAmount = (element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity
      element.total = element.salePrice * element.quantity;
      gstInPer = element.gstRate;
      if (this.isShown) {
        this.totalPrice = price;
      } else {
        this.totalPrice = price;
      }
    });
    if (this.gstType === 'Exempted') {
      this.totalGST = 0;
      this.gstInRs = 0;
      this.gstInPer = 0
    } else if (this.gstType === 'Composite') {
      if (this.compositeType == 'Manufacturer' || this.compositeType == 'Trader') {
        this.totalGST = this.subTotal * 1 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else if (this.compositeType == 'Restaurant') {
        this.totalGST = this.subTotal * 5 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else {
        this.totalGST = this.subTotal * 6 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      }
    } else {
      this.totalGST = this.gstRate;
      this.gstInRs = this.gstRate;
      this.gstInPer = gstInPer;
    }

    this.additionalCess = additionalCess
    if (this.isShown) {
      this.total = price;
    } else {
      this.total = price;
    }
  }

  setSelectedPartyDeatails(item){
    this.partyId = item.id;
    if(this.partyData) {
      this.partyData.forEach((ele) => {
        this.SaleOrderGst=ele.gstIn
        this.SaleOrderForm.patchValue({
          'billingAddress': ele.billingAddress,
          'customerName': ele.partyName,
          'MobileNo': ele.mobile,
        })
        if (this.SaleOrderGst.slice(0, 2) === this.userProfileGSTNo) {
          this.gstStateMatch = true
          this.supplytype = "INTRASTATE"
        }
        else {
          this.gstStateMatch = false;
          this.supplytype = "INTERSTATE"
        }
      })
    } else {
      this.saleInvoiceService.getPartyByName({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item.partyName)
      .then((response: any) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        this.allPartyList.forEach((ele) => {
          this.SaleOrderGst = ele.gstIn
          this.SaleOrderForm.patchValue({
            'billingAddress': ele.billingAddress,
            'customerName': ele.partyName,
            'MobileNo': ele.mobile,
          })
          if (this.SaleOrderGst.slice(0, 2) === this.userProfileGSTNo) {
            this.gstStateMatch = true
            this.supplytype = "INTRASTATE"
          }
          else {
            this.gstStateMatch = false;
            this.supplytype = "INTERSTATE"
          }
        });
      })
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(name => name ? this.filterNames(name) : this.allPartyList.slice())
    );
    }
  }

  changeQtyCalculation(i) {
    this.productData[i].quantity

    this.calculationTotal();
  }

  changeQuantity(sign, i, quantity = 0) {
    switch (sign) {
      case "-":
        if (this.productData[i].quantity > 1) {
          this.productData[i].quantity = this.productData[i].quantity - 1;
        }
        this.calculationTotal();
        break;

      case "+":
        this.productData[i].quantity = this.productData[i].quantity + 1;
        this.calculationTotal();
        break;

      case "rm":
        this.productData.splice(i, 1);
        if (this.productData.length <= 0) {
          this.totalGST = 0;
          this.subTotal = 0;
          this.total = 0;
        }
        this.calculationTotal();
        break;
      case "qty":
        this.productData[i].quantity = quantity;
        this.calculationTotal();
        break;
      default:
        this.productData[i];
        break;
    }
  }

  // getGoodsByBarcode() {
  //   this.saleInvoiceService.getGoodsByBarcode({}, this.barcode, this.currentUser.id)
  //     .then((response) => {
  //       // Check if the barcode already exists in productData
  //       const existingProductIndex = this.productData.findIndex(
  //         (product) => product.barcode === response.data.barcode
  //       );

  //       if (existingProductIndex !== -1) {
  //         // Barcode exists, update quanti
  //         this.productData[existingProductIndex].quantity += 1;
  //       } else {
  //         // Barcode doesn't exist, add new product
  //         response.data.quantity = 1;
  //         this.productData.push(response.data);
  //       }
  //       this.calculationTotal();
  //     });
  // }

  getCredit(e) {
    if (e.value == "true") {
      e.value = true;
    } else {
      e.value = false;
    }
    this.SaleOrderForm.value.PurchaseType = e.value;
  }

  setSaveAsDraft() {
    this.saveAsDraft = true;
    this.submitted = true;
    if (this.SaleOrderForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    } else {
      if (this.productData.length < 1) {

        this.toastService.toastMsg({
          title: "Error",
          content: "Please Add At Least One Product To Proceed!",
        });
        return false;
      }

    this.productData.forEach(e => {
      this.sendArray.push({
        quantity: e.quantity,
        barcode: e.barcode,
      });
    })

    const userType = this.currentUser.userType.toLowerCase();
    const warehouseIdToSend =
      userType === 'warehouse' ? this.currentUser.id :
      userType === 'shop' ? (this.SaleOrderForm.value.warehouse || this.currentUser.id) :
      userType === 'client' && this.SaleOrderForm.value.warehouse !== null ? this.SaleOrderForm.value.warehouse : this.currentUser.id;
    let data = {
      invoiceDetails: {
        invoiceDate: moment(this.SaleOrderForm.value.orderDate, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
        invoiceNo: this.SaleOrderForm.value.orderNo,
        shippedFrom: "",
        ewayBillNo: "2123123",
        Description: this.SaleOrderForm.value.Description,
        billingAddress: this.SaleOrderForm.value.billingAddress,
        shippingAddress: this.SaleOrderForm.value.billingAddress ? this.SaleOrderForm.value.billingAddress : "",
        credit: this.SaleOrderForm.value.PurchaseType ? this.SaleOrderForm.value.PurchaseType.toString() : false,
        userId: this.SaleOrderForm.value.warehouse ? this.SaleOrderForm.value.warehouse : this.currentUser.id,
        partyId: this.partyId,
        rcm: false,
        shipmentCharges: this.SaleOrderForm.value.shipmentCharges ? parseFloat(this.SaleOrderForm.value.shipmentCharges) : 0,
        supplyType: null,
        dueDate: moment(this.SaleOrderForm.value.dueDate, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
        warehouseId: `${warehouseIdToSend}`,
        cashSale: false,
        mobile: this.SaleOrderForm.value?.MobileNo ? this.SaleOrderForm.value?.MobileNo : null,
        customerName: this.SaleOrderForm.value?.customerName ? this.SaleOrderForm.value?.customerName : null,
        roundOff: false,
        coverImage: null,
        lastInvoiceNumber: `${this.lastInvoiceNumber}`,
        placeOfSupply: null,
        isDraft: true,
      },
      products: this.sendArray
    }
    console.log(data)
    this.saleorderservice.saleOderForWarehouse(data).then((res: any) => {
      if (res.success) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Sale Order Drafted Successfully!",
        });
        this.commonService.notifyDataAdded();
        this.router.navigate(['/pages/sale_new/sale_draft']);
        this.dialogRef.close();
      }
    }, (err) => {
      this.sendArray = [];
      if (err.error.expose) {
        let errorMessage = err.error.error_message;
            if (errorMessage.includes('invoiceDetails.partyId')) {
                errorMessage = "Vendor Name  is required. Please select a valid Vendor Name option.";
            } else {
                errorMessage = this.titleCasePipe.transform(errorMessage);
            }
            this.toastService.toastMsg({
                title: "Error",
                content: errorMessage,
            });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }
  }

  SaveSaleOrder() {
    this.submitted = true;
    console.log(this.SaleOrderForm)
      if (this.SaleOrderForm.invalid) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return false;
      } else {
        if (this.productData.length < 1) {

          this.toastService.toastMsg({
            title: "Error",
            content: "Please Add At Least One Product To Proceed!",
          });
          return false;
        }

      this.productData.forEach(e => {
        this.sendArray.push({
          quantity: e.quantity,
          barcode: e.barcode,
        });
      })

      const userType = this.currentUser.userType.toLowerCase();
      const warehouseIdToSend =
        userType === 'warehouse' ? this.currentUser.id :
        userType === 'shop' ? (this.SaleOrderForm.value.warehouse || this.currentUser.id) :
        userType === 'client' && this.SaleOrderForm.value.warehouse !== null ? this.SaleOrderForm.value.warehouse : this.currentUser.id;

      let data = {
        invoiceDetails: {
          // invoiceDate: moment(this.SaleOrderForm.value.invoiceDate, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
          invoiceDate: moment(this.SaleOrderForm.value.orderDate, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
          invoiceNo: this.SaleOrderForm.value.orderNo,
          shippedFrom: "",
          ewayBillNo: "2123123",
          Description: this.SaleOrderForm.value.Description,
          billingAddress: this.SaleOrderForm.value.billingAddress,
          shippingAddress: this.SaleOrderForm.value.billingAddress ? this.SaleOrderForm.value.billingAddress : "",
          credit: this.SaleOrderForm.value.PurchaseType ? this.SaleOrderForm.value.PurchaseType.toString() : false,
          userId: this.SaleOrderForm.value.warehouse ? this.SaleOrderForm.value.warehouse : this.currentUser.id,
          partyId: this.partyId,
          rcm: false,
          shipmentCharges: this.SaleOrderForm.value.shipmentCharges ? parseFloat(this.SaleOrderForm.value.shipmentCharges) : 0,
          supplyType: null,
          dueDate: moment(this.SaleOrderForm.value.dueDate, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
          warehouseId: `${warehouseIdToSend}`,
          cashSale: false,
          mobile: this.SaleOrderForm.value?.MobileNo ? this.SaleOrderForm.value?.MobileNo : null,
          customerName: this.SaleOrderForm.value?.customerName ? this.SaleOrderForm.value?.customerName : null,
          roundOff: false,
          coverImage: null,
          lastInvoiceNumber: `${this.lastInvoiceNumber}`,
          placeOfSupply: null,
          isDraft:false,
          draftId: this.draftId ? this.draftId : null,
        },
        products: this.sendArray
      }
      console.log(data)
      // this.saleorderservice.postSaleOrdSave(data).then((res: any) => {
      this.saleorderservice.saleOderForWarehouse(data).then((res: any) => {
        if (res.success) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Sale Order Added Successfully!",
          });
          this.commonService.notifyDataAdded();
          this.saleInvoiceService.notifySaleInvoiceAdded();
          this.dialogRef.close();
        }
      }, (err) => {
        this.sendArray = [];
        console.log(err)
        if (err.error?.expose) {
          let errorMessage = err.error.error_message;
              if (errorMessage.includes('invoiceDetails.partyId')) {
                  errorMessage = "Vendor Name  is required. Please select a valid Vendor Name option.";
              } else {
                  errorMessage = this.titleCasePipe.transform(errorMessage);
              }
              this.toastService.toastMsg({
                  title: "Error",
                  content: errorMessage,
              });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }
  }

  /**
  * Post party details api call*/

  // addNewParty() {
  //   this.submittedParty = true;
  //   if (this.isShowGstFields) {
  //     if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
  //       || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == "" ) ) {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: "Fill All Required Fields.",
  //       });
  //       return false;
  //     } else if ((this.PartyForm.controls.billingAddress.value == "" && this.PartyForm.controls.partyName.value == ""
  //       && this.PartyForm.controls.mobile.value == "" && this.PartyForm.controls.email.value == "" )) {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: "Fill All Required Fields.",
  //       });
  //     }
  //     else {
  //       this.addPartyObject();
  //     }
  //   }
  //   else {
  //     if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
  //       || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == ""
  //       || this.PartyForm.controls.gstType.value == "" || this.PartyForm.controls.gstIn.value == "" )) {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: "Fill All Required Fields.",
  //       });
  //       return false;
  //     }

  //     else {
  //       this.addPartyObject();
  //     }
  //   }
  // }

  // addPartyObject() {
  //   this.finalObject = {
  //     "partyDetails": {
  //       "isCustomer": this.isShowGstFields,
  //       "gstType": this.PartyForm.controls.gstType.value ? this.PartyForm.controls.gstType.value : null,
  //       "gstIn": this.PartyForm.controls.gstIn.value ? this.PartyForm.controls.gstIn.value : null,
  //       "partyName": this.PartyForm.controls.partyName.value,
  //       "mobile": this.PartyForm.controls.mobile.value ? (this.PartyForm.controls.mobile.value).toString() : null,
  //       "email": this.PartyForm.controls.email.value,
  //       "billingAddress": this.PartyForm.controls.billingAddress.value,
  //       "shippingAddress": this.PartyForm.controls.shippingAddress.value,
  //       "openingBalance": null,
  //       "asOfDate": null,
  //       "balanceStatus": this.PartyForm.controls.balanceStatus.value,
  //       "payment": null,
  //       "creditPeriodDay": null,
  //       "creditLimit": null,
  //       "noOfCredits": null,
  //       "relationSince": null,
  //       "userId": this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
  //       "distance":null,
  //       "transporation":null,
  //     },
  //     "groupAdd": null
  //   }

  //   this.partyService.postParty(this.finalObject).then((res) => {
  //     this.paramID = res.data.id
  //     if (res) {
  //       this.toastService.toastMsg({
  //         title: "Success",
  //         content: "Vendor Added Successfully!",
  //       })
  //       this.PartyForm.reset();
  //       this.modal.dismissAll();
  //       this.getAllParties();
  //       this.submittedParty = false;
  //     }
  //   },
  //     (err) => {
  //       if (err.error.expose) {
  //         this.toastService.toastMsg({
  //           title: "Error",
  //           content: this.titleCasePipe.transform(err.error.error_message),
  //         });
  //       }
  //       else {
  //         this.toastService.toastMsg({
  //           title: "Error",
  //           content: "Something Went Wrong.",
  //         });
  //       }
  //     })
  // }

  addNewParty() {
    this.submittedParty = true;
    console.log('This.partyForm',this.PartyForm)
    console.log('PartyForm Valid:', this.PartyForm.valid);
    console.log('PartyForm Controls:', this.PartyForm.controls);

    // Use Angular's built-in validation
    if (this.PartyForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return;
    }

    // Check if GST fields should be shown and validate accordingly
    if (this.isShowGstFields) {
      if (!this.PartyForm.controls.billingAddress.value || !this.PartyForm.controls.partyName.value ||
          !this.PartyForm.controls.mobile.value || !this.PartyForm.controls.email.value) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return;
      }
    } else {
      if (!this.PartyForm.controls.gstIn.value || !this.PartyForm.controls.billingAddress.value ||
          !this.PartyForm.controls.partyName.value || !this.PartyForm.controls.mobile.value ||
          !this.PartyForm.controls.email.value) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return;
      }
    }

    // Proceed with form submission if valid
    this.addPartyObject();
  }

  addPartyObject() {
    console.log("addPartyObject", this.isShowGstFields)
    const finalObject = {
      partyDetails: {
        isCustomer: this.isShowGstFields,
        gstIn: this.PartyForm.value.gstIn ? this.PartyForm.value.gstIn : null,
        partyName: this.PartyForm.value.partyName,
        mobile: this.PartyForm.value.mobile ? this.PartyForm.value.mobile.toString() : null,
        email: this.PartyForm.value.email,
        billingAddress: this.PartyForm.value.billingAddress,
        shippingAddress: this.PartyForm.value.shippingAddress || null,
        openingBalance: null,
        asOfDate: null,
        balanceStatus: this.PartyForm.value.balanceStatus,
        payment: null,
        creditPeriodDay: null,
        creditLimit: null,
        noOfCredits: null,
        relationSince: null,
        userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
        distance: null,
        transporation: null,
        gstType: null,
      },
      groupAdd: null
    };

    console.log('Final Object:', finalObject);

    this.partyService.postParty(finalObject).then((res) => {
      if (res.success) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Contact Added Successfully!",
        });
        this.PartyForm.reset();
        this.modal.dismissAll();
        this.getAllParties();
        this.submittedParty = false;
        this.isShowGstFields = true;
      }
    }).catch((err) => {
      this.toastService.toastMsg({
        title: "Error",
        content: err.error?.expose ? this.titleCasePipe.transform(err.error.error_message) : "Something Went Wrong.",
      });
    });
  }

  customertoggleShow(value) {
    this.isShowGstFields = value;
    console.log("customertoggleShow", value);
    this.isShippingAddressinModal = false;
    this.PartyForm.patchValue({
      gstIn: null, // Assuming you want to clear gstIn as well
      partyName: '',
      mobile: null,
      email: '',
      billingAddress: '',
      shippingAddress: ''
    });

    Object.keys(this.PartyForm.controls).forEach(key => {
      this.PartyForm.get(key)?.markAsUntouched();
    });
    const gstInControl = this.PartyForm.get('gstIn');

    if (this.isShowGstFields) {
      gstInControl.disable();
    } else {
      gstInControl.enable();
    }
  }


  Units = [
    "BAGS(BAG)",
    "BALE(BAL)",
    "BUNDLES(BDL)",
  ]

  getUnit(value) {
    this.unitValue = "1 " + value;
  }

  showShippingAddressinModal(checked: boolean) {
    this.isShippingAddressinModal = checked;
  }

  // customertoggleShow(value) {
  //   this.isShowGstFields = value;
  // }

  getGstNumber(gstin) {
    this.partyService.getGstNumber({}, gstin).then((res) => {
      this.gstDetails = res;
      if (res) {
        this.PartyForm.patchValue({
          gstType: res.data.taxPayerType,
          gstNo: res.data.gstIn
        })
      }
      this.isDisabledGST = false;
    }, (err) => {
      this.isDisabledGST = true;
    })
  }

  searchPartyByName(item) {
    this.partyId = item.id;
    this.saleInvoiceService.getPartyByName({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item.partyName)
      .then((response) => {
        this.partyData = response.data.rows;
        this.partyData.forEach((ele) => {
        })
      });
  }

  addFieldValue() {
    this.fieldArray.push(this.newAttribute)
    this.newAttribute = {};
  }

  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }

  onFileSelect(fileselect) {
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
    get pf() {
    return this.PartyForm.controls;
  }

  openAddPartyModal(name) {
    this.modal.open(name, {
      centered: true,
      backdrop: "static",
      size: "lg"
    });
  }

  modalDismiss() {
    this.PartyForm.reset();
    this.modal.dismissAll();
  }

  onCancelModal(){
    this.dialogRef.close();
  }

  openDatepickerOrderDate(){
    // Check if the current value of invoiceDate is an "Invalid Date"
    const orderDate = this.SaleOrderForm.get('orderDate').value;
    if (isNaN(orderDate.getTime())) {
      // Set a default date or any valid date here
      this.SaleOrderForm.get('orderDate').setValue(new Date());
    }
  }

  openDatepickerDueDate(){
    // Check if the current value of invoiceDate is an "Invalid Date"
    const dueDate = this.SaleOrderForm.get('dueDate').value;
    if (isNaN(dueDate.getTime())) {
      // Set a default date or any valid date here
      this.SaleOrderForm.get('dueDate').setValue(new Date());
    }
  }

  getWarehouseList() {
    this.warehouseService.getAllWarehouse({
      data:{},
      userId: this.currentUser.userType !== 'CLIENT' ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
    }).then((res: any) => {
      this.warehouseList = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  onSearchInputChange(searchKey: string) {
    if (searchKey.length >= 3) {
      this.getGoodsList(searchKey);
    }
  }

  getGoodsList(value) {
    if (value && (String(value).trim() !== '' || value !== null)) {
      this.biddingService.getAllProductsByUserSearch({}, this.SaleOrderForm.value.warehouse ? this.SaleOrderForm.value.warehouse : this.currentUser.id, value, 1000, 1, this.SaleOrderForm.value.warehouse ? this.SaleOrderForm.value.warehouse : this.currentUser.id)
        .then((res) => {
          if(res.success) {
            this.searchedProduct = res.data.pageData;
            this.productOptions = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
              );
            }
        },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
            }

          })
    } else {}
  }

  getGoodsByBarcodeSelected(barcode, event) {
    if (event.isUserInput) {
      this.saleInvoiceService
        .getGoodsByBarcode({}, barcode, this.currentUser.id,this.SaleOrderForm.value.warehouse ? this.SaleOrderForm.value.warehouse : this.currentUser.id)
        .then(async (response) => {
          response.data.quantity = 1;
          // Check if the barcode already exists in productData
          const existingProductIndex = this.productData.findIndex(
            (product) => product.barcode === response.data.barcode
            );

          if (existingProductIndex !== -1) {
            // Barcode exists, update quantity
            this.productData[existingProductIndex].quantity += 1;
            // await this.checkFreeProducts(this.productData)
            // await this.checkOffer(this.productData[existingProductIndex].quantity, this.productData[existingProductIndex].id)
            // this.removeCoupon()
          } else {
            // Barcode doesn't exist, add new product
            response.data.quantity = 1;
            this.productData.push(response.data);
            // this.addDetailsButtonClick(this.productData.length - 1);
            // await this.checkFreeProducts(this.productData)
            // await this.checkOffer(response.data.quantity, response.data.id)
            // this.removeCoupon()
          }

          this.calculationTotal();
          this.myControl1.patchValue('')
          document.getElementById('inp12').nodeValue = ''
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
    }
  }

  // get formArr() {
  //   return this.SaleOrderForm.get("subcheckbox") as FormArray;
  // }

  // addDetailsButtonClick(i): void {
  //   const content = this.SaleOrderForm.get("subcheckbox") as FormArray;
  //   content.push(this.addRolesPermission(i));
  // }

  // addRolesPermission(id): FormGroup {
  //   return new FormGroup({
  //     read: new FormControl(false),
  //   });
  // }

  getSaleInvoiceNumber() {
    this.saleInvoiceService
      .getSaleInvoiceNumber({}, this.currentUser.id)
      .then((res) => {
        this.SaleOrderForm.patchValue({
          orderNo: String(res.data.prefix).concat(String(res.data.suffix)),
        });
        this.lastInvoiceNumber = res.data.suffix;
      });
  }

  // Function to handle selection change in warehouse selection dropdown
  onWarehouseSelectionChange(event: any) {
    if (event.value === 'clear') {
      this.SaleOrderForm.get('warehouse').setValue(null); // Clear the selection
      this.productData = [];
      this.calculationTotal();
    }
  }

  validateShipmentCharges(): void {
    const shipmentChargesControl = this.SaleOrderForm.get('shipmentCharges');
    const value = shipmentChargesControl?.value;
    const maxAllowedAmount = this.total - 1;
    console.log(value)
    console.log(maxAllowedAmount)
    if (value && value > maxAllowedAmount) {
      console.log(value > maxAllowedAmount)
      shipmentChargesControl?.setValue(maxAllowedAmount);
    }
  }
}
