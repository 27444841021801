import { Component, OnInit } from '@angular/core';
import { RemoteShopService } from 'src/app/core/services/remote-shop.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { Constants } from 'src/app/_helpers/constant';

@Component({
  selector: 'app-generated-order-receipts',
  templateUrl: './generated-order-receipts.component.html',
  styleUrls: ['./generated-order-receipts.component.scss']
})
export class GeneratedOrderReceiptsComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public generatedReceiptsData: any;
  public invoiceNo:any;
  public billGstAmount: number;  
  public totalBillAmount: number;
  public saleProductData:any=[];

  constructor(private remotShopService: RemoteShopService,private saleInvoiceService:SaleInvoiceService) { }

  ngOnInit(): void {
    this.getGeneratedOrder();
  }
  
  getGeneratedOrder(){
    this.remotShopService.getGeneratedOrder({},this.currentUser.id,1).then((res)=>{
      console.log(res);
      this.generatedReceiptsData = res.data
    })
  }
  getReceiptById(receiptId){
    this.saleInvoiceService.getSaleInvoiceById({},receiptId).then((res)=>{
      if(res.success){
        this.saleProductData = res.data.sales_products;
        this.invoiceNo = res.data.invoiceNo; 
        this.billGstAmount = res.data.billGstAmount;      
        this.totalBillAmount = res.data.totalBillAmount;
      }
    })
  }
}
