import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public notificationArray: any;
  public isData: boolean = false;
  public type = "bidding";
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  public totalrow: any;
  constructor(private notificationService: NotificationService,
    public router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getAllNotificationByUserId()
  }
  /**
   * get notification by userId
   */
  getAllNotificationByUserId() {
    this.notificationService.getAllNotification({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.isData = true;
      this.notificationArray = res.data.pageData;
      this.totalrow = res.data.totalrows
    }, (err) => {
      if (err.error.status == 404) {
        this.isData = false;
      }
    })
  }

  GoTOPages(type) {
    if (type) {
      this.router.navigate(["/pages/client-bid"]);
    }
  }

  // for pagination
  public handlePage(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.notificationArray.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getAllNotificationByUserId();
    }
  }

}
