import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  companyInformation: FormGroup;

  constructor(private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.companyInformation = this.fb.group({
      companyLogo: [null],
      companyName: [null],
      taxId: [null],
      website: [null],
      taxJarApiKey: [null],
      useTaxJarLocationApi: [false],
      flatDiscountTax: [false],
      priceIncludeTax: [false],
      chargeTaxOnPurchase: [false],
      useTaxValuesAtAllLocations: [false],
      currencySymbol: [null],
      currencyCode: [null],
      paymentCurrency: [null],
      currencySymbolExchange: [null],
      currencySymbolLocation: [null],
      noOfDecimal: [null],
      thousandSeparator: [null],
      decimalPoint: [null],
      exchangeRate: [null],
      currencySymbolLoc: [null],
      decimal: [null],
      thousandSep: [null],
      decimalPt: [null],
    });
  }

}
