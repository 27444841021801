import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
//import { Router } from "@angular/router";
import { PartyService } from "src/app/core/services/party.service";
import { ProfileService } from "src/app/core/services/profile.service";
import { ScheduleShopService } from "src/app/core/services/schedule-shop.service";
import { Location, TitleCasePipe } from "@angular/common";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
@Component({
  selector: "app-shop-edit-page",
  templateUrl: "./shop-edit-page.component.html",
  styleUrls: ["./shop-edit-page.component.scss"],
})
export class ShopEditPageComponent implements OnInit {
  public submitted: boolean;
  shopDetailsIUpdateForm: FormGroup;
  shopID: any;
  edishopData: any;
  isShown: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private ScheduleShopService: ScheduleShopService,
    private partyService: PartyService,
    private profileService: ProfileService,
    private router: Router,
    private location: Location,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe
  ) {}

  ngOnInit(): void {
    this.setValue();
    this.shopDetailsIUpdateForm = this.formBuilder.group({
      //Basic details
      email: [
        null,
        [
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"),  
                  Validators.email,
          Validators.required,
        ],
      ],
      mobile: [
        ,
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern("^[0-9]*$"),
          Validators.required,
        ],
      ],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      // parentId:["",[Validators.required]],
      //shop details
      isRegistered: ["", [Validators.required]],
      legalName: ["", [Validators.required]],
      accountMethod: ["", [Validators.required]],
      businessType: ["", [Validators.required]],
      //gstinNo: ["", [Validators.required]],
      gstinNo: [null, [Validators.pattern("^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"),
      Validators.minLength(15), Validators.maxLength(15)]],
      gstType: ["", [Validators.required]],
      compositeType: ["", [Validators.required]],
      //address details
      homeno: ["", [Validators.required]],
      pincode: [
        "",
        [
          Validators.minLength(6),
          Validators.maxLength(6),
          Validators.pattern("^[0-9]*$"),
          Validators.required,
        ],
      ],
      address: ["", [Validators.required]],
      city: ["", Validators.required],
      district: ["", Validators.required],
      landmark: ["", [Validators.required]],
      state: ["", Validators.required],
      country: ["", Validators.required],
    });

    this.ScheduleShopService.getshopListBYshopId({}, this.shopID).then(
      (res) => {
        this.edishopData = res;
        console.log("edit data", this.edishopData);

        this.shopDetailsIUpdateForm.patchValue({
          email: this.edishopData.data.email,
          mobile: this.edishopData.data.mobile,
          firstName: this.edishopData.data.firstName,
          lastName: this.edishopData.data.lastName,

          isRegistered: this.edishopData.data.client.isRegistered,
          legalName: this.edishopData.data.client.legalName,
          accountMethod: this.edishopData.data.client.accountMethod,
          businessType: this.edishopData.data.client.businessType,
          gstinNo: this.edishopData.data.client.gstinNo,
          gstType: this.edishopData.data.client.gstType,
          compositeType: this.edishopData.data.client.compositeType,
          // //address details
          homeno: this.edishopData.data.primaryAddress.homeno,
          pincode: this.edishopData.data.primaryAddress.pincode,
          address: this.edishopData.data.primaryAddress.address,
          city: this.edishopData.data.primaryAddress.city,
          district: this.edishopData.data.primaryAddress.district,
          landmark: this.edishopData.data.primaryAddress.landmark,
          state: this.edishopData.data.primaryAddress.state,
          country: this.edishopData.data.primaryAddress.country,
        });
      }
    );
  }

  get f() {
    return this.shopDetailsIUpdateForm.controls;
  }
  setValue() {
    this.route.params.subscribe((params) => (this.shopID = params["id"]));
  }
  getGstNumber(gstin) {
    this.partyService.getGstNumber({}, gstin).then(
      (res) => {
        if (res) {
          this.shopDetailsIUpdateForm.patchValue({
            gstType: res.data.taxPayerType,
            legalName: res.data.legalName,
            // status: res.data.status
          });
        }
        // this.isDisabledGST = false;
      },
      (err) => {
        // this.isDisabledGST = true;
      }
    );
  }
  //get pincode api
  getPincodeDetails(pin) {
    this.profileService.getUserPincode({}, pin).then((res) => {
      this.shopDetailsIUpdateForm.patchValue({
        district: res.data.districtName,
        state: res.data.stateName,
      });
    });
  }
  backBtn() {
    this.location.back();
  }
  updateShop() {
    this.submitted = true;
    console.log("Shop" + JSON.stringify(this.shopDetailsIUpdateForm.value));
    // if (this.shopDetailsIUpdateForm.invalid) {
    //   this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
    //   return false;
    // }
    if(this.isShown){
      if((this.shopDetailsIUpdateForm.controls.email.value == "" || this.shopDetailsIUpdateForm.controls.mobile.value == "" || this.shopDetailsIUpdateForm.controls.firstName.value == ""
          || this.shopDetailsIUpdateForm.controls.lastName.value == "" || this.shopDetailsIUpdateForm.controls.isRegistered.value == "" || this.shopDetailsIUpdateForm.controls.legalName.value == ""
          || this.shopDetailsIUpdateForm.controls.accountMethod.value == "" || this.shopDetailsIUpdateForm.controls.businessType.value == "" || this.shopDetailsIUpdateForm.controls.gstinNo.value == ""
          || this.shopDetailsIUpdateForm.controls.gstType.value == "" || this.shopDetailsIUpdateForm.controls.compositeType.value || this.shopDetailsIUpdateForm.controls.homeno.value == "" || this.shopDetailsIUpdateForm.controls.pincode.value == ""
          || this.shopDetailsIUpdateForm.controls.address.value == "" || this.shopDetailsIUpdateForm.controls.city.value == "" || this.shopDetailsIUpdateForm.controls.district.value == "" || this.shopDetailsIUpdateForm.controls.landmark.value == "" 
          || this.shopDetailsIUpdateForm.controls.state.value == "" || this.shopDetailsIUpdateForm.controls.country.value == "") && (this.shopDetailsIUpdateForm.invalid))
          {
            this.toastService.toastMsg({
              title: "Error",
              content: "Fill All Required Fields.",
            });
            // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
            return false;
           }
          else if((this.shopDetailsIUpdateForm.controls.email.value == "" && this.shopDetailsIUpdateForm.controls.mobile.value == "" && this.shopDetailsIUpdateForm.controls.firstName.value == ""
          && this.shopDetailsIUpdateForm.controls.lastName.value == "" && this.shopDetailsIUpdateForm.controls.isRegistered.value == "" && this.shopDetailsIUpdateForm.controls.legalName.value == ""
          && this.shopDetailsIUpdateForm.controls.accountMethod.value == "" && this.shopDetailsIUpdateForm.controls.businessType.value == "" && this.shopDetailsIUpdateForm.controls.gstinNo.value == ""
          && this.shopDetailsIUpdateForm.controls.gstType.value == "" && this.shopDetailsIUpdateForm.controls.compositeType.value == "" && this.shopDetailsIUpdateForm.controls.homeno.value == "" || this.shopDetailsIUpdateForm.controls.pincode.value == ""
          && this.shopDetailsIUpdateForm.controls.address.value == "" && this.shopDetailsIUpdateForm.controls.city.value == "" && this.shopDetailsIUpdateForm.controls.district.value == "" || this.shopDetailsIUpdateForm.controls.landmark.value == "" 
          && this.shopDetailsIUpdateForm.controls.state.value == "" && this.shopDetailsIUpdateForm.controls.country.value == "") && (this.shopDetailsIUpdateForm.invalid))
          {
            this.toastService.toastMsg({
              title: "Error",
              content: "Fill All Required Fields.",
            });
            // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
            
          }
          else {
            this.editShopObject();
          }
    } else {
      if((this.shopDetailsIUpdateForm.controls.email.value == "" || this.shopDetailsIUpdateForm.controls.mobile.value == "" || this.shopDetailsIUpdateForm.controls.firstName.value == ""
          || this.shopDetailsIUpdateForm.controls.lastName.value == "" || this.shopDetailsIUpdateForm.controls.isRegistered.value == "" || this.shopDetailsIUpdateForm.controls.legalName.value == ""
          || this.shopDetailsIUpdateForm.controls.accountMethod.value == "" || this.shopDetailsIUpdateForm.controls.businessType.value == "" || this.shopDetailsIUpdateForm.controls.gstinNo.value == ""
          || this.shopDetailsIUpdateForm.controls.gstType.value == "" || this.shopDetailsIUpdateForm.controls.compositeType.value || this.shopDetailsIUpdateForm.controls.homeno.value == "" || this.shopDetailsIUpdateForm.controls.pincode.value == ""
          || this.shopDetailsIUpdateForm.controls.address.value == "" || this.shopDetailsIUpdateForm.controls.city.value == "" || this.shopDetailsIUpdateForm.controls.district.value == "" || this.shopDetailsIUpdateForm.controls.landmark.value == "" 
          || this.shopDetailsIUpdateForm.controls.state.value == "" || this.shopDetailsIUpdateForm.controls.country.value == "") && (this.shopDetailsIUpdateForm.invalid))
          {
            this.toastService.toastMsg({
              title: "Error",
              content: "Fill All Required Fields.",
            });
            // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
            return false;
           }
          else if((this.shopDetailsIUpdateForm.controls.email.value != "" && this.shopDetailsIUpdateForm.controls.mobile.value != "" && this.shopDetailsIUpdateForm.controls.firstName.value != ""
          && this.shopDetailsIUpdateForm.controls.lastName.value != "" && this.shopDetailsIUpdateForm.controls.isRegistered.value != "" && this.shopDetailsIUpdateForm.controls.legalName.value != ""
          && this.shopDetailsIUpdateForm.controls.accountMethod.value != "" && this.shopDetailsIUpdateForm.controls.businessType.value != "" && this.shopDetailsIUpdateForm.controls.gstinNo.value != ""
          && this.shopDetailsIUpdateForm.controls.gstType.value != "" && this.shopDetailsIUpdateForm.controls.compositeType.value != "" && this.shopDetailsIUpdateForm.controls.homeno.value != "" || this.shopDetailsIUpdateForm.controls.pincode.value != ""
          && this.shopDetailsIUpdateForm.controls.address.value != "" && this.shopDetailsIUpdateForm.controls.city.value != "" && this.shopDetailsIUpdateForm.controls.district.value != "" || this.shopDetailsIUpdateForm.controls.landmark.value != "" 
          && this.shopDetailsIUpdateForm.controls.state.value != "" && this.shopDetailsIUpdateForm.controls.country.value != "") && (this.shopDetailsIUpdateForm.invalid))
          {
            this.toastService.toastMsg({
              title: "Error",
              content: "Fill All Required Fields.",
            });
            // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
            
          }
          else {
            this.editShopObject();
          }

    }
    
  }

  editShopObject(){
    let body = {
      shopDetails: {
        email: this.shopDetailsIUpdateForm.controls.email.value,
        mobile: this.shopDetailsIUpdateForm.controls.mobile.value,
        firstName: this.shopDetailsIUpdateForm.controls.firstName.value,
        lastName: this.shopDetailsIUpdateForm.controls.lastName.value,
        fullName: this.shopDetailsIUpdateForm.controls.firstName.value,
      },
    };
    this.ScheduleShopService.UpdateShopDetails(body, this.shopID).then(
      (res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Shop Record Updated Successfully!",
            })
          // this.toastService.openSnackBar("Shop Record Updated Successfully");
          this.router.navigate(["/pages/shop-page"]);
          // this.shopDetailsIUpdateForm.reset();
        }
      },
      (err) => {
        if (err.error.expose) {
          // this.toastService.openErrorSnackBar(
          //   this.titleCasePipe.transform(err.error.error_message)
          // );
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        } else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      }
    );
  }
}
