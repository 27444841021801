import { Options } from 'highcharts';

export function getChartOptions(
  chartType: string,
  chartData: any,
  xAxisTitle: string = '',
  yAxisTitle: string = '',
  xAxisCategories: any[] = []
): Options {
  
  switch (chartType) {
    case 'NoData':
      return {
        ...emptyChartOptions,
      };
    case 'spline':
      return {
        ...lineChartOptions,
        series: chartData,
        xAxis: {
          ...lineChartOptions.xAxis,
          title: { text: xAxisTitle },
          categories: xAxisCategories
        },
        yAxis: {
          ...lineChartOptions.yAxis,
          title: { text: yAxisTitle }
        }
      };
    case 'pie':
      return {
        ...pieChartOptions,
        series: chartData
      };
    case 'donut':
      return {
        ...donutChartOptions,
        series: chartData
      };
    case 'column':
      return {
        ...columnChartOptions,
        series: chartData,
        xAxis: {
        ...columnChartOptions.xAxis,
          title: { text: xAxisTitle },
          categories: xAxisCategories
        },
        yAxis: {
          ...columnChartOptions.yAxis,
          title: { text: yAxisTitle }
        }
      };
    case 'area':
      return {
        ...areaChartOptions,
        series: chartData,
        xAxis: {
        ...areaChartOptions.xAxis,
          title: { text: xAxisTitle },
          categories: xAxisCategories
        },
        yAxis: {
          ...areaChartOptions.yAxis,
          title: { text: yAxisTitle }
        }
      };
    case 'bar':
      return {
        ...barChartOptions,
        series: chartData,
        xAxis: {
        ...barChartOptions.xAxis,
          title: { text: xAxisTitle },
          categories: xAxisCategories
        },
        yAxis: {
          ...barChartOptions.yAxis,
          title: { text: yAxisTitle }
        }
      };
    case 'scatter':
      return {
        ...scatterChartOptions,
        series: chartData,
        xAxis: {
          ...scatterChartOptions.xAxis,
          title: { text: xAxisTitle },
          categories: xAxisCategories,
        },
        yAxis: {
          ...scatterChartOptions.yAxis,
          title: { text: yAxisTitle },
        },
      };
    case 'radar':
      return {
        ...radarChartOptions,
        series: chartData,
        xAxis: {
          ...radarChartOptions.xAxis,
          categories: xAxisCategories,
        },
        yAxis: {
          ...radarChartOptions.yAxis,
          title: { text: yAxisTitle },
        },
      };
    default:
      return {
        ...lineChartOptions,
        series: chartData,
        xAxis: {
          ...lineChartOptions.xAxis,
          title: { text: xAxisTitle },
          categories: xAxisCategories
        },
        yAxis: {
          ...lineChartOptions.yAxis,
          title: { text: yAxisTitle }
        }
      };
  }
}

export const baseChartOptions: Options = {
  chart: {
    zooming: {
      type: 'x'
    }
  },
  credits: {
      enabled: false,
  },
  title: {
      text: ' ',
      style: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: '500',
          fontSize: '16px',
          color: '#737791',
      },
  },
  xAxis: {
      title: {
          text: 'xAxis',
      },
  },
  yAxis: {
      title: {
          text: 'yAxis',
      },
      gridLineWidth: 0,
  },
  plotOptions: {
      series: {
          animation: {
              duration: 3000,
          },
          cursor: 'pointer',
      },
  },
  series: [],
};

export const lineChartOptions: Options = {
  ...baseChartOptions,
  chart: {
      type: 'spline',
      height: 300,
      plotShadow: false,
  },
};

export const pieChartOptions: Options = {
  ...baseChartOptions,
  chart: {
      type: 'pie',
      height: 300,
  },
  plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
          size: '100%'
      },
  },
  // Disable the accessibility module to remove the warning
  accessibility: {
    enabled: false  // This will suppress the warning
  }
};

export const donutChartOptions: Options = {
  ...baseChartOptions,
  chart: {
      type: 'pie',
      height: 300,
  },
  plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
          size: '100%',
          innerSize: '50%',
      },
  },
};

export const columnChartOptions: Options = {
  ...baseChartOptions,
  chart: {
      type: 'column',
      height: 300,
  },
  plotOptions: {
    column: {
      dataLabels: {
        enabled: true,
      }
    },
  }
};

export const barChartOptions: Options = {
  ...baseChartOptions,
  chart: {
      type: 'bar',
      height: 300,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  }
};

export const areaChartOptions: Options = {
  ...baseChartOptions,
  chart: {
    type: 'area',
    height: 300
  },
  plotOptions: {
    area: {
      dataLabels: {
        enabled: true,
      }
    },
  }
};

export const emptyChartOptions: Options = {
    chart: {
      type: 'spline',
      height: 300,
      events: {
        load: function () {
          const chart = this;
          const centerX = chart.plotWidth / 2;
          const centerY = chart.plotHeight / 2;

          // Add the image element
          chart.renderer
            .image(
              '../../../assets/img/icons/spot-illustrations/notfound1.png',
              centerX - 90,
              centerY - 70,
              200,
              150
            )
            .add();
        },
      },
    },
    title: {
      text: ' ',
      style: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '500',
        fontSize: '16px',
        color: '#737791',
      },
    },
    credits: {
      enabled: false,
    },
};

export const scatterChartOptions: Options = {
  ...baseChartOptions,
  chart: {
    type: 'scatter',
  },
  // xAxis: {
  //   title: { text: 'X Axis Title' },
  // },
  // yAxis: {
  //   title: { text: 'Y Axis Title' },
  // },
  plotOptions: {
    scatter: {
      marker: {
        radius: 5,
        states: {
          hover: {
            enabled: true,
            lineColor: 'rgb(100,100,100)',
          },
        },
      },
      states: {
        hover: {
          // marker: {
          //   enabled: false,
          // },
        },
      },
      tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        pointFormat: '{point.x} cm, {point.y} kg',
      },
    },
  },
};

export const radarChartOptions: Options = {
  ...baseChartOptions,
  chart: {
    polar: true,
    type: 'line',
  },
  pane: {
    size: '80%',
  },
  xAxis: {
    categories: ['Category 1', 'Category 2', 'Category 3', 'Category 4'],
    tickmarkPlacement: 'on',
    lineWidth: 0,
  },
  yAxis: {
    gridLineInterpolation: 'polygon',
    lineWidth: 0,
    min: 0,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
};

// export const lineChartOptions: Options = {
//     chart: {
//         type: 'spline',
//         height: 300,
//         plotShadow: false,
//     },
//     credits: {
//         enabled: false,
//     },
//     title: {
//         text: ' ',
//         style: {
//             fontFamily: "'Poppins', sans-serif",
//             fontWeight: '500',
//             fontSize: '16px',
//             color: '#737791',
//         },
//     },
//     xAxis: {
//         title: {
//             text: 'Days',
//         },
//     },
//     yAxis: {
//         title: {
//             text: 'Sale Amount',
//         },
//         gridLineWidth: 0,
//     },
//     plotOptions: {
//         series: {
//             animation: {
//                 duration: 3000,
//             },
//             cursor: 'pointer',
//         },
//     },
//     series: [],
// };


// export function getLineChartOptions(data: any, categories: string[]): Options {
//   return {
//     chart: {
//       type: 'spline',
//       height: 350,
//       plotShadow: false
//     },
//     credits: {
//       enabled: false,
//     },
//     title: {
//       text: ' ',
//       style: {
//         fontFamily: "'Poppins', sans-serif",
//         fontWeight: '500',
//         fontSize: '16px',
//         color: '#737791',
//       },
//     },
//     xAxis: {
//       title: {
//         text: 'Days',
//       },
//       categories: categories,
//     },
//     yAxis: {
//       title: {
//         text: 'Sale Amount',
//       },
//       gridLineWidth: 0,
//     },
//     plotOptions: {
//       series: {
//         animation: {
//           duration: 3000,
//         },
//         cursor: 'pointer',
//       },
//     },
//     series: [
//     //   {
//     //     name: 'Past Week',
//     //     data: data.pastWeekData, // Assuming data[1] is for Current Week
//     //     color: '#FC7643',
//     // },
//     // {
//     //     name: 'Current Week',
//     //     data: data.currentWeekData, // Assuming data[0] is for Past Week
//     //     color: '#110152',
//     //   },
//     ],
//   };
// }
