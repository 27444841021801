<div class="row d-flex p-4">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <img [src]="defaultImage" class="center" width="350" height="350">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <h2 class="font-style text-right">Name</h2>
        <p class="font-style text-right">
            Some quick example text to build on the card title and make up the bulk of the card's content.
            Some quick example text to build on the card title and make up the bulk of the card's content.
            Some quick example text to build on the card title and make up the bulk of the card's content.
            Some quick example text to build on the card title and make up the bulk of the card's content.
            Some quick example text to build on the card title and make up the bulk of the card's content.
        </p>
    </div>
</div>