<div class="card widget" style="box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15)">
  <div class="card-header">
    <div class="row headerRow">
      <div class="col-sm-6">
        <!-- <h2 class="pt-3"><b style = "font-family: 'Poppins', sans-serif;">{{purchaseType}} Purchase</b></h2> -->
        <span
          class="pt-3"
          style="
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 18px;
          "
          >{{ purchaseType }} Purchase</span
        >
      </div>
      <div class="col-sm-6">
        <div class="row justify-content-end">
          <div class="col-auto d-flex">
            <mat-form-field appearance="outline" style="width: 120px">
              <mat-select
                matNativeControl
                matInput
                disableOptionCentering
                [(value)]="defaultPurchaseSelect"
                (selectionChange)="getPurchaseReportType($event.value)"
              >
                <mat-option value="Daily" selected>Daily</mat-option>
                <mat-option value="Weekly">Weekly</mat-option>
                <mat-option value="Monthly">Monthly</mat-option>
                <mat-option value="Yearly">Yearly</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" style="height: 325px">
    <div class="max-w-100 line" [chart]="lineChart" style="margin: auto"></div>
    <!-- <div class="max-w-100" style="margin: auto" id="purchaseCanvas">
      {{ purchaseChart }}
    </div> -->
  </div>
</div>
