import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { request } from 'http';
import { CommonService } from 'src/app/core/services/common.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-cash-to-register',
  templateUrl: './cash-to-register.component.html',
  styleUrls: ['./cash-to-register.component.scss']
})
export class CashToRegisterComponent implements OnInit {
  public submitted: boolean = false;
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  addForm: FormGroup;
  // removeForm: FormGroup;
  mode: any;

  constructor(
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CashToRegisterComponent>,
    private fb: FormBuilder,
    private saleInvoiceService: SaleInvoiceService,
    private commonService: CommonService,
    private toastService: ToastNotificationService,

  ) {
      this.mode = data.mode;
    }

  ngOnInit(): void {
    this.addForm = this.fb.group({
      amount: [null, Validators.required],
      description: [null, Validators.required]
    });
    // this.removeForm = this.fb.group({
    //   removeAmount: [null],
    //   removeDescription: [null]
    // });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  cancelAddForm() {
    this.addForm.reset();
    this.dialogRef.close();
  }
  get f() {
		return this.addForm.controls;
	}

  // cancelRemoveForm() {
  //   this.removeForm.reset();
  //   this.dialogRef.close();
  // }

  save(type: string){
    this.submitted = true;

    console.log(type)
    if(this.addForm.valid){
      const requestBody = {
        userId: this.currentUser.id,
        type: type,
        description: this.addForm.value.description,
        amount: parseFloat(this.addForm.value.amount)
      }
      this.saleInvoiceService.addremoveamount(requestBody, this.currentUser?.dailysessionId).then((res: any) => {
        if(res.success){
          this.toastService.toastMsg({
            title: "Success",
            content: this.mode ? "Amount Added Successfully!" : "Amount Removed Successfully!",
          });
          this.commonService.notifyDataAdded();
          this.dialogRef.close();
        }
      }, (err) => {
        this.toastService.toastMsg({
          title: "Error",
          content: err.error.error_message,
        });
      })
    } else {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
    }
  }
}
