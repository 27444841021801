<div class="container-fluid mb-3 mx-0 bg-white">
    <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
            Item Wise Discount
        </div>
        <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
          <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i>
            Export</button>
    
          <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
          </button>
        </div>
    </div>
    <hr class="mt-1" />
    <div class="fs--1">
        <div class="row">
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-4 col-lg-3" id="dateRangePrintHide">
                <label class="form-label">Date</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input  class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
                        bsDaterangepicker [maxDate]="today" [(ngModel)]="dateRange"
                        [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
                    <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
                </mat-form-field>
            </div>
        </div>
        <!-- <div class="row mx-auto"> -->
        <div class="row" id="searchBarHide">
            <label class="col-md-2 mt-3 mb-3" class="form-label">Item Wise Discount</label>
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <label class="form-label text-nowrap">Select Product Name</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Product Name" (input)="searchProduct()"
                        aria-label="Select Product Name" matInput [formControl]="myControl" [matAutocomplete]="auto">
                    <mat-icon class="m-2" matSuffix>search</mat-icon>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.itemName"
                            (onSelectionChange)="selectedProduct(option.itemName)">
                            {{ option.itemName | titlecase }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <label class="form-label">Select Categories</label>
                <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width"
                    appearance="outline">
                    <mat-select placeholder="Select Categories" (selectionChange)="getCategories($event.value)">
                        <mat-option *ngFor="let item of productCategory" [value]="item">{{item.categoryName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <label class="form-label">Select Vendor Name</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Vendor Name" aria-label="FILTERS" matInput
                        (keyup)="searchPartyByName($event.target.value)" [matAutocomplete]="autoParty" [ngClass]="{
                                    'is-invalid': (partyName.invalid && partyName.touched) && submitted }" required>
                    <mat-autocomplete #autoParty="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptionsParty | async" [value]="option.partyName"
                            (onSelectionChange)="setSelectedPartyDeatails(option)">
                            {{option.partyName}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>


        <div class="table-responsive scrollbar mt-3">
            <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                <thead class="" style="background-color: #F0F0F0;">
                    <tr class="text-900">
                        <th class="text-nowrap text-center" scope="col">#</th>
                        <th class="text-nowrap text-center" scope="col">Item Name</th>
                        <th class="text-nowrap text-center" scope="col">Total Qty Sold</th>
                        <th class="text-nowrap text-center" scope="col">Total Sales Amount</th>
                        <th class="text-nowrap text-center" scope="col">Total Disc. Amount</th>
                        <!--<th class="text-nowrap text-center" scope="col">TOTAL DISC.AMOUNT</th>-->
                        <th class="text-nowrap text-center" scope="col">Avg. Disc. (%)</th>
                        <th class="text-nowrap text-center" scope="col">Details</th>
                    </tr>
                </thead>
                <tbody class="list">
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <!-- <td></td> -->
                    </tr>
                </tbody>
            </table>
        </div>
        <div><br><br><br><br><br>
            <label>No items</label>
        </div>
        <div>
            <label>Summary</label>
        </div>
        <div>
            <label>Total Sales Amount: -----</label>
        </div>
        <div>
            <label>Total Discount amount: -----</label>
        </div>
    </div>