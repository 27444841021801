import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import * as moment from 'moment';
import { Chart } from 'angular-highcharts'
import { getChartOptions } from '../../chartOptions/chartOptions';

import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { WarehouseService } from 'src/app/core/services/warehouse.service';
import { SaleOrderService } from 'src/app/core/services/sale-order.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
  },
};

@Component({
  selector: 'app-order-summary-chart',
  templateUrl: './order-summary-chart.component.html',
  styleUrls: ['./order-summary-chart.component.scss']
})
export class OrderSummaryChartComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public todaysDate = this.commonService.getTodaysDate();
  public isWeek: boolean = true;
  public isMonth: boolean = false;
  public isYear: boolean = false;
  public salesType: string = 'Weekly';
  public maxDate = new Date();
  public defaultSaleSelect = "Weekly";
  orderdData: any[];
  deliveredData: any[];
  private chart: Highcharts.Chart;
  @Input() chartType: string = 'spline'; // Default chart type
  public currentChartType: string = 'spline'; // Default chart type
  lineChart: Chart;
  @Input() xAxisTitle: string = ''; // Default x-axis title
  @Input() yAxisTitle: string = ''; // Default y-axis title
  @Input() xAxisCategories: any[] = []; // Default x-axis categories

  constructor(public rolePermission: RolesPermissionsService,
    public authorizationService: AuthorizationService,
    public dashboardService: DashboardService,
    public warehouseService: WarehouseService,
    public commonService: CommonService,
    public saleorderservice: SaleOrderService,
    public router: Router) {
  }

  ngOnInit(): void {
    // this.weekSalesDateValue = new Date();
    // this.lastweekSalesDateValue = new Date();
    // this.monthSalesDateValue = new Date();
    // this.lastmonthSalesDateValue = new Date();
    // this.yearSalesDateValue = new Date();
    // this.lastyearSalesDateValue = new Date();

    this.getAllStockTransfer();
    
    this.dashboardService.widgetData$.subscribe(data => {
      if (data) {
        const chartData = JSON.parse(data.chartData);
        if(chartData.component === 'OrderSummaryChartComponent'){
          this.chartType = data.chartType;
        }        // this.chartId = data.chartId;
        // this.chartData = data.chartData;
        // Initialize your chart here using this.chartType, this.chartId, and this.chartData
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyChart();
  }

  private destroyChart() {
    if (this.lineChart) {
      this.lineChart.ref$.subscribe(chart => {
        chart.destroy();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartType) {
      // this.getSaleInvoiceReportType('Weekly');
      this.getAllStockTransfer()
    }
  }

  private initChart(chartType, chartData, categories): void {
    this.destroyChart(); // Destroy any existing chart instance
    if (categories) {
      this.xAxisCategories = categories;
    }
    // const options = { ...lineChartOptions, chart: { ...lineChartOptions.chart, type: this.chartType } };
    const options = getChartOptions(chartType, chartData, this.xAxisTitle, this.yAxisTitle, this.xAxisCategories);

    // if (chartData) {
    //   options.series = chartData;
    // }

    this.lineChart = new Chart(options);
  }

  // getSaleInvoiceReportType(type) {
  //   this.salesType = type
  //   if (type === 'Weekly') {
  //     this.isWeek = true;
  //     this.isMonth = false;
  //     this.isYear = false;
  //     this.getAllStockTransfer();
  //   } else if (type === 'Monthly') {
  //     this.isWeek = false;
  //     this.isMonth = true;
  //     this.isYear = false;
  //     this.getTotalInvoiceMonthReport();
  //   } else if (type === 'Yearly') {
  //     this.isWeek = false;
  //     this.isMonth = false;
  //     this.isYear = true;
  //     this.getTotalInvoiceYearReport();
  //   }
  // }
  
  async getAllStockTransfer() {
    // if (this.newSaleDate) {
    //   this.todaysDate = this.newSaleDate
    // }

    // this api call for the latest sales of the current week report
    let chartData = {};
    try {
      await this.saleorderservice.getSaleOrderList({}, this.currentUser.id, "SALEORDER", 500, 1, 'NOTPACKED').then((res) => {
        if(res.success) {
          // Assume response is stored in res variable
        const saleOrders = res.data.pageData;

        // Initialize arrays to store ordered and delivered data for each month
        const orderedData = new Array(12).fill(0); // Initialize with zeros for all months

        // Loop through each sale order and update the corresponding month's data
        saleOrders.forEach(order => {
          const orderDate = new Date(order.invoiceDate);
          const month = orderDate.getMonth(); // Month index (0-11)
          orderedData[month] += order.totalBillAmount; // Update ordered amount for the month
        });
        this.orderdData = orderedData;
        }
      })
    } catch (error) {
      console.error('Error fetching orders:', error);
      this.initChart('NoData', {}, []);
    }
    try {
      await this.saleorderservice.getSaleOrderList({}, this.currentUser.id, "SALEORDER", 500, 1, 'DELIVERED').then((res) => {
        if(res.success) {
          // Assume response is stored in res variable
          const saleOrders = res.data.pageData;

          // Initialize arrays to store ordered and delivered data for each month
          const deliveredData = new Array(12).fill(0); // Initialize with zeros for all months

          // Loop through each sale order and update the corresponding month's data
          saleOrders.forEach(order => {
            const deliveredDate = new Date(order.invoiceDate);
            const month = deliveredDate.getMonth(); // Month index (0-11)
            deliveredData[month] += order.totalBillAmount; // Update ordered amount for the month
          });
          this.deliveredData = deliveredData

          const isOrderdDataEmpty = this.orderdData === (null || undefined);
            const isDeliveredDataEmpty = this.deliveredData === (null || undefined);
            if (isOrderdDataEmpty && isDeliveredDataEmpty) {
              this.currentChartType = 'NoData';
            } else {
              this.currentChartType = this.chartType;
              chartData = [
                {
                    name: 'Yesterday',
                    data: this.orderdData,
                    color: '#FC7643',
                },
                {
                    name: 'Today',
                    data: this.defaultSaleSelect,
                    color: '#110152',
                }
              ]
            }
            this.initChart(this.currentChartType, chartData, ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
        }
      })
    } catch (error) {
      console.error('Error fetching deliveries:', error);
      this.initChart('NoData', {}, []);
    }
    
    // this.plotWeeklyChart()
  }
  
  // plotWeeklyChart(){
  //   this.salesChart = Highcharts;
  //   const isOrderEmpty = (this.orderdData === undefined) || (this.orderdData === null);
  //   const isDeliveredEmpty = (this.deliveredData === undefined) || (this.deliveredData === null);
  //   if (isDeliveredEmpty && isOrderEmpty) {        
  //     console.log("inside if");
  //       // Display a "No data found" message
  //       const chartOptions = {
  //         chart: {
  //           type: 'spline',
  //           events: {
  //             load: function() {
  //               const chart = this;
  //               const centerX = chart.plotWidth / 2;
  //               const centerY = chart.plotHeight / 2;
        
  //               // Add the image element
  //               chart.noDataImage = chart.renderer
  //                 .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 70, 200, 150)
  //                 .add();
  //             }
  //           }
  //         },
  //         title: {
  //           text: 'Order Summary',
  //           style: {
  //             fontFamily: "'Poppins', sans-serif",
  //             fontWeight: '500',
  //             fontSize: '16px',
  //             color: '#737791'
  //           }
  //         },
  //         // xAxis: {
  //         //   categories: 
  //         // },
  //         credits: {
  //           enabled: false
  //         },
  //       };
      
  //       // Render the chart
  //       this.salesChart.chart('salesCanvas', chartOptions);
  //     } else {
  //       const chartOptions = {
  //         chart: {
  //           type: 'spline',
  //         },
  //         credits:{
  //           enabled: false
  //         },
  //         title: {
  //           text: 'Order Summary',
  //           style: {
  //             fontFamily: "'Poppins', sans-serif",
  //             fontWeight: '500',
  //             fontSize: '16px',
  //             color: '#737791'
  //           }
  //         },
  //         xAxis: {
  //           title: {
  //             text: 'Months'
  //           },
  //           categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] // month names here
  //         },
  //         yAxis: {
  //           title: {
  //             text: 'Amount'
  //           },
  //           gridLineWidth: 0
  //         },
  //         plotOptions: {
  //           series: {
  //             animation: {
  //               duration: 3000
  //             },
  //             cursor: 'pointer',
  //           }
  //         },   
  //         series: [
  //           {
  //             name: 'Ordered',
  //             data: this.orderdData,
  //             color: '#FC7643',
  //           },
  //           {
  //             name: 'Delivered',
  //             data: this.deliveredData,
  //             color: '#110152',
  //           }
  //         ]
  //       };
        
  //       this.salesChart.chart('salesCanvas', chartOptions);
  //   }
  // }

  getTotalInvoiceMonthReport() {
    // if (this.newSaleDate) {
    //   this.todaysDate = this.newSaleDate;
    // }
    // const today = new Date(this.todaysDate);
    // const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    // const oneMonthAgoDateString = `${oneMonthAgo.getFullYear()}-${oneMonthAgo.getMonth() + 1}-${oneMonthAgo.getDate()}`;
    // // this API call returns current month data
    // this.dashboardService.getTotalInvoiceMonthReport({}, this.currentUser.id, this.todaysDate, "SALES").then(async (res) => {
    //   this.monthDay = Object.values(res.data.dataset).map((names: any) => names.day.split('-')[2]);
    //   this.currentMonthtotalBillAmount = Object.values(res.data.dataset).map((total: any) => total.totalBillAmount);
    //   // this.plotMonthChart()
    //   this.dashboardService.getTotalInvoiceMonthReport({}, this.currentUser.id, oneMonthAgoDateString, "SALES").then(async (res) => {
    //     this.pastmonthDay = Object.values(res.data.dataset).map((names: any) => names.day.split('-')[2]);
    //     this.pastMonthTotalBillAmount = Object.values(res.data.dataset).map((total: any) => total.totalBillAmount);
    //         // this.plotMonthChart();
    //       });
    // });
  }

  // plotMonthChart(){
  //   this.salesChart = Highcharts;
  //   const isPastMonthDataEmpty = this.pastMonthTotalBillAmount.every(value => value === 0);
  //   const isCurrentMonthDataEmpty = this.currentMonthtotalBillAmount.every(value => value === 0);
  //   if (isPastMonthDataEmpty && isCurrentMonthDataEmpty) {
  //     // Display a "No data found" message
  //     const chartOptions = {
  //       chart: {
  //         type: 'spline',
  //         events: {
  //           load: function() {
  //             const chart = this;
  //             const centerX = chart.plotWidth / 2;
  //             const centerY = chart.plotHeight / 2;
      
  //             // Add the image element
  //             chart.noDataImage = chart.renderer
  //               .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 70, 200, 150)
  //               .add();
  //           }
  //         }
  //       },
  //       title: {
  //         text: this.salesType + ' Sales Report',
  //           style: {
  //             fontFamily: "'Poppins', sans-serif",
  //             fontWeight: '500',
  //             fontSize: '16px',
  //             color: '#737791'
  //           }
  //       },
  //       xAxis: {
  //         categories: this.weekNames
  //       },
  //       credits: {
  //         enabled: false
  //       },
  //     }; 
  //         this.salesChart.chart('salesCanvas', chartOptions);
  //       } else {
  //       // Data available, render the chart
  //     const chartOptions = {
  //       chart: {
  //     type: 'spline'
  //       },
  //       title: {
  //         text: this.salesType + ' Sales Report',
  //         style: {
  //           fontFamily: "'Poppins', sans-serif",
  //           fontWeight: '500',
  //           fontSize: '16px',
  //           color: '#737791'
  //         }
  //       },
  //       credits:{
  //         enabled: false
  //      },
  //       xAxis: {
  //         categories: this.pastmonthDay 
  //       },
  //       yAxis: {
  //         title: {
  //           text: 'Sales Amount'
  //         },
  //         gridLineWidth: 0
  //       },
  //       plotOptions: {
  //         series: {
  //             animation: {
  //                 duration: 3000
  //             },
  //             cursor: 'pointer',
  //         }
  //       },   
  //       series: [
  //         {
  //           name: 'Past Month',
  //           data: this.pastMonthTotalBillAmount,
  //           color: '#FC7643',
  //         },
  //         {
  //           name: 'Current Month',
  //           data: this.currentMonthtotalBillAmount,
  //           color: '#110152',
  //         }
  //       ]
  //     };
  //     this.salesChart.chart('salesCanvas', chartOptions);
  //   }
  // }

  getTotalInvoiceYearReport() {
    // if (this.newSaleDate) {
    //   this.todaysDate = this.newSaleDate;
    // }
    // const oneYearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD')
    // this.dashboardService.getTotalInvoiceYearReport({}, this.currentUser.id, this.todaysDate, "SALES").then((res) => {
    //   this.yearlySalesDate = res.data.dataset.map((names: any) => names.date + "/" + names.year);
    //   this.yearlySalestotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
    //   // this.plotYearlyChart()
    //   this.dashboardService.getTotalInvoiceYearReport({}, this.currentUser.id, oneYearAgo, "SALES").then((res) => {
    //     this.lastyearlySalesDate = res.data.dataset.map((names: any) => names.date + "/" + names.year);
    //     this.lastYearlySalestotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
    //     // this.plotYearlyChart()
    //   })
    // })
  }

  // plotYearlyChart(){
  //   this.salesChart = Highcharts;
  //   const isPastYearDataEmpty = this.lastYearlySalestotalBillAmount.every(value => value === 0);
  //   const isCurrentYearDataEmpty = this.yearlySalestotalBillAmount.every(value => value === 0);
  //   if (isPastYearDataEmpty && isCurrentYearDataEmpty) {
  //     // Display a "No data found" message
  //     const chartOptions = {
  //       chart: {
  //         type: 'spline',
  //         events: {
  //           load: function() {
  //             const chart = this;
  //             const centerX = chart.plotWidth / 2;
  //             const centerY = chart.plotHeight / 2;
      
  //             // Add the image element
  //             chart.noDataImage = chart.renderer
  //               .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 70, 200, 150)
  //               .add();
  //           }
  //         }
  //       },
  //       title: {
  //         text: this.salesType + ' Sales Report',
  //           style: {
  //             fontFamily: "'Poppins', sans-serif",
  //             fontWeight: '500',
  //             fontSize: '16px',
  //             color: '#737791'
  //           }
  //       },
  //       xAxis: {
  //         categories: this.weekNames
  //       },
  //       credits: {
  //         enabled: false
  //       },
  //     };
  //         this.salesChart.chart('salesCanvas', chartOptions);
  //       } else {
  //         const chartOptions = {
  //           chart: {
  //             type: 'spline'
  //           },
  //           credits:{
  //             enabled: false
  //          },
  //           title: {
  //             text: this.salesType + ' Sales Report',
  //             style: {
  //               fontFamily: "'Poppins', sans-serif",
  //               fontWeight: '500',
  //               fontSize: '16px',
  //               color: '#737791'
  //             }
  //           },
  //           xAxis: {
  //             categories: this.yearlySalesDate
  //             // categories: this.lastyearlySalesDate + '-' + this.yearlySalesDate
  //           },
  //           yAxis: {
  //             title: {
  //               text: 'Sales Amount'
  //             },
  //             min: 0,
  //             gridLineWidth: 0
  //           },
  //           plotOptions: {
  //             series: {
  //                 animation: {
  //                     duration: 3000
  //                 },
  //                 cursor: 'pointer',
  //                 dragDrop: {
  //                   draggableY: true, // enable Y-axis dragging
  //                   // dragMinY: 0, // minimum Y-axis value
  //                   // dragMaxY: 100, // maximum Y-axis value
  //                   // dragPrecisionY: 1, // Y-axis precision
  //                   dragCursor: 'ns-resize', // cursor style during drag
  //                   dragStart: function (e) {
  //                     console.log('Drag started on series:', e.target.name);
  //                   },
  //                   dragEnd: function (e) {
  //                     console.log('Drag ended on series:', e.target.name);
  //                   }
  //                 }
  //             }
  //           },   
  //           series: [{
  //             name: 'Past Year',
  //             data: this.lastYearlySalestotalBillAmount,
  //             color: '#FC7643',
  //           },
  //           {
  //             name: 'Current Year',
  //             data: this.yearlySalestotalBillAmount,
  //             color: '#110152',
  //           }]
  //         }
  //         this.salesChart.chart('salesCanvas', chartOptions)
  //       }
  // }

}
