<div class="modal-body" *ngIf = "currentUser.userType === 'EMPLOYEE'" style="height: 100%;overflow-y: auto; color: #000;">
  <form  [formGroup]="SaleInvoiceForm">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-8 mb-3 ps-0">
        <div class="card">
          <div style = "display: flex;align-items: center;flex-wrap:wrap;">
            <div class="ps-1">
              <button type="button" class="btn my-1" style="width: 50px; height: 35px; background-color: #110152;">
                <img src="../../../../assets/icons/barcode.png" class="img-fluid" style="width:25px;height:25px;">
              </button>
            </div>
            <div style="flex-grow: 1;" class="search-product">
              <mat-form-field class="example-full-width my-1" appearance="outline">
                <input type="text" id="inp12" placeholder="Enter item name or scan barcode" (input)="onSearchInputChange($event.target.value)"
                  aria-label="Select Vendor Name" matInput [formControl]="myControl1" [matAutocomplete]="auto">
                <mat-icon class="m-2" matSuffix>search</mat-icon>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions2 | async" [value]="option.itemName"
                    (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                    <span style="font-size: 12px;">
                      {{ option?.itemName }}/<b>
                      {{ option?.barcode }}</b>
                    </span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="sale-icon">
              <button type="button" class="btn my-1" style="width: 75px; height: 35px; background-color: #F9C2AD; display: flex;justify-content:center; align-items: center;border-radius: 0px;">
                <!-- <img src="../../../../assets/icons/sale-icon.png" class="img-fluid" style="width:30px;height:25px;"> -->
                <span style="font-size: 14px; color: #000;">SALE</span>
              </button>
            </div>
            <div class="grid-btn">
              <button type="button" class="btn me-3 my-1" style="width: 100px; height: 35px; display: flex;justify-content:center;align-items:center; background-color: #110152;border-radius:0px;">
                <!-- <img src="../../../../assets/icons/grid.png" class="img-fluid" style="width: 25px; height: 25px; margin-right: 2px;"> -->
                <span style="font-size: 14px; color: #fff;">GRID</span>
              </button>
            </div>
          </div>
        </div>
        <div class="card" style="overflow-x: auto;">
          <table class="table table-responsive">
            <thead>
              <tr>
                <th style="width: 5%;text-align:center;">-</th>
                <th style="width: 35%;">Item Name</th>
                <th style="width: 25%;">Price</th>
                <th style="width: 10%;">Qty.</th>
                <th style="width: 10%;">Discount</th>
                <th style="width: 25%;text-align:right;">Total</th>
              </tr>
            </thead>
            <tbody>
              <ng-container formArrayName="subcheckbox"
              *ngFor="let items of formArr.controls;let item of productData;let i=index">
              <tr [formGroupName]="i">
                <td>
                  <button type="button" class="text-center btnb" style="width: 3rem;" (click)="changeQuantity('rm',i)">
                    <span class="bi-trash icon-color fs-1" style="color:red;" data-bs-toggle="tooltip"
                      data-bs-placement="top" title="Delete"></span>
                  </button>
                </td>
                <!-- <td style="vertical-align: middle;">{{ i + 1 }}</td> -->
                <!-- <td>
                  {{ item.barcode }}
                </td> -->
                <td>{{ item?.itemName | titlecase }}</td>
                <!-- <td class="text-center">
                  <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit" readonly />
                </td> -->
                <td colspan="1">
                  <p class="mb-0">
                    AED&nbsp;{{ item?.basePrice | number:'1.2-2' }}
                  </p>
                </td>
                <td style="display:flex; flex-direction: row; justify-content: center; align-items: center; padding: 14px 9px 13px 9px;">
                  <a class="border-white btn mb-0" style="margin-top: 0px ;padding: 0px;"
                    (click)="changeQuantity('-',i)">-</a>
                  <input type="text" matInput class="mb-0" [value]="item.quantity" placeholder="Quantity"
                    (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem; text-align: center;" />
                  <a class="border-white btn mb-0" style="margin-top: 0px; padding: 0px;"
                    (click)="changeQuantity('+',i)">+</a>
                </td>
                <td colspan="1">
                  <p class="mb-0">
                    {{ item?.offerData?.discountPercentage || 0 | number:'1.2-2' }} %
                  </p>
                </td>
                <!-- <td colspan="1">
                  <p class="">
                    {{ item.gstRate }}%
                  </p>
                </td> -->
                <!-- <td colspan="1" style="max-width: 150px;">
                  <p class="">
                    AED&nbsp;{{item.gstAmount | number:'1.2-2'}}
                  </p>
                </td> -->
                <td>
                  <p class="mb-0" style="max-width: 150px;text-align:right;">
                    AED&nbsp;{{item?.total | number:'1.2-2'}}
                  </p>
                </td>
              </tr>
              <tr *ngIf="item.isOffer" class="promotion-row" style="color: #000;">
                <td colspan="1">
                </td>
                <td colspan="2" style="text-align: left;">
                  {{ item.offerData?.schemaName}}
                </td>
                <!-- <td colspan="2" class="text-success" style="text-align: right;">
                  AED&nbsp; - {{ (item?.gstAmount - item.offerData?.originalGst) | number:'1.2-2' }}
                </td> -->
                <td colspan="3" style="text-align: right;">
                  AED&nbsp; - {{ (item?.total - item.offerData?.totalDiscountedPrice) | number:'1.2-2' }}
                </td>
                <!-- <td></td> -->
              </tr>
              <tr *ngIf="item.isFreeProduct">
                <td colspan="1">
                </td>
                <td colspan="1" style="text-align: left;">
                  {{ item?.schemaName | uppercase }}
                </td>
                <td colspan="2" style="text-align: right;padding-right: 46px;">
                  {{ item?.freeProductQuantity}}
                </td>
                <!-- <td colspan="1" class="text-success" style="text-align: right;">
                  AED&nbsp; - {{ item?.freeProductTotalGst | number:'1.2-2' }}
                </td> -->
                <td colspan="2" style="text-align:right;">
                  AED&nbsp; - {{ item?.freeProductTotalPrice | number:'1.2-2' }}
                </td>
                <!-- <td></td> -->
              </tr>
              </ng-container>
            </tbody>
            <tbody *ngIf="formArr.controls.length === 0 && productData === 0">
              <tr class="text-center">
                <!-- <td style="width:100px;">&nbsp;</td> -->
                <td colspan="6">
                  <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                    alt="notfound1" width="200" height="150" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-4 pe-0">
        <div class="card">
          <!-- <div style="display: flex; align-items: center; justify-content: center; gap: 2rem; padding: 20px 10px;">
            <div>
              <div class="my-1" *ngIf="!showInvoiceGenerateData">
                <div class="d-flex" style="font-size: 14px;">
                  <div class="form-check padding-left">
                    <input class="" style="margin-left: 0px" id="inlineCheckbox1" type="checkbox" formControlName="cashSale"
                      (change)="isCashSaleToggle($event.target.checked)" [disabled]="showInvoiceGenerateData" />
                  </div>
                  <label class="text-nowrap saleText form-label" style="margin-left:4px">Cash </label>
                </div>
              </div>
              </div>
              <div>
                <button type="button" class="btn btn-cancel my-1"  (click)="onCancelModal()">Cancel Sale</button>
              </div>
          </div> -->
          <!-- <div class="dropdown-divider"></div> -->
          <div>
            <div style="display: flex; align-items: center; justify-content: flex-end;gap:1rem;width: 99%;margin-right:4px;" class="mt-3 ps-3">
              <!-- <button type="button" class="btn" style="border: 1px solid black;font-size: 14px;">...</button> -->
              <div style="flex: 0 0 auto;">
                <button type="button" class="btn" style="width: 100px; height: 34px; color: #fff; background-color: #110152;"
                (click)="onCancel()">
                  <span>Clear</span>
                </button>
              </div>
              <div style="flex: 0 0 auto;">
                <button [matMenuTriggerFor]="menu" class="btn" type="button" style="height: 34px; width: 36px; background-color: #110152; color: #fff;">
                  <i class="fas fa-ellipsis-v"></i>
                </button>
                <mat-menu #menu="matMenu">
                  <!-- <button mat-menu-item>
                    <mat-icon>check</mat-icon>
                    <span>Suspended sale</span>
                  </button> -->
                  <!-- <button mat-menu-item>
                    <mat-icon>check</mat-icon>
                    <span>Batch Sale</span>
                  </button> -->
                  <button mat-menu-item (click)="onShowLastSaleReceiptClick()" *appHasPermission="['read']; name 'sales'" data-bs-toggle="modal" data-bs-target="#invoice-modal2">
                    <mat-icon>check</mat-icon>
                    <span>Show last sale receipt</span>
                  </button>
                  <button mat-menu-item (click)="openCustomerFacingDisplay()">
                    <mat-icon>check</mat-icon>
                    <span>Customer facing display</span>
                  </button>
                  <!-- <button mat-menu-item>
                    <mat-icon>check</mat-icon>
                    <span>Pop Open cash drawer</span>
                  </button> -->
                  <button mat-menu-item (click)="openDialogPayment(true)">
                    <mat-icon>check</mat-icon>
                    <span>Add cash to register</span>
                  </button>
                  <button mat-menu-item (click)="openDialogPayment(false)">
                    <mat-icon>check</mat-icon>
                    <span>Remove Cash to register</span>
                  </button>
                  <button mat-menu-item (click)="openCloseRegister()">
                    <mat-icon>check</mat-icon>
                    <span>Close register</span>
                  </button>
                  <!-- <button mat-menu-item>
                    <mat-icon>check</mat-icon>
                    <span>Enable test mode</span>
                  </button> -->
                  <button mat-menu-item (click)="customFieldConfiguration()">
                    <mat-icon>check</mat-icon>
                    <span>Custom field configuration</span>
                  </button>
                </mat-menu>
              </div>
            </div>
            <div style="display: flex; align-items: center; justify-content: space-between; width: 100%; margin-top: 4px;" class="ps-3">
              <div class="ps-1">
                <button type="button" class="btn my-1" style="width: 50px; height: 35px; background-color: #110152;">
                  <img src="../../../../assets/icons/user.png" class="img-fluid" style="width:25px;height:25px;">
                </button>
              </div>
              <mat-form-field style="font-size: 11px;" class=" example-full-width" appearance="outline">
                <input type="text" placeholder="Customer Name" ngxOnlyString aria-label="Select Vendor Name" matInput
                  [formControl]="myControl" (keyup)="searchPartyByName($event.target.value)"
                  [matAutocomplete]="autoCustomer" [ngClass]="{
                          'is-invalid':
                            myControl.invalid && myControl.touched && submitted
                        }">
                <mat-autocomplete #autoCustomer="matAutocomplete">
                  <!-- <mat-option *ngIf="currentUser.userType !== 'SHOP' && currentUser.userType !== 'WAREHOUSE'">
                    <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true"
                      title="Add new Customer" (click)="openAddPartyModal(addParty1)">
                      Add new Customer
                    </a>
                  </mat-option> -->
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                    (onSelectionChange)="setSelectedPartyDeatails(option)">
                    {{ option?.partyName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
                <!-- <mat-error class="text-danger" *ngIf="
                    (submitted || myControl.touched) && myControl.errors?.required
                  ">
                Customer Is Required.
              </mat-error> -->
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; width: 100%; margin-top: 4px;" class="ps-3">
              <div class="ps-1">
                <button type="button" class="btn my-1" style="width: 50px; height: 35px; background-color: #110152;">
                  <img src="../../../../assets/icons/tax.png" class="img-fluid" style="width:25px;height:25px;">
                </button>
              </div>
              <mat-form-field style="font-size: 11px;" class="example-full-width" appearance="outline">
                <input type="text" placeholder="Enter Tax Invoice #" matInput formControlName="invoiceNo" maxlength="20"
                  [disabled]="showInvoiceGenerateData" [ngClass]="{
                  'is-invalid': (f.invoiceNo.invalid && f.invoiceNo.touched) && submitted }">
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submitted || f.invoiceNo.touched) && f.invoiceNo.errors?.required">
                Tax Invoice Is Required.
              </mat-error>
            </div>
          </div>
        </div>
        <div class="card mt-2 p-2">
          <div *ngIf="!showInvoiceGenerateData">
            <div *ngIf="!showInvoiceGenerateData && showCounterDiscountField" class="d-flex justify-content-start">
              <p class="counter-text p-2 mb-0" (click)="getCounterDiscountAmount()" *ngIf="!counterOn" style="color: #5DBD44;">Apply Counter Discount</p>
            </div>
            <div *ngIf="!showInvoiceGenerateData && showCounterDiscountField" class="d-flex justify-content-start">
              <p class="counter-text p-2 mb-0" (click)="openCouponModal()" *ngIf="!couponApplied" style="color: #5DBD44;">
                <i class="fas fa-tags"></i> Apply Coupon
              </p>
            </div>
            <div class="p-2" style="display: flex; justify-content: space-between; background-color: #FFF0EA; color: #000;">
              <div>Subtotal</div>
              <div *ngIf="amtToMinusFromTotal === 0 || null || undefined">AED&nbsp;{{ subTotal | number:'1.2-2' }}</div>
              <div *ngIf="amtToMinusFromTotal !== 0 || null || undefined">AED&nbsp;{{ (subTotal || 0) - ((amtToMinusFromTotal - newGstRate) || 0) | number:'1.2-2' }}</div>
            </div>
            <!-- <div *ngIf="amtToMinusFromTotal !== 0 || null || undefined" class="p-2" style="display:flex;justify-content: space-between;background-color: #FFF0EA; color: #5DBD44;">
              <div>Subtotal</div>
              <div *ngIf="amtToMinusFromTotal !== 0 || null || undefined">(AED&nbsp;{{ - (amtToMinusFromTotal - newGstRate) | number:'1.2-2'}})</div>
            </div> -->
            <div class="p-2" style="display:flex;justify-content: space-between;">
              <div>Tax</div>
              <div *ngIf="(newGstRate === 0 || null || undefined)">AED&nbsp;{{ gstRate | number:'1.2-2' }}</div>
              <div *ngIf="(newGstRate !== 0 || null || undefined)">AED&nbsp;{{ (gstRate - newGstRate) || 0 | number:'1.2-2' }}</div>
            </div>
            <!-- <div class="p-2" *ngIf="(newGstRate !== 0 || null || undefined)" class="" style="display: flex; justify-content: space-between; color: #5DBD44;">
              <div>Discounted Tax</div>
              <div>(AED&nbsp;{{ - newGstRate | number:'1.2-2'}})</div>
            </div> -->
            <div class="p-2" style="display:flex;justify-content: space-between; background-color: #FFF0EA; color: #000;">
              <div>Total</div>
              <div *ngIf="(amtToMinusFromTotal === 0 || null || undefined)">AED&nbsp;{{ total || 0 | number:'1.2-2' }}</div>
              <div *ngIf="(amtToMinusFromTotal !== 0 || null || undefined)">AED&nbsp;{{ ((total || 0) - (amtToMinusFromTotal || 0)) || 0 | number:'1.2-2' }}
            </div>
            <!-- <div class="p-2" *ngIf="(amtToMinusFromTotal !== 0 || null || undefined) && !showInvoiceGenerateData && !showCounterDiscountField" class="" style="display:flex;justify-content: space-between;padding:10px 5px;background-color: #FFF0EA; color: #5DBD44;">
              <div>Discounted Total</div> -->
              <!-- <div *ngIf="(amtToMinusFromTotal !== 0 || null || undefined && f.counterDiscount.value === (0 || null || undefined || ''))"> -->
              <!-- <div *ngIf="(amtToMinusFromTotal !== 0 || null || undefined)">AED&nbsp;{{ ((total || 0) - (amtToMinusFromTotal || 0)) || 0 | number:'1.2-2'}}
              </div> -->
            </div>
          </div>

          <div>
            <table *ngIf="!showInvoiceGenerateData && showCounterDiscountField" class="table table-bordered  my-custom-scrollbar" style="color: #5DBD44;">
              <tr *ngIf="counterOn">
                <td class="thWidth p-2" style="text-align: left; color: #5DBD44;">Counter Discount</td>
                <td class="p-0 " style="text-align: right; color: #5DBD44;">
                  <span (click)="removeCounter()" class="text-danger" style="cursor: pointer;">Remove</span>
                  <mat-form-field style="font-size: 12px; width: 50%;" class="p-0 m-0" appearance="outline">
                    <span matPrefix>AED -</span>
                    <input type="text" placeholder="Counter Discount" (change)="calculationTotal()" matInput
                      formControlName="counterDiscount" (keypress)="numberOnly($event)">
                  </mat-form-field>
                </td>
              </tr>
              <tr *ngIf="couponApplied">
                <td class="thWidth p-2" style="text-align: left; color: #5DBD44;">Coupon Discount</td>
                <td class="p-0" style="text-align: right; color: #5DBD44;">
                  <ng-container *ngIf="!couponApplied; else appliedCouponBlock" (click)="openCouponModal()">
                    Apply Coupon
                  </ng-container>
                  <ng-template #appliedCouponBlock>
                    <span (click)="removeCoupon()" class="text-danger" style="cursor: pointer;">Remove</span>
                    <span>AED&nbsp;- {{ appliedCoupon?.discount_amount | number:'1.2-2' }}</span>
                  </ng-template>
                </td>
              </tr>
              <tr *ngIf="billFlatOffer?.discountAmount !== null && billFlatOffer?.discountAmount !== undefined">
                <td class="thWidth p-2" style="text-align: left; color: #5DBD44;">Flat Discount -{{billFlatOffer?.schemaName}}
                </td>
                <td class="p-2" style="width: 50px; text-align: right; color: #5DBD44;">
                  AED-&nbsp;{{ billFlatOffer?.discountAmount | number:'1.2-2'}}
                </td>
              </tr>
              <!-- <tr>
                <td class="thWidth p-2" style="text-align: left;font-size:14px;">Final Total</td>
                <td class="p-2" *ngIf="billFinalTotal !== null && billFinalTotal !== undefined"
                  style="width: 50px; text-align: right;">
                  AED&nbsp;{{ ((billFinalTotal || 0) - (billFlatOffer?.discountAmount || 0 )) | number:'1.2-2'}}
                </td>
              </tr> -->
            </table>
            <!-- <div *ngIf="!showInvoiceGenerateData && showCounterDiscountField" class="dropdown-divider"></div> -->
            <table *ngIf="!showInvoiceGenerateData && showCounterDiscountField" class="table table-responsive">
              <tbody>
                <tr>
                  <td style="border-right: 1px solid #FFF0EA;">
                    <div style="display: flex;flex-direction: column;">
                      <div class="p-2" style="text-align: left;font-size: 16px;color:#000;font-weight: 500;">
                        Total
                      </div>
                      <div *ngIf="billFinalTotal !== null && billFinalTotal !== undefined" class="p-2"
                          style="text-align: right;font-size: 15px;color: green;font-weight: 700;">
                        AED&nbsp;{{ ((billFinalTotal || 0) - (billFlatOffer?.discountAmount || 0 )) | number:'1.2-2'}}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div style="display: flex;flex-direction: column;">
                      <div class="p-2" style="text-align: left;font-size: 16px;color: black;font-weight: 500;">
                        Amount Due
                      </div>
                      <div class="p-2" style="text-align: right;font-size: 15px;color: orange;font-weight: 700;">
                        AED&nbsp;{{ changeDue || 0 | number:'1.2-2'}}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="!showInvoiceGenerateData && showCounterDiscountField" class="dropdown-divider"></div>
            <div style="padding: 10px 5px;" *ngIf="SaleInvoiceForm.get('currency')">
              <mat-label class="form-label">Payment Currency</mat-label>
              <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
                <input type="text" placeholder="Enter payment currency" matInput maxlength="20" formControlName="currency" value = "AED">
              </mat-form-field>
            </div>
            <div class="dropdown-divider"></div>
            <div class="mt-3">
              <label style="font-size: 14px;color: black;margin-left: 5px;">Add Payment</label><br>

              <button type="button" class="btn paymentMode mx-2 my-1" (change)="isCashSaleToggle($event.target.checked)"
                [ngClass]="{'selected-mode': SaleInvoiceForm.get('paymentMode')?.value === 'CASH'}"
                (click)="setPaymentMode('CASH')">
                Cash
              </button>

              <!-- <button type="button" class="btn paymentMode mx-1 my-1"
                [ngClass]="{'selected-mode': SaleInvoiceForm.get('paymentMode')?.value === 'GIFTCARD'}"
                (click)="setPaymentMode('GIFTCARD')">
                Gift Card
              </button>

              <button type="button" class="btn paymentMode mx-2 my-1"
                [ngClass]="{'selected-mode': SaleInvoiceForm.get('paymentMode')?.value === 'CREDITCARD'}"
                (click)="setPaymentMode('CREDITCARD')">
                Credit Card
              </button> -->
            </div>
            <div style="margin: 8px 0px;display:flex;align-items: center;flex-wrap: wrap;">
              <div *ngIf="!showInvoiceGenerateData && showCounterDiscountField" style="flex-grow: 1;">
                <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
                  <span matPrefix>AED -</span>
                  <input type="number" placeholder="Amount Paid by User" (input)="restrictAmountInput($event)" matInput
                    formControlName="amountByUser" maxlength="10">
                </mat-form-field>
                <mat-error *ngIf="SaleInvoiceForm.get('amountByUser').hasError('minimumAmount')">
                  Amount must be at least {{ SaleInvoiceForm.get('amountByUser').errors?.minimumAmount.requiredAmount }} AED.
                </mat-error>
              </div>
              <div>
                <button type="button" class="btn btn-save ms-2" (click)="postSaleInvoice()"
                *appHasPermission="['create']; name 'sales'">Complete Sale</button>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <div *ngIf="SaleInvoiceForm.get('comment')" style="margin: 4px;">
              <textarea rows="3" style="width: 100%;" placeholder="Add comments" formControlName="comment"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add New Party Modal -->
    <ng-template #addParty1 let-modal>
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 options">
          <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
            aria-label="Close"></a>
        </div>
        <div class="modal-body p-0">
          <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
            <h4 class="mb-1" id="modalExampleDemoLabel" style="font-size: 16px;font-weight:500;font-family: 'Poppins';">
              Add New Customer
            </h4>
          </div>
          <form [formGroup]="PartyForm">
            <div class="p-4 pb-0">
              <div class="form-check form-switch mb-0 lh-1 mb-3">
                <input class="form-check-input" type="checkbox" [checked]="isShowGstFields" id="flexSwitchCheckDefault"
                  (click)="customertoggleShow($event.target.checked)" />
                <label class="lab text-nowrap form-label p-1" style="font-size: 14px;"> {{ isShowGstFields ? 'As
                  Customer' : 'As Vendor' }} </label>
              </div>

              <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <div><mat-label>{{ lang.GSTIN }}</mat-label></div>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" matInput formControlName="gstIn" placeholder="{{lang.GSTIN }}" #input1
                      (keypress)="numberOnly($event)" maxlength="15" minlength="15"
                      (input)="validateTRN($event.target.value)"
                      [ngClass]="{'is-invalid': (pf.gstIn.invalid && pf.gstIn.touched) && submittedParty }">
                  </mat-form-field>

                  <!-- <mat-error class="text-danger"
                    *ngIf="((submittedParty || pf.gstIn.touched) && pf.gstIn.errors?.required)">
                    {{ lang.GSTIN }} is Required.
                  </mat-error> -->
                  <mat-error class="text-danger"
                    *ngIf="(pf.gstIn.invalid && pf.gstIn.dirty ) || pf.gstIn.errors?.maxlength || pf.gstIn.errors?.minlength">
                    Please enter {{ lang.GSTIN }} number with 15-digits.
                  </mat-error>
                </div>

              </div>

              <div class="row d-flex justify-content-start">
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">Customer Name<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Customer Name" formControlName="partyName"
                      ngxOnlyString [ngClass]="{
                      'is-invalid': (pf.partyName.invalid && pf.partyName.touched) && submittedParty }">
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="(submittedParty || pf.partyName.touched)">
                    <mat-error *ngIf="(submittedParty || pf.partyName.touched) && pf.partyName.errors?.required">
                      Customer Name Is Required.
                    </mat-error>
                  </mat-error>

                </div>
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">Mobile #<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Mobile #" matInput formControlName="mobile" minlength="10"
                      maxLength="10" ngxOnlyNumbers [ngClass]="{
                        'is-invalid': (pf.mobile.invalid && pf.mobile.touched) && submittedParty }">
                  </mat-form-field>

                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.pattern">
                    Enter a valid mobile #.
                  </mat-error>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.required">
                    Mobile # is required.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.maxlength">
                    Mobile # must be of 10-digits.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.minlength">
                    Mobile # must be of 10-digits
                  </mat-error>
                </div>
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">E-Mail<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input type="email" placeholder="E-Mail" matInput formControlName="email" [ngClass]="{
                        'is-invalid': (pf.email.invalid && pf.email.touched) && submittedParty }">
                  </mat-form-field>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || pf.email.touched) && pf.email.errors?.pattern">
                    Enter a valid E-Mail.
                  </mat-error>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || pf.email.touched) && pf.email.errors?.required">
                    E-Mail is required.
                  </mat-error>
                </div>
              </div>

              <div class="row d-flex justify-content-start">
                <div class="col-lg-4 col-sm-12 col-md-6">
                  <label class="form-label">Billing Address<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="billingAddress" maxlength="150" placeholder="Enter your address"
                      [ngClass]="{
                        'is-invalid': (pf.billingAddress.invalid && pf.billingAddress.touched) && submittedParty }"></textarea>
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="(submittedParty || pf.billingAddress.touched)">
                    <mat-error
                      *ngIf="(submittedParty || pf.billingAddress.touched) && pf.billingAddress.errors?.required">
                      Billing Address Is Required.
                    </mat-error>
                  </mat-error>
                </div>

                <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
                  <label class="form-label">Shipping Address</label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-check form-check-inline mb-2">
                <label class="form-check-label form-label fs--1" for="inlineCheckbox1"><span
                    style="font-size: 14px;">Add Shipping Address</span></label>
                <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
                  [checked]="isShippingAddressinModal" (change)="showShippingAddressinModal($event.target.checked)" />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-cancel" (click)="modalDismiss()">Close</button>
          <button class="btn btn-save" (click)="addNewParty()" data-bs-dismiss="modal">Add
          </button>
        </div>
      </div>
    </ng-template>

  </form>
</div>
