
<div *ngIf="isLoading" class="loader-container">
  <div class="loader">
      <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
      </div>
  </div>
</div>

<!-- UAE -->
<div class="nav-list-items d-flex my-2" style="flex-wrap:wrap;">
  <div class="nav-item justify-content-start navOption-btns mt-2">
    <button *ngIf="currentUser.userType === 'CLIENT'" type="button" class="btn shortcutBtn mb-3" (click)="openModal('sale-invoice')">
      <span class="btn-icon"><i class="fas fa-shopping-cart"></i></span>
      Create New Sale Invoice
    </button>
    <button  *ngIf="currentUser.userType === 'EMPLOYEE'" type="button" class="btn shortcutBtn mb-3" (click)="openSaleInvoice()">
      <span class="btn-icon"><i class="fas fa-shopping-cart"></i></span>
      Create New Sale Invoice
    </button>
  </div>

  <!-- <div class="nav-item justify-content-start navOption-btns mt-2">
    <button type="button" class="btn shortcutBtn" (click)="startSession()">
      <span class="btn-icon"><i class="fas fa-shopping-cart"></i></span>
      Start Session
    </button>
  </div> -->
  <div class="nav-item justify-content-start navOption-btns mt-2">
    <button type="button" class="btn shortcutBtn mb-3" (click)="openModal('purchase-invoice')">
      <span class="btn-icon"><i class="fas fa-shopping-bag"></i></span>
      Create New Purchase Invoice
    </button>
  </div>
  <div class="nav-item justify-content-start navOption-btns mt-2">
    <button type="button" class="btn shortcutBtn  mb-3" [routerLink]="['/pages/close-register']">
      <span class="btn-icon"><i class="fas fa-shopping-bag"></i></span>
      Close Register
    </button>
  </div>
  <!--
  <div class="nav-item justify-content-start navOption-btns mt-2">
    <button type="button" class="btn shortcutBtn"  [routerLink]="['/pages/inventory-report/Inventory summary']">
      <span class="btn-icon"><i class="fas fa-file-invoice"></i></span>
      Today Sale Report
    </button>
  </div>
  <div class="nav-item justify-content-start navOption-btns mt-2" [routerLink]="['/pages/inventory-report/Inventory summary']">
    <button type="button" class="btn shortcutBtn" >
      <span class="btn-icon"><i class="fas fa-chart-bar"></i></span>
      Item Summary Report
    </button>
  </div> -->
</div>

<div class="mt-2" style="background-color: #e7e6ee; padding: 0px 12px 8px 12px" *appHasPermission="['read']; name 'dashboard'">
  <nav class="pt-1">
    <div class="container-fluid d-flex space-between" style="align-items: center;flex-wrap:wrap;">
      <div class="nav-list-items dashboard-select" *appHasPermission="['read']; name 'dashboard'">
        <mat-form-field appearance="outline" class="create">
          <mat-select placeholder="Default Dashboard" [(ngModel)]="selectedDashboard"
            (selectionChange)="onChangeSelection($event)">
            <mat-option value="0">Default Dashboard</mat-option>
            <mat-option *ngFor="let dashboard of dashboardNameList" [value]="dashboard.dashboardnew?.id">
              {{ dashboard?.dashboardnew?.dashboardName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div class="nav-list-items heading-container">
        <div class="heading">Welcome to iCeipts</div>
      </div> -->
      <div class="nav-list-items dashboard-btns d-flex mt-2" *appHasPermission="['create']; name 'dashboard'">
        <div class="nav-item navOption-btns create">
          <button type="button" class="btn dashboardButton" (click)="openDialog()">
            Create New Dashboard
          </button>
        </div>
        <div class="nav-item navOption-btns create" *appHasPermission="['modify']; name 'dashboard'">
          <button *ngIf="selectedDashboard !== '0'" type="button" class="btn dashboardButton" (click)="customizeDashboard(selectedDashboard)">
            Customize
          </button>
        </div>
      </div>
    </div>
  </nav>
  <!--*ngIf="selectedDashboard === '0'"-->
  <div class="default-dashboard">
    <div *ngIf="!addWidget" class="dash-widget-list d-flex flex-wrap drop-area">
      <div *ngFor=" let widget of selectedWidgetList" class="widget p-2 width{{ widget.widgetWidth }}">
        <div dynamicComponent [dynamicComponent]="widget.component" [inputs]="{ chartType: widget.chartType, chartId: widget?._id, chartData: (widget | json) }" class="widget-content">
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="addWidget">
    <!--temporary addition-->
    <div class="d-flex justify-content-center align-items-center" style="background-color: #e7e6ee; height:70vh;">
      <div class="text-center">
        <div class="mb-2">
          <div class="row">
            <div class="col-md-3 px-1">
              <img src="../../../../../assets/img/chart-icons/chart1.png" alt="" />
            </div>
            <div class="col-md-3 px-1">
              <img src="../../../../../assets/img/chart-icons/chart2.png" alt="" />
            </div>
            <div class="col-md-3 px-1">
              <img src="../../../../../assets/img/chart-icons/chart3.png" alt="" />
            </div>
            <div class="col-md-3 px-1">
              <img src="../../../../../assets/img/chart-icons/chart4.png" alt="" />
            </div>
          </div>
        </div>
        <h5>Add any custom widgets to your dashboard</h5>
        <button type="button" class="btn btn-save custom-button mx-auto" (click)="customizeDashboard(selectedDashboard)">Add widgets to
          this
          dashboard</button>
      </div>
    </div>
  </div>

</div>
<!-- this is script to integrat bot to website -->

<df-messenger #dfMessenger chat-title="iBot v2" agent-id="faef6d97-165e-44c8-bfb4-e0a7313bfb29"
  language-code="en" chat-icon="assets/img/icons/spot-illustrations/chat.png">
</df-messenger>
<!--
<script>
  // Select the df-messenger element
  const dfMessenger = document.querySelector('df-messenger');
  console.log(dfMessenger);
  // Listen for the message sent event
  dfMessenger.addEventListener('df-message-sent', function(event) {
    console.log('Message sent event triggered', event);

    // Extract the user input message from the event
    const userMessage = event.detail.message.queryText;
    console.log('User Message:', userMessage); // Check if user message is captured


    // Make sure that `currentUser` object is defined and has valid `id` and `accessToken`
    if (currentUser && currentUser.id && currentUser.accessToken) {
      console.log(`Calling API for User ID: ${currentUser.id} with token: ${currentUser.accessToken}`);

      // Call the API with the user input
      fetch(`https://uaestaging.iceipts.com/api/inventory/web/botLogin/${currentUser.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer${currentUser.accessToken}`
        },
        body: JSON.stringify({
          message: userMessage, // Send the user input message in the body
        }),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    } else {
      console.error('User information is missing.');
    }
  });
</script>
 -->
