import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeatureProductRoutingModule } from './feature-product-routing.module';
import { FeatureProductComponent } from './feature-product.component';


@NgModule({
  declarations: [FeatureProductComponent],
  imports: [
    CommonModule,
    FeatureProductRoutingModule
  ],
  exports: [FeatureProductComponent],
})
export class FeatureProductModule { }
