import { Location,TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { CustomerProfileService } from 'src/app/core/services/customer-profile.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-book-appointment-time',
  templateUrl: './book-appointment-time.component.html',
  styleUrls: ['./book-appointment-time.component.scss']
})
export class BookAppointmentTimeComponent implements OnInit {
  model:Date;
  customerForm: FormGroup;
  date: { year: number, month: number };
  calendar: any;
  public dayTimeSlot: any = [];
  today: Date;
  shopId: any;
  appointId: any;
  day: any;
  custClick: boolean = false;
  startSlot: any; endSlot: any;
  submitted: boolean = false;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  constructor(private appointmentService: AppointmentService, private profileService: CustomerProfileService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe, 
    public  _location: Location) {
    this.today = new Date();
    this.route.queryParams.subscribe(params => {
      this.appointId = params['param1'];
      this.shopId = params['param2'];
    });
  }
  selectToday() {
    this.model = this.calendar.getToday();
  }
  ngOnInit(): void {
    this.getCustomerDetails();
    this.customerForm = this.formBuilder.group({
      customerName: ["", [Validators.required]],
      phone: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      email: [null, [Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")
     , Validators.email]],
      address: ["", [Validators.required]],
    });
  }
  get f() {
    return this.customerForm.controls
  }
  getCustomerDetails() {
    this.profileService.getCustomerProfileByUserId({}, this.currentUser.id).then((res => {
      if (res) {
        this.customerForm.patchValue({
          customerName: res.data.basicDetails.firstName,
          phone: res.data.basicDetails.mobile,
          email: res.data.basicDetails.email,
          address: Object.values(res.data.primaryAddress).reduce((acc: any, next: any) => {
            next ?
              acc = acc + ' ' + next : ''
            return acc;
          }, ''),
        })
      }
    }), (err) => {
      if (err.error.status === 500) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }
  getDaySlot(shopId) {
    console.log("shopId",shopId,this.shopId)
    var d = new Date(this.model.toString());
    var dayName = d.toString().split(' ')[0];
    console.log("Day Name",dayName)
    this.appointmentService.getDaySlot({}, this.shopId, this.appointId, dayName.toLowerCase()).then((res) => {
      console.log("API ",res)
      this.dayTimeSlot = res.data.dayslots.timeslots;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      } else if (err.error.status === 500) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      } else {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        this.toastService.toastMsg({
          title: "Error",
          content: "Service For This Shop Is Not Available Right Now",
        });
        // this.toastService.openErrorSnackBar("service for this shop is not available right now");      
      }
    })
  }
  getTimeDetails(startSlot, endSlot) {
    this.startSlot = startSlot;
    this.endSlot = endSlot;
  }
  customerFormOpen() {
    this.custClick = !this.custClick;
  }
  saveCustomer() {
    this.submitted = true;
    if (this.customerForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    }
    let body = {
      "appointmentTypeId": this.appointId,
      "shopId": this.shopId,
      "bookingDate": this.model,
      "startTime": this.startSlot,
      "endTime": this.endSlot,
      "customerName": this.customerForm.controls.customerName.value,
      "phone": this.customerForm.controls.phone.value,
      "email": this.customerForm.controls.email.value,
      "address": this.customerForm.controls.address.value,
      "customerId": this.currentUser.id
    }
    this.appointmentService.postCustomerAppointBookDetails(body).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Customer Appointment Booking Successfully!"
        });

      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      } else if (err.error.status === 500) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      } else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");      
      }
    });
  }
  
 openDatepicker(){
  // Check if the current value of taskdate is an "Invalid Date"
  const selectedDate2 = this.model;
  if (isNaN(selectedDate2.getTime())) {
    // Set a default date or any valid date here
    this.model=new Date()
  }

}

goTOBack(){
  this._location.back();

}

}
