import { Component, OnInit } from '@angular/core';
import { PriceRangeDialogComponent } from '../price-range-dialog/price-range-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-best-selling-products',
  templateUrl: './best-selling-products.component.html',
  styleUrls: ['./best-selling-products.component.scss'],
})
export class BestSellingProductsComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  updatedProductList: any[] = [];
  filteredProductList: any[] = [];
  sortedProductList: any[];
  selectedFilter: string;
  selectedSort: string;
  emptyListMessage: string;
  topProductSoldList: any[];
  isSortSelected = false;
  isFilterSelected = false;
  todaySales: number;
  percentageCount: any;
  todaysProductSold: any;
  todaysProductSoldPercent: any;

  constructor(
    private dialog: MatDialog,
    public dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.getproductSoldToday();
    this.getTopProductSoldToday();
  }
  getproductSoldToday() {
    this.dashboardService.getProductSold({}, this.currentUser.id, 'SALES').then(
      (res: any) => {
        // console.log(res.data);
        this.todaysProductSold = res.data.productDataToday;
        this.todaysProductSoldPercent = res.data.extraSalesPercentage;
      },
      (err) => {
        if (err.error.status == 404) {
        }
      }
    );
  }
  getTopProductSoldToday() {
    this.dashboardService
      .getTopProductSold({}, this.currentUser.id, 10, 'SALES')
      .then(
        (res: any) => {
          this.topProductSoldList = res.data;
          this.updatedProductList = this.topProductSoldList;
          console.log(this.updatedProductList);
        },
        (err) => {
          if (err.error.status == 404) {
          }
        }
      );
  }
  getTotalSales() {
    this.dashboardService
      .getTotalSales({}, this.currentUser.id, 'SALES')
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          this.todaySales = res.data.todaySales;
          this.percentageCount = res.data.percentageChange;
        }
      });
  }
  updateFilteredProductList() {
    this.filteredProductList = this.updatedProductList;

    if (this.selectedFilter === 'low Stock') {
      this.filteredProductList = this.updatedProductList.filter(
        (item) => item.status.toLowerCase() === 'lowstock'
      );
      this.updatedProductList = this.filteredProductList;
    } else if (this.selectedFilter === 'In Stock') {
      this.filteredProductList = this.updatedProductList.filter(
        (item) => item.status.toLowerCase() === 'instock'
      );
      this.updatedProductList = this.filteredProductList;
    } else if (this.selectedFilter === 'out Of Stock') {
      this.filteredProductList = this.updatedProductList.filter(
        (item) => item.status.toLowerCase() === 'outofstock'
      );
      this.updatedProductList = this.filteredProductList;
    } else if (this.selectedFilter === 'All') {
      // console.log("Filter some products", this.productList)
      this.updatedProductList = this.topProductSoldList; // update the list if user remove all filter then only sorted list should be displayed
      this.updateSortedProductList();
      this.selectedFilter = 'All'; // Set the selected filter to 'All'
    }

    // Handle 'All' and other filter conditions as needed

    if (this.filteredProductList.length === 0) {
      this.emptyListMessage =
        'No products found within the selected filter criteria.';
      setTimeout(() => {
        this.emptyListMessage = '';
      }, 3000);
      this.updatedProductList = this.topProductSoldList; // update the list if there is not found in the filter list then only sorted list should be displayed
      this.updateSortedProductList();
      this.selectedFilter = 'All'; // Set the selected filter to 'All'
    }
  }
  updateSortedProductList() {
    console.log('updateSortedProductList', this.updatedProductList);
    this.sortedProductList = this.updatedProductList;

    if (this.selectedSort === 'Price: Low to High') {
      this.sortedProductList = this.sortedProductList.sort(
        (a, b) => a.salePrice - b.salePrice
      );
    } else if (this.selectedSort === 'Price: High to Low') {
      this.sortedProductList = this.sortedProductList.sort(
        (a, b) => b.salePrice - a.salePrice
      );
    } else if (this.selectedSort === 'Stock: Low to High') {
      this.sortedProductList = this.sortedProductList.sort(
        (a, b) => a.stock - b.stock
      );
    } else if (this.selectedSort === 'Stock: High to Low') {
      this.sortedProductList = this.sortedProductList.sort(
        (a, b) => b.stock - a.stock
      );
    } else if (this.selectedSort === 'Total Sales') {
      this.sortedProductList = this.sortedProductList.sort(
        (a, b) => b.totalSold - a.totalSold
      );
    } else {
      this.sortedProductList = this.sortedProductList.sort(
        (a, b) => b.totalSold - a.totalSold
      );
    }
  }
  openPriceRangeDialog(): void {
    const dialogRef = this.dialog.open(PriceRangeDialogComponent, {
      width: '300px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedFilter = 'Price';
        if (result.maxPrice <= 0 || result.minPrice < 0) {
          this.emptyListMessage = 'Please select a valid price range.';
          setTimeout(() => {
            this.emptyListMessage = '';
          }, 3000);
          this.getTopProductSoldToday();
        } else if (result.minPrice !== null && result.maxPrice !== null) {
          const minPrice = result.minPrice;
          const maxPrice = result.maxPrice;
          this.filteredProductList = this.updatedProductList.filter((item) => {
            return item.salePrice >= minPrice && item.salePrice <= maxPrice;
          });
          if (this.filteredProductList.length === 0) {
            this.emptyListMessage =
              'No products found within the selected price range.';
            setTimeout(() => {
              this.emptyListMessage = '';
            }, 3000);
            this.getTopProductSoldToday();
          }
        } else {
          // Handled the condition when the price range is not selected
          this.emptyListMessage = 'Please select a valid price range.';
          setTimeout(() => {
            this.emptyListMessage = '';
          }, 3000);
        }

        this.updatedProductList = this.filteredProductList;
        // this.productList = this.filteredProductList;
      }
    });
  }
  selectFilter(filter: string) {
    this.isFilterSelected = true;
    this.selectedFilter = filter;

    this.updateFilteredProductList();
  }

  selectSort(sort: string) {
    this.isSortSelected = true;
    this.selectedSort = sort;
    this.updateSortedProductList();
  }
}
