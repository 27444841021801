import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnyNsRecord } from 'dns';
import { ScheduleAppointmentService } from 'src/app/core/services/schedule-appointment.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-shopappointment',
  templateUrl: './shopappointment.component.html',
  styleUrls: ['./shopappointment.component.scss']
})
export class ShopappointmentComponent implements OnInit {

  public appointMentData = [
    {
      "id": 1,
      "Name": "Routine Visit",
      "Duration": "Business/Owner",
      "LocationType": "At Saloon",
      "BookingSpan": "Indefinatiately into Future",
      "ClientFacing": "yes",
      "RSVPEnaled": "no"
    },
    {
      "id": 2,
      "Name": "Virtual appt",
      "Duration": "Business/Owner",
      "LocationType": "Virtual/Telechat",
      "BookingSpan": "Indefinatiately into Future",
      "ClientFacing": "yes",
      "RSVPEnaled": "yes"
    },
    {
      "id": 3,
      "Name": "Test  1*1 Appointment",
      "Duration": "Business/Owner",
      "LocationType": "At Saloon",
      "BookingSpan": "Indefinatiately into Future",
      "ClientFacing": "yes",
      "RSVPEnaled": "no"
    },
    {
      "id": 4,
      "Name": "One To One",
      "Duration": "Business/Owner",
      "LocationType": "Virtual/Telechat",
      "BookingSpan": "Indefinatiately into Future",
      "ClientFacing": "yes",
      "RSVPEnaled": "yes"
    },

  ]
  public shopID: any;
  public currentPageNo: number = 0;
  public appointmentPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  public appointListRes: any = [];
  public totalrow: any;
  public appointId: any;
  public id: any;
  public schduleAllRes: any;
  public searchKey: any;


  constructor(private route: ActivatedRoute,
    private ScheduleAppointmentService: ScheduleAppointmentService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      params => this.shopID = params['id']
    );
    this.getAppointmentDetails();

  }
  getAppointmentDetails() {
    this.ScheduleAppointmentService.getAppointTypeList({}, this.shopID, this.appointmentPageSize, (this.currentPageNo + 1)).then((res) => {
      this.appointListRes = res.data.pageData;
      this.totalrow = res.data.totalrows
    })

  }

  getSearch(searchKey) {
    if(this.searchKey == ""){
      this.toastService.toastMsg({
        title: "Error",
        content: "Please Enter Apointment Name!",
      });
      // this.toastService.openErrorSnackBar("Please enter Apointment name!!!");
      this.ScheduleAppointmentService.getAppointmentSearch({}, this.shopID, this.appointmentPageSize, (this.currentPageNo + 1), this.searchKey).then((res) => {
        this.appointListRes = res.data.pageData;
        this. totalrow = res.data.totalrows;
      })
    }
    else{
      this.ScheduleAppointmentService.getAppointmentSearch({}, this.shopID, this.appointmentPageSize, (this.currentPageNo + 1), this.searchKey).then((res) => {
        this.appointListRes = res.data.pageData;
        this. totalrow = res.data.totalrows;
        if(this.totalrow == 0){
          this.toastService.toastMsg({
            title: "Error",
            content: "Appointment Not Found!",
          });
          // this.toastService.openErrorSnackBar("Appointment Not Found!!!");
        }else{
          this.toastService.toastMsg({
            title: "Success",
            content: "Appointment Found Successfully!",
            })
          // this.toastService.openSnackBar("Appointment Found Successfully!!!");
        }
      
    
    //     this.toastService.openSnackBar("Appointment Found Successfully!!!");
      },
        (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
        })
    }
    
  }

  deleteAppointMent(appointmentId) {
    this.appointId = appointmentId
    // console.log("appointment",this.appointId)
  }

  removeAppointMent() {
    this.ScheduleAppointmentService.deleteAppointmentById({}, this.appointId).then((res) => {
      console.log("----------", res)
      this.toastService.toastMsg({
        title: "Success",
        content: "Appointment Deleted Successfully!",
        })
      // this.toastService.openSnackBar("Appointment Deleted Successfully!!!");
      this.getAppointmentDetails();
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }

      })
  }
  // for pagination
  public handlePage(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.appointListRes.length > 0) {
      this.appointmentPageSize = e.pageSize;
      this.getAppointmentDetails();
    }
  }
  shopId(arg0: {}, shopId: any) {
    throw new Error('Method not implemented.');
  }


}
