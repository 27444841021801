<div class="content">
    <div class="card mb-2">
        <div class="card-body p-2">
            <div class="row flex-between-center">
                <div class="col-sm-auto mb-2 mb-sm-0">
                    <p class="d-none d-md-inline-block fs--1 mt-3 ml-5"
                        style="font-weight:800;font-size:20px!important;">
                        Wishlist
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="card p-2 mb-2" *ngFor="let item of biddingList ">
            <div class="card-body p-0 overflow-hidden ">
                <div class="row">
                    <div class="col-12">
                        <div class="row d-flex">
                            <div class="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                                <a class="d-block">
                                    <img class="img-fluid fit-cover w-sm-100 h-sm-100 rounded-0 absolute-sm-centered imageFormat"
                                        alt="" [src]="item.coverPic"
                                        onerror="this.src='../../../assets/img/products/2.jpg';" />
                                </a>
                                <!-- <div class="d-grid gap-2">
                                    <button class="btn btn-warning rounded-0" *ngIf="item.publish == 0"
                                        data-bs-toggle="modal" data-bs-target="#warning-modal"
                                        (click)="GeTproductId(item.id,item.itemName,item)">
                                        <h4 class="text-white text-bold mb-0">Publish Bid</h4>
    
                                      -->
                                <!-- </button>
                                    <button class="btn btn-warning rounded-0" *ngIf="item.publish == 1"
                                        (click)="BidTimerFalseFlow()">
                                        <h4 class="text-white text-bold mb-0">Bid closed in</h4>
                                        <h2 class="text-white text-bold mb-0">{{item.showTimeInterval}}</h2>
                                   Bid closed in 00:30:30 -->
                                <!-- </button>
                                </div> -->
                            </div>

                            <div class="col-sm-12 col-md-9 col-lg-10 col-xl-10">
                                <div class="row d-flex">
                                    <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9 text-capitalize">
                                        <h5 class="mt-1">
                                            <a class="text-dark fs-0 fs-lg-1 fw-bold">
                                                {{ item.itemName}}
                                            </a>
                                        </h5>
                                        <ul class="list-unstyled d-none d-lg-block mb-0 bgBullet mt-1">
                                            <li><span class="fas fa-circle bgBullet" data-fa-transform="shrink-12"
                                                    style="font-size: 6px!important;"></span><span>
                                                    {{ item.description }}
                                                </span>
                                            </li>
                                        </ul>
                                        <div class="mt-5" style="font-size: 12px!important;">
                                            <a class="text-dark fs-0 fs-lg-1">
                                                <span *ngIf="item.publish == 1|| item.publish == 0"
                                                    class="text-warning me-3" style="font-size: 12px!important;"><span
                                                        style="font-size: 12px!important;"
                                                        class="text-black fw-bold me-1">Expected Range:</span>(
                                                    <span>
                                                        <mat-icon style="font-size: 12px!important;"
                                                            class="iconcalender">
                                                            currency_rupee
                                                        </mat-icon>
                                                    </span>{{item.budgetMin}}-
                                                    <span>
                                                        <mat-icon style="font-size: 12px!important;"
                                                            class="iconcalender">
                                                            currency_rupee
                                                        </mat-icon>
                                                    </span>{{item.budgetMax}})</span>
                                                <span *ngIf="item.publish == 1|| item.publish == 0"
                                                    class="text-warning me-3" style="font-size: 12px!important;"><span
                                                        style="font-size: 12px!important;"
                                                        class="text-black fw-bold me-1">Delevery
                                                        Pincode:</span>{{item.pincode}}</span>
                                                <span *ngIf="item.publish == 1|| item.publish == 0"
                                                    class="text-warning me-3" style="font-size: 12px!important;"><span
                                                        style="font-size: 12px!important;"
                                                        class="text-black fw-bold me-1">City:</span>{{item.location}}</span>
                                                <span *ngIf="item.publish == 1" class="text-warning me-3"
                                                    style="font-size: 12px!important;"><span
                                                        style="font-size: 12px!important;"
                                                        class="text-black fw-bold me-1">Expected
                                                        Delivery:</span>{{item.approxCompletedDate | date:"dd MMM YYYY"|
                                                    uppercase }}</span>
                                            </a>
                                        </div>
                                        <div class="" style="font-size: 12px!important;" *ngIf="item.publish == 1">
                                            <a class="text-dark fs-0 fs-lg-1 fw-bold"
                                                style="font-size: 12px!important;">
                                                Top 5 Lowest Biddings :
                                                <span *ngIf="item.biddings"
                                                    class="btn rounded-pill btn-outline-warning me-2"
                                                    style="font-size: 12px!important;"
                                                    [routerLink]="['/pages/customer/bidding-details',item.id]">
                                                    <span *ngIf="item.biddings">
                                                        <span
                                                            *ngFor="let items of item.biddings">{{items.price}}</span>
                                                        </span>
                                                </span>


                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-xl-3 col-sm-10 col-md-3">
                                        <div class="text-right">
                                            <div class="text-right">
                                                <p> <span>
                                                        <mat-icon
                                                            style="font-size: 18px!important; margin-right:10px!important"
                                                            class="iconcalender p-1">currency_rupee
                                                        </mat-icon>
                                                    </span><span
                                                        style="font-size: 18px!important;">{{item.price}}</span>
                                                    <span class="fw-bold">
                                                        <button class="btn fw-bold float-right"
                                                            (click)="removeWishlist(item.id)">
                                                            <span class="bi-x text-danger fw-bold"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="Delete" style="font-size: 20px!important;">
                                                            </span>
                                                        </button></span>
                                                </p>
                                                <del>
                                                    <mat-icon
                                                        style="font-size: 12px!important; margin-right:10px!important"
                                                        class="iconcalender p-1">currency_rupee
                                                    </mat-icon>
                                                    {{item.price}}
                                                </del>
                                                <span class="ms-1">-50%</span>
                                                <!-- <button class="btn btn-d" (click)="removeWishlist(item.id)">
                                                    <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Delete">
                                                    </span>
                                                </button> -->
                                            </div>
                                            <!-- <h4 class="fs-0 fs-md-1 text-warning mb-0">
                                                 $1199.5 -->
                                            <!-- {{ item.wishStatus }}
                                            </h4>
                                            <h5 class="fs--1 text-500 mb-0 mt-1">
                                                <del>$2399 </del>
                                                <span class="ms-1">-50%</span>
                                            </h5>
                                            <div class="mb-2 mt-3">
                                                <span class="fa fa-star text-warning"></span>
                                                <span class="fa fa-star text-warning"></span>
                                                <span class="fa fa-star text-warning"></span>
                                                <span class="fa fa-star text-warning"></span>
                                                <span class="fa fa-star text-300"></span>
                                                <span class="ms-1">(8)</span>
                                            </div>
                                            <div class="d-none d-lg-block">
                                                <p class="fs--1 mb-1">Shipping Cost: <strong>$50</strong></p>
                                                <p class="fs--1 mb-1">Stock:
                                                    <strong class="text-success"
                                                        *ngIf="item.product.stock > 5">Available</strong>
                                                    <strong class="text-success" *ngIf="item.product.stock <= 5">Only few
                                                        items left</strong>
                                                </p>
                                                <del>
                                                    <mat-icon style="font-size: 12px!important; margin-right:10px!important"
                                                        class="iconcalender p-1">currency_rupee
                                                    </mat-icon>
                                                    {{item.product.price}}
                                                </del>
                                                <span class="ms-1">-50%</span>
                                            </div>
                                            <div class="mt-5" *ngIf="item.publish == 1">
                                                <a class="btn btn-sm btn-warning d-lg-block p-2" style=""
                                                    [routerLink]="['/pages/customer/bidding-details',item.id]">
                                                    <span class="ms-2 ">Leader Board ></span>
                                                </a>
                                            </div>
                                           -->

                                            <!-- <div class="mt-5" *ngIf="item.publish == 1">
                                                <a class="btn btn-sm btn-warning d-lg-block p-2"
                                                    [routerLink]="['/pages/customer/bidding-details',item.id]">
                                                    <span class="ms-2 ">Leader Board ></span>
                                                </a>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="!isData">
                <div class="row min-vh-50 flex-center g-0">
                    <h4 class="text-center">No Data Found....</h4>
                </div>
            </div>

        </div>

    </div>

    <mat-paginator class="float-right mt-2" *ngIf="biddingList.length> 0" [pageSize]="partyPageSize"
        [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo" (page)="handlePage($event)"
        aria-label="Select page">
    </mat-paginator>



</div>
<!-- <div class="card p-2 mb-2">
    <div class="card-body" *ngIf="!isData">
        <div class="row min-vh-50 flex-center g-0">
            <h4 class="text-center">No Data Found....</h4>
        </div>
    </div>
</div> -->



<!-- Warning Modal -->
<div class="modal fade" id="warning-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 600px">
        <form [formGroup]="wishlistForm">
            <div class="modal-content position-relative">
                <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                    <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        data-bs-dismiss="modal" (click)="modalDismiss()" aria-label="Close"></a>
                </div>
                <div class="modal-body p-2">
                    <div class="rounded-top-lg ps-2">
                        <p class="d-none d-md-inline-block fs--1 mt-3 ml-5"
                            style="font-weight:800;font-size:20px!important;">
                            Bidding Request
                        </p>
                    </div>
                    <div class="pb-0 ps-2">
                        <div class="row">
                            <div class="col-6">
                                <h3 class="text-capitalize">{{productName}}</h3>
                            </div>

                        </div>
                        <div class="row">

                            <!-- Pincode -->
                            <div class="col-6">
                                <h5>Pincode</h5>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Pincode</mat-label>
                                    <input matNativeControl type="text" required matInput minlength="5" maxlength="5"
                                        formControlName="pincode" (change)="getPincodeDetails($event.target.value)"
                                        placeholder="Pincode" [ngClass]="{
                            'is-invalid': f.pincode.invalid && f.pincode.touched && f.pincode.pristine }" value="">
                                    <mat-error *ngIf="f.pincode.invalid && f.pincode.touched && f.pincode.pristine"
                                        class="invalid-feedback">
                                        <div class="error-font" *ngIf="f.pincode.errors.required">State
                                            Pincode Is Required!</div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!-- State -->
                            <div class="col-6">
                                <h5>Location</h5>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>State</mat-label>
                                    <mat-select disableOptionCentering aria-label="Default select example"
                                        placeholder="Select State" formControlName="location">
                                        <mat-option [value]="state" *ngFor="let state of allStates">{{ state }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <h5>Expected Range</h5>
                            <!-- Budget Min -->
                            <div class="col-6">
                                <h5>Minimum</h5>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label> Minimum</mat-label>
                                    <input matNativeControl type="text" required matInput formControlName="budgetMin"
                                        placeholder="Budget Min" [ngClass]="{
                            'is-invalid': f.budgetMin.invalid && f.budgetMin.touched && f.budgetMin.pristine }"
                                        value="" (change)="getMinBudget()">
                                    <mat-error
                                        *ngIf="f.budgetMin.invalid && f.budgetMin.touched && f.budgetMin.pristine"
                                        class="invalid-feedback">
                                        <div class="error-font" *ngIf="f.budgetMin.errors.required">
                                            Min budget Limit Is Required!</div>
                                    </mat-error>
                                </mat-form-field>
                                <div class="invalid-feedback" class="text-danger">
                                    <div class="error-font" *ngIf="isLessPrice">
                                        Budget should be less than original price
                                    </div>
                                </div>
                            </div>

                            <!-- Budget Max -->
                            <div class="col-6">
                                <h5>Maximum</h5>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Maximum</mat-label>
                                    <input matNativeControl type="text" required matInput formControlName="budgetMax"
                                        (change)="getMaxBudget()" placeholder="Budget Max" [ngClass]="{
                            'is-invalid': f.budgetMax.invalid && f.budgetMax.touched && f.budgetMax.pristine }"
                                        value="">


                                    <mat-error
                                        *ngIf="f.budgetMax.invalid && f.budgetMax.touched && f.budgetMax.pristine"
                                        class="invalid-feedback">
                                        <div class="error-font" *ngIf="f.budgetMax.errors.required">
                                            Max budget Limit Is Required!</div>
                                    </mat-error>
                                </mat-form-field>
                                <div class="invalid-feedback" class="text-danger">
                                    <div class="error-font" *ngIf="isHighPrice">
                                        Budget should be less than original price
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col">
                                <div class="form-check mt-2">
                                    <input class="form-check-input" type="checkbox" formControlName="publish"
                                        disabled />
                                    <label class="form-check-label" for="flexCheckDefault">
                                        {{ chkBoxTxt }}
                                    </label>
                                    <span class="bi-info-circle text-primary fs-1 margin-left" data-bs-toggle="tooltip"
                                        data-bs-placement="bottom" title="Publish bid to notify retailer"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3 mb-3 m-2">
                    <div class="col-6 text-center d-grid gap-2">
                        <a class="btn btn-warning" data-bs-dismiss="modal">Cancel</a>
                    </div>
                    <div class="col-6 d-grid">
                        <a class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#PopUp"
                            (click)="postwishlistBid()">Submit</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>