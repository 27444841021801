import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactUsServiceService {

  constructor(public httpClient: HttpService) { }
  private partyAddedSource = new Subject<void>();
  partyAdded$ = this.partyAddedSource.asObservable();
  notifyPartyAdded() {
    this.partyAddedSource.next();
  }

  contactUspost(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.ContactUsPost + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  contactUsImagepost(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.contactImagePost, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getAllContactEnDetails(data: {}, id, page, size,startDate,endDate){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAllContactEn + id + "?page=" + size + "&size=" + page + "&startDate=" + startDate + "&endDate=" + endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getEnquiriesList(data: {}, userId: string, size, page, search: string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getEnquiriesSearch + userId + "?page=" + page + "&size=" + size + "&search=" + search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  contactFeedback(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.ContactUsPost + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteticketById(data, ticketId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteticketById + ticketId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  deleteMultipeticket(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteMultipeticket + userId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  closeticket(data:{}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.closeticket + userId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  closeMulTicket(data:{}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.closeMulTicket + userId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
