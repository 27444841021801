import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Router } from "@angular/router";
import { catchError, switchMap, tap } from "rxjs/operators";
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ToastNotificationService } from '../services/toast-notification.service';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>("");

  constructor(
    private authService: AuthService,
    private toastService: ToastNotificationService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      console.log("-------")
      const headers = request.headers
      return next.handle(request.clone({ headers }));
    } else {
      if (this.authService.getAccessToken()) {
        request = this.addToken(request, this.authService.getAccessToken());
      }
      return next.handle(request).pipe(
        catchError((error) => {
          if (error && error.status === 401) {
            return this.handle401Error(request, next);
          } else {
            return throwError(error);
          }
        })
      );
    }
  }

  handle404Error(error: any): any {
    throw new Error("Method not implemented.");
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    console.log("handle401Error called")
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.authService.getRefreshToken();
      console.log(`Refresh token found`, token);
      if (token) {


        return this.authService.refreshToken().pipe(
          switchMap((token: any) => {
            console.log("token22", token);
            this.isRefreshing = false;

            // this.authService.storeTokens(token.data);
            this.refreshTokenSubject.next(token.data.jwtToken);
            return next.handle(this.addToken(request, token.data.jwtToken));

          }),
          catchError((err) => {
            this.isRefreshing = false;
            console.log("session expired error: " + err)
            this.toastService.toastMsg({
              title: "Error",
              content: "Session has expired! Please login again.",
            });
            return throwError(err);
          })
        );
      } else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Session has expired! Please login again.",
        });
        this.authService.logout();
        this.router.navigate(["/auth"])
      }
    }

    // let accessToken = this.authService.getAccessToken();
    // return next.handle(this.addToken(request, accessToken));
  }
}

