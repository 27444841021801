import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(public httpClient:HttpService) { }
  registerClientUsers(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.postClientUser ,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  warehouseRegister(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.warehouseRegister,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  registerCustomerUsers(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.postCustomerUser ,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllCountryClient(data:{}){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAllCountryClient , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  SearchCountryByNameId(data:{},name:string){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.searchCountryByNameId + "?search=" + name + "&exact=true", 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
