import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseInvoiceService {

  constructor(public httpClient:HttpService) { }
  getPartyByName(data: {}, userId:string , name:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.searchPartyByName +"?userId="+ userId + "&search=" + name ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getGoodsByBarcode(data: {}, barcode:any, userId:string ): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getGoodsByBarcode+barcode +"&userId="+ userId ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postPurchaseInvoice(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postPurchaseInvoice,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getAllPurchaseInvoice(data: {},userId:string,receiptType,size:any,page:any): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllPurchaseInvoice + userId + "?receiptsType=" + receiptType +"&size="+ size + "&page=" + page  + "&isDraft=0",  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllPurchaseInvoiceSearch(data: {},userId:string,receiptType,size:any,page:any,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllPurchaseInvoice + userId + "?receiptsType=" + receiptType +"&size="+ size + "&page=" + page + "&search="+search  + "&isDraft=0",  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getAllPurchaseInvoiceBySearch(data: {},userId:string,filter ,receiptType,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllPurchaseInvoice + userId + "?filter=" + filter + "&receiptsType=" + receiptType +"&search="+search  + "&isDraft=0",  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getPurchaseInvoiceById(data: {},receiptId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getPurchaseInvoiceById + receiptId,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  patchGenerateRemoteInovice(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.patchGenerateRemoteInovice,  'PATCH').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // Drafted
  getAllDraftedPurchaseInvoice(data: {},userId:string,receiptType,size:any,page:any): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllPurchaseInvoice + userId + "?receiptsType=" + receiptType +"&size="+ size + "&page=" + page  + "&isDraft=1",  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getPurchaseInvoiceDraftById(data: {},receiptId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getPurchaseInvoiceById + receiptId,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  // {{INVENTORYSERVER}}/invoice/deleteDraft/{{userId}}?draftId=pp
  deleteDraftById(data: {},userid, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deleteDraftById + userid + "?draftId=" + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      );
    });
  }
}


