import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Constants } from 'src/app/_helpers/constant';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss'],
  animations:[ trigger('openClose', [
    state('open', style({
      height: '200px',
      opacity: 1,
      //backgroundColor: 'yellow'
    })),
    transition('open => closed', [
      animate('1s')
    ]),
    transition('closed => open', [
      animate('0.5s')
    ]),
  ]),]
})
export class TestimonialComponent implements OnInit {

  constructor() { }

  isOpen = true;
  public defaultImage = Constants.images.defaultLogoImage;
  toggle() {
    this.isOpen = !this.isOpen;
  }
  ngOnInit(): void {
  }

}
