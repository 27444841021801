import { TitleCasePipe } from '@angular/common';
import lang from "src/assets/langTranslation/language_translation"
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { FormArray, FormGroup } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { PartyService } from 'src/app/core/services/party.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { SaleReturnServiceService } from 'src/app/core/services/sale-return-service.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { MatDialogRef } from '@angular/material/dialog';
import { WarehouseService } from 'src/app/core/services/warehouse.service';

@Component({
  selector: 'app-sales-return',
  templateUrl: './sales-return.component.html',
  styleUrls: ['./sales-return.component.scss']
})
export class SalesReturnComponent implements OnInit {

  public lang = lang.UAE;
  public gstRate = 0;
  public gstAmount = 0;
  public sendArray: any = [];
  public totalPrice: any;
  public barcode: any;
  public unitValue: any;
  public index: any;
  public product: any;
  public amount: any;
  public qty: any;
  public unit: any;
  public wtax: any;
  public wotax: any;
  public ramount: any;
  public allPartyList: any = [];
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public filteredOptions: Observable<string[]>
  public myControl = new FormControl();
  public myControl1 = new FormControl();
  searchedProduct: any;
  productOptions: Observable<any>;
  public partyId: any;
  public partyData: any;
  public todaysDate;
  public refNo: any;
  public saleInvoiceNo;
  public SaleReturnNo;
  public isShown: boolean = false;
  public subTotal = 0;
  public totalGST = 0;
  public total = 0;
  public additionalCess: number = 0;
  public productData: any = [];
  public saleReturnForm: any;
  public gstType: any;
  public gstInRs: any
  public gstInPer: any;
  public compositeType: any;
  public today: Date;
  public maxDate: Date;
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public lblColor: boolean = false;
  public invoiceId: any;
  public partyPageSize: number = 30;
  public invoiceDetails: any;
  public invoiceIDDD: any;
  public SaleProduct: any;
  public producteOuter: any
  public GstAmount = 0;
  public totalAmount = 0;
  public submitted: boolean = false;
  saleReturnType = "FULL";
  warehouseList: any[];

  constructor(private partyService: PartyService,
    private saleInvoiceService: SaleInvoiceService,
    private biddingService: BiddingService,
    public dialogRef: MatDialogRef<SalesReturnComponent>,
    private commonService: CommonService,
    private formBuilder: FormBuilder, private saleretunService: SaleReturnServiceService,
    private toastService: ToastNotificationService, private titleCasePipe: TitleCasePipe, private router: Router,
    public warehouseService : WarehouseService
    ) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }

  ngOnInit(): void {
    this.getAllParties();
    this.getWarehouseList();
    this.todaysDate = new Date();
    this.newAttribute = { unit: "", ramount: "", wtax: "", wotax: "", index: "", product: "", gty: "", select: "" };
    this.fieldArray.push(this.newAttribute);
    this.saleReturnForm = this.formBuilder.group({
      saleInvoiceno: ["", [Validators.required]],
      SaleReturnNo: ["", [Validators.required]],
      warehouse: [null],
      returnType: [false],
      invoiceDate: ["", new Date(), [Validators.required]],

    });
  }

  get f() {
    return this.saleReturnForm.controls;
  }

  Units = Constants.Units

  getUnit(value: string) {
    this.unitValue = "1 " + value;
  }
  getInvoiceById(item) {
    this.invoiceId = item;
    this.getallInvoice();
  }

  getallInvoice() {
    this.saleInvoiceService.getAllSaleInvoice({}, this.currentUser.id, "SALES", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.invoiceDetails = res.data.pageData.filter((ele) =>
        ele.invoiceNo == this.invoiceId,
      )
      this.invoiceIDDD = this.invoiceDetails[0].id;
      this.getReceiptById();
    })
  }

  getReceiptById() {
    this.saleInvoiceService.getSaleInvoiceById({}, this.invoiceIDDD).then((res) => {
      if(res.success){
        this.SaleProduct = res.data.sales_products;
        this.productData = this.SaleProduct
        this.GstAmount = res.data.billGstAmount;
        this.totalAmount = res.data.totalBillAmount
      }
    })
  }

  getAllParties() {
    this.partyService
      .getAllPartiesDetails({}, this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
      .then((res) => {
        this.allPartyList = res.data;
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
      });
  }

  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  searchPartyByName(item) {
    this.partyId = item.id;
    this.saleInvoiceService.getPartyByName({}, this.currentUser.id, item.partyName)
      .then((response) => {
        this.partyData = response.data.rows;
      });
  }

  addFieldValue() {
    this.fieldArray.push(this.newAttribute)
    this.newAttribute = {};
  }

  deleteFieldValue(index: number) {
    this.fieldArray.splice(index, 1);
  }
  toggleShow() {
    this.isShown = !this.isShown;
    if (this.isShown) {
      this.total = this.subTotal + this.totalGST + this.additionalCess;
    } else {
      this.total = this.subTotal + this.totalGST;
    }
  }
  /**
   * calculation total for product
   */
  async calculationTotal() {
    this.barcode = "";
    let price = 0;

    let additionalCess = 0;
    let gstInPer = 0;
    this.totalPrice = 0;
    this.subTotal = 0;
    this.gstRate = 0;
    this.productData.forEach((element) => {
      let gstInPer = element.gstRate;
      price = price + (element.salePrice * element.quantity);
      this.gstRate = ((element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity) + this.gstRate
      this.gstAmount = (element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity
      additionalCess = element.salePrice * element.additionalCess / 100;

      this.subTotal = (element.basePrice * element.quantity) + this.subTotal;

      element.subTotal = element.basePrice * element.quantity;
      element.gstAmount = (element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity
      element.total = element.salePrice * element.quantity;
      gstInPer = element.gstRate;
      if (this.isShown) {
        this.totalPrice = price;
      } else {
        this.totalPrice = price;
      }
    });
    if (this.gstType === 'Exempted') {
      this.totalGST = 0;
      this.gstInRs = 0;
      this.gstInPer = 0
    } else if (this.gstType === 'Composite') {
      if (this.compositeType == 'Manufacturer' || this.compositeType == 'Trader') {
        this.totalGST = this.subTotal * 1 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else if (this.compositeType == 'Restaurant') {
        this.totalGST = this.subTotal * 5 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else {
        this.totalGST = this.subTotal * 6 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      }
    } else {
      this.totalGST = this.gstRate;
      this.gstInRs = this.gstRate;
      this.gstInPer = gstInPer;
    }

    this.additionalCess = additionalCess
    if (this.isShown) {
      this.total = price;
    } else {
      this.total = price;
    }
  }

  /**
 * calculation for quantity change
 * @param i
 */
  changeQtyCalculation(i) {
    this.totalGST = (this.productData[i].pricePerUnit * this.productData[i].quantity) * (this.productData[i].gst / 100);
    this.subTotal = (this.productData[i].pricePerUnit * this.productData[i].quantity);
    this.total = this.totalGST + this.subTotal;
    this.calculationTotal();
  }
  /**
  * quantity change method
  * @param sign
  * @param i
  */

  changeQuantity(sign, i, quantity = 0) {
    switch (sign) {
      case "-":
        if (this.productData[i].quantity > 1) {
          this.productData[i].quantity = this.productData[i].quantity - 1;
        }
        // this.changeQtyCalculation(i);
        this.calculationTotal();
        break;
      case "+":
        this.productData[i].quantity = this.productData[i].quantity + 1;
        // this.changeQtyCalculation(i);
        this.calculationTotal();
        break;
      case "rm":
        this.productData.splice(i, 1);
        if (this.productData.length <= 0) {
          this.totalGST = 0;
          this.subTotal = 0;
          this.total = 0;
        }
        this.calculationTotal();
        break;
      case "qty":
        this.productData[i].quantity = quantity;
        this.calculationTotal();
        break;
      default:
        this.productData[i];
        break;
    }
  }
  
  onSearchInputChange(searchKey: string) {
    if (searchKey.length >= 3) {
      this.getGoodsList(searchKey);
    }
  }

  getGoodsList(value) {
    if (value && (String(value).trim() !== '' || value !== null)) {
      this.biddingService.getAllProductsByUserSearch({}, this.saleReturnForm.value.warehouse ? this.saleReturnForm.value.warehouse : this.currentUser.id, value, 1000, 1, this.saleReturnForm.value.warehouse ? this.saleReturnForm.value.warehouse : this.currentUser.id)
        .then((res) => {
          if(res.success) {
            this.searchedProduct = res.data.pageData;
            this.productOptions = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
              );
            }
        },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
            }

          })
    } else {}
  }

  /**
    * get goods by barcode
    */
  // getGoodsByBarcode() {
  //   this.saleInvoiceService.getGoodsByBarcode({}, this.barcode, this.currentUser.id)
  //     .then((response) => {
  //       // Check if the barcode already exists in productData
  //       const existingProductIndex = this.productData.findIndex(
  //         (product) => product.barcode === response.data.barcode
  //       );

  //       if (existingProductIndex !== -1) {
  //         // Barcode exists, update quantity
  //         this.productData[existingProductIndex].quantity += 1;
  //       } else {
  //         // Barcode doesn't exist, add new product
  //         response.data.quantity = 1;
  //         this.productData.push(response.data);
  //       }
  //       this.calculationTotal();
  //     });
  // }

  getGoodsByBarcodeSelected(barcode, event) {
    if (event.isUserInput) {
      this.saleInvoiceService
        .getGoodsByBarcode({}, barcode, this.currentUser.id)
        .then(async (response) => {
          response.data.quantity = 1;
          // Check if the barcode already exists in productData
          const existingProductIndex = this.productData.findIndex(
            (product) => product.barcode === response.data.barcode
            );

          if (existingProductIndex !== -1) {
            // Barcode exists, update quantity
            this.productData[existingProductIndex].quantity += 1;
            // await this.checkFreeProducts(this.productData)
            // await this.checkOffer(this.productData[existingProductIndex].quantity, this.productData[existingProductIndex].id)
            // this.removeCoupon()
          } else {
            // Barcode doesn't exist, add new product
            response.data.quantity = 1;
            this.productData.push(response.data);
            this.addDetailsButtonClick(this.productData.length - 1);
            // await this.checkFreeProducts(this.productData)
            // await this.checkOffer(response.data.quantity, response.data.id)
            // this.removeCoupon()
          }

          this.calculationTotal();
          this.myControl1.patchValue('')
          document.getElementById('inp12').nodeValue = ''
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
    }
  }

  addDetailsButtonClick(i): void {
    const content = this.saleReturnForm.get("subcheckbox") as FormArray;
    content.push(this.addRolesPermission(i));
  }

  addRolesPermission(id): FormGroup {
    return new FormGroup({
      read: new FormControl(false),
    });
  }

  getRadioBtnValue(event) {
    if (!event) {
      this.lblColor = true;
      this.saleReturnType = "FULL"
    }
    else {
      this.lblColor = false;
      this.saleReturnType = "PARTIAL"
    }
  }
  SaveSaleReturn() {
    this.submitted = true;
    if (this.saleReturnForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }
    else {
      this.productData.forEach(e => {
        let data = {
          "purchaseProductId": e.id,
          "returnQty": e.quantity
        }
        this.sendArray.push(data);
      })

      const userType = this.currentUser.userType.toLowerCase();
      const warehouseIdToSend =
        userType === 'warehouse' ? this.currentUser.id :
        userType === 'shop' ? (this.saleReturnForm.value.warehouse || this.currentUser.id) :
        userType === 'client' && this.saleReturnForm.value.warehouse !== null ? this.saleReturnForm.value.warehouse : this.currentUser.id;
      let body = {
        "invoiceNo": this.saleReturnForm.controls.saleInvoiceno.value,
        "returnType": this.saleReturnType,
        "saleReturnNumber": this.saleReturnForm.controls.SaleReturnNo.value,
        "returnDate": this.todaysDate,
        "userId": this.currentUser.id,
        "returnProducts": this.sendArray,
        warehouseId: `${warehouseIdToSend}`
      }
      this.saleretunService.postSaleReturn(body).then((res: any) => {
        if (res.success) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Sale Return Added Successfully!",
          });
          this.saleInvoiceService.notifySaleInvoiceAdded();
          this.dialogRef.close();
        }
      }, (err) => {
        this.sendArray = [];
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        } else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }
  }

  onCancelModal() {
    this.dialogRef.close();
  }

  openDatepicker() {
    // Check if the current value of invoiceDate is an "Invalid Date"
    const selectedDate = this.todaysDate;
    if (isNaN(selectedDate.getTime())) {
      this.todaysDate = new Date();
    }
  }

  getWarehouseList() {
    this.warehouseService.getAllWarehouse({
      data:{},
      userId: this.currentUser.userType !== 'CLIENT' ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
    }).then((res: any) => {
      this.warehouseList = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  // Function to handle selection change in warehouse selection dropdown
  onWarehouseSelectionChange(event: any) {
    if (event.value === 'clear') {
      this.saleReturnForm.get('warehouse').setValue(null); // Clear the selection
      this.productData = [];
      this.calculationTotal();
    }
  }
}
