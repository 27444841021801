<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: 500;margin-left: 10px;" class="modal-title mb-0">Add Current Assets</h1>

    <div class="addProductButtons">

        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="cancel()">Cancel</button>
        <button type="submit" class="btn btn-save" (click)="postAssetsDetails()" *appHasPermission="['create']; name 'assets'">Save</button>

    </div>
</div>
<div class="modal-body">
    <div class="container-fluid px-2">
        <form [formGroup]="assetsForm">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
                    <label class="form-label">Date<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input matInput required ngModel bsDatepicker placeholder="DD MMM YYYY"
                            style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                            containerClass: 'theme-dark-blue',
                            dateInputFormat: 'DD MMM YYYY',
                             showWeekNumbers:false
                            }" formControlName="assetDate" autocomplete="off" (click)="openDatepicker()">
                        <span>
                            <mat-icon class="iconcalender ">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="
                    (submitted || f.assetDate.touched) && f.assetDate.errors?.required
                  ">
                        Date is Required.
                    </mat-error>


                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
                    <label class="form-label">Assets Name<span class="error-asterisk">*</span></label>

                    <mat-form-field class="example-full-width" appearance="outline">
                        <input matInput required matNativeControl matInput disableOptionCentering
                            placeholder="Assets Name" formControlName="assetName" [ngClass]="{
                                'is-invalid': (f.assetName.invalid && f.assetName.touched) && submitted }">

                    </mat-form-field>

                    <mat-error class="text-danger" *ngIf="
                    (submitted || f.assetName.touched) && f.assetName.errors?.required
                  ">
                        Assets Name is Required.
                    </mat-error>

                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
                    <label class="form-label">Amount<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input matNativeControl (keypress)="numberOnly($event)" type="text" placeholder="Amount" formControlName="toatalAmount" />
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="
                    (submitted || f.toatalAmount.touched) && f.toatalAmount.errors?.required
                  ">
                        Amount is Required.
                    </mat-error>
                </div>
            </div>
        </form>
    </div>
</div>
