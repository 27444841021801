import { Injectable } from '@angular/core';
import { resolve } from 'dns';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class RemoteShopService {

  constructor(public httpClient: HttpService) { }

  postRemoteShopToggle(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.remoteShopToggle + userId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  gettRemoteShops(data: {},search? ): any {
    let url=UrlConstants.getAllRetailers
    if(search !== ''){
      url = url + "?search=" + (search || '')
    }  
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, url, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getClientInventoryGoodsAndServices(data: {}, userId: any,productId: string ,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getClientInventoryGoodsAndServices + userId + "?productType=" + productId + "&size="+ size + "&page=" + page + "&warehouseId=" +userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  postAddToCart(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postAddtoCart, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }

  getCustomerCartItems(data: {}, cusomerId: any): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getCustomerCartItems + cusomerId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteCustomerCartItems(data: {}, productId: any): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deleteCustomerCartItems + productId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  putProductQuantity(data: {}, productId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.putProductQuantity + productId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  postRemoteOrder(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postRemoteOrder, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }
  getClientPendingOrder(data: {},userId):any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getClientPendingOrder + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }
  getCartCount(data: {},userId):any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getCartCount + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }
  getCustomerProductByOrderId(data: {},orderId):any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getCustomerProductByOrderId + orderId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }
  patchGenerateRemoteInovice(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.patchGenerateRemoteInovice,  'PATCH').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getInvoiceByOrderId(data: {},orderId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getInvoiceByOrderId + orderId,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getGeneratedOrder(data:{},userId,status:number): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getGeneratedOrder + userId + "?status=" + status,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // getRemoteOrder(data:{},userId,status:number,search): any {
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, UrlConstants.getRemoteOrder + userId + "?status=" + status + "&totalBillAmount="+search,  'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }

  getRemoteOrder1(data:{},userId,status:number,datevalue): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getRemoteOrder + userId + "?status=" + status  + "&invoiceDate="+datevalue,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getRemoteOrdersearch(data:{},userId,status:number,value): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getRemoteOrder + userId + "?status=" + status  + "&customerName="+value,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getCustomerAllOrders(data:{},userId,size,page): any {
    console.log("service")
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getCustomerAllOrders + userId+ "?size="+ size + "&page=" + page,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
