export const environment = {
  web: "qa",
  webUrl: "https://web.iceipts.com/",
  apiUrl : "https://web.iceipts.com/api/apiserver/",
  appointmentUrl :"https://web.iceipts.com/api/appointments/",
  inventoryUrl :  "https://web.iceipts.com/api/inventory/",  

  production: false,

  // Your web app's Firebase configuration
  firebase: {
    apiKey: "AIzaSyBFnui2LL8madoiQVbmydKbecG23nvYHHE",
    authDomain: "iceipts-3b086.firebaseapp.com",
    databaseURL: "https://iceipts-3b086.firebaseio.com",
    projectId: "iceipts-3b086",
    storageBucket: "iceipts-3b086.appspot.com",
    messagingSenderId: "476279777572",
    appId: "1:476279777572:web:169fc7e0f393ff160fabb1",
    measurementId: "G-0QMHDNNGFH",
    vapidKey:
      "BCd_mm8tQfCMoDT7drbBrgc1JVbuYVUUIVbmA9q4E1g5RSv_JDiqrVpF0Qgg-uDAZGhqEqngmg-MbnHqnd6BxeE",
  },
};
