import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SaleInvoiceService {

  private SaleInvoiceAddedSource = new Subject<void>();
  saleInvoiceAdded$ = this.SaleInvoiceAddedSource.asObservable();

  private invoiceDataSource = new BehaviorSubject<any>({
    productData: [],
    subtotal: 0,
    discountedSubtotal: 0,
    tax: 0,
    discountedTax: 0,
    total: 0,
    discountedTotal: 0,
    counterDiscount: 0,
    couponDiscount: 0,
    flatDiscount: 0,
    finalTotal: 0,
    amountByUser: 0,
    amountDue: 0,
    paymentMode: 'Cash',
  });
  // Observable to expose invoice data changes
  currentInvoiceData = this.invoiceDataSource.asObservable();

  notifySaleInvoiceAdded() {
    this.SaleInvoiceAddedSource.next();
  }

  // Method to update invoice data
  updateInvoiceData(newData: any) {
    this.invoiceDataSource.next(newData);
  }

  constructor(public httpClient: HttpService) { }

  getPartyByName(data: {}, userId:string , search: string): any {
    let url = UrlConstants.searchPartyByName +"?userId=" + userId;
    if(search){
      url += "&search=" + search ;
    }
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, url, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getGoodsByBarcode(data: {}, barcode:any, userId:string, warehouseId?): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getGoodsByBarcode + barcode + "&userId="+ userId + "&warehouseId=" + (warehouseId || ''),  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postSaleInvoice(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postSalesInvoice,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllSaleInvoice(data: {},userId:string,receiptType,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllSalesInvoice + userId + "?receiptsType=" + receiptType + "&size="+ size + "&page=" + page + "&isDraft=0",  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteSaleInvoice(invoiceId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory({}, UrlConstants.deleteSaleInvoice + invoiceId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllPaymentInData(data: {}, userId:string, receiptType, size, page): any {
    return new Promise((resolve, reject) => {
      // this.httpClient.inventory(data, UrlConstants.getAllPaymentInData + userId + "?receiptsType=" + receiptType + "&size="+ size + "&page=" + page,  'GET').subscribe(
      this.httpClient.inventory(data, UrlConstants.getAllPaymentInData + userId,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // {{INVENTORYSERVER}}/invoice/getDetailedPaymentInOutData/f27a759e-1d86-4a9f-a551-37d87fbc200c?userId={{userId}}
  getDetailedPaymentInOutData(data: {},saleId: string, userId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getDetailedPaymentInOutData + saleId + "?userId=" + userId,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  getAllPaymentOutData(data: {}, userId:string, receiptType, size, page): any {
    return new Promise((resolve, reject) => {
      // this.httpClient.inventory(data, UrlConstants.getAllPaymentInData + userId + "?receiptsType=" + receiptType + "&size="+ size + "&page=" + page,  'GET').subscribe(
      this.httpClient.inventory(data, UrlConstants.getAllPaymentOutData + userId,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllSaleInvoiceSearch(data: {},userId:string,receiptType,size,page,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllSalesInvoice + userId + "?receiptsType=" + receiptType + "&size="+ size + "&page=" + page + "&search="+search + "&isDraft=0",  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // {{INVENTORYSERVER}}/invoice/getSaleReturn/{{userId}}?search=SALES&searchInvoice=12

  getAllSaleReturnSearch(data: {},userId:string,receiptType,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllSalesReturn + userId + "?search=" + receiptType +"&searchInvoice="+search  + "&isDraft=0",  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSaleInvoiceById(data: {},receiptId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleInvoiceById + receiptId,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postInvoiceNumber(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postinvoiceFormat,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postreciptprofiledata(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.settingprofiledata,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getInvoiceNumber(data: {},Id:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postinvoiceFormat + Id,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getInvoicesetting(data: {},Id:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postinvoicesetting + Id,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateInvoiceNumber(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postinvoiceFormat+ id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateInvoicesetting(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.putinvoicesetting+ id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getSaleInvoiceNumber(data: {},Id:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleInvoiceNumber + Id,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getsaleInvoiceByIncoice(data:{},Id:string,invoiceNumber):any{

   return new Promise((resolve, reject) => {
    this.httpClient.inventory(data, UrlConstants.getsaleInvoiceByinvoiceNumber + Id +"?invoiceNo=" +invoiceNumber,  'GET').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  });
  }

  getsaleReturnByList(data: {},userId:string,size,page,search){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleReturnList + userId +  "?size="+ size + "&page=" + page +"&search=" +search + "&isDraft=0" ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // Drafted

  // getAllSaleInvoiceDrafted(data: {},userId:string,receiptType,size,page): any {
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, UrlConstants.getAllSalesInvoice + userId + "?receiptsType=" + receiptType + "&size="+ size + "&page=" + page + "&isDraft=1",  'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }
  // getAllSaleInvoiceDrafted(data: {}, userId: string, size, page, receiptType?, receiptType1?, receiptType2?, receiptType3?, receiptType4?): any {
  //   let url = UrlConstants.getAllSalesInvoice + userId + "?receiptsType=" + (receiptType || '') + "&size="+ size + "&page=" + page + "&isDraft=1"
  //   console.log("url", url)
  //   if(receiptType1){
  //     url += "&receiptType=" + receiptType1;
  //   }if(receiptType4){
  //     url += "&receiptType=" + receiptType4;
  //   }if(receiptType3){
  //     url += "&receiptType=" + receiptType3;
  //   }if(receiptType2){
  //     url += "&receiptType=" + receiptType2;
  //   }
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, UrlConstants.getAllSalesInvoice + userId + "?receiptsType=" + (receiptType || '') + "&size="+ size + "&page=" + page + "&isDraft=1",  'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }

  getAllSaleInvoiceDrafted(data: {}, userId: string, size, page, receiptType?, receiptType1?, receiptType2?, receiptType3?, receiptType4?): any {
    // Construct the base URL with required parameters
    let url = UrlConstants.getAllSalesInvoice + userId + "?receiptsType=" + (receiptType || '') + "&size=" + size + "&page=" + page + "&isDraft=1";

    // Append the additional receipt types if they exist
    if (receiptType1) {
      url += "&receiptsType=" + receiptType1;
    }
    if (receiptType2) {
      url += "&receiptsType=" + receiptType2;
    }
    if (receiptType3) {
      url += "&receiptsType=" + receiptType3;
    }
    if (receiptType4) {
      url += "&receiptsType=" + receiptType4;
    }

    return new Promise((resolve, reject) => {
      // Use the constructed URL for the HTTP request
      this.httpClient.inventory(data, url, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  getSaleInvoiceByDraftId(data: {},receiptId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleInvoiceById + receiptId,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getDraftedsaleReturnByList(data: {},userId:string,size,page,search){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleReturnList + userId +  "?size="+ size + "&page=" + page +"&search=" +search + "&isDraft=1",  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // {{INVENTORYSERVER}}/invoice/deleteDraft/{{userId}}?draftId=pp
  deleteDraftById(data: {},userid, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deleteDraftById + userid + "?draftId=" + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      );
    });
  }


  // POS APIs
  addremoveamount(data: {}, sessionId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.addremoveamount + sessionId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getCheckoutData(data: {}, sessionId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getCheckoutData + sessionId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getDailyRegisterById(data: {}, sessionId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getDailyRegisterById + sessionId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  editDailySales(data: {}, sessionId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.editDailySales + sessionId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteDailySales(data: {}, sessionId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deleteDailySales + sessionId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}

