<!-- Modal Header -->
<div class="modal-header pt-0">
  <h1 class="modal-title mb-0">New Dashboard</h1>
  <div class="addDashboardButtons nav-item d-flex">
    <button type="button" class="btn btn-cancel" style="margin-right: 10px !important" (click)="closeDialog()">
      Cancel
    </button>
    <button type="button" class="btn btn-save" color="primary" (click)="updateChartType()">
      Update Widgets
    </button>
  </div>
</div>

<!-- Modal body -->
<div class="modal-body">
  <div class="container-fluid px-2">
    <label class="form-label"></label>
    <!-- <div dynamicComponent [dynamicComponent]="currentComponent" [inputs]="{ chartType: selectedChartType, chartId: chartId, chartData: chartData, xAxisTitle: xAxisTitle, yAxisTitle: yAxisTitle, categories: categories }" class="widget-content"></div> -->
    <div dynamicComponent [dynamicComponent]="widget.component" [inputs]="{ chartType: chartType, chartId: chartId, chartData: chartData }" class="widget-content"></div>
    <!-- <app-weekly-sales [chartType]="selectedChartType"></app-weekly-sales> -->
    <div class="pt-4 pb-4">Choose Chart Type</div>
    <div class="row mb-2">
      <div class="col-sm-2" (click)="selectChartType('spline')">
        <img src="../../../../../assets/img/chart-icons/chart1.png" alt="Line Chart" />
      </div>
      <div class="col-sm-2" (click)="selectChartType('bar')">
        <img src="../../../../../assets/img/chart-icons/chart2.png" alt="Bar Chart" />
      </div>
      <div class="col-sm-2" (click)="selectChartType('column')">
        <img src="../../../../../assets/img/chart-icons/chart3.png" alt="Column Chart" />
      </div>
      <div class="col-sm-2" (click)="selectChartType('donut')">
        <img src="../../../../../assets/img/chart-icons/chart4.png" alt="Donut Chart" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2" (click)="selectChartType('pie')">
        <img src="../../../../../assets/img/chart-icons/chart5.png" alt="Pie Chart" />
      </div>
      <div class="col-sm-2" (click)="selectChartType('area')">
        <img src="../../../../../assets/img/chart-icons/chart6.png" alt="Area Chart" />
      </div>
    </div>
  </div>
<!-- </div> -->