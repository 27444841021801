import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CashInHandServiceService {
  
  private partyAddedSource = new Subject<void>();
  partyAdded$ = this.partyAddedSource.asObservable();

  constructor(public httpClient:HttpService) { }
  notifyPartyAdded() {
    this.partyAddedSource.next();
  }
  getPartyByName(data: {}, userId:string , name:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.searchPartyByName +"?userId="+ userId + "&search=" + name ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  cashInHandpost(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.CashInHandPost,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  denominationPost(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.denominationPost,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getCurrentBalance(data: {}, userId:string ): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getCurrentBalance +userId,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getCashInHandDetails(data:{},userId,size,page):any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getCashInHand + "?userId=" +userId + "&size="+ size + "&page=" + page,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getcashInHand(data: {},id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addcashhand+id,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getCashInHandlist(data:{},userId,size,page):any{
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addcashhand + "?userId=" +userId + "&size="+ size + "&page=" + page,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  deletecashand(data:{},id){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteCash + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getcurrentbalance(data: {}, userId:string ): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.currentBalance +userId,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
