<div class="container-fluid mb-3 px-0 mx-0">
  <div class="row pt-2 pb-3" id="headingPrintHide">
    <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:500;font-size:20px!important;padding-left: 15px;">
      GST Tax Report
    </div>
    <div class="col-6 navOption-btns" style="display: flex; justify-content: flex-end;">
      <button class="btn me-3" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i></button>

      <button class="btn" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
      </button>
    </div>
  </div>
  <hr class="mt-1" />

  <div class="fs--1 bg-white">
    <div class="row mb-3">
      <!-- <div class="row mx-auto mb-3"> -->
      <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;">
        <label class="form-label" id="datePrintHide">Date</label>
        <mat-form-field class="example-full-width" appearance="outline" id="datePrintHide">
          <!-- <mat-label for="Date Range">Date Range</mat-label> -->
          <input  class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
            bsDaterangepicker [maxDate]="today" [(ngModel)]="dateRange" (ngModelChange)="changeDate($event)"
                                            [bsConfig]="{ ranges: ranges,
                                            showWeekNumbers:false,
                                            showNextMonth: false,
                                            rangeInputFormat : 'DD MMM YYYY',
                                            containerClass: 'theme-dark-blue',
                                              displayOneMonthRange: true }">
          <span>
            <mat-icon class="iconcalender p-1">date_range</mat-icon>
          </span>
        </mat-form-field>
        <!-- <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label for="Date">Date</mat-label>
                      <input matInput placeholder="DD MMM YYYY" id="Date" [outsideClick]="true" ngModel bsDatepicker
                        [maxDate]="today" style="text-transform:uppercase!important;"[(ngModel)]="dateRange"  (ngModelChange)="changeDate($event)"
                        [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
                      <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                      </span>
                    </mat-form-field> -->
      </div>
      <!-- <div class="col-sm-5"></div>
      <div class="col-sm-2"></div>
      <div class="col-sm-1  mt-2 text-center" id="excelPrintHide" (click)="exportExcel()">
        <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
        <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>
      </div>

      <div class="col-sm-1 mt-2 text-center" id="PrintoptionHide" (click)="onPrint()">
        <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x"></a>
        <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
      </div> -->
      <!-- </div> -->
    </div>


    <div class="tab-pane fade show active mb-5" id="tab-home" role="tabpanel" aria-labelledby="home-tab">
      <table class="table bg-white table-bordered table-stripped" id="excel-table">
        <thead style="background-color: #F0F0F0;">
          <tr>
            <th style="width:50%" scope="col">Group Name</th>
            <th style="width:30%; text-align:right;" scope="col">Sale Amount</th>
            <th style="width:20%;text-align:right;" scope="col">Purchase Amount</th>
          </tr>
        </thead>
        <tbody id="component1">
          <tr *ngFor="let gstTax of gstTaxlist;">
            <td>{{gstTax.groupName}}</td>
            <td style="text-align: right;">₹&nbsp;{{gstTax.totalSaleAmount | number:'1.2-2'}}</td>
            <td style="text-align: right;">₹&nbsp;{{gstTax.totalPurchaseAmount | number:'1.2-2'}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="gstTaxlist == 0">
          <tr class="text-center">
            <td colspan="10">
              <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt="">
              <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>

            <td class="nowrap">
              <strong class=" text-dark">Total</strong>
            </td>

            <td class="nowrap" style="text-align: right;">
              <strong class=" text-dark">
                <a class="text-success">
                  ₹&nbsp;{{Taxin | number:'1.2-2'}}
                </a>
              </strong>
            </td>
            <td class="nowrap" colspan="1" style="text-align: right;">
              <strong class="fw-bold text-dark">
                <a class="text-success">
                  ₹&nbsp;{{Taxout | number:'1.2-2'}}
                </a>
              </strong>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>


    <!-- <div class="card-footer">
            <div>
                <div class="accordion-item position-absolute bottom-0 start-0" style=" width: 100%;">
                    <div class="accordion-body">
                        <div class="row sm p-0" style="height: px;">
                            <div class="col-sm-3 p-2">
                                <strong class=" text-dark p-2" style="margin-top: 20px; margin-left: 15px;">
                                    Total Tax In: <a class="text-success">₹ {{Taxin}}</a>
                                </strong>
                            </div>
                            <div class="col-sm-5">
                                <strong class=" text-dark"></strong>
                            </div>
                            <div class="col-sm-4 p-2">
                                <strong class="fw-bold text-dark " style="margin-left: 30px; ">Total Tax Out:
                                    <a class="text-success">₹ {{Taxout}}</a> </strong>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</div>
