import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddCustomRolesComponent } from './add-custom-roles/add-custom-roles.component';
import { ChatsComponent } from './chats/chats.component';
import { CustomerDetailsComponent } from './Customer/customer-details/customer-details.component';
import { CustomerListComponent } from './Customer/customer-list/customer-list.component';
import { ClientDashboardComponent } from './dashboard/dashboard.component';
import { EditPermissionsComponent } from './edit-permissions/edit-permissions.component';
import { LayoutComponent } from './layout/layout.component';
import { PartyStatementComponent } from './party-statement/party-statement.component';
import { ProductGridComponent } from './Product/product-grid/product-grid.component';
import { ProfileSettingsComponent } from './Profile/profile-settings/profile-settings.component';
import { ProfileComponent } from './Profile/profile/profile.component';
import { RemoteRetailerComponent } from './RemoteShopping/RemoteRetailer/remote-retailer/remote-retailer.component';
import { RolesListComponent } from './roles-list/roles-list.component';
import { GstReport2Component } from './gst-report2/gst-report2.component';
import { GstReport3BComponent } from './gst-report3-b/gst-report3-b.component';
import { PartyWiseProfitLossComponent } from './party-wise-profit-loss/party-wise-profit-loss.component';
import { AllPartiesComponent } from './all-parties/all-parties.component';
import { PartyReportByItemComponent } from './party-report-by-item/party-report-by-item.component';
import { SalePurchaseByPartyComponent } from './sale-purchase-by-party/sale-purchase-by-party.component';
import { SalePurchaseByPartyGroupComponent } from './sale-purchase-by-party-group/sale-purchase-by-party-group.component';
// import {StockSummaryComponent} from './stock-summary/stock-summary.component';

import { GstReportComponent } from './gst-report/gst-report.component';
import { GstReport9Component } from './gst-report9/gst-report9.component';
import { SaleSummaryComponent } from './sale-summary/sale-summary.component';
import { GstTaxReportComponent } from './gst-tax-report/gst-tax-report.component';
import { GstTaxRateReportComponent } from './gst-tax-rate-report/gst-tax-rate-report.component';
import { GstFormNoComponent } from './gst-form-no/gst-form-no.component';
import { TcsReceivableComponent } from './tcs-receivable/tcs-receivable.component';
import { CustomerProfileComponent } from './Customer/customer-profile/customer-profile.component';
import { CustomerDashboardComponent } from './Customer/customer-dashboard/customer-dashboard.component';
import { CustomerProfileSettingComponent } from './Customer/customer-profile-setting/customer-profile-setting.component';
import { EditPartyComponent } from './Party/edit-party/edit-party.component';
import { ItemBatchReportComponent } from './item-batch-report/item-batch-report.component';
import { ItemDetailComponent } from './item-detail/item-detail.component';
import { ItemReportByPartyComponent } from './item-report-by-party/item-report-by-party.component';
import { LowStockSummaryComponent } from './low-stock-summary/low-stock-summary.component';

import { ItemWiseProfitAndLossComponent } from './item-wise-profit-and-loss/item-wise-profit-and-loss.component';
import { ItemWiseDiscountComponent } from './item-wise-discount/item-wise-discount.component';
import { ItemSerialReportComponent } from './item-serial-report/item-serial-report.component';
import { StockDetailComponent } from './stock-detail/stock-detail.component';
import { StockSummaryComponent } from './stock-summary/stock-summary.component';
import { RemoteCustomerComponent } from './RemoteShopping/RemoteCustomer/remote-customer/remote-customer.component';
import { LayoutCustomerComponent } from './layout-customer/layout-customer.component';
import { ViewProductComponent } from './RemoteShopping/RemoteCustomer/view-product/view-product.component';
import { InvoiceNumberComponent } from './invoice-number/invoice-number.component';
import { WidgetsLayoutComponent } from './widgets-layout/widgets-layout.component';
import { UpiPaymentComponent } from './Invoice/upi-payment/upi-payment.component';
import { CustomerAppointmentComponent } from './Customer/customer-appointment/customer-appointment.component';
import { BookAppointmentComponent } from './Customer/book-appointment/book-appointment.component';
import { BookAppointmentTimeComponent } from './Customer/book-appointment-time/book-appointment-time.component';

import { ScheduleComponent } from './schedule/schedule.component';
import { OrderReceiptsComponent } from './RemoteShopping/RemoteCustomer/order-receipts/order-receipts.component';
import { RemoteCartComponent } from './RemoteShopping/RemoteCustomer/remote-cart/remote-cart.component';
import { PracticeAvailabiltyComponent } from './schedule/compnnent/admin/component/practice-availabilty/practice-availabilty.component';
import { AppointmentTypeComponent } from './schedule/compnnent/admin/component/appointment-type/appointment-type.component';
import { ManageCalenderComponent } from './schedule/compnnent/admin/component/manage-calender/manage-calender.component';
import { AutomationComponent } from './schedule/compnnent/admin/component/automation/automation.component';
import { AppointementTypeFComponent } from './schedule/compnnent/admin/component/appointement-type-f/appointement-type-f.component';
import { GstReport4Component } from './gst-report4/gst-report4.component';
import { GstReport5Component } from './gst-report5/gst-report5.component';
import { GstReport8Component } from './gst-report8/gst-report8.component';
import { GstReport2AComponent } from './gst-report2-a/gst-report2-a.component';
import { CheckoutComponent } from './RemoteShopping/RemoteCustomer/checkout/checkout.component';
import { CashInHandComponent } from './cash-in-hand/cash-in-hand.component';
import { ShopPageComponent } from './shop/shop-page/shop-page.component';
import { ShopEditPageComponent } from './shop/component/shop-edit-page/shop-edit-page.component';
import { ShopSettingComponent } from './shop/component/shop-setting/shop-setting.component';
import { ShopappointmentComponent } from './shop/component/shopappointment/shopappointment.component';
import { ShopScheduleComponent } from './shop/component/shop-schedule/shop-schedule.component';
import { ShopEmployeeComponent } from './shop/component/shop-employee/shop-employee.component';
import { ShopappointFormComponent } from './shop/component/shopappoint-form/shopappoint-form.component';
import { ShopSFormComponent } from './shop/component/shop-sform/shop-sform.component';
import { ScheduleShopFormPageComponent } from './shop/component/schedule-shop-form-page/schedule-shop-form-page.component';
import { BookAppointmentShopComponent } from './Customer/book-appointment-shop/book-appointment-shop.component';
import { GeneratedOrderReceiptsComponent } from './RemoteShopping/RemoteRetailer/generated-order-receipts/generated-order-receipts.component';
import { BiddingComponent } from './RemoteShopping/RemoteCustomer/bidding/bidding.component';
import { BiddingDetailsComponent } from './RemoteShopping/RemoteCustomer/bidding-details/bidding-details.component';
import { ProductDetailsComponent } from './RemoteShopping/RemoteCustomer/product-details/product-details.component';
import { SellerBidComponent } from './RemoteShopping/RemoteRetailer/seller-bid/seller-bid.component';
import { PlaceOrderComponent } from './RemoteShopping/RemoteCustomer/place-order/place-order.component';
import { PayMentInfoComponent } from './RemoteShopping/RemoteCustomer/pay-ment-info/pay-ment-info.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContactListTicketComponent } from './contact-list-ticket/contact-list-ticket.component';
import { PartyGroupAddComponent } from './Party/party-group-add/party-group-add.component';
import { AddGroupMultiplePartyComponent } from './Party/add-group-multiple-party/add-group-multiple-party.component';
import { UpdateshopappointmentComponent } from './shop/component/updateshopappointment/updateshopappointment.component';
import { AddNewTradeComponent } from './Profile/add-new-trade/add-new-trade.component';

import { AuthGuard } from '../core/guards/auth.guard';
import { LayoutEmployeeComponent } from './layout-employee/layout-employee.component';
import { EmployeedashboardComponent } from './employeedashboard/employeedashboard.component';
import { WarehouseLayoutComponent } from './warehouse/components/warehouse-layout/warehouse-layout.component';
import { WarehouseDashboardComponent } from './warehouse/components/warehouse-dashboard/warehouse-dashboard.component';
import { WarehouseProfileComponent } from './warehouse/components/warehouse-settings/components/warehouse-profile/warehouse-profile.component';
import { ShopModuleComponent } from './shop-module/shop-module.component';
import { ShopLayoutComponent } from './shop-module/components/shop-layout/shop-layout.component';
import { ShopProfileComponent } from './shop-module/components/shop-settings/components/shop-profile/shop-profile.component';

//Dashboard Customization imports
import { DashboardNavComponent } from './dashboard/pages/dashboard-nav/dashboard-nav.component';
import { CreateDashboardComponent } from './dashboard/pages/create-dashboard/create-dashboard.component';
import { DashboardWidgetListComponent } from './dashboard/pages/update-dashboard/dashboard-widget-list/dashboard-widget-list.component';
import { UpdateDashboardComponent } from './dashboard/pages/update-dashboard/update-dashboard.component';
import { DailyReportPageComponent } from './daily-report-page/daily-report-page.component';
import { ViewsalereportComponent } from './new-reportes/sale-report/viewsalereport/viewsalereport.component';
import { ViewpurchasereportComponent } from './new-reportes/purchase-report/viewpurchasereport/viewpurchasereport.component';
import { ViewreceivablereportComponent } from './new-reportes/receivable-report/viewreceivablereport/viewreceivablereport.component';
import { ViewpaymetreceivedreportComponent } from './new-reportes/paymentreceived-report/viewpaymetreceivedreport/viewpaymetreceivedreport.component';
import { ViewpayablereportComponent } from './new-reportes/payable-report/viewpayablereport/viewpayablereport.component';
import { ViewinventoryreportComponent } from './new-reportes/inventory-report/viewinventoryreport/viewinventoryreport.component';
import { TestComponent } from './test/test.component';
import { CloseRegisterComponent } from './close-register/close-register.component';
import { ViewExtraReportsComponent } from './new-reportes/extra-reports/view-extra-reports/view-extra-reports.component';
import { GraphicalViewComponent } from './new-reportes/extra-reports/graphical-view/graphical-view.component';
import { SalesInvoiceComponent } from './Invoice/sales-invoice/sales-invoice.component';
import { CashierSaleInvoiceComponent } from './cashier-sale-invoice/cashier-sale-invoice/cashier-sale-invoice.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: ClientDashboardComponent,
      },
      {
        path: 'sale-invoice',
        component: CashierSaleInvoiceComponent,
      },

      // {
      //   path: 'dashboard',
      //   loadChildren: () =>
      //     import('../pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'party-page',
        loadChildren: () =>
          import('../pages/Party/party.module').then((m) => m.PartyModule),
      },
      // {
      //   path: 'employee-page',
      //   loadChildren: () =>
      //     import('../pages/Employee/employee.module').then(
      //       (m) => m.EmployeeModule
      //     ),
      // },
      // {
      //   path: 'payroll-page',
      //   loadChildren: () =>
      //     import('../pages/payroll/payroll.module').then(
      //       (m) => m.PayrollModule
      //     ),
      // },
      // {
      //   path: 'time-sheet',
      //   loadChildren: () =>
      //     import('../pages/timesheet/timesheet.module').then(
      //       (m) => m.TimesheetModule
      //     ),
      // },
      {
        path: 'new-product',
        loadChildren: () =>
          import('../pages/Product/product.module').then(
            (m) => m.ProductModule
          ),
      },

      // {
      //   path: 'expense_page',
      //   loadChildren: () =>
      //     import('../pages/expense/expense.module').then(
      //       (m) => m.ExpenseModule
      //     ),
      // },
      {
        path: 'sale_new',
        loadChildren: () =>
          import('../pages/Invoice/sale/sale.module').then((m) => m.SaleModule),
      },

      {
        path: 'purchase-page',
        loadChildren: () =>
          import('../pages/purchase/purchase.module').then(
            (m) => m.PurchaseModule
          ),
      },

      {
        path: 'Bank-page',
        loadChildren: () =>
          import('../pages/bank-module/bank-module.module').then(
            (m) => m.BankModuleModule
          ),
      },
      {
        path: 'auditing',
        loadChildren: () =>
          import('../pages/auditing/auditing.module').then(
            (m) => m.AuditingModule
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('../pages/new-reportes/new-reportes.module').then(
            (m) => m.NewReportesModule
          ),
      },
      {
        path: 'Bid-page',
        loadChildren: () =>
          import('../pages/bid-module/bid-module.module').then(
            (m) => m.BidModuleModule
          ),
      },
      {
        path: 'reportgst',
        loadChildren: () =>
          import('../pages/gst-allreports/gst-allreports.module').then(
            (m) => m.GstAllreportsModule
          ),
      },
      {
        path: 'Contact-page-new',
        loadChildren: () =>
          import('../pages/contact-new-module/contact-new-module.module').then(
            (m) => m.ContactNewModuleModule
          ),
      },
      {
        path: 'contactlist-page',
        loadChildren: () =>
          import('../pages/contactlist-module/contactlist-module.module').then(
            (m) => m.ContactlistModuleModule
          ),
      },
      // {
      //   path: 'shop-schedule-page',
      //   loadChildren: () =>
      //     import('../pages/new-shop-schedule/new-shop-schedule.module').then(
      //       (m) => m.NewShopScheduleModule
      //     ),
      // },
      // {
      //   path: 'Admin-page',
      //   loadChildren: () =>
      //     import('../pages/admin-new-module/admin-new-module.module').then(
      //       (m) => m.AdminNewModuleModule
      //     ),
      // },
      // {
      //   path: 'remote-shop-page',
      //   loadChildren: () =>
      //     import(
      //       '../pages/new-remote-shopping/new-remote-shopping.module'
      //     ).then((m) => m.NewRemoteShoppingModule),
      // },
      {
        path: 'help-faq-page',
        loadChildren: () =>
          import('../pages/help-an-faq-module/help-an-faq-module.module').then(
            (m) => m.HelpAnFaqModuleModule
          ),
      },

      {
        path: 'settings',
        loadChildren: () =>
          import('../pages/product-setting/product-setting.module').then(
            (m) => m.ProductSettingModule
          ),
      },
      // {
      //   path: 'sales-invoice/:id',
      //   component: SaleNewInvoiceComponent,
      // },
      // {
      //   path: 'crm',
      //   loadChildren: () =>
      //     import('../pages/crmmodule/crmmodule.module').then(
      //       (m) => m.CrmmoduleModule
      //     ),
      // },
      // {
      //   path: 'campaign-page',
      //   loadChildren: () =>
      //     import('../pages/campaign/campaign.module').then(
      //       (m) => m.CampaignModule
      //     ),
      // },

      // {
      //   path: "Setting-page",
      //   loadChildren: () => import('../pages/setting-module/setting-module.module').then(m => m.SettingModuleModule),
      // },
      // {
      //   path: 'ledger-page',
      //   loadChildren: () =>
      //     import('../pages/ledger/ledger.module').then((m) => m.LedgerModule),
      // },
      // {
      //   path: 'books-of-account',
      //   loadChildren: () =>
      //     import('../pages/books-of-account/books-of-account.module').then(
      //       (m) => m.BooksOfAccountModule
      //     ),
      // },
      // {
      //   path: 'new-assets',
      //   loadChildren: () =>
      //     import('../pages/assets-module/assets-module.module').then(
      //       (m) => m.AssetsModuleModule
      //     ),
      // },
      {
        path: 'organization-structure',
        loadChildren: () =>
          import(
            '../pages/organizational-structure/organizational-structure.module'
          ).then((m) => m.OrganizationalStructureModule),
      },
      // {
      //   path: 'prmotions',
      //   loadChildren: () =>
      //     import('../pages/promotions/promotions.module').then(
      //       (m) => m.PromotionsModule
      //     ),
      // },
      {
        path: 'warehouse',
        loadChildren: () =>
          import('../pages/warehouse/warehouse.module').then(
            (m) => m.WarehouseModule
          ),
      },
      // {
      //   path: "projects",
      //   loadChildren: () => import('../pages/projects/projects.module').then(m => m.ProjectsModule),
      // },
      {
        path: 'HRMS',
        loadChildren: () =>
          import('../pages/hrms/hrms.module').then((m) => m.HRMSModule),
      },
      {
        path: 'marketing',
        loadChildren: () =>
          import('../pages/marketing/marketing.module').then(
            (m) => m.MarketingModule
          ),
      },
      {
        path: 'accounting',
        loadChildren: () =>
          import('../pages/accounting/accounting.module').then(
            (m) => m.AccountingModule
          ),
      },
      {
        path: 'shopping',
        loadChildren: () =>
          import('../pages/shopping/shopping.module').then(
            (m) => m.ShoppingModule
          ),
      },
      // {
      //   path: "chat",
      //   loadChildren: () => import('../pages/chat/chat.module').then(m => m.ChatModule),
      // },
      // ,{
      //   path:"expense",
      //   loadChildren:()=>import('../pages/expense/expense-module/expense-module.module').then(m=>m.ExpenseModuleModule)

      // },
      // {
      //   path: 'auth',
      //   loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
      // },
      // {
      //   path: "chats",
      //   component: ChatsComponent
      // },

      // {
      //   path: "customer-list",
      //   component: CustomerListComponent
      // },
      // {
      //   path: "customer-details",
      //   component: CustomerDetailsComponent
      // },
      // Profile Components
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'profile-settings',
        component: ProfileSettingsComponent,
      },
      {
        path: 'add-new-trade',
        component: AddNewTradeComponent,
      },
      // Product Components
      // {
      //   path: "product-grid",
      //   component: ProductGridComponent
      // },

      {
        path: 'contact-us',
        component: ContactUsComponent,
      },      
      {
        path: 'ticket-list',
        component: ContactListTicketComponent,
      },
      {
        path: 'upi-pay',
        component: UpiPaymentComponent,
      },
      {
        path: 'cash-in-hand',
        component: CashInHandComponent,
      },
      {
        path: 'close-register',
        component: CloseRegisterComponent,
      },
      {
        path: 'invoice-number',
        component: InvoiceNumberComponent,
      },
      {
        path: 'invoice-number/:id',
        component: InvoiceNumberComponent,
      },
      {
        path: 'gst-report',
        component: GstReportComponent,
      },
      {
        path: 'gst-report2',
        component: GstReport2Component,
      },
      {
        path: 'gst-report3-b',
        component: GstReport3BComponent,
      },
      {
        path: 'gst-report4',
        component: GstReport4Component,
      },
      {
        path: 'gst-report5',
        component: GstReport5Component,
      },
      {
        path: 'gst-report8',
        component: GstReport8Component,
      },
      {
        path: 'gst-report2-a',
        component: GstReport2AComponent,
      },
      {
        path: 'gst-report9',
        component: GstReport9Component,
      },
      {
        path: 'sale-summary',
        component: SaleSummaryComponent,
      },
      {
        path: 'gst-tax-report',
        component: GstTaxReportComponent,
      },
      {
        path: 'gst-tax-rate-report',
        component: GstTaxRateReportComponent,
      },
      {
        path: 'gst-form-no',
        component: GstFormNoComponent,
      },
      {
        path: 'tcs-receivable',
        component: TcsReceivableComponent,
      },

      // Role
      {
        path: 'edit-role/:id',
        component: EditPermissionsComponent,
      },
      {
        path: 'add-role',
        component: AddCustomRolesComponent,
      },
      {
        path: 'roles-list',
        component: RolesListComponent,
      },


      {
        path: 'party-statement',
        component: PartyStatementComponent,
      },
      {
        path: 'party-wise-profit-loss',
        component: PartyWiseProfitLossComponent,
      },
      {
        path: 'all-parties',
        component: AllPartiesComponent,
      },
      {
        path: 'party-report-by-item',
        component: PartyReportByItemComponent,
      },
      {
        path: 'sale-purchase-by-party',
        component: SalePurchaseByPartyComponent,
      },
      {
        path: 'sale-purchase-by-party-group',
        component: SalePurchaseByPartyGroupComponent,
      },
      {
        path:'end-day-reports',
        component:DailyReportPageComponent,
      },
      // stock reports
      {
        path: 'item-batch-report',
        component: ItemBatchReportComponent,
      },
      {
        path: 'item-detail',
        component: ItemDetailComponent,
      },
      {
        path: 'item-report-by-party',
        component: ItemReportByPartyComponent,
      },
      {
        path: 'item-wise-discount',
        component: ItemWiseDiscountComponent,
      },
      {
        path: 'item-serial-report',
        component: ItemSerialReportComponent,
      },
      {
        path: 'item-wise-profit-and-loss',
        component: ItemWiseProfitAndLossComponent,
      },
      {
        path: 'stock-detail',
        component: StockDetailComponent,
      },
      {
        path: 'stock-summary',
        component: StockSummaryComponent,
      },
      {
        path: 'low-stock-summary',
        component: LowStockSummaryComponent,
      },
      {
        path: 'stock-detail',
        component: StockDetailComponent,
      },
      {
        path: 'stock-summary',
        component: StockSummaryComponent,
      },


      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },

  {
    path: 'view-gst-all',
    loadChildren: () =>
      import('../pages/gst-allreports/gst-allreports.module').then(
        (m) => m.GstAllreportsModule
      ),
  },

  // Customer path
  {
    path: 'customer',
    component: LayoutCustomerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'customer-dashboard',
        component: CustomerDashboardComponent,
      },
      {
        path: 'remote-customer',
        component: RemoteCustomerComponent,
      },
      {
        path: 'customer-appointment',
        component: CustomerAppointmentComponent,
      },
      {
        path: 'book-appointment/:id',
        component: BookAppointmentComponent,
      },
      {
        path: 'book-appointment-time',
        component: BookAppointmentTimeComponent,
      },
      {
        path: 'book-appointment-shop',
        component: BookAppointmentShopComponent,
      },
      {
        path: 'customer-profile',
        component: CustomerProfileComponent,
      },
      {
        path: 'customer-profile-setting',
        component: CustomerProfileSettingComponent,
      },
      {
        path: 'viewProduct/:id',
        component: ViewProductComponent,
      },
      {
        path: 'product-details/:id',
        component: ProductDetailsComponent,
      },
      {
        path: 'order-receipts',
        component: OrderReceiptsComponent,
      },
      {
        path: 'my-cart',
        component: RemoteCartComponent,
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
      },
      {
        path: 'bidding',
        component: BiddingComponent,
      },
      {
        path: 'bidding-details/:id',
        component: BiddingDetailsComponent,
      },
      {
        path: 'place-order/:id',
        component: PlaceOrderComponent,
      },
      {
        path: 'PaymentInfo',
        component: PayMentInfoComponent,
      },
    ],
  },

  // Warehouse Personel Login path
  {
    path: 'warehouse-landing',
    component: WarehouseLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: WarehouseDashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'product',
        loadChildren: () =>
          import('../pages/Product/product.module').then(
            (m) => m.ProductModule
          ),
      },
      {
        path: 'expense',
        loadChildren: () =>
          import('../pages/expense/expense.module').then(
            (m) => m.ExpenseModule
          ),
      },
      {
        path: 'sale',
        loadChildren: () =>
          import('../pages/Invoice/sale/sale.module').then((m) => m.SaleModule),
      },
      {
        path: 'purchase',
        loadChildren: () =>
          import('../pages/purchase/purchase.module').then(
            (m) => m.PurchaseModule
          ),
      },
      {
        path: 'packages',
        loadChildren: () =>
          import('../pages/warehouse/components/packages/packages.module').then(
            (m) => m.PackagesModule
          ),
      },
      {
        path: 'shipping',
        loadChildren: () =>
          import('../pages/warehouse/components/shipping/shipping.module').then(
            (m) => m.ShippingModule
          ),
      },
      // {
      //   path: "transfer",
      //   loadChildren: () => import('../pages/warehouse/components/transfer/transfer.module').then(m => m.TransferModule),
      // },
      {
        path: 'warehouse-profile-setting',
        loadChildren: () =>
          import(
            '../pages/warehouse/components/warehouse-settings/warehouse-settings.module'
          ).then((m) => m.WarehouseSettingsModule),
      },
      {
        path: 'warehouse-profile',
        component: WarehouseProfileComponent,
      },
      { path: 'warehouse-landing', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },

  {
    path: 'shop-landing',
    loadChildren: () =>
      import('../pages/shop-module/shop-module.module').then(
        (m) => m.ShopModuleModule
      ),
  },
  // Shop Personel Login path
  // {
  //   path: 'shop-landing',
  //   component: ShopLayoutComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: "dashboard",
  //       component: WarehouseDashboardComponent,
  //       canActivate: [AuthGuard],
  //     },
  //     {
  //       path: "expense",
  //       loadChildren: () => import('../pages/expense/expense.module').then(m => m.ExpenseModule),
  //     },
  //     {
  //       path: "sale",
  //       loadChildren: () => import('../pages/Invoice/sale/sale.module').then(m => m.SaleModule),
  //     },
  //     {
  //       path: "purchase",
  //       loadChildren: () => import('../pages/purchase/purchase.module').then(m => m.PurchaseModule),
  //     },
  //     {
  //       path: "packages",
  //       loadChildren: () => import('../pages/warehouse/components/packages/packages.module').then(m => m.PackagesModule),
  //     },
  //     {
  //       path: "shipping",
  //       loadChildren: () => import('../pages/warehouse/components/shipping/shipping.module').then(m => m.ShippingModule),
  //     },
  //     {
  //       path: "transfer",
  //       loadChildren: () => import('../pages/warehouse/components/transfer/transfer.module').then(m => m.TransferModule),
  //     },
  //     { path: "shop-landing", redirectTo: "dashboard", pathMatch: "full" },
  //   ]
  // },

  {
    path: 'employee-work',
    component: LayoutEmployeeComponent,
    canActivate: [AuthGuard],
    children: [
      // {
      //   path: 'employee-dashboard',
      //   component: EmployeedashboardComponent,
      // },
      {
        path: 'Profile-employee',
        loadChildren: () =>
          import('../pages/employeeprofile/employeeprofile.module').then(
            (m) => m.EmployeeprofileModule
          ),
      },
      {
        path: 'expense-employee',
        loadChildren: () =>
          import('../pages/employeeexpence/employeeexpence.module').then(
            (m) => m.EmployeeexpenceModule
          ),
      },
      {
        path: 'payroll-employee',
        loadChildren: () =>
          import('../pages/employeepayroll/employeepayroll.module').then(
            (m) => m.EmployeepayrollModule
          ),
      },
      {
        path: 'leave-employee',
        loadChildren: () =>
          import('../pages/employee-leave/employee-leave.module').then(
            (m) => m.EmployeeLeaveModule
          ),
      },
      {
        path: 'employee-attendence',
        loadChildren: () =>
          import(
            '../pages/employee-attendence/employee-attendence.module'
          ).then((m) => m.EmployeeAttendenceModule),
      },
      {
        path: 'employee-approval',
        loadChildren: () =>
          import(
            '../pages/employee-approval/employee-approval.module'
          ).then((m) => m.EmployeeApprovalModule),
      },

      { path: "employee-work", redirectTo: "Profile-employee", pathMatch: "full" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  // imports: [
  //   RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
  //   RouterModule.forRoot(appRoutes, { useHash: true, relativeLinkResolution: 'legacy' })
  // ],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
