import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss']
})
export class RolesListComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public roleData: any;
  public roleId: string="";
  public Id: any;
  showDeleteMessage: boolean=false;
  constructor(public rolePermissionservice: RolesPermissionsService,
    private titleCasePipe: TitleCasePipe,

    private toastService: ToastNotificationService) { }

  ngOnInit(): void {
    this.getRole();
  }
  getRole() {
    
    this.rolePermissionservice.getRoles({},this.currentUser.id).then(data => {
      this.roleData = data.data;
     this.roleId=data.data.id;
    },(err) => {
        if(err.error.expose){
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message)
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else{
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong."
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
        
      })
  }

  deleteRole(){
    this.rolePermissionservice.deleteRoleById({},this.Id).then(data=>{
      // this.toastService.openSnackBar("Role Deleted Successfully!!!");
      this.toastService.toastMsg({
        title: "Success",
        content: "Role Deleted Successfully!",
      })
      this.getRole();
     },(err) => {
        if(err.error.expose){
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message)
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else{
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong."
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
        
      })
  } 
  getRoleID(id){
    this.Id= id
  }
}
