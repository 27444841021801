<!-- <div class="modal-header row" id="Add-Party-modal">
    <div class="col-lg-6 col-sm-12 col-md-5">
        <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title">Add New Schedule</h1>
    </div>
    <div *ngIf="!isConfirmBtn" class="col-lg-6">
        <div class="row">
            <div class="col-lg-4"></div>
            <div class="col-lg-3 col-sm-12 col-md-3 mb-2">
                <button type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
            </div>

            <div class="col-lg-5 col-sm-12 col-md-4 mb-2">
                <button type="button" class="btn btn-save" (click)="postTimeSlots()">Save & Next</button>
            </div>
        </div>
    </div>
    <div *ngIf="isConfirmBtn" class="col-lg-6">
        <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-3 col-sm-12 col-md-3 mb-2">
                <button type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
            </div>

            <div class="col-lg-3 col-sm-12 col-md-3 mb-2">
                <button type="button" class="btn btn-save" (click)="postCreateSchedule()">Save</button>
            </div>
        </div>
    </div>

</div> -->

<div class="modal-header" id="Add-Party-modal">

    <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title mb-0">Add New Schedule</h1>

    <div *ngIf="!isConfirmBtn" class="addScheduleButtons">

        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-save" (click)="postTimeSlots()">Save & Next</button>

    </div>

    <div *ngIf="isConfirmBtn" class="addScheduleButtons">

        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-save" (click)="postCreateSchedule()">Save</button>

    </div>

</div>
<div class="modal-body">
    <form [formGroup]="scheduleForm" *ngIf="!isConfirmBtn">
        <div class="row mt-3">
            <div class="col-sm-12 col-lg-12 col-md-12">
                <label class="form-label">Holiday</label>
            </div>
        </div>
        <div class="row d-flex mb-1 mt-3">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div name="ingredients" class="week-btn-group" style="margin-left:5px;" aria-label="Ingredients"
                    multiple>
                    <mat-button-toggle [ngClass]="{ 'mat-button-toggle-checked': selectedDays.includes('0') }"
                        (click)="getHoliday('0')" style="font-family: 'Poppins';font-size:14px;">Mon</mat-button-toggle>
                    <mat-button-toggle [ngClass]="{ 'mat-button-toggle-checked': selectedDays.includes('1') }"
                        (click)="getHoliday('1')" style="font-family: 'Poppins';font-size:14px;">Tue</mat-button-toggle>
                    <mat-button-toggle [ngClass]="{ 'mat-button-toggle-checked': selectedDays.includes('2') }"
                        (click)="getHoliday('2')" style="font-family: 'Poppins';font-size:14px;">Wed</mat-button-toggle>
                    <mat-button-toggle [ngClass]="{ 'mat-button-toggle-checked': selectedDays.includes('3') }"
                        (click)="getHoliday('3')" style="font-family: 'Poppins';font-size:14px;">Thu</mat-button-toggle>
                    <mat-button-toggle [ngClass]="{ 'mat-button-toggle-checked': selectedDays.includes('4') }"
                        (click)="getHoliday('4')" style="font-family: 'Poppins';font-size:14px;">Fri</mat-button-toggle>
                    <mat-button-toggle [ngClass]="{ 'mat-button-toggle-checked': selectedDays.includes('5') }"
                        (click)="getHoliday('5')" style="font-family: 'Poppins';font-size:14px;">Sat</mat-button-toggle>
                    <mat-button-toggle [ngClass]="{ 'mat-button-toggle-checked': selectedDays.includes('6') }"
                        (click)="getHoliday('6')" style="font-family: 'Poppins';font-size:14px;">Sun</mat-button-toggle>

                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12 col-lg-12 col-md-12">
                <label class="form-label">Shop Location</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select formControlName="shopSelected"
                        [ngClass]="{ 'is-invalid' : f.shopSelected.invalid && f.shopSelected.touched && submitted }"
                        (selectionChange)="onShopSelected($event)" placeholder="Shop Location">
                        <mat-option *ngFor="let shop of shopListWithShipId" [value]="shop.shopId">
                            {{shop.tradeName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12 col-lg-12 col-md-12">
                <label class="form-label">Appointment Type<span class="error-asterisk">*</span> </label>
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-select formControlName="appointmentType" placeholder="Appointment Type" required
                        [ngClass]="{ 'is-invalid' : f.appointmentType.invalid && f.appointmentType.touched && submitted }">
                        <mat-option *ngFor="let appointmentList of appointmentData" [value]="appointmentList.id">
                            {{appointmentList.name | titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger mb-1"
                    *ngIf="(submitted || f.appointmentType.touched) && f.appointmentType.errors?.required">
                    Appointment Type Is Required.
                </mat-error>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12 mb-2">
                <label class="form-label">Shop Timing<span class="required">*</span></label>
            </div>
            <div class="col-sm-12 col-lg-6 col-md-6 mb-3">
                <mat-label style="margin-left: 5px;">Start Time<span class="error-asterisk">*</span> </mat-label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="time" matInput placeholder="Start Time" formControlName="start_time"
                        [ngClass]="{ 'is-invalid': f.start_time.invalid && f.start_time.touched && submitted }"
                        [value]="scheduleForm.controls.start_time.value || null">
                </mat-form-field>
                <mat-error class="text-danger mb-1"
                    *ngIf="(submitted || f.start_time.touched) && f.start_time.errors?.required">
                    Shop Start Timing Is Required.
                </mat-error>
            </div>
            <div class="col-sm-12 col-lg-6 col-md-6 mb-3">
                <mat-label style="margin-left: 5px;">End Time<span class="error-asterisk">*</span> </mat-label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="time" matInput placeholder="End Time" formControlName="end_time"
                        [ngClass]="{ 'is-invalid': f.end_time.invalid && f.end_time.touched && submitted }"
                        [value]="scheduleForm.controls.end_time.value || null">
                </mat-form-field>
                <mat-error class="text-danger mb-1"
                    *ngIf="(submitted || f.end_time.touched) && f.end_time.errors?.required">
                    Shop End Timing Is Required.
                </mat-error>
            </div>
        </div>


        <div class="row">
            <div class="col-12 mb-2">
                <label class="form-label">Break Timing</label>
            </div>
            <div class="col-sm-12 col-lg-6 col-md-6 mb-3">
                <mat-label style="margin-left: 5px;">Start Time<span class="error-asterisk">*</span> </mat-label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="time" matInput placeholder="Start Time" formControlName="break_start_time" [ngClass]="{
                        'is-invalid':
                          f.break_start_time.invalid && f.break_start_time.touched && submitted
                      }">
                </mat-form-field>
                <mat-error class="text-danger mb-1"
                    *ngIf="(submitted || f.break_start_time.touched) && f.break_start_time.errors?.required">
                    Break Start Timing Is Required.
                </mat-error>
            </div>
            <div class="col-sm-12 col-lg-6 col-md-6 mb-3">
                <mat-label style="margin-left: 5px;">End Time<span class="error-asterisk">*</span> </mat-label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="time" matInput placeholder="End Time" formControlName="break_end_time" [ngClass]="{
                        'is-invalid':
                          f.break_end_time.invalid && f.break_end_time.touched && submitted
                      }">
                </mat-form-field>
                <mat-error class="text-danger mb-1" *ngIf="
                (submitted || f.break_end_time.touched) &&
                f.break_end_time.errors?.required
              ">
                    Break End Timing Is Required.
                </mat-error>
            </div>
        </div>


        <div class="row mb-3">
            <div class="col-sm-12">
                <label class="form-label">Duration (in Minutes)<span class="error-asterisk">*</span> </label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="number" matInput placeholder="Slot Duration" formControlName="slotDuration" min="0"
                        oninput="this.value = Math.abs(this.value)" [ngClass]="{
                        'is-invalid':
                            f.slotDuration.invalid && f.slotDuration.touched && submitted
                        }">
                </mat-form-field>
                <mat-error class="text-danger mb-1"
                    *ngIf="(submitted || f.slotDuration.touched) && f.slotDuration.errors?.required">
                    Slot Duration Is Required.
                </mat-error>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-sm-12">
                <label class="form-label">Capacity (in Number)<span class="error-asterisk">*</span> </label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="number" matInput placeholder="Capacity" formControlName="capacity" min="0"
                        oninput="this.value = Math.abs(this.value)" [ngClass]="{
                    'is-invalid':
                        f.capacity.invalid && f.capacity.touched && submitted
                    }">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.capacity.touched) && f.capacity.errors?.required">
                    Capacity Is Required.
                </mat-error>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-sm-12">
                <label class="form-label">Buffer Time (in Minutes)<span class="error-asterisk">*</span> </label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="number" matInput placeholder="Buffer Time" formControlName="bufferTime" min="0"
                        oninput="this.value = Math.abs(this.value)" [ngClass]="{
                        'is-invalid':
                            f.bufferTime.invalid && f.bufferTime.touched && submitted
                        }">
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.bufferTime.touched) && f.bufferTime.errors?.required">
                    Buffer Time Duration Is Required.
                </mat-error>
            </div>
        </div>
        <!-- <div>
            <button class="btn btn-primary" (click)="postTimeSlots()">Submit</button>
        </div> -->
    </form>
    <div class="row" *ngIf="isConfirmBtn">
        <div class="col-12">
            <div class="row" *ngFor="let day of days">
                <div class="col-2 m-auto text-center">
                    {{ day }}
                </div>
                <div class="col-10">
                    <div class="cards-wrapper" *ngIf="!dayWiseSlots[day].dayDetails.holiday">
                        <div class="card" *ngFor="let i of dayWiseSlots[day].slotDetails">
                            Start Slot - {{i.startSlot}}<br>
                            End Slot - {{i.endSlot}} <br>
                            Capacity - {{i.capacity}}<br>
                            <!-- <h4>Slot No - {{i.slotNo}}</h4> -->
                        </div>
                    </div>
                    <div class="cards-wrapper" *ngIf="dayWiseSlots[day].dayDetails.holiday">
                        <div class="card">
                            <h4>Holiday</h4>
                        </div>
                    </div>
                </div>
                <hr class="mt-2">
            </div>
        </div>
    </div>
    <!-- <div class="justify-content-end">
        <button class="btn btn-outline-primary" (click)="postCreateSchedule()" *ngIf="isConfirmBtn" type="button">
            Confirm
        </button>
    </div> -->

</div>