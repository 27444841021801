import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventListener } from 'ngx-bootstrap/utils/facade/browser';
import { CommonService } from 'src/app/core/services/common.service';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import lang from "src/assets/langTranslation/language_translation"
import { WarehouseService } from 'src/app/core/services/warehouse.service';
import moment from 'moment';
@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent implements OnInit {
  public lang = lang.UAE;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public unitValue: any;
  public unitSecondaryValue: any;
  public isDisabled: boolean = false;
  public showProductType: any;
  public serviceToggle: boolean;
  public isStockToggle: boolean = false;
  public ProductForm: any;
  public todaysDate: any;
  public todaysDate2: any;
  public today: Date;
  public unit: any;
  public primaryUnitShort: any;
  public secondaryUnitShort: any;
  public secondaryunit: any;
  public maxDate: Date;
  // Units = Constants.Units
  public Units = Constants.UnitFS
  public SecondaryUnit = Constants.Units
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrc: any = '';
  public imageSrcOne: any = '';
  public imageSrcTwo: any = '';
  public imageSrcThree: any = '';
  public imageSrcFour: any = '';
  public imageSrcFive: any = '';
  public imageSrcSix: any = '';
  public imageSrcSeven: any = '';
  public imgNumber: any;
  public selectedFiles: any;
  public selectedFileName: any;
  public submitted: boolean = false;
  public productCategory: any;
  public productPicUrl: string = '';
  public productPicUrl1: any = '';
  public productPicUrl2: any = '';
  public productPicUrl3: any = '';
  public productPicUrl4: any = '';
  public productPicUrl5: any = '';
  public productPicUrl6: any = '';
  public currentInput: any;
  myFiles: File[] = [];
  public multipleImage = [];
  public multipleImage1 = [];
  previews: string[] = [];
  public catId: number;
  public primarySelectUnit: any;
  public secondarySelectUnit: any;
  public warrantyType: string="Days";
  public maxFiles: number = 0;
  public Filesmax: number = 5;
  subCategoryList: any;
  activeSubCategoryList: any;
  BrandId: any;
  ProductBrand: any;
  brandId: any;
  subCategoryId: any;
  customFieldsList: any[];
  warehouseList: any;
  warrantyTypeControl = new FormControl('Days');
  warehouseId: any;
  dropdownValue = [];

  constructor(private productService: ProductService,
    private formBuilder: FormBuilder,
    private titleCasePipe: TitleCasePipe,
    private router: Router,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProductAddComponent>,
    @Inject(MAT_DIALOG_DATA) public ProductData: any,
    private toastService: ToastNotificationService,
    private warehouseService: WarehouseService,
    private commonService: CommonService) {
      this.today = new Date();
      this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);

      // this.productId = this.ProductData.productId;
      this.warehouseId = this.ProductData.warehouseId;
  }

  async ngOnInit(): Promise<void> {
    this.todaysDate = new Date(new Date().setDate(new Date().getDate()));
    this.todaysDate2 = new Date(new Date().setDate(new Date().getDate() + 1));
    // Initialize the form with placeholder values or empty if needed.
    this.ProductForm = this.formBuilder.group({
      itemName: ["",],
      category: ["", Validators.required],
      itemCode: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8), Validators.pattern('^[a-zA-Z0-9]*$')]],
      generateBarcode: ["", [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern("^[0-9]*$")]],
      description: [null],
      hsn: [null],
      unit: [null],
      price: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
      gstRate: ["5"],
      secondaryUnitFlag: [false],
      lowStockWarning: [false],
      secondaryUnit: [null],
      lowStockUnit: [null],
      conversionRate: [null],
      openingStock: [],
      asOfDate: [null],
      additionalCess: [null, [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
      serviceCode: [null],
      serviceName: [null],
      supportType: ["GUARANTEE"],
      supportDuration: [null],
      expiry: [null],
      warehouse: [null],
      pricingDetails: new FormArray([this.createPricingDetails()]),
      brand: [null],
      section: [null],
      subCategoryId: [null],
      // gender: [null],
      color: [null],
      gender: [null],
      ageGroup: [null],
      promoPrice: [null],  // type: double
      promoStartDate: [null],
      promoEndDate: [null],
    });
    try {
      // Fetch necessary data using async/await
      await Promise.all([
        this.getProductCategory(),
        this.getProductBrand(),
        this.getWarehouseList(),
      ]);
      // Patch the form values after fetching data
      this.ProductForm.patchValue({
        additionalCess: 0,
        openingStock: 0
      })

      // Set the form control value when warehouseId is available
      if (this.warehouseId) {
        this.ProductForm.get('warehouse')?.setValue(this.warehouseId);
      }
      await this.getCustomFieldDetails()
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error if needed
    }
  }

  updateAgeGroupValidators() {
    const gender = this.ProductForm.get('gender').value.toLowerCase(); // Ensure lowercase for comparison
    const ageGroupControl = this.ProductForm.get('ageGroup');

    if (gender === 'men') {
      ageGroupControl.setValidators([ Validators.min(18), Validators.max(100)]);
    } else if (gender === 'women') {
      ageGroupControl.setValidators([ Validators.min(16), Validators.max(100)]);
    } else if (gender === 'boy') {
      ageGroupControl.setValidators([ Validators.min(0), Validators.max(18)]);
    } else if (gender === 'girl') {
      ageGroupControl.setValidators([ Validators.min(0), Validators.max(16)]);
    } else if (gender === 'unisex') { // Use lowercase "unisex"
      ageGroupControl.setValidators([ Validators.min(18), Validators.max(100)]); // Define the age range for "Unisex" as per your requirement
    } else {
      ageGroupControl.setValidators(null); // Clear validators if no gender is selected
    }

    ageGroupControl.updateValueAndValidity();
  }

  onGenderChange(event) {
    this.updateAgeGroupValidators();
  }
  
  generateCustomFields(): void {
    if (!this.customFieldsList || !Array.isArray(this.customFieldsList)) {
      console.error('Custom fields list is not properly initialized or is not an array.');
      return;
    }

    // Loop through customFieldsList and create form controls dynamically
    this.customFieldsList.forEach(field => {
      if (field.type === 'TEXT') {
        // For text fields
        this.ProductForm.addControl(field.fieldName, this.formBuilder.control(null));
        } else if (field.type === 'DROPDOWN' && field.productCustomFieldOptions) {
        // For dropdown fields
        const dropdownField = this.generateDropdownField(field);
        // Add the FormArray control for dropdown options
        this.ProductForm.addControl(field.fieldName, dropdownField);
      }
    });
  }

  // function to handle dropdown control creation
  generateDropdownField(field: any): FormArray {
    return this.formBuilder.array(
      field.productCustomFieldOptions.map(option =>
        this.formBuilder.control({
          optionName: option.optionName,
          optionId: option.id
        })
      )
    );
  }

  getCustomFieldDetails(){
    this.productService.getCustomFields({
      data:{},
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
      isActive: ''
    }).then(async (res) => {
      // Filter and assign active custom fields to customFieldsList
      if (res.data) {
        this.customFieldsList = res.data.pageData.filter(field => field.isActive);
        // Call the function once data is assigned
        await this.generateCustomFields();
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      }
    })
  }

  getProductCategory() {
    this.productService.getCategory({
      data:{},
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
      isActive: true
    }).then((res: any) => {
      if(res.success)
        this.productCategory = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      }
    })
  }

  getProductBrand() {
    this.productService.getBrandList({
      data:{},
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
      isActive: true
    }).then((res: any) => {
      this.ProductBrand = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  getWarehouseList() {
    this.warehouseService.getAllWarehouse({
      data:{},
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
    }).then((res: any) => {
      this.warehouseList = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  getProductSubCategory() {
    this.productService.getSubCategory({
      data:{},
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
      categoryId: this.ProductForm.controls.category.value,
      isActive: ''
    }).then((res: any) => {
      if (res.success) {
        this.subCategoryList = res.data.pageData;
        this.activeSubCategoryList = this.subCategoryList ?.filter(subcategory => subcategory.isActive === true);
      }
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
  }

  selectWarrantyType(event) {
    this.warrantyType = event.value
  }

  get f() {
    return this.ProductForm.controls;
  }

  getPrimaryUnit(event) {
    this.primaryUnitShort = event.source.value.shortName;
    this.unit = event.source.value.fullName;
    this.unitValue = "1 " + event.source.value.fullName;
    const inputString = this.unit;
    const startIndex = 0;
    const endIndex = inputString.indexOf("(");
    if (endIndex !== -1) {
      const result = inputString.substring(startIndex, endIndex);
      this.primarySelectUnit = result;
    } else {
    }
    this.filterSecondary();
  }

  private filterSecondary() {
    const selectedPrimary = this.SecondaryUnit.filter(us => this.unit === us);
    const index = this.SecondaryUnit.indexOf(this.unit);
    if (index > -1) {
      this.SecondaryUnit.splice(index, 1);
    }
  }

  showSecondaryUnitField(checked: boolean) {
    this.isDisabled = checked;
    if (checked) {
      this.ProductForm.controls['secondaryUnit'].setValidators([Validators.required]);
      this.ProductForm.controls['conversionRate'].setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.ProductForm.controls['secondaryUnit'].clearValidators();
      this.ProductForm.controls['conversionRate'].clearValidators();
    }
    this.ProductForm.controls['secondaryUnit'].updateValueAndValidity();
    this.ProductForm.controls['conversionRate'].updateValueAndValidity();
  }

  getEnableStockValue(checked: boolean) {
    this.isStockToggle = checked;
    if (checked) {
      this.ProductForm.controls['lowStockUnit'].setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.ProductForm.controls['lowStockUnit'].clearValidators();
    }
    this.ProductForm.controls['lowStockUnit'].updateValueAndValidity();
  }

  getSecondaryUnit(event) {
    this.secondaryUnitShort = event.source.value.shortName;
    this.unitSecondaryValue = event.source.value;
    const regex = /\(([^)]+)\)/;
    const match = this.unitSecondaryValue.match(regex);
    if (match) {
      const result1 = match[1]; // The matched text inside the parentheses
      this.secondarySelectUnit = result1;
    } 
  }

  show(id) {
    if (id === 5) {
      this.showProductType = Constants.USER.GOODS;
      this.serviceToggle = false;
    }
    else if (id === 6) {
      this.showProductType = Constants.USER.SERVICE;
      this.serviceToggle = true;
    }
  }

  getCategoryType(event) {
    this.catId = event.source.value;
    this.getProductSubCategory()
  }

  addService() {
    let data = {
      "userId": this.currentUser.id,
      "itemName": this.ProductForm.controls.serviceName.value,
      "categoryId": this.ProductForm.controls.category.value,
      "itemCode": this.ProductForm.controls.serviceCode.value,
      "barcode": this.ProductForm.controls.serviceCode.value,
      "description": this.ProductForm.controls.description.value,
      "hsn": this.ProductForm.controls.hsn.value ? this.ProductForm.controls.hsn.value : null,
      "salePrice": this.ProductForm.controls.price.value,
      "gstRate": this.ProductForm.controls.gstRate.value,
      "stock": this.ProductForm.controls.openingStock.value,
      "expiry": this.ProductForm.controls.expiry.value,
      "additionalCess": this.ProductForm.controls.additionalCess.value,
    }
    this.productService.postService(data).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Service Added Successfully!",
        });
      }
      this.router.navigate(["/pages/product-list"])
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }

  saveAndNewService() {
    let data = {
      "userId": this.currentUser.id,
      "itemName": this.ProductForm.controls.serviceName.value,
      "categoryId": this.ProductForm.controls.category.value,
      "itemCode": this.ProductForm.controls.serviceCode.value,
      "barcode": this.ProductForm.controls.serviceCode.value,
      "description": this.ProductForm.controls.description.value,
      "hsn": this.ProductForm.controls.hsn.value ? this.ProductForm.controls.hsn.value : null,
      "salePrice": this.ProductForm.controls.price.value,
      "gstRate": this.ProductForm.controls.gstRate.value,
      "stock": this.ProductForm.controls.openingStock.value,
      "asOfDate": this.ProductForm.controls.asOfDate.value,
      "additionalCess": this.ProductForm.controls.additionalCess.value,
    }
    this.productService.postService(data).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Service Added Successfully!",
        });
        this.submitted = false;
        this.ProductForm.reset();
        this.todaysDate = new Date();
        this.ProductForm.patchValue({
          additionalCess: 0,
          openingStock: 0
        })
        this.imageSrcOne = '';
        this.imageSrcTwo = '';
        this.imageSrcThree = '';
        this.imageSrcFour = '';
        this.imageSrcFive = '';
        this.imageSrcSix = '';
        this.imageSrcSeven = '';
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })

  }

  getDropdownOptions(field: any): any[] {
    // console.log("in get dripdown option", field)
    const formArray = this.ProductForm.get(field.fieldName) as FormArray;
    // console.log(formArray.value);
    return formArray ? formArray.value : [];
  }

  onOptionClick(field: any, option: any): void {
    // Set the selected option manually
    const formArray = this.ProductForm.get(field.fieldName) as FormArray;

    // Clear existing values in the FormArray
    // while (formArray.length) {
    //   formArray.removeAt(0);
    // }
    let obj = {
      field: field,
      value: this.formBuilder.control(option)
    }
    this.dropdownValue.push(obj)
    // formArray.push(this.formBuilder.control(option));
    // this.ProductForm.get(field.fieldName).setValue(option);
  }

  createCustomFieldData(field: any, fieldValue: any): any {
    return {
      fieldName: field?.fieldName,
      value: fieldValue,
      type: field?.type,
      productCustomFieldId: field?.id,
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
    };
  }

  addGoods() {
    console.log(this.ProductForm)
    this.submitted = true;
    if (this.ProductForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }
    else {
      if (this.catId == 2 || this.catId == 4 || this.catId == 8 || this.catId == 9 || this.catId == 10 || this.catId == 11 || this.catId == 14 || this.catId == 15) {
        let data = {
          "addProducts": {
            "userId": (this.ProductForm.controls.warehouse.value === null) ? this.currentUser.id : `${this.ProductForm.controls.warehouse.value}`,
            "itemName": this.ProductForm.controls.itemName.value,
            "itemCode": this.ProductForm.controls.itemCode.value,
            "barcode": this.ProductForm.controls.generateBarcode.value,
            "description": this.ProductForm.controls.description.value,
            // "hsn": this.ProductForm.controls.hsn.value ? this.ProductForm.controls.hsn.value : null,
            "salePrice": this.ProductForm.controls.price.value,
            "gstRate": this.ProductForm.controls.gstRate.value,
            "unit": this.unit,
            "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,
            "secondaryUnit": this.unitSecondaryValue,
            "conversionRate": this.ProductForm.controls.conversionRate.value,
            "stock": this.ProductForm.controls.openingStock.value,
            "asOfDate": this.ProductForm.controls.asOfDate.value,
            "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
            "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
            "expiryDate": this.ProductForm.controls.expiry.value ? this.ProductForm.controls.expiry.value : null,
            "additionalCess": this.ProductForm.controls.additionalCess.value,
            "supportType": this.ProductForm.controls.supportType.value,
            "supportDuration":
                this.ProductForm.controls.supportDuration.value ? this.ProductForm.controls.supportDuration.value.toString()
                + (this.warrantyType ? this.warrantyType.toString() : null) : null,
            "categoryId": this.ProductForm.controls.category.value,
            "coverImage": this.productPicUrl ?  this.productPicUrl : null,
            "brandId": this.ProductForm.controls.brand.value ? this.ProductForm.controls.brand.value.toString() : null,
            "section": this.ProductForm.controls.section.value ? this.ProductForm.controls.section.value : null,
            "subCategoryId": this.ProductForm.controls.subCategoryId.value ? this.ProductForm.controls.subCategoryId.value.toString() : null,
            "gender": this.ProductForm.controls.gender.value ? this.ProductForm.controls.gender.value : null,
            "color": this.ProductForm.controls.color.value ? this.ProductForm.controls.color.value : null,
            "ageGroup": this.ProductForm.controls.ageGroup.value ? this.ProductForm.controls.ageGroup.value : null,
            "promoPrice": this.ProductForm.controls.promoPrice.value ? this.ProductForm.controls.promoPrice.value : null,
            "promoStartDate": this.ProductForm.controls.promoStartDate.value ? moment(this.ProductForm.controls.promoStartDate.value, "DD MMM YYYY").utc().format("YYYY-MM-DD") : null,
            "promoEndDate": this.ProductForm.controls.promoEndDate.value ? moment(this.ProductForm.controls.promoEndDate.value, "DD MMM YYYY").utc().format("YYYY-MM-DD") : null,
          },
          "warehouseId": (this.ProductForm.controls.warehouse.value === null) ? this.currentUser.id : `${this.ProductForm.controls.warehouse.value}`,
          "productImages":
            this.multipleImage ? this.multipleImage : null,
          "fields": [],
          "dropdownFields": []
        }

         // Iterate over dynamically generated custom fields
         if (this.customFieldsList && this.customFieldsList.length > 0) {
          this.customFieldsList.forEach(field => {
            // console.log(field)
            // const fieldValue = this.getProductFormFieldValue(field);
            const fieldValue = this.ProductForm.controls[field.fieldName].value;
            const customFieldData = this.createCustomFieldData(field, fieldValue);
            if(field.type === 'TEXT'){
              data.fields.push(customFieldData);
            } 
          });
          // this.dropdownValue.map(item => {
          //   const customFieldData = this.createCustomFieldData(item?.field, item?.value.value);
          //   data.dropdownFields.push(customFieldData);
          // })

          // Handle dropdown values separately
          this.dropdownValue.forEach(item => {
            // Ensure value is an array if it's a dropdown
            const fieldValueArray = Array.isArray(item.value.value) ? item.value.value : [item.value.value];
            // console.log(fieldValueArray)
            // console.log(typeof(fieldValueArray))
            const customFieldData = this.createCustomFieldData(item.field, fieldValueArray);
            data.dropdownFields.push(customFieldData);
          });
        }
        if (!this.f.promoPrice.value && !(this.f.promoPrice.value > 0)) {
          delete data.addProducts.promoStartDate;
          delete data.addProducts.promoEndDate;
        }
        this.productService.postGoods(data).then((res) => {
          if (res.success) {
            this.toastService.toastMsg({
              title: "Success",
              content: "Product Added Successfully!",
            });
            this.productService.notifyProductAdded();
            this.dialogRef.close();
          }
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        })
      }
      else {
        let data = {
          "addProducts": {
            "userId": (this.ProductForm.controls.warehouse.value === null) ? this.currentUser.id : `${this.ProductForm.controls.warehouse.value}`,
            "itemName": this.ProductForm.controls.itemName.value,
            "itemCode": this.ProductForm.controls.itemCode.value,
            "barcode": this.ProductForm.controls.generateBarcode.value,
            "description": this.ProductForm.controls.description.value,
            // "hsn": this.ProductForm.controls.hsn.value ? this.ProductForm.controls.hsn.value : null,
            "salePrice": this.ProductForm.controls.price.value,
            "gstRate": this.ProductForm.controls.gstRate.value,
            "unit": this.unit,
            "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,
            "secondaryUnit": this.unitSecondaryValue,
            "conversionRate": this.ProductForm.controls.conversionRate.value,
            "stock": this.ProductForm.controls.openingStock.value,
            "asOfDate": this.ProductForm.controls.asOfDate.value,
            "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
            "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
            "additionalCess": this.ProductForm.controls.additionalCess.value,
            "supportType": this.ProductForm.controls.supportType.value,
            "supportDuration":
                this.ProductForm.controls.supportDuration.value?this.ProductForm.controls.supportDuration.value.toString()
                + (this.warrantyType?this.warrantyType.toString():null) : null ,
            "categoryId": this.ProductForm.controls.category.value,
            "coverImage": this.productPicUrl ?  this.productPicUrl : null,
            // "used": this.ProductForm.controls.used.value == "false" ? false : true,
            "brandId": this.ProductForm.controls.brand.value ? this.ProductForm.controls.brand.value.toString() : null,
            "section": this.ProductForm.controls.section.value ? this.ProductForm.controls.section.value : null,
            "subCategoryId": this.ProductForm.controls.subCategoryId.value ? this.ProductForm.controls.subCategoryId.value.toString() : null,
            "gender": this.ProductForm.controls.gender.value ? this.ProductForm.controls.gender.value : null,
            "color": this.ProductForm.controls.color.value ? this.ProductForm.controls.color.value : null,
            "ageGroup": this.ProductForm.controls.ageGroup.value ? this.ProductForm.controls.ageGroup.value : null,
            "promoPrice": this.ProductForm.controls.promoPrice.value ? this.ProductForm.controls.promoPrice.value : null,
            "promoStartDate": this.ProductForm.controls.promoStartDate.value ? moment(this.ProductForm.controls.promoStartDate.value, "DD MMM YYYY").utc().format("YYYY-MM-DD") : null,
            "promoEndDate": this.ProductForm.controls.promoEndDate.value ? moment(this.ProductForm.controls.promoEndDate.value, "DD MMM YYYY").utc().format("YYYY-MM-DD") : null,
          },
          "warehouseId": (this.ProductForm.controls.warehouse.value === null) ? this.currentUser.id : `${this.ProductForm.controls.warehouse.value}`,
          "productImages":
            this.multipleImage ? this.multipleImage : null,
          "fields": [],
          "dropdownFields": []
        }

        // Iterate over dynamically generated custom fields
        if (this.customFieldsList && this.customFieldsList.length > 0) {
          this.customFieldsList.forEach(field => {
            // console.log(field)
            // const fieldValue = this.getProductFormFieldValue(field);
            const fieldValue = this.ProductForm.controls[field.fieldName].value;
            const customFieldData = this.createCustomFieldData(field, fieldValue);
            if(field.type === 'TEXT'){
              data.fields.push(customFieldData);
            } 
          });
            // this.dropdownValue.map(item => {
            //   const customFieldData = this.createCustomFieldData(item?.field, item?.value.value);
            //   data.dropdownFields.push(customFieldData);
            // })

            // Handle dropdown values separately
          this.dropdownValue.forEach(item => {
            // Ensure value is an array if it's a dropdown
            const fieldValueArray = Array.isArray(item.value.value) ? item.value.value : [item.value.value];
            console.log(fieldValueArray)
            console.log(typeof(fieldValueArray))
            const customFieldData = this.createCustomFieldData(item.field, fieldValueArray);
            data.dropdownFields.push(customFieldData);
          });
        }
        if (!this.f.promoPrice.value && !(this.f.promoPrice.value > 0)) {
          delete data.addProducts.promoStartDate;
          delete data.addProducts.promoEndDate;
        }
        this.productService.postGoods(data).then((res) => {
          if (res.success) {
            this.toastService.toastMsg({
              title: "Success",
              content: "Product Added Successfully!",
            });
            this.productService.notifyProductAdded();
            this.dialogRef.close();
          }

        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        })
      }
    }
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  handleInputChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.ProductForm.patchValue({
          file: reader.result,
        });
        if (file === null) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload",
          });
        } else {
          if (this.imgNumber === 1) {
            this.imageSrcOne = reader.result;
            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              if(res.success) {
                this.productPicUrl1 = res.data.location;
                this.toastService.toastMsg({
                  title: "Success",
                  content: "Image uploaded successfully!",
                });
              }
            });
          } else if (this.imgNumber === 2) {
            this.imageSrcTwo = reader.result;
            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              if(res.success) {
                this.productPicUrl2 = res.data.location;
                this.toastService.toastMsg({
                  title: "Success",
                  content: "Image uploaded successfully!",
                });
              }
            });
          } else if (this.imgNumber === 3) {
            this.imageSrcThree = reader.result;
            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              if(res.success) {
                this.productPicUrl3 = res.data.location;
                this.toastService.toastMsg({
                  title: "Success",
                  content: "Image uploaded successfully!",
                });
              }
            });
          } else if (this.imgNumber === 4) {
            this.imageSrcFour = reader.result;
            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              if(res.success) {
                this.productPicUrl4 = res.data.location;
                this.toastService.toastMsg({
                  title: "Success",
                  content: "Image uploaded successfully!",
                });
              }
            });
          } else if (this.imgNumber === 5) {
            this.imageSrcFive = reader.result;
            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              if(res.success) {
                this.productPicUrl5 = res.data.location;
                this.toastService.toastMsg({
                  title: "Success",
                  content: "Image uploaded successfully!",
                });
              }
            });
          } else if (this.imgNumber === 6) {
            this.imageSrcSix = reader.result;
            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              if(res.success) {
                this.productPicUrl6 = res.data.location;
                this.toastService.toastMsg({
                  title: "Success",
                  content: "Image uploaded successfully!",
                });
              }
            });
          } else if (this.imgNumber === 7) {
            this.imageSrcSeven = reader.result;
            formdata.set("uploadProductImage", file)
            e.srcElement.value = "";
          }
        }
      }
    };
  }
  handleCoverDragEnter() {
    this.dragging = true;
  }
  handleCoverDragLeave() {
    this.dragging = false;
  }
  handleCoverImageLoad() {
    this.imageLoaded = true;
  }
  handleCoverDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.coverImgChange(e);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberDecimalOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  coverImgChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    else {
      this.loaded = false;
      if (e.target.files && e.target.files[0]) {
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.ProductForm.patchValue({
            file: reader.result,
          });

          if (file == null) {

            this.toastService.toastMsg({
              title: "Error",
              content: "Please Select Image To Upload",
            });
          } else {
            this.imageSrcSeven = reader.result;
            formdata.set("upload", file)
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              if(res.success) {
                this.productPicUrl = res.data.locations[0];
                this.toastService.toastMsg({
                  title: "Success",
                  content: "Cover Image uploaded successfully!",
                });
              }
            });
          }
        }
      }
    }
  }


  // Clear the inouts in the form.
  clearInput() {
    this.dialogRef.close();
    this.ProductForm.reset();
    this.isDisabled = false;
    this.isStockToggle = false;
    this.todaysDate = new Date();
    this.todaysDate2 = new Date();
    this.imageSrcOne = '';
    this.imageSrcTwo = '';
    this.imageSrcThree = '';
    this.imageSrcFour = '';
    this.imageSrcFive = '';
    this.imageSrcSix = '';
    this.imageSrcSeven = '';
  }
  autoGenerate() {
    this.productService.autoGenerateBarcode({}, this.currentUser.id).then((codeData) => {
      if (codeData) {
        this.ProductForm.patchValue({
          generateBarcode: codeData.data
        })
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }

  onFileSelected(event) {
    const maxFileSize = 1024 * 1024; // 1 MB
    const selectedFiles = event.target.files;
    if (this.maxFiles == 5) {
      this.toastService.toastMsg({
        title: "Error",
        content: "You Have AlReady Select 5 Images.",
      });
      event.srcElement.value = "";
    }
    else {
      if (selectedFiles.length > (this.Filesmax - this.maxFiles)) {
        // Handle too many files error
        this.toastService.toastMsg({
          title: "Error",
          content: "You Can Only Select Up To ${this.Filesmax - this.maxFiles} Images.",
        });
        event.srcElement.value = "";
        return;
      }
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (file.size > maxFileSize) {
          this.toastService.toastMsg({
            title: "Error",
            content: "File ${file.name} Exceeds The Maximum Size Of 1 MB.",
          });
          event.srcElement.value = "";
          return;
        }
      }
      // Continue with file processing
      const frmData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(selectedFiles[i]);
        frmData.append("upload", selectedFiles[i]);
      }
      this.maxFiles = this.maxFiles + selectedFiles.length;

      this.uploadMultiplefile(frmData);
      event.srcElement.value = "";
    }
  }

  uploadMultiplefile(frmData: FormData) {
    this.productService.postCoverImage1(frmData).then((res) => {
      if (res) {
        if (this.multipleImage.length == 0) {
          this.multipleImage = res.data.locations;;
        }
        else {
          this.multipleImage1 = res.data.locations;

          for (var i = 0; i < this.multipleImage1.length; i++) {
            this.multipleImage.push(this.multipleImage1[i]);
          }
        }
        this.toastService.toastMsg({
          title: "Success",
          content: "Product Image uploaded successfully!",
        });
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong",
        });
      }
    });
  }

  cancelmultiImage(i: number) {
    this.previews.splice(i, 1); // Removes one element, starting from index
    this.maxFiles = this.maxFiles - 1; // Decrement maxFiles by 1
    this.multipleImage.splice(i, 1);
    this.toastService.toastMsg({
      title: "Success",
      content: "Image removed successfully!",
    });
  }

  cancelCoverImage() {
    this.imageSrcSeven = '';
    this.productPicUrl = '';
    this.imageLoaded = false;
    this.toastService.toastMsg({
      title: "Success",
      content: "Cover Image removed successfully!",
    });
  }

  openDatepicker(item) {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.ProductForm.get(item).value;
    if (selectedDate && isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.ProductForm.get(item).setValue(new Date());
    }
  }

  openDatepickerexpiry() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate2 = this.ProductForm.get('expiry').value;
    if (selectedDate2 && isNaN(selectedDate2.getTime())) {
      // Set a default date or any valid date here
      this.ProductForm.get('expiry').setValue(new Date());
    }
  }

  get pricingDetailsControls() {
    return this.ProductForm.get('pricingDetails') as FormArray;
  }

  createPricingDetails(): FormGroup {
    return new FormGroup({
      cost: new FormControl(""),
      directExpense: new FormControl(""),
      margin: new FormControl("",),
      mrp: new FormControl("",),
    });
  }

  addPricingDetails(): void {
    const data = this.ProductForm.get('pricingDetails') as FormArray;
    data.push(this.createPricingDetails());
  }

  deletePricingDetails(index: number): void {
    const data = this.ProductForm.get('pricingDetails') as FormArray;
    data.removeAt(index)
  }

  // Function to handle selection change in warehouse selection dropdown
  onWarehouseSelectionChange(event: any) {
    if (event.value === 'clear') {
      this.ProductForm.get('warehouse').setValue(null); // Clear the selection
    }
  }

  calculateMinDate() {
    const promoStartDate = this.ProductForm.get('promoStartDate').value;
    // Assuming joiningDate is a valid Date object
    if (promoStartDate) {
        const minDate = new Date(promoStartDate);
        minDate.setDate(minDate.getDate() + 1); // Setting the minimum date as one day after joining date
        return minDate;
    }
    return null; // or you can set a default minDate if joiningDate is not set
  }
}
