import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AdminComponent } from '../../admin.component';

@Component({
  selector: 'app-appointement-type-f',
  templateUrl: './appointement-type-f.component.html',
  styleUrls: ['./appointement-type-f.component.scss']
})
export class AppointementTypeFComponent implements OnInit {
  public appointMentForm: any;
  public submitted: boolean;
  locations=[
      {
        "id":1,
        "locationName":"At Home"
  
      },
      {
        "id":2,
        "locationName":"Virtual/Technician"
  
      },
      {
        "id":2,
        "locationName":"Phone Call"
  
      },
]
  userRoles=[
    {
      "id":1,
      "userRole":"Admin"

    },
    {
      "id":1,
      "userRole":"User"

    }
  ]

  constructor(private formBuilder:FormBuilder) { }

  ngOnInit(): void {
    this.appointMentForm = this.formBuilder.group({
			Name: ["",[Validators.required,]],
      Duration:["",[Validators.required]],
      LocationType:["",[Validators.required]],
      UserRole:["",Validators.required],
      Description:["",Validators.required],
      QAppointMentType:[""],
      QRSVPtype:[""],
      inviteSchedule:[""]

    });
  }
  saveAppointForm(){
    this.submitted=true;
  }
  get f(){
    return this.appointMentForm.controls;
  }

}
