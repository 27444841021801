import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PartGroupServiceService {

  constructor(public httpClient:HttpService) { }
  
  getPartiGroupsDetails(data:{}, id, size, page):any{
    return new Promise((resolve, reject) => {
      // this.httpClient.inventory(data, UrlConstants.getAllGroup + id , 'GET').subscribe(    // old API call for the party group list 
      this.httpClient.inventory(data, UrlConstants.getAllPartyGroup + id + "?size="+ size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartiGroupsDetailsByGrpId(data:{},grpid):any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllGroupById + grpid , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  postsinglepartyGroup(data:{}){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postSinglepartyGroup, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updateGroupName(data:{}, groupId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.updateGroupName + groupId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartiGroupsDetailsByName(data:{},id,groupName):any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllPartyGroup + id + "?search=" + groupName , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteGroup(data,groupId):any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.DeleteGroup + groupId , 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  DeletePartyFromGroup(data,groupId):any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.DeletePartyFromGroup + groupId , 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  multipleGroupDelete(data,groupId):any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deleteMultipleGroup + groupId , 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

}
