import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(public httpClient: HttpService) { }

  postWarehouse(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.warehouse, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updateWarehouse(data: {}, warehouseId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.warehouse + `updateWarehouse/` + warehouseId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  // getAllWarehouse({data, userId, size, page, search}): any {
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, `warehouse?userId=${userId}&size=${size}&page=${page}&search=${search}`, 'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }

  getAllWarehouse({data, userId, size, page, search}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, `users/getAllWarehouse/${userId}?size=${size}&page=${page}&search=${search}&selfId=${userId}`, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  requestStockTransfer(data): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.requestStockTransfer, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllStockTransfer({data, requesterId, senderId, status}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllStockTransfer + '?status=' + (status ? `${status}` : '') + '&requesterId=' + (requesterId || '') + '&senderId=' + (senderId || ''), 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getStockTransferById(data, transferId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getStockTransferById + `${transferId}` , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  stockTransferAcceptReject(data): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.stockTransferAcceptReject , 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  changeStatus(data, warehouseId?): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, `users/warehouseToggle/${warehouseId}`, 'GET').subscribe(
      // this.httpClient.inventory(data, UrlConstants.warehouse + `changeStatus/${warehouseId}`, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getById(data, userId, warehouseId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.warehouse + `getById/${userId}/${warehouseId}`, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteWarehouse(data, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.warehouse + `${userId}`, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  packProducts(data): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.packProducts, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllPackagesByUser(data, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllPackagesByUser + `${userId}`, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  changeStatusOfPackage(data): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.changeStatusOfPackage, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updatePackage(data, packageId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.updatePackage + packageId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  warehouseDashboardCount(data, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.warehouseDashboardCount + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  productIsInWhichWarehouse(data, productId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.warehouse + `productIsInWhichWarehouse/${productId}`, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
