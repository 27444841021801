
<form [formGroup]="bankForm">
    <div formArrayName="addtionalBankDetails" *ngFor="let item of bankdataControl.controls; let i = index">
        <div class="row" [formGroupName]="i">
            <h2>Configure Schedule</h2>
            <div style="font-size: 12px" class="col-md-4">
                <div class="mb-1 mt-3">
                    <!-- <mat-form-field class="example-full-width" appearance="outline"> -->
                        <mat-label>weeks</mat-label>
                        <mat-button-toggle-group  formControlName="WeekName" name="ingredients" aria-label="Ingredients" multiple>
                            <mat-button-toggle value="S">S</mat-button-toggle>
                            <mat-button-toggle value="M">M</mat-button-toggle>
                            <mat-button-toggle value="T">T</mat-button-toggle>
                            <mat-button-toggle value="W">W</mat-button-toggle>
                            <mat-button-toggle value="T">T</mat-button-toggle>
                            <mat-button-toggle value="F">F</mat-button-toggle>
                            <mat-button-toggle value="S">S</mat-button-toggle>
                          </mat-button-toggle-group>
                    <!-- </mat-form-field> -->
                </div>
            </div>

            <div style=" font-size: 10px" class="col-md-4">
                <mat-label>Start Time</mat-label>
                <ngb-timepicker formControlName="timePicker" [meridian]="true"></ngb-timepicker>
            </div>
            <div style="font-size: 10px" class="col-md-4">
                <mat-label>End Time</mat-label>
                <ngb-timepicker formControlName="timePicker" [meridian]="true"></ngb-timepicker>
            </div>
          
            
            <div style=" font-size: 10px" class="col-md-4">
                <mat-label>Start  Slot Time</mat-label>
                <ngb-timepicker formControlName="timePicker" [meridian]="true"></ngb-timepicker>
            </div>
            <div style="font-size: 10px" class="col-md-4">
                <mat-label>End  Slot Time</mat-label>
                <ngb-timepicker formControlName="timePicker" [meridian]="true"></ngb-timepicker>
            </div>
            <div class="col-sm-12 col-lg-3 col-md-3">
                <div class="mb-1">
                    <a class="btn minus fs--1" (click)="deleteaddBankDetails(item)">
                        <i class="fas fa-minus-square"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>


    <a class="btn  plus fs--1" style="margin-right: 0.8rem" (click)="addBankDetails()">
        <i class="fas fa-plus-square"></i>
    </a>
    <div class="card-footer bg-light">
        <div class="px-sm-3 px-md-5">
            <ul class="pager wizard list-inline mb-0">
                <li class="previous">
                </li>
                <li class="">
                    <a class="btn btn-primary px-5 px-sm-6 fs--1" (click)="BankDetails()" type="submit">
                        Submit<span class=" ms-2 fs--1" data-fa-transform="shrink-3">
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</form>

