import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AssetsServiceService } from 'src/app/core/services/assets-service.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-asset-formcompoennet',
  templateUrl: './asset-formcompoennet.component.html',
  styleUrls: ['./asset-formcompoennet.component.scss']
})
export class AssetFormcompoennetComponent implements OnInit {
  public assetsForm: any
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public submitted = false;
  public GLData: any;


  constructor(public dialogRef: MatDialogRef<AssetFormcompoennetComponent>, private formBuilder: FormBuilder, public router: Router, private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe, private assetsServiceService: AssetsServiceService) { }

  ngOnInit(): void {
    this.createAssetForm();
    this.getASSETData()
  }
  getASSETData() {
    this.assetsServiceService.getAsseTListByledger({}, this.currentUser.id).then((res: any) => {
      console.log("AssetsByLedegr", res)
      this.GLData = res.data.map((item) => { return item.glNumber });
    })

  }
  createAssetForm() {
    this.assetsForm = this.formBuilder.group({
      assetDate: ["", [Validators.required]],
      assetName: ["", [Validators.required]],
      toatalAmount: ["", [Validators.required]],
      // glNumber:["",[Validators.required]]
    });

  }
  get f() {
    return this.assetsForm.controls;
  }

  postAssetsDetails() {
    this.submitted = true;
    if (this.assetsForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields."
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;

    }
    else {
      let Body =
      {
        "userId": this.currentUser.id,
        // "glNumber": this.assetsForm.controls.glNumber.value,
        "assetsType": "CURRENT",
        "assetName": this.assetsForm.controls.assetName.value,
        "date": this.assetsForm.controls.assetDate.value,
        "valueOfAsset": this.assetsForm.controls.toatalAmount.value,
      }
      this.assetsServiceService.addAssets(Body).then((res: any) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Current Assets Added Successfully!"
        });
        // this.toastService.openSnackBar("Current Assets Added Successfully!");
        this.assetsServiceService.notifyassetsAdded();
        this.dialogRef.close();

      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong."
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
    }

  }
  cancel() {
    this.dialogRef.close();
  }
  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.assetsForm.get('assetDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.assetsForm.get('assetDate').setValue(new Date());
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
