import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  apiserver(data: {}, getHelpAndFaq: string, arg2: string) {
      throw new Error('Method not implemented.');
  }
  public pincode_url = "https://api.postalpincode.in/"
  api_url: "https://iceipts-3b086.appspot.com"
  public ifsc_Url = "https://ifsc.razorpay.com/"
  
  constructor(private httpClient: HttpClient) { }

  call(data, api, method): Observable<object> {
    let headers = new HttpHeaders();
    if (api == 'users/addEmployeeUser' || 'users/uploadBulkEmployee') {
      headers = headers.set('web', environment.web)
    }
    return this.httpClient.request(method, environment.apiUrl + api, {
      body: data,
      headers
    })
  }

  appointment(data, api, method): Observable<object> {
    const headers = new HttpHeaders();
    return this.httpClient.request(method, environment.appointmentUrl + api, {
      body: data,
      headers
    })
  }
  pincodeApi(api, method): Observable<object> {
    return this.httpClient.request(method, this.pincode_url + api, {
    })
  }
  inventory(data, api, method): Observable<object> {
    const headers = new HttpHeaders();
    return this.httpClient.request(method, environment.inventoryUrl + api, {
      body: data,
      headers
    })
  }
  widgets(data, api, method): Observable<object> {
    const headers = new HttpHeaders();
    return this.httpClient.request(method, this.api_url + api, {
      body: data,
      headers
    })
  }

  Ifc(method, data, code): Observable<object> {
    const headers = new HttpHeaders();
    return this.httpClient.request(method, this.ifsc_Url + code, {
      body: data,
      headers
    })
    // return this.httpClient.request(method, this.addIFCCode + api, {})

  }


  // localCall(data, api, method): Observable<object> {
  //   const headers = new HttpHeaders();
  //   return this.httpClient.request(method, "https://d7fb-103-241-225-174.ngrok.io/" + api, {
  //     body: data,
  //     headers
  //   })
  // }
}
