import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetsServiceService {
  
  private expenseAddedSource = new Subject<void>();
  assetsAdded$ = this.expenseAddedSource.asObservable();

  notifyassetsAdded() {
    this.expenseAddedSource.next();
  }

  constructor(public httpClient: HttpService) { }
  getCurrentAssetsData(data: {}, userId, size ,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getCurrentAssetData + userId + "?size=" + size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getCurrentAssetsDataFilter(data: {}, userId, search, size ,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getCurrentAssetData + userId  + "?search=" + search + "&size=" + size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getNONCurrentAssetsData(data: {} ,userId ,size ,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.nonCurrentAsset + userId + "?size=" + size+ "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getNONCurrentAssetsDataBYId(data: {}, nonassetId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.nonCurrentAssetbYId + nonassetId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updateNonCurrentAssets(data:{}, id){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.updateNonCurrentAsset + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getNONCurrentAssetsDataFilter(data: {},userId , size, page, search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.nonCurrentAsset + userId + "?size=" + size + "&page=" + page + "&search=" + search , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  addAssets(data:{}){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.currentASSEtAdd , 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteAssets(data:{}, assetId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.DeleteCurrentAndNonCurrent + assetId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAsseTListByledger(data:{}, userId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.assetsName + userId , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  addRundeprec(data:{}, id){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.rundeprecationPost + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updateRundeprec(data:{}, id){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.updateRunDeprac + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
