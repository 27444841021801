import { HttpClient } from '@angular/common/http';
import { Component, OnInit} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CalendarOptions } from '@fullcalendar/angular';

import { EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public DashboardForm: any;
  public submitted: boolean;
  locations=[
    {
      "id":1,
      "locationName":"At Home"

    },
    {
      "id":2,
      "locationName":"Virtual/Technician"

    },
    {
      "id":2,
      "locationName":"Phone Call"

    },
]
constructor(private formBuilder:FormBuilder){}
  ngOnInit(): void {
    this.DashboardForm = this.formBuilder.group({
			LocationType: ["",[Validators.required]],
    });
  }
  locationChange(){
    this.submitted=true;

  }
  upiUpdate(){

  }
  get f(){
    return this.DashboardForm.controls;
  }

 
  

}
